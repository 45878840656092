import React from 'react';

import {connect} from 'react-redux';

import TextFull from '../../../elements/viewData/textFull'

import {isGFE, isGFD} from '../../../../redux/procedure.js';

import {viewStringValue} from '../../../../api/valueToString.js'

const PreparationValueString = (props) => {
    if(props.auctionId === null) return null
    if(props.auctionId.preparationValue === null) return null
    let tmp = viewStringValue(props.auctionId.preparationValue)
    return (
        <TextFull
            title={typeTitleText(props)}
            data={tmp}
        />
    )
}

function typeTitleText(props){
    return 'Витрати на підготовку лота'
}

const mapStateToProps = state => {
    return {
        auctionId: state.start.auctionFull,
    }
}

export default connect(mapStateToProps, null)(PreparationValueString)