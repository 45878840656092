import { checkProp } from "../../../../redux/check"
import { isALE, isCSE, isCSD, isBSE, isBSD, isBRE, isBRD, isBRW, isRLE, isRLD, isCLE, isCLD, isSPE, isSPD,
isNLE, isNLD, isLAE, isLAW, isLAP, isLPE, isAPE, isAPD,
isLLE, isLLD, isLLP, isLRE, isLSE, isLSP } from "../../../../redux/procedure";

// EDIT_BUTTON
export function viewAucEditDoc(props) {
    if (props.scope.aucStatus === null || props.scope.aucStatus === "draft") {
        return true
    }
    if (props.scope.aucStatus === "active_rectification" && props.scope.aucOwner === true) {
        return true
    }
    if (props.scope.aucStatus === 'active_tendering') { //TODO: CSE, CSD - isPerishable не прилетает???
        if(
            isLLE(props.scope.aucType) || isLLD(props.scope.aucType) || isLLP(props.scope.aucType) ||
            isBSE(props.scope.aucType) || isBSD(props.scope.aucType) ||
            isALE(props.scope.aucType) ||
            isCSE(props.scope.aucType) || isCSD(props.scope.aucType) ||
            isBRE(props.scope.aucType) || isBRD(props.scope.aucType) ||
            isRLE(props.scope.aucType) || isRLD(props.scope.aucType) ||
            isCLE(props.scope.aucType) || isCLD(props.scope.aucType) ||
            isSPE(props.scope.aucType) || isSPD(props.scope.aucType) ||
            isNLE(props.scope.aucType) || isNLD(props.scope.aucType) ||
            isLAE(props.scope.aucType) || isLAP(props.scope.aucType) ||
            isLPE(props.scope.aucType) ||
            isAPE(props.scope.aucType) || isAPD(props.scope.aucType)
            || isLRE(props.scope.aucType) || isLSE(props.scope.aucType) || isLSP(props.scope.aucType)
            || props.scope.aucType.startsWith('renewables')
        ){
            return true
        }
        if (checkProp(props.scope, 'aucPeriods')) {
            let tmpDate = Date.now()
            for (let key in props.scope.aucPeriods) {
                if ( checkProp(props.scope.aucPeriods[key], 'status') &&
                     props.scope.aucPeriods[key].status === 'rectificationPeriod' &&
                     checkProp(props.scope.aucPeriods[key], 'endDate')) {
                    let tmpEndDate = Date.parse(props.scope.aucPeriods[key].endDate)
                    if (tmpDate < tmpEndDate) { return true }
                }
            }
        }
    }
    if (props.scope.aucStatus === 'active_qualification' || props.scope.aucStatus === 'active_awarded') {
        if(isBRW(props.scope.aucType) || isLAW(props.scope.aucType)){
            return true
        }
    }
    return false
}

// DELETE_BUTTON
export function viewAucDeleteDoc(props) {
    if (props.scope.aucStatus === null || props.scope.aucStatus === 'draft') {
        if (props.scope.aucOwner === true) return true
        if(
            isLLE(props.scope.aucType) || isLLD(props.scope.aucType) || isLLP(props.scope.aucType) ||
            isBSE(props.scope.aucType) || isBSD(props.scope.aucType) ||
            isALE(props.scope.aucType) ||
            isCSE(props.scope.aucType) || isCSD(props.scope.aucType) ||
            isBRE(props.scope.aucType) || isBRD(props.scope.aucType) || isBRW(props.scope.aucType) ||
            isRLE(props.scope.aucType) || isRLD(props.scope.aucType) ||
            isCLE(props.scope.aucType) || isCLD(props.scope.aucType) ||
            isNLE(props.scope.aucType) || isNLD(props.scope.aucType) ||
            isLAE(props.scope.aucType) || isLAW(props.scope.aucType) || isLAP(props.scope.aucType) ||
            isAPE(props.scope.aucType) || isAPD(props.scope.aucType)
            || isLRE(props.scope.aucType) || isLSE(props.scope.aucType) || isLSP(props.scope.aucType) ||
            props.scope.aucType.startsWith('renewables')
        ) return true
    }
    return false
}

export async function deleteAucDocuments(props){
    props.changeLoader(true)
    const endPoint = `${props.scope.deleteURL}/${props.data.id}`
    const response = await fetch(endPoint, {
        method: "DELETE",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    })
    .catch(console.error)
    if(response === undefined || response === 'undefined'){
        props.changeLoader(false)
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){
            props.changeLoader(false)
            //let tmp = JSON.stringify({title: 'Помилка додання документу до чернетки', message: JSON.stringify(json4)})
            //props.setUniversalError(tmp)
        }else{
            let data = {
                domen: process.env.REACT_APP_END_POINT_BD,
                version: 'v1',
                local: 'uk',
                params: '?limit=20'
            }
            await props.scope.reload()
            await props.changeLoader(false)
        }
    }
}