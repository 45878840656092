import React, {useEffect} from 'react';

import {connect} from 'react-redux';
import ViewBid from "../../../product/newBidLot/viewBid/viewBid";
import procedureHelper from "../../../../redux/procedure";
import {getUserBidsListByAuction} from "../../../_api/bidApi";

import {saveArrBids} from "../../../../redux/allAuctionBids";
import CreateBid from "../create/createBid";

const ViewBidList = (props) => {
    useEffect(() => {
        getUserBidsListByAuction(props, props?.auction?.id)
    }, [])

    if (props?.allUserBids?.arr?.length > 0) {
        let bidViewList = props?.allUserBids?.arr
            .map((bid) => {
                return <ViewBid elemBid={bid}/>
            })
        return (
            <div>
                {bidViewList}
                {procedureHelper.isREM(props.auction.sellingMethod) ?
                    <CreateBid isMultiAward={true}/>
                    : null
                }
            </div>
        )
    }
    return null
}

const mapStateToProps = state => {
    return {
        allUserBids: state.start.auctionsAllBids,
        token: state.start.token,
        auction: state.start.auctionFull,
    }
}
const mapDispatchToProps = {
    saveArrBids,
    getUserBidsListByAuction
}
export default connect(mapStateToProps, mapDispatchToProps)(ViewBidList)