import React,{useState} from 'react';
import styles from './newAward.module.css';

import {connect} from 'react-redux';

import {auctionsGetAwards, auctionsGetAwardsId, changeAwardRejectPopUpStatus, changeAwardApprowePopUpStatus, changeAwardView} from '../../../redux/actions/awards/awards.js';

import { setAwardAllUserPopUp, setAwardAllUserPopUpUser } from '../../../redux/actions.js';
import { isTIE, isSUE, isSUD, isRCE, isRCD, isGFE, isGFD, isLLE, isLLD,
    isLLP, isLRE, isLSE, isLSP, isBSE, isBSD, isALE, isCSE, isCSD,
    isBRE, isBRD, isBRW, isRLE, isRLD, isCLE, isCLD} from '../../../redux/procedure';

// import AwardDataPopUp from './popUpDataAward/popUpDataAward.js';
// import AwardDataPopUpUser from './popUpDataAward/popUpDataAwardUser.js';

import {
    changeContractApprowePopUpStatus,
    setContractTitle,
    setContractDescription,
    setContractContractNumber,
    setContractDateFrom,
    setContractDateTill,
    setContractStartDate,
    setContractEndDate,
    setContractCurrency,
    setContractAmount,
    setContractDateSigned,
    setContractDatePaid,
    setContractDocId,
    setContractDocTitle,
    setContractDocType,
    setContractDocDescription,
    setContractDocDocumentOf,
} from '../../../redux/actions/contracts/contracts.js';

import { getUpdateDataAuction, getUpdateDataBid } from '../../product/updateData';
import { saveArrBids } from '../../../redux/allAuctionBids';
import { setDataAddress } from '../../../redux/createAddress';

import TextFull from '../../elements/viewData/textFull'
import TextHalf from '../../elements/viewData/textHalf'
import PopDown from '../../elements/viewData/popdownBlock/popDownBlock'
// import ContractDocItem from './awardComponents/docItems/awardDocItem';
import AddDocPopup from '../../documents/addDocPopup/addDocPopup';
import DocItem from '../../documents/docItem';

import {
    historyAuctions,
    historyAwards,
    historyContrasts,
    historyBids,
} from '../../../redux/history.js';
import {setUniversalError, changeLoader} from '../../../redux/actions.js';
import {contractsAuctionIdGet} from '../../../redux/actions/contracts/contracts.js';

import {modifiDateString} from '../../../redux/modifiDate'
import { CONTACTS_TITLE_BOTTOM } from '../../../model/lang/topMenu';
import { checkProp } from '../../../redux/check';
import {startCostAuction} from '../util';
import {viewStringValue} from '../../../api/valueToString.js'

const Contract = (props) => {
    const [slim, setSlim] = useState({ state: false, text: 'Розгорнути' })
    const [popAddDoc, setPopAddDoc] = useState(false)

    function Slimmer() {
        slim.state ? setSlim({ state: false, text: 'Розгорнути'}) : setSlim({ state: true, text: 'Згорнути'})
    }

    if(props.auctionId !== null){
        if(props.auctionId === undefined || props.auctionId === 'undefined'){

        }else{
            if(
                props.auctionId.status === 'active_auction' ||
                props.auctionId.status === 'active_awarded' ||
                props.auctionId.status === 'active_qualification' ||
                props.auctionId.status === 'pending_payment' ||
                props.auctionId.status === 'pending_admission' ||
                props.auctionId.status === 'cancelled' ||
                props.auctionId.status === 'complete' ||
                props.auctionId.status === 'unsuccessful'
            ){
                if(props.auctionId.awards !== null){
                    return (<>
                                
                                <div className={styles.contractBlock}>
                                    <div className={styles.contractBlock_title}>Кваліфікація учасників</div>
                                    <div className={styles.slimmer} onClick={() => Slimmer()}>{slim.text}</div>
                                    {slim.state ?
                                        <div className={styles.contractBlockContent}>
                                            {viewAwardArr(props, popAddDoc, setPopAddDoc)}
                                        </div> : null
                                    }
                                </div>
                            </>
                    )
                }
            }
        }
    }
    return null
}



function viewAwardArr(props, popAddDoc, setPopAddDoc) {
    let tmp = null;
    if(props.auctionId !== null){
        if(props.auctionId.awards.length > 0){
            tmp = props.auctionId.awards.map(
                (i, count) => {
                    return (<>
                                {/*
                                            popAddDoc ?
                                                <AddDocPopup
                                                    scopeData={docScopeData}
                                                    actionClose={() => {
                                                        setPopAddDoc(false)
                                                    }}
                                                    actionNo={() => {
                                                        setPopAddDoc(false)
                                                    }}
                                                    // bidId={props.elemBid.id}
                                                    // elemBid={props.elemBid}
                                                />
                                            : null
                                        */}
                                <div className={styles.contractBlock}>
                                    {viewTitle(i)}
                                    {viewBiddersName(i)}
                                    {viewBidValue(props, i)}
                                    {initialValueAmount(props, i)}
                                    {viewTerminationReason(props, i)}
                                    {viewStatus(props, i)}
                                    {viewAddress(i)}
                                    {dataViewVerificationPeriod(i)}
                                    {dataViewSigningPeriod(i)}
                                    {i.terminationReason === null ? <div className={styles.btnBlock}>
                                        {buttonContractPopUp(props, i, count, setPopAddDoc, popAddDoc, setAwardDocTypes)}
                                    </div> : null}
                                    {viewContractDocuments(props, i, count)}
                                </div>
                        </>
                    )
                }
            )
        }
    }
    return tmp
}


function buttonContractPopUp(props, i, count, setPopAddDoc, popAddDoc){
    if(props.auctionId !== null){
        return (
            <>
                {viewApproveContractDoc(props, i, count, setPopAddDoc, popAddDoc)}
            </>
        )
    }
    return null
}

function viewApproveContractDoc(props, i, count, setPopAddDoc, popAddDoc) { // Кнопка "Документи кваліфікації"  
    
    function getBidOwner( props, i) {
        if (props.auctionId.privateData.hasBids) {
            for (let key in props.auctionId.privateData.bids) {
                if (i.bidId === props.auctionId.privateData.bids[key].bidId) return true
            }
        }
        return false
    }

    function getBidDocuments(props, i) {
        if (getBidOwner(props, i)) {
            for (let key in props.auctionId.bids) {
                if (i.bidId === props.auctionId.bids[key].id) {
                    return props.auctionId.bids[key].documents.filter((i) => { return i.documentType === 'auctionProtocol' })
                }
            }
        }

    }

    let tmp = null
    const docScopeData = {}
    docScopeData.aucId = props.auctionId.id
    docScopeData.aucStatus = props.auctionId.status
    docScopeData.aucType = props.auctionId.sellingMethod
    docScopeData.awardId = i.id
    docScopeData.awardStatus = i.status
    // docScopeData.aucReload = props.reload
    docScopeData.aucOwner = props.auctionId.privateData.isOwner
    docScopeData.bidOwner = getBidOwner( props, i)
    docScopeData.bidId = i.bidId
    docScopeData.docTypesList = setAwardDocTypes(props, i)
    docScopeData.reload = () => {
        getUpdateDataAuction(props, props.auctionId.id)
        if (!props.auctionId.privateData.isOwner) getUpdateDataBid(props, props.auctionsAllBids.arr.id)
    }
    docScopeData.docList = i.documents
    docScopeData.bidDocList = getBidDocuments(props, i)

    if(props.auctionId.privateData.isOwner){
        docScopeData.typeOf = 'award'
        docScopeData.uploadNewDocURL = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction/${props.auctionId.id}/award/${i.id}/documents`
    } else if (props.auctionId.privateData.hasBids) {
        docScopeData.typeOf = 'bidAward'
        docScopeData.uploadNewDocURL = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/bid/${props.auctionId.privateData.bids[0].id}/documents`
    }

    //if(
        //!isLRE(props.auctionId.sellingMethod) &&
        //!isLSE(props.auctionId.sellingMethod) &&
        //!isLSP( props.auctionId.sellingMethod)
    //){
        if(props.auctionId.privateData.isOwner === true){
            if(
                props.auctionId.status !== 'cancelled' &&
                props.auctionId.status !== 'unsuccessful' &&
                props.auctionId.status !== 'complete' &&
                i.status !== 'cancelled' &&
                i.status !== 'unsuccessful'
            ){
                tmp = (
                    <>
                    {
                                popAddDoc ?
                                <AddDocPopup
                                    scopeData={docScopeData}
                                    actionClose={() => {
                                        setPopAddDoc(false)
                                    }}
                                    actionNo={() => {
                                        setPopAddDoc(false)
                                    }}
                                />
                                : null
                            }
                        <div
                            className={styles.btnAccept}
                            onClick={
                                () => {
                                    setPopAddDoc(true)
                                }
                            }
                        >
                            Документи кваліфікації
                        </div>
                        {props.auctionId.privateData.isOwner ? viewApprove(props, i, count, 4) : null}
                    </>
                )
            }
            if(i.status === 'pending_waiting') tmp = null
        }else if(props.auctionId.privateData !== null){
            if(i.status === 'pending_waiting'){
                if(
                    props.auctionId.status !== 'cancelled' &&
                    props.auctionId.status !== 'unsuccessful' &&
                    props.auctionId.status !== 'complete' &&
                    i.status !== 'cancelled' &&
                    i.status !== 'unsuccessful'
                ){
                    if(props.auctionId.privateData.bids !== null && props.auctionId.privateData.bids.length > 0){
                        if(i.bidId === props.auctionId.privateData.bids[0].bidId){
                            tmp = (
                                <>
                                    {
                                        popAddDoc ?
                                        <AddDocPopup
                                            scopeData={docScopeData}
                                            actionClose={() => {
                                                setPopAddDoc(false)
                                            }}
                                            actionNo={() => {
                                                setPopAddDoc(false)
                                            }}
                                        />
                                        : null
                                    }
                                    {props.auctionId.privateData.isOwner ? viewApprove(props, i, count) : null}
                                    {props.auctionId.privateData.hasBids ? viewApprove(props, i, count) : null}
                                </>
                            )
                        }
                    }
                }
            }else{
                if(
                    props.auctionId.status !== 'cancelled' &&
                    props.auctionId.status !== 'unsuccessful' &&
                    props.auctionId.status !== 'complete' &&
                    i.status !== 'cancelled' &&
                    i.status !== 'unsuccessful' &&
                    i.status !== 'active'
                ){
                    if(props.auctionId.privateData.bids !== null && props.auctionId.privateData.bids.length > 0){
                        if(i.bidId === props.auctionId.privateData.bids[0].bidId){
                            tmp = (
                                <>
                                    {
                                        popAddDoc ?
                                            <AddDocPopup
                                                scopeData={docScopeData}
                                                actionClose={() => {
                                                    setPopAddDoc(false)
                                                }}
                                                actionNo={() => {
                                                    setPopAddDoc(false)
                                                }}
                                            />
                                        : null
                                    }
                                    <div
                                        className={styles.btnAccept}
                                        onClick={
                                            () => {
                                                setPopAddDoc(true)
                                            }
                                        }
                                    >
                                        Документи кваліфікації
                                    </div>
                                    {props.auctionId.privateData.isOwner ? viewApprove(props, i, count) : null}
                                </>
                            )
                        }
                    }
                }
            }
        }
    /*}else{
        if(props.auctionId.privateData.isOwner === true){
            if(
                props.auctionId.status !== 'cancelled' &&
                props.auctionId.status !== 'unsuccessful' &&
                props.auctionId.status !== 'complete' &&
                i.status !== 'cancelled' &&
                i.status !== 'unsuccessful'
            ){
                tmp = (
                                <>
                                {
                                popAddDoc ?
                                <AddDocPopup
                                    scopeData={docScopeData}
                                    actionClose={() => {
                                        setPopAddDoc(false)
                                    }}
                                    actionNo={() => {
                                        setPopAddDoc(false)
                                    }}
                                />
                                : null
                            }
                        <div
                            className={styles.btnAccept}
                            onClick={
                                () => {
                                    setPopAddDoc(true)
                                }
                            }
                        >
                            Документи кваліфікації
                        </div>
                        {props.auctionId.privateData.isOwner ? viewApprove(props, i, count, 4) : null}
                    </>
                )
            }
            if(i.status === 'pending_waiting') tmp = null
        }else if(props.auctionId.privateData.bids !== null && props.auctionId.privateData.bids.length > 0){
            if(i.bidId === props.auctionId.privateData.bids[0].bidId){
                if(
                    props.auctionId.status !== 'cancelled' &&
                    props.auctionId.status !== 'unsuccessful' &&
                    props.auctionId.status !== 'complete' &&
                    props.auctionId.status !== 'complete' &&
                    i.status !== 'cancelled' &&
                    i.status !== 'unsuccessful'
                ){
                    if(i.status === 'pending'){
                        tmp = (
                            <>
                            {
                                popAddDoc ?
                                <AddDocPopup
                                    scopeData={docScopeData}
                                    actionClose={() => {
                                        setPopAddDoc(false)
                                    }}
                                    actionNo={() => {
                                        setPopAddDoc(false)
                                    }}
                                />
                                : null
                            }
                                <div
                                    className={styles.btnAccept}
                                    onClick={
                                        () => {
                                            setPopAddDoc(true)
                                        }
                                    }
                                >
                                    Документи кваліфікації
                                </div>
                            </>
                        )
                    }else if(i.status === 'pending_waiting'){
                        tmp = (
                            <>
                            {
                                popAddDoc ?
                                <AddDocPopup
                                    scopeData={docScopeData}
                                    actionClose={() => {
                                        setPopAddDoc(false)
                                    }}
                                    actionNo={() => {
                                        setPopAddDoc(false)
                                    }}
                                />
                                : null
                            }
                                {props.auctionId.privateData.isOwner ? viewApprove(props, i, count) : null}
                            </>
                        )
                    }else{
                        tmp = (
                            <>
                                {props.auctionId.privateData.isOwner ? viewApprove(props, i, count) : null}
                            </>
                        )
                    }
                }
            }
        }
    }*/
    return tmp
}


function viewTitle(i){
    if(i.title !== null && i.title !== ''){
        return (
            <TextHalf
                title='Назва'
                data={i.title}
            />
        )
    }
    return null
}

function viewBiddersName(i){
    if(i.buyers !== null){
        if(i.buyers.length > 0){
            if(i.buyers[0].name !== null && i.buyers[0].name !== ''){
                return (
                    <TextHalf
                        title='Назва учасника'
                        data={i.buyers[0].name.uk_UA}
                    />
                )
            }
        }
    }
    return null
}

function setAwardDocTypes(props, i) {
    if(props.auctionId.privateData.isOwner && i.status === 'pending') {
        if(
            isBSE(props.auctionId.sellingMethod) || isBSD(props.auctionId.sellingMethod) 
            // ||
            // isCSE(props.auctionId.sellingMethod) ||
            // isCSD(props.auctionId.sellingMethod)
        ){
            return [
                    { value: 'auctionProtocol', name: 'Протокол аукціону', main: null },
                    { value: 'rejectionProtocol', name: 'Протокол відхилення', main: null },
                    { value: 'act', name: 'Акт про відмову', main: null },
                    ]
        }
        if(
            isLLE(props.auctionId.sellingMethod) || isLLD(props.auctionId.sellingMethod)  || isLLP(props.auctionId.sellingMethod) 
            // ||
            // isCSE(props.auctionId.sellingMethod) ||
            // isCSD(props.auctionId.sellingMethod)
        ){
            return [
                { value: 'auctionProtocol', name: 'Протокол аукціону', main: null },
                { value: 'rejectionProtocol', name: 'Протокол відхилення', main: null },
                { value: 'act', name: 'Акт про відмову / непідписання', main: null },
            ]
        }
        return [
            { value: 'auctionProtocol', name: 'Протокол аукціону', main: null },
            { value: 'rejectionProtocol', name: 'Документ, що підтверджує дискваліфікацію', main: null },
            { value: 'act', name: 'Документ, що підтверджує відмову', main: null },
            ]
    }
    if(props.auctionId.privateData.isOwner && i.status === 'pending_payment') {
        if(
            isBRE(props.auctionId.sellingMethod) || isBRD(props.auctionId.sellingMethod)  || isBRW(props.auctionId.sellingMethod) 
            // ||
            // isCSE(props.auctionId.sellingMethod) ||
            // isCSD(props.auctionId.sellingMethod)
        ){
            return [
                    { value: 'paymentInformation', name: 'Інформація про повний розрахунок', main: null },
                    { value: 'partialPayment', name: 'Інформація про часткову оплату', main: null },
                    { value: 'rejectionProtocol', name: 'Протокол відхилення', main: null },
                    { value: 'act', name: 'Акт про відмову', main: null },
                    ]
        }
        return [
            { value: 'paymentInformation', name: 'Інформація про повний розрахунок', main: null },
            { value: 'partialPayment', name: 'Інформація про часткову оплату', main: null },
            { value: 'rejectionProtocol', name: 'Документ, що підтверджує дискваліфікацію', main: null },
            { value: 'act', name: 'Документ, що підтверджує відмову', main: null },
            ]
    }
    if (props.auctionId.privateData.isOwner && i.status === 'active') {
        if(
            isBSE(props.auctionId.sellingMethod) || isBSD(props.auctionId.sellingMethod) 
            // ||
            // isCSE(props.auctionId.sellingMethod) ||
            // isCSD(props.auctionId.sellingMethod)
        ){
            return [
                { value: 'rejectionProtocol', name: 'Протокол відхилення', main: null },
                { value: 'act', name: 'Акт про відмову', main: null },
            ]
        }
        if(
            isLLE(props.auctionId.sellingMethod) || isLLD(props.auctionId.sellingMethod)  || isLLP(props.auctionId.sellingMethod) 
            // ||
            // isCSE(props.auctionId.sellingMethod) ||
            // isCSD(props.auctionId.sellingMethod)
        ){
            return [
                { value: 'rejectionProtocol', name: 'Протокол відхилення', main: null },
                { value: 'act', name: 'Акт про відмову / непідписання', main: null },
            ]
        }
        return [
            { value: 'rejectionProtocol', name: 'Документ, що підтверджує дискваліфікацію', main: null },
            { value: 'act', name: 'Документ, що підтверджує відмову', main: null },
        ]
    }
    if (props.auctionId.privateData.bids !== null && props.auctionId.privateData.bids.length > 0) {
        if(i.bidId === props.auctionId.privateData.bids[0].bidId  && i.status === 'pending'){
        //if(i.status === 'pending'){
            return [
                { value: 'auctionProtocol', name: 'Протокол аукціону', main: null }
            ]
        }else if(i.bidId === props.auctionId.privateData.bids[0].bidId && i.status === 'active'){
        //}else if(i.status === 'active'){
            let tmpIsCurrentTenant = null
            for(let g = 0; g < props.auctionId.bids.length; g++){
                if(props.auctionId.bids[g].hasOwnProperty('isCurrentTenant') === true){
                    if(props.auctionId.bids[g].id === props.auctionId.privateData.bids[0].bidId) tmpIsCurrentTenant = props.auctionId.bids[g].isCurrentTenant
                }
            }
            if(tmpIsCurrentTenant !== null){
                if(tmpIsCurrentTenant === false){
                    return [
                        { value: 'auctionProtocol', name: 'Протокол аукціону', main: null },
                        { value: 'transferAct', name: 'Акт приймання-передачі', main: true },
                    ]
                }else{
                    return [
                        { value: 'auctionProtocol', name: 'Протокол аукціону', main: null },
                        { value: 'transferAct', name: 'Акт приймання-передачі', main: null },
                    ]
                }
            }else{
                return [
                    { value: 'auctionProtocol', name: 'Протокол аукціону', main: null }
                ]
            }
        }else if(i.bidId === props.auctionId.privateData.bids[0].bidId && i.status === 'pending_waiting'){
        //}else if(i.status === 'pending_waiting'){
            return [
                { value: 'act', name: 'Документ, що підтверджує відмову', main: null }
            ]
        }
    }
    return []
}

function viewBidValue(props, i){
    if(props.auctionId !== null){
        if(props.auctionId.bids !== null){
            if(props.auctionId.bids.length > 0){
                for(let tmp = 0; tmp < props.auctionId.bids.length; tmp++){
                    if(props.auctionId.bids[tmp].value !== null){
                        if(props.auctionId.bids[tmp].id === i.bidId){
                            let tmpValue = viewStringValue(props.auctionId.bids[tmp].value)
                            return (
                                <TextHalf
                                    title='Розмір цінової пропозиції'
                                    data={tmpValue}
                                />
                            )
                        }
                    }
                }
            }
        }
    }
    return null
}

function initialValueAmount(props, i){
    if(props.auctionId !== null){
        if(props.auctionId.bids !== null){
            if(props.auctionId.bids.length > 0){
                for(let tmp = 0; tmp < props.auctionId.bids.length; tmp++){
                    if(props.auctionId.bids[tmp].value !== null){
                        if(props.auctionId.bids[tmp].id === i.bidId){
                            let tmpCost = {
                                amount: props.auctionId.bids[tmp].initialValueAmount,
                                currency: "UAH"
                            }
                            let tmpValue = viewStringValue(tmpCost)
                            return props.auctionId.bids[tmp].initialValueAmount ? <TextHalf
                                    title='Розмір закритої цінової пропозиції'
                                    data={tmpValue}
                                /> : null
                        }
                    }
                }
            }
        }
    }
    return null
}

function viewTerminationReason(props, data){
    if(data.terminationReason !== null && data.terminationReason !== '' && data.terminationReason !== 'null'){
        return (
            <TextHalf
                title='Причина відмови'
                data={viewTypeTerminationReason(props, data.terminationReason)}
            />
        )
    }
    return null
}

function viewTypeTerminationReason(props, data){
    if(props.auctionId !== null){
        switch(true){
            case isSUE(props.auctionId.sellingMethod):
            case isSUD(props.auctionId.sellingMethod):
                switch(data){
                    case '1': return "Непідписання протоколу"
                    case '2': return "Непідписання договору"
                    case '3': return "Поширюються обмежувальні заходи (санкції)"
                    case '4': return "Непроведення реєстрації представництва"
                    case '5': return "Невнесення сплати в установлений строк"
                    case '6': return "Неотримання висновку з оцінки впливу на довкілля"
                    case '7': return "Отримання висновку з оцінки впливу на довкілля з обґрунтуванням недопустимості провадження планованої діяльності"
                    default: return data
                }
            case isLLE(props.auctionId.sellingMethod):
            case isLLD(props.auctionId.sellingMethod):
                switch(data){
                    case '1': return 'Непідписання протоколу'
                    case '2': return `Непідписання договору`
                    case '3': return 'Невідповідність вимогам статті 4 ЗУ'
                    case '4': return `Неподання обов\'язкових документів або відомостей`
                    case '5': return 'Подання неправдивих відомостей'
                    case '6': return 'Судові рішення та інші підстави'
                    default: return data
                }
            case isGFE(props.auctionId.sellingMethod):
            case isGFD(props.auctionId.sellingMethod):
                switch(data){
                    case '1': return 'Відмовився від підписання договору'
                    case '2': return `Переможець аукціону є боржником та/або поручителем за кредитними договорами та договорами забезпечення виконання зобов\'язань`
                    case '3': return 'Повна оплата коштів за лот не здійснена в строк'
                    default: return data
                }
            case isLRE(props.auctionId.sellingMethod):
            case isLSE(props.auctionId.sellingMethod):
            case isLSP(props.auctionId.sellingMethod):
                switch(data){
                    case "1": return "Відмовився від підписання протоколу"
                    case "2": return "Відмовився від укладання договору"
                    case "3": return "Не надав обов’язкові документи або відомості /надав неправдиві відомості"
                    case "4": return "Не сплатив належну суму за придбаний лот та/або суму витрат на підготовку лота до продажу"
                    case "5": return "Не відповідає встановленим Кодексом вимогам"
                    case "6": return "Не отримав необхідне погодження ВРУ або КМУ"
                    default: return data
                }
            case isBSE(props.auctionId.sellingMethod):
            case isBSD(props.auctionId.sellingMethod):
            case isALE(props.auctionId.sellingMethod):
            case isCSE(props.auctionId.sellingMethod):
            case isCSD(props.auctionId.sellingMethod):
            case isBRE(props.auctionId.sellingMethod):
            case isBRD(props.auctionId.sellingMethod):
            case isBRW(props.auctionId.sellingMethod):
            case isRLE(props.auctionId.sellingMethod):
            case isRLD(props.auctionId.sellingMethod):
            case isCLE(props.auctionId.sellingMethod):
            case isCLD(props.auctionId.sellingMethod):
                switch(data){
                    case "1": return "Відмова або непідписання протоколу"
                    case "2": return "Відмова або непідписання договору"
                    case "3": return "Відсутні обов’язкові документи"
                    case "4": return "Невнесення передоплати або оплати за договором"
                    case "5": return "Участь в аукціоні з більше, ніж 1 майданчика"
                    default: return data
                }
            default:
                switch(data){
                    case '1': return 'Відмовився від підписання протоколу'
                    case '2': return 'Невідповідність встановленим вимогам'
                    case '3': return 'Відмовився від підписання договору купівлі-продажу лота'
                    case '4': return 'Не підписав договір купівлі-продажу лота в установлений строк'
                    case '5': return 'Вчасно не здійснив передоплату за договором купівлі-продажу лота'
                    default: return data
                }
        }
    }
}

function viewStatus(props, data){
    if(data.status !== null && data.status !== '' && data.status !== 'null'){
        return (
            <TextHalf
                title='Статус'
                data={viewStatusAwards(props, data.status)}
            />
        )
    }
    return null
}

function viewStatusAwards(props, status){
    switch(status){
        case 'pending':
            return 'Очікується протокол'
        case 'pending_waiting':
            return 'Очікується рішення'
        case 'active':
            if(props.auctionId.status === "complete"){
                return 'Переможець. Аукціон завершено. Договір підписано'
            }else{
                return 'Переможець. Очікується договір'
            }
        case 'unsuccessful':
            return 'Дискваліфіковано'
        case 'cancelled':
            return 'Учасник не став переможцем'
    }
    return null
}

function viewAddress(data){
    if(createAddress(data) !== null){
        return (
            <TextHalf
                title='Юридична адреса'
                data={createAddress(data)}
            />
        )
    }
    return null
}

function createAddress(i){
    let tmp = null
    if(i.buyers !== null){
        if(i.buyers !== null){
            if(i.buyers.length > 0){
                tmp = i.buyers[0].address.countryName.uk_UA + ', ' + i.buyers[0].address.postalCode + ', ' + i.buyers[0].address.locality.uk_UA + ', ' + i.buyers[0].address.region.uk_UA + ', ' + i.buyers[0].address.streetAddress.uk_UA
            }
        }
    }
    return tmp
}

function dataViewVerificationPeriod(data){
    if(data.hasOwnProperty('verificationPeriod')){
        if(data.verificationPeriod !== null){
            let tmp = `${modifiDateString(data.verificationPeriod.startDate, 3)} - ${modifiDateString(data.verificationPeriod.endDate, 3)}`
            return (
                <TextHalf
                    title='Термін на завантаження та підтвердження протоколу'
                    data={tmp}
                />
            )
        }
    }
    return null
}

function dataViewSigningPeriod(data){
    if(data.hasOwnProperty('signingPeriod')){
        if(data.signingPeriod !== null){
            let tmp = `${modifiDateString(data.signingPeriod.startDate, 3)} - ${modifiDateString(data.signingPeriod.endDate, 3)}`
            return (
                <TextHalf
                    title='Термін на завантаження та підтвердження договору'
                    data={tmp}
                />
            )
        }
    }
    return null
}

function viewContractDocuments(props, data, count, setPopAddDoc){
    if(data !== null){
        if (data.documents !== null) {
            let TmpBidDocs = viewDocAwardBid(props, data)
            let tmpArr =  TmpBidDocs !== null ? TmpBidDocs.length : null
            if((data.documents !== null && data.documents.length > 0) || (TmpBidDocs !== null && TmpBidDocs.length > 0)){// || viewDocAwardBidCount(props, data) !== null){
                let docOrg = null
                let docUch = null
                if(data.documents.length !== 0) docOrg = <div className={styles.blockHeader}>Документи організатора:</div>
                if(viewDocAwardBid(props, data) !== null && viewDocAwardBid(props, data).length !== 0) docUch = <div className={styles.blockHeader}>Документи учасника:</div>
                return <PopDown
                    title={`Додані документи (${data.documents.length + tmpArr})`}
                    content={
                        <>
                            {docOrg}
                            {viewDocContract(data, count, props, setPopAddDoc)}
                            {docUch}
                            {viewDocAwardBid(props, data)}
                        </>
                    }
                />
            }
        }
    }
    return null
}

function viewDocContract(data, countContract, props, setPopAddDoc) { // документы организатора
    
    function dataECPMapper(data, t) {
        let tmp = data
            .filter(
            (item) => {
                return item.documentType === 'digitalSignature' && item.relatedDocument === t.id
            })
        if (tmp.length === 0) return null
        return tmp
    }
    
    if(data !== null){
        if(data === undefined || data === 'undefined'){
        }else{
            let tmp = data.documents
            // .filter(
            //     (t) => {
            //         return t.documentType !== 'digitalSignature'
            //     }
            // )
            .map(
                (t) => {

                        const getHistoryURL = (props, data) => {
                            if (t.documentOf === 'award') {
                                    return `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/public/history/auction/${props.auctionId.id}/awards/${data.id}/documents`
                                }
                                if (t.documentOf === 'bid') {
                                    return `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/public/history/auction/${props.auctionId.id}/bids/${data.bidId}/documents`
                                }
                        }
                        
                        const getBidOwner = (props, data) => {
                            if (props.auctionId.privateData.hasBids) {
                                for (let key in props.auctionId.privateData.bids) {
                                    if (data.bidId === props.auctionId.privateData.bids[key].bidId) return true
                                }
                            }
                            return false
                        }
                     
                        let tmp = null
                        const docScopeData = {}
                        docScopeData.aucId = props.auctionId.id
                        docScopeData.aucStatus = props.auctionId.status
                        docScopeData.aucType = props.auctionId.sellingMethod
                        docScopeData.reload = () => {
                            if (!props.auctionId.privateData.isOwner) getUpdateDataBid(props, props.auctionsAllBids.arr.id)
                            getUpdateDataAuction(props, props.auctionId.id)
                        }
                        docScopeData.aucOwner = props.auctionId.privateData.isOwner
                        docScopeData.historyUrl = getHistoryURL(props, data)
                        
                           
                        docScopeData.bidOwner = getBidOwner( props, data)
                        docScopeData.docTypesList = setAwardDocTypes(props, data)
                        docScopeData.awardStatus = data.status
                        docScopeData.docList = data.documents

                        if(props.auctionId.privateData.isOwner){
                            docScopeData.typeOf = 'award'
                            docScopeData.uploadNewDocURL = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction/${props.auctionId.id}/award/${data.id}/documents`
                            docScopeData.patchNewDocFilesURL = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction/${props.auctionId.id}/award/${data.id}/documents`
                            docScopeData.patchNewDocWoutFilesURL = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction/${props.auctionId.id}/award/${data.id}/documents`
                        }
                        // else if (props.auctionId.privateData.hasBids && docScopeData.bidOwner) {
                        //     docScopeData.typeOf = 'bidAward'
                        //     docScopeData.uploadNewDocURL = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/bid/${props.auctionId.privateData.bids[0].id}/documents`
                        //     docScopeData.patchNewDocFilesURL = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/bid/${props.auctionId.privateData.bids[0].id}/documents`
                        //     docScopeData.patchNewDocWoutFilesURL = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/bid/${props.auctionId.privateData.bids[0].id}/documents`
                        // }
                        // else {
                        //     docScopeData.typeOf = 'bidAward'
                        // }
                    
                        return (
                            <DocItem
                                data={t}
                                scopeData={docScopeData}
                                dataECP={ dataECPMapper(data.documents, t) }
                                key={t.id}
                            />
                        )
                }
            )
            return tmp
        }
    }
}

function viewDocAwardBid(props, data) { //документы участника
    const getBidOwner = (props, data) => {
        if (props.auctionId.privateData.hasBids) {
            for (let key in props.auctionId.privateData.bids) {
                if (data.bidId === props.auctionId.privateData.bids[key].bidId) return true
            }
        }
        return false
    }

    function dataECPMapper(data, t) {
        let tmp = data
            .filter(
            (item) => {
                return item.documentType === 'digitalSignature' &&  item.relatedDocument === t.documentId
            })
        if (tmp.length === 0) return null
        return tmp
    }

    //TODO: Пофиксить вывод документов бида
    let tmpArr = null
    let ecpArr = null
    if (checkProp(props.auctionId, 'bids') && props.auctionId.bids.length > 0) {
        for (let key in props.auctionId.bids) {
            if (props.auctionId.bids[key].id === data.bidId) {
                if (checkProp(props.auctionId.bids[key], 'documents') && props.auctionId.bids[key].documents.length > 0) {
                    // ecpArr = props.auctionId.bids[key].documents
                    tmpArr = props.auctionId.bids[key].documents.filter(
                        (t) => {
                            return t.documentType === 'auctionProtocol'
                                // || t.documentType === "digitalSignature"
                                            }
                    )
                    break
                }
            }
        }
    }
    
    if (tmpArr !== null) {
        let tmp = tmpArr.map(
            (t) => {
                const docScopeData = {}
                docScopeData.aucId = props.auctionId.id
                docScopeData.aucStatus = props.auctionId.status
                docScopeData.aucType = props.auctionId.sellingMethod
                docScopeData.reload = () => {
                    // await getUpdateDataBid(props, props.auctionId.privateData.bids[0].id)
                    if (!props.auctionId.privateData.isOwner) getUpdateDataBid(props, props.auctionsAllBids.arr.id)
                    getUpdateDataAuction(props, props.auctionId.id)
                }
                docScopeData.aucOwner = props.auctionId.privateData.isOwner
                
                docScopeData.bidOwner = getBidOwner( props, data)
                docScopeData.docTypesList = setAwardDocTypes(props, data)
                docScopeData.awardStatus = data.status
                docScopeData.typeOf = 'bidAward'
                docScopeData.docList = data.documents

                function getDocId(id){
                    for (let key in props.auctionsAllBids.arr.documents) {
                        if (props.auctionsAllBids.arr.documents[key].documentId === id) return props.auctionsAllBids.arr.documents[key].id
                     }
                }

                docScopeData.historyUrl = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/public/history/auction/${props.auctionId.id}/bids/${data.bidId}/documents`
                if (checkProp(props.auctionId.privateData, 'bids') && props.auctionId.privateData.bids.length > 0 ){
                    docScopeData.patchNewDocFilesURL = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/bid/${props.auctionId.privateData.bids[0].id}/documents/${getDocId(t.id)}`
                    docScopeData.patchNewDocWoutFilesURL = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/bid/${props.auctionId.privateData.bids[0].id}/documents/${getDocId(t.id)}`
                }
                return (
                    <>
                        <DocItem
                            data={t}
                            scopeData={docScopeData}
                            key={t.id}
                            dataECP={dataECPMapper(tmpArr, t)}
                        />
                    </>
                )
            }
        )
        return tmp
    }
    return null
}

//////////////////////////////////////////
function viewDocAwardBidKEP(data, countContract, props, relatedDocument){
    if(data !== null){
        if(data === undefined || data === 'undefined'){
        }else{
            let tmp = data.bid.documents
            .filter(
                (t) => {
                    return t.documentType === "digitalSignature"
                }
            )
            .filter(
                (t) => {
                    return t.relatedDocument === relatedDocument
                }
            )
            .map(
                (t, count) => {
                    let datePublished = null
                    let dateModified = null
                    if (t.datePublished !== null) {
                        datePublished = modifiDateString(t.datePublished, 3, false)
                    }
                    if (t.dateModified !== null) {
                        dateModified = modifiDateString(t.dateModified, 3, false)
                    }
                    let tmpURL = `${t.url}`
                    if (t.hasOwnProperty('token') === true) {
                        if (t.token !== null) {
                            if (t.token !== '') {
                                tmpURL = `${t.url}?token=${t.token}`
                            }
                        }
                    }
                    let tmpDataBid = null

                    if(data.bid.createdByCurrentUser === true){
                        tmpDataBid = () => {
                                props.changeContractApprowePopUpStatus({
                                aucId: props.auctionId.id,
                                // contractId: data.id,
                                sellingMethod: props.auctionId.sellingMethod,
                                count: countContract,
                                fullAuction: data,
                                typeView: 3,
                                currentUser: data
                            })
                            props.setContractDocId(t.id)
                            props.setContractDocTitle(t.title)
                            props.setContractDocType(t.documentType)
                            props.setContractDocDescription(t.description)
                            //props.setContractDocDocumentOf(t.documentOf)
                            props.setDataAddress(t.documentOf, 'SET_TYPE_DOCUMENT_OF')
                        }
                    }
                    return (
                        <DocItem
                            data={t}
                            // scopeData={docScopeData}
                            // dataFuncOpenPopUp={setPopAddDoc}
                            awardData={data}
                            key={count}
                        />
                    )
                }
            )
            return tmp
        }
    }
}

function viewApprove(props, i, count){
    if(i.status !== 'active'){
        if(
            !isLRE(props.auctionId.sellingMethod) &&
            !isLSE(props.auctionId.sellingMethod) &&
            !isLSP( props.auctionId.sellingMethod)
        ){
            if(
                props.auctionId.status !== 'cancelled' &&
                props.auctionId.status !== 'unsuccessful' &&
                props.auctionId.status !== 'complete' &&
                i.status !== 'cancelled' &&
                i.status !== 'unsuccessful' //&&
                //i.status !== 'active'
            ){
                if(props.auctionId.privateData.bids !== null && props.auctionId.privateData.bids.length > 0){
                    if(i.bidId === props.auctionId.privateData.bids[0].bidId){
                        if(i.status === 'pending_waiting'){
                            return (
                                <div
                                    className={styles.btnAccept}
                                    onClick={
                                        () => {
                                            props.changeAwardRejectPopUpStatus({
                                                aucId: props.auctionId.id,
                                                awardId: i.id,
                                                status: i.status,
                                                awards: count,
                                                sellingMethod: props.auctionId.sellingMethod
                                            })
                                            /*props.changeContractApprowePopUpStatus({
                                                aucId: props.auctionsGetId.id,
                                                contractId: i.id,
                                                sellingMethod: props.auctionsGetId.sellingMethod,
                                                count: count,
                                                fullAuction: props.auctionsGetId,
                                                typeView: 4,
                                                bid: i
                                            })*/
                                        }
                                    }
                                >
                                    Відмовитись від очікування
                                </div>
                            )
                        }
                    }
                }else{
                    if(i.documents !== null){
                        if(i.documents.length > 0){
                            return (
                                <div
                                    className={styles.btnAccept}
                                    onClick={
                                        () => {
                                            //props.changeAwardRejectPopUpStatus({aucId: props.auctionId.id, awardId: i.id, status: i.status, awards: count, sellingMethod: props.auctionId.sellingMethod})
                                            props.changeAwardRejectPopUpStatus({
                                                aucId: props.auctionId.id,
                                                awardId: i.id,
                                                status: i.status,
                                                awards: count,
                                                sellingMethod: props.auctionId.sellingMethod
                                            })
                                            /*props.changeContractApprowePopUpStatus({
                                                aucId: props.auctionsGetId.id,
                                                contractId: i.id,
                                                sellingMethod: props.auctionsGetId.sellingMethod,
                                                count: count,
                                                fullAuction: props.auctionsGetId,
                                                typeView: 4,
                                                bid: i
                                            })*/
                                        }
                                    }
                                >
                                    Дії з учасником
                                </div>
                            )
                        }
                    }
                }
            }
            return (
                <div className={styles.btnDecline}>
                    Дії з учасником
                </div>
            )
        }else{
            if(
                props.auctionId.status !== 'cancelled' &&
                props.auctionId.status !== 'unsuccessful' &&
                props.auctionId.status !== 'complete' &&
                i.status !== 'cancelled' &&
                i.status !== 'unsuccessful' //&&
                //i.status !== 'active'
            ){
                if(i.documents !== null){
                    if(i.documents.length > 0){
                        if(i.status !== 'active'){
                            return (
                                <div
                                    className={styles.btnAccept}
                                    onClick={
                                        () => {
                                            props.changeAwardRejectPopUpStatus({
                                                aucId: props.auctionId.id,
                                                awardId: i.id,
                                                status: i.status,
                                                awards: count,
                                                sellingMethod: props.auctionId.sellingMethod
                                            })
                                        }
                                    }
                                >
                                    Дії з учасником
                                </div>
                            )
                        }else{
                            if(props.auctionId.privateData.isOwner === true){
                                //Тут если статус актив надо взять время аварда и проверить время создания документов если есть док с временем больше аварда открыть кнопку
                                let tmpDataAward = ''
                                if(i.dateModified !== null){
                                    let tmpNow = new Date(i.datePublished)
                                    tmpDataAward = tmpNow.getTime()
                                }
                                for(let tm = 0; tm < i.documents.length; tm++){
                                    let tmpDoc = new Date(i.documents[tm].datePublished)
                                    if(
                                        i.documents[tm].documentType === 'rejectionProtocol' || 
                                        i.documents[tm].documentType === 'act'
                                    ){
                                        if(tmpDataAward < tmpDoc.getTime()){
                                            return (
                                                <div
                                                    className={styles.btnAccept}
                                                    onClick={
                                                        () => {
                                                            /*props.changeAwardRejectPopUpStatus({
                                                                aucId: props.auctionId.id,
                                                                awardId: i.id,
                                                                status: i.status,
                                                                awards: count,
                                                                sellingMethod: props.auctionId.sellingMethod
                                                            })*/
                                                            props.changeAwardRejectPopUpStatus({
                                                                aucId: props.auctionId.id,
                                                                awardId: i.id,
                                                                status: i.status,
                                                                awards: count,
                                                                sellingMethod: props.auctionId.sellingMethod
                                                            })
                                                        }
                                                    }
                                                >
                                                    Дії з учасником
                                                </div>
                                            )
                                        }
                                    }
                                }
                            }
                            return (
                                <div className={styles.btnDecline}>
                                    Дії з учасником
                                </div>
                            )
                            
                        }
                    //}else if(i.bid.createdByCurrentUser === true){
                    }else if(!props.auctionId.privateData.isOwner && (props.auctionId.privateData.bids[0].bidId === i.bidId)){
                        return (
                            <div
                                className={styles.btnAccept}
                                onClick={
                                    () => {
                                        /*props.changeAwardRejectPopUpStatus({
                                            aucId: props.auctionId.id,
                                            awardId: i.id,
                                            status: i.status,
                                            awards: count,
                                            sellingMethod: props.auctionId.sellingMethod
                                        })*/
                                        props.changeAwardRejectPopUpStatus({
                                            aucId: props.auctionId.id,
                                            awardId: i.id,
                                            status: i.status,
                                            awards: count,
                                            sellingMethod: props.auctionId.sellingMethod
                                        })
                                    }
                                }
                            >
                                Відмовитись від очікування
                            </div>
                        )
                    }
                //}else if(i.bid.createdByCurrentUser === true){
                }else if(!props.auctionId.privateData.isOwner && (props.auctionId.privateData.bids[0].bidId === i.bidId)){
                    return (
                        <div
                            className={styles.btnAccept}
                            onClick={
                                () => {
                                    props.changeAwardRejectPopUpStatus({
                                        aucId: props.auctionId.id,
                                        awardId: i.id,
                                        status: i.status,
                                        awards: count,
                                        sellingMethod: props.auctionId.sellingMethod
                                    })
                                }
                            }
                        >
                            Відмовитись від очікування
                        </div>
                    )
                }
            }else{
                if(openButtonWithContract(props, i) === true){
                    if(i.documents !== null){
                        if(i.documents.length > 0){
                            return (
                                <div
                                    className={styles.btnAccept}
                                    onClick={
                                        () => {
                                            props.changeAwardRejectPopUpStatus({
                                                aucId: props.auctionId.id,
                                                awardId: i.id,
                                                status: i.status,
                                                awards: count,
                                                sellingMethod: props.auctionId.sellingMethod
                                            })
                                            /*props.changeContractApprowePopUpStatus({
                                                aucId: props.auctionsGetId.id,
                                                contractId: i.id,
                                                sellingMethod: props.auctionsGetId.sellingMethod,
                                                count: count,
                                                fullAuction: props.auctionsGetId,
                                                typeView: 4,
                                                bid: i
                                            })*/
                                        }
                                    }
                                >
                                    Дії з учасником
                                </div>
                            )
                        //}else if(i.bid.createdByCurrentUser === true){
                        }else if(props.auctionId.privateData.bids[0].bidId === i.bidId){
                            return (
                                <div
                                    className={styles.btnAccept}
                                    onClick={
                                        () => {
                                            props.changeAwardRejectPopUpStatus({
                                                aucId: props.auctionId.id,
                                                awardId: i.id,
                                                status: i.status,
                                                awards: count,
                                                sellingMethod: props.auctionId.sellingMethod
                                            })
                                            /*props.changeContractApprowePopUpStatus({
                                                aucId: props.auctionsGetId.id,
                                                contractId: i.id,
                                                sellingMethod: props.auctionsGetId.sellingMethod,
                                                count: count,
                                                fullAuction: props.auctionsGetId,
                                                typeView: 4,
                                                bid: i
                                            })*/
                                        }
                                    }
                                >
                                    Відмовитись від очікування
                                </div>
                            )
                        }
                    //}else if(i.bid.createdByCurrentUser === true){
                    }else if(props.auctionId.privateData.bids[0].bidId === i.bidId){
                        return (
                            <div
                                className={styles.btnAccept}
                                onClick={
                                    () => {
                                        props.changeAwardRejectPopUpStatus({
                                            aucId: props.auctionId.id,
                                            awardId: i.id,
                                            status: i.status,
                                            awards: count,
                                            sellingMethod: props.auctionId.sellingMethod
                                        })
                                        /*props.changeContractApprowePopUpStatus({
                                            aucId: props.auctionsGetId.id,
                                            contractId: i.id,
                                            sellingMethod: props.auctionsGetId.sellingMethod,
                                            count: count,
                                            fullAuction: props.auctionsGetId,
                                            typeView: 4,
                                            bid: i
                                        })*/
                                    }
                                }
                            >
                                Відмовитись від очікування
                            </div>
                        )
                    }
                }
            }
            return (
                <div className={styles.btnDecline}>
                    Дії з учасником
                </div>
            )
        }
    }else{
        let tmp = false
        for(let tt = 0; tt < i.documents.length; tt++){
            if(i.documents[tt].documentType === 'rejectionProtocol' || i.documents[tt].documentType === 'act'){
                tmp = true
            }
        }
        if(tmp){
            if(
                !isLRE(props.auctionId.sellingMethod) &&
                !isLSE(props.auctionId.sellingMethod) &&
                !isLSP( props.auctionId.sellingMethod)
            ){
                if(
                    props.auctionId.status !== 'cancelled' &&
                    props.auctionId.status !== 'unsuccessful' &&
                    props.auctionId.status !== 'complete' &&
                    i.status !== 'cancelled' &&
                    i.status !== 'unsuccessful' //&&
                    //i.status !== 'active'
                ){
                    if(props.auctionId.privateData.bids !== null && props.auctionId.privateData.bids.length > 0){
                        if(i.bidId === props.auctionId.privateData.bids[0].bidId){
                            if(i.status === 'pending_waiting'){
                                return (
                                    <div
                                        className={styles.btnAccept}
                                        onClick={
                                            () => {
                                                props.changeAwardRejectPopUpStatus({
                                                    aucId: props.auctionId.id,
                                                    awardId: i.id,
                                                    status: i.status,
                                                    awards: count,
                                                    sellingMethod: props.auctionId.sellingMethod
                                                })
                                                /*props.changeContractApprowePopUpStatus({
                                                    aucId: props.auctionsGetId.id,
                                                    contractId: i.id,
                                                    sellingMethod: props.auctionsGetId.sellingMethod,
                                                    count: count,
                                                    fullAuction: props.auctionsGetId,
                                                    typeView: 4,
                                                    bid: i
                                                })*/
                                            }
                                        }
                                    >
                                        Відмовитись від очікування
                                    </div>
                                )
                            }
                        }
                    }else{
                        if(i.documents !== null){
                            if(i.documents.length > 0){
                                return (
                                    <div
                                        className={styles.btnAccept}
                                        onClick={
                                            () => {
                                                //props.changeAwardRejectPopUpStatus({aucId: props.auctionId.id, awardId: i.id, status: i.status, awards: count, sellingMethod: props.auctionId.sellingMethod})
                                                props.changeAwardRejectPopUpStatus({
                                                    aucId: props.auctionId.id,
                                                    awardId: i.id,
                                                    status: i.status,
                                                    awards: count,
                                                    sellingMethod: props.auctionId.sellingMethod
                                                })
                                                /*props.changeContractApprowePopUpStatus({
                                                    aucId: props.auctionsGetId.id,
                                                    contractId: i.id,
                                                    sellingMethod: props.auctionsGetId.sellingMethod,
                                                    count: count,
                                                    fullAuction: props.auctionsGetId,
                                                    typeView: 4,
                                                    bid: i
                                                })*/
                                            }
                                        }
                                    >
                                        Дії з учасником
                                    </div>
                                )
                            }
                        }
                    }
                }
                return (
                    <div className={styles.btnDecline}>
                        Дії з учасником
                    </div>
                )
            }else{
                if(
                    props.auctionId.status !== 'cancelled' &&
                    props.auctionId.status !== 'unsuccessful' &&
                    props.auctionId.status !== 'complete' &&
                    i.status !== 'cancelled' &&
                    i.status !== 'unsuccessful' //&&
                    //i.status !== 'active'
                ){
                    if(i.documents !== null){
                        if(i.documents.length > 0){
                            if(i.status !== 'active'){
                                return (
                                    <div
                                        className={styles.btnAccept}
                                        onClick={
                                            () => {
                                                props.changeAwardRejectPopUpStatus({
                                                    aucId: props.auctionId.id,
                                                    awardId: i.id,
                                                    status: i.status,
                                                    awards: count,
                                                    sellingMethod: props.auctionId.sellingMethod
                                                })
                                            }
                                        }
                                    >
                                        Дії з учасником
                                    </div>
                                )
                            }else{
                                if(props.auctionId.privateData.isOwner === true){
                                    //Тут если статус актив надо взять время аварда и проверить время создания документов если есть док с временем больше аварда открыть кнопку
                                    let tmpDataAward = ''
                                    if(i.dateModified !== null){
                                        let tmpNow = new Date(i.datePublished)
                                        tmpDataAward = tmpNow.getTime()
                                    }
                                    for(let tm = 0; tm < i.documents.length; tm++){
                                        let tmpDoc = new Date(i.documents[tm].datePublished)
                                        if(
                                            i.documents[tm].documentType === 'rejectionProtocol' || 
                                            i.documents[tm].documentType === 'act'
                                        ){
                                            if(tmpDataAward < tmpDoc.getTime()){
                                                return (
                                                    <div
                                                        className={styles.btnAccept}
                                                        onClick={
                                                            () => {
                                                                /*props.changeAwardRejectPopUpStatus({
                                                                    aucId: props.auctionId.id,
                                                                    awardId: i.id,
                                                                    status: i.status,
                                                                    awards: count,
                                                                    sellingMethod: props.auctionId.sellingMethod
                                                                })*/
                                                                props.changeAwardRejectPopUpStatus({
                                                                    aucId: props.auctionId.id,
                                                                    awardId: i.id,
                                                                    status: i.status,
                                                                    awards: count,
                                                                    sellingMethod: props.auctionId.sellingMethod
                                                                })
                                                            }
                                                        }
                                                    >
                                                        Дії з учасником
                                                    </div>
                                                )
                                            }
                                        }
                                    }
                                }
                                return (
                                    <div className={styles.btnDecline}>
                                        Дії з учасником
                                    </div>
                                )
                                
                            }
                        //}else if(i.bid.createdByCurrentUser === true){
                        }else if(!props.auctionId.privateData.isOwner && (props.auctionId.privateData.bids[0].bidId === i.bidId)){
                            return (
                                <div
                                    className={styles.btnAccept}
                                    onClick={
                                        () => {
                                            /*props.changeAwardRejectPopUpStatus({
                                                aucId: props.auctionId.id,
                                                awardId: i.id,
                                                status: i.status,
                                                awards: count,
                                                sellingMethod: props.auctionId.sellingMethod
                                            })*/
                                            props.changeAwardRejectPopUpStatus({
                                                aucId: props.auctionId.id,
                                                awardId: i.id,
                                                status: i.status,
                                                awards: count,
                                                sellingMethod: props.auctionId.sellingMethod
                                            })
                                        }
                                    }
                                >
                                    Дії з учасником
                                </div>
                            )
                        }
                    //}else if(i.bid.createdByCurrentUser === true){
                    }else if(!props.auctionId.privateData.isOwner && (props.auctionId.privateData.bids[0].bidId === i.bidId)){
                        return (
                            <div
                                className={styles.btnAccept}
                                onClick={
                                    () => {
                                        props.changeAwardRejectPopUpStatus({
                                            aucId: props.auctionId.id,
                                            awardId: i.id,
                                            status: i.status,
                                            awards: count,
                                            sellingMethod: props.auctionId.sellingMethod
                                        })
                                    }
                                }
                            >
                                Дії з учасником
                            </div>
                        )
                    }
                }else{
                    if(openButtonWithContract(props, i) === true){
                        if(i.documents !== null){
                            if(i.documents.length > 0){
                                return (
                                    <div
                                        className={styles.btnAccept}
                                        onClick={
                                            () => {
                                                props.changeAwardRejectPopUpStatus({
                                                    aucId: props.auctionId.id,
                                                    awardId: i.id,
                                                    status: i.status,
                                                    awards: count,
                                                    sellingMethod: props.auctionId.sellingMethod
                                                })
                                                /*props.changeContractApprowePopUpStatus({
                                                    aucId: props.auctionsGetId.id,
                                                    contractId: i.id,
                                                    sellingMethod: props.auctionsGetId.sellingMethod,
                                                    count: count,
                                                    fullAuction: props.auctionsGetId,
                                                    typeView: 4,
                                                    bid: i
                                                })*/
                                            }
                                        }
                                    >
                                        Дії з учасником
                                    </div>
                                )
                            //}else if(i.bid.createdByCurrentUser === true){
                            }else if(!props.auctionId.privateData.isOwner && (props.auctionId.privateData.bids[0].bidId === i.bidId)){
                                return (
                                    <div
                                        className={styles.btnAccept}
                                        onClick={
                                            () => {
                                                props.changeAwardRejectPopUpStatus({
                                                    aucId: props.auctionId.id,
                                                    awardId: i.id,
                                                    status: i.status,
                                                    awards: count,
                                                    sellingMethod: props.auctionId.sellingMethod
                                                })
                                                /*props.changeContractApprowePopUpStatus({
                                                    aucId: props.auctionsGetId.id,
                                                    contractId: i.id,
                                                    sellingMethod: props.auctionsGetId.sellingMethod,
                                                    count: count,
                                                    fullAuction: props.auctionsGetId,
                                                    typeView: 4,
                                                    bid: i
                                                })*/
                                            }
                                        }
                                    >
                                        Дії з учасником
                                    </div>
                                )
                            }
                        //}else if(i.bid.createdByCurrentUser === true){
                        }else if(!props.auctionId.privateData.isOwner && (props.auctionId.privateData.bids[0].bidId === i.bidId)){
                            return (
                                <div
                                    className={styles.btnAccept}
                                    onClick={
                                        () => {
                                            props.changeAwardRejectPopUpStatus({
                                                aucId: props.auctionId.id,
                                                awardId: i.id,
                                                status: i.status,
                                                awards: count,
                                                sellingMethod: props.auctionId.sellingMethod
                                            })
                                            /*props.changeContractApprowePopUpStatus({
                                                aucId: props.auctionsGetId.id,
                                                contractId: i.id,
                                                sellingMethod: props.auctionsGetId.sellingMethod,
                                                count: count,
                                                fullAuction: props.auctionsGetId,
                                                typeView: 4,
                                                bid: i
                                            })*/
                                        }
                                    }
                                >
                                    Дії з учасником
                                </div>
                            )
                        }
                    }
                }
                return (
                    <div className={styles.btnDecline}>
                        Дії з учасником
                    </div>
                )
            }
        }
    }
    return null
}

function openButtonWithContract(props, data){
    if(props.contractAuctionIdGet !== null){
        if(props.contractAuctionIdGet.length > 0){
            for(let i = 0; i < props.contractAuctionIdGet.length; i++){
                if(props.contractAuctionIdGet[i].award !== null){
                    if(props.contractAuctionIdGet[i].award.id === data.id){
                        if(
                            props.contractAuctionIdGet[i].status === 'signed' &&
                            props.contractAuctionIdGet[i].lotPaymentConfirmation === false
                        ) return true
                    }
                }
            }
        }   
    }
    return false
}

const mapStateToProps = state => {
    return {
        token: state.start.token,
        langFlag: state.start.langFlag,
        auctionAwards: state.start.auctionAwards,
        allUserPopUp: state.start.allUserPopUp,
        allUserPopUpUser: state.start.allUserPopUpUser,
        contractAuctionIdGet: state.start.contractAuctionIdGet,
        timberContractPut: state.start.timberContractPut,
        pageNumber: state.start.pageNumber,
        auctionsGetId: state.start.auctionsGetId,
        auctionsAllBids: state.start.auctionsAllBids,
        // history: state.start.history,
        //auctionId: state.start.auctionsGetId,
        auctionId: state.start.auctionFull,
        bidEdit: state.start.bidEdit,
        userProfiles: state.start.userProfiles
        // historyDocsContracts: state.start.historyDocsContracts,
    }
}

const mapDispatchToProps = {
    changeAwardRejectPopUpStatus,
    setAwardAllUserPopUp,
    setAwardAllUserPopUpUser,

    changeContractApprowePopUpStatus,

    setContractTitle,
    setContractDescription,
    setContractContractNumber,
    setContractDateFrom,
    setContractDateTill,
    setContractStartDate,
    setContractEndDate,
    setContractCurrency,
    setContractAmount,
    setContractDateSigned,

    historyAuctions,
    historyAwards,
    historyContrasts,
    historyBids,

    setUniversalError,
    changeLoader,
    contractsAuctionIdGet,
    setContractDatePaid,

    setContractDocId,
    setContractDocTitle,
    setContractDocType,
    setContractDocDescription,
    setContractDocDocumentOf,
    setDataAddress,
    saveArrBids
}

export default connect(mapStateToProps, mapDispatchToProps)(Contract)
