import React from 'react';
import styles from './windowAuction.module.css';

import {connect} from 'react-redux';

import {changeCreateAuctionAccessDetails} from '../../../redux/actions.js';

import InputText from '../../elements/inputs/input';

import TextField from '@material-ui/core/TextField';
import {TranslationsUk} from '../../../redux/translations.uk.js';

import {disabledElement} from '../disabled'
import { isMongo } from '../../../redux/check';
import { isBRD, isBRE, isALE, isBSD, isBSE, isCSD, isCSE, isLRE, isLSE, isLSP, isAAE } from '../../../redux/procedure';

const WindowAuctionAccessDetails = ( props ) => {
    return setElem(props)

}

function setElem(props){
    if(
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-fast" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-fast-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-fast-auction-manual-qualification" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-auction" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-auction-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-qualification" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-qualification-prod" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-fast" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-fast-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-fast-auction-manual-qualification" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-initial-auction" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-initial-auction-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-initial-qualification-prod" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-auction-manual-qualification" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification-prod"
    ){
        if(props.typeElem === 1){
            return (
                <InputText
                    disabled={disabledElement(props.auctionsMy, '00')}
                    label={viewNameInput(props)}
                    value={isMongo(props.auctionsMy.accessDetails)}
                    onChange={
                        (e) => {
                            props.changeCreateAuctionAccessDetails(e)
                        }
                    }
                />
            )
        }else{
            return (
                <div className={styles.inputMax}>
                    <TextField
                        disabled={disabledElement(props.auctionsMy, '00')}
                        className={styles.inputMax}
                        id="filled-required"
                        label={viewNameInput(props)}
                        defaultValue={props.auctionsMy.accessDetails !== null ? props.auctionsMy.accessDetails.hasOwnProperty('uk_UA') ? props.auctionsMy.accessDetails.uk_UA : props.auctionsMy.accessDetails : props.auctionsMy.accessDetails}
                        variant="outlined"
                        onChange={(event)=>{
                            props.changeCreateAuctionAccessDetails(event.target.value)
                        }}
                    />
                </div>
            )
        }
    }else if(
        props.auctionsMy.sellingMethod === 'landRental-english' ||
        props.auctionsMy.sellingMethod === 'landRental-english-fast' ||
        props.auctionsMy.sellingMethod === 'landRental-english-fast-manual' ||
        props.auctionsMy.sellingMethod === 'landRental-english-fast-auction-manual-qualification' ||
        props.auctionsMy.sellingMethod === 'landRental-english-initial-auction' ||
        props.auctionsMy.sellingMethod === 'landRental-english-initial-auction-manual' ||
        props.auctionsMy.sellingMethod === 'landRental-english-initial-qualification' ||
        props.auctionsMy.sellingMethod === 'landRental-english-initial-qualification-prod' ||
        props.auctionsMy.sellingMethod === 'landSell-english' ||
        props.auctionsMy.sellingMethod === 'landSell-english-fast' ||
        props.auctionsMy.sellingMethod === 'landSell-english-fast-manual' ||
        props.auctionsMy.sellingMethod === 'landSell-english-fast-auction-manual-qualification' ||
        props.auctionsMy.sellingMethod === 'landSell-english-initial-auction' ||
        props.auctionsMy.sellingMethod === 'landSell-english-initial-auction-manual' ||
        props.auctionsMy.sellingMethod === 'landSell-english-initial-qualification' ||
        props.auctionsMy.sellingMethod === 'landSell-english-initial-qualification-prod' ||
        props.auctionsMy.sellingMethod === 'landSell-priorityEnglish' ||
        props.auctionsMy.sellingMethod === 'landSell-priorityEnglish-fast' ||
        props.auctionsMy.sellingMethod === 'landSell-priorityEnglish-fast-manual' ||
        props.auctionsMy.sellingMethod === 'landSell-priorityEnglish-fast-auction-manual-qualification' ||
        props.auctionsMy.sellingMethod === 'landSell-priorityEnglish-initial-auction' ||
        props.auctionsMy.sellingMethod === 'landSell-priorityEnglish-initial-auction-manual' ||
        props.auctionsMy.sellingMethod === 'landSell-priorityEnglish-initial-qualification' ||
        props.auctionsMy.sellingMethod === 'landSell-priorityEnglish-initial-qualification-prod'
    ){
        if(props.typeElem === 1){
            return (
                <InputText
                    disabled={disabledElement(props.auctionsMy, '00')}
                    label={viewNameInput(props)}
                    value={isMongo(props.auctionsMy.accessDetails)}
                    onChange={
                        (e) => {
                            props.changeCreateAuctionAccessDetails(e)
                        }
                    }
                />
            )
        }else{
            return (
                <div className={styles.inputMax}>
                    <TextField
                        disabled={disabledElement(props.auctionsMy, '00')}
                        className={styles.inputMax}
                        id="filled-required"
                        label={viewNameInput(props)}
                        defaultValue={props.auctionsMy.accessDetails !== null ? props.auctionsMy.accessDetails.hasOwnProperty('uk_UA') ? props.auctionsMy.accessDetails.uk_UA : props.auctionsMy.accessDetails : props.auctionsMy.accessDetails}
                        variant="outlined"
                        onChange={(event)=>{
                            props.changeCreateAuctionAccessDetails(event.target.value)
                        }}
                    />
                </div>
            )
        }
    }else{
        if(props.typeElem === 1){
            return (
                <InputText
                    disabled={disabledElement(props.auctionsMy, '00')}
                    label={viewNameInput(props)}
                    value={isMongo(props.auctionsMy.accessDetails)}
                    onChange={
                        (e) => {
                            props.changeCreateAuctionAccessDetails(e)
                        }
                    }
                />
            )
        }else{
            return (
                <div className={styles.inputMax}>
                    <TextField
                        disabled={disabledElement(props.auctionsMy, '00')}
                        className={styles.inputMax}
                        id="filled-required"
                        label={viewNameInput(props)}
                        defaultValue={props.auctionsMy.accessDetails !== null ? props.auctionsMy.accessDetails.hasOwnProperty('uk_UA') ? props.auctionsMy.accessDetails.uk_UA : props.auctionsMy.accessDetails : props.auctionsMy.accessDetails}
                        variant="outlined"
                        onChange={(event)=>{
                            props.changeCreateAuctionAccessDetails(event.target.value)
                        }}
                    />
                </div>
            )
        }
    }
}

function viewNameInput(props){
    if(
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-fast" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-fast-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-fast-auction-manual-qualification" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-auction" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-auction-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-qualification" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-qualification-prod" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-fast" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-fast-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-fast-auction-manual-qualification" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-initial-auction" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-initial-auction-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-initial-qualification-prod"
    ){
        return TranslationsUk['legitimatePropertyLease-english'].legitimatePropertyLeaseEnglishProcedure.accessDetails.legalName
    }else if(
        isCSE(props.auctionsMy.sellingMethod) || isCSD(props.auctionsMy.sellingMethod) ||
        isBRE(props.auctionsMy.sellingMethod) || isBRD(props.auctionsMy.sellingMethod)
    ){
        return "Порядок ознайомлення з майном, час і місце проведення огляду об'єкта"
    }else{
        return 'Порядок та можливий час ознайомлення з лотом'
    }
}

const mapStateToProps = state => {
    return {
        auctionsMy: state.start.auctionsMy,
        auctionsZUAttemp: state.start.auctionsZUAttemp
    }
}

const mapDispatchToProps = {
    changeCreateAuctionAccessDetails,
}

export default connect(mapStateToProps, mapDispatchToProps)(WindowAuctionAccessDetails)
