import React,{useState} from 'react'
import styles from './aucLotItem.module.css'

import { useSelector } from 'react-redux';

import { checkProp, isMongo } from '../../../../../../redux/check'

import TextFull from '../../../../../elements/viewData/textFull'
import TextHalf from '../../../../../elements/viewData/textHalf'
import PopAsk from "../../../../../_popups/popAsk/popAsk";


const AucLotItem = ({ data, edit, del}) => {

    const myAuc = useSelector( state => state.start.auctionsMy)
    const [popDelete, setPopDelete] = useState(false)

    function getContent(a) {
        if(checkProp(a, 'vehicleProps') ){
                return <>
                {
                    checkProp(a.vehicleProps, 'brand') ? 
                    <TextHalf
                        title={'Марка траспортного засобу'}
                        data={a.vehicleProps.brand} 
                    /> : null
                }
                {checkProp(a.vehicleProps, 'model')? 
                    <TextHalf
                        title={'Модель траспортного засобу'}
                        data={a.vehicleProps.model} 
                    /> : null
                }
                </>
        }
        return null
    }

    const editButton =( myAuc )=> {
        let dateNow = Date.now()
        if (myAuc.status === null
            || myAuc.status === 'null'
            //|| myAuc.status === 'active_tendering'
            || myAuc.status === "active_rectification"
        ) {
            return <div className={styles.editBtn}
                        onClick={edit}>
                        <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path className={styles.editIcon} fillRule="evenodd" clipRule="evenodd" d="M15.861 5.59598L16.9757 4.48123L14.5187 2.02416L13.4039 3.13892L15.861 5.59598ZM14.4542 7.00276L11.9971 4.54569L2.80565 13.7372L2.24014 16.7597L5.26272 16.1942L14.4542 7.00276ZM18.4419 3.13387C19.186 3.87799 19.186 5.08446 18.4419 5.82859L6.45174 17.8188C6.30989 17.9606 6.12847 18.0563 5.93129 18.0931L1.17816 18.9824C0.490832 19.111 -0.111152 18.5091 0.0174442 17.8217L0.90674 13.0686C0.943632 12.8714 1.03928 12.69 1.18113 12.5481L13.1713 0.557973C13.9154 -0.186154 15.1219 -0.186154 15.866 0.557973L18.4419 3.13387ZM18.0099 18.9999H9.9901C8.66997 18.9999 8.66997 16.9999 9.9901 16.9999H18.0099C19.33 16.9999 19.33 18.9999 18.0099 18.9999Z" fill="#92929D"/>
                        </svg>
                    </div>
        }
        return null
    }

    const delButton = <div className={styles.deleteBtn}
                        onClick={()=> { setPopDelete(true) }}>
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path className={styles.deleteIcon} fillRule="evenodd" clipRule="evenodd" d="M16.6448 14.4563C17.5006 13.1828 18 11.6496 18 9.99988C18 5.5816 14.4182 1.99988 9.99997 1.99988C8.3488 1.99988 6.81447 2.50011 5.54025 3.35729L16.6448 14.4563ZM15.3278 15.9677L4.02947 4.67499C2.76719 6.08933 1.99997 7.95501 1.99997 9.99988C1.99997 14.4182 5.58169 17.9999 9.99997 17.9999C12.0463 17.9999 13.9131 17.2316 15.3278 15.9677ZM9.99997 -0.00012207C15.5228 -0.00012207 20 4.47703 20 9.99988C20 15.5227 15.5228 19.9999 9.99997 19.9999C4.47712 19.9999 -3.05176e-05 15.5227 -3.05176e-05 9.99988C-3.05176e-05 4.47703 4.47712 -0.00012207 9.99997 -0.00012207Z" fill="#FFA047"/>
                            </svg>
                        </div>

    let tmpDescription = ''
    if(data?.classification?.description !== null) tmpDescription = isMongo(data?.classification?.description)

    function loadMainClassif(data){
        if(data === null) return null
        let idClass = null
        for(let tmp=0; tmp < data.additionalClassifications.length; tmp++){
            if(data.additionalClassifications[tmp].scheme === "kvtspz") idClass = data.additionalClassifications[tmp].id
        }
        let tmpData = null
        if(idClass !== null){
            if(idClass.indexOf('.') !== -1){
                let tmp1 = idClass.split('.')
                tmpData = tmp1[0]
            }else{
                tmpData = idClass
            }
        }
        switch(tmpData){
            case "01":
                return "CAV 06121000-6 Землі сільськогосподарського призначення"
            case "02":
                return "CAV 06111000-3 Землі житлової забудови (землі, які використовуються для розміщення житлової забудови (житлові будинки, гуртожитки, господарські будівлі та інше); землі, які використовуються для розміщення гаражного будівництва)"
            case "03":
                return "CAV 06112000-0 Землі громадської забудови (землі, які використовуються для розміщення громадських будівель і споруд (готелів, офісних будівель, торговельних будівель, для публічних виступів, для музеїв та бібліотек, для навчальних та дослідних закладів, для лікарень та оздоровчих закладів), інших об'єктів загального користування)"
            case "04":
            case "05":
                return "CAV 06122000-3 Землі природно-заповідного фонду та іншого природоохоронного призначення"
            case "06":
                return "CAV 06124000-7 Землі оздоровчого призначення"
            case "07":
                return "CAV 06124000-7 Землі рекреаційного призначення"
            case "08":
                return "CAV 06125000-4 Землі історико-культурного призначення"
            case "09":
                return "CAV 06126000-1 Землі лісогосподарського призначення"
            case "10":
                return "CAV 06127000-8 Землі водного фонду"
            case "11":
            case "12":
            case "13":
            case "14":
            case "15":
                return "CAV 06128000-5 Землі промисловості, транспорту, зв'язку, енергетики, оборони та іншого призначення"
            case "16":
            case "17":
            case "18":
            case "19":
                return "CAV 06129000-2 Землі запасу, резервного фонду та загального користування"
            default:
                return ""
        }
    }

    return (
        <div className={styles.wrapBlock}>
            <div className={styles.wrapBlockTitle}>
                {data.additionalClassifications !== null 
                    ? `${loadMainClassif(data)}`//`${data?.classification?.scheme} ${data?.classification?.id} ${tmpDescription}`
                    : "Відсутній класифікатор"}
                <div className={styles.blockBtn}>
                    {editButton(myAuc)}
                    { myAuc.status === null ? delButton : null }
                </div>
            </div>
            <div className={styles.wrapContent}>
                {checkProp(data, 'id') ? 
                    <TextHalf
                        title={'ID лота'}
                        data={data.id} 
                    /> : null
                }
                {/*checkProp(data, 'quantity')  ? 
                    <TextHalf
                        title={'Кількість'}
                        data={`${data.quantity} ${checkProp(data.unit, 'name') ? isMongo(data.unit.name) : ''}`}
                    /> : null
                */}
                {getContent(data)}
                {checkProp(data, 'description')
                    ? <TextFull
                        title={"Опис об'єкта"}
                        data={isMongo(data.description)} 
                    />
                    : null
                }
                {checkProp(data, 'address')
                    ? <TextFull
                        title={'Розташування'}
                        data={`${checkProp(data.address, 'countryName') ? isMongo(data.address.countryName) + ', ' : ''}
                        ${checkProp(data.address, 'region') ? isMongo(data.address.region) + ', ' : ''}
                        ${checkProp(data.address, 'locality') ? isMongo(data.address.locality) : ''}
                        ${checkProp(data.address, 'streetAddress') ? ', ' + isMongo(data.address.streetAddress) : ''}
                        ${checkProp(data.location, 'latitude') && checkProp(data.location, 'longitude') ? ' - ('  + data.location.latitude + ', ' + data.location.longitude +  ')' : ''}
                        `} 
                    />
                    : null
                }
            </div>
            {
                popDelete ? 
                <PopAsk
                    title={`Ви дійсно хочете видалити лот ${isMongo(data?.classification?.description) ?? '' }?`}
                    actionYes={del}
                    actionNo={()=>{setPopDelete(false)}}
                />
        : null
            }
        </div>
    )
}

export default AucLotItem