import React from 'react';
import styles from './windowAuction.module.css';

import { DateTimePicker, KeyboardDateTimePicker } from "@material-ui/pickers";

import {connect} from 'react-redux';

import {setDataAddress} from '../../../redux/createAddress';

import TextField from '@material-ui/core/TextField';
import InputDate from '../../elements/inputs/inputDate';

import {
    setKoatuuRegion, 
} from '../../../redux/actions/address/address.js';

import {disabledElement, onOffCurrentTenat} from '../disabled'

import PreviousAuctionBidderfrom from '../../product/lotInformation/auctionElements/previousAuctionBidder'

import { getRegion } from '../../../api/dictonaries/region';

const LandCurrentTenant = ( props ) => {
    if(props.koatuu.region === null){
        getKoatuuRegion(props)
    }
    if(props.auctionsMy !== null){
        if(
            props.auctionsMy.sellingMethod === 'landSell-priorityEnglish' ||
            props.auctionsMy.sellingMethod === 'landSell-priorityEnglish-fast' ||
            props.auctionsMy.sellingMethod === 'landSell-priorityEnglish-fast-manual' ||
            props.auctionsMy.sellingMethod === 'landSell-priorityEnglish-fast-auction-manual-qualification' ||
            props.auctionsMy.sellingMethod === 'landSell-priorityEnglish-initial-auction' ||
            props.auctionsMy.sellingMethod === 'landSell-priorityEnglish-initial-auction-manual' ||
            props.auctionsMy.sellingMethod === 'landSell-priorityEnglish-initial-qualification' ||
            props.auctionsMy.sellingMethod === 'landSell-priorityEnglish-initial-qualification-prod'
        ){
            let dataContractTimeStartDate = {
                targetType: 'SET_LAND_CURRENT_TENANT_CURRENT_CONTRACT_TIME_DATE_FROM',
                target: getStartDate(props),
                typePopUp: '',
                functions: props.setDataAddress,
                label: `Період з`,
                necessarily: true,
                time: true,
            }
            let dataContractTimeEndDate = {
                targetType: 'SET_LAND_CURRENT_TENANT_CURRENT_CONTRACT_TIME_DATE_TO',
                target: getEndDate(props),
                typePopUp: '',
                functions: props.setDataAddress,
                label: `Період по`,
                necessarily: true,
                time: true,
            }
            let borderLine = null
            if(props.auctionsMy.hasOwnProperty('previousAuctionBidder') === true){
                if(props.auctionsMy.previousAuctionBidder !== null){
                    borderLine = <div className={styles.borderLine}></div>
                }
            }
            return (
                <>
                    <div>Дані про учасника з переважним правом</div>
                    <div className={styles.groupElem}>
                        <div>
                            <TextField
                                disabled
                                id="filled-required"
                                className={styles.inputText}
                                label="Повна юридична назва організації або ПІБ"
                                value={getName(props)}
                                onChange={
                                    (event) => {
                                        props.setDataAddress(event.target.value, 'SET_LAND_CURRENT_TENANT_NAME')
                                    }
                                }
                                variant="outlined"
                                helperText=""
                            />
                        </div>
                    </div>
                    <div>Ідентифікатори організації або особи</div>
                    <div className={styles.groupElem}>
                        <div>
                            <TextField
                                disabled={blokCurrentTenant(props)}
                                required
                                id="filled-required"
                                className={styles.inputText}
                                label="Ідентифікатор організації"
                                value={getIdentifierIdentifier(props)}
                                onChange={
                                    (event) => {
                                        props.setDataAddress(event.target.value, 'SET_LAND_CURRENT_TENANT_IDENTIFIER_ID')
                                    }
                                }
                                variant="outlined"
                                helperText=""
                            />
                        </div>
                    </div>
                    <div className={styles.groupElem}>
                        <div>
                            <TextField
                                disabled={blokCurrentTenant(props)}
                                required
                                id="filled-required"
                                className={styles.inputText}
                                label="Повна юридична назва організації або ПІБ"
                                value={getIdentifierLegalName(props)}
                                onChange={
                                    (event) => {
                                        props.setDataAddress(event.target.value, 'SET_LAND_CURRENT_TENANT_IDENTIFIER_LEGAL_NAME')
                                    }
                                }
                                variant="outlined"
                                helperText=""
                            />
                        </div>
                    </div>
                    <div className={styles.groupElem}>
                        <div>Ідентифікатор організації</div>
                        <div>
                            <TextField
                                disabled={blokCurrentTenant(props)}
                                id="outlined-select-currency-native"
                                select
                                value={getIdentifierScheme(props)}
                                onChange={(event)=>{
                                    props.setDataAddress(event.target.value, 'SET_LAND_CURRENT_TENANT_IDENTIFIER_SCHEME')
                                }}
                                SelectProps={{
                                    native: true,
                                }}
                                variant="outlined"
                            >
                                <option key={0} selected disabled value='null'>Обрати</option>
                                <option key={1} value='UA-EDR'>ЄДРПОУ</option>
                                <option key={2} value='UA-IPN'>ІПН/РНОКПП</option>
                                <option key={3} value='UA-PASSPORT'>Паспорт</option>
                                <option key={4} value='UA-ID-CARD'>ID-карта</option>
                                <option key={5} value='UA-IPN-FOP'>ІПН/РНОКПП</option>
                            </TextField>
                        </div>
                    </div>

                    <div>Адреса</div>
                    <div className={styles.groupElem}>
                        <div>
                            <TextField
                                disabled='disabled'
                                required
                                id="filled-required"
                                className={styles.inputText}
                                label="Країна"
                                value='Україна'
                                variant="outlined"
                                helperText=""
                            />
                        </div>
                    </div>
                    <div className={styles.groupElem}>
                        <div>
                            <TextField
                                disabled={blokCurrentTenant(props)}
                                required
                                type='number'
                                id="filled-required"
                                className={styles.inputText}
                                label="Поштовий індекс"
                                value={getAddressPostalCode(props)}
                                onChange={
                                    (event) => {
                                        if(event.target.value.length <= 5){
                                            props.setDataAddress(event.target.value, 'SET_LAND_CURRENT_TENANT_ADDRESS_POSTAL_CODE')
                                        }else{
                                            let tmp = event.target.value.substring(0, 5)
                                            props.setDataAddress(tmp, 'SET_LAND_CURRENT_TENANT_ADDRESS_POSTAL_CODE')
                                        }
                                    }
                                }
                                variant="outlined"
                                helperText=""
                            />
                        </div>
                    </div>
                    <div className={styles.groupElem}>
                        <div>{viewRegion(props)}</div>
                    </div>
                    <div className={styles.groupElem}>
                        <div>
                            <TextField
                                disabled={blokCurrentTenant(props)}
                                required
                                id="filled-required"
                                className={styles.inputText}
                                label="Населений пункт"
                                value={getAddressLocality(props)}
                                onChange={
                                    (event) => {
                                        props.setDataAddress(event.target.value, 'SET_LAND_CURRENT_TENANT_ADDRESS_LOCALITY')
                                    }
                                }
                                variant="outlined"
                                helperText=""
                            />
                        </div>
                    </div>
                    <div className={styles.groupElem}>
                        <div>
                            <TextField
                                disabled={blokCurrentTenant(props)}
                                required
                                id="filled-required"
                                className={styles.inputText}
                                label="Адреса"
                                value={getAddressStreetAddress(props)}
                                onChange={
                                    (event) => {
                                        props.setDataAddress(event.target.value, 'SET_LAND_CURRENT_TENANT_ADDRESS_STREET_ADDRESS')
                                    }
                                }
                                variant="outlined"
                                helperText=""
                            />
                        </div>
                    </div>
                    
                    
                    <div className={styles.groupElem}>
                        <div>Код об’єктів адміністративно-територіального устрою України</div>
                    </div>
                    <div className={styles.groupElem}>
                        <div>
                            <TextField
                                disabled
                                required
                                id="filled-required"
                                className={styles.inputText}
                                label="Класифікатор об’єктів адміністративно-територіального устрою України"
                                value='koatuu'
                                onChange={
                                    (event) => {
                                        //props.setDataAddress(event.target.value, 'SET_LAND_CURRENT_TENANT_ADDRESS_LOCALITY')
                                    }
                                }
                                variant="outlined"
                                helperText=""
                            />
                        </div>
                    </div>
                    <div className={styles.groupElem}>
                        <div>
                            <TextField
                                disabled
                                required
                                id="filled-required"
                                className={styles.inputText}
                                label="Код адміністративно-територіальних об’єктів України"
                                value={getAddressRegionName(props)}
                                onChange={
                                    (event) => {
                                        //props.setDataAddress(event.target.value, 'SET_LAND_CURRENT_TENANT_ADDRESS_LOCALITY')
                                    }
                                }
                                variant="outlined"
                                helperText=""
                            />
                        </div>
                    </div>
                    <div className={styles.groupElem}>
                        <div>
                            <TextField
                                disabled
                                required
                                id="filled-required"
                                className={styles.inputText}
                                label="Назва адміністративно-територіальних об’єктів України"
                                value={getAddressRegionCode(props)}
                                onChange={
                                    (event) => {
                                        //props.setDataAddress(event.target.value, 'SET_LAND_CURRENT_TENANT_ADDRESS_LOCALITY')
                                    }
                                }
                                variant="outlined"
                                helperText=""
                            />
                        </div>
                    </div>






                    <div className={styles.groupElem}>
                        <div>
                            <TextField
                                disabled={blokCurrentTenant(props)}
                                required
                                id="filled-required"
                                className={styles.inputText}
                                label="Інформація щодо підтвердження повноважень"
                                value={getRepresentativeInfo(props)}
                                onChange={
                                    (event) => {
                                        props.setDataAddress(event.target.value, 'SET_LAND_CURRENT_TENANT_REPRESENTATIVE_INFO')
                                    }
                                }
                                variant="outlined"
                                helperText=""
                            />
                        </div>
                    </div>
                    {viewData(props)}
                    <div>Орендна ставка (сума на рік)</div>
                    <div className={styles.groupElem}>
                        <div>
                            <TextField
                                disabled='disabled'
                                required
                                id="filled-required"
                                className={styles.inputText}
                                label="Валюта"
                                value='гривні'
                                variant="outlined"
                                helperText=""
                            />
                        </div>
                    </div>
                    <div className={styles.groupElem}>
                        <div>
                            <TextField
                                disabled={blokCurrentTenant(props)}
                                required
                                id="filled-required"
                                className={styles.inputText}
                                label="Сума"
                                value={getValueAmount(props)}
                                onChange={
                                    (event) => {
                                        props.setDataAddress(event.target.value, 'SET_LAND_CURRENT_TENANT_VALUE_AMOUNT')
                                    }
                                }
                                variant="outlined"
                                helperText=""
                            />
                        </div>
                    </div>
                    {borderLine}
                    <PreviousAuctionBidderfrom />
                </>
            )
        }
    }
    return null
}

function viewData(props){
    let dataContractTimeStartDate = {
        targetType: 'SET_LAND_CURRENT_TENANT_CURRENT_CONTRACT_TIME_DATE_FROM',
        target: getStartDate(props),
        typePopUp: '',
        onChange: props.setDataAddress,
        label: `Період з`,
        necessarily: true,
        time: true,
    }
    let dataContractTimeEndDate = {
        targetType: 'SET_LAND_CURRENT_TENANT_CURRENT_CONTRACT_TIME_DATE_TO',
        target: getEndDate(props),
        typePopUp: '',
        onChange: props.setDataAddress,
        label: `Період по`,
        necessarily: true,
        time: true,
    }
    if(blokCurrentTenant(props) === 'disabled'){
        return null
    }
    return (
        <>
            <div>Дата початку та закінчення договору оренди</div>
            <div className={styles.groupElem}>
                <div>
                    <InputDate data={dataContractTimeStartDate} onChange={ e => props.setDataAddress(e, 'SET_LAND_CURRENT_TENANT_CURRENT_CONTRACT_TIME_DATE_FROM') }/>
                </div>
            </div>
            <div className={styles.groupElem}>
                <div>
                    <InputDate data={dataContractTimeEndDate} onChange={ e => props.setDataAddress(e, 'SET_LAND_CURRENT_TENANT_CURRENT_CONTRACT_TIME_DATE_TO') }/>
                </div>
            </div>
        </>
    )
}

function blokCurrentTenant(props){
    let tmpCurrent = Date.now()
    if(props.auctionsSwitchWindow === 3){
        return null
    }
    if(props.auctionsMy.auctionPeriods !== null){
        if(props.auctionsMy.auctionPeriods.length === 0){
            return onOffCurrentTenat(props.auctionsMy)
        }
        //let tmpStart = dateTimeStart(props)
        let tmpEnd = dateTimeEnd(props)
        //if( tmpCurrent < tmpStart.getTime()){
        //    return 'disabled'
        //}else
        if(tmpCurrent > tmpEnd.getTime()){
            return 'disabled'
        }else{
            return onOffCurrentTenat(props.auctionsMy)
        }
    }
}

function dateTimeStart(props){
    if(props.auctionsMy.auctionPeriods !== null){
        if(props.auctionsMy.auctionPeriods.length > 0){
            for(let tm = 0; tm < props.auctionsMy.auctionPeriods.length; tm++){
                if(props.auctionsMy.auctionPeriods[tm].status === "transferPriorityPeriod") {
                    return new Date(props.auctionsMy.auctionPeriods[tm].startDate)
                }
            }
        }
    }
}

function dateTimeEnd(props){
    if(props.auctionsMy.auctionPeriods !== null){
        if(props.auctionsMy.auctionPeriods.length > 0){
            for(let tm = 0; tm < props.auctionsMy.auctionPeriods.length; tm++){
                if(props.auctionsMy.auctionPeriods[tm].status === "transferPriorityPeriod") {
                    return new Date(props.auctionsMy.auctionPeriods[tm].endDate)
                }
            }
        }
    }
}


function viewRegion(props){
    if(props.koatuu.region !== null){
        let tmpRegionOption = props.koatuu.region.map(
            (i) => {
                return <option key={i} value={`${i.classificationId}+:+${i.description}`}>{i.description}</option>
            }
        )
        return(
            <TextField
                disabled={blokCurrentTenant(props)}
                className={styles.inputBank}
                id="outlined-select-currency-native"
                select
                label="Область"
                value={getAddressRegion(props)}
                onChange={(event) => {
                    props.setDataAddress(event.target.value, 'SET_LAND_CURRENT_TENANT_ADDRESS_REGION')
                }}
                SelectProps={{
                    native: true,
                }}
                helperText=""
                variant="outlined"
            >
                <option key={0} selected disabled value='null'>Обрати</option>
                {tmpRegionOption}
            </TextField>
        )
    }else{
        return(
            <TextField
                disabled={blokCurrentTenant(props)}
                className={styles.inputBank}
                disabled
                id="outlined-select-currency-native"
                select
                label="Область"
                value={getAddressRegion(props)}
                SelectProps={{
                    native: true,
                }}
                helperText=""
                variant="outlined"
            >
                <option key={0} selected disabled value='null'>Обрати</option>
            </TextField>
        )
    }
    return null
}

function createRegionCode(data){
    if(data === 'Автономна Республіка Крим' || data === "АВТОНОМНА РЕСПУБЛІКА КРИМ/М.СІМФЕРОПОЛЬ" || data === "0100000000+:+АВТОНОМНА РЕСПУБЛІКА КРИМ/М.СІМФЕРОПОЛЬ"){
        return '0100000000'
    }else if(data === 'Вінницька область' || data === "ВІННИЦЬКА ОБЛАСТЬ/М.ВІННИЦЯ" || data === "0500000000+:+ВІННИЦЬКА ОБЛАСТЬ/М.ВІННИЦЯ"){
        return '0500000000'
    }else if(data === 'Волинська область' || data === "ВОЛИНСЬКА ОБЛАСТЬ/М.ЛУЦЬК" || data === "0700000000+:+ВОЛИНСЬКА ОБЛАСТЬ/М.ЛУЦЬК"){
        return '0700000000'
    }else if(data === 'Дніпропетровська область' || data === "ДНІПРОПЕТРОВСЬКА ОБЛАСТЬ/М.ДНІПРО" || data === "1200000000+:+ДНІПРОПЕТРОВСЬКА ОБЛАСТЬ/М.ДНІПРО"){
        return '1200000000'
    }else if(data === 'Донецька область' || data === "ДОНЕЦЬКА ОБЛАСТЬ/М.ДОНЕЦЬК" || data === "1400000000+:+ДОНЕЦЬКА ОБЛАСТЬ/М.ДОНЕЦЬК"){
        return '1400000000'
    }else if(data === 'Житомирська область' || data === "ЖИТОМИРСЬКА ОБЛАСТЬ/М.ЖИТОМИР" || data === "1800000000+:+ЖИТОМИРСЬКА ОБЛАСТЬ/М.ЖИТОМИР"){
        return '1800000000'
    }else if(data === 'Закарпатська область' || data === "ЗАКАРПАТСЬКА ОБЛАСТЬ/М.УЖГОРОД" || data === "2100000000+:+ЗАКАРПАТСЬКА ОБЛАСТЬ/М.УЖГОРОД"){
        return '2100000000'
    }else if(data === 'Запорізька область' || data === "ЗАПОРІЗЬКА ОБЛАСТЬ/М.ЗАПОРІЖЖЯ" || data === "2300000000+:+ЗАПОРІЗЬКА ОБЛАСТЬ/М.ЗАПОРІЖЖЯ"){
        return '2300000000'
    }else if(data === 'Івано-Франківська область' || data === "ІВАНО-ФРАНКІВСЬКА ОБЛАСТЬ/М.ІВАНО-ФРАНКІВСЬК" || data === "2600000000+:+ІВАНО-ФРАНКІВСЬКА ОБЛАСТЬ/М.ІВАНО-ФРАНКІВСЬК"){
        return '2600000000'
    }else if(data === 'Київська область' || data === "КИЇВСЬКА ОБЛАСТЬ/М.КИЇВ" || data === "3200000000+:+КИЇВСЬКА ОБЛАСТЬ/М.КИЇВ"){
        return '3200000000'
    }else if(data === 'Кіровоградська область' || data === "КІРОВОГРАДСЬКА ОБЛАСТЬ/М.КРОПИВНИЦЬКИЙ" || data === "3500000000+:+КІРОВОГРАДСЬКА ОБЛАСТЬ/М.КРОПИВНИЦЬКИЙ"){
        return '3500000000'
    }else if(data === 'Луганська область' || data === "ЛУГАНСЬКА ОБЛАСТЬ/М.ЛУГАНСЬК" || data === "4400000000+:+ЛУГАНСЬКА ОБЛАСТЬ/М.ЛУГАНСЬК"){
        return '4400000000'
    }else if(data === 'Львівська область' || data === "ЛЬВІВСЬКА ОБЛАСТЬ/М.ЛЬВІВ" || data === "4600000000+:+ЛЬВІВСЬКА ОБЛАСТЬ/М.ЛЬВІВ"){
        return '4600000000'
    }else if(data === 'Миколаївська область' || data === "МИКОЛАЇВСЬКА ОБЛАСТЬ/М.МИКОЛАЇВ" || data === "4800000000+:+МИКОЛАЇВСЬКА ОБЛАСТЬ/М.МИКОЛАЇВ"){
        return '4800000000'
    }else if(data === 'Одеська область' || data === "ОДЕСЬКА ОБЛАСТЬ/М.ОДЕСА" || data === "5100000000+:+ОДЕСЬКА ОБЛАСТЬ/М.ОДЕСА"){
        return '5100000000'
    }else if(data === 'Полтавська область' || data === "ПОЛТАВСЬКА ОБЛАСТЬ/М.ПОЛТАВА" || data === "5300000000+:+ПОЛТАВСЬКА ОБЛАСТЬ/М.ПОЛТАВА"){
        return '5300000000'
    }else if(data === 'Рівненська область' || data === "РІВНЕНСЬКА ОБЛАСТЬ/М.РІВНЕ" || data === "5600000000+:+РІВНЕНСЬКА ОБЛАСТЬ/М.РІВНЕ"){
        return '5600000000'
    }else if(data === 'Сумська область' || data === "СУМСЬКА ОБЛАСТЬ/М.СУМИ" || data === "5900000000+:+СУМСЬКА ОБЛАСТЬ/М.СУМИ"){
        return '5900000000'
    }else if(data === 'Тернопільська область' || data === "ТЕРНОПІЛЬСЬКА ОБЛАСТЬ/М.ТЕРНОПІЛЬ" || data === "6100000000+:+ТЕРНОПІЛЬСЬКА ОБЛАСТЬ/М.ТЕРНОПІЛЬ"){
        return '6100000000'
    }else if(data === 'Харківська область' || data === "ХАРКІВСЬКА ОБЛАСТЬ/М.ХАРКІВ" || data === "6300000000+:+ХАРКІВСЬКА ОБЛАСТЬ/М.ХАРКІВ"){
        return '6300000000'
    }else if(data === 'Херсонська область' || data === "ХЕРСОНСЬКА ОБЛАСТЬ/М.ХЕРСОН" || data === "6500000000+:+ХЕРСОНСЬКА ОБЛАСТЬ/М.ХЕРСОН"){
        return '6500000000'
    }else if(data === 'Хмельницька область' || data === "ХМЕЛЬНИЦЬКА ОБЛАСТЬ/М.ХМЕЛЬНИЦЬКИЙ" || data === "6800000000+:+ХМЕЛЬНИЦЬКА ОБЛАСТЬ/М.ХМЕЛЬНИЦЬКИЙ"){
        return '6800000000'
    }else if(data === 'Черкаська область' || data === "ЧЕРКАСЬКА ОБЛАСТЬ/М.ЧЕРКАСИ" || data === "7100000000+:+ЧЕРКАСЬКА ОБЛАСТЬ/М.ЧЕРКАСИ"){
        return '7100000000'
    }else if(data === 'Чернівецька область' || data === "ЧЕРНІВЕЦЬКА ОБЛАСТЬ/М.ЧЕРНІВЦІ" || data === "7300000000+:+ЧЕРНІВЕЦЬКА ОБЛАСТЬ/М.ЧЕРНІВЦІ"){
        return '7300000000'
    }else if(data === 'Чернігівська область' || data === "ЧЕРНІГІВСЬКА ОБЛАСТЬ/М.ЧЕРНІГІВ" || data === "7400000000+:+ЧЕРНІГІВСЬКА ОБЛАСТЬ/М.ЧЕРНІГІВ"){
        return '7400000000'
    }else if(data === 'Київ' || data === "М.КИЇВ" || data === "8000000000+:+М.КИЇВ"){
        return '8000000000'
    }else if(data === 'Севастополь' || data === "М.СЕВАСТОПОЛЬ" || data === "8500000000+:+М.СЕВАСТОПОЛЬ"){
        return '8500000000'
    }else{
        return ''
    }
}

function getAddressRegionName(props){
    if(props.auctionsMy !== null){
        if(props.auctionsMy.hasOwnProperty('currentTenant') === true){
            if(props.auctionsMy.currentTenant !== null){
                if(props.auctionsMy.currentTenant.hasOwnProperty('address') === true){
                    if(props.auctionsMy.currentTenant.address !== null){
                        if(props.auctionsMy.currentTenant.address.region !== '' && props.auctionsMy.currentTenant.address.region !== null){
                            return createRegionCode(props.auctionsMy.currentTenant.address.region)
                        }
                    }
                }
            }
        }
    }
    return ''
}

function getAddressRegionCode(props){
    if(props.auctionsMy !== null){
        if(props.auctionsMy.hasOwnProperty('currentTenant') === true){
            if(props.auctionsMy.currentTenant !== null){
                if(props.auctionsMy.currentTenant.hasOwnProperty('address') === true){
                    if(props.auctionsMy.currentTenant.address !== null){
                        if(props.auctionsMy.currentTenant.address.region !== '' && props.auctionsMy.currentTenant.address.region !== null){
                            return getRegion(props.auctionsMy.currentTenant.address.region)
                        }
                    }
                }
            }
        }
    }
    return ''
}



function getAddressRegion(props){
    if(props.auctionsMy !== null){
        if(props.auctionsMy.hasOwnProperty('currentTenant') === true){
            if(props.auctionsMy.currentTenant !== null){
                if(props.auctionsMy.currentTenant.hasOwnProperty('address') === true){
                    if(props.auctionsMy.currentTenant.address !== null){
                        if(props.auctionsMy.currentTenant.address.region !== '' && props.auctionsMy.currentTenant.address.region !== null){
                            if(props.auctionsMy.currentTenant.address.region === 'Автономна Республіка Крим' || props.auctionsMy.currentTenant.address.region === "АВТОНОМНА РЕСПУБЛІКА КРИМ/М.СІМФЕРОПОЛЬ"){
                                return "0100000000+:+АВТОНОМНА РЕСПУБЛІКА КРИМ/М.СІМФЕРОПОЛЬ"
                            }else if(props.auctionsMy.currentTenant.address.region === 'Вінницька область' || props.auctionsMy.currentTenant.address.region === "ВІННИЦЬКА ОБЛАСТЬ/М.ВІННИЦЯ"){
                                return "0500000000+:+ВІННИЦЬКА ОБЛАСТЬ/М.ВІННИЦЯ"
                            }else if(props.auctionsMy.currentTenant.address.region === 'Волинська область' || props.auctionsMy.currentTenant.address.region === "ВОЛИНСЬКА ОБЛАСТЬ/М.ЛУЦЬК"){
                                return "0700000000+:+ВОЛИНСЬКА ОБЛАСТЬ/М.ЛУЦЬК"
                            }else if(props.auctionsMy.currentTenant.address.region === 'Дніпропетровська область' || props.auctionsMy.currentTenant.address.region === "ДНІПРОПЕТРОВСЬКА ОБЛАСТЬ/М.ДНІПРО"){
                                return "1200000000+:+ДНІПРОПЕТРОВСЬКА ОБЛАСТЬ/М.ДНІПРО"
                            }else if(props.auctionsMy.currentTenant.address.region === 'Донецька область' || props.auctionsMy.currentTenant.address.region === "ДОНЕЦЬКА ОБЛАСТЬ/М.ДОНЕЦЬК"){
                                return "1400000000+:+ДОНЕЦЬКА ОБЛАСТЬ/М.ДОНЕЦЬК"
                            }else if(props.auctionsMy.currentTenant.address.region === 'Житомирська область' || props.auctionsMy.currentTenant.address.region === "ЖИТОМИРСЬКА ОБЛАСТЬ/М.ЖИТОМИР"){
                                return "1800000000+:+ЖИТОМИРСЬКА ОБЛАСТЬ/М.ЖИТОМИР"
                            }else if(props.auctionsMy.currentTenant.address.region === 'Закарпатська область' || props.auctionsMy.currentTenant.address.region === "ЗАКАРПАТСЬКА ОБЛАСТЬ/М.УЖГОРОД"){
                                return "2100000000+:+ЗАКАРПАТСЬКА ОБЛАСТЬ/М.УЖГОРОД"
                            }else if(props.auctionsMy.currentTenant.address.region === 'Запорізька область' || props.auctionsMy.currentTenant.address.region === "ЗАПОРІЗЬКА ОБЛАСТЬ/М.ЗАПОРІЖЖЯ"){
                                return "2300000000+:+ЗАПОРІЗЬКА ОБЛАСТЬ/М.ЗАПОРІЖЖЯ"
                            }else if(props.auctionsMy.currentTenant.address.region === 'Івано-Франківська область' || props.auctionsMy.currentTenant.address.region === "ІВАНО-ФРАНКІВСЬКА ОБЛАСТЬ/М.ІВАНО-ФРАНКІВСЬК"){
                                return "2600000000+:+ІВАНО-ФРАНКІВСЬКА ОБЛАСТЬ/М.ІВАНО-ФРАНКІВСЬК"
                            }else if(props.auctionsMy.currentTenant.address.region === 'Київська область' || props.auctionsMy.currentTenant.address.region === "КИЇВСЬКА ОБЛАСТЬ/М.КИЇВ"){
                                return "3200000000+:+КИЇВСЬКА ОБЛАСТЬ/М.КИЇВ"
                            }else if(props.auctionsMy.currentTenant.address.region === 'Кіровоградська область' || props.auctionsMy.currentTenant.address.region === "КІРОВОГРАДСЬКА ОБЛАСТЬ/М.КРОПИВНИЦЬКИЙ"){
                                return "3500000000+:+КІРОВОГРАДСЬКА ОБЛАСТЬ/М.КРОПИВНИЦЬКИЙ"
                            }else if(props.auctionsMy.currentTenant.address.region === 'Луганська область' || props.auctionsMy.currentTenant.address.region === "ЛУГАНСЬКА ОБЛАСТЬ/М.ЛУГАНСЬК"){
                                return "4400000000+:+ЛУГАНСЬКА ОБЛАСТЬ/М.ЛУГАНСЬК"
                            }else if(props.auctionsMy.currentTenant.address.region === 'Львівська область' || props.auctionsMy.currentTenant.address.region === "ЛЬВІВСЬКА ОБЛАСТЬ/М.ЛЬВІВ"){
                                return "4600000000+:+ЛЬВІВСЬКА ОБЛАСТЬ/М.ЛЬВІВ"
                            }else if(props.auctionsMy.currentTenant.address.region === 'Миколаївська область' || props.auctionsMy.currentTenant.address.region === "МИКОЛАЇВСЬКА ОБЛАСТЬ/М.МИКОЛАЇВ"){
                                return "4800000000+:+МИКОЛАЇВСЬКА ОБЛАСТЬ/М.МИКОЛАЇВ"
                            }else if(props.auctionsMy.currentTenant.address.region === 'Одеська область' || props.auctionsMy.currentTenant.address.region === "ОДЕСЬКА ОБЛАСТЬ/М.ОДЕСА"){
                                return "5100000000+:+ОДЕСЬКА ОБЛАСТЬ/М.ОДЕСА"
                            }else if(props.auctionsMy.currentTenant.address.region === 'Полтавська область' || props.auctionsMy.currentTenant.address.region === "ПОЛТАВСЬКА ОБЛАСТЬ/М.ПОЛТАВА"){
                                return "5300000000+:+ПОЛТАВСЬКА ОБЛАСТЬ/М.ПОЛТАВА"
                            }else if(props.auctionsMy.currentTenant.address.region === 'Рівненська область' || props.auctionsMy.currentTenant.address.region === "РІВНЕНСЬКА ОБЛАСТЬ/М.РІВНЕ"){
                                return "5600000000+:+РІВНЕНСЬКА ОБЛАСТЬ/М.РІВНЕ"
                            }else if(props.auctionsMy.currentTenant.address.region === 'Сумська область' || props.auctionsMy.currentTenant.address.region === "СУМСЬКА ОБЛАСТЬ/М.СУМИ"){
                                return "5900000000+:+СУМСЬКА ОБЛАСТЬ/М.СУМИ"
                            }else if(props.auctionsMy.currentTenant.address.region === 'Тернопільська область' || props.auctionsMy.currentTenant.address.region === "ТЕРНОПІЛЬСЬКА ОБЛАСТЬ/М.ТЕРНОПІЛЬ"){
                                return "6100000000+:+ТЕРНОПІЛЬСЬКА ОБЛАСТЬ/М.ТЕРНОПІЛЬ"
                            }else if(props.auctionsMy.currentTenant.address.region === 'Харківська область' || props.auctionsMy.currentTenant.address.region === "ХАРКІВСЬКА ОБЛАСТЬ/М.ХАРКІВ"){
                                return "6300000000+:+ХАРКІВСЬКА ОБЛАСТЬ/М.ХАРКІВ"
                            }else if(props.auctionsMy.currentTenant.address.region === 'Херсонська область' || props.auctionsMy.currentTenant.address.region === "ХЕРСОНСЬКА ОБЛАСТЬ/М.ХЕРСОН"){
                                return "6500000000+:+ХЕРСОНСЬКА ОБЛАСТЬ/М.ХЕРСОН"
                            }else if(props.auctionsMy.currentTenant.address.region === 'Хмельницька область' || props.auctionsMy.currentTenant.address.region === "ХМЕЛЬНИЦЬКА ОБЛАСТЬ/М.ХМЕЛЬНИЦЬКИЙ"){
                                return "6800000000+:+ХМЕЛЬНИЦЬКА ОБЛАСТЬ/М.ХМЕЛЬНИЦЬКИЙ"
                            }else if(props.auctionsMy.currentTenant.address.region === 'Черкаська область' || props.auctionsMy.currentTenant.address.region === "ЧЕРКАСЬКА ОБЛАСТЬ/М.ЧЕРКАСИ"){
                                return "7100000000+:+ЧЕРКАСЬКА ОБЛАСТЬ/М.ЧЕРКАСИ"
                            }else if(props.auctionsMy.currentTenant.address.region === 'Чернівецька область' || props.auctionsMy.currentTenant.address.region === "ЧЕРНІВЕЦЬКА ОБЛАСТЬ/М.ЧЕРНІВЦІ"){
                                return "7300000000+:+ЧЕРНІВЕЦЬКА ОБЛАСТЬ/М.ЧЕРНІВЦІ"
                            }else if(props.auctionsMy.currentTenant.address.region === 'Чернігівська область' || props.auctionsMy.currentTenant.address.region === "ЧЕРНІГІВСЬКА ОБЛАСТЬ/М.ЧЕРНІГІВ"){
                                return "7400000000+:+ЧЕРНІГІВСЬКА ОБЛАСТЬ/М.ЧЕРНІГІВ"
                            }else if(props.auctionsMy.currentTenant.address.region === 'Київ' || props.auctionsMy.currentTenant.address.region === "М.КИЇВ"){
                                return "8000000000+:+М.КИЇВ"
                            }else if(props.auctionsMy.currentTenant.address.region === 'Севастополь' || props.auctionsMy.currentTenant.address.region === "М.СЕВАСТОПОЛЬ"){
                                return "8500000000+:+М.СЕВАСТОПОЛЬ"
                            }else{
                                return props.auctionsMy.currentTenant.address.region
                            }
                        }
                    }
                }
            }
        }
    }
    return 'null'
}


function getAddressLocality(props){
    if(props.auctionsMy !== null){
        if(props.auctionsMy.hasOwnProperty('currentTenant') === true){
            if(props.auctionsMy.currentTenant !== null){
                if(props.auctionsMy.currentTenant.hasOwnProperty('address') === true){
                    if(props.auctionsMy.currentTenant.address !== null){
                        if(props.auctionsMy.currentTenant.address.locality !== '' && props.auctionsMy.currentTenant.address.locality !== null){
                            return props.auctionsMy.currentTenant.address.locality
                        }
                    }
                }
            }
        }
    }
    return ''
}

function getAddressStreetAddress(props){
    if(props.auctionsMy !== null){
        if(props.auctionsMy.hasOwnProperty('currentTenant') === true){
            if(props.auctionsMy.currentTenant !== null){
                if(props.auctionsMy.currentTenant.hasOwnProperty('address') === true){
                    if(props.auctionsMy.currentTenant.address !== null){
                        if(props.auctionsMy.currentTenant.address.streetAddress !== '' && props.auctionsMy.currentTenant.address.streetAddress !== null){
                            return props.auctionsMy.currentTenant.address.streetAddress
                        }
                    }
                }
            }
        }
    }
    return ''
}

function getAddressPostalCode(props){
    if(props.auctionsMy !== null){
        if(props.auctionsMy.hasOwnProperty('currentTenant') === true){
            if(props.auctionsMy.currentTenant !== null){
                if(props.auctionsMy.currentTenant.hasOwnProperty('address') === true){
                    if(props.auctionsMy.currentTenant.address !== null){
                        if(props.auctionsMy.currentTenant.address.postalCode !== '' && props.auctionsMy.currentTenant.address.postalCode !== null){
                            return props.auctionsMy.currentTenant.address.postalCode
                        }
                    }
                }
            }
        }
    }
    return ''
}

function getAddressCountryName(props){
    if(props.auctionsMy !== null){
        if(props.auctionsMy.hasOwnProperty('currentTenant') === true){
            if(props.auctionsMy.currentTenant !== null){
                if(props.auctionsMy.currentTenant.hasOwnProperty('address') === true){
                    if(props.auctionsMy.currentTenant.address !== null){
                        if(props.auctionsMy.currentTenant.address.countryName !== '' && props.auctionsMy.currentTenant.address.countryName !== null){
                            return props.auctionsMy.currentTenant.address.countryName
                        }
                    }
                }
            }
        }
    }
    return 'Україна'
}

function getName(props){
    if(props.auctionsMy !== null){
        if(props.auctionsMy.hasOwnProperty('currentTenant') === true){
            if(props.auctionsMy.currentTenant !== null){
                if(props.auctionsMy.currentTenant.name !== '' && props.auctionsMy.currentTenant.name !== null){
                    return props.auctionsMy.currentTenant.name
                }
            }
        }
    }
    return ''
}

function getIdentifierIdentifier(props){
    if(props.auctionsMy !== null){
        if(props.auctionsMy.hasOwnProperty('currentTenant') === true){
            if(props.auctionsMy.currentTenant !== null){
                if(props.auctionsMy.currentTenant.hasOwnProperty('identifier') === true){
                    if(props.auctionsMy.currentTenant.identifier !== null){
                        if(props.auctionsMy.currentTenant.identifier.identifier !== '' && props.auctionsMy.currentTenant.identifier.identifier !== null){
                            return props.auctionsMy.currentTenant.identifier.identifier
                        }
                    }
                }
            }
        }
    }
    return ''
}

function getIdentifierLegalName(props){
    if(props.auctionsMy !== null){
        if(props.auctionsMy.hasOwnProperty('currentTenant') === true){
            if(props.auctionsMy.currentTenant !== null){
                if(props.auctionsMy.currentTenant.hasOwnProperty('identifier') === true){
                    if(props.auctionsMy.currentTenant.identifier !== null){
                        if(props.auctionsMy.currentTenant.identifier.legalName !== '' && props.auctionsMy.currentTenant.identifier.legalName !== null){
                            return props.auctionsMy.currentTenant.identifier.legalName
                        }
                    }
                }
            }
        }
    }
    return ''
}

function getIdentifierScheme(props){
    if(props.auctionsMy !== null){
        if(props.auctionsMy.hasOwnProperty('currentTenant') === true){
            if(props.auctionsMy.currentTenant !== null){
                if(props.auctionsMy.currentTenant.hasOwnProperty('identifier') === true){
                    if(props.auctionsMy.currentTenant.identifier !== null){
                        if(props.auctionsMy.currentTenant.identifier.scheme !== '' && props.auctionsMy.currentTenant.identifier.scheme !== null){
                            return props.auctionsMy.currentTenant.identifier.scheme
                        }
                    }
                }
            }
        }
    }
    return 'null'
}





function getRepresentativeInfo(props){
    if(props.auctionsMy !== null){
        if(props.auctionsMy.hasOwnProperty('currentTenant') === true){
            if(props.auctionsMy.currentTenant !== null){
                if(props.auctionsMy.currentTenant.representativeInfo !== '' && props.auctionsMy.currentTenant.representativeInfo !== null){
                    return props.auctionsMy.currentTenant.representativeInfo
                }
            }
        }
    }
    return ''
}

function getStartDate(props){
    if(props.auctionsMy !== null){
        if(props.auctionsMy.hasOwnProperty('currentTenant') === true){
            if(props.auctionsMy.currentTenant !== null){
                if(props.auctionsMy.currentTenant.hasOwnProperty('currentContractTime') === true){
                    if(props.auctionsMy.currentTenant.currentContractTime !== null){
                        if(props.auctionsMy.currentTenant.currentContractTime.dateFrom !== '' && props.auctionsMy.currentTenant.currentContractTime.dateFrom !== null){
                            return props.auctionsMy.currentTenant.currentContractTime.dateFrom
                        }
                    }
                }
            }
        }
    }
    return ''
}

function getEndDate(props){
    if(props.auctionsMy !== null){
        if(props.auctionsMy.hasOwnProperty('currentTenant') === true){
            if(props.auctionsMy.currentTenant !== null){
                if(props.auctionsMy.currentTenant.hasOwnProperty('currentContractTime') === true){
                    if(props.auctionsMy.currentTenant.currentContractTime !== null){
                        if(props.auctionsMy.currentTenant.currentContractTime.dateTill !== '' && props.auctionsMy.currentTenant.currentContractTime.dateTill !== null){
                            return props.auctionsMy.currentTenant.currentContractTime.dateTill
                        }
                    }
                }
            }
        }
    }
    return ''
}

function getValueAmount(props){
    if(props.auctionsMy !== null){
        if(props.auctionsMy.hasOwnProperty('currentTenant') === true){
            if(props.auctionsMy.currentTenant !== null){
                if(props.auctionsMy.currentTenant.hasOwnProperty('currentTenantValue') === true){
                    if(props.auctionsMy.currentTenant.currentTenantValue !== null){
                        if(props.auctionsMy.currentTenant.currentTenantValue.amount !== '' && props.auctionsMy.currentTenant.currentTenantValue.amount !== null){
                            return props.auctionsMy.currentTenant.currentTenantValue.amount
                        }
                    }
                }
            }
        }
    }
    return ''
}

async function getKoatuuRegion(props){
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/classifications/koatuu/byMask`;
    const response = await fetch(endPoint, {
        method: "GET",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json1 = await response.json()
        if(json1.hasOwnProperty('message') === true){}
        else{
            props.setKoatuuRegion(json1)
        }
        
    }
}

const mapStateToProps = state => {
    return {
        auctionsMy: state.start.auctionsMy,
        koatuu: state.start.koatuu,
        token: state.start.token,
        auctionsSwitchWindow: state.start.auctionsSwitchWindow
    }
}

const mapDispatchToProps = {
    setDataAddress,
    setKoatuuRegion,
}

export default connect(mapStateToProps, mapDispatchToProps)(LandCurrentTenant)