// "Ліс: Англійський аукціон" - 'timber-english'
export function isTIE(sellingMethod) {
    return typeof sellingMethod === 'string' && sellingMethod?.startsWith(procedureHelper.SELLING_METHOD_TIMBER_ENGLISH)
}

// "Надра: Англійський аукціон" - "subsoil-english"
export function isSUE(sellingMethod) {
    return typeof sellingMethod === 'string' && sellingMethod?.startsWith(procedureHelper.SELLING_METHOD_SUBSOIL_ENGLISH)
}

// "Надра: Голандський аукціон" - "subsoil-dutch"
export function isSUD(sellingMethod) {
    return typeof sellingMethod === 'string' && sellingMethod?.startsWith(procedureHelper.SELLING_METHOD_SUBSOIL_DUTCH)
}

// "Залізничні перевезення: Англійський аукціон" - "railwayCargo-english"
export function isRCE(sellingMethod) {
    return typeof sellingMethod === 'string' && sellingMethod?.startsWith(procedureHelper.SELLING_METHOD_RAILWAY_CARGO_ENGLISH)
}

// "Залізничні перевезення: Голандський аукціон" - "railwayCargo-dutch"
export function isRCD(sellingMethod) {
    return typeof sellingMethod === 'string' && sellingMethod?.startsWith(procedureHelper.SELLING_METHOD_RAILWAY_CARGO_DUTCH)
}

// "ДГФ: Англійський аукціон" - "dgf-english"
export function isGFE(sellingMethod) {
    return typeof sellingMethod === 'string' && sellingMethod?.startsWith(procedureHelper.SELLING_METHOD_DGF_ENGLISH)
}

// "ДГФ: Голандський аукціон" - "dgf-dutch"
export function isGFD(sellingMethod) {
    return typeof sellingMethod === 'string' && sellingMethod?.startsWith(procedureHelper.SELLING_METHOD_DGF_DUTCH)
}

// "Оренда державного / комунального майна" - "legitimatePropertyLease-english"
export function isLLE(sellingMethod) {
    return typeof sellingMethod === 'string' && sellingMethod?.startsWith(procedureHelper.SELLING_METHOD_LEGITIMATE_PROPERTY_LEASE_ENGLISH)
}

// "Оренда державного / комунального майна з покроковим зниженням" - "legitimatePropertyLease-dutch"
export function isLLD(sellingMethod) {
    return typeof sellingMethod === 'string' && sellingMethod?.startsWith(procedureHelper.SELLING_METHOD_LEGITIMATE_PROPERTY_LEASE_DUTCH)
}

// "Оренда державного / комунального майна з використанням переважного права" - "legitimatePropertyLease-priorityEnglish"
export function isLLP(sellingMethod) {
    return typeof sellingMethod === 'string' && sellingMethod?.startsWith(procedureHelper.SELLING_METHOD_LEGITIMATE_PROPERTY_LEASE_PRIORITY_ENGLISH)
}

// "Земельні торги - оренда" - 'landRental-english'
export function isLRE(sellingMethod) {
    return typeof sellingMethod === 'string' && sellingMethod?.startsWith(procedureHelper.SELLING_METHOD_LAND_RENTAL_ENGLISH)
}

// "Земельні торги - продаж" - 'landSell-english'
export function isLSE(sellingMethod) {
    return typeof sellingMethod === 'string' && sellingMethod?.startsWith(procedureHelper.SELLING_METHOD_LAND_SELL_ENGLISH)
}

// "Продаж землі: З використанням переважного права" - 'landSell-priorityEnglish'
export function isLSP(sellingMethod) {
    return typeof sellingMethod === 'string' && sellingMethod?.startsWith(procedureHelper.SELLING_METHOD_LAND_SELL_PRIORITY_ENGLISH)
}

// "Базовий продаж" - "basicSell-english"
export function isBSE(sellingMethod) {
    return typeof sellingMethod === 'string' && sellingMethod?.startsWith(procedureHelper.SELLING_METHOD_BASIC_SELL_ENGLISH)
}

// "Базовий продаж" - "basicSell-dutch"
export function isBSD(sellingMethod) {
    return typeof sellingMethod === 'string' && sellingMethod?.startsWith(procedureHelper.SELLING_METHOD_BASIC_SELL_DUTCH)
}

// "Відчуження майна" - "alienation-english"
export function isALE(sellingMethod) {
    return typeof sellingMethod === 'string' && sellingMethod?.startsWith(procedureHelper.SELLING_METHOD_ALIENATION_ENGLISH)
}

// "Комерційні торги - продаж (англійський аукціон)" - "commercialSell-english"
export function isCSE(sellingMethod) {
    return typeof sellingMethod === 'string' && sellingMethod?.startsWith(procedureHelper.SELLING_METHOD_COMMERCIAL_SELL_ENGLISH)
}

// "Комерційні торги - продаж (голландський аукціон)" - "commercialSell-dutch"
export function isCSD(sellingMethod) {
    return typeof sellingMethod === 'string' && sellingMethod?.startsWith(procedureHelper.SELLING_METHOD_COMMERCIAL_SELL_DUTCH)
}

//Арештоване майно
export function isAAE(sellingMethod) {
    return typeof sellingMethod === 'string' && sellingMethod?.startsWith(procedureHelper.SELLING_METHOD_ARRESTED_ASSETS_ENGLISH)
}

// Продаж майна боржників у справах про банкрутство (Банкрутство - англійський аукціон)
export function isBRE(sellingMethod) {
    return typeof sellingMethod === 'string' && sellingMethod?.startsWith(procedureHelper.SELLING_METHOD_BANKRUPTCY_ENGLISH)
}

// Продаж майна боржників у справах про банкрутство (Банкрутство - голландський аукціон)
export function isBRD(sellingMethod) {
    return typeof sellingMethod === 'string' && sellingMethod?.startsWith(procedureHelper.SELLING_METHOD_BANKRUPTCY_DUTCH)
}

// Продаж майна боржників у справах про банкрутство (Банкрутство - Відсутні заяви на участь і аукціон)
export function isBRW(sellingMethod) {
    return typeof sellingMethod === 'string' && sellingMethod?.startsWith(procedureHelper.SELLING_METHOD_BANKRUPTCY_WITHOUT_AUCTION)
}

export function isRLE(sellingMethod) {
    return typeof sellingMethod === 'string' && sellingMethod?.startsWith(procedureHelper.SELLING_METHOD_REGULATIONS_PROPERTY_LEASE_ENGLISH)
}

export function isRLD(sellingMethod) {
    return typeof sellingMethod === 'string' && sellingMethod?.startsWith(procedureHelper.SELLING_METHOD_REGULATIONS_PROPERTY_LEASE_DUTCH)
}

export function isCLE(sellingMethod) {
    return typeof sellingMethod === 'string' && sellingMethod?.startsWith(procedureHelper.SELLING_METHOD_COMMERCIAL_PROPERTY_LEASE_ENGLISH)
}

export function isCLD(sellingMethod) {
    return typeof sellingMethod === 'string' && sellingMethod?.startsWith(procedureHelper.SELLING_METHOD_COMMERCIAL_PROPERTY_LEASE_DUTCH)
}

export function isSPE(sellingMethod) {
    return typeof sellingMethod === 'string' && sellingMethod?.startsWith(procedureHelper.SELLING_METHOD_SMALL_PRIVATIZATION_ENGLISH)
}

export function isSPD(sellingMethod) {
    return typeof sellingMethod === 'string' && sellingMethod?.startsWith(procedureHelper.SELLING_METHOD_SMALL_PRIVATIZATION_DUTCH)
}

export function isNLE(sellingMethod) {
    return typeof sellingMethod === 'string' && sellingMethod?.startsWith(procedureHelper.SELLING_METHOD_NONPERFORMING_LOANS_ENGLISH)
}

// "Базовий продаж" - "basicSell-dutch"
export function isNLD(sellingMethod) {
    return typeof sellingMethod === 'string' && sellingMethod?.startsWith(procedureHelper.SELLING_METHOD_NONPERFORMING_LOANS_DUTCH)
}

export function isLAE(sellingMethod) {
    return typeof sellingMethod === 'string' && sellingMethod?.startsWith(procedureHelper.SELLING_METHOD_LAND_ARRESTED_ENGLISH)
}

export function isLAW(sellingMethod) {
    return typeof sellingMethod === 'string' && sellingMethod?.startsWith(procedureHelper.SELLING_METHOD_LAND_ARRESTED_WITHOUT_AUCTION)
}

export function isLAP(sellingMethod) {
    return typeof sellingMethod === 'string' && sellingMethod?.startsWith(procedureHelper.SELLING_METHOD_LAND_ARRESTED_PRIORITY_ENGLISH)
}

export function isLPE(sellingMethod) {
    return typeof sellingMethod === 'string' && sellingMethod?.startsWith(procedureHelper.SELLING_METHOD_LARGE_PRIVATIZATION_ENGLISH)
}

export function isAPE(sellingMethod) {
    return typeof sellingMethod === 'string' && sellingMethod?.startsWith(procedureHelper.SELLING_METHOD_ARMA_PROPERTY_ENGLISH)
}

export function isAPD(sellingMethod) {
    return typeof sellingMethod === 'string' && sellingMethod?.startsWith(procedureHelper.SELLING_METHOD_ARMA_PROPERTY_DUTCH)
}

export function isREM(sellingMethod) {
    return typeof sellingMethod === 'string' && sellingMethod?.startsWith(procedureHelper.SELLING_METHOD_RENEWABLES_MULTI_AWARDS)
}

export function viewAuctionType(sellingMethod) {
    switch (true) {
        case isTIE(sellingMethod):
            return "Ліс: англійський аукціон"
        case isSUE(sellingMethod):
            return "Надра: англійський аукціон"
        case isSUD(sellingMethod):
            return "Надра: голандський аукціон"
        case isRCE(sellingMethod):
            return "Залізничні перевезення: англійський аукціон"
        case isRCD(sellingMethod):
            return "Залізничні перевезення: голандський аукціон"
        case isGFE(sellingMethod):
            return "ДГФ: англійський аукціон"
        case isGFD(sellingMethod):
            return "ДГФ: голандський аукціон"
        case isLLE(sellingMethod):
            return "Оренда державного / комунального майна"
        case isLLD(sellingMethod):
            return "Оренда державного / комунального майна з покроковим зниженням"
        case isLLP(sellingMethod):
            return "Оренда державного / комунального майна з використанням переважного права"
        case isLRE(sellingMethod):
            return "Земельні торги - оренда"
        case isLSE(sellingMethod):
            return "Земельні торги - продаж"
        case isLSP(sellingMethod):
            return "Земельні торги - продаж з переважним правом"
        case isBSE(sellingMethod):
            return "Базовий продаж: англійський аукціон"
        case isBSD(sellingMethod):
            return "Базовий продаж: голандський аукціон"
        case isALE(sellingMethod):
            return "Відчуження майна"
        case isCSE(sellingMethod):
            return "Комерційні торги - продаж (англійський аукціон)"
        case isCSD(sellingMethod):
            return "Комерційні торги - продаж (голландський аукціон)"
        case isAAE(sellingMethod):
            return "Арештоване майно"
        case isBRE(sellingMethod):
            return "Банкрутство - англійський аукціон"
        case isBRD(sellingMethod):
            return "Банкрутство - голландський аукціон"
        case isBRW(sellingMethod):
            return "Продаж майна боржників у справах про банкрутство без аукціону"

        case isRLE(sellingMethod):
            return "Оренда за регламентом (англійський аукціон)"
        case isRLD(sellingMethod):
            return "Оренда за регламентом (голландський аукціон)"
        case isCLE(sellingMethod):
            return "Комерційна оренда (англійський аукціон)"
        case isCLD(sellingMethod):
            return "Комерційна оренда (голландський аукціон)"
        case isSPE(sellingMethod):
            return "Продаж об'єктів малої приватизації на \"англійському аукціоні\""
        case isSPD(sellingMethod):
            return "Продаж об'єктів малої приватизації на \"голландському аукціоні\""
        case isNLE(sellingMethod):
            return "Продаж непрацюючих кредитів державних банків: англійський аукціон"
        case isNLD(sellingMethod):
            return "Продаж непрацюючих кредитів державних банків: голандський аукціон"
        case isLAE(sellingMethod):
            return "Арештована земля англійський аукціон"
        case isLAW(sellingMethod):
            return "Арештована земля. Викуп"
        case isLAP(sellingMethod):
            return "Арештована земля: з переважним правом"
        case isLPE(sellingMethod):
            return "Продаж об'єктів великої приватизації на \"англійському аукціоні\""
        case isAPE(sellingMethod):
            return "Арештовані активи АРМА. Англійський аукціон"
        case isAPD(sellingMethod):
            return "Арештовані активи АРМА. Голандський аукціон"
        case isREM(sellingMethod):
            return "Аукціон з розподілу квоти підтримки"
        default:
            return null
    }
}

const procedureHelper = {
    SELLING_METHOD_ALIENATION_ENGLISH: 'alienation-english',
    SELLING_METHOD_ARRESTED_ASSETS_ENGLISH: 'arrestedAssets-english',
    SELLING_METHOD_ARMA_PROPERTY_ENGLISH: 'armaProperty-english',
    SELLING_METHOD_ARMA_PROPERTY_DUTCH: 'armaProperty-dutch',
    SELLING_METHOD_BANKRUPTCY_ENGLISH: 'bankRuptcy-english',
    SELLING_METHOD_BANKRUPTCY_DUTCH: 'bankRuptcy-dutch',
    SELLING_METHOD_BANKRUPTCY_WITHOUT_AUCTION: 'bankRuptcy-withoutAuction',
    SELLING_METHOD_BASIC_SELL_ENGLISH: 'basicSell-english',
    SELLING_METHOD_BASIC_SELL_DUTCH: 'basicSell-dutch',
    SELLING_METHOD_BASIC_SELL_WITHOUT_AUCTION: 'basicSell-withoutAuction',
    SELLING_METHOD_COMMERCIAL_PROPERTY_LEASE_ENGLISH: 'commercialPropertyLease-english',
    SELLING_METHOD_COMMERCIAL_PROPERTY_LEASE_DUTCH: 'commercialPropertyLease-dutch',
    SELLING_METHOD_COMMERCIAL_SELL_ENGLISH: 'commercialSell-english',
    SELLING_METHOD_COMMERCIAL_SELL_INFINITY: 'commercialSell-infinity',
    SELLING_METHOD_COMMERCIAL_SELL_DUTCH: 'commercialSell-dutch',
    SELLING_METHOD_DGF_ENGLISH: 'dgf-english',
    SELLING_METHOD_DGF_DUTCH: 'dgf-dutch',
    SELLING_METHOD_RENEWABLES: 'renewables',
    SELLING_METHOD_RENEWABLES_MULTI_AWARDS: 'renewables-multiAwards',//@todo implement
    SELLING_METHOD_SUBSOIL_ENGLISH: 'subsoil-english',
    SELLING_METHOD_SUBSOIL_DUTCH: 'subsoil-dutch',
    SELLING_METHOD_LAND_ARRESTED_ENGLISH: 'landArrested-english',
    SELLING_METHOD_LAND_ARRESTED_WITHOUT_AUCTION: 'landArrested-withoutAuction',
    SELLING_METHOD_LAND_ARRESTED_PRIORITY_ENGLISH: 'landArrested-priorityEnglish',
    SELLING_METHOD_LAND_RENTAL_ENGLISH: 'landRental-english',
    SELLING_METHOD_LAND_SELL_ENGLISH: 'landSell-english',
    SELLING_METHOD_LAND_SELL_PRIORITY_ENGLISH: 'landSell-priorityEnglish',
    SELLING_METHOD_NONPERFORMING_LOANS_ENGLISH: 'nonperformingLoans-english',
    SELLING_METHOD_NONPERFORMING_LOANS_DUTCH: 'nonperformingLoans-dutch',
    SELLING_METHOD_LEGITIMATE_PROPERTY_LEASE_ENGLISH: 'legitimatePropertyLease-english',
    SELLING_METHOD_LEGITIMATE_PROPERTY_LEASE_DUTCH: 'legitimatePropertyLease-dutch',
    SELLING_METHOD_LEGITIMATE_PROPERTY_LEASE_PRIORITY_ENGLISH: 'legitimatePropertyLease-priorityEnglish',
    SELLING_METHOD_RAILWAY_CARGO_ENGLISH: 'railwayCargo-english',
    SELLING_METHOD_RAILWAY_CARGO_DUTCH: 'railwayCargo-dutch',
    SELLING_METHOD_REGULATIONS_PROPERTY_LEASE_ENGLISH: 'regulationsPropertyLease-english',
    SELLING_METHOD_REGULATIONS_PROPERTY_LEASE_DUTCH: 'regulationsPropertyLease-dutch',
    SELLING_METHOD_SIMPLE_SELL_WITHOUT_AUCTION: 'simpleSell-withoutAuction',
    SELLING_METHOD_SMALL_PRIVATIZATION_ENGLISH: 'smallPrivatization-english',
    SELLING_METHOD_SMALL_PRIVATIZATION_DUTCH: 'smallPrivatization-dutch',
    SELLING_METHOD_LARGE_PRIVATIZATION_ENGLISH: 'largePrivatization-english',
    SELLING_METHOD_TIMBER_ENGLISH: 'timber-english',
    SELLING_METHOD_TIMBER_MULTI_AWARDS: 'timber-multiAwards',


    SELLING_METHOD_LEGACY_SMALL_PRIVATIZATION_ENGLISH: "legacySmallPrivatization-english",
    SELLING_METHOD_LEGACY_SMALL_PRIVATIZATION_DUTCH: "legacySmallPrivatization-dutch",
    SELLING_METHOD_LEGACY_BASIC_SELL_ENGLISH: "legacyBasicSell-english",
    SELLING_METHOD_LEGACY_BASIC_SELL_DUTCH: "legacyBasicSell-dutch",
    SELLING_METHOD_LEGACY_LEGITIMATE_PROPERTY_LEASE_ENGLISH: "legacyLegitimatePropertyLease-english",
    SELLING_METHOD_LEGACY_LEGITIMATE_PROPERTY_LEASE_DUTCH: "legacyLegitimatePropertyLease-dutch",
    SELLING_METHOD_LEGACY_REGULATION_PROPERTY_LEASE_ENGLISH: "legacyRegulationsPropertyLease-english",
    SELLING_METHOD_LEGACY_REGULATION_PROPERTY_LEASE_DUTCH: "legacyRegulationsPropertyLease-dutch",
    SELLING_METHOD_LEGACY_BANK_RUPTCY_ENGLISH: "legacyBankRuptcy-english",
    SELLING_METHOD_LEGACY_BANK_RUPTCY_DUTCH: "legacyBankRuptcy-dutch",
    SELLING_METHOD_LEGACY_TIMBER_ENGLISH: "legacyTimber-english",
    SELLING_METHOD_LEGACY_SUBSOIL_ENGLISH: "legacySubsoil-english",
    SELLING_METHOD_LEGACY_SUBSOIL_DUTCH: "legacySubsoil-dutch",
    SELLING_METHOD_LEGACY_LAND_RENTAL_INFINITY: "legacyLandRental-infinity",
    SELLING_METHOD_LEGACY_COMMERCIAL_SELL_ENGLISH: "legacyCommercialSell-english",
    SELLING_METHOD_LEGACY_COMMERCIAL_SELL_DUTCH: "legacyCommercialSell-dutch",
    SELLING_METHOD_LEGACY_COMMERCIAL_PROPERTY_LEASE_ENGLISH: "legacyCommercialPropertyLease-english",
    SELLING_METHOD_LEGACY_COMMERCIAL_PROPERTY_LEASE_DUTCH: "legacyCommercialPropertyLease-dutch",
    SELLING_METHOD_LEGACY_RAILWAY_CARGO_ENGLISH: "legacyRailwayCargo-english",
    SELLING_METHOD_LEGACY_RAILWAY_CARGO_DUTCH: "legacyRailwayCargo-dutch",


    SELLING_PREFIX_AUCTION_PROD: '-auction-prod',
    SELLING_PREFIX_AUCTION_QUALIFICATION: '-auction-qualification',
    SELLING_PREFIX_FAST: '-fast',
    SELLING_PREFIX_FAST_AUCTION_PROD: '-fast-auction-prod',
    SELLING_PREFIX_ULTRA: '-ultra',
    SELLING_PREFIX_ULTRA_FAST: '-ultra-fast',
    SELLING_PREFIX_MANUAL: '-manual',
    SELLING_PREFIX_FAST_MANUAL: '-fast-manual',
    SELLING_PREFIX_FAST_MANUAL_QUALIFICATION: '-fast-auction-manual-qualification',
    SELLING_PREFIX_INITIAL_AUCTION: '-initial-auction',
    SELLING_PREFIX_INITIAL_AUCTION_MANUAL: '-initial-auction-manual',
    SELLING_PREFIX_INITIAL_QUALIFICATION: '-initial-qualification',
    SELLING_PREFIX_INITIAL_QUALIFICATION_PROD: '-initial-qualification-prod',
    SELLING_PREFIX_INITIAL_QUALIFICATION_FAST: '-initial-qualification-fast',

// "Ліс: Англійський аукціон" - 'timber-english'
    isDutch: function isDutch(sellingMethod) {
        return typeof sellingMethod === 'string' && sellingMethod?.includes('dutch')
    },

// "Ліс: Англійський аукціон" - 'timber-english'
    isTIE: function isTIE(sellingMethod) {
        return typeof sellingMethod === 'string' && sellingMethod?.startsWith(procedureHelper.SELLING_METHOD_TIMBER_ENGLISH)
    },

// "Надра: Англійський аукціон" - "subsoil-english"
    isSUE: function isSUE(sellingMethod) {
        return typeof sellingMethod === 'string' && sellingMethod?.startsWith(procedureHelper.SELLING_METHOD_SUBSOIL_ENGLISH)
    },

// "Надра: Голандський аукціон" - "subsoil-dutch"
    isSUD: function isSUD(sellingMethod) {
        return typeof sellingMethod === 'string' && sellingMethod?.startsWith(procedureHelper.SELLING_METHOD_SUBSOIL_DUTCH)
    },

// "Залізничні перевезення: Англійський аукціон" - "railwayCargo-english"
    isRCE: function isRCE(sellingMethod) {
        return typeof sellingMethod === 'string' && sellingMethod?.startsWith(procedureHelper.SELLING_METHOD_RAILWAY_CARGO_ENGLISH)
    },

// "Залізничні перевезення: Голандський аукціон" - "railwayCargo-dutch"
    isRCD: function isRCD(sellingMethod) {
        return typeof sellingMethod === 'string' && sellingMethod?.startsWith(procedureHelper.SELLING_METHOD_RAILWAY_CARGO_DUTCH)
    },

// "ДГФ: Англійський аукціон" - "dgf-english"
    isGFE: function isGFE(sellingMethod) {
        return typeof sellingMethod === 'string' && sellingMethod?.startsWith(procedureHelper.SELLING_METHOD_DGF_ENGLISH)
    },

// "ДГФ: Голандський аукціон" - "dgf-dutch"
    isGFD: function isGFD(sellingMethod) {
        return typeof sellingMethod === 'string' && sellingMethod?.startsWith(procedureHelper.SELLING_METHOD_DGF_DUTCH)
    },

// "Оренда державного / комунального майна" - "legitimatePropertyLease-english"
    isLLE: function isLLE(sellingMethod) {
        return typeof sellingMethod === 'string' && sellingMethod?.startsWith(procedureHelper.SELLING_METHOD_LEGITIMATE_PROPERTY_LEASE_ENGLISH)
    },

// "Оренда державного / комунального майна з покроковим зниженням" - "legitimatePropertyLease-dutch"
    isLLD: function isLLD(sellingMethod) {
        return typeof sellingMethod === 'string' && sellingMethod?.startsWith(procedureHelper.SELLING_METHOD_LEGITIMATE_PROPERTY_LEASE_DUTCH)
    },

// "Оренда державного / комунального майна з використанням переважного права" - "legitimatePropertyLease-priorityEnglish"
    isLLP: function isLLP(sellingMethod) {
        return typeof sellingMethod === 'string' && sellingMethod?.startsWith(procedureHelper.SELLING_METHOD_LEGITIMATE_PROPERTY_LEASE_PRIORITY_ENGLISH)
    },

// "Земельні торги - оренда" - 'landRental-english'
    isLRE: function isLRE(sellingMethod) {
        return typeof sellingMethod === 'string' && sellingMethod?.startsWith(procedureHelper.SELLING_METHOD_LAND_RENTAL_ENGLISH)
    },

// "Земельні торги - продаж" - 'landSell-english'
    isLSE: function isLSE(sellingMethod) {
        return typeof sellingMethod === 'string' && sellingMethod?.startsWith(procedureHelper.SELLING_METHOD_LAND_SELL_ENGLISH)
    },

// "Продаж землі: З використанням переважного права" - 'landSell-priorityEnglish'
    isLSP: function isLSP(sellingMethod) {
        return typeof sellingMethod === 'string' && sellingMethod?.startsWith(procedureHelper.SELLING_METHOD_LAND_SELL_PRIORITY_ENGLISH)
    },

// "Базовий продаж" - "basicSell-english"
    isBSE: function isBSE(sellingMethod) {
        return typeof sellingMethod === 'string' && sellingMethod?.startsWith(procedureHelper.SELLING_METHOD_BASIC_SELL_ENGLISH)
    },

// "Базовий продаж" - "basicSell-dutch"
    isBSD: function isBSD(sellingMethod) {
        return typeof sellingMethod === 'string' && sellingMethod?.startsWith(procedureHelper.SELLING_METHOD_BASIC_SELL_DUTCH)
    },

// "Відчуження майна" - "alienation-english"
    isALE: function isALE(sellingMethod) {
        return typeof sellingMethod === 'string' && sellingMethod?.startsWith(procedureHelper.SELLING_METHOD_ALIENATION_ENGLISH)
    },

// "Комерційні торги - продаж (англійський аукціон)" - "commercialSell-english"
    isCSE: function isCSE(sellingMethod) {
        return typeof sellingMethod === 'string' && sellingMethod?.startsWith(procedureHelper.SELLING_METHOD_COMMERCIAL_SELL_ENGLISH)
    },

// "Комерційні торги - продаж (голландський аукціон)" - "commercialSell-dutch"
    isCSD: function isCSD(sellingMethod) {
        return typeof sellingMethod === 'string' && sellingMethod?.startsWith(procedureHelper.SELLING_METHOD_COMMERCIAL_SELL_DUTCH)
    },

//Арештоване майно
    isAAE: function isAAE(sellingMethod) {
        return typeof sellingMethod === 'string' && sellingMethod?.startsWith(procedureHelper.SELLING_METHOD_ARRESTED_ASSETS_ENGLISH)
    },

// Продаж майна боржників у справах про банкрутство (Банкрутство - англійський аукціон)
    isBRE: function isBRE(sellingMethod) {
        return typeof sellingMethod === 'string' && sellingMethod?.startsWith(procedureHelper.SELLING_METHOD_BANKRUPTCY_ENGLISH)
    },

// Продаж майна боржників у справах про банкрутство (Банкрутство - голландський аукціон)
    isBRD: function isBRD(sellingMethod) {
        return typeof sellingMethod === 'string' && sellingMethod?.startsWith(procedureHelper.SELLING_METHOD_BANKRUPTCY_DUTCH)
    },

// Продаж майна боржників у справах про банкрутство (Банкрутство - Відсутні заяви на участь і аукціон)
    isBRW: function isBRW(sellingMethod) {
        return typeof sellingMethod === 'string' && sellingMethod?.startsWith(procedureHelper.SELLING_METHOD_BANKRUPTCY_WITHOUT_AUCTION)
    },

    isRLE: function isRLE(sellingMethod) {
        return typeof sellingMethod === 'string' && sellingMethod?.startsWith(procedureHelper.SELLING_METHOD_REGULATIONS_PROPERTY_LEASE_ENGLISH)
    },

    isRLD: function isRLD(sellingMethod) {
        return typeof sellingMethod === 'string' && sellingMethod?.startsWith(procedureHelper.SELLING_METHOD_REGULATIONS_PROPERTY_LEASE_DUTCH)
    },

    isCLE: function isCLE(sellingMethod) {
        return typeof sellingMethod === 'string' && sellingMethod?.startsWith(procedureHelper.SELLING_METHOD_COMMERCIAL_PROPERTY_LEASE_ENGLISH)
    },

    isCLD: function isCLD(sellingMethod) {
        return typeof sellingMethod === 'string' && sellingMethod?.startsWith(procedureHelper.SELLING_METHOD_COMMERCIAL_PROPERTY_LEASE_DUTCH)
    },

    isSPE: function isSPE(sellingMethod) {
        return typeof sellingMethod === 'string' && sellingMethod?.startsWith(procedureHelper.SELLING_METHOD_SMALL_PRIVATIZATION_ENGLISH)
    },

    isSPD: function isSPD(sellingMethod) {
        return typeof sellingMethod === 'string' && sellingMethod?.startsWith(procedureHelper.SELLING_METHOD_SMALL_PRIVATIZATION_DUTCH)
    },

    isNLE: function isNLE(sellingMethod) {
        return typeof sellingMethod === 'string' && sellingMethod?.startsWith(procedureHelper.SELLING_METHOD_NONPERFORMING_LOANS_ENGLISH)
    },

// "Базовий продаж" - "basicSell-dutch"
    isNLD: function isNLD(sellingMethod) {
        return typeof sellingMethod === 'string' && sellingMethod?.startsWith(procedureHelper.SELLING_METHOD_NONPERFORMING_LOANS_DUTCH)
    },

    isLAE: function isLAE(sellingMethod) {
        return typeof sellingMethod === 'string' && sellingMethod?.startsWith(procedureHelper.SELLING_METHOD_LAND_ARRESTED_ENGLISH)
    },

    isLAW: function isLAW(sellingMethod) {
        return typeof sellingMethod === 'string' && sellingMethod?.startsWith(procedureHelper.SELLING_METHOD_LAND_ARRESTED_WITHOUT_AUCTION)
    },

    isLAP: function isLAP(sellingMethod) {
        return typeof sellingMethod === 'string' && sellingMethod?.startsWith(procedureHelper.SELLING_METHOD_LAND_ARRESTED_PRIORITY_ENGLISH)
    },

    isLPE: function isLPE(sellingMethod) {
        return typeof sellingMethod === 'string' && sellingMethod?.startsWith(procedureHelper.SELLING_METHOD_LARGE_PRIVATIZATION_ENGLISH)
    },

    isAPE: function isAPE(sellingMethod) {
        return typeof sellingMethod === 'string' && sellingMethod?.startsWith(procedureHelper.SELLING_METHOD_ARMA_PROPERTY_ENGLISH)
    },

    isAPD: function isAPD(sellingMethod) {
        return typeof sellingMethod === 'string' && sellingMethod?.startsWith(procedureHelper.SELLING_METHOD_ARMA_PROPERTY_DUTCH)
    },

    isREM: function isREM(sellingMethod) {
        return typeof sellingMethod === 'string' && sellingMethod?.startsWith(procedureHelper.SELLING_METHOD_RENEWABLES_MULTI_AWARDS)
    },
}

export default procedureHelper