import React from 'react';
import styles from './tabs.module.css';

import { Link } from 'react-router-dom';
import {connect} from 'react-redux';

import { isLAW, isLPE } from '../../../redux/procedure';

const InformationAboutTheOrganizer = (props) => {
    let tmpOgol = null
    let tmpZaput = null
    let tmpRezul = null
    if(window.location.pathname.indexOf('/questions') !== -1){
        tmpOgol = null
        tmpZaput = styles.activeNavItem
        tmpRezul = null
    }else if(window.location.pathname.indexOf('/auctionResult') !== -1){
        tmpOgol = null
        tmpZaput = null
        tmpRezul = styles.activeNavItem
    }else{
        tmpOgol = styles.activeNavItem
        tmpZaput = null
        tmpRezul = null
    }
    let count = 0
    if(props.auctionId !== null){
        if(props.auctionId.questions !== null){
            count = props.auctionId.questions.length
        }        
    }
    return (
        <div className={styles.aucNav}>
            <Link to={`/auctions/${props.auctionId.auctionId}`}>
                <div className={styles.aucNavItem + ' ' + tmpOgol}>
                    Оголошення
                </div>
            </Link>
            {isLAW(props.auctionId.sellingMethod) !== true && isLPE(props.auctionId.sellingMethod) !== true ? <Link to={`/auctions/${props.auctionId.auctionId}/questions`}>
                <div className={styles.aucNavItem + ' ' + tmpZaput}>
                    Обговорення ({count})
                </div>
            </Link> : null}
            <Link to={`/auctions/${props.auctionId.auctionId}/auctionResult`}>
                <div className={styles.aucNavItem + ' ' + tmpRezul}>
                    Результат аукціону
                </div>
            </Link>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        //auctionId: state.start.auctionsGetId,
        auctionId: state.start.auctionFull,
        pageNumber: state.start.pageNumber
    }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(InformationAboutTheOrganizer)
