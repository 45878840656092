import React from 'react';

import {connect} from 'react-redux';

import {changeAuctionsSwitchWindow, auctionsGetMy, setPopUpSendAuction, setPopUpErrorAuction} from '../../redux/actions/auctions/auctions.js';
import {classificationsGetByScheme} from '../../redux/actions/classifications/classifications.js';
import {
    getMyAuction,
    changeCreateAuctionTitle,
    changeCreateAuctionDescription,
    changeCreateAuctionType,
    changeCreateAuctionTenderAttempts,
    changeCreateAuctionAccessDetails,
    changeCreateAuctionXDocumentRequirements,
    changeCreateAuctionXAdditionalInformation,
    changeCreateAuctionValueCurrency,
    changeCreateAuctionValueAmount,
    changeCreateAuctionValueTax,
    changeCreateAuctionGuaranteeCurrency,
    changeCreateAuctionGuaranteeAmount,
    changeCreateAuctionGuaranteeTax,
    changeCreateAuctionMinimalStepCurrency,
    changeCreateAuctionMinimalStepAmount,
    changeCreateAuctionMinimalStepTax,
    changeCreateAuctionDateStart,
    changeCreateAuctionDateEnd,
    changeCreateAuctionId,
    setEditAuctionFromId,
} from '../../redux/actions.js';

import {
    changeDiscountPercent,
    changeDiscountPreviousAuctionCurrency,
    changeDiscountPreviousAuctionAmount,
    changeDiscountPreviousAuctionId,
    discountAuc,
    changeDiscountId,
} from '../../redux/actions/discount/discount.js';

import {
    setDataAddress,
} from '../../redux/createAddress';


//import {auctionGetId} from '../../redux/actions/auctions/auctions.js';

import {setUniversalError} from '../../redux/actions.js';

import {auctionsGetAwards, auctionsGetAwardsId} from '../../redux/actions/awards/awards.js';
import {getAuctionsBidsId} from '../../redux/actions/auctionBid/auctionBid.js';
import {contractsAuctionIdGet} from '../../redux/actions/contracts/contracts.js';
import {setPopBankAccountId, setPopBankAccountIdArr, setBankAccountSave, setBankAccountAdd, setBankAccountDelete} from '../../redux/actions/bankAccount/bankAccount.js';
import {oldArrDocumentGet} from '../../redux/actions/documents/documents.js';

import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';

import {getAPI} from '../../redux/actions/crud.js';

const ButtonEditAuction = ( props ) => {
    return (
        <div
            title="Робота з аукціоном"
            onClick={
                () => {
                    createButton(props)
                }
            }
        >
            <IconButton aria-label="edit">
                <EditIcon/>
            </IconButton>
        </div>
    )
}

async function createButton(props){
    props.setBankAccountSave([])
    props.setBankAccountAdd([])
    props.setBankAccountDelete([])
    createArrayBankAccounts(props, props.i)
    if(props.i.discount !== null){
        props.changeDiscountPercent(props.i.discount.discountPercent)
        props.changeDiscountId(props.i.discount.id)
        props.discountAuc(props.i.discount.discount)
        if(props.i.discount.previousAuctionValue !== null){
            props.changeDiscountPreviousAuctionCurrency(props.i.discount.previousAuctionValue.currency)
            props.changeDiscountPreviousAuctionAmount(props.i.discount.previousAuctionValue.amount)
        }
    }
    props.changeDiscountPreviousAuctionId(props.i.previousAuctionId)
    //if(props.auctionEdit === null){
        await getAucionFromId(props, props.i.id)
        //props.changeAuctionsSwitchWindow(1)
    //}
}


function createArrayBankAccounts(props, i){
    let tmp = [];
    if(i !== null){
        if(i.bankAccounts !== null || i.bankAccounts.length > 0){
            for(let t = 0; t < i.bankAccounts.length; t++){
                tmp[t] = i.bankAccounts[t].id
            }
        }
    }
    if(tmp !== null){
        props.setBankAccountSave(tmp)
        props.setPopBankAccountIdArr(tmp)
    }
}


////////////////////////////
async function getAucionFromId(props, aucId){
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/auction/${aucId}`;
    const response = await fetch(endPoint, {
        method: "GET",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){
            let tmp = JSON.stringify({title: 'Помилка при завантаженні аукціону', message: JSON.stringify(json)})
	        props.setUniversalError(tmp)
        }else{
            //props.setEditAuctionFromId(null)
            //props.setEditAuctionFromId(json)
            await setAuctionEdit(props, json)
            await discountUpdate(props, json)
            await getAddressOrg(props, json)
        }
    }
}

async function setAuctionEdit(props, data){
    //props.setEditAuctionFromId(data)
    props.setDataAddress(data, 'EDIT_AUCTION_FROM_ID')
    props.setDataAddress(data, 'CREATE_AUCTIONS_ZU_ATTEMP')
    props.changeDiscountPreviousAuctionId(data.auctionId)
}

async function discountUpdate(props, data){
    if(data.previousAuctionId !== null){
        props.changeDiscountPreviousAuctionId(data.previousAuctionId)
        if(data.discount !== null){
            if(data.discount.previousAuctionValue !== null){
                props.changeDiscountPreviousAuctionAmount(data.discount.previousAuctionValue.amount)
            }
            props.discountAuc(data.discount.discount)
            props.changeDiscountId(data.discount.id)
            props.changeDiscountPercent(data.discount.discountPercent)
        }
    }
}

async function getAddressOrg(props, auction){
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/organizations?limit=30&offset=0`
    const response = await fetch(endPoint, {
        method: "GET",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){
            let tmp = JSON.stringify({title: 'Помилка при завантаженні аукціону', message: JSON.stringify(json)})
	        props.setUniversalError(tmp)
        }else{
            let tmpArr = json
            if(auction.relatedOrganizations !== null && auction.relatedOrganizations !== 'null'){
                if(auction.relatedOrganizations.currentTenant !== null){
                    let flag = false
                    for(let i = 0; i < tmpArr.length; i++){
                        if(tmpArr[i].id === auction.relatedOrganizations.currentTenant.id){
                            flag = true
                        }
                    }
                    if(flag === false){
                        let tmpObj = auction.relatedOrganizations.currentTenant
                        tmpObj.name = tmpObj.contactPoint.name 
                        tmpArr.push(tmpObj)
                        //props.setDataAddress(tmpArr, 'GET_ADDRESS_NEW_BOOKS')
                    }else{
                        //props.setDataAddress(json, 'GET_ADDRESS_NEW_BOOKS')
                    }
                }else{
                    //props.setDataAddress(json, 'GET_ADDRESS_NEW_BOOKS')
                }
                if(auction.relatedOrganizations.sellingEntity !== null){
                    let flag = false
                    for(let i = 0; i < tmpArr.length; i++){
                        if(tmpArr[i].id === auction.relatedOrganizations.sellingEntity.id){
                            flag = true
                        }
                    }
                    if(flag === false){
                        let tmpObj = auction.relatedOrganizations.sellingEntity
                        tmpObj.name = tmpObj.contactPoint.name 
                        tmpArr.push(tmpObj)
                        //props.setDataAddress(tmpArr, 'GET_ADDRESS_NEW_BOOKS')
                    }else{
                        //props.setDataAddress(json, 'GET_ADDRESS_NEW_BOOKS')
                    }
                }else{
                    //props.setDataAddress(json, 'GET_ADDRESS_NEW_BOOKS')
                }
                if(auction.relatedOrganizations.propertyOwner !== null){
                    let flag = false
                    for(let i = 0; i < tmpArr.length; i++){
                        if(tmpArr[i].id === auction.relatedOrganizations.propertyOwner.id){
                            flag = true
                        }
                    }
                    if(flag === false){
                        let tmpObj = auction.relatedOrganizations.propertyOwner
                        tmpObj.name = tmpObj.contactPoint.name 
                        tmpArr.push(tmpObj)
                        //props.setDataAddress(tmpArr, 'GET_ADDRESS_NEW_BOOKS')
                    }else{
                        //props.setDataAddress(json, 'GET_ADDRESS_NEW_BOOKS')
                    }
                }else{
                    //props.setDataAddress(json, 'GET_ADDRESS_NEW_BOOKS')
                }
            }
            props.setDataAddress(tmpArr, 'GET_ADDRESS_NEW_BOOKS')
        }
    }
    props.changeAuctionsSwitchWindow(1)
}

const mapStateToProps = state => {
    return {
        token: state.start.token,
        auctions: state.start.auctionsGet,
        auctionsMy: state.start.auctionsGetMy,
        langFlag: state.start.langFlag,
        createAuctionTab: state.start.createAuctionTab,
        auctionsSwitchWindow: state.start.auctionsSwitchWindow,
        auctionEdit: state.start.auctionsMy,
        auctionsMyNew: state.start.auctionsMyNew,
        userProfiles: state.start.userProfiles,
        myBids: state.start.myBids,
    }
}

const mapDispatchToProps = {
    changeAuctionsSwitchWindow,

    getMyAuction,

    changeCreateAuctionTitle,
    changeCreateAuctionDescription,
    changeCreateAuctionType,
    changeCreateAuctionTenderAttempts,
    changeCreateAuctionAccessDetails,
    changeCreateAuctionXDocumentRequirements,
    changeCreateAuctionXAdditionalInformation,
    changeCreateAuctionValueCurrency,
    changeCreateAuctionValueAmount,
    changeCreateAuctionValueTax,
    changeCreateAuctionGuaranteeCurrency,
    changeCreateAuctionGuaranteeAmount,
    changeCreateAuctionGuaranteeTax,
    changeCreateAuctionMinimalStepCurrency,
    changeCreateAuctionMinimalStepAmount,
    changeCreateAuctionMinimalStepTax,
    changeCreateAuctionDateStart,
    changeCreateAuctionDateEnd,
    changeCreateAuctionId,

    classificationsGetByScheme,
    auctionsGetMy,
    getAPI,
    setPopUpSendAuction,

    auctionsGetAwards,
    auctionsGetAwardsId,
    getAuctionsBidsId,

    contractsAuctionIdGet,
    setPopUpErrorAuction,
    setPopBankAccountId,
    setPopBankAccountIdArr,

    oldArrDocumentGet,

    changeDiscountId,
    changeDiscountPercent,
    changeDiscountPreviousAuctionCurrency,
    changeDiscountPreviousAuctionAmount,
    changeDiscountPreviousAuctionId,
    discountAuc,

    setUniversalError,
    setEditAuctionFromId,

    setBankAccountSave,
    setBankAccountAdd,
    setBankAccountDelete,

    setDataAddress,
}

export default connect(mapStateToProps, mapDispatchToProps)(ButtonEditAuction)
