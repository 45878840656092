import React from 'react';
import styles from './windowAuction.module.css';

import {connect} from 'react-redux';

import {
    setDataAddress,
} from '../../../redux/createAddress';

import TextField from '@material-ui/core/TextField';
import {TranslationsUk} from '../../../redux/translations.uk.js';

import {disabledElement} from '../disabled'

const СurrentTenantGuaranteeFee = ( props ) => {
    let tmp = null;
    let tmpValue = 'null'
    if(props.auctionsMy !== null){
        if(props.auctionsMy.currentTenantGuaranteeFee !== null){
            if(props.auctionsMy.currentTenantGuaranteeFee.currency !== null){
                tmpValue = props.auctionsMy.currentTenantGuaranteeFee.currency
            }
        }
    }
    if(props.typeElem === 1){
        tmp = <div className={styles.group}>
            <div className={styles.titleValue}>{viewNameInput(props)}</div>
            <div className={styles.groupElem}>
                <div className={styles.valuta}>
                    <div>{TranslationsUk['legitimatePropertyLease-english'].legitimatePropertyLeaseEnglishProcedure.value.currency.legalName}</div>
                    <TextField
                        className={styles.valutaInput}
                        disabled
                        id="outlined-select-currency-native"
                        select
                        value={tmpValue}
                        SelectProps={{
                            native: true,
                        }}
                        helperText="Тип валюти"
                        variant="outlined"
                    >
                        {viewOptionCurrentCost(props, 1)}
                    </TextField>
                </div>
                {summZU(props)}
            </div>
        </div>
    }else{
        tmp = <div className={styles.group}>
            <div className={styles.titleValue}>{viewNameInput(props)}</div>
            <div className={styles.groupElem}>
                <div className={styles.valuta}>
                    <div>{TranslationsUk['legitimatePropertyLease-english'].legitimatePropertyLeaseEnglishProcedure.value.currency.legalName}</div>
                    <TextField
                        className={styles.valutaInput}
                        disabled
                        id="outlined-select-currency-native"
                        select
                        value={tmpValue}
                        SelectProps={{
                            native: true,
                        }}
                        helperText="Тип валюти"
                        variant="outlined"
                    >
                        {viewOptionCurrentCost(props, 1)}
                    </TextField>
                </div>
                {summZU(props)}
            </div>
        </div>
    }
    return tmp
}

function viewNameInput(props){
    if(
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-auction-manual-qualification" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification-prod"
    ){
        return 'Гарантійний внесок чинного орендаря'
    }
    return null
}

function viewOptionCurrentCost(props, position){
    let tmp = null;
    switch(props.auctionsMy.sellingMethod){
        case "legitimatePropertyLease-priorityEnglish":
        case "legitimatePropertyLease-priorityEnglish-fast":
        case "legitimatePropertyLease-priorityEnglish-fast-manual":
        case "legitimatePropertyLease-priorityEnglish-fast-auction-manual-qualification":
        case "legitimatePropertyLease-priorityEnglish-initial-auction":
        case "legitimatePropertyLease-priorityEnglish-initial-auction-manual":
        case "legitimatePropertyLease-priorityEnglish-initial-qualification":
        case "legitimatePropertyLease-priorityEnglish-initial-qualification-prod":
            tmp = <>
                <option key={1} value="UAH">ГРН</option>
            </>
            break;
        default:
            tmp = <>
                <option key={1} value="UAH">ГРН</option>
            </>
            break;
    }
    return tmp
}

function summZU(props){
    let tmpAmount = ''
    if(props.auctionsMy !== null){
        if(props.auctionsMy.currentTenantGuaranteeFee !== null){
            if(props.auctionsMy.currentTenantGuaranteeFee.amount !== null){
                tmpAmount = props.auctionsMy.currentTenantGuaranteeFee.amount
            }
        }
    }
    if(
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-auction-manual-qualification" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification-prod"
    ){
        if(props.auctionsZUAttemp !== null){
            if(props.auctionsZUAttemp.tenderAttempts === 1 && props.auctionsMy.tenderAttempts === '2'){
                //let tmp = tmpAmount / 2
                let tmp = tmpAmount
                return (
                    <div>
                        <div>{TranslationsUk['legitimatePropertyLease-english'].legitimatePropertyLeaseEnglishProcedure.value.amount.legalName}</div>
                        <TextField
                            error = {redBlock(props, tmpAmount)}
                            disabled='disabled'
                            value={tmp}
                            variant="outlined"
                            helperText=" "
                        />
                    </div>
                )
            }
        }
    }
    if(props.typeElem === 1){
        return (
            <div>
                <div>{TranslationsUk['legitimatePropertyLease-english'].legitimatePropertyLeaseEnglishProcedure.value.amount.legalName}</div>
                <TextField
                    error = {redBlock(props, tmpAmount)}
                    disabled={disabledElement(props.auctionsMy, '01')}
                    value={tmpAmount}
                    onChange={(event) => {
                        let reg=/[a-zA-Zа-яА-Яа-яіІїЇєЄ]/;
                        if(reg.test(event.target.value) !== true) props.setDataAddress(convertStrToNumber(event.target.value), 'SET_CURRENT_TENANT_GUARANTEE_FEE_VALUE')
                        if(
                            props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish" ||
                            props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast" ||
                            props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-manual" ||
                            props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-auction-manual-qualification" ||
                            props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction" ||
                            props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction-manual" ||
                            props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification" ||
                            props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification-prod"
                        ){
                            //let tmpGaranty = (event.target.value / 100) * 5
                            //props.changeCreateAuctionGuaranteeAmount(tmpGaranty.toFixed(2))
                        }
                    }}
                    variant="outlined"
                    helperText=" "
                />
            </div>
        )
    }else{
        return (
            <div>
                <div>{TranslationsUk['legitimatePropertyLease-english'].legitimatePropertyLeaseEnglishProcedure.value.amount.legalName}</div>
                <TextField
                    error = {redBlock(props, tmpAmount)}
                    value={tmpAmount}
                    onChange={(event) => {
                        let reg=/[a-zA-Zа-яА-Яа-яіІїЇєЄ]/;
                        if(reg.test(event.target.value) !== true) props.setDataAddress(convertStrToNumber(event.target.value), 'SET_CURRENT_TENANT_GUARANTEE_FEE_VALUE')
                        if(
                            props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish" ||
                            props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast" ||
                            props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-manual" ||
                            props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-auction-manual-qualification" ||
                            props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction" ||
                            props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction-manual" ||
                            props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification" ||
                            props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification-prod"
                        ){
                            //let tmpGaranty = (event.target.value / 100) * 5
                            //props.changeCreateAuctionGuaranteeAmount(tmpGaranty.toFixed(2))
                            
                        }
                    }}
                    variant="outlined"
                    helperText=" "
                />
            </div>
        )
    }
}

function redBlock(props, amount){
    if(
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-fast" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-fast-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-fast-auction-manual-qualification" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-auction" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-auction-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-qualification" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-qualification-prod" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-fast" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-fast-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-fast-auction-manual-qualification" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-initial-auction" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-initial-auction-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-initial-qualification-prod" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-auction-manual-qualification" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification-prod"
    ){
        if(amount === null || amount === '' || amount === 'null'){
            return 'error'
        }
        return null
    }
    return null
}

function convertStrToNumber(data){
    let str = ''
    if(data.indexOf(',') !== -1){
        str = data.replace(',', '.')
    }else{
        str = data
    }
    let strArr = null
    if(str.indexOf('.') !== -1){
        strArr = str.split('.')
        if(strArr.length < 3){
            if(strArr[1].length < 3){
                return str
            }else{
                return strArr[0] + '.' + strArr[1].slice(0, 2)
            }
        }else{
            if(strArr[1].length === 0){
                return strArr[0] + '.'
            }else if(strArr[1].length === 1){
                return strArr[0] + '.' + strArr[1].slice(0, 1)
            }else if(strArr[1].length === 2){
                return strArr[0] + '.' + strArr[1].slice(0, 2)
            }    
        }
    }
    return str
}

const mapStateToProps = state => {
    return {
        auctionsMy: state.start.auctionsMy,
        auctionsZUAttemp: state.start.auctionsZUAttemp,
    }
}

const mapDispatchToProps = {
    setDataAddress,
}

export default connect(mapStateToProps, mapDispatchToProps)(СurrentTenantGuaranteeFee)