import React from 'react';

import {connect} from 'react-redux';

import {changeAwardRejectPopUpStatus} from '../../../redux/actions/awards/awards.js';

import { setAwardAllUserPopUp, setAwardAllUserPopUpUser } from '../../../redux/actions.js';
import {
    isSUE,
    isBRE, isBRD, isBRW, isSPE, isSPD, isNLE, isNLD, isLAE, isLAW, isLAP, isLPE, isAPE, isAPD, isREM
} from '../../../redux/procedure';

import {
    changeContractApprowePopUpStatus,
    setContractTitle,
    setContractDescription,
    setContractContractNumber,
    setContractDateFrom,
    setContractDateTill,
    setContractStartDate,
    setContractEndDate,
    setContractCurrency,
    setContractAmount,
    setContractDateSigned,
    setContractDatePaid,
    setContractDocId,
    setContractDocTitle,
    setContractDocType,
    setContractDocDescription,
    setContractDocDocumentOf,
} from '../../../redux/actions/contracts/contracts.js';

import { saveArrBids } from '../../../redux/allAuctionBids';
import { setDataAddress } from '../../../redux/createAddress';

import {
    historyAuctions,
    historyAwards,
    historyContrasts,
    historyBids,
} from '../../../redux/history.js';
import {setUniversalError, changeLoader} from '../../../redux/actions.js';
import {contractsAuctionIdGet} from '../../../redux/actions/contracts/contracts.js';

import AwardSPE from './newAwardSPE'
import AwardBREBRD from './newAwardBREBRD'
import AwardBRW from './newAwardBRW'
import AwardSUE from './newAwardSUE'
import AwardNLENLD from './newAwardNLENLD'
import AwardLAE from './newAwardLAE'
import AwardLAW from './newAwardLAW'
import AwardLAP from './newAwardLAP'
import AwardLPE from './newAwardLPE'
import AwardAPE from './newAwardAPE'
import AwardAPD from './newAwardAPD'
import AwardREM from './AwardREM'
import AwardOther from './newAwardOther'

const Contract = (props) => {
    if(isSPE(props.auctionId.sellingMethod) || isSPD(props.auctionId.sellingMethod)){
        return <AwardSPE />
    }
    if(isBRE(props.auctionId.sellingMethod) || isBRD(props.auctionId.sellingMethod)){
        return <AwardBREBRD />
    }
    if(isBRW(props.auctionId.sellingMethod)){
        return <AwardBRW />
    }
    if(isSUE(props.auctionId.sellingMethod)){
        return <AwardSUE />
    }
    if(isNLE(props.auctionId.sellingMethod) || isNLD(props.auctionId.sellingMethod)){
        return <AwardNLENLD />    
    }
    if(isLAE(props.auctionId.sellingMethod)){
        return <AwardLAE />    
    }
    if(isLAW(props.auctionId.sellingMethod)){
        return <AwardLAW />    
    }
    if(isLAP(props.auctionId.sellingMethod)){
        return <AwardLAP />
    }
    if(isLPE(props.auctionId.sellingMethod)){
        return <AwardLPE />
    }
    if(isAPE(props.auctionId.sellingMethod)){
        return <AwardAPE />
    }
    if(isAPD(props.auctionId.sellingMethod)){
        return <AwardAPD />
    }
    if(isREM(props.auctionId.sellingMethod)){
        return <AwardREM />
    }
    return <AwardOther />
}

const mapStateToProps = state => {
    return {
        token: state.start.token,
        langFlag: state.start.langFlag,
        auctionAwards: state.start.auctionAwards,
        allUserPopUp: state.start.allUserPopUp,
        allUserPopUpUser: state.start.allUserPopUpUser,
        contractAuctionIdGet: state.start.contractAuctionIdGet,
        timberContractPut: state.start.timberContractPut,
        pageNumber: state.start.pageNumber,
        auctionsGetId: state.start.auctionsGetId,
        auctionsAllBids: state.start.auctionsAllBids,
        auctionId: state.start.auctionFull,
        bidEdit: state.start.bidEdit,
        userProfiles: state.start.userProfiles
    }
}

const mapDispatchToProps = {
    changeAwardRejectPopUpStatus,
    setAwardAllUserPopUp,
    setAwardAllUserPopUpUser,

    changeContractApprowePopUpStatus,

    setContractTitle,
    setContractDescription,
    setContractContractNumber,
    setContractDateFrom,
    setContractDateTill,
    setContractStartDate,
    setContractEndDate,
    setContractCurrency,
    setContractAmount,
    setContractDateSigned,

    historyAuctions,
    historyAwards,
    historyContrasts,
    historyBids,

    setUniversalError,
    changeLoader,
    contractsAuctionIdGet,
    setContractDatePaid,

    setContractDocId,
    setContractDocTitle,
    setContractDocType,
    setContractDocDescription,
    setContractDocDocumentOf,
    setDataAddress,
    saveArrBids
}

export default connect(mapStateToProps, mapDispatchToProps)(Contract)
