import React from 'react';
import styles from './windowAuction.module.css';

import {connect} from 'react-redux';

import {TranslationsUk} from '../../../redux/translations.uk.js';

import {
    setEditAuctionFromId,

    getMyAuction,
    changeLoader,
} from '../../../redux/actions.js';

import {
    changeDiscountPercent,
    changeDiscountPreviousAuctionCurrency,
    changeDiscountPreviousAuctionAmount,
    changeDiscountPreviousAuctionId
} from '../../../redux/actions/discount/discount.js';

import {
    changeCreateAuctionMinNumberOfQualifiedBids,
    changeCreateAuctionDgfVirtualDataRoom,
    changeCreateAuctionDgfEligibilityCriteria,
    changeCreateAuctionDgfX_dgfPlatformLegalDetails,
    changeCreateAuctionDgfDecisionId,
    changeCreateAuctionDgfDecisionDate,
    changeCreateAuctionСalcOnBookValue,
    changeCreateAuctionValueAddedTaxCharged,
    changeCreateAuctionRegistryId,
    changeCreateAuctionValuePeriod,
    changeCreateAuctionLeaseRulesIntendedUse,
    changeCreateAuctionLeaseRulesIntendedUseRestrictionMethod,
    changeCreateAuctionLeaseRulesIntendedUseRestrictionDescription,
    changeCreateAuctionLeaseRulesAdditionalLeaseConditions,
    changeCreateAuctionLeaseRulesSubleaseAllowed,
} from '../../../redux/actions.js';

import {
    setDataAddress,
} from '../../../redux/createAddress';

import {modifiDateString} from '../../../redux/modifiDate'

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import RegistrationFee from './windowAuctionRegistrationFee'
import Organization from './zuAddressOrganization'
import { DateTimePicker, KeyboardDateTimePicker } from "@material-ui/pickers";

import RelatedOrganizationsOwnershipType from './auctionsCommon/zuRelatedOrganizationsOwnershipType'

import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';

import СurrentTenantGuaranteeFee from './currentTenantGuaranteeFee'

import {disabledElement} from '../disabled'

import LeaseRules from './ZU/leaseRules'
import RelaitedOrganisations from './ZU/relatedOrganizations'

const WindowAuctionDgf = ( props ) => {
    let tmp = null;
    let tmpPopUp = null
    if(props.addressNew.openPopUp === true){
        tmpPopUp = <Organization />
    }

    let tmpСurrentTenantGuaranteeFee = null
    if(
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-auction-manual-qualification" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification-prod"
    ){
        tmpСurrentTenantGuaranteeFee = <СurrentTenantGuaranteeFee />
    }
    if(props.typeElem === 1){
        let tmpValuePeriod = 'null'
        if(props.auctionsMy.valuePeriod !== 'null' && props.auctionsMy.valuePeriod !== null && props.auctionsMy.valuePeriod !== ''){
            tmpValuePeriod = tmpValuePeriod = props.auctionsMy.valuePeriod
        }
        tmp = <>
            {tmpСurrentTenantGuaranteeFee}
            <div className={styles.groupElem}>
                <div>
                    <TextField
                        required
                        disabled={disabledElement(props.auctionsMy, '00')}
                        className={styles.inputText + ' ' + styles.inputTextSmall}
                        id="outlined-select-currency-native"
                        select
                        label='Стартова орендна плата розрахована від балансової вартості'
                        value={props.auctionsMy.calcOnBookValue}
                        onChange={(event)=>{
                            props.changeCreateAuctionСalcOnBookValue(event.target.value)
                        }}
                        variant="outlined"
                    >
                        <option key={0} className={styles.hoverOptions} selected value={true}>Так</option>
                        <option key={1} className={styles.hoverOptions} value={false}>Ні</option>
                    </TextField>
                </div>
            </div>
            <div className={styles.groupElem}>
                <div>
                    <TextField
                        required
                        disabled={disabledElement(props.auctionsMy, '00')}
                        className={styles.inputText}
                        id="outlined-select-currency-native"
                        select
                        label={TranslationsUk['legitimatePropertyLease-english'].legitimatePropertyLeaseEnglishProcedure.valueAddedTaxCharged.legalName}
                        defaultValue={props.auctionsMy.valueAddedTaxCharged}
                        onChange={(e)=>{
                            props.changeCreateAuctionValueAddedTaxCharged(e.target.value)
                        }}
                        variant="outlined"
                    >
                        <option key={0} className={styles.hoverOptions} selected value={true}>Так</option>
                        <option key={1} className={styles.hoverOptions} value={false}>Ні</option>
                    </TextField>
                </div>
            </div>
            <div className={styles.groupElem}>
                <div>
                    <TextField
                        error = {redBlock(props, props.auctionsMy.registryId)}
                        disabled='disabled'
                        required
                        id="filled-required"
                        className={styles.inputText}
                        label="Ідентифікатор об'єкта"
                        defaultValue={props.auctionsMy.registryId}
                        variant="outlined"
                        onChange={
                            (e) => {
                                props.changeCreateAuctionRegistryId(e.target.value)
                                if(e.target.value !== ''){
                                    getObjectId(props, e.target.value)
                                    //createObject(props, e.target.value)
                                }
                            }
                        }
                        helperText=""
                    />
                </div>
            </div>
            <RegistrationFee />
            <div className={styles.groupElem}>
                <div>
                    <TextField
                        disabled={disabledElement(props.auctionsMy, '00')}
                        className={styles.inputText}
                        id="outlined-select-currency-native"
                        select
                        label='Період розрахунку стартової вартості'
                        value={tmpValuePeriod}
                        onChange={(e)=>{
                            props.changeCreateAuctionValuePeriod(e.target.value)
                        }}
                        variant="outlined"
                    >
                        <option key={0} className={styles.hoverOptions} selected value='null'>Обрати</option>
                        <option key={2} className={styles.hoverOptions} value='hour'>Стартова орендна плата за годину</option>
                        <option key={1} className={styles.hoverOptions} value='day'>Стартова орендна плата за добу</option>
                        <option key={3} className={styles.hoverOptions} value='month'>Стартова орендна плата за місяць</option>
                    </TextField>
                </div>
            </div>
            {viewLesureRules(props)}
            <LeaseRules />
            <RelaitedOrganisations />
            {tmpPopUp}
        </>
    }else{
        let tmpValuePeriod = 'null'
        if(props.auctionsMy.valuePeriod !== 'null' && props.auctionsMy.valuePeriod !== null && props.auctionsMy.valuePeriod !== ''){
            tmpValuePeriod = tmpValuePeriod = props.auctionsMy.valuePeriod
        }
        tmp = <>
            {tmpСurrentTenantGuaranteeFee}
            <div className={styles.groupElem}>
                <div>
                    <TextField
                        disabled={disabledElement(props.auctionsMy, '00')}
                        required
                        className={styles.inputText + ' ' + styles.inputTextSmall}
                        id="outlined-select-currency-native"
                        select
                        label='Стартова орендна плата розрахована від балансової вартості'
                        value={props.auctionsMy.calcOnBookValue}
                        onChange={(event)=>{
                            props.changeCreateAuctionСalcOnBookValue(event.target.value)
                        }}
                        variant="outlined"
                    >
                        <option key={0} className={styles.hoverOptions} selected value={true}>Так</option>
                        <option key={1} className={styles.hoverOptions} value={false}>Ні</option>
                    </TextField>
                </div>
            </div>
            <div className={styles.groupElem}>
                <div>
                    <TextField
                        disabled={disabledElement(props.auctionsMy, '00')}
                        required
                        className={styles.inputText}
                        id="outlined-select-currency-native"
                        select
                        label={TranslationsUk['legitimatePropertyLease-english'].legitimatePropertyLeaseEnglishProcedure.valueAddedTaxCharged.legalName}
                        defaultValue={props.auctionsMy.valueAddedTaxCharged}
                        onChange={(e)=>{
                            props.changeCreateAuctionValueAddedTaxCharged(e.target.value)
                        }}
                        variant="outlined"
                    >
                        <option key={0} className={styles.hoverOptions} selected value={true}>Так</option>
                        <option key={1} className={styles.hoverOptions} value={false}>Ні</option>
                    </TextField>
                </div>
            </div>
            <div className={styles.groupElem}>
                <div>
                    <TextField
                        error = {redBlock(props, props.auctionsMy.registryId)}
                        required
                        disabled='disabled'
                        id="filled-required"
                        className={styles.inputText}
                        label="Ідентифікатор об'єкта"
                        defaultValue={props.auctionsMy.registryId}
                        variant="outlined"
                        onChange={
                            (e) => {
                                props.changeCreateAuctionRegistryId(e.target.value)
                                if(e.target.value !== '') {
                                    //getObjectId(props, e.target.value)
                                    //createObject(props, e.target.value)
                                }
                            }
                        }
                        helperText=""
                    />
                </div>
            </div>
            <RegistrationFee />
            <div className={styles.groupElem}>
                <div>
                    <TextField
                        disabled={disabledElement(props.auctionsMy, '00')}
                        className={styles.inputText}
                        id="outlined-select-currency-native"
                        select
                        label='Період розрахунку стартової вартості'
                        value={tmpValuePeriod}
                        onChange={(e)=>{
                            props.changeCreateAuctionValuePeriod(e.target.value)
                        }}
                        variant="outlined"
                    >
                        <option key={0} className={styles.hoverOptions} selected value='null'>Обрати</option>
                        <option key={2} className={styles.hoverOptions} value='hour'>Стартова орендна плата за годину</option>
                        <option key={1} className={styles.hoverOptions} value='day'>Стартова орендна плата за добу</option>
                        <option key={3} className={styles.hoverOptions} value='month'>Стартова орендна плата за місяць</option>
                    </TextField>
                </div>
            </div>
            {viewLesureRules(props)}
            <LeaseRules />
            <RelaitedOrganisations />
            {tmpPopUp}
        </>
    }
    return tmp
}

function redBlock(props, amount){
    if(
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-fast" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-fast-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-fast-auction-manual-qualification" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-auction" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-auction-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-qualification" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-qualification-prod" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-fast" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-fast-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-fast-auction-manual-qualification" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-initial-auction" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-initial-auction-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-initial-qualification-prod" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-auction-manual-qualification" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification-prod"
    ){
        if(amount === null || amount === '' || amount === 'null'){
            return 'error'
        }
        return null
    }
    return null
}

function disableShedule(props){
    let tmp = null
    if(
        props.schedule.hoursBy.length === 0 &&
        props.schedule.daysBy.length === 0 
    ){
        if(
            props.schedule.hoursBy.value !== '' &&
            props.schedule.hoursBy.by !== 'null' &&
            props.schedule.hoursBy.by !== null &&
            props.schedule.daysBy.value !== '' &&
            props.schedule.daysBy.by !== 'null' &&
            props.schedule.daysBy.by !== null
        ){
            tmp = null
        }else{
            tmp =<>
                <div>{TranslationsUk['legitimatePropertyLease-english'].legitimatePropertyLeaseEnglishProcedure.leaseRules.schedule.period.legalName}</div>
                <div className={styles.groupElem}>
                    <div>
                        <TextField
                            disabled={disabledElement(props.auctionsMy, '00')}
                            id="filled-required"
                            className={styles.inputText}
                            label={TranslationsUk['legitimatePropertyLease-english'].legitimatePropertyLeaseEnglishProcedure.leaseRules.schedule.other.legalName}
                            value={props.schedule.other}
                            variant="outlined"
                            onChange={
                                (e) => {
                                    props.setDataAddress(e.target.value, 'SET_SCHEDULE_OTHER')
                                }
                            }
                            helperText={textHelp(props)}
                        />
                    </div>
                </div>
            </>
        }
    }else{
        tmp =<>
            <div>{TranslationsUk['legitimatePropertyLease-english'].legitimatePropertyLeaseEnglishProcedure.leaseRules.schedule.period.legalName}</div>
            <div className={styles.groupElem}>
                <div>
                    <TextField
                        disabled={disabledElement(props.auctionsMy, '00')}
                        id="filled-required"
                        className={styles.inputText}
                        label={TranslationsUk['legitimatePropertyLease-english'].legitimatePropertyLeaseEnglishProcedure.leaseRules.schedule.other.legalName}
                        value={props.schedule.other}
                        variant="outlined"
                        onChange={
                            (e) => {
                                props.setDataAddress(e.target.value, 'SET_SCHEDULE_OTHER')
                            }
                        }
                        helperText={textHelp(props)}
                    />
                </div>
            </div>
        </>
    }
    let tmpSaveArr = null
    //if(props.schedule.other !== '' && props.schedule.other !== null){
        tmpSaveArr = <>
            <div>Збережені періоди</div>
            <div>{viewPeriodArr(props)}</div>
            <div>Не збережені періоди</div>
            <div>{viewNewPeriodArr(props)}</div>
            <div className={styles.bottonBlock}>
                <Button
                    disabled={disabledElement(props.auctionsMy, '00')}
                    className={styles.buttonExitDocument}
                    variant="contained"
                    color="primary"
                    onClick={
                        () => {
                            props.setDataAddress(true, 'SET_SHEDULE_PERIOD')
                        }
                    }
                >
                    Додати період
                </Button>
            </div>
        </>
    //}
    return ( 
        <>
            {tmp}
            {tmpSaveArr}
        </>
    )
}

function viewNewPeriodWeekdaysArr(data){
    if(data.length > 0){
        return data.map(
            (e) => {
                return (
                    <div className={styles.weekend_elem}>
                        <div className={styles.weekend_data}>День тижня: {e.weekday}</div>
                        <div className={styles.weekend_data}>Початок: {e.startTime}</div>
                        <div className={styles.weekend_data}>Кінець: {e.endTime}</div>
                    </div>
                )
            }
        )
    }
}

function viewNewPeriodArr(props){
    if(props.shedullePeriod.newFullPeriod.length > 0){
        return props.shedullePeriod.newFullPeriod.map(
            (e, count) => {
                let tmpWeekday = null
                if(Array.isArray(e.weekdays) === true){
                    if(e.weekdays.length > 0) tmpWeekday = viewNewPeriodWeekdaysArr(e.weekdays)
                }
                let tmpStart = null
                if(e.timeStart !== '') tmpStart = modifiDateString(e.timeStart, 1, true)
                let tmpEnd = null
                if(e.timeEnd !== '') tmpEnd = modifiDateString(e.timeEnd, 1, true)
                return (
                    <div className={styles.weekend_wrap}>
                        <div className={styles.weekend}>
                            <div className={styles.weekend_data}>Дата початку: {tmpStart}</div>
                            <div className={styles.weekend_data}>Дата завершення: {tmpEnd}</div>
                            <IconButton
                                disabled={disabledElement(props.auctionsMy, '01')}
                                aria-label="edit"
                                onClick={
                                    () => {
                                        let tmp = []
                                        let tmpCount = 0
                                        for(let i = 0; i < props.shedullePeriod.newFullPeriod.length; i++){
                                            if(i !== count){
                                                tmp[tmpCount] = props.shedullePeriod.newFullPeriod[i]
                                                tmpCount++
                                            }
                                        }
                                        props.setDataAddress(tmp, 'SET_NEW_FULL_PERIOD_ARR')
                                    }
                                }
                            >
                                <DeleteIcon/>
                            </IconButton>
                        </div>
                        <div>{tmpWeekday}</div>
                    </div>
                )
            }
        )
    }
}

function viewPeriodWeekdaysArr(data){
    if(data.length > 0){
        return data
        .filter(
            (e) => {
                return e !== null
            }
        )
        .map(
            (e) => {
                return (
                    <div className={styles.weekend_elem}>
                        <div className={styles.weekend_data}>День тижня: {e.weekday}</div>
                        <div className={styles.weekend_data}>Початок: {e.startTime}</div>
                        <div className={styles.weekend_data}>Кінець: {e.endTime}</div>
                    </div>
                )
            }
        )
    }
}

function viewPeriodArr(props){
    if(props.auctionsMy !== null){
        if(props.auctionsMy.leaseRules !== null){
            if(props.auctionsMy.leaseRules.hasOwnProperty('schedule') === true){
                if(props.auctionsMy.leaseRules.schedule !== null){
                    if(props.auctionsMy.leaseRules.schedule.hasOwnProperty('periods') === true){
                        if(props.auctionsMy.leaseRules.schedule.periods !== null){
                            if(props.auctionsMy.leaseRules.schedule.periods.length > 0){
                                return props.auctionsMy.leaseRules.schedule.periods.map(
                                    (e) => {
                                        let tmpWeekday = null
                                        if(e.weekdays.length > 0) tmpWeekday = viewPeriodWeekdaysArr(e.weekdays)
                                        return (
                                            <div className={styles.weekend_wrap}>
                                                <div className={styles.weekend}>
                                                    <div className={styles.weekend_data}>Дата початку: {modifiDateString(e.startDate, 1)}</div>
                                                    <div className={styles.weekend_data}>Дата завершення: {modifiDateString(e.endDate, 1)}</div>
                                                    <IconButton
                                                        disabled={disabledElement(props.auctionsMy, '00')}
                                                        aria-label="edit"
                                                        onClick={
                                                            () => {
                                                                props.setDataAddress(true, 'SET_SHEDULE_PERIOD')
                                                                props.setDataAddress(e.id, 'SET_SHEDULE_PERIOD_ID')
                                                                props.setDataAddress(e.weekdays, 'SET_NEW_PERIOD_ARR')
                                                                props.setDataAddress(e.startDate, 'SET_SCHEDULE_PERIOD_START_DATE')
                                                                props.setDataAddress(e.endDate, 'SET_SCHEDULE_PERIOD_END_DATE')
                                                                props.setDataAddress('null', 'SET_SCHEDULE_WEEKDAY')
                                                                props.setDataAddress('', 'SET_SCHEDULE_TIME_START')
                                                                props.setDataAddress('', 'SET_SCHEDULE_TIME_END')
                                                            }
                                                        }
                                                    >
                                                        <EditIcon/>
                                                    </IconButton>
                                                    <IconButton
                                                        disabled={disabledElement(props.auctionsMy, '00')}
                                                        aria-label="edit"
                                                        onClick={
                                                            () => {
                                                                deleteWeek(props, props.auctionsMy.id, e.id)
                                                            }
                                                        }
                                                    >
                                                        <DeleteIcon/>
                                                    </IconButton>
                                                </div>
                                                <div>{tmpWeekday}</div>
                                            </div>
                                        )
                                    }
                                )
                            }
                        }
                    }
                }
            }
        }
    }
}

async function deleteWeek(props, aucId, periodId){
    props.changeLoader(true)
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${aucId}/lease_rules/schedule/period/${periodId}`
    const response = await fetch(endPoint, {
        method: "DELETE",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json2 = await response.json()
        if(json2.hasOwnProperty('message') === true){

        }else{
            await props.getMyAuction(null)
            await getAucionFromId(props, aucId)
            await props.changeLoader(false)
        }
    }
}

function textHelp(props){
    if(
        props.schedule.hoursBy.value === '' ||
        props.schedule.hoursBy.by === 'null' ||
        props.schedule.daysBy.value === '' ||
        props.schedule.daysBy.by === 'null'
    ){
        if(props.schedule.other === ''){
            return 'Обов\'зкове поле'
        }
    }
}

function textData(value){
    /*let tmp = ''
    if(value !== ''){
        tmp = value.split('T');
        return tmp[0]
    }*/
    //return tmp
    return value
}

function viewRelatedOrganizations(props){
    if(props.auctionsMy !== null){
        let tmp = null
        if(
            /*props.auctionsMy.sellingMethod !== "legitimatePropertyLease-english" &&
            props.auctionsMy.sellingMethod !== "legitimatePropertyLease-english-fast" &&
            props.auctionsMy.sellingMethod !== "legitimatePropertyLease-english-fast-manual" &&
            props.auctionsMy.sellingMethod !== "legitimatePropertyLease-english-fast-auction-manual-qualification" &&
            props.auctionsMy.sellingMethod !== "legitimatePropertyLease-english-initial-auction" &&
            props.auctionsMy.sellingMethod !== "legitimatePropertyLease-english-initial-auction-manual" &&
            props.auctionsMy.sellingMethod !== "legitimatePropertyLease-english-initial-qualification" &&
            props.auctionsMy.sellingMethod !== "legitimatePropertyLease-english-initial-qualification-prod" &&
            props.auctionsMy.sellingMethod !== "legitimatePropertyLease-dutch" &&
            props.auctionsMy.sellingMethod !== "legitimatePropertyLease-dutch-fast" &&
            props.auctionsMy.sellingMethod !== "legitimatePropertyLease-dutch-fast-manual" &&
            props.auctionsMy.sellingMethod !== "legitimatePropertyLease-dutch-fast-auction-manual-qualification" &&
            props.auctionsMy.sellingMethod !== "legitimatePropertyLease-dutch-initial-auction" &&
            props.auctionsMy.sellingMethod !== "legitimatePropertyLease-dutch-initial-auction-manual" &&
            props.auctionsMy.sellingMethod !== "legitimatePropertyLease-dutch-initial-qualification-prod"*/
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-manual" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-auction-manual-qualification" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction-manual" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification-prod"
        ){
            tmp = viewCurrentTenant(props)
        }/*else{
            if(props.auctionsMy.relatedOrganizations !== null && props.addressNew.getAddress !== null){
                if(props.auctionsMy.relatedOrganizations.hasOwnProperty('currentTenant') === true){
                    if(props.auctionsMy.relatedOrganizations.currentTenant !== null){
                        tmp = viewCurrentTenant(props)
                    }
                }
            }
        }*/
        if(props.typeElem === 1){
            return (
                <>
                    {viewPropertyOwner(props)}
                    {viewSellingEntity(props)}
                    {tmp}
                    <RelatedOrganizationsOwnershipType typeElem={props.typeElem}/>
                </>
            )
        }else{
            return (
                <>
                    {viewPropertyOwner(props)}
                    {viewSellingEntity(props)}
                    {tmp}
                    <RelatedOrganizationsOwnershipType typeElem={props.typeElem}/>
                </>
            )
        }
    }
}

function viewPropertyOwner(props){
    let tmp = ''
    let tmpI = ''
    if(props.auctionsMy.relatedOrganizations !== null && props.addressNew.getAddress !== null){
        if(props.auctionsMy.relatedOrganizations.hasOwnProperty('propertyOwner') === true){
            if(props.auctionsMy.relatedOrganizations.propertyOwner !== null){
                if(props.auctionsMy.relatedOrganizations.propertyOwner.hasOwnProperty('id') === true){
                    /*let tmpArr = props.addressNew.getAddress
                    let flag = false
                    for(let i = 0; i < props.addressNew.getAddress.length; i++){
                        if(props.addressNew.getAddress[i].id === props.auctionsMy.relatedOrganizations.propertyOwner.id){
                            flag = true
                        }
                    }
                    if(flag === false){
                        let tmpObj = props.auctionsMy.relatedOrganizations.propertyOwner
                        tmpObj.name = tmpObj.contactPoint.name 
                        tmpArr.push(tmpObj)
                        props.setDataAddress(tmpArr, 'GET_ADDRESS_NEW_BOOKS')
                    }else{
                        tmp = props.auctionsMy.relatedOrganizations.propertyOwner.id
                    }*/
                    tmp = props.auctionsMy.relatedOrganizations.propertyOwner.id
                    tmpI = props.auctionsMy.relatedOrganizations.propertyOwner
                }
            }
        }
    }else{
        props.setDataAddress({
            propertyOwner: {id: 'null'},
            sellingEntity: {id: 'null'},
            currentTenant: {id: 'null'},
            ownershipType: 'null',
        }, 'SET_AUCTION_RELATED_ORGANIZATIONS')
    }
    if(props.typeElem === 1){
        return (
            <>
                <div className={styles.groupElem}>
                    <div>
                        <TextField
                            disabled={disabledElement(props.auctionsMy, '11')}
                            className={styles.inputText}
                            id="outlined-select-currency-native"
                            select
                            label="Балансоутримувач"
                            defaultValue={tmp}
                            onChange={(e)=>{
                                props.setDataAddress(e.target.value, 'SET_AUCTION_PROPERTY_OWNER_ID')
                            }}
                            variant="outlined"
                        >
                            <option key={0} className={styles.hoverOptions} selected disabled value={0}>Обрати</option>
                            {setOption(props)}
                        </TextField>
                    </div>
                </div>
                <div className={styles.bottonBlock}>
                    <Button
                        disabled={disabledElement(props.auctionsMy, '01')}
                        className={styles.buttonExitDocument}
                        variant="contained"
                        color="primary"
                        onClick={
                            () => {
                                if(tmpI !== null && tmpI !== ''){
                                    props.setDataAddress(tmpI.id, 'CREATE_ADDRESS_NEW_ID')
                                    //props.setDataAddress(i.name, 'CREATE_ADDRESS_NEW_NAME')
                                    props.setDataAddress(tmpI.contactPoint.name, 'CREATE_ADDRESS_NEW_NAME')

                                    props.setDataAddress(tmpI.identifier.id, 'CREATE_ADDRESS_NEW_IDENTIFIER_ID')
                                    props.setDataAddress(tmpI.identifier.identifier, 'CREATE_ADDRESS_NEW_IDENTIFIER_IDENTIFIER')
                                    props.setDataAddress(tmpI.identifier.scheme, 'CREATE_ADDRESS_NEW_IDENTIFIER_SCHEME')
                                    props.setDataAddress(tmpI.identifier.legalName, 'CREATE_ADDRESS_NEW_IDENTIFIER_LEGAL_NAME')

                                    props.setDataAddress(tmpI.additionalIdentifiers.id, 'CREATE_ADDRESS_NEW_ADDITIONAL_IDENTIFIERS_ID')
                                    props.setDataAddress(tmpI.additionalIdentifiers.identifier, 'CREATE_ADDRESS_NEW_ADDITIONAL_IDENTIFIERS_IDENTIFIER')
                                    props.setDataAddress(tmpI.additionalIdentifiers.scheme, 'CREATE_ADDRESS_NEW_ADDITIONAL_IDENTIFIERS_SCHEME')
                                    props.setDataAddress(tmpI.additionalIdentifiers.legalName, 'CREATE_ADDRESS_NEW_ADDITIONAL_IDENTIFIERS_LEGAL_NAME')

                                    if(tmpI.address.addressIdentifier !== null){
                                        props.setDataAddress(tmpI.address.addressIdentifier.id, 'CREATE_ADDRESS_NEW_ADDRESS_ADDITIONAL_IDENTIFIERS_ID')
                                        props.setDataAddress(tmpI.address.addressIdentifier.identifier, 'CREATE_ADDRESS_NEW_ADDRESS_ADDITIONAL_IDENTIFIERS_IDENTIFIER')
                                        props.setDataAddress(tmpI.address.addressIdentifier.scheme, 'CREATE_ADDRESS_NEW_ADDRESS_ADDITIONAL_IDENTIFIERS_SCHEME')
                                        props.setDataAddress(tmpI.address.addressIdentifier.legalName, 'CREATE_ADDRESS_NEW_ADDRESS_ADDITIONAL_IDENTIFIERS_LEGAL_NAME')
                                    }
                                    props.setDataAddress(tmpI.address.id, 'CREATE_ADDRESS_NEW_ADDRESS_ID')
                                    props.setDataAddress(tmpI.address.streetAddress, 'CREATE_ADDRESS_NEW_ADDRESS_STREET_ADDRESS')
                                    let tmp = null
                                    if(tmpI.address.region === 'Автономна Республіка Крим' || tmpI.address.region === "АВТОНОМНА РЕСПУБЛІКА КРИМ/М.СІМФЕРОПОЛЬ"){
                                        tmp = "0100000000+:+АВТОНОМНА РЕСПУБЛІКА КРИМ/М.СІМФЕРОПОЛЬ"
                                    }else if(tmpI.address.region === 'Вінницька область' || tmpI.address.region === "ВІННИЦЬКА ОБЛАСТЬ/М.ВІННИЦЯ"){
                                        tmp = "0500000000+:+ВІННИЦЬКА ОБЛАСТЬ/М.ВІННИЦЯ"
                                    }else if(tmpI.address.region === 'Волинська область' || tmpI.address.region === "ВОЛИНСЬКА ОБЛАСТЬ/М.ЛУЦЬК"){
                                        tmp = "0700000000+:+ВОЛИНСЬКА ОБЛАСТЬ/М.ЛУЦЬК"
                                    }else if(tmpI.address.region === 'Дніпропетровська область' || tmpI.address.region === "ДНІПРОПЕТРОВСЬКА ОБЛАСТЬ/М.ДНІПРО"){
                                        tmp = "1200000000+:+ДНІПРОПЕТРОВСЬКА ОБЛАСТЬ/М.ДНІПРО"
                                    }else if(tmpI.address.region === 'Донецька область' || tmpI.address.region === "ДОНЕЦЬКА ОБЛАСТЬ/М.ДОНЕЦЬК"){
                                        tmp = "1400000000+:+ДОНЕЦЬКА ОБЛАСТЬ/М.ДОНЕЦЬК"
                                    }else if(tmpI.address.region === 'Житомирська область' || tmpI.address.region === "ЖИТОМИРСЬКА ОБЛАСТЬ/М.ЖИТОМИР"){
                                        tmp = "1800000000+:+ЖИТОМИРСЬКА ОБЛАСТЬ/М.ЖИТОМИР"
                                    }else if(tmpI.address.region === 'Закарпатська область' || tmpI.address.region === "ЗАКАРПАТСЬКА ОБЛАСТЬ/М.УЖГОРОД"){
                                        tmp = "2100000000+:+ЗАКАРПАТСЬКА ОБЛАСТЬ/М.УЖГОРОД"
                                    }else if(tmpI.address.region === 'Запорізька область' || tmpI.address.region === "ЗАПОРІЗЬКА ОБЛАСТЬ/М.ЗАПОРІЖЖЯ"){
                                        tmp = "2300000000+:+ЗАПОРІЗЬКА ОБЛАСТЬ/М.ЗАПОРІЖЖЯ"
                                    }else if(tmpI.address.region === 'Івано-Франківська область' || tmpI.address.region === "ІВАНО-ФРАНКІВСЬКА ОБЛАСТЬ/М.ІВАНО-ФРАНКІВСЬК"){
                                        tmp = "2600000000+:+ІВАНО-ФРАНКІВСЬКА ОБЛАСТЬ/М.ІВАНО-ФРАНКІВСЬК"
                                    }else if(tmpI.address.region === 'Київська область' || tmpI.address.region === "КИЇВСЬКА ОБЛАСТЬ/М.КИЇВ"){
                                        tmp = "3200000000+:+КИЇВСЬКА ОБЛАСТЬ/М.КИЇВ"
                                    }else if(tmpI.address.region === 'Кіровоградська область' || tmpI.address.region === "КІРОВОГРАДСЬКА ОБЛАСТЬ/М.КРОПИВНИЦЬКИЙ"){
                                        tmp = "3500000000+:+КІРОВОГРАДСЬКА ОБЛАСТЬ/М.КРОПИВНИЦЬКИЙ"
                                    }else if(tmpI.address.region === 'Луганська область' || tmpI.address.region === "ЛУГАНСЬКА ОБЛАСТЬ/М.ЛУГАНСЬК"){
                                        tmp = "4400000000+:+ЛУГАНСЬКА ОБЛАСТЬ/М.ЛУГАНСЬК"
                                    }else if(tmpI.address.region === 'Львівська область' || tmpI.address.region === "ЛЬВІВСЬКА ОБЛАСТЬ/М.ЛЬВІВ"){
                                        tmp = "4600000000+:+ЛЬВІВСЬКА ОБЛАСТЬ/М.ЛЬВІВ"
                                    }else if(tmpI.address.region === 'Миколаївська область' || tmpI.address.region === "МИКОЛАЇВСЬКА ОБЛАСТЬ/М.МИКОЛАЇВ"){
                                        tmp = "4800000000+:+МИКОЛАЇВСЬКА ОБЛАСТЬ/М.МИКОЛАЇВ"
                                    }else if(tmpI.address.region === 'Одеська область' || tmpI.address.region === "ОДЕСЬКА ОБЛАСТЬ/М.ОДЕСА"){
                                        tmp = "5100000000+:+ОДЕСЬКА ОБЛАСТЬ/М.ОДЕСА"
                                    }else if(tmpI.address.region === 'Полтавська область' || tmpI.address.region === "ПОЛТАВСЬКА ОБЛАСТЬ/М.ПОЛТАВА"){
                                        tmp = "5300000000+:+ПОЛТАВСЬКА ОБЛАСТЬ/М.ПОЛТАВА"
                                    }else if(tmpI.address.region === 'Рівненська область' || tmpI.address.region === "РІВНЕНСЬКА ОБЛАСТЬ/М.РІВНЕ"){
                                        tmp = "5600000000+:+РІВНЕНСЬКА ОБЛАСТЬ/М.РІВНЕ"
                                    }else if(tmpI.address.region === 'Сумська область' || tmpI.address.region === "СУМСЬКА ОБЛАСТЬ/М.СУМИ"){
                                        tmp = "5900000000+:+СУМСЬКА ОБЛАСТЬ/М.СУМИ"
                                    }else if(tmpI.address.region === 'Тернопільська область' || tmpI.address.region === "ТЕРНОПІЛЬСЬКА ОБЛАСТЬ/М.ТЕРНОПІЛЬ"){
                                        tmp = "6100000000+:+ТЕРНОПІЛЬСЬКА ОБЛАСТЬ/М.ТЕРНОПІЛЬ"
                                    }else if(tmpI.address.region === 'Харківська область' || tmpI.address.region === "ХАРКІВСЬКА ОБЛАСТЬ/М.ХАРКІВ"){
                                        tmp = "6300000000+:+ХАРКІВСЬКА ОБЛАСТЬ/М.ХАРКІВ"
                                    }else if(tmpI.address.region === 'Херсонська область' || tmpI.address.region === "ХЕРСОНСЬКА ОБЛАСТЬ/М.ХЕРСОН"){
                                        tmp = "6500000000+:+ХЕРСОНСЬКА ОБЛАСТЬ/М.ХЕРСОН"
                                    }else if(tmpI.address.region === 'Хмельницька область' || tmpI.address.region === "ХМЕЛЬНИЦЬКА ОБЛАСТЬ/М.ХМЕЛЬНИЦЬКИЙ"){
                                        tmp = "6800000000+:+ХМЕЛЬНИЦЬКА ОБЛАСТЬ/М.ХМЕЛЬНИЦЬКИЙ"
                                    }else if(tmpI.address.region === 'Черкаська область' || tmpI.address.region === "ЧЕРКАСЬКА ОБЛАСТЬ/М.ЧЕРКАСИ"){
                                        tmp = "7100000000+:+ЧЕРКАСЬКА ОБЛАСТЬ/М.ЧЕРКАСИ"
                                    }else if(tmpI.address.region === 'Чернівецька область' || tmpI.address.region === "ЧЕРНІВЕЦЬКА ОБЛАСТЬ/М.ЧЕРНІВЦІ"){
                                        tmp = "7300000000+:+ЧЕРНІВЕЦЬКА ОБЛАСТЬ/М.ЧЕРНІВЦІ"
                                    }else if(tmpI.address.region === 'Чернігівська область' || tmpI.address.region === "ЧЕРНІГІВСЬКА ОБЛАСТЬ/М.ЧЕРНІГІВ"){
                                        tmp = "7400000000+:+ЧЕРНІГІВСЬКА ОБЛАСТЬ/М.ЧЕРНІГІВ"
                                    }else if(tmpI.address.region === 'Київ' || tmpI.address.region === "М.КИЇВ"){
                                        tmp = "8000000000+:+М.КИЇВ"
                                    }else if(tmpI.address.region === 'Севастополь' || tmpI.address.region === "М.СЕВАСТОПОЛЬ"){
                                        tmp = "8500000000+:+М.СЕВАСТОПОЛЬ"
                                    }
                                    /*let tmpRegionOption = null
                                    if(props.koatuu.region !== null){
                                        for(let tt = 0; tt < props.koatuu.region.length; tt++){
                                            if(props.koatuu.region[tt].description === tmp){
                                                tmpRegionOption = `${props.koatuu.region[tt].classificationId}+:+${props.koatuu.region[tt].description}`
                                            }
                                        }
                                    }*/
                                    props.setDataAddress(tmp, 'CREATE_ADDRESS_NEW_ADDRESS_REGION')
                                    //props.setDataAddress(tmpRegionOption, 'CREATE_ADDRESS_NEW_ADDRESS_REGION')
                                    createLine(props, tmp)
                                    props.setDataAddress(tmpI.address.locality, 'CREATE_ADDRESS_NEW_ADDRESS_LOCALITY')
                                    props.setDataAddress(tmpI.address.postalCode, 'CREATE_ADDRESS_NEW_ADDRESS_POSTAL_CODE')
                                    props.setDataAddress(tmpI.address.countryName, 'CREATE_ADDRESS_NEW_ADDRESS_COUNTRY_NAME')

                                    props.setDataAddress(tmpI.contactPoint.id, 'CREATE_ADDRESS_NEW_CONTACT_POINT_ID')
                                    props.setDataAddress(tmpI.contactPoint.name, 'CREATE_ADDRESS_NEW_CONTACT_POINT_NAME')
                                    props.setDataAddress(tmpI.contactPoint.email, 'CREATE_ADDRESS_NEW_CONTACT_POINT_EMAIL')
                                    props.setDataAddress(tmpI.contactPoint.telephone, 'CREATE_ADDRESS_NEW_CONTACT_POINT_TELEPHONE')
                                    props.setDataAddress(tmpI.contactPoint.faxNumber, 'CREATE_ADDRESS_NEW_CONTACT_POINT_FAX_NUMBER')
                                    props.setDataAddress(tmpI.contactPoint.url, 'CREATE_ADDRESS_NEW_CONTACT_POINT_URL')

                                    props.setDataAddress(tmpI.representativeInfo, 'CREATE_ADDRESS_NEW_REPRESENTATIVE_INFO')

                                    if(tmpI.currentContractTime !== null){
                                        props.setDataAddress(tmpI.currentContractTime.startDate, 'SET_ADDRESS_NEW_CURRENT_CONTRACT_TIME_START_DATE')
                                        props.setDataAddress(tmpI.currentContractTime.endDate, 'SET_ADDRESS_NEW_CURRENT_CONTRACT_TIME_END_DATE')
                                    }

                                    props.setDataAddress(true, 'CREATE_ADDRESS_NEW_OPEN_CLOSE')
                                    props.setDataAddress(false, 'CREATE_ADDRESS_TYPE_ORG')
                                }
                            }
                        }
                    >
                        Редагування чинного балансоутримувача
                    </Button>
                </div>
            </>
        )
    }else{
        return (
            <>
                <div className={styles.groupElem}>
                    <div>
                        <TextField
                            disabled={disabledElement(props.auctionsMy, '11')}
                            className={styles.inputText}
                            id="outlined-select-currency-native"
                            select
                            label="Балансоутримувач"
                            defaultValue={tmp}
                            onChange={(e)=>{
                                props.setDataAddress(e.target.value, 'SET_AUCTION_PROPERTY_OWNER_ID')
                            }}
                            variant="outlined"
                        >
                            <option key={0} className={styles.hoverOptions} selected disabled value={0}>Обрати</option>
                            {setOption(props)}
                        </TextField>
                    </div>
                </div>
                <div className={styles.bottonBlock}>
                    <Button
                        disabled={disabledElement(props.auctionsMy, '01')}
                        className={styles.buttonExitDocument}
                        variant="contained"
                        color="primary"
                        onClick={
                            () => {
                                if(tmpI !== null && tmpI !== ''){
                                    props.setDataAddress(tmpI.id, 'CREATE_ADDRESS_NEW_ID')
                                    //props.setDataAddress(i.name, 'CREATE_ADDRESS_NEW_NAME')
                                    props.setDataAddress(tmpI.contactPoint.name, 'CREATE_ADDRESS_NEW_NAME')

                                    props.setDataAddress(tmpI.identifier.id, 'CREATE_ADDRESS_NEW_IDENTIFIER_ID')
                                    props.setDataAddress(tmpI.identifier.identifier, 'CREATE_ADDRESS_NEW_IDENTIFIER_IDENTIFIER')
                                    props.setDataAddress(tmpI.identifier.scheme, 'CREATE_ADDRESS_NEW_IDENTIFIER_SCHEME')
                                    props.setDataAddress(tmpI.identifier.legalName, 'CREATE_ADDRESS_NEW_IDENTIFIER_LEGAL_NAME')

                                    props.setDataAddress(tmpI.additionalIdentifiers.id, 'CREATE_ADDRESS_NEW_ADDITIONAL_IDENTIFIERS_ID')
                                    props.setDataAddress(tmpI.additionalIdentifiers.identifier, 'CREATE_ADDRESS_NEW_ADDITIONAL_IDENTIFIERS_IDENTIFIER')
                                    props.setDataAddress(tmpI.additionalIdentifiers.scheme, 'CREATE_ADDRESS_NEW_ADDITIONAL_IDENTIFIERS_SCHEME')
                                    props.setDataAddress(tmpI.additionalIdentifiers.legalName, 'CREATE_ADDRESS_NEW_ADDITIONAL_IDENTIFIERS_LEGAL_NAME')

                                    if(tmpI.address.addressIdentifier !== null){
                                        props.setDataAddress(tmpI.address.addressIdentifier.id, 'CREATE_ADDRESS_NEW_ADDRESS_ADDITIONAL_IDENTIFIERS_ID')
                                        props.setDataAddress(tmpI.address.addressIdentifier.identifier, 'CREATE_ADDRESS_NEW_ADDRESS_ADDITIONAL_IDENTIFIERS_IDENTIFIER')
                                        props.setDataAddress(tmpI.address.addressIdentifier.scheme, 'CREATE_ADDRESS_NEW_ADDRESS_ADDITIONAL_IDENTIFIERS_SCHEME')
                                        props.setDataAddress(tmpI.address.addressIdentifier.legalName, 'CREATE_ADDRESS_NEW_ADDRESS_ADDITIONAL_IDENTIFIERS_LEGAL_NAME')
                                    }
                                    props.setDataAddress(tmpI.address.id, 'CREATE_ADDRESS_NEW_ADDRESS_ID')
                                    props.setDataAddress(tmpI.address.streetAddress, 'CREATE_ADDRESS_NEW_ADDRESS_STREET_ADDRESS')
                                    let tmp = null
                                    if(tmpI.address.region === 'Автономна Республіка Крим' || tmpI.address.region === "АВТОНОМНА РЕСПУБЛІКА КРИМ/М.СІМФЕРОПОЛЬ"){
                                        tmp = "0100000000+:+АВТОНОМНА РЕСПУБЛІКА КРИМ/М.СІМФЕРОПОЛЬ"
                                    }else if(tmpI.address.region === 'Вінницька область' || tmpI.address.region === "ВІННИЦЬКА ОБЛАСТЬ/М.ВІННИЦЯ"){
                                        tmp = "0500000000+:+ВІННИЦЬКА ОБЛАСТЬ/М.ВІННИЦЯ"
                                    }else if(tmpI.address.region === 'Волинська область' || tmpI.address.region === "ВОЛИНСЬКА ОБЛАСТЬ/М.ЛУЦЬК"){
                                        tmp = "0700000000+:+ВОЛИНСЬКА ОБЛАСТЬ/М.ЛУЦЬК"
                                    }else if(tmpI.address.region === 'Дніпропетровська область' || tmpI.address.region === "ДНІПРОПЕТРОВСЬКА ОБЛАСТЬ/М.ДНІПРО"){
                                        tmp = "1200000000+:+ДНІПРОПЕТРОВСЬКА ОБЛАСТЬ/М.ДНІПРО"
                                    }else if(tmpI.address.region === 'Донецька область' || tmpI.address.region === "ДОНЕЦЬКА ОБЛАСТЬ/М.ДОНЕЦЬК"){
                                        tmp = "1400000000+:+ДОНЕЦЬКА ОБЛАСТЬ/М.ДОНЕЦЬК"
                                    }else if(tmpI.address.region === 'Житомирська область' || tmpI.address.region === "ЖИТОМИРСЬКА ОБЛАСТЬ/М.ЖИТОМИР"){
                                        tmp = "1800000000+:+ЖИТОМИРСЬКА ОБЛАСТЬ/М.ЖИТОМИР"
                                    }else if(tmpI.address.region === 'Закарпатська область' || tmpI.address.region === "ЗАКАРПАТСЬКА ОБЛАСТЬ/М.УЖГОРОД"){
                                        tmp = "2100000000+:+ЗАКАРПАТСЬКА ОБЛАСТЬ/М.УЖГОРОД"
                                    }else if(tmpI.address.region === 'Запорізька область' || tmpI.address.region === "ЗАПОРІЗЬКА ОБЛАСТЬ/М.ЗАПОРІЖЖЯ"){
                                        tmp = "2300000000+:+ЗАПОРІЗЬКА ОБЛАСТЬ/М.ЗАПОРІЖЖЯ"
                                    }else if(tmpI.address.region === 'Івано-Франківська область' || tmpI.address.region === "ІВАНО-ФРАНКІВСЬКА ОБЛАСТЬ/М.ІВАНО-ФРАНКІВСЬК"){
                                        tmp = "2600000000+:+ІВАНО-ФРАНКІВСЬКА ОБЛАСТЬ/М.ІВАНО-ФРАНКІВСЬК"
                                    }else if(tmpI.address.region === 'Київська область' || tmpI.address.region === "КИЇВСЬКА ОБЛАСТЬ/М.КИЇВ"){
                                        tmp = "3200000000+:+КИЇВСЬКА ОБЛАСТЬ/М.КИЇВ"
                                    }else if(tmpI.address.region === 'Кіровоградська область' || tmpI.address.region === "КІРОВОГРАДСЬКА ОБЛАСТЬ/М.КРОПИВНИЦЬКИЙ"){
                                        tmp = "3500000000+:+КІРОВОГРАДСЬКА ОБЛАСТЬ/М.КРОПИВНИЦЬКИЙ"
                                    }else if(tmpI.address.region === 'Луганська область' || tmpI.address.region === "ЛУГАНСЬКА ОБЛАСТЬ/М.ЛУГАНСЬК"){
                                        tmp = "4400000000+:+ЛУГАНСЬКА ОБЛАСТЬ/М.ЛУГАНСЬК"
                                    }else if(tmpI.address.region === 'Львівська область' || tmpI.address.region === "ЛЬВІВСЬКА ОБЛАСТЬ/М.ЛЬВІВ"){
                                        tmp = "4600000000+:+ЛЬВІВСЬКА ОБЛАСТЬ/М.ЛЬВІВ"
                                    }else if(tmpI.address.region === 'Миколаївська область' || tmpI.address.region === "МИКОЛАЇВСЬКА ОБЛАСТЬ/М.МИКОЛАЇВ"){
                                        tmp = "4800000000+:+МИКОЛАЇВСЬКА ОБЛАСТЬ/М.МИКОЛАЇВ"
                                    }else if(tmpI.address.region === 'Одеська область' || tmpI.address.region === "ОДЕСЬКА ОБЛАСТЬ/М.ОДЕСА"){
                                        tmp = "5100000000+:+ОДЕСЬКА ОБЛАСТЬ/М.ОДЕСА"
                                    }else if(tmpI.address.region === 'Полтавська область' || tmpI.address.region === "ПОЛТАВСЬКА ОБЛАСТЬ/М.ПОЛТАВА"){
                                        tmp = "5300000000+:+ПОЛТАВСЬКА ОБЛАСТЬ/М.ПОЛТАВА"
                                    }else if(tmpI.address.region === 'Рівненська область' || tmpI.address.region === "РІВНЕНСЬКА ОБЛАСТЬ/М.РІВНЕ"){
                                        tmp = "5600000000+:+РІВНЕНСЬКА ОБЛАСТЬ/М.РІВНЕ"
                                    }else if(tmpI.address.region === 'Сумська область' || tmpI.address.region === "СУМСЬКА ОБЛАСТЬ/М.СУМИ"){
                                        tmp = "5900000000+:+СУМСЬКА ОБЛАСТЬ/М.СУМИ"
                                    }else if(tmpI.address.region === 'Тернопільська область' || tmpI.address.region === "ТЕРНОПІЛЬСЬКА ОБЛАСТЬ/М.ТЕРНОПІЛЬ"){
                                        tmp = "6100000000+:+ТЕРНОПІЛЬСЬКА ОБЛАСТЬ/М.ТЕРНОПІЛЬ"
                                    }else if(tmpI.address.region === 'Харківська область' || tmpI.address.region === "ХАРКІВСЬКА ОБЛАСТЬ/М.ХАРКІВ"){
                                        tmp = "6300000000+:+ХАРКІВСЬКА ОБЛАСТЬ/М.ХАРКІВ"
                                    }else if(tmpI.address.region === 'Херсонська область' || tmpI.address.region === "ХЕРСОНСЬКА ОБЛАСТЬ/М.ХЕРСОН"){
                                        tmp = "6500000000+:+ХЕРСОНСЬКА ОБЛАСТЬ/М.ХЕРСОН"
                                    }else if(tmpI.address.region === 'Хмельницька область' || tmpI.address.region === "ХМЕЛЬНИЦЬКА ОБЛАСТЬ/М.ХМЕЛЬНИЦЬКИЙ"){
                                        tmp = "6800000000+:+ХМЕЛЬНИЦЬКА ОБЛАСТЬ/М.ХМЕЛЬНИЦЬКИЙ"
                                    }else if(tmpI.address.region === 'Черкаська область' || tmpI.address.region === "ЧЕРКАСЬКА ОБЛАСТЬ/М.ЧЕРКАСИ"){
                                        tmp = "7100000000+:+ЧЕРКАСЬКА ОБЛАСТЬ/М.ЧЕРКАСИ"
                                    }else if(tmpI.address.region === 'Чернівецька область' || tmpI.address.region === "ЧЕРНІВЕЦЬКА ОБЛАСТЬ/М.ЧЕРНІВЦІ"){
                                        tmp = "7300000000+:+ЧЕРНІВЕЦЬКА ОБЛАСТЬ/М.ЧЕРНІВЦІ"
                                    }else if(tmpI.address.region === 'Чернігівська область' || tmpI.address.region === "ЧЕРНІГІВСЬКА ОБЛАСТЬ/М.ЧЕРНІГІВ"){
                                        tmp = "7400000000+:+ЧЕРНІГІВСЬКА ОБЛАСТЬ/М.ЧЕРНІГІВ"
                                    }else if(tmpI.address.region === 'Київ' || tmpI.address.region === "М.КИЇВ"){
                                        tmp = "8000000000+:+М.КИЇВ"
                                    }else if(tmpI.address.region === 'Севастополь' || tmpI.address.region === "М.СЕВАСТОПОЛЬ"){
                                        tmp = "8500000000+:+М.СЕВАСТОПОЛЬ"
                                    }
                                    /*let tmpRegionOption = null
                                    if(props.koatuu.region !== null){
                                        for(let tt = 0; tt < props.koatuu.region.length; tt++){
                                            if(props.koatuu.region[tt].description === tmp){
                                                tmpRegionOption = `${props.koatuu.region[tt].classificationId}+:+${props.koatuu.region[tt].description}`
                                            }
                                        }
                                    }*/
                                    props.setDataAddress(tmp, 'CREATE_ADDRESS_NEW_ADDRESS_REGION')
                                    //props.setDataAddress(tmpRegionOption, 'CREATE_ADDRESS_NEW_ADDRESS_REGION')
                                    createLine(props, tmp)
                                    props.setDataAddress(tmpI.address.locality, 'CREATE_ADDRESS_NEW_ADDRESS_LOCALITY')
                                    props.setDataAddress(tmpI.address.postalCode, 'CREATE_ADDRESS_NEW_ADDRESS_POSTAL_CODE')
                                    props.setDataAddress(tmpI.address.countryName, 'CREATE_ADDRESS_NEW_ADDRESS_COUNTRY_NAME')

                                    props.setDataAddress(tmpI.contactPoint.id, 'CREATE_ADDRESS_NEW_CONTACT_POINT_ID')
                                    props.setDataAddress(tmpI.contactPoint.name, 'CREATE_ADDRESS_NEW_CONTACT_POINT_NAME')
                                    props.setDataAddress(tmpI.contactPoint.email, 'CREATE_ADDRESS_NEW_CONTACT_POINT_EMAIL')
                                    props.setDataAddress(tmpI.contactPoint.telephone, 'CREATE_ADDRESS_NEW_CONTACT_POINT_TELEPHONE')
                                    props.setDataAddress(tmpI.contactPoint.faxNumber, 'CREATE_ADDRESS_NEW_CONTACT_POINT_FAX_NUMBER')
                                    props.setDataAddress(tmpI.contactPoint.url, 'CREATE_ADDRESS_NEW_CONTACT_POINT_URL')

                                    props.setDataAddress(tmpI.representativeInfo, 'CREATE_ADDRESS_NEW_REPRESENTATIVE_INFO')

                                    if(tmpI.currentContractTime !== null){
                                        props.setDataAddress(tmpI.currentContractTime.startDate, 'SET_ADDRESS_NEW_CURRENT_CONTRACT_TIME_START_DATE')
                                        props.setDataAddress(tmpI.currentContractTime.endDate, 'SET_ADDRESS_NEW_CURRENT_CONTRACT_TIME_END_DATE')
                                    }

                                    props.setDataAddress(true, 'CREATE_ADDRESS_NEW_OPEN_CLOSE')
                                    props.setDataAddress(false, 'CREATE_ADDRESS_TYPE_ORG')
                                }
                            }
                        }
                    >
                        Редагування чинного балансоутримувача
                    </Button>
                </div>
            </>
        )
    }
}

function buttonnBalans(props){
    if(props.auctionsMy.status === null || props.auctionsMy.status === 'active_rectification'){
        return false
    }
    return false
}

function viewSellingEntity(props){
    let tmp = ''
    let tmpI = null
    if(props.auctionsMy.relatedOrganizations !== null && props.addressNew.getAddress !== null){
        if(props.auctionsMy.relatedOrganizations.hasOwnProperty('sellingEntity') === true){
            if(props.auctionsMy.relatedOrganizations.sellingEntity !== null){
                if(props.auctionsMy.relatedOrganizations.sellingEntity.hasOwnProperty('id') === true){
                    /*let tmpArr = props.addressNew.getAddress
                    let flag = false
                    for(let i = 0; i < props.addressNew.getAddress.length; i++){
                        if(props.addressNew.getAddress[i].id === props.auctionsMy.relatedOrganizations.sellingEntity.id){
                            flag = true
                        }
                    }
                    if(flag === false){
                        let tmpObj = props.auctionsMy.relatedOrganizations.sellingEntity
                        tmpObj.name = tmpObj.contactPoint.name 
                        tmpArr.push(tmpObj)
                        props.setDataAddress(tmpArr, 'GET_ADDRESS_NEW_BOOKS')
                    }else{
                        tmp = props.auctionsMy.relatedOrganizations.sellingEntity.id
                    }*/
                    tmp = props.auctionsMy.relatedOrganizations.sellingEntity.id
                    tmpI = props.auctionsMy.relatedOrganizations.sellingEntity
                }
            }
        }
    }else{
        props.setDataAddress({
            propertyOwner: {id: 'null'},
            sellingEntity: {id: 'null'},
            currentTenant: {id: 'null'},
            ownershipType: 'null',
        }, 'SET_AUCTION_RELATED_ORGANIZATIONS')
    }
    if(props.typeElem === 1){
        return (
            <>
                <div className={styles.groupElem}>
                    <div>
                        <TextField
                            disabled={disabledElement(props.auctionsMy, '11')}
                            className={styles.inputText}
                            id="outlined-select-currency-native"
                            select
                            label="Орендодавець"
                            defaultValue={tmp}
                            onChange={(e)=>{
                                props.setDataAddress(e.target.value, 'SET_AUCTION_SELLING_ENTITY_ID')
                            }}
                            variant="outlined"
                        >
                            <option key={0} className={styles.hoverOptions} selected disabled value={0}>Обрати</option>
                            {setOption(props)}
                        </TextField>
                    </div>
                </div>
                <div className={styles.bottonBlock}>
                    <Button
                        disabled={disabledElement(props.auctionsMy, '01')}
                        className={styles.buttonExitDocument}
                        variant="contained"
                        color="primary"
                        onClick={
                            () => {
                                if(tmpI !== null && tmpI !== ''){
                                    props.setDataAddress(tmpI.id, 'CREATE_ADDRESS_NEW_ID')
                                    //props.setDataAddress(i.name, 'CREATE_ADDRESS_NEW_NAME')
                                    props.setDataAddress(tmpI.contactPoint.name, 'CREATE_ADDRESS_NEW_NAME')

                                    props.setDataAddress(tmpI.identifier.id, 'CREATE_ADDRESS_NEW_IDENTIFIER_ID')
                                    props.setDataAddress(tmpI.identifier.identifier, 'CREATE_ADDRESS_NEW_IDENTIFIER_IDENTIFIER')
                                    props.setDataAddress(tmpI.identifier.scheme, 'CREATE_ADDRESS_NEW_IDENTIFIER_SCHEME')
                                    props.setDataAddress(tmpI.identifier.legalName, 'CREATE_ADDRESS_NEW_IDENTIFIER_LEGAL_NAME')

                                    props.setDataAddress(tmpI.additionalIdentifiers.id, 'CREATE_ADDRESS_NEW_ADDITIONAL_IDENTIFIERS_ID')
                                    props.setDataAddress(tmpI.additionalIdentifiers.identifier, 'CREATE_ADDRESS_NEW_ADDITIONAL_IDENTIFIERS_IDENTIFIER')
                                    props.setDataAddress(tmpI.additionalIdentifiers.scheme, 'CREATE_ADDRESS_NEW_ADDITIONAL_IDENTIFIERS_SCHEME')
                                    props.setDataAddress(tmpI.additionalIdentifiers.legalName, 'CREATE_ADDRESS_NEW_ADDITIONAL_IDENTIFIERS_LEGAL_NAME')

                                    if(tmpI.address.addressIdentifier !== null){
                                        props.setDataAddress(tmpI.address.addressIdentifier.id, 'CREATE_ADDRESS_NEW_ADDRESS_ADDITIONAL_IDENTIFIERS_ID')
                                        props.setDataAddress(tmpI.address.addressIdentifier.identifier, 'CREATE_ADDRESS_NEW_ADDRESS_ADDITIONAL_IDENTIFIERS_IDENTIFIER')
                                        props.setDataAddress(tmpI.address.addressIdentifier.scheme, 'CREATE_ADDRESS_NEW_ADDRESS_ADDITIONAL_IDENTIFIERS_SCHEME')
                                        props.setDataAddress(tmpI.address.addressIdentifier.legalName, 'CREATE_ADDRESS_NEW_ADDRESS_ADDITIONAL_IDENTIFIERS_LEGAL_NAME')
                                    }
                                    props.setDataAddress(tmpI.address.id, 'CREATE_ADDRESS_NEW_ADDRESS_ID')
                                    props.setDataAddress(tmpI.address.streetAddress, 'CREATE_ADDRESS_NEW_ADDRESS_STREET_ADDRESS')
                                    let tmp = null
                                    if(tmpI.address.region === 'Автономна Республіка Крим' || tmpI.address.region === "АВТОНОМНА РЕСПУБЛІКА КРИМ/М.СІМФЕРОПОЛЬ"){
                                        tmp = "0100000000+:+АВТОНОМНА РЕСПУБЛІКА КРИМ/М.СІМФЕРОПОЛЬ"
                                    }else if(tmpI.address.region === 'Вінницька область' || tmpI.address.region === "ВІННИЦЬКА ОБЛАСТЬ/М.ВІННИЦЯ"){
                                        tmp = "0500000000+:+ВІННИЦЬКА ОБЛАСТЬ/М.ВІННИЦЯ"
                                    }else if(tmpI.address.region === 'Волинська область' || tmpI.address.region === "ВОЛИНСЬКА ОБЛАСТЬ/М.ЛУЦЬК"){
                                        tmp = "0700000000+:+ВОЛИНСЬКА ОБЛАСТЬ/М.ЛУЦЬК"
                                    }else if(tmpI.address.region === 'Дніпропетровська область' || tmpI.address.region === "ДНІПРОПЕТРОВСЬКА ОБЛАСТЬ/М.ДНІПРО"){
                                        tmp = "1200000000+:+ДНІПРОПЕТРОВСЬКА ОБЛАСТЬ/М.ДНІПРО"
                                    }else if(tmpI.address.region === 'Донецька область' || tmpI.address.region === "ДОНЕЦЬКА ОБЛАСТЬ/М.ДОНЕЦЬК"){
                                        tmp = "1400000000+:+ДОНЕЦЬКА ОБЛАСТЬ/М.ДОНЕЦЬК"
                                    }else if(tmpI.address.region === 'Житомирська область' || tmpI.address.region === "ЖИТОМИРСЬКА ОБЛАСТЬ/М.ЖИТОМИР"){
                                        tmp = "1800000000+:+ЖИТОМИРСЬКА ОБЛАСТЬ/М.ЖИТОМИР"
                                    }else if(tmpI.address.region === 'Закарпатська область' || tmpI.address.region === "ЗАКАРПАТСЬКА ОБЛАСТЬ/М.УЖГОРОД"){
                                        tmp = "2100000000+:+ЗАКАРПАТСЬКА ОБЛАСТЬ/М.УЖГОРОД"
                                    }else if(tmpI.address.region === 'Запорізька область' || tmpI.address.region === "ЗАПОРІЗЬКА ОБЛАСТЬ/М.ЗАПОРІЖЖЯ"){
                                        tmp = "2300000000+:+ЗАПОРІЗЬКА ОБЛАСТЬ/М.ЗАПОРІЖЖЯ"
                                    }else if(tmpI.address.region === 'Івано-Франківська область' || tmpI.address.region === "ІВАНО-ФРАНКІВСЬКА ОБЛАСТЬ/М.ІВАНО-ФРАНКІВСЬК"){
                                        tmp = "2600000000+:+ІВАНО-ФРАНКІВСЬКА ОБЛАСТЬ/М.ІВАНО-ФРАНКІВСЬК"
                                    }else if(tmpI.address.region === 'Київська область' || tmpI.address.region === "КИЇВСЬКА ОБЛАСТЬ/М.КИЇВ"){
                                        tmp = "3200000000+:+КИЇВСЬКА ОБЛАСТЬ/М.КИЇВ"
                                    }else if(tmpI.address.region === 'Кіровоградська область' || tmpI.address.region === "КІРОВОГРАДСЬКА ОБЛАСТЬ/М.КРОПИВНИЦЬКИЙ"){
                                        tmp = "3500000000+:+КІРОВОГРАДСЬКА ОБЛАСТЬ/М.КРОПИВНИЦЬКИЙ"
                                    }else if(tmpI.address.region === 'Луганська область' || tmpI.address.region === "ЛУГАНСЬКА ОБЛАСТЬ/М.ЛУГАНСЬК"){
                                        tmp = "4400000000+:+ЛУГАНСЬКА ОБЛАСТЬ/М.ЛУГАНСЬК"
                                    }else if(tmpI.address.region === 'Львівська область' || tmpI.address.region === "ЛЬВІВСЬКА ОБЛАСТЬ/М.ЛЬВІВ"){
                                        tmp = "4600000000+:+ЛЬВІВСЬКА ОБЛАСТЬ/М.ЛЬВІВ"
                                    }else if(tmpI.address.region === 'Миколаївська область' || tmpI.address.region === "МИКОЛАЇВСЬКА ОБЛАСТЬ/М.МИКОЛАЇВ"){
                                        tmp = "4800000000+:+МИКОЛАЇВСЬКА ОБЛАСТЬ/М.МИКОЛАЇВ"
                                    }else if(tmpI.address.region === 'Одеська область' || tmpI.address.region === "ОДЕСЬКА ОБЛАСТЬ/М.ОДЕСА"){
                                        tmp = "5100000000+:+ОДЕСЬКА ОБЛАСТЬ/М.ОДЕСА"
                                    }else if(tmpI.address.region === 'Полтавська область' || tmpI.address.region === "ПОЛТАВСЬКА ОБЛАСТЬ/М.ПОЛТАВА"){
                                        tmp = "5300000000+:+ПОЛТАВСЬКА ОБЛАСТЬ/М.ПОЛТАВА"
                                    }else if(tmpI.address.region === 'Рівненська область' || tmpI.address.region === "РІВНЕНСЬКА ОБЛАСТЬ/М.РІВНЕ"){
                                        tmp = "5600000000+:+РІВНЕНСЬКА ОБЛАСТЬ/М.РІВНЕ"
                                    }else if(tmpI.address.region === 'Сумська область' || tmpI.address.region === "СУМСЬКА ОБЛАСТЬ/М.СУМИ"){
                                        tmp = "5900000000+:+СУМСЬКА ОБЛАСТЬ/М.СУМИ"
                                    }else if(tmpI.address.region === 'Тернопільська область' || tmpI.address.region === "ТЕРНОПІЛЬСЬКА ОБЛАСТЬ/М.ТЕРНОПІЛЬ"){
                                        tmp = "6100000000+:+ТЕРНОПІЛЬСЬКА ОБЛАСТЬ/М.ТЕРНОПІЛЬ"
                                    }else if(tmpI.address.region === 'Харківська область' || tmpI.address.region === "ХАРКІВСЬКА ОБЛАСТЬ/М.ХАРКІВ"){
                                        tmp = "6300000000+:+ХАРКІВСЬКА ОБЛАСТЬ/М.ХАРКІВ"
                                    }else if(tmpI.address.region === 'Херсонська область' || tmpI.address.region === "ХЕРСОНСЬКА ОБЛАСТЬ/М.ХЕРСОН"){
                                        tmp = "6500000000+:+ХЕРСОНСЬКА ОБЛАСТЬ/М.ХЕРСОН"
                                    }else if(tmpI.address.region === 'Хмельницька область' || tmpI.address.region === "ХМЕЛЬНИЦЬКА ОБЛАСТЬ/М.ХМЕЛЬНИЦЬКИЙ"){
                                        tmp = "6800000000+:+ХМЕЛЬНИЦЬКА ОБЛАСТЬ/М.ХМЕЛЬНИЦЬКИЙ"
                                    }else if(tmpI.address.region === 'Черкаська область' || tmpI.address.region === "ЧЕРКАСЬКА ОБЛАСТЬ/М.ЧЕРКАСИ"){
                                        tmp = "7100000000+:+ЧЕРКАСЬКА ОБЛАСТЬ/М.ЧЕРКАСИ"
                                    }else if(tmpI.address.region === 'Чернівецька область' || tmpI.address.region === "ЧЕРНІВЕЦЬКА ОБЛАСТЬ/М.ЧЕРНІВЦІ"){
                                        tmp = "7300000000+:+ЧЕРНІВЕЦЬКА ОБЛАСТЬ/М.ЧЕРНІВЦІ"
                                    }else if(tmpI.address.region === 'Чернігівська область' || tmpI.address.region === "ЧЕРНІГІВСЬКА ОБЛАСТЬ/М.ЧЕРНІГІВ"){
                                        tmp = "7400000000+:+ЧЕРНІГІВСЬКА ОБЛАСТЬ/М.ЧЕРНІГІВ"
                                    }else if(tmpI.address.region === 'Київ' || tmpI.address.region === "М.КИЇВ"){
                                        tmp = "8000000000+:+М.КИЇВ"
                                    }else if(tmpI.address.region === 'Севастополь' || tmpI.address.region === "М.СЕВАСТОПОЛЬ"){
                                        tmp = "8500000000+:+М.СЕВАСТОПОЛЬ"
                                    }
                                    /*let tmpRegionOption = null
                                    if(props.koatuu.region !== null){
                                        for(let tt = 0; tt < props.koatuu.region.length; tt++){
                                            if(props.koatuu.region[tt].description === tmp){
                                                tmpRegionOption = `${props.koatuu.region[tt].classificationId}+:+${props.koatuu.region[tt].description}`
                                            }
                                        }
                                    }*/
                                    props.setDataAddress(tmp, 'CREATE_ADDRESS_NEW_ADDRESS_REGION')
                                    //props.setDataAddress(tmpRegionOption, 'CREATE_ADDRESS_NEW_ADDRESS_REGION')
                                    createLine(props, tmp)
                                    props.setDataAddress(tmpI.address.locality, 'CREATE_ADDRESS_NEW_ADDRESS_LOCALITY')
                                    props.setDataAddress(tmpI.address.postalCode, 'CREATE_ADDRESS_NEW_ADDRESS_POSTAL_CODE')
                                    props.setDataAddress(tmpI.address.countryName, 'CREATE_ADDRESS_NEW_ADDRESS_COUNTRY_NAME')

                                    props.setDataAddress(tmpI.contactPoint.id, 'CREATE_ADDRESS_NEW_CONTACT_POINT_ID')
                                    props.setDataAddress(tmpI.contactPoint.name, 'CREATE_ADDRESS_NEW_CONTACT_POINT_NAME')
                                    props.setDataAddress(tmpI.contactPoint.email, 'CREATE_ADDRESS_NEW_CONTACT_POINT_EMAIL')
                                    props.setDataAddress(tmpI.contactPoint.telephone, 'CREATE_ADDRESS_NEW_CONTACT_POINT_TELEPHONE')
                                    props.setDataAddress(tmpI.contactPoint.faxNumber, 'CREATE_ADDRESS_NEW_CONTACT_POINT_FAX_NUMBER')
                                    props.setDataAddress(tmpI.contactPoint.url, 'CREATE_ADDRESS_NEW_CONTACT_POINT_URL')

                                    props.setDataAddress(tmpI.representativeInfo, 'CREATE_ADDRESS_NEW_REPRESENTATIVE_INFO')

                                    if(tmpI.currentContractTime !== null){
                                        props.setDataAddress(tmpI.currentContractTime.startDate, 'SET_ADDRESS_NEW_CURRENT_CONTRACT_TIME_START_DATE')
                                        props.setDataAddress(tmpI.currentContractTime.endDate, 'SET_ADDRESS_NEW_CURRENT_CONTRACT_TIME_END_DATE')
                                    }

                                    props.setDataAddress(true, 'CREATE_ADDRESS_NEW_OPEN_CLOSE')
                                    props.setDataAddress(false, 'CREATE_ADDRESS_TYPE_ORG')
                                }
                            }
                        }
                    >
                        Редагування чинного орендодавця
                    </Button>
                </div>
            </>
        )
    }else{
        return (
            <>
                <div className={styles.groupElem}>
                    <div>
                        <TextField
                            disabled={disabledElement(props.auctionsMy, '11')}
                            className={styles.inputText}
                            id="outlined-select-currency-native"
                            select
                            label="Орендодавець"
                            defaultValue={tmp}
                            onChange={(e)=>{
                                props.setDataAddress(e.target.value, 'SET_AUCTION_SELLING_ENTITY_ID')
                            }}
                            variant="outlined"
                        >
                            <option key={0} className={styles.hoverOptions} selected disabled value={0}>Обрати</option>
                            {setOption(props)}
                        </TextField>
                    </div>
                </div>
                <div className={styles.bottonBlock}>
                    <Button
                        disabled={disabledElement(props.auctionsMy, '01')}
                        className={styles.buttonExitDocument}
                        variant="contained"
                        color="primary"
                        onClick={
                            () => {
                                if(tmpI !== null && tmpI !== ''){
                                    props.setDataAddress(tmpI.id, 'CREATE_ADDRESS_NEW_ID')
                                    //props.setDataAddress(i.name, 'CREATE_ADDRESS_NEW_NAME')
                                    props.setDataAddress(tmpI.contactPoint.name, 'CREATE_ADDRESS_NEW_NAME')

                                    props.setDataAddress(tmpI.identifier.id, 'CREATE_ADDRESS_NEW_IDENTIFIER_ID')
                                    props.setDataAddress(tmpI.identifier.identifier, 'CREATE_ADDRESS_NEW_IDENTIFIER_IDENTIFIER')
                                    props.setDataAddress(tmpI.identifier.scheme, 'CREATE_ADDRESS_NEW_IDENTIFIER_SCHEME')
                                    props.setDataAddress(tmpI.identifier.legalName, 'CREATE_ADDRESS_NEW_IDENTIFIER_LEGAL_NAME')

                                    props.setDataAddress(tmpI.additionalIdentifiers.id, 'CREATE_ADDRESS_NEW_ADDITIONAL_IDENTIFIERS_ID')
                                    props.setDataAddress(tmpI.additionalIdentifiers.identifier, 'CREATE_ADDRESS_NEW_ADDITIONAL_IDENTIFIERS_IDENTIFIER')
                                    props.setDataAddress(tmpI.additionalIdentifiers.scheme, 'CREATE_ADDRESS_NEW_ADDITIONAL_IDENTIFIERS_SCHEME')
                                    props.setDataAddress(tmpI.additionalIdentifiers.legalName, 'CREATE_ADDRESS_NEW_ADDITIONAL_IDENTIFIERS_LEGAL_NAME')

                                    if(tmpI.address.addressIdentifier !== null){
                                        props.setDataAddress(tmpI.address.addressIdentifier.id, 'CREATE_ADDRESS_NEW_ADDRESS_ADDITIONAL_IDENTIFIERS_ID')
                                        props.setDataAddress(tmpI.address.addressIdentifier.identifier, 'CREATE_ADDRESS_NEW_ADDRESS_ADDITIONAL_IDENTIFIERS_IDENTIFIER')
                                        props.setDataAddress(tmpI.address.addressIdentifier.scheme, 'CREATE_ADDRESS_NEW_ADDRESS_ADDITIONAL_IDENTIFIERS_SCHEME')
                                        props.setDataAddress(tmpI.address.addressIdentifier.legalName, 'CREATE_ADDRESS_NEW_ADDRESS_ADDITIONAL_IDENTIFIERS_LEGAL_NAME')
                                    }
                                    props.setDataAddress(tmpI.address.id, 'CREATE_ADDRESS_NEW_ADDRESS_ID')
                                    props.setDataAddress(tmpI.address.streetAddress, 'CREATE_ADDRESS_NEW_ADDRESS_STREET_ADDRESS')
                                    let tmp = null
                                    if(tmpI.address.region === 'Автономна Республіка Крим' || tmpI.address.region === "АВТОНОМНА РЕСПУБЛІКА КРИМ/М.СІМФЕРОПОЛЬ"){
                                        tmp = "0100000000+:+АВТОНОМНА РЕСПУБЛІКА КРИМ/М.СІМФЕРОПОЛЬ"
                                    }else if(tmpI.address.region === 'Вінницька область' || tmpI.address.region === "ВІННИЦЬКА ОБЛАСТЬ/М.ВІННИЦЯ"){
                                        tmp = "0500000000+:+ВІННИЦЬКА ОБЛАСТЬ/М.ВІННИЦЯ"
                                    }else if(tmpI.address.region === 'Волинська область' || tmpI.address.region === "ВОЛИНСЬКА ОБЛАСТЬ/М.ЛУЦЬК"){
                                        tmp = "0700000000+:+ВОЛИНСЬКА ОБЛАСТЬ/М.ЛУЦЬК"
                                    }else if(tmpI.address.region === 'Дніпропетровська область' || tmpI.address.region === "ДНІПРОПЕТРОВСЬКА ОБЛАСТЬ/М.ДНІПРО"){
                                        tmp = "1200000000+:+ДНІПРОПЕТРОВСЬКА ОБЛАСТЬ/М.ДНІПРО"
                                    }else if(tmpI.address.region === 'Донецька область' || tmpI.address.region === "ДОНЕЦЬКА ОБЛАСТЬ/М.ДОНЕЦЬК"){
                                        tmp = "1400000000+:+ДОНЕЦЬКА ОБЛАСТЬ/М.ДОНЕЦЬК"
                                    }else if(tmpI.address.region === 'Житомирська область' || tmpI.address.region === "ЖИТОМИРСЬКА ОБЛАСТЬ/М.ЖИТОМИР"){
                                        tmp = "1800000000+:+ЖИТОМИРСЬКА ОБЛАСТЬ/М.ЖИТОМИР"
                                    }else if(tmpI.address.region === 'Закарпатська область' || tmpI.address.region === "ЗАКАРПАТСЬКА ОБЛАСТЬ/М.УЖГОРОД"){
                                        tmp = "2100000000+:+ЗАКАРПАТСЬКА ОБЛАСТЬ/М.УЖГОРОД"
                                    }else if(tmpI.address.region === 'Запорізька область' || tmpI.address.region === "ЗАПОРІЗЬКА ОБЛАСТЬ/М.ЗАПОРІЖЖЯ"){
                                        tmp = "2300000000+:+ЗАПОРІЗЬКА ОБЛАСТЬ/М.ЗАПОРІЖЖЯ"
                                    }else if(tmpI.address.region === 'Івано-Франківська область' || tmpI.address.region === "ІВАНО-ФРАНКІВСЬКА ОБЛАСТЬ/М.ІВАНО-ФРАНКІВСЬК"){
                                        tmp = "2600000000+:+ІВАНО-ФРАНКІВСЬКА ОБЛАСТЬ/М.ІВАНО-ФРАНКІВСЬК"
                                    }else if(tmpI.address.region === 'Київська область' || tmpI.address.region === "КИЇВСЬКА ОБЛАСТЬ/М.КИЇВ"){
                                        tmp = "3200000000+:+КИЇВСЬКА ОБЛАСТЬ/М.КИЇВ"
                                    }else if(tmpI.address.region === 'Кіровоградська область' || tmpI.address.region === "КІРОВОГРАДСЬКА ОБЛАСТЬ/М.КРОПИВНИЦЬКИЙ"){
                                        tmp = "3500000000+:+КІРОВОГРАДСЬКА ОБЛАСТЬ/М.КРОПИВНИЦЬКИЙ"
                                    }else if(tmpI.address.region === 'Луганська область' || tmpI.address.region === "ЛУГАНСЬКА ОБЛАСТЬ/М.ЛУГАНСЬК"){
                                        tmp = "4400000000+:+ЛУГАНСЬКА ОБЛАСТЬ/М.ЛУГАНСЬК"
                                    }else if(tmpI.address.region === 'Львівська область' || tmpI.address.region === "ЛЬВІВСЬКА ОБЛАСТЬ/М.ЛЬВІВ"){
                                        tmp = "4600000000+:+ЛЬВІВСЬКА ОБЛАСТЬ/М.ЛЬВІВ"
                                    }else if(tmpI.address.region === 'Миколаївська область' || tmpI.address.region === "МИКОЛАЇВСЬКА ОБЛАСТЬ/М.МИКОЛАЇВ"){
                                        tmp = "4800000000+:+МИКОЛАЇВСЬКА ОБЛАСТЬ/М.МИКОЛАЇВ"
                                    }else if(tmpI.address.region === 'Одеська область' || tmpI.address.region === "ОДЕСЬКА ОБЛАСТЬ/М.ОДЕСА"){
                                        tmp = "5100000000+:+ОДЕСЬКА ОБЛАСТЬ/М.ОДЕСА"
                                    }else if(tmpI.address.region === 'Полтавська область' || tmpI.address.region === "ПОЛТАВСЬКА ОБЛАСТЬ/М.ПОЛТАВА"){
                                        tmp = "5300000000+:+ПОЛТАВСЬКА ОБЛАСТЬ/М.ПОЛТАВА"
                                    }else if(tmpI.address.region === 'Рівненська область' || tmpI.address.region === "РІВНЕНСЬКА ОБЛАСТЬ/М.РІВНЕ"){
                                        tmp = "5600000000+:+РІВНЕНСЬКА ОБЛАСТЬ/М.РІВНЕ"
                                    }else if(tmpI.address.region === 'Сумська область' || tmpI.address.region === "СУМСЬКА ОБЛАСТЬ/М.СУМИ"){
                                        tmp = "5900000000+:+СУМСЬКА ОБЛАСТЬ/М.СУМИ"
                                    }else if(tmpI.address.region === 'Тернопільська область' || tmpI.address.region === "ТЕРНОПІЛЬСЬКА ОБЛАСТЬ/М.ТЕРНОПІЛЬ"){
                                        tmp = "6100000000+:+ТЕРНОПІЛЬСЬКА ОБЛАСТЬ/М.ТЕРНОПІЛЬ"
                                    }else if(tmpI.address.region === 'Харківська область' || tmpI.address.region === "ХАРКІВСЬКА ОБЛАСТЬ/М.ХАРКІВ"){
                                        tmp = "6300000000+:+ХАРКІВСЬКА ОБЛАСТЬ/М.ХАРКІВ"
                                    }else if(tmpI.address.region === 'Херсонська область' || tmpI.address.region === "ХЕРСОНСЬКА ОБЛАСТЬ/М.ХЕРСОН"){
                                        tmp = "6500000000+:+ХЕРСОНСЬКА ОБЛАСТЬ/М.ХЕРСОН"
                                    }else if(tmpI.address.region === 'Хмельницька область' || tmpI.address.region === "ХМЕЛЬНИЦЬКА ОБЛАСТЬ/М.ХМЕЛЬНИЦЬКИЙ"){
                                        tmp = "6800000000+:+ХМЕЛЬНИЦЬКА ОБЛАСТЬ/М.ХМЕЛЬНИЦЬКИЙ"
                                    }else if(tmpI.address.region === 'Черкаська область' || tmpI.address.region === "ЧЕРКАСЬКА ОБЛАСТЬ/М.ЧЕРКАСИ"){
                                        tmp = "7100000000+:+ЧЕРКАСЬКА ОБЛАСТЬ/М.ЧЕРКАСИ"
                                    }else if(tmpI.address.region === 'Чернівецька область' || tmpI.address.region === "ЧЕРНІВЕЦЬКА ОБЛАСТЬ/М.ЧЕРНІВЦІ"){
                                        tmp = "7300000000+:+ЧЕРНІВЕЦЬКА ОБЛАСТЬ/М.ЧЕРНІВЦІ"
                                    }else if(tmpI.address.region === 'Чернігівська область' || tmpI.address.region === "ЧЕРНІГІВСЬКА ОБЛАСТЬ/М.ЧЕРНІГІВ"){
                                        tmp = "7400000000+:+ЧЕРНІГІВСЬКА ОБЛАСТЬ/М.ЧЕРНІГІВ"
                                    }else if(tmpI.address.region === 'Київ' || tmpI.address.region === "М.КИЇВ"){
                                        tmp = "8000000000+:+М.КИЇВ"
                                    }else if(tmpI.address.region === 'Севастополь' || tmpI.address.region === "М.СЕВАСТОПОЛЬ"){
                                        tmp = "8500000000+:+М.СЕВАСТОПОЛЬ"
                                    }
                                    /*let tmpRegionOption = null
                                    if(props.koatuu.region !== null){
                                        for(let tt = 0; tt < props.koatuu.region.length; tt++){
                                            if(props.koatuu.region[tt].description === tmp){
                                                tmpRegionOption = `${props.koatuu.region[tt].classificationId}+:+${props.koatuu.region[tt].description}`
                                            }
                                        }
                                    }*/
                                    props.setDataAddress(tmp, 'CREATE_ADDRESS_NEW_ADDRESS_REGION')
                                    //props.setDataAddress(tmpRegionOption, 'CREATE_ADDRESS_NEW_ADDRESS_REGION')
                                    createLine(props, tmp)
                                    props.setDataAddress(tmpI.address.locality, 'CREATE_ADDRESS_NEW_ADDRESS_LOCALITY')
                                    props.setDataAddress(tmpI.address.postalCode, 'CREATE_ADDRESS_NEW_ADDRESS_POSTAL_CODE')
                                    props.setDataAddress(tmpI.address.countryName, 'CREATE_ADDRESS_NEW_ADDRESS_COUNTRY_NAME')

                                    props.setDataAddress(tmpI.contactPoint.id, 'CREATE_ADDRESS_NEW_CONTACT_POINT_ID')
                                    props.setDataAddress(tmpI.contactPoint.name, 'CREATE_ADDRESS_NEW_CONTACT_POINT_NAME')
                                    props.setDataAddress(tmpI.contactPoint.email, 'CREATE_ADDRESS_NEW_CONTACT_POINT_EMAIL')
                                    props.setDataAddress(tmpI.contactPoint.telephone, 'CREATE_ADDRESS_NEW_CONTACT_POINT_TELEPHONE')
                                    props.setDataAddress(tmpI.contactPoint.faxNumber, 'CREATE_ADDRESS_NEW_CONTACT_POINT_FAX_NUMBER')
                                    props.setDataAddress(tmpI.contactPoint.url, 'CREATE_ADDRESS_NEW_CONTACT_POINT_URL')

                                    props.setDataAddress(tmpI.representativeInfo, 'CREATE_ADDRESS_NEW_REPRESENTATIVE_INFO')

                                    if(tmpI.currentContractTime !== null){
                                        props.setDataAddress(tmpI.currentContractTime.startDate, 'SET_ADDRESS_NEW_CURRENT_CONTRACT_TIME_START_DATE')
                                        props.setDataAddress(tmpI.currentContractTime.endDate, 'SET_ADDRESS_NEW_CURRENT_CONTRACT_TIME_END_DATE')
                                    }

                                    props.setDataAddress(true, 'CREATE_ADDRESS_NEW_OPEN_CLOSE')
                                    props.setDataAddress(false, 'CREATE_ADDRESS_TYPE_ORG')
                                }
                            }
                        }
                    >
                        Редагування чинного орендодавця
                    </Button>
                </div>
            </>
        )
    }
}

function viewCurrentTenant(props){
    let tmp = ''
    let tmpI = null
    if(props.auctionsMy.relatedOrganizations !== null && props.addressNew.getAddress !== null){
        if(props.auctionsMy.relatedOrganizations.hasOwnProperty('currentTenant') === true){
            if(props.auctionsMy.relatedOrganizations.currentTenant !== null){
                if(props.auctionsMy.relatedOrganizations.currentTenant.hasOwnProperty('id') === true){
                    /*let tmpArr = props.addressNew.getAddress
                    let flag = false
                    for(let i = 0; i < props.addressNew.getAddress.length; i++){
                        if(props.addressNew.getAddress[i].id === props.auctionsMy.relatedOrganizations.currentTenant.id){
                            flag = true
                        }
                    }
                    if(flag === false){
                        let tmpObj = props.auctionsMy.relatedOrganizations.currentTenant
                        tmpObj.name = tmpObj.contactPoint.name 
                        tmpArr.push(tmpObj)
                        props.setDataAddress(tmpArr, 'GET_ADDRESS_NEW_BOOKS')
                    }else{*/
                        tmp = props.auctionsMy.relatedOrganizations.currentTenant.id
                        tmpI = props.auctionsMy.relatedOrganizations.currentTenant
                    //}
                }
            }
        }
    }else{
        props.setDataAddress({
            propertyOwner: {id: 'null'},
            sellingEntity: {id: 'null'},
            currentTenant: {id: 'null'},
            ownershipType: 'null',
        }, 'SET_AUCTION_RELATED_ORGANIZATIONS')
    }
    if(props.typeElem === 1){
        return (
            <>
                <div className={styles.groupElem}>
                    <div>
                        <TextField
                            disabled={disabledElement(props.auctionsMy, '11')}
                            className={styles.inputText}
                            id="outlined-select-currency-native"
                            select
                            label="Чинний орендар"
                            defaultValue={tmp}
                            onChange={(e)=>{
                                props.setDataAddress(e.target.value, 'SET_AUCTION_CURRENT_TENANT_ID')
                            }}
                            variant="outlined"
                        >
                            <option key={0} className={styles.hoverOptions} selected disabled value={0}>Обрати</option>
                            {setOption(props)}
                        </TextField>
                    </div>
                </div>
                <div className={styles.bottonBlock}>
                    <Button
                        disabled={disabledElement(props.auctionsMy, '00')}
                        className={styles.buttonExitDocument}
                        variant="contained"
                        color="primary"
                        onClick={
                            () => {
                                if(tmpI !== null && tmpI !== ''){
                                    props.setDataAddress(tmpI.id, 'CREATE_ADDRESS_NEW_ID')
                                    //props.setDataAddress(i.name, 'CREATE_ADDRESS_NEW_NAME')
                                    props.setDataAddress(tmpI.contactPoint.name, 'CREATE_ADDRESS_NEW_NAME')

                                    props.setDataAddress(tmpI.identifier.id, 'CREATE_ADDRESS_NEW_IDENTIFIER_ID')
                                    props.setDataAddress(tmpI.identifier.identifier, 'CREATE_ADDRESS_NEW_IDENTIFIER_IDENTIFIER')
                                    props.setDataAddress(tmpI.identifier.scheme, 'CREATE_ADDRESS_NEW_IDENTIFIER_SCHEME')
                                    props.setDataAddress(tmpI.identifier.legalName, 'CREATE_ADDRESS_NEW_IDENTIFIER_LEGAL_NAME')

                                    props.setDataAddress(tmpI.additionalIdentifiers.id, 'CREATE_ADDRESS_NEW_ADDITIONAL_IDENTIFIERS_ID')
                                    props.setDataAddress(tmpI.additionalIdentifiers.identifier, 'CREATE_ADDRESS_NEW_ADDITIONAL_IDENTIFIERS_IDENTIFIER')
                                    props.setDataAddress(tmpI.additionalIdentifiers.scheme, 'CREATE_ADDRESS_NEW_ADDITIONAL_IDENTIFIERS_SCHEME')
                                    props.setDataAddress(tmpI.additionalIdentifiers.legalName, 'CREATE_ADDRESS_NEW_ADDITIONAL_IDENTIFIERS_LEGAL_NAME')

                                    if(tmpI.address.addressIdentifier !== null){
                                        props.setDataAddress(tmpI.address.addressIdentifier.id, 'CREATE_ADDRESS_NEW_ADDRESS_ADDITIONAL_IDENTIFIERS_ID')
                                        props.setDataAddress(tmpI.address.addressIdentifier.identifier, 'CREATE_ADDRESS_NEW_ADDRESS_ADDITIONAL_IDENTIFIERS_IDENTIFIER')
                                        props.setDataAddress(tmpI.address.addressIdentifier.scheme, 'CREATE_ADDRESS_NEW_ADDRESS_ADDITIONAL_IDENTIFIERS_SCHEME')
                                        props.setDataAddress(tmpI.address.addressIdentifier.legalName, 'CREATE_ADDRESS_NEW_ADDRESS_ADDITIONAL_IDENTIFIERS_LEGAL_NAME')
                                    }
                                    props.setDataAddress(tmpI.address.id, 'CREATE_ADDRESS_NEW_ADDRESS_ID')
                                    props.setDataAddress(tmpI.address.streetAddress, 'CREATE_ADDRESS_NEW_ADDRESS_STREET_ADDRESS')
                                    let tmp = null
                                    if(tmpI.address.region === 'Автономна Республіка Крим' || tmpI.address.region === "АВТОНОМНА РЕСПУБЛІКА КРИМ/М.СІМФЕРОПОЛЬ"){
                                        tmp = "0100000000+:+АВТОНОМНА РЕСПУБЛІКА КРИМ/М.СІМФЕРОПОЛЬ"
                                    }else if(tmpI.address.region === 'Вінницька область' || tmpI.address.region === "ВІННИЦЬКА ОБЛАСТЬ/М.ВІННИЦЯ"){
                                        tmp = "0500000000+:+ВІННИЦЬКА ОБЛАСТЬ/М.ВІННИЦЯ"
                                    }else if(tmpI.address.region === 'Волинська область' || tmpI.address.region === "ВОЛИНСЬКА ОБЛАСТЬ/М.ЛУЦЬК"){
                                        tmp = "0700000000+:+ВОЛИНСЬКА ОБЛАСТЬ/М.ЛУЦЬК"
                                    }else if(tmpI.address.region === 'Дніпропетровська область' || tmpI.address.region === "ДНІПРОПЕТРОВСЬКА ОБЛАСТЬ/М.ДНІПРО"){
                                        tmp = "1200000000+:+ДНІПРОПЕТРОВСЬКА ОБЛАСТЬ/М.ДНІПРО"
                                    }else if(tmpI.address.region === 'Донецька область' || tmpI.address.region === "ДОНЕЦЬКА ОБЛАСТЬ/М.ДОНЕЦЬК"){
                                        tmp = "1400000000+:+ДОНЕЦЬКА ОБЛАСТЬ/М.ДОНЕЦЬК"
                                    }else if(tmpI.address.region === 'Житомирська область' || tmpI.address.region === "ЖИТОМИРСЬКА ОБЛАСТЬ/М.ЖИТОМИР"){
                                        tmp = "1800000000+:+ЖИТОМИРСЬКА ОБЛАСТЬ/М.ЖИТОМИР"
                                    }else if(tmpI.address.region === 'Закарпатська область' || tmpI.address.region === "ЗАКАРПАТСЬКА ОБЛАСТЬ/М.УЖГОРОД"){
                                        tmp = "2100000000+:+ЗАКАРПАТСЬКА ОБЛАСТЬ/М.УЖГОРОД"
                                    }else if(tmpI.address.region === 'Запорізька область' || tmpI.address.region === "ЗАПОРІЗЬКА ОБЛАСТЬ/М.ЗАПОРІЖЖЯ"){
                                        tmp = "2300000000+:+ЗАПОРІЗЬКА ОБЛАСТЬ/М.ЗАПОРІЖЖЯ"
                                    }else if(tmpI.address.region === 'Івано-Франківська область' || tmpI.address.region === "ІВАНО-ФРАНКІВСЬКА ОБЛАСТЬ/М.ІВАНО-ФРАНКІВСЬК"){
                                        tmp = "2600000000+:+ІВАНО-ФРАНКІВСЬКА ОБЛАСТЬ/М.ІВАНО-ФРАНКІВСЬК"
                                    }else if(tmpI.address.region === 'Київська область' || tmpI.address.region === "КИЇВСЬКА ОБЛАСТЬ/М.КИЇВ"){
                                        tmp = "3200000000+:+КИЇВСЬКА ОБЛАСТЬ/М.КИЇВ"
                                    }else if(tmpI.address.region === 'Кіровоградська область' || tmpI.address.region === "КІРОВОГРАДСЬКА ОБЛАСТЬ/М.КРОПИВНИЦЬКИЙ"){
                                        tmp = "3500000000+:+КІРОВОГРАДСЬКА ОБЛАСТЬ/М.КРОПИВНИЦЬКИЙ"
                                    }else if(tmpI.address.region === 'Луганська область' || tmpI.address.region === "ЛУГАНСЬКА ОБЛАСТЬ/М.ЛУГАНСЬК"){
                                        tmp = "4400000000+:+ЛУГАНСЬКА ОБЛАСТЬ/М.ЛУГАНСЬК"
                                    }else if(tmpI.address.region === 'Львівська область' || tmpI.address.region === "ЛЬВІВСЬКА ОБЛАСТЬ/М.ЛЬВІВ"){
                                        tmp = "4600000000+:+ЛЬВІВСЬКА ОБЛАСТЬ/М.ЛЬВІВ"
                                    }else if(tmpI.address.region === 'Миколаївська область' || tmpI.address.region === "МИКОЛАЇВСЬКА ОБЛАСТЬ/М.МИКОЛАЇВ"){
                                        tmp = "4800000000+:+МИКОЛАЇВСЬКА ОБЛАСТЬ/М.МИКОЛАЇВ"
                                    }else if(tmpI.address.region === 'Одеська область' || tmpI.address.region === "ОДЕСЬКА ОБЛАСТЬ/М.ОДЕСА"){
                                        tmp = "5100000000+:+ОДЕСЬКА ОБЛАСТЬ/М.ОДЕСА"
                                    }else if(tmpI.address.region === 'Полтавська область' || tmpI.address.region === "ПОЛТАВСЬКА ОБЛАСТЬ/М.ПОЛТАВА"){
                                        tmp = "5300000000+:+ПОЛТАВСЬКА ОБЛАСТЬ/М.ПОЛТАВА"
                                    }else if(tmpI.address.region === 'Рівненська область' || tmpI.address.region === "РІВНЕНСЬКА ОБЛАСТЬ/М.РІВНЕ"){
                                        tmp = "5600000000+:+РІВНЕНСЬКА ОБЛАСТЬ/М.РІВНЕ"
                                    }else if(tmpI.address.region === 'Сумська область' || tmpI.address.region === "СУМСЬКА ОБЛАСТЬ/М.СУМИ"){
                                        tmp = "5900000000+:+СУМСЬКА ОБЛАСТЬ/М.СУМИ"
                                    }else if(tmpI.address.region === 'Тернопільська область' || tmpI.address.region === "ТЕРНОПІЛЬСЬКА ОБЛАСТЬ/М.ТЕРНОПІЛЬ"){
                                        tmp = "6100000000+:+ТЕРНОПІЛЬСЬКА ОБЛАСТЬ/М.ТЕРНОПІЛЬ"
                                    }else if(tmpI.address.region === 'Харківська область' || tmpI.address.region === "ХАРКІВСЬКА ОБЛАСТЬ/М.ХАРКІВ"){
                                        tmp = "6300000000+:+ХАРКІВСЬКА ОБЛАСТЬ/М.ХАРКІВ"
                                    }else if(tmpI.address.region === 'Херсонська область' || tmpI.address.region === "ХЕРСОНСЬКА ОБЛАСТЬ/М.ХЕРСОН"){
                                        tmp = "6500000000+:+ХЕРСОНСЬКА ОБЛАСТЬ/М.ХЕРСОН"
                                    }else if(tmpI.address.region === 'Хмельницька область' || tmpI.address.region === "ХМЕЛЬНИЦЬКА ОБЛАСТЬ/М.ХМЕЛЬНИЦЬКИЙ"){
                                        tmp = "6800000000+:+ХМЕЛЬНИЦЬКА ОБЛАСТЬ/М.ХМЕЛЬНИЦЬКИЙ"
                                    }else if(tmpI.address.region === 'Черкаська область' || tmpI.address.region === "ЧЕРКАСЬКА ОБЛАСТЬ/М.ЧЕРКАСИ"){
                                        tmp = "7100000000+:+ЧЕРКАСЬКА ОБЛАСТЬ/М.ЧЕРКАСИ"
                                    }else if(tmpI.address.region === 'Чернівецька область' || tmpI.address.region === "ЧЕРНІВЕЦЬКА ОБЛАСТЬ/М.ЧЕРНІВЦІ"){
                                        tmp = "7300000000+:+ЧЕРНІВЕЦЬКА ОБЛАСТЬ/М.ЧЕРНІВЦІ"
                                    }else if(tmpI.address.region === 'Чернігівська область' || tmpI.address.region === "ЧЕРНІГІВСЬКА ОБЛАСТЬ/М.ЧЕРНІГІВ"){
                                        tmp = "7400000000+:+ЧЕРНІГІВСЬКА ОБЛАСТЬ/М.ЧЕРНІГІВ"
                                    }else if(tmpI.address.region === 'Київ' || tmpI.address.region === "М.КИЇВ"){
                                        tmp = "8000000000+:+М.КИЇВ"
                                    }else if(tmpI.address.region === 'Севастополь' || tmpI.address.region === "М.СЕВАСТОПОЛЬ"){
                                        tmp = "8500000000+:+М.СЕВАСТОПОЛЬ"
                                    }
                                    /*let tmpRegionOption = null
                                    if(props.koatuu.region !== null){
                                        for(let tt = 0; tt < props.koatuu.region.length; tt++){
                                            if(props.koatuu.region[tt].description === tmp){
                                                tmpRegionOption = `${props.koatuu.region[tt].classificationId}+:+${props.koatuu.region[tt].description}`
                                            }
                                        }
                                    }*/
                                    props.setDataAddress(tmp, 'CREATE_ADDRESS_NEW_ADDRESS_REGION')
                                    //props.setDataAddress(tmpRegionOption, 'CREATE_ADDRESS_NEW_ADDRESS_REGION')
                                    createLine(props, tmp)
                                    props.setDataAddress(tmpI.address.locality, 'CREATE_ADDRESS_NEW_ADDRESS_LOCALITY')
                                    props.setDataAddress(tmpI.address.postalCode, 'CREATE_ADDRESS_NEW_ADDRESS_POSTAL_CODE')
                                    props.setDataAddress(tmpI.address.countryName, 'CREATE_ADDRESS_NEW_ADDRESS_COUNTRY_NAME')

                                    props.setDataAddress(tmpI.contactPoint.id, 'CREATE_ADDRESS_NEW_CONTACT_POINT_ID')
                                    props.setDataAddress(tmpI.contactPoint.name, 'CREATE_ADDRESS_NEW_CONTACT_POINT_NAME')
                                    props.setDataAddress(tmpI.contactPoint.email, 'CREATE_ADDRESS_NEW_CONTACT_POINT_EMAIL')
                                    props.setDataAddress(tmpI.contactPoint.telephone, 'CREATE_ADDRESS_NEW_CONTACT_POINT_TELEPHONE')
                                    props.setDataAddress(tmpI.contactPoint.faxNumber, 'CREATE_ADDRESS_NEW_CONTACT_POINT_FAX_NUMBER')
                                    props.setDataAddress(tmpI.contactPoint.url, 'CREATE_ADDRESS_NEW_CONTACT_POINT_URL')

                                    props.setDataAddress(tmpI.representativeInfo, 'CREATE_ADDRESS_NEW_REPRESENTATIVE_INFO')

                                    if(tmpI.currentContractTime !== null){
                                        props.setDataAddress(tmpI.currentContractTime.startDate, 'SET_ADDRESS_NEW_CURRENT_CONTRACT_TIME_START_DATE')
                                        props.setDataAddress(tmpI.currentContractTime.endDate, 'SET_ADDRESS_NEW_CURRENT_CONTRACT_TIME_END_DATE')
                                    }

                                    props.setDataAddress(true, 'CREATE_ADDRESS_NEW_OPEN_CLOSE')
                                    props.setDataAddress(true, 'CREATE_ADDRESS_TYPE_ORG')
                                }
                            }
                        }
                    >
                        Редагування чинного орендаря
                    </Button>
                </div>
            </>
        )
    }else{
        return (
            <>
                <div className={styles.groupElem}>
                    <div>
                        <TextField
                            disabled={disabledElement(props.auctionsMy, '11')}
                            className={styles.inputText}
                            id="outlined-select-currency-native"
                            select
                            label="Чинний орендар"
                            defaultValue={tmp}
                            onChange={(e)=>{
                                props.setDataAddress(e.target.value, 'SET_AUCTION_CURRENT_TENANT_ID')
                            }}
                            variant="outlined"
                        >
                            <option key={0} className={styles.hoverOptions} selected disabled value={0}>Обрати</option>
                            {setOption(props)}
                        </TextField>
                    </div>
                </div>
                <div className={styles.bottonBlock}>
                    <Button
                        disabled={disabledElement(props.auctionsMy, '00')}
                        className={styles.buttonExitDocument}
                        variant="contained"
                        color="primary"
                        onClick={
                            () => {
                                if(tmpI !== null && tmpI !== ''){
                                    props.setDataAddress(tmpI.id, 'CREATE_ADDRESS_NEW_ID')
                                    //props.setDataAddress(i.name, 'CREATE_ADDRESS_NEW_NAME')
                                    props.setDataAddress(tmpI.contactPoint.name, 'CREATE_ADDRESS_NEW_NAME')

                                    props.setDataAddress(tmpI.identifier.id, 'CREATE_ADDRESS_NEW_IDENTIFIER_ID')
                                    props.setDataAddress(tmpI.identifier.identifier, 'CREATE_ADDRESS_NEW_IDENTIFIER_IDENTIFIER')
                                    props.setDataAddress(tmpI.identifier.scheme, 'CREATE_ADDRESS_NEW_IDENTIFIER_SCHEME')
                                    props.setDataAddress(tmpI.identifier.legalName, 'CREATE_ADDRESS_NEW_IDENTIFIER_LEGAL_NAME')

                                    props.setDataAddress(tmpI.additionalIdentifiers.id, 'CREATE_ADDRESS_NEW_ADDITIONAL_IDENTIFIERS_ID')
                                    props.setDataAddress(tmpI.additionalIdentifiers.identifier, 'CREATE_ADDRESS_NEW_ADDITIONAL_IDENTIFIERS_IDENTIFIER')
                                    props.setDataAddress(tmpI.additionalIdentifiers.scheme, 'CREATE_ADDRESS_NEW_ADDITIONAL_IDENTIFIERS_SCHEME')
                                    props.setDataAddress(tmpI.additionalIdentifiers.legalName, 'CREATE_ADDRESS_NEW_ADDITIONAL_IDENTIFIERS_LEGAL_NAME')

                                    if(tmpI.address.addressIdentifier !== null){
                                        props.setDataAddress(tmpI.address.addressIdentifier.id, 'CREATE_ADDRESS_NEW_ADDRESS_ADDITIONAL_IDENTIFIERS_ID')
                                        props.setDataAddress(tmpI.address.addressIdentifier.identifier, 'CREATE_ADDRESS_NEW_ADDRESS_ADDITIONAL_IDENTIFIERS_IDENTIFIER')
                                        props.setDataAddress(tmpI.address.addressIdentifier.scheme, 'CREATE_ADDRESS_NEW_ADDRESS_ADDITIONAL_IDENTIFIERS_SCHEME')
                                        props.setDataAddress(tmpI.address.addressIdentifier.legalName, 'CREATE_ADDRESS_NEW_ADDRESS_ADDITIONAL_IDENTIFIERS_LEGAL_NAME')
                                    }
                                    props.setDataAddress(tmpI.address.id, 'CREATE_ADDRESS_NEW_ADDRESS_ID')
                                    props.setDataAddress(tmpI.address.streetAddress, 'CREATE_ADDRESS_NEW_ADDRESS_STREET_ADDRESS')
                                    let tmp = null
                                    if(tmpI.address.region === 'Автономна Республіка Крим' || tmpI.address.region === "АВТОНОМНА РЕСПУБЛІКА КРИМ/М.СІМФЕРОПОЛЬ"){
                                        tmp = "0100000000+:+АВТОНОМНА РЕСПУБЛІКА КРИМ/М.СІМФЕРОПОЛЬ"
                                    }else if(tmpI.address.region === 'Вінницька область' || tmpI.address.region === "ВІННИЦЬКА ОБЛАСТЬ/М.ВІННИЦЯ"){
                                        tmp = "0500000000+:+ВІННИЦЬКА ОБЛАСТЬ/М.ВІННИЦЯ"
                                    }else if(tmpI.address.region === 'Волинська область' || tmpI.address.region === "ВОЛИНСЬКА ОБЛАСТЬ/М.ЛУЦЬК"){
                                        tmp = "0700000000+:+ВОЛИНСЬКА ОБЛАСТЬ/М.ЛУЦЬК"
                                    }else if(tmpI.address.region === 'Дніпропетровська область' || tmpI.address.region === "ДНІПРОПЕТРОВСЬКА ОБЛАСТЬ/М.ДНІПРО"){
                                        tmp = "1200000000+:+ДНІПРОПЕТРОВСЬКА ОБЛАСТЬ/М.ДНІПРО"
                                    }else if(tmpI.address.region === 'Донецька область' || tmpI.address.region === "ДОНЕЦЬКА ОБЛАСТЬ/М.ДОНЕЦЬК"){
                                        tmp = "1400000000+:+ДОНЕЦЬКА ОБЛАСТЬ/М.ДОНЕЦЬК"
                                    }else if(tmpI.address.region === 'Житомирська область' || tmpI.address.region === "ЖИТОМИРСЬКА ОБЛАСТЬ/М.ЖИТОМИР"){
                                        tmp = "1800000000+:+ЖИТОМИРСЬКА ОБЛАСТЬ/М.ЖИТОМИР"
                                    }else if(tmpI.address.region === 'Закарпатська область' || tmpI.address.region === "ЗАКАРПАТСЬКА ОБЛАСТЬ/М.УЖГОРОД"){
                                        tmp = "2100000000+:+ЗАКАРПАТСЬКА ОБЛАСТЬ/М.УЖГОРОД"
                                    }else if(tmpI.address.region === 'Запорізька область' || tmpI.address.region === "ЗАПОРІЗЬКА ОБЛАСТЬ/М.ЗАПОРІЖЖЯ"){
                                        tmp = "2300000000+:+ЗАПОРІЗЬКА ОБЛАСТЬ/М.ЗАПОРІЖЖЯ"
                                    }else if(tmpI.address.region === 'Івано-Франківська область' || tmpI.address.region === "ІВАНО-ФРАНКІВСЬКА ОБЛАСТЬ/М.ІВАНО-ФРАНКІВСЬК"){
                                        tmp = "2600000000+:+ІВАНО-ФРАНКІВСЬКА ОБЛАСТЬ/М.ІВАНО-ФРАНКІВСЬК"
                                    }else if(tmpI.address.region === 'Київська область' || tmpI.address.region === "КИЇВСЬКА ОБЛАСТЬ/М.КИЇВ"){
                                        tmp = "3200000000+:+КИЇВСЬКА ОБЛАСТЬ/М.КИЇВ"
                                    }else if(tmpI.address.region === 'Кіровоградська область' || tmpI.address.region === "КІРОВОГРАДСЬКА ОБЛАСТЬ/М.КРОПИВНИЦЬКИЙ"){
                                        tmp = "3500000000+:+КІРОВОГРАДСЬКА ОБЛАСТЬ/М.КРОПИВНИЦЬКИЙ"
                                    }else if(tmpI.address.region === 'Луганська область' || tmpI.address.region === "ЛУГАНСЬКА ОБЛАСТЬ/М.ЛУГАНСЬК"){
                                        tmp = "4400000000+:+ЛУГАНСЬКА ОБЛАСТЬ/М.ЛУГАНСЬК"
                                    }else if(tmpI.address.region === 'Львівська область' || tmpI.address.region === "ЛЬВІВСЬКА ОБЛАСТЬ/М.ЛЬВІВ"){
                                        tmp = "4600000000+:+ЛЬВІВСЬКА ОБЛАСТЬ/М.ЛЬВІВ"
                                    }else if(tmpI.address.region === 'Миколаївська область' || tmpI.address.region === "МИКОЛАЇВСЬКА ОБЛАСТЬ/М.МИКОЛАЇВ"){
                                        tmp = "4800000000+:+МИКОЛАЇВСЬКА ОБЛАСТЬ/М.МИКОЛАЇВ"
                                    }else if(tmpI.address.region === 'Одеська область' || tmpI.address.region === "ОДЕСЬКА ОБЛАСТЬ/М.ОДЕСА"){
                                        tmp = "5100000000+:+ОДЕСЬКА ОБЛАСТЬ/М.ОДЕСА"
                                    }else if(tmpI.address.region === 'Полтавська область' || tmpI.address.region === "ПОЛТАВСЬКА ОБЛАСТЬ/М.ПОЛТАВА"){
                                        tmp = "5300000000+:+ПОЛТАВСЬКА ОБЛАСТЬ/М.ПОЛТАВА"
                                    }else if(tmpI.address.region === 'Рівненська область' || tmpI.address.region === "РІВНЕНСЬКА ОБЛАСТЬ/М.РІВНЕ"){
                                        tmp = "5600000000+:+РІВНЕНСЬКА ОБЛАСТЬ/М.РІВНЕ"
                                    }else if(tmpI.address.region === 'Сумська область' || tmpI.address.region === "СУМСЬКА ОБЛАСТЬ/М.СУМИ"){
                                        tmp = "5900000000+:+СУМСЬКА ОБЛАСТЬ/М.СУМИ"
                                    }else if(tmpI.address.region === 'Тернопільська область' || tmpI.address.region === "ТЕРНОПІЛЬСЬКА ОБЛАСТЬ/М.ТЕРНОПІЛЬ"){
                                        tmp = "6100000000+:+ТЕРНОПІЛЬСЬКА ОБЛАСТЬ/М.ТЕРНОПІЛЬ"
                                    }else if(tmpI.address.region === 'Харківська область' || tmpI.address.region === "ХАРКІВСЬКА ОБЛАСТЬ/М.ХАРКІВ"){
                                        tmp = "6300000000+:+ХАРКІВСЬКА ОБЛАСТЬ/М.ХАРКІВ"
                                    }else if(tmpI.address.region === 'Херсонська область' || tmpI.address.region === "ХЕРСОНСЬКА ОБЛАСТЬ/М.ХЕРСОН"){
                                        tmp = "6500000000+:+ХЕРСОНСЬКА ОБЛАСТЬ/М.ХЕРСОН"
                                    }else if(tmpI.address.region === 'Хмельницька область' || tmpI.address.region === "ХМЕЛЬНИЦЬКА ОБЛАСТЬ/М.ХМЕЛЬНИЦЬКИЙ"){
                                        tmp = "6800000000+:+ХМЕЛЬНИЦЬКА ОБЛАСТЬ/М.ХМЕЛЬНИЦЬКИЙ"
                                    }else if(tmpI.address.region === 'Черкаська область' || tmpI.address.region === "ЧЕРКАСЬКА ОБЛАСТЬ/М.ЧЕРКАСИ"){
                                        tmp = "7100000000+:+ЧЕРКАСЬКА ОБЛАСТЬ/М.ЧЕРКАСИ"
                                    }else if(tmpI.address.region === 'Чернівецька область' || tmpI.address.region === "ЧЕРНІВЕЦЬКА ОБЛАСТЬ/М.ЧЕРНІВЦІ"){
                                        tmp = "7300000000+:+ЧЕРНІВЕЦЬКА ОБЛАСТЬ/М.ЧЕРНІВЦІ"
                                    }else if(tmpI.address.region === 'Чернігівська область' || tmpI.address.region === "ЧЕРНІГІВСЬКА ОБЛАСТЬ/М.ЧЕРНІГІВ"){
                                        tmp = "7400000000+:+ЧЕРНІГІВСЬКА ОБЛАСТЬ/М.ЧЕРНІГІВ"
                                    }else if(tmpI.address.region === 'Київ' || tmpI.address.region === "М.КИЇВ"){
                                        tmp = "8000000000+:+М.КИЇВ"
                                    }else if(tmpI.address.region === 'Севастополь' || tmpI.address.region === "М.СЕВАСТОПОЛЬ"){
                                        tmp = "8500000000+:+М.СЕВАСТОПОЛЬ"
                                    }
                                    /*let tmpRegionOption = null
                                    if(props.koatuu.region !== null){
                                        for(let tt = 0; tt < props.koatuu.region.length; tt++){
                                            if(props.koatuu.region[tt].description === tmp){
                                                tmpRegionOption = `${props.koatuu.region[tt].classificationId}+:+${props.koatuu.region[tt].description}`
                                            }
                                        }
                                    }*/
                                    props.setDataAddress(tmp, 'CREATE_ADDRESS_NEW_ADDRESS_REGION')
                                    //props.setDataAddress(tmpRegionOption, 'CREATE_ADDRESS_NEW_ADDRESS_REGION')
                                    createLine(props, tmp)
                                    props.setDataAddress(tmpI.address.locality, 'CREATE_ADDRESS_NEW_ADDRESS_LOCALITY')
                                    props.setDataAddress(tmpI.address.postalCode, 'CREATE_ADDRESS_NEW_ADDRESS_POSTAL_CODE')
                                    props.setDataAddress(tmpI.address.countryName, 'CREATE_ADDRESS_NEW_ADDRESS_COUNTRY_NAME')

                                    props.setDataAddress(tmpI.contactPoint.id, 'CREATE_ADDRESS_NEW_CONTACT_POINT_ID')
                                    props.setDataAddress(tmpI.contactPoint.name, 'CREATE_ADDRESS_NEW_CONTACT_POINT_NAME')
                                    props.setDataAddress(tmpI.contactPoint.email, 'CREATE_ADDRESS_NEW_CONTACT_POINT_EMAIL')
                                    props.setDataAddress(tmpI.contactPoint.telephone, 'CREATE_ADDRESS_NEW_CONTACT_POINT_TELEPHONE')
                                    props.setDataAddress(tmpI.contactPoint.faxNumber, 'CREATE_ADDRESS_NEW_CONTACT_POINT_FAX_NUMBER')
                                    props.setDataAddress(tmpI.contactPoint.url, 'CREATE_ADDRESS_NEW_CONTACT_POINT_URL')

                                    props.setDataAddress(tmpI.representativeInfo, 'CREATE_ADDRESS_NEW_REPRESENTATIVE_INFO')

                                    if(tmpI.currentContractTime !== null){
                                        props.setDataAddress(tmpI.currentContractTime.startDate, 'SET_ADDRESS_NEW_CURRENT_CONTRACT_TIME_START_DATE')
                                        props.setDataAddress(tmpI.currentContractTime.endDate, 'SET_ADDRESS_NEW_CURRENT_CONTRACT_TIME_END_DATE')
                                    }

                                    props.setDataAddress(true, 'CREATE_ADDRESS_NEW_OPEN_CLOSE')
                                    props.setDataAddress(true, 'CREATE_ADDRESS_TYPE_ORG')
                                }
                            }
                        }
                    >
                        Редагування чинного орендаря
                    </Button>
                </div>
            </>
        )
    }
}

function createLine(props, tmp){
    let tmpRegionOption = null
    if(props.koatuu.region !== null){
        for(let i = 0; i < props.koatuu.region.length; i++){
            if(props.koatuu.region[i].description === tmp){
                tmpRegionOption = `${props.koatuu.region[i].classificationId}+:+${props.koatuu.region[i].description}`
            }
        }
    }
    if(tmpRegionOption !== null){
        props.regionAddress(tmpRegionOption)
        let t = tmpRegionOption.substr(0, 2)
        props.setKoatuuGorod(null)
        props.localityAddress('null')
        getKoatuuGorod(props, t)
    }
}

async function getKoatuuGorod(props, id){
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/classifications/koatuu/byMask?mask=${id}`;
    const response = await fetch(endPoint, {
        method: "GET",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json1 = await response.json()
        if(json1.hasOwnProperty('message') === true){}
        else{
            props.setKoatuuGorod(json1)
        }
        
    }
}

function viewLesureRules(props){
    let tmp = null
    if(props.auctionsMy.leaseRules !== null){
        if(props.auctionsMy.leaseRules.hasOwnProperty('intendedUseRestrictionMethod') === true){
            if(props.auctionsMy.leaseRules.intendedUseRestrictionMethod === 'exceptDescribed'){
                tmp = <>
                    {viewIntendedUse(props)}
                    <div>Обрані значення</div>
                    {viewArrIntendedUse(props)}
                </>
            }
        }
    }
    if(props.typeElem === 1){
        return <>
            <div>{TranslationsUk['legitimatePropertyLease-english'].legitimatePropertyLeaseEnglishProcedure.leaseRules.legalName}</div>
            {viewIntendedUseRestrictionMethod(props)}
            {tmp}
            {viewIntendedUseRestrictionDescription(props)}
            {viewAdditionalLeaseConditions(props)}
            {viewSubleaseAllowed(props)}
        </>
    }else{
        return <>
            <div>{TranslationsUk['legitimatePropertyLease-english'].legitimatePropertyLeaseEnglishProcedure.leaseRules.legalName}</div>
            {viewIntendedUseRestrictionMethod(props)}
            {tmp}
            {viewIntendedUseRestrictionDescription(props)}
            {viewAdditionalLeaseConditions(props)}
            {viewSubleaseAllowed(props)}
        </>
    }
}

function viewIntendedUse(props){
    let tmp = ''
    if(props.auctionsMy.leaseRules !== null){
        if(props.auctionsMy.leaseRules.hasOwnProperty('intendedUse') === true){
            if(props.auctionsMy.leaseRules.intendedUse !== null){
                tmp = props.auctionsMy.leaseRules.intendedUse
            }
        }
    }else{
        props.setDataAddress({
            intendedUse: 'null',
            intendedUseRestrictionMethod: 'null',
            intendedUseRestrictionDescription: '',
            additionalLeaseConditions: '',
            subleaseAllowed: true,
        }, 'SET_AUCTION_LEASE_RULES')
    }
    if(props.typeElem === 1){
        return (
            <div className={styles.groupElem}>
                <div>
                    <TextField
                        disabled={disabledElement(props.auctionsMy, '01')}
                        className={styles.inputText}
                        id="outlined-select-currency-native"
                        select
                        label='Обмеження щодо використання майна (заборонені цільові призначення)'
                        defaultValue='null'
                        onChange={(e)=>{
                            let tmpArr = null
                            if(props.auctionsMy.leaseRules !== null){
                                if(props.auctionsMy.leaseRules.hasOwnProperty('intendedUse') === true){
                                    if(props.auctionsMy.leaseRules.intendedUse !== null){
                                        tmpArr = props.auctionsMy.leaseRules.intendedUse
                                    }
                                }
                            }
                            if(tmpArr !== null){
                                if(tmpArr.length === 0){
                                    tmpArr = []
                                    tmpArr.push(e.target.value)
                                    props.changeCreateAuctionLeaseRulesIntendedUse(tmpArr)
                                }else{
                                    tmpArr.push(e.target.value)
                                    props.changeCreateAuctionLeaseRulesIntendedUse(tmpArr)
                                }
                            }else{
                                tmp = []
                                tmp.push(e.target.value)
                                props.changeCreateAuctionLeaseRulesIntendedUse(tmp)
                            }
                        }}
                        variant="outlined"
                    >
                        <option key={0} className={styles.hoverOptions} selected value='null'>Обрати</option>
                        <option key={1} className={styles.hoverOptions} value='agricultureFoodNoAlcoEntp'>Підприємства сільського господарства, харчової промисловості (крім лікеро-горілчаної та виноробної промисловості)</option>
                        <option key={2} className={styles.hoverOptions} value='atelierBeautyServices'>Майстерні, ательє. Салони краси, перукарні. Надання інших побутових послуг населенню</option>
                        <option key={3} className={styles.hoverOptions} value='authoritiesGovernments'>Органи державної влади та органи місцевого самоврядування, інші установи і організації, діяльність яких фінансується за рахунок державного або місцевих бюджетів</option>
                        <option key={4} className={styles.hoverOptions} value='buildingMaterialEntp'>Підприємства з виробництва будівельних матеріалів</option>
                        <option key={5} className={styles.hoverOptions} value='concertEntertExhibEntp'>Підприємства з організації концертно-видовищної та виставкової діяльності</option>
                        <option key={6} className={styles.hoverOptions} value='consumerServicesEntp'>Підприємства побутового обслуговування</option>
                        <option key={7} className={styles.hoverOptions} value='coursesTrainingsEarly'>Центри раннього розвитку дитини. Діяльність у сфері освіти, курси і тренінги</option>
                        <option key={8} className={styles.hoverOptions} value='deputyOffice'>Громадська приймальня народного депутата України або депутата місцевої ради</option>
                        <option key={9} className={styles.hoverOptions} value='diplomats'>Дипломатичні представництва та консульські установи іноземних держав, представництва міжнародних міжурядових організацій в Україні</option>
                        <option key={10} className={styles.hoverOptions} value='disabilityRehabilitation'>Реабілітаційні установи для осіб з інвалідністю та дітей з інвалідністю для розміщення таких реабілітаційних установ</option>
                        <option key={11} className={styles.hoverOptions} value='education'>Заклади освіти</option>
                        <option key={12} className={styles.hoverOptions} value='electricEquipEntp'>Підприємства з виробництва електричного та електронного устаткування</option>
                        <option key={13} className={styles.hoverOptions} value='electricGasChemicalFerrousEntp'>Підприємства електроенергетики, газової, хімічної і нафтохімічної промисловості, чорної металургії</option>
                        <option key={14} className={styles.hoverOptions} value='eventsElection'>Проведення публічних заходів (зборів, дебатів, дискусій) під час та на період виборчої кампанії</option>
                        <option key={15} className={styles.hoverOptions} value='eventsSienceCulturalPublic'>Організація та проведення науково-практичних, культурних, мистецьких, громадських, суспільних та політичних заходів</option>
                        <option key={16} className={styles.hoverOptions} value='exchangeAtmTelecomAdvertizingPress'>Пункти обміну валюти, банкомати, платіжні термінали. Торговельні автомати. Розміщення технічних засобів і антен операторів телекомунікацій, суб’єктів підприємницької діяльності, які надають послуги зв’язку, послуги доступу до Інтернету, телекомунікації, передання сигналу мовлення. Розміщення зовнішньої реклами на будівлях і спорудах. Продаж книг, газет і журналів</option>
                        <option key={17} className={styles.hoverOptions} value='exhibitions'>Проведення виставок</option>
                        <option key={18} className={styles.hoverOptions} value='ferryRailRoadTranspot'>Розміщення морського, залізничного та автомобільного транспорту</option>
                        <option key={19} className={styles.hoverOptions} value='foodBarRestuarantExcisable'>Заклади харчування, кафе, бари, ресторани, які здійснюють продаж товарів підакцизної групи. Торговельні об’єкти, які здійснюють продаж товарів підакцизної групи</option>
                        <option key={20} className={styles.hoverOptions} value='foodBuffetCafeNotExcisable'>Заклади харчування, їдальні, буфети, кафе, які не здійснюють продаж товарів підакцизної групи. Торговельні об’єкти, які не здійснюють продаж товарів підакцизної групи</option>
                        <option key={21} className={styles.hoverOptions} value='forestryFisheryEntp'>Підприємства лісового господарства, рибного господарства</option>
                        <option key={22} className={styles.hoverOptions} value='fuelEntp'>Підприємства паливної промисловості</option>
                        <option key={23} className={styles.hoverOptions} value='garmentTextileEntp'>Підприємства з виробництва швейної та текстильної промисловості</option>
                        <option key={24} className={styles.hoverOptions} value='gymSport'>Тренажерні зали, заклади фізичної культури і спорту, діяльність з організації та проведення занять різними видами спорту</option>
                        <option key={25} className={styles.hoverOptions} value='healthcarePharmaLaboratory'>Заклади охорони здоров’я, клініки, лікарні, приватна медична практика. Аптеки. Ветеринарні лікарні (клініки), лабораторії ветеринарної медицини, ветеринарні аптеки. Медичні лабораторії</option>
                        <option key={26} className={styles.hoverOptions} value='libraryCinemaTheatre'>Бібліотеки. Театри. Кінотеатри, діяльність з кінопоказів</option>
                        <option key={27} className={styles.hoverOptions} value='lotteryEntp'>Підприємства з випуску лотерейних білетів та проведення лотерей</option>
                        <option key={28} className={styles.hoverOptions} value='metallworkLightNoTextileEntp'>Підприємства з металообробки, легкої (крім швейної та текстильної) промисловості</option>
                        <option key={29} className={styles.hoverOptions} value='museums'>Музеї</option>
                        <option key={30} className={styles.hoverOptions} value='ngo'>Громадські об’єднання та благодійні організації</option>
                        <option key={31} className={styles.hoverOptions} value='nightclubRestuarantSaunaHotelInternetclub'>Нічні клуби. Ресторани з нічним режимом роботи (після 22 год). Сауни, лазні. Організація концертів та іншої видовищно-розважальної діяльності. Готелі, хостели, турбази, мотелі, кемпінги, літні будиночки. Комп’ютерні клуби та Інтернет-кафе</option>
                        <option key={32} className={styles.hoverOptions} value='noRestuarantRestuarantEntp'>Підприємства ресторанного господарства (крім ресторанів)</option>
                        <option key={33} className={styles.hoverOptions} value='nonEnergyMiningEntp'>Підприємства з видобування неенергетичних матеріалів</option>
                        <option key={34} className={styles.hoverOptions} value='nonFerrousOilGasEntp'>Підприємства кольорової металургії, нафтогазодобувної промисловості</option>
                        <option key={35} className={styles.hoverOptions} value='officePostMassmediaBank'>Офісні приміщення, коворкінги. Об’єкти поштового зв’язку та розміщення суб’єктів господарювання, що надають послуги з перевезення та доставки (вручення) поштових відправлень. Редакції засобів масової інформації, видавництва друкованих засобів масової інформації та видавничої продукції. Ломбарди, відділення банків, інших провайдерів фінансових послуг</option>
                        <option key={36} className={styles.hoverOptions} value='other'>Інше</option>
                        <option key={37} className={styles.hoverOptions} value='otherProduction'>Розміщення суб’єктів підприємницької діяльності, які здійснюють іншу виробничу діяльність</option>
                        <option key={38} className={styles.hoverOptions} value='parkingCarriageCarRepair'>Стоянки автомобілів. Розміщення транспортних підприємств з перевезення пасажирів і вантажів. Станції технічного обслуговування автомобілів</option>
                        <option key={39} className={styles.hoverOptions} value='pensionFund'>Пенсійний фонд України та його органи</option>
                        <option key={40} className={styles.hoverOptions} value='publicCultural'>Громадські організації (об’єднання) у сфері культури і мистецтв (у тому числі національні творчі спілки або їх члени під творчі майстерні)</option>
                        <option key={41} className={styles.hoverOptions} value='pulpPaperWasteEntp'>Підприємства целюлозно-паперової промисловості, переробки відходів</option>
                        <option key={42} className={styles.hoverOptions} value='religion'>Релігійні організації для забезпечення проведення релігійних обрядів та церемоній</option>
                        <option key={43} className={styles.hoverOptions} value='ritualWcRaw'>Ритуальні послуги. Громадські вбиральні. Збір і сортування вторинної сировини</option>
                        <option key={44} className={styles.hoverOptions} value='rubberPlasticEntp'>Підприємства з виробництва гумових та пластмасових виробів</option>
                        <option key={45} className={styles.hoverOptions} value='science'>Науково-дослідні установи, наукові парки</option>
                        <option key={46} className={styles.hoverOptions} value='socialServicesLaw'>Державні та комунальні спеціалізовані підприємства, установи та заклади соціального обслуговування, що надають соціальні послуги відповідно до Закону України “Про соціальні послуги”</option>
                        <option key={47} className={styles.hoverOptions} value='stateMunicipalCultural'>Державні та комунальні підприємства, установи, організації у сфері культури і мистецтв</option>
                        <option key={48} className={styles.hoverOptions} value='stateMunicipalMassMedia'>Редакції державних і комунальних періодичних видань, державні видавництва, підприємства книгорозповсюдження, вітчизняні видавництва та підприємства книгорозповсюдження, що забезпечують підготовку, випуск та (або) розповсюдження не менш як 50 відсотків книжкової продукції державною мовою (за винятком видань рекламного та еротичного характеру)</option>
                        <option key={49} className={styles.hoverOptions} value='stockArchive'>Склади. Камери схову, архіви</option>
                        <option key={50} className={styles.hoverOptions} value='telecomEntp'>Підприємства зв’язку</option>
                        <option key={51} className={styles.hoverOptions} value='tobaccoAlcoEntp'>Підприємства тютюнової промисловості, лікеро-горілчаної та виноробної промисловості</option>
                        <option key={52} className={styles.hoverOptions} value='tradeEntp'>Підприємства торгівлі</option>
                        <option key={53} className={styles.hoverOptions} value='transportAdditionalEntp'>Підприємства з надання додаткових транспортних послуг та допоміжних операцій</option>
                        <option key={54} className={styles.hoverOptions} value='transportProductionServiceEntp'>Підприємства з виробництва транспортних засобів, устаткування та їх ремонту, виробництва машин та устаткування, призначеного для механічного, термічного оброблення матеріалів або здійснення інших операцій</option>
                        <option key={55} className={styles.hoverOptions} value='veterans'>Громадські організації (об’єднання) ветеранів для розміщення реабілітаційних установ для ветеранів</option>
                        <option key={56} className={styles.hoverOptions} value='woodFurnitureEntp'>Підприємства з виробництва деревини та виробів з деревини, меблів</option>
                    </TextField>
                </div>
            </div>
        )
    }else{
        return (
            <div className={styles.groupElem}>
                <div>
                    <TextField
                        disabled={disabledElement(props.auctionsMy, '01')}
                        className={styles.inputText}
                        id="outlined-select-currency-native"
                        select
                        label='Обмеження щодо використання майна (заборонені цільові призначення)'
                        defaultValue='null'
                        onChange={(e)=>{
                            let tmpArr = null
                            if(props.auctionsMy.leaseRules !== null){
                                if(props.auctionsMy.leaseRules.hasOwnProperty('intendedUse') === true){
                                    if(props.auctionsMy.leaseRules.intendedUse !== null){
                                        tmpArr = props.auctionsMy.leaseRules.intendedUse
                                    }
                                }
                            }
                            if(tmpArr !== null){
                                if(tmpArr.length === 0){
                                    tmpArr = []
                                    tmpArr.push(e.target.value)
                                    props.changeCreateAuctionLeaseRulesIntendedUse(tmpArr)
                                }else{
                                    tmpArr.push(e.target.value)
                                    props.changeCreateAuctionLeaseRulesIntendedUse(tmpArr)
                                }
                            }else{
                                tmp = []
                                tmp.push(e.target.value)
                                props.changeCreateAuctionLeaseRulesIntendedUse(tmp)
                            }
                        }}
                        variant="outlined"
                    >
                        <option key={0} className={styles.hoverOptions} selected value='null'>Обрати</option>
                        <option key={1} className={styles.hoverOptions} value='agricultureFoodNoAlcoEntp'>Підприємства сільського господарства, харчової промисловості (крім лікеро-горілчаної та виноробної промисловості)</option>
                        <option key={2} className={styles.hoverOptions} value='atelierBeautyServices'>Майстерні, ательє. Салони краси, перукарні. Надання інших побутових послуг населенню</option>
                        <option key={3} className={styles.hoverOptions} value='authoritiesGovernments'>Органи державної влади та органи місцевого самоврядування, інші установи і організації, діяльність яких фінансується за рахунок державного або місцевих бюджетів</option>
                        <option key={4} className={styles.hoverOptions} value='buildingMaterialEntp'>Підприємства з виробництва будівельних матеріалів</option>
                        <option key={5} className={styles.hoverOptions} value='concertEntertExhibEntp'>Підприємства з організації концертно-видовищної та виставкової діяльності</option>
                        <option key={6} className={styles.hoverOptions} value='consumerServicesEntp'>Підприємства побутового обслуговування</option>
                        <option key={7} className={styles.hoverOptions} value='coursesTrainingsEarly'>Центри раннього розвитку дитини. Діяльність у сфері освіти, курси і тренінги</option>
                        <option key={8} className={styles.hoverOptions} value='deputyOffice'>Громадська приймальня народного депутата України або депутата місцевої ради</option>
                        <option key={9} className={styles.hoverOptions} value='diplomats'>Дипломатичні представництва та консульські установи іноземних держав, представництва міжнародних міжурядових організацій в Україні</option>
                        <option key={10} className={styles.hoverOptions} value='disabilityRehabilitation'>Реабілітаційні установи для осіб з інвалідністю та дітей з інвалідністю для розміщення таких реабілітаційних установ</option>
                        <option key={11} className={styles.hoverOptions} value='education'>Заклади освіти</option>
                        <option key={12} className={styles.hoverOptions} value='electricEquipEntp'>Підприємства з виробництва електричного та електронного устаткування</option>
                        <option key={13} className={styles.hoverOptions} value='electricGasChemicalFerrousEntp'>Підприємства електроенергетики, газової, хімічної і нафтохімічної промисловості, чорної металургії</option>
                        <option key={14} className={styles.hoverOptions} value='eventsElection'>Проведення публічних заходів (зборів, дебатів, дискусій) під час та на період виборчої кампанії</option>
                        <option key={15} className={styles.hoverOptions} value='eventsSienceCulturalPublic'>Організація та проведення науково-практичних, культурних, мистецьких, громадських, суспільних та політичних заходів</option>
                        <option key={16} className={styles.hoverOptions} value='exchangeAtmTelecomAdvertizingPress'>Пункти обміну валюти, банкомати, платіжні термінали. Торговельні автомати. Розміщення технічних засобів і антен операторів телекомунікацій, суб’єктів підприємницької діяльності, які надають послуги зв’язку, послуги доступу до Інтернету, телекомунікації, передання сигналу мовлення. Розміщення зовнішньої реклами на будівлях і спорудах. Продаж книг, газет і журналів</option>
                        <option key={17} className={styles.hoverOptions} value='exhibitions'>Проведення виставок</option>
                        <option key={18} className={styles.hoverOptions} value='ferryRailRoadTranspot'>Розміщення морського, залізничного та автомобільного транспорту</option>
                        <option key={19} className={styles.hoverOptions} value='foodBarRestuarantExcisable'>Заклади харчування, кафе, бари, ресторани, які здійснюють продаж товарів підакцизної групи. Торговельні об’єкти, які здійснюють продаж товарів підакцизної групи</option>
                        <option key={20} className={styles.hoverOptions} value='foodBuffetCafeNotExcisable'>Заклади харчування, їдальні, буфети, кафе, які не здійснюють продаж товарів підакцизної групи. Торговельні об’єкти, які не здійснюють продаж товарів підакцизної групи</option>
                        <option key={21} className={styles.hoverOptions} value='forestryFisheryEntp'>Підприємства лісового господарства, рибного господарства</option>
                        <option key={22} className={styles.hoverOptions} value='fuelEntp'>Підприємства паливної промисловості</option>
                        <option key={23} className={styles.hoverOptions} value='garmentTextileEntp'>Підприємства з виробництва швейної та текстильної промисловості</option>
                        <option key={24} className={styles.hoverOptions} value='gymSport'>Тренажерні зали, заклади фізичної культури і спорту, діяльність з організації та проведення занять різними видами спорту</option>
                        <option key={25} className={styles.hoverOptions} value='healthcarePharmaLaboratory'>Заклади охорони здоров’я, клініки, лікарні, приватна медична практика. Аптеки. Ветеринарні лікарні (клініки), лабораторії ветеринарної медицини, ветеринарні аптеки. Медичні лабораторії</option>
                        <option key={26} className={styles.hoverOptions} value='libraryCinemaTheatre'>Бібліотеки. Театри. Кінотеатри, діяльність з кінопоказів</option>
                        <option key={27} className={styles.hoverOptions} value='lotteryEntp'>Підприємства з випуску лотерейних білетів та проведення лотерей</option>
                        <option key={28} className={styles.hoverOptions} value='metallworkLightNoTextileEntp'>Підприємства з металообробки, легкої (крім швейної та текстильної) промисловості</option>
                        <option key={29} className={styles.hoverOptions} value='museums'>Музеї</option>
                        <option key={30} className={styles.hoverOptions} value='ngo'>Громадські об’єднання та благодійні організації</option>
                        <option key={31} className={styles.hoverOptions} value='nightclubRestuarantSaunaHotelInternetclub'>Нічні клуби. Ресторани з нічним режимом роботи (після 22 год). Сауни, лазні. Організація концертів та іншої видовищно-розважальної діяльності. Готелі, хостели, турбази, мотелі, кемпінги, літні будиночки. Комп’ютерні клуби та Інтернет-кафе</option>
                        <option key={32} className={styles.hoverOptions} value='noRestuarantRestuarantEntp'>Підприємства ресторанного господарства (крім ресторанів)</option>
                        <option key={33} className={styles.hoverOptions} value='nonEnergyMiningEntp'>Підприємства з видобування неенергетичних матеріалів</option>
                        <option key={34} className={styles.hoverOptions} value='nonFerrousOilGasEntp'>Підприємства кольорової металургії, нафтогазодобувної промисловості</option>
                        <option key={35} className={styles.hoverOptions} value='officePostMassmediaBank'>Офісні приміщення, коворкінги. Об’єкти поштового зв’язку та розміщення суб’єктів господарювання, що надають послуги з перевезення та доставки (вручення) поштових відправлень. Редакції засобів масової інформації, видавництва друкованих засобів масової інформації та видавничої продукції. Ломбарди, відділення банків, інших провайдерів фінансових послуг</option>
                        <option key={36} className={styles.hoverOptions} value='other'>Інше</option>
                        <option key={37} className={styles.hoverOptions} value='otherProduction'>Розміщення суб’єктів підприємницької діяльності, які здійснюють іншу виробничу діяльність</option>
                        <option key={38} className={styles.hoverOptions} value='parkingCarriageCarRepair'>Стоянки автомобілів. Розміщення транспортних підприємств з перевезення пасажирів і вантажів. Станції технічного обслуговування автомобілів</option>
                        <option key={39} className={styles.hoverOptions} value='pensionFund'>Пенсійний фонд України та його органи</option>
                        <option key={40} className={styles.hoverOptions} value='publicCultural'>Громадські організації (об’єднання) у сфері культури і мистецтв (у тому числі національні творчі спілки або їх члени під творчі майстерні)</option>
                        <option key={41} className={styles.hoverOptions} value='pulpPaperWasteEntp'>Підприємства целюлозно-паперової промисловості, переробки відходів</option>
                        <option key={42} className={styles.hoverOptions} value='religion'>Релігійні організації для забезпечення проведення релігійних обрядів та церемоній</option>
                        <option key={43} className={styles.hoverOptions} value='ritualWcRaw'>Ритуальні послуги. Громадські вбиральні. Збір і сортування вторинної сировини</option>
                        <option key={44} className={styles.hoverOptions} value='rubberPlasticEntp'>Підприємства з виробництва гумових та пластмасових виробів</option>
                        <option key={45} className={styles.hoverOptions} value='science'>Науково-дослідні установи, наукові парки</option>
                        <option key={46} className={styles.hoverOptions} value='socialServicesLaw'>Державні та комунальні спеціалізовані підприємства, установи та заклади соціального обслуговування, що надають соціальні послуги відповідно до Закону України “Про соціальні послуги”</option>
                        <option key={47} className={styles.hoverOptions} value='stateMunicipalCultural'>Державні та комунальні підприємства, установи, організації у сфері культури і мистецтв</option>
                        <option key={48} className={styles.hoverOptions} value='stateMunicipalMassMedia'>Редакції державних і комунальних періодичних видань, державні видавництва, підприємства книгорозповсюдження, вітчизняні видавництва та підприємства книгорозповсюдження, що забезпечують підготовку, випуск та (або) розповсюдження не менш як 50 відсотків книжкової продукції державною мовою (за винятком видань рекламного та еротичного характеру)</option>
                        <option key={49} className={styles.hoverOptions} value='stockArchive'>Склади. Камери схову, архіви</option>
                        <option key={50} className={styles.hoverOptions} value='telecomEntp'>Підприємства зв’язку</option>
                        <option key={51} className={styles.hoverOptions} value='tobaccoAlcoEntp'>Підприємства тютюнової промисловості, лікеро-горілчаної та виноробної промисловості</option>
                        <option key={52} className={styles.hoverOptions} value='tradeEntp'>Підприємства торгівлі</option>
                        <option key={53} className={styles.hoverOptions} value='transportAdditionalEntp'>Підприємства з надання додаткових транспортних послуг та допоміжних операцій</option>
                        <option key={54} className={styles.hoverOptions} value='transportProductionServiceEntp'>Підприємства з виробництва транспортних засобів, устаткування та їх ремонту, виробництва машин та устаткування, призначеного для механічного, термічного оброблення матеріалів або здійснення інших операцій</option>
                        <option key={55} className={styles.hoverOptions} value='veterans'>Громадські організації (об’єднання) ветеранів для розміщення реабілітаційних установ для ветеранів</option>
                        <option key={56} className={styles.hoverOptions} value='woodFurnitureEntp'>Підприємства з виробництва деревини та виробів з деревини, меблів</option>
                    </TextField>
                </div>
            </div>
        )
    }
}

function viewArrIntendedUse(props){
    let tmp = null
    if(props.auctionsMy.leaseRules !== null){
        if(props.auctionsMy.leaseRules.hasOwnProperty('intendedUse') === true){
            if(props.auctionsMy.leaseRules.intendedUse !== null && props.auctionsMy.leaseRules.intendedUse !== 'null'){
                tmp = props.auctionsMy.leaseRules.intendedUse
                return tmp.map(
                    (i, count) => {
                        return (
                            <div>
                                {viewElem(props, i)}
                                <Button
                                    disabled={disabledElement(props.auctionsMy, '01')}
                                    variant="contained"
                                    color="primary"
                                    onClick={
                                        () => {
                                            let tmpS = []
                                            let countS = 0
                                            for(let t = 0; t < props.auctionsMy.leaseRules.intendedUse.length; t++){
                                                if(count !== t){
                                                    tmpS[countS] = props.auctionsMy.leaseRules.intendedUse[t]
                                                    countS++
                                                }
                                            }
                                            props.changeCreateAuctionLeaseRulesIntendedUse(tmpS)
                                        }
                                    }
                                >
                                    Видалити
                                </Button>
                            </div>
                        )
                    }
                )
            }
        }
    }
}
function viewElem(props, data){
    const arrIntendedUse = [
        {value: 'agricultureFoodNoAlcoEntp', name: "Підприємства сільського господарства, харчової промисловості (крім лікеро-горілчаної та виноробної промисловості)"},
        {value: 'atelierBeautyServices', name: "Майстерні, ательє. Салони краси, перукарні. Надання інших побутових послуг населенню"},
        {value: 'authoritiesGovernments', name: "Органи державної влади та органи місцевого самоврядування, інші установи і організації, діяльність яких фінансується за рахунок державного або місцевих бюджетів"},
        {value: 'buildingMaterialEntp', name: "Підприємства з виробництва будівельних матеріалів"},
        {value: 'concertEntertExhibEntp', name: "Підприємства з організації концертно-видовищної та виставкової діяльності"},
        {value: 'consumerServicesEntp', name: "Підприємства побутового обслуговування"},
        {value: 'coursesTrainingsEarly', name: "Центри раннього розвитку дитини. Діяльність у сфері освіти, курси і тренінги"},
        {value: 'deputyOffice', name: "Громадська приймальня народного депутата України або депутата місцевої ради"},
        {value: 'diplomats', name: "Дипломатичні представництва та консульські установи іноземних держав, представництва міжнародних міжурядових організацій в Україні"},
        {value: 'disabilityRehabilitation', name: "Реабілітаційні установи для осіб з інвалідністю та дітей з інвалідністю для розміщення таких реабілітаційних установ"},
        {value: 'education', name: "Заклади освіти"},
        {value: 'electricEquipEntp', name: "Підприємства з виробництва електричного та електронного устаткування"},
        {value: 'electricGasChemicalFerrousEntp', name: "Підприємства електроенергетики, газової, хімічної і нафтохімічної промисловості, чорної металургії"},
        {value: 'eventsElection', name: "Проведення публічних заходів (зборів, дебатів, дискусій) під час та на період виборчої кампанії"},
        {value: 'eventsSienceCulturalPublic', name: "Організація та проведення науково-практичних, культурних, мистецьких, громадських, суспільних та політичних заходів"},
        {value: 'exchangeAtmTelecomAdvertizingPress', name: "Пункти обміну валюти, банкомати, платіжні термінали. Торговельні автомати. Розміщення технічних засобів і антен операторів телекомунікацій, суб’єктів підприємницької діяльності, які надають послуги зв’язку, послуги доступу до Інтернету, телекомунікації, передання сигналу мовлення. Розміщення зовнішньої реклами на будівлях і спорудах. Продаж книг, газет і журналів"},
        {value: 'exhibitions', name: "Проведення виставок"},
        {value: 'ferryRailRoadTranspot', name: "Розміщення морського, залізничного та автомобільного транспорту"},
        {value: 'foodBarRestuarantExcisable', name: "Заклади харчування, кафе, бари, ресторани, які здійснюють продаж товарів підакцизної групи. Торговельні об’єкти, які здійснюють продаж товарів підакцизної групи"},
        {value: 'foodBuffetCafeNotExcisable', name: "Заклади харчування, їдальні, буфети, кафе, які не здійснюють продаж товарів підакцизної групи. Торговельні об’єкти, які не здійснюють продаж товарів підакцизної групи"},
        {value: 'forestryFisheryEntp', name: "Підприємства лісового господарства, рибного господарства"},
        {value: 'fuelEntp', name: "Підприємства паливної промисловості"},
        {value: 'garmentTextileEntp', name: "Підприємства з виробництва швейної та текстильної промисловості"},
        {value: 'gymSport', name: "Тренажерні зали, заклади фізичної культури і спорту, діяльність з організації та проведення занять різними видами спорту"},
        {value: 'healthcarePharmaLaboratory', name: "Заклади охорони здоров’я, клініки, лікарні, приватна медична практика. Аптеки. Ветеринарні лікарні (клініки), лабораторії ветеринарної медицини, ветеринарні аптеки. Медичні лабораторії"},
        {value: 'libraryCinemaTheatre', name: "Бібліотеки. Театри. Кінотеатри, діяльність з кінопоказів"},
        {value: 'lotteryEntp', name: "Підприємства з випуску лотерейних білетів та проведення лотерей"},
        {value: 'metallworkLightNoTextileEntp', name: "Підприємства з металообробки, легкої (крім швейної та текстильної) промисловості"},
        {value: 'museums', name: "Музеї"},
        {value: 'ngo', name: "Громадські об’єднання та благодійні організації"},
        {value: 'nightclubRestuarantSaunaHotelInternetclub', name: "Нічні клуби. Ресторани з нічним режимом роботи (після 22 год). Сауни, лазні. Організація концертів та іншої видовищно-розважальної діяльності. Готелі, хостели, турбази, мотелі, кемпінги, літні будиночки. Комп’ютерні клуби та Інтернет-кафе"},
        {value: 'noRestuarantRestuarantEntp', name: "Підприємства ресторанного господарства (крім ресторанів)"},
        {value: 'nonEnergyMiningEntp', name: "Підприємства з видобування неенергетичних матеріалів"},
        {value: 'nonFerrousOilGasEntp', name: "Підприємства кольорової металургії, нафтогазодобувної промисловості"},
        {value: 'officePostMassmediaBank', name: "Офісні приміщення, коворкінги. Об’єкти поштового зв’язку та розміщення суб’єктів господарювання, що надають послуги з перевезення та доставки (вручення) поштових відправлень. Редакції засобів масової інформації, видавництва друкованих засобів масової інформації та видавничої продукції. Ломбарди, відділення банків, інших провайдерів фінансових послуг"},
        {value: 'other', name: "Інше"},
        {value: 'otherProduction', name: "Розміщення суб’єктів підприємницької діяльності, які здійснюють іншу виробничу діяльність"},
        {value: 'parkingCarriageCarRepair', name: "Стоянки автомобілів. Розміщення транспортних підприємств з перевезення пасажирів і вантажів. Станції технічного обслуговування автомобілів"},
        {value: 'pensionFund', name: "Пенсійний фонд України та його органи"},
        {value: 'publicCultural', name: "Громадські організації (об’єднання) у сфері культури і мистецтв (у тому числі національні творчі спілки або їх члени під творчі майстерні)"},
        {value: 'pulpPaperWasteEntp', name: "Підприємства целюлозно-паперової промисловості, переробки відходів"},
        {value: 'religion', name: "Релігійні організації для забезпечення проведення релігійних обрядів та церемоній"},
        {value: 'ritualWcRaw', name: "Ритуальні послуги. Громадські вбиральні. Збір і сортування вторинної сировини"},
        {value: 'rubberPlasticEntp', name: "Підприємства з виробництва гумових та пластмасових виробів"},
        {value: 'science', name: "Науково-дослідні установи, наукові парки"},
        {value: 'socialServicesLaw', name: "Державні та комунальні спеціалізовані підприємства, установи та заклади соціального обслуговування, що надають соціальні послуги відповідно до Закону України “Про соціальні послуги”"},
        {value: 'stateMunicipalCultural', name: "Державні та комунальні підприємства, установи, організації у сфері культури і мистецтв"},
        {value: 'stateMunicipalMassMedia', name: "Редакції державних і комунальних періодичних видань, державні видавництва, підприємства книгорозповсюдження, вітчизняні видавництва та підприємства книгорозповсюдження, що забезпечують підготовку, випуск та (або) розповсюдження не менш як 50 відсотків книжкової продукції державною мовою (за винятком видань рекламного та еротичного характеру)"},
        {value: 'stockArchive', name: "Склади. Камери схову, архіви"},
        {value: 'telecomEntp', name: "Підприємства зв’язку"},
        {value: 'tobaccoAlcoEntp', name: "Підприємства тютюнової промисловості, лікеро-горілчаної та виноробної промисловості"},
        {value: 'tradeEntp', name: "Підприємства торгівлі"},
        {value: 'transportAdditionalEntp', name: "Підприємства з надання додаткових транспортних послуг та допоміжних операцій"},
        {value: 'transportProductionServiceEntp', name: "Підприємства з виробництва транспортних засобів, устаткування та їх ремонту, виробництва машин та устаткування, призначеного для механічного, термічного оброблення матеріалів або здійснення інших операцій"},
        {value: 'veterans', name: "Громадські організації (об’єднання) ветеранів для розміщення реабілітаційних установ для ветеранів"},
        {value: 'woodFurnitureEntp', name: "Підприємства з виробництва деревини та виробів з деревини, меблів"},
    ]
    return arrIntendedUse
    .filter(
        (i) => {
            return i.value === data
        }
    )
    .map(
        (i) => {
            return (
                <div>{i.name}</div>
            )
        }
    )
}

function viewIntendedUseRestrictionMethod(props){
    let tmp = ''
    if(props.auctionsMy.leaseRules !== null){
        if(props.auctionsMy.leaseRules.hasOwnProperty('intendedUseRestrictionMethod') === true){
            if(props.auctionsMy.leaseRules.intendedUseRestrictionMethod !== null){
                tmp = props.auctionsMy.leaseRules.intendedUseRestrictionMethod
            }
        }
    }else{
        props.setDataAddress({
            intendedUse: 'null',
            intendedUseRestrictionMethod: 'null',
            intendedUseRestrictionDescription: '',
            additionalLeaseConditions: '',
            subleaseAllowed: true,
        }, 'SET_AUCTION_LEASE_RULES')
    }
    if(props.typeElem === 1){
        return (
            <div className={styles.groupElem}>
                <div>
                    <TextField
                        disabled={disabledElement(props.auctionsMy, '01')}
                        className={styles.inputText}
                        id="outlined-select-currency-native"
                        select
                        label="Цільове призначення об'єкта оренди (за наявності)"
                        defaultValue={tmp}
                        onChange={(e)=>{
                            props.changeCreateAuctionLeaseRulesIntendedUseRestrictionMethod(e.target.value)
                        }}
                        variant="outlined"
                    >
                        <option key={0} className={styles.hoverOptions} selected value='null'>Обрати</option>
                        <option key={1} className={styles.hoverOptions} value='exceptDescribed'>Окрім зазначеного</option>
                        <option key={2} className={styles.hoverOptions} value='noRestrictions'>Без обмежень</option>
                        <option key={3} className={styles.hoverOptions} value='onlyDescribed'>Тільки зазначене</option>
                    </TextField>
                </div>
            </div>
        )
    }else{
        return (
            <div className={styles.groupElem}>
                <div>
                    <TextField
                        disabled={disabledElement(props.auctionsMy, '01')}
                        className={styles.inputText}
                        id="outlined-select-currency-native"
                        select
                        label="Цільове призначення об'єкта оренди (за наявності)"
                        defaultValue={tmp}
                        onChange={(e)=>{
                            props.changeCreateAuctionLeaseRulesIntendedUseRestrictionMethod(e.target.value)
                        }}
                        variant="outlined"
                    >
                        <option key={0} className={styles.hoverOptions} selected value='null'>Обрати</option>
                        <option key={1} className={styles.hoverOptions} value='exceptDescribed'>Окрім зазначеного</option>
                        <option key={2} className={styles.hoverOptions} value='noRestrictions'>Без обмежень</option>
                        <option key={3} className={styles.hoverOptions} value='onlyDescribed'>Тільки зазначене</option>
                    </TextField>
                </div>
            </div>
        )
    }
}

function viewIntendedUseRestrictionDescription(props){
    let tmp = ''
    if(props.auctionsMy.leaseRules !== null){
        if(props.auctionsMy.leaseRules.hasOwnProperty('intendedUseRestrictionDescription') === true){
            if(props.auctionsMy.leaseRules.intendedUseRestrictionDescription !== null){
                tmp = props.auctionsMy.leaseRules.intendedUseRestrictionDescription
            }
        }
    }else{
        props.setDataAddress({
            intendedUse: 'null',
            intendedUseRestrictionMethod: 'null',
            intendedUseRestrictionDescription: '',
            additionalLeaseConditions: '',
            subleaseAllowed: true,
        }, 'SET_AUCTION_LEASE_RULES')
    }
    if(props.typeElem === 1){
        return (
            <div className={styles.groupElem}>
                <div>
                    <TextField
                        disabled={disabledElement(props.auctionsMy, '01')}
                        id="filled-required"
                        className={styles.inputText}
                        label={TranslationsUk['legitimatePropertyLease-english'].legitimatePropertyLeaseEnglishProcedure.leaseRules.intendedUseRestrictionDescription.legalName}
                        defaultValue={tmp}
                        variant="outlined"
                        onChange={
                            (e) => {
                                props.changeCreateAuctionLeaseRulesIntendedUseRestrictionDescription(e.target.value)
                            }
                        }
                        helperText=""
                    />
                </div>
            </div>
        )
    }else{
        return (
            <div className={styles.groupElem}>
                <div>
                    <TextField
                        disabled={disabledElement(props.auctionsMy, '01')}
                        id="filled-required"
                        className={styles.inputText}
                        label={TranslationsUk['legitimatePropertyLease-english'].legitimatePropertyLeaseEnglishProcedure.leaseRules.intendedUseRestrictionDescription.legalName}
                        defaultValue={tmp}
                        variant="outlined"
                        onChange={
                            (e) => {
                                props.changeCreateAuctionLeaseRulesIntendedUseRestrictionDescription(e.target.value)
                            }
                        }
                        helperText=""
                    />
                </div>
            </div>
        )
    }
}

function viewAdditionalLeaseConditions(props){
    let tmp = ''
    if(props.auctionsMy.leaseRules !== null){
        if(props.auctionsMy.leaseRules.hasOwnProperty('additionalLeaseConditions') === true){
            if(props.auctionsMy.leaseRules.additionalLeaseConditions !== null){
                tmp = props.auctionsMy.leaseRules.additionalLeaseConditions
            }
        }
    }else{
        props.setDataAddress({
            intendedUse: 'null',
            intendedUseRestrictionMethod: 'null',
            intendedUseRestrictionDescription: '',
            additionalLeaseConditions: '',
            subleaseAllowed: true,
        }, 'SET_AUCTION_LEASE_RULES')
    }
    if(props.typeElem === 1){
        return (
            <div className={styles.groupElem}>
                <div>
                    <TextField
                        disabled={disabledElement(props.auctionsMy, '01')}
                        id="filled-required"
                        className={styles.inputText}
                        label={TranslationsUk['legitimatePropertyLease-english'].legitimatePropertyLeaseEnglishProcedure.leaseRules.additionalLeaseConditions.legalName}
                        defaultValue={tmp}
                        variant="outlined"
                        onChange={
                            (e) => {
                                props.changeCreateAuctionLeaseRulesAdditionalLeaseConditions(e.target.value)
                            }
                        }
                        helperText=""
                    />
                </div>
            </div>
        )
    }else{
        return (
            <div className={styles.groupElem}>
                <div>
                    <TextField
                        disabled={disabledElement(props.auctionsMy, '01')}
                        id="filled-required"
                        className={styles.inputText}
                        label={TranslationsUk['legitimatePropertyLease-english'].legitimatePropertyLeaseEnglishProcedure.leaseRules.additionalLeaseConditions.legalName}
                        defaultValue={tmp}
                        variant="outlined"
                        onChange={
                            (e) => {
                                props.changeCreateAuctionLeaseRulesAdditionalLeaseConditions(e.target.value)
                            }
                        }
                        helperText=""
                    />
                </div>
            </div>
        )
    }
}

function viewSubleaseAllowed(props){
    let tmp = ''
    if(props.auctionsMy.leaseRules !== null){
        if(props.auctionsMy.leaseRules.hasOwnProperty('subleaseAllowed') === true){
            if(props.auctionsMy.leaseRules.subleaseAllowed !== null){
                tmp = props.auctionsMy.leaseRules.subleaseAllowed
            }
        }
    }else{
        props.setDataAddress({
            intendedUse: 'null',
            intendedUseRestrictionMethod: 'null',
            intendedUseRestrictionDescription: '',
            additionalLeaseConditions: '',
            subleaseAllowed: true,
        }, 'SET_AUCTION_LEASE_RULES')
    }
    if(props.typeElem === 1){
        return (
            <div className={styles.groupElem}>
                <div>
                    <TextField
                        disabled={disabledElement(props.auctionsMy, '01')}
                        className={styles.inputText}
                        id="outlined-select-currency-native"
                        select
                        label={TranslationsUk['legitimatePropertyLease-english'].legitimatePropertyLeaseEnglishProcedure.leaseRules.subleaseAllowed.legalName}
                        defaultValue={tmp}
                        onChange={(e)=>{
                            props.changeCreateAuctionLeaseRulesSubleaseAllowed(e.target.value)
                        }}
                        variant="outlined"
                    >
                        <option key={0} className={styles.hoverOptions} selected value={true}>Так</option>
                        <option key={1} className={styles.hoverOptions} value={false}>Ні</option>
                    </TextField>
                </div>
            </div>
        )
    }else{
        return (
            <div className={styles.groupElem}>
                <div>
                    <TextField
                        disabled={disabledElement(props.auctionsMy, '01')}
                        className={styles.inputText}
                        id="outlined-select-currency-native"
                        select
                        label={TranslationsUk['legitimatePropertyLease-english'].legitimatePropertyLeaseEnglishProcedure.leaseRules.subleaseAllowed.legalName}
                        defaultValue={tmp}
                        onChange={(e)=>{
                            props.changeCreateAuctionLeaseRulesSubleaseAllowed(e.target.value)
                        }}
                        variant="outlined"
                    >
                        <option key={0} className={styles.hoverOptions} selected value={true}>Так</option>
                        <option key={1} className={styles.hoverOptions} value={false}>Ні</option>
                    </TextField>
                </div>
            </div>
        )
    }
}

function setOption(props){
    if(props.addressNew.getAddress !== null){
        return props.addressNew.getAddress.map(
            (e, count) => {
                //return <option key={count+1} className={styles.hoverOptions} value={e.id}>{e.name}</option>
                return <option key={count+1} className={styles.hoverOptions} value={e.id}>{e.contactPoint.name}</option>
            }
        )
    }
}

async function createObject(props, objId){
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/bridge/${objId}`
    const response = await fetch(endPoint, {
        method: "POST",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){
            //let tmp = JSON.stringify({title: "Помилка при створенні Тип переліку, до якого віднесено об'єкт", message: JSON.stringify(json)})
            //props.setUniversalError(tmp)
        }else{
            getAucionFromId(props, json.id)
        }
    }
}

async function getAucionFromId(props, aucId){
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/auction/${aucId}`;
    const response = await fetch(endPoint, {
        method: "GET",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){
            let tmp = JSON.stringify({title: 'Помилка при завантаженні аукціону', message: JSON.stringify(json)})
	        props.setUniversalError(tmp)
        }else{
            props.setEditAuctionFromId(json)
            if(json.previousAuctionId !== null){
                props.changeDiscountPreviousAuctionId(json.previousAuctionId)
                if(json.discount !== null){
                    props.changeDiscountPreviousAuctionAmount(json.discount.previousAuctionValue.amount)
                    props.changeDiscountPercent(json.discount.discountPercent)
                }
            }
        }
    }
}

async function getObjectId(props, objId){
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/bridge/${objId}/raw`
    const response = await fetch(endPoint, {
        method: "POST",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){
            //let tmp = JSON.stringify({title: "Помилка при створенні Тип переліку, до якого віднесено об'єкт", message: JSON.stringify(json)})
            //props.setUniversalError(tmp)
        }else{
            if(json.hasOwnProperty('leaseRules') === true){
                if(json.leaseRules !== null){
                    if(json.leaseRules.hasOwnProperty('intendedUseRestrictionMethod') === true){
                        if(json.leaseRules.intendedUseRestrictionMethod !== null){
                            props.changeCreateAuctionLeaseRulesIntendedUseRestrictionMethod(json.leaseRules.intendedUseRestrictionMethod)
                        }
                    }
                    if(json.leaseRules.hasOwnProperty('subleaseAllowed') === true){
                        if(json.leaseRules.subleaseAllowed !== null){
                            props.changeCreateAuctionLeaseRulesSubleaseAllowed(json.leaseRules.subleaseAllowed)
                        }
                    }
                    if(json.leaseRules.hasOwnProperty('schedule') === true){
                        if(json.leaseRules.schedule !== null){
                            if(json.leaseRules.schedule.hasOwnProperty('daysBy') === true){
                                if(json.leaseRules.schedule.daysBy !== null){
                                    if(json.leaseRules.schedule.daysBy.hasOwnProperty('by') === true){
                                        if(json.leaseRules.schedule.daysBy.by !== null){
                                            props.setDataAddress(json.leaseRules.schedule.daysBy.by, 'SET_SCHEDULE_DAYS_BY_BY')
                                        }
                                    }
                                    if(json.leaseRules.schedule.daysBy.hasOwnProperty('value') === true){
                                        if(json.leaseRules.schedule.daysBy.value !== null){
                                            props.setDataAddress(json.leaseRules.schedule.daysBy.value, 'SET_SCHEDULE_DAYS_BY_VALUE')
                                        }
                                    }
                                }
                            }
                            if(json.leaseRules.schedule.hasOwnProperty('hoursBy') === true){
                                if(json.leaseRules.schedule.hoursBy !== null){
                                    if(json.leaseRules.schedule.hoursBy.hasOwnProperty('by') === true){
                                        if(json.leaseRules.schedule.hoursBy.by !== null){
                                            props.setDataAddress(json.leaseRules.schedule.hoursBy.by, 'SET_SCHEDULE_HOURS_BY_BY')
                                        }
                                    }
                                    if(json.leaseRules.schedule.hoursBy.hasOwnProperty('value') === true){
                                        if(json.leaseRules.schedule.hoursBy.value !== null){
                                            props.setDataAddress(json.leaseRules.schedule.hoursBy.value, 'SET_SCHEDULE_HOURS_BY_VALUE')
                                        }
                                    }
                                }
                            }
                            if(json.leaseRules.schedule.hasOwnProperty('period') === true){
                
                            }
                        }
                    }
                }
            }
            if(json.hasOwnProperty('relatedOrganizations') === true){
                if(json.relatedOrganizations !== null){
                    if(json.relatedOrganizations.hasOwnProperty('ownershipType') === true){
                        if(json.relatedOrganizations.ownershipType !== null){
                            props.setDataAddress(json.relatedOrganizations.ownershipType, 'SET_AUCTION_OWNERSHIP_TYPE')
                        }
                    }
                }
            }
            props.setDataAddress(json, 'SET_OBJECT_REESTR')
        }
    }
}

function blockedInput(props){
    if(props.auctionsMy.status !== 'active_rectification'){
        return 'disabled'
    }
    return null
}

function blockedInputSpec(props){
    if(
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-auction-manual-qualification" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification-prod"
    ){
        if(props.auctionsMy.status !== null && props.auctionsMy.status !== 'active_rectification'){
            return 'disabled'
        }
    }
    return null
}

const mapStateToProps = state => {
    return {
        token: state.start.token,
        auctionsMy: state.start.auctionsMy,
        dictionaries: state.start.dictionaries,
        addressNew: state.start.addressNew,
        schedule: state.start.schedule,
        objectReestr: state.start.objectReestr,
        shedullePeriod: state.start.shedullePeriod,
        koatuu: state.start.koatuu,
        auctionsZUAttemp: state.start.auctionsZUAttemp
    }
}

const mapDispatchToProps = {
    changeCreateAuctionMinNumberOfQualifiedBids,
    changeCreateAuctionDgfVirtualDataRoom,
    changeCreateAuctionDgfEligibilityCriteria,
    changeCreateAuctionDgfX_dgfPlatformLegalDetails,
    changeCreateAuctionDgfDecisionId,
    changeCreateAuctionDgfDecisionDate,
    changeCreateAuctionСalcOnBookValue,
    changeCreateAuctionValueAddedTaxCharged,
    changeCreateAuctionRegistryId,
    changeCreateAuctionValuePeriod,
    changeCreateAuctionLeaseRulesIntendedUse,
    changeCreateAuctionLeaseRulesIntendedUseRestrictionMethod,
    changeCreateAuctionLeaseRulesIntendedUseRestrictionDescription,
    changeCreateAuctionLeaseRulesAdditionalLeaseConditions,
    changeCreateAuctionLeaseRulesSubleaseAllowed,
    setDataAddress,

    setEditAuctionFromId,
    changeDiscountPreviousAuctionId,
    changeDiscountPreviousAuctionAmount,
    changeDiscountPercent,

    getMyAuction,
    changeLoader,
}

export default connect(mapStateToProps, mapDispatchToProps)(WindowAuctionDgf)
