import {getCurrency} from "../redux/check";

export function viewStringValue(data) {
    if (data !== null) {
        return startCostAuction(data.amount) + ' ' + getCurrency(data.currency) + ' ' + taxStatusAuction(data);
    }
}

function taxStatusAuction(data) {
    let tmp = ''
    if (data.hasOwnProperty('valueAddedTaxIncluded') === true) {
        if (data.valueAddedTaxIncluded !== null) {
            if (data.valueAddedTaxIncluded) {
                tmp += ', з ПДВ'
            } else {
                tmp += ', без ПДВ'
            }
        }
    }
    return tmp
}

function startCostAuction(data) {
    let tmp = null
    let amount = null
    if (data !== null) {
        amount = String(data);
        if (amount !== null) {
            if (amount.indexOf('.') !== -1) {
                amount = amount.split('.');
                // console.log(amount)
                tmp = String(amount[0]);
                tmp = tmp.split("").reverse().join("");
                let tmp1 = [];
                for (let i = 0; i < tmp.length; i++) {
                    if (((i + 1) % 3) === 0) {
                        if (i !== tmp.length - 1) {
                            tmp1[i] = tmp[i] + '\'';
                        } else {
                            tmp1[i] = tmp[i];
                        }
                    } else {
                        tmp1[i] = tmp[i];
                    }
                }
                tmp = tmp1.join('')
                tmp = tmp.split("").reverse().join("");
                if (amount[1].indexOf("0") === -1) {
                    amount = Number(amount[1]);
                    if (amount < 10) amount = amount + "0"
                } else {
                    amount = amount[1]
                }
            } else {
                tmp = String(amount);
                tmp = tmp.split("").reverse().join("");
                let tmp1 = [];
                for (let i = 0; i < tmp.length; i++) {
                    if (((i + 1) % 3) === 0) {
                        if (i !== tmp.length - 1) {
                            tmp1[i] = tmp[i] + '\'';
                        } else {
                            tmp1[i] = tmp[i];
                        }
                    } else {
                        tmp1[i] = tmp[i];
                    }
                }
                tmp = tmp1.join('')
                tmp = tmp.split("").reverse().join("");
                amount = '00'
            }
        }
    }
    return tmp + ',' + amount
}