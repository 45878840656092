import React from 'react';
import styles from './createAuction.module.css';

import { Link } from 'react-router-dom';

import {connect} from 'react-redux';
import {TranslationsUk} from '../../redux/translations.uk.js';

import CreateAuctionZU from './createAuctionZU'

////////////////////////
import {
    auctionsGetMy,
    changeAuctionsSwitchWindow,
    auctionsGetClassificationsByType,
    auctionsGetClassificationsByTimberSortment,
    auctionsGetClassificationsByTimberSortmentFromCashe,
    auctionsGetClassificationsByTimberSpecies,
    auctionsGetClassificationsByTimberSpeciesFromCashe,
    auctionsGetClassificationsByTimberClass,
    auctionsGetClassificationsByTimberClassFromCashe,
    auctionsGetClassificationsByTimberDiameter,
    auctionsGetClassificationsByTimberDiameterFromCashe,
    auctionsGetClassificationsByTimberLength,
    auctionsGetClassificationsByTimberLengthFromCashe,
    auctionsGetClassificationsByTimberStorage,
    auctionsGetClassificationsByTimberStorageFromCashe,
    auctionsGetClassificationsByTimberProductionYear,
    auctionsGetClassificationsByTimberProductionYearFromCashe,
    auctionsGetClassificationsByTimberProductionQuarter,
    auctionsGetClassificationsByTimberProductionQuarterFromCashe,
    auctionsGetClassificationsByWagonSpecies,
    auctionsGetClassificationsByWagonSpeciesFromCashe,
    auctionsGetClassificationsByTypeNadraEnglish,
    auctionsGetClassificationsByTypeNadraEnglishFromCashe,
    auctionsGetClassificationsByByShemaSubsoilUsageType,
    auctionsGetClassificationsByTypeRailway,
    auctionsGetClassificationsByCarryType,
    auctionsGetClassificationsByTypeRailwayCargoAdd,
    auctionsGetWagonRestrict,
    auctionsGetWagonRestrictType,
} from '../../redux/actions/auctions/auctions.js';

import {
    setDataAddress,
} from '../../redux/createAddress';


import {
    changeCreateItemClassificationAddId,
    getMyAuction
} from '../../redux/actions.js';

import {bankAccountGet, bankAccountShemeGet, setBankAccountSave, setBankAccountAdd, setBankAccountDelete} from '../../redux/actions/bankAccount/bankAccount.js';

import {auctionsBidsGetMy} from  '../../redux/actions/auctionBid/auctionBid.js';

import {unitsGetByType, unitsGetByTypeEnergy, unitsGetByTypeRailway, unitsGetByTypeSubsoil, unitsGetByTypeZU} from '../../redux/actions/itemUnits/itemUnits.js';

import {addressesGetMy} from '../../redux/actions/address/address.js';

import {
    workMyPageCurrentAuction,
    workMyPageNextAuction
} from '../../redux/togetherApi.js';

////////////////////////

import {changeCreateAuctionTab} from '../../redux/actions.js';
import {setPopUpSendAuction, setPopUpErrorAuction} from '../../redux/actions/auctions/auctions.js';
import {classificationsGetByScheme} from '../../redux/actions/classifications/classifications.js';
import {
    changeCreateAuctionTitle,
    changeCreateAuctionDescription,
    changeCreateAuctionType,
    changeCreateAuctionTenderAttempts,
    changeCreateAuctionAccessDetails,
    changeCreateAuctionXDocumentRequirements,
    changeCreateAuctionXAdditionalInformation,
    changeCreateAuctionValueCurrency,
    changeCreateAuctionValueAmount,
    changeCreateAuctionValueTax,
    changeCreateAuctionGuaranteeCurrency,
    changeCreateAuctionGuaranteeAmount,
    changeCreateAuctionGuaranteeTax,
    changeCreateAuctionMinimalStepCurrency,
    changeCreateAuctionMinimalStepAmount,
    changeCreateAuctionMinimalStepTax,
    changeCreateAuctionDateStart,
    changeCreateAuctionDateEnd,
    changeCreateAuctionId,
    changePageNumber,
    setEditAuctionFromId,
    changeLoader,
} from '../../redux/actions.js';

import {
    changeDiscountPercent,
    changeDiscountPreviousAuctionCurrency,
    changeDiscountPreviousAuctionAmount,
    changeDiscountPreviousAuctionId,
    discountAuc,
    changeDiscountId,
} from '../../redux/actions/discount/discount.js';

import {setUniversalError} from '../../redux/actions.js';

import {auctionsGetAwards, auctionsGetAwardsId} from '../../redux/actions/awards/awards.js';
import {getAuctionsBidsId} from '../../redux/actions/auctionBid/auctionBid.js';
import {contractsAuctionIdGet} from '../../redux/actions/contracts/contracts.js';
import {setPopBankAccountId, setPopBankAccountIdArr} from '../../redux/actions/bankAccount/bankAccount.js';
import {oldArrDocumentGet} from '../../redux/actions/documents/documents.js';

import TabMenu from './tabMenu/tabMenu.js';
import TabMenuBid from './tabMenuBid/tabMenu.js';
import WindowAuction from './windowAuction/windowAuction.js';
import WindowBid from './windowBid/windowBid.js';
import WindowDocument from './windowDocument/windowDocument.js';
import WindowAwards from './windowAwards/windowAwards.js';
import WindowContract from './windowContract/windowContract.js';
import WindowAnswer from './windowAnswer/windowAnswer';
import PagingButton from './paging'

//import Button from '@material-ui/core/Button';
//import DeleteIcon from '@material-ui/icons/Delete';
//import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/PlayArrow';
import CopyIcon from '@material-ui/icons/Pages';
import LinkIcon from '@material-ui/icons/Link';
import IconButton from '@material-ui/core/IconButton';
//import Icon from '@material-ui/core/Icon';

import {Redirect} from 'react-router-dom';

//import {AUCTIONS_GET_SEND} from '../../redux/types.js';
import {getAPI} from '../../redux/actions/crud.js';

import CreateAuctionLoader from './createAuctionLoader.js';
import {setLogo} from '../../redux/actions/support.js';
import { isLLE, isLLD, isLLP, isBRD, isBRE, isALE, isBSD, isBSE, isCSD, isCSE, isLRE, isLSE, isLSP, isAAE } from '../../redux/procedure';

import {
    setPopUpAuctionMyNull,
} from '../../redux/actions/auctions/auctions.js';

import buttonsCancelAndDeleteAuction from './buttonsCancelAndDeleteAuction.js';
import ButtonCreateAuction from './buttonsNewAuction.js';
import ButtonEditAuction from './buttonsEditAuction.js';

import ListAuctions from './listAuction/actions'
import MyAuctionsHeader from './listAuction/myAuctionsHeader/myAuctionsHeader'
import ListMyBids from './listMyBids/myBids'
import WindowItems from "../_CRUD/item/windowItems";

const Product = ( props ) => {
    if(props.token !== null && props.userProfiles !== null){
        if(window.location.href.indexOf('/create_auctions/new') !== -1){
            if(props.auctionsSwitchWindow !== 2){
                createButton(props)
            }
        }
        return (
            <div className={styles.auctions__wrap}>
                {switchViewAuctionsMy(props)}
            </div>
        )
    }
    return null
}

async function createButton(props){
    props.changeAuctionsSwitchWindow(2)
    props.getMyAuction(props.auctionsMyNew)
    props.changeCreateAuctionTenderAttempts(1)
    props.setBankAccountSave([])
    props.setBankAccountAdd([])
    props.setBankAccountDelete([])
}

///////////////////////////////////////////
//Новый аук
async function concatDataClassification(props){
    let tmpArr0 = await getDictionaryJointPropertyComplex(props, '06121000-6')
    let tmpArr1 = await tmpArr0.concat(await getDictionaryJointPropertyComplex(props, '06111000-3'))
    let tmpArr2 = await tmpArr1.concat(await getDictionaryJointPropertyComplex(props, '06112000-0'))
    let tmpArr3 = await tmpArr2.concat(await getDictionaryJointPropertyComplex(props, '06122000-3'))
    let tmpArr4 = await tmpArr3.concat(await getDictionaryJointPropertyComplex(props, '06123000-0'))
    let tmpArr5 = await tmpArr4.concat(await getDictionaryJointPropertyComplex(props, '06124000-7'))
    let tmpArr6 = await tmpArr5.concat(await getDictionaryJointPropertyComplex(props, '06125000-4'))
    let tmpArr7 = await tmpArr6.concat(await getDictionaryJointPropertyComplex(props, '06126000-1'))
    let tmpArr8 = await tmpArr7.concat(await getDictionaryJointPropertyComplex(props, '06127000-8'))
    let tmpArr9 = await tmpArr8.concat(await getDictionaryJointPropertyComplex(props, '06128000-5'))
    let tmpArr10 = await tmpArr9.concat(await getDictionaryJointPropertyComplex(props, '06129000-2'))
    props.setDataAddress(tmpArr10, 'SET_CLASSIFICATIONS_BY_TYPE_DGF_ADD')
}

async function getDictionaryJointPropertyComplex(props, mask){
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/classifications/autocomplete/classification_id?limit=1000&offset=0&classification_id=${mask}&scheme=CAV&mask=${mask}`;
    const response = await fetch(endPoint, {
        method: "GET",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        return await response.json()
    }
}

async function getAdditionalClassificationZU(props){
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/public/dictionaries/kvtspz?limit=1000`;
    const response = await fetch(endPoint, {
        method: "GET",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json = await response.json()
        await props.setDataAddress(json, 'CREATE_ITEMS_LAND_RENTAL_ADDITIONAL_CLASSIFICATIONS_ARR')
    }
}
///////////////////////////////////////////


function switchViewAuctionsMy(props){
    return windowCreateEditMyAuction(props, false)
}

//myBids
function combineButtons(props){
    let tmpCreateAuction = null
    let tmpCreateAuctionZU = null
    if(props.userProfiles !== null){
        if(props.userProfiles.canCreateAuction === true) tmpCreateAuction = <ButtonCreateAuction />
        if(props.userProfiles.canCreateLeaseAuction === true) tmpCreateAuctionZU = <CreateAuctionZU />
    }
    return(
        <div>
            {tmpCreateAuction}
            {tmpCreateAuctionZU}
        </div>
    )
}

function checkDocuments(i){
    let tmpTechnicalSpecifications = 'Технічна специфікація'
    let tmpContractProforma = ' Проект договору'

    let tmpX_auctionDocumentationPackage = ' Пакет аукціонної документації'
    let tmpX_submissionBidProcedure = ' Порядок подання заявок для участі в аукціоні'
    let tmpX_nonSanctionedRequirements = ' Вимоги до заявників (санкції)'
    let tmpX_brokerPaymentConditions = ' Порядок та строки сплати винагороди оператору'
    let tmpX_qualificationSigningTerms = ' Строки підписання протоколу проведення аукціону та договорів купівлі-продажу'
    let tmpEvaluationCriteria = ' Перелік даних, які надаються заявниками'

    let tmpContractProformaZU = ' Проект договору оренди'
    let tmpIllustrationZU = ' Фотографічне зображення майна'

    if (i.sellingMethod.startsWith("timber-english")) {
        if(i.documents !== null){
            for(let tr = 0; tr < i.documents.length; tr++){
                if(i.documents[tr].documentType === "technicalSpecifications") tmpTechnicalSpecifications = ''
                if(i.documents[tr].documentType === "contractProforma") tmpContractProforma = ''
            }
        }
        return (
            <>
                <div>{tmpTechnicalSpecifications}</div>
                <div>{tmpContractProforma}</div>
            </>
        )
    } else if (i.sellingMethod.startsWith("railwayCargo-english")) {
        tmpContractProforma = ''
        tmpTechnicalSpecifications = ''
        return (
            <>
                <div>{tmpTechnicalSpecifications}</div>
                <div>{tmpContractProforma}</div>
            </>
        )
    }else if(
        i.sellingMethod.startsWith("subsoil-english") ||
        i.sellingMethod.startsWith("subsoil-dutch")
    ){
        if(i.documents !== null){
            for(let tr = 0; tr < i.documents.length; tr++){
                if(i.documents[tr].documentType === "x_auctionDocumentationPackage") tmpX_auctionDocumentationPackage = ''
                if(i.documents[tr].documentType === "x_submissionBidProcedure") tmpX_submissionBidProcedure = ''
                if(i.documents[tr].documentType === "x_nonSanctionedRequirements") tmpX_nonSanctionedRequirements = ''
                if(i.documents[tr].documentType === "x_brokerPaymentConditions") tmpX_brokerPaymentConditions = ''
                if(i.documents[tr].documentType === "x_qualificationSigningTerms") tmpX_qualificationSigningTerms = ''
                if(i.documents[tr].documentType === "evaluationCriteria") tmpEvaluationCriteria = ''
            }
        }
        return (
            <>
                <div>{tmpX_auctionDocumentationPackage}</div>
                <div>{tmpX_submissionBidProcedure}</div>
                <div>{tmpX_nonSanctionedRequirements}</div>
                <div>{tmpX_brokerPaymentConditions}</div>
                <div>{tmpX_qualificationSigningTerms}</div>
                <div>{tmpEvaluationCriteria}</div>
            </>
        )
    }else if(
        i.sellingMethod.startsWith("legitimatePropertyLease-english") ||
        i.sellingMethod.startsWith("legitimatePropertyLease-dutch") ||
        i.sellingMethod.startsWith("legitimatePropertyLease-priorityEnglish")
    ){
        if(i.documents !== null){
            for(let tr = 0; tr < i.documents.length; tr++){
                if(i.documents[tr].documentType === "contractProforma") tmpContractProformaZU = ''
                if(i.documents[tr].documentType === "illustration") tmpIllustrationZU = ''
            }
        }
        return(
            <>
                <div>{tmpContractProformaZU}</div>
                <div>{tmpIllustrationZU}</div>
            </>
        )
    }else{
        if(i.documents !== null){
            for(let tr = 0; tr < i.documents.length; tr++){
                if(i.documents[tr].documentType === "technicalSpecifications") tmpTechnicalSpecifications = ''
            }
        }
        return <div>{tmpTechnicalSpecifications}</div>
    }
}
function checkItems(i){
    let tmp = null
    if(i.items !== null){
        if(i.items.length > 0){
            return null
        }
    }
    return 'Лот аукціону'
}

function viewTextStatusUkr(status){
    switch (status) {
        case status === 'active_rectification':
            return 'Редагування доступне'
        case status === 'active_tendering':
            return 'Прийняття заяв на участь'
        case status === 'active_auction':
            return 'Аукціон'
        case status === 'active_qualification':
            return 'Очікується опублікування протоколу'
        case status === 'active_awarded':
            return 'Очікується підписання договору'
        case status === 'pending_payment':
            return 'Очікується сплата до бюджету'
        case status === 'pending_admission':
            return 'Аукціон завершено. Очікується виконання умов договору'
            return 'Очікується оплата'
        case status === 'cancelled':
            return 'Аукціон скасовано'
        case status === 'unsuccessful':
            return 'Аукціон не відбувся'
        case status === 'complete':
            return 'Аукціон завершено'
        case status === null:
            return 'Чернетка'
        default:
            return status
    }
}

function viewcompleteAuction(props, i){
    let tmp = <div className={styles.disabledButton}>Завершення аукціону</div>
    let tmpSellingMethod = null
    if(i.sellingMethod !== null){
        tmpSellingMethod = i.sellingMethod.indexOf('railwayCargo')
    }
    if( tmpSellingMethod === -1){
        if(i.hasOwnProperty('contracts') === true){
            if(i.contracts.length > 0){
                if(i.contracts[0].status === 'active'){
                    tmp = <div className={styles.enabledButton}
                        onClick={
                            () => {
                                completeAuction(props, i.id)
                            }
                        }
                    >
                        Завершення аукціону
                    </div>
                }
            }
        }
    }else{
        if(i.status === 'active_qualification'){
        //if(i.hasOwnProperty('awards') === true){
            //if(i.awards.length > 0){
                //if(i.awards[0].status === 'active'){
                    tmp = <div className={styles.enabledButton}
                        onClick={
                            () => {
                                completeAuction(props, i.id)
                            }
                        }
                    >
                        Завершення аукціону
                    </div>
                //}
            //}
        //}
        }
    }
    if(i.status === "unsuccessful" || i.status === "cancelled" || i.status === "complete") tmp = <div className={styles.disabledButton}>Завершення аукціону</div>

    let tmpContractDoc = false
    let tmpContractStatus = false
    /*if(i.contracts !== null){
        if(i.contracts.length > 0){
            for(let t = 0; t < i.contracts.length; t++){
                if(i.contracts[t].status === 'active'){
                    tmpContractStatus = true
                }
            }
        }
    }*/
    return tmp
}

function listsMyAuctionsBids(props){
    let tmp = null;
    if(props.myBids !== null){
        if (props.myBids.length > 0) {
            return <ListMyBids />
        }
    }else{
        tmp = <CreateAuctionLoader />
    }
    return <div>{tmp}</div>
}



function buttonPublishAuction(props, i){
    let tmp = <div title="Публікація аукціону">
        <IconButton
            disabled='disabled'
            aria-label="add"
        >
            <AddIcon />
        </IconButton>
    </div>
    let tmpOnOff = false;
    if(i.documents !== null){
        if(i.documents.length > 0){
            for(let tpi = 0; tpi < i.documents.length; tpi++){
                if(i.documents[tpi].documentType === 'technicalSpecifications'){
                    tmpOnOff = true
                }
            }
        }
    }
    if(i.status === null){
        if(i.documents !== null){
            if(i.documents.length > 0 && tmpOnOff === true){
                tmp = <div
                    onClick={()=>{sendAuction(props, i.id)}}
                    title="Публікація аукціону"
                >
                    <IconButton aria-label="add">
                        <AddIcon />
                    </IconButton>
                </div>
            }else{
                switch(true){
                    case i.sellingMethod.startsWith("railwayCargo-english"):
                    case i.sellingMethod.startsWith("railwayCargo-dutch"):
                        tmp = <div
                                onClick={()=>{sendAuction(props, i.id)}}
                                title="Публікація аукціону"
                            >
                                <IconButton aria-label="add">
                                    <AddIcon />
                                </IconButton>
                            </div>
                        break;
                    default:
                        break;
                }
            }
        }else{
            switch(true){
                case i.sellingMethod.startsWith("railwayCargo-english"):
                case i.sellingMethod.startsWith("railwayCargo-dutch"):
                    tmp = <div
                            onClick={()=>{sendAuction(props, i.id)}}
                            title="Публікація аукціону"
                        >
                            <IconButton aria-label="add">
                                <AddIcon />
                            </IconButton>
                        </div>
                    break;
                default:
                    break;
            }
        }
    }
    return tmp
}

/////////////////////////////////
function checkDocumentsPublish(i){
    let tmpTechnicalSpecifications = false
    let tmpContractProforma = false
    let tmpX_auctionDocumentationPackage = false
    let tmpX_submissionBidProcedure = false
    let tmpX_nonSanctionedRequirements = false
    let tmpX_brokerPaymentConditions = false
    let tmpX_qualificationSigningTerms = false
    let tmpEvaluationCriteria = false
    if(
        i.sellingMethod.startsWith("timber-english")
    ){
        if(i.documents !== null){
            for(let tr = 0; tr < i.documents.length; tr++){
                if(i.documents[tr].documentType === "technicalSpecifications") tmpTechnicalSpecifications = true
                if(i.documents[tr].documentType === "contractProforma") tmpContractProforma = true
            }
        }
    }else if(
        i.sellingMethod.startsWith("subsoil-english") ||
        i.sellingMethod.startsWith("subsoil-dutch")
    ){
        if(i.documents !== null){
            for(let tr = 0; tr < i.documents.length; tr++){
                if(i.documents[tr].documentType === "x_auctionDocumentationPackage") tmpX_auctionDocumentationPackage = true
                if(i.documents[tr].documentType === "x_submissionBidProcedure") tmpX_submissionBidProcedure = true
                if(i.documents[tr].documentType === "x_nonSanctionedRequirements") tmpX_nonSanctionedRequirements = true
                if(i.documents[tr].documentType === "x_brokerPaymentConditions") tmpX_brokerPaymentConditions = true
                if(i.documents[tr].documentType === "x_qualificationSigningTerms") tmpX_qualificationSigningTerms = true
                if(i.documents[tr].documentType === "evaluationCriteria") tmpEvaluationCriteria = true
            }
        }
        if(
            tmpX_auctionDocumentationPackage === true &&
            tmpX_submissionBidProcedure === true &&
            tmpX_nonSanctionedRequirements === true &&
            tmpX_brokerPaymentConditions === true &&
            tmpX_qualificationSigningTerms === true &&
            tmpEvaluationCriteria === true
        ){
            return true
        }else{
            return false
        }
    }else if(
        i.sellingMethod.startsWith("legitimatePropertyLease-english") ||
        i.sellingMethod.startsWith("legitimatePropertyLease-dutch") ||
        i.sellingMethod.startsWith("legitimatePropertyLease-priorityEnglish")
    ){
        let tmpContractProformaZU = false
        let tmpIllustrationZU = false
        if(i.documents !== null){
            for(let tr = 0; tr < i.documents.length; tr++){
                if(i.documents[tr].documentType === "contractProforma") tmpContractProformaZU = true
                if(i.documents[tr].documentType === "illustration") tmpIllustrationZU = true
            }
        }
        if(
            tmpContractProformaZU === true &&
            tmpIllustrationZU === true
        ){
            return true
        }else{
            return false
        }
    }


	return tmpTechnicalSpecifications === true && tmpContractProforma === true;
}

function checkItemsPublish(i){
    if(i.items !== null){
        if(i.items.length > 0){
            return true
        }
    }
    return false
}

function buttonPublishAuctionNew(props, i){
    let tmp = <div title="Публікація аукціону">
        <IconButton
            disabled='disabled'
            aria-label="add"
        >
            <AddIcon />
        </IconButton>
    </div>
    if(i.status === null){
        switch(true){
            case i.sellingMethod.startsWith("railwayCargo-english"):
            case i.sellingMethod.startsWith("railwayCargo-dutch"):
                if(checkItemsPublish(i) === true){
                    tmp = <div
                            onClick={()=>{sendAuction(props, i.id)}}
                            title="Публікація аукціону"
                        >
                            <IconButton aria-label="add">
                                <AddIcon />
                            </IconButton>
                        </div>
                    }
                break;
            case i.sellingMethod.startsWith("timber-english"):
                if(checkItemsPublish(i) === true && checkDocumentsPublish(i) === true){
                    tmp = <div
                            onClick={()=>{sendAuction(props, i.id)}}
                            title="Публікація аукціону"
                        >
                            <IconButton aria-label="add">
                                <AddIcon />
                            </IconButton>
                        </div>
                    }
                break;
            case i.sellingMethod.startsWith("subsoil-english"):
            case i.sellingMethod.startsWith("subsoil-dutch"):
                if(checkItemsPublish(i) === true && checkDocumentsPublish(i) === true){
                    tmp = <div
                            onClick={()=>{sendAuction(props, i.id)}}
                            title="Публікація аукціону"
                        >
                            <IconButton aria-label="add">
                                <AddIcon />
                            </IconButton>
                        </div>
                    }
                break;
            case i.sellingMethod.startsWith("legitimatePropertyLease-english"):
            case i.sellingMethod.startsWith("legitimatePropertyLease-dutch"):
            case i.sellingMethod.startsWith("legitimatePropertyLease-priorityEnglish"):
                if(checkItemsPublish(i) === true && checkDocumentsPublish(i) === true){
                    tmp = <div
                            onClick={()=>{patchZURegistry(props, i.id, i.registryId)}}
                            title="Публікація аукціону"
                        >
                            <IconButton aria-label="add">
                                <AddIcon />
                            </IconButton>
                        </div>
                    }
                break;
            default:
                if(checkItemsPublish(i) === true){
                    tmp = <div
                            onClick={()=>{sendAuction(props, i.id)}}
                            title="Публікація аукціону"
                        >
                            <IconButton aria-label="add">
                                <AddIcon />
                            </IconButton>
                        </div>
                }
                break;
        }
    }
    return tmp
}

function windowCreateEditMyAuction(props, type){
    let tmpTab = [];
    if(type === true){
        tmpTab[0] = <TabMenu count={0} state={true}/>
        tmpTab[1] = <TabMenu count={2} state={true}/>
    }else{
        if(props.auctionsSwitchWindow === 1){
            for(let i = 0; i < 3; i++){
                if(props.auctionsMySingl !== null){
                    if(i === 5){
                        if(
                            props.auctionsMySingl.sellingMethod.startsWith("railwayCargo-english") ||
                            props.auctionsMySingl.sellingMethod.startsWith("railwayCargo-dutch")
                        ){
                            continue
                        }
                    }
                }
                tmpTab[i] = <TabMenu count={i} state={true}/>
            }
        }else if(props.auctionsSwitchWindow === 2){
            for(let i = 0; i < 3; i++){
                if(props.auctionsMySingl !== null){
                    if(i === 5){
                        if(
                            props.auctionsMySingl.sellingMethod.startsWith("railwayCargo-english") ||
                            props.auctionsMySingl.sellingMethod.startsWith("railwayCargo-dutch")
                        ){
                            continue
                        }
                    }
                }
                tmpTab[i] = <TabMenu count={i} state={false}/>
            }
        }
    }
    if(
        props.auctionsMySingl !== null && (
            isLRE(props.auctionsMySingl.sellingMethod) || isLSE(props.auctionsMySingl.sellingMethod) || isLSP(props.auctionsMySingl.sellingMethod) ||
            isLLE(props.auctionsMySingl.sellingMethod) || isLLD(props.auctionsMySingl.sellingMethod) || isLLP(props.auctionsMySingl.sellingMethod) ||
            isBSE(props.auctionsMySingl.sellingMethod) || isBSD(props.auctionsMySingl.sellingMethod) ||
            isALE(props.auctionsMySingl.sellingMethod) ||
            isCSE(props.auctionsMySingl.sellingMethod) || isCSD(props.auctionsMySingl.sellingMethod) ||
            isBRE(props.auctionsMySingl.sellingMethod) || isBRD(props.auctionsMySingl.sellingMethod)
        )
    ){
        return (viewWindowChoiseTab(props))
    }else{
        return (
            <>
                <div className={styles.tab__wrap}>
                    {tmpTab}
                </div>
                {viewWindowChoiseTab(props)}
            </>
        )
    }
}

function windowCreateEditMyAuctionBid(props){
    let tmpTab = [];
    for(let i = 0; i < 3; i++){
        tmpTab[i] = <TabMenuBid count={i} />
    }
    return (
        <>
            <div className={styles.tab__wrap}>
                {tmpTab}
            </div>
            {viewWindowChoiseTabBid(props)}
        </>
    )
}

function viewWindowChoiseTab(props){
    let tmp = null;
    switch(props.createAuctionTab){
        case 0:
            tmp = <WindowAuction />
            break;
        case 1:
            tmp = <WindowItems />
            break;
        case 2:
            tmp = <WindowDocument />
            break;
        case 3:
            tmp = <WindowAnswer />
            //tmp = ''
            break;
        case 4:
            tmp = <WindowAwards />
            //tmp = ''
            break;
        case 5:
            tmp = <WindowContract />
            //tmp = ''
            break
        default:
            tmp = ''
            break;
    }
    return tmp
}

function viewWindowChoiseTabBid(props){
    let tmp = null;
    switch(props.createAuctionTab){
        case 0:
            tmp = <WindowBid />
            break;
        case 1:
            tmp = <WindowAwards />
            break;
        case 2:
            tmp = <WindowContract />
            break;
        default:
            tmp = ''
            break;
    }
    return tmp
}

async function completeAuction(props, id){
    props.changeLoader(true)
    const url = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction/${id}/complete`;
    const response1 = await fetch(url, {
        method: "POST",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response1 === undefined || response1 === 'undefined'){
        props.changeLoader(false)
    }else{
        const json1 = await response1.json()
        if(json1.hasOwnProperty('message') === true){
            let tmp = JSON.stringify({title: 'Помилка при завершенні аукціону', message: JSON.stringify(json1)})
	        props.setUniversalError(tmp)
        }else{
            const url2 = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${id}/sync`;
            const response2 = await fetch(url2, {
                method: "GET",
                headers: {
                    'Authorization': props.token.access_token,
                    'Content-Type': 'application/json'
                }
            }).catch(console.error)
            if(response2 === undefined || response2 === 'undefined'){
                const json2 = await response2.json()
                props.setPopUpAuctionMyNull()
                let data = {}
                data.domen = process.env.REACT_APP_END_POINT_BD
                data.version = 'v1'
                data.local = props.langFlag
                if(props.myCabinetPage === 0){
                    data.params = `?offset=${props.myCabinetPage}`
                }else{
                    data.params = `?offset=${props.myCabinetPage}0` 
                }
                let header = {
                    'Authorization': props.token.access_token,
                    'Content-Type': 'application/json'
                }
                props.auctionsGetMy(data, header)
            }else{
                const json2 = await response2.json()
                props.setPopUpAuctionMyNull()
                let data = {}
                data.domen = process.env.REACT_APP_END_POINT_BD
                data.version = 'v1'
                data.local = props.langFlag
                if(props.myCabinetPage === 0){
                    data.params = `?offset=${props.myCabinetPage}`
                }else{
                    data.params = `?offset=${props.myCabinetPage}0` 
                }
                let header = {
                    'Authorization': props.token.access_token,
                    'Content-Type': 'application/json'
                }
                props.auctionsGetMy(data, header)
            }
        }
        props.changeLoader(false)
    }
}

async function patchZURegistry(props, idAuc, idZu){
    props.changeLoader(true)
    const url = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${idAuc}/bridge/${idZu}`
    const response1 = await fetch(url, {
        method: "PATCH",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    })
    .catch(console.error)
    if(response1 === undefined || response1 === 'undefined'){
        props.changeLoader(false)
    }else{
        const json1 = await response1.json()
        if(json1.hasOwnProperty('message') === true){
            props.changeLoader(false)
            let tmp = JSON.stringify({title: 'Помилка при оновленні данних через брідж', message: JSON.stringify(json1)})
	        props.setUniversalError(tmp)
        }else{
            await sendAuction(props, idAuc)
        }
    }
}

async function sendAuction(props, id){
    props.changeLoader(true)
    const url = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${id}/send`;
    const response1 = await fetch(url, {
        method: "GET",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    })
    .catch(console.error)
    if(response1 === undefined || response1 === 'undefined'){
        props.changeLoader(false)
    }else{
        const json1 = await response1.json()
        if(json1.hasOwnProperty('message') === true){
            let tmp = JSON.stringify({title: 'Помилка при активації аукціону', message: JSON.stringify(json1)})
            props.changeLoader(false)
	        props.setUniversalError(tmp)
        }else{
            const url2 = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${id}/sync`;
            const response2 = await fetch(url2, {
                method: "GET",
                headers: {
                    'Authorization': props.token.access_token,
                    'Content-Type': 'application/json'
                }
            }).catch(console.error)
            if(response2 === undefined || response2 === 'undefined'){
                props.setPopUpAuctionMyNull()
                let data = {}
                data.domen = process.env.REACT_APP_END_POINT_BD
                data.version = 'v1'
                data.local = props.langFlag
                if(props.myCabinetPage === 0){
                    data.params = `?offset=${props.myCabinetPage}`
                }else{
                    data.params = `?offset=${props.myCabinetPage}0` 
                }
                let header = {
                    'Authorization': props.token.access_token,
                    'Content-Type': 'application/json'
                }
                props.auctionsGetMy(data, header)
                props.changeLoader(false)
            }else{
                props.setPopUpAuctionMyNull()
                let data = {}
                data.domen = process.env.REACT_APP_END_POINT_BD
                data.version = 'v1'
                data.local = props.langFlag
                if(props.myCabinetPage === 0){
                    data.params = `?offset=${props.myCabinetPage}`
                }else{
                    data.params = `?offset=${props.myCabinetPage}0` 
                }
                let header = {
                    'Authorization': props.token.access_token,
                    'Content-Type': 'application/json'
                }
                props.auctionsGetMy(data, header)
                props.changeLoader(false)
            }
        }
    }
}

////////////////////////////
async function getAucionFromId(props, aucId, type, tenderAttempts=null){
    props.changeLoader(true)
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/auction/${aucId}`;
    const response = await fetch(endPoint, {
        method: "GET",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){
            let tmp = JSON.stringify({title: 'Помилка при завантаженні аукціону', message: JSON.stringify(json)})
	        props.setUniversalError(tmp)
        }else{
            props.setEditAuctionFromId(json)
            if(tenderAttempts === '2'){
                props.setDataAddress(tenderAttempts, 'CREATE_AUCTION_TENDER_ATTEMPTS')
            }else if(tenderAttempts === '3'){
                props.setDataAddress(tenderAttempts, 'CREATE_AUCTION_TENDER_ATTEMPTS')
                if(Number(process.env.REACT_APP_BUILD_STATUS) === 0){
                    props.setDataAddress("legitimatePropertyLease-dutch", 'CREATE_AUCTION_TYPE')
                }else{
                    props.setDataAddress("legitimatePropertyLease-dutch-fast-manual", 'CREATE_AUCTION_TYPE')
                }
            }
            /*if(json.value !== null){
                props.changeDiscountPreviousAuctionAmount(json.value.amount)
            }*/
            await getAddressOrg(props, json, type)
        }
    }
}

async function getAddressOrg(props, auction, type){
    //props.changeCreateAuctionValueTax(true)
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/organizations?limit=30&offset=0`
    const response = await fetch(endPoint, {
        method: "GET",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){
            let tmp = JSON.stringify({title: 'Помилка при завантаженні аукціону', message: JSON.stringify(json)})
	        props.setUniversalError(tmp)
        }else{
            let tmpArr = json
            if(auction.relatedOrganizations !== null && auction.relatedOrganizations !== 'null'){
                if(auction.relatedOrganizations.currentTenant !== null){
                    let flag = false
                    for(let i = 0; i < tmpArr.length; i++){
                        if(tmpArr[i].id === auction.relatedOrganizations.currentTenant.id){
                            flag = true
                        }
                    }
                    if(flag === false){
                        let tmpObj = auction.relatedOrganizations.currentTenant
                        tmpObj.name = tmpObj.contactPoint.name 
                        tmpArr.push(tmpObj)
                        //props.setDataAddress(tmpArr, 'GET_ADDRESS_NEW_BOOKS')
                    }else{
                        //props.setDataAddress(json, 'GET_ADDRESS_NEW_BOOKS')
                    }
                }else{
                    //props.setDataAddress(json, 'GET_ADDRESS_NEW_BOOKS')
                }
                if(auction.relatedOrganizations.sellingEntity !== null){
                    let flag = false
                    for(let i = 0; i < tmpArr.length; i++){
                        if(tmpArr[i].id === auction.relatedOrganizations.sellingEntity.id){
                            flag = true
                        }
                    }
                    if(flag === false){
                        let tmpObj = auction.relatedOrganizations.sellingEntity
                        tmpObj.name = tmpObj.contactPoint.name 
                        tmpArr.push(tmpObj)
                        //props.setDataAddress(tmpArr, 'GET_ADDRESS_NEW_BOOKS')
                    }else{
                        //props.setDataAddress(json, 'GET_ADDRESS_NEW_BOOKS')
                    }
                }else{
                    //props.setDataAddress(json, 'GET_ADDRESS_NEW_BOOKS')
                }
                if(auction.relatedOrganizations.propertyOwner !== null){
                    let flag = false
                    for(let i = 0; i < tmpArr.length; i++){
                        if(tmpArr[i].id === auction.relatedOrganizations.propertyOwner.id){
                            flag = true
                        }
                    }
                    if(flag === false){
                        let tmpObj = auction.relatedOrganizations.propertyOwner
                        tmpObj.name = tmpObj.contactPoint.name 
                        tmpArr.push(tmpObj)
                        //props.setDataAddress(tmpArr, 'GET_ADDRESS_NEW_BOOKS')
                    }else{
                        //props.setDataAddress(json, 'GET_ADDRESS_NEW_BOOKS')
                    }
                }else{
                    //props.setDataAddress(json, 'GET_ADDRESS_NEW_BOOKS')
                }
            }
            props.setDataAddress(tmpArr, 'GET_ADDRESS_NEW_BOOKS')
        }
    }
    props.changeAuctionsSwitchWindow(type)
    props.changeLoader(false)
    props.setDataAddress(true, 'SET_BRIDGE_OPEN_BUTTON')
}

async function getCopyAuc(props, id){
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/auction/${id}`
    const response = await fetch(endPoint, {
        method: "GET",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){
            let tmp = JSON.stringify({title: 'Помилка при завантаженні аукціону', message: JSON.stringify(json)})
	        props.setUniversalError(tmp)
        }else{
            props.setDataAddress(json, 'CREATE_AUCTIONS_ZU_ATTEMP')
        }
    }
}

const mapStateToProps = state => {
    return {
        token: state.start.token,
        auctions: state.start.auctionsGet,
        auctionsMy: state.start.auctionsGetMy,
        auctionsMySingl: state.start.auctionsMy,
        langFlag: state.start.langFlag,
        createAuctionTab: state.start.createAuctionTab,
        auctionsSwitchWindow: state.start.auctionsSwitchWindow,
        auctionsMyNew: state.start.auctionsMyNew,
        userProfiles: state.start.userProfiles,
        myBids: state.start.myBids,
        myCabinetPage: state.start.myCabinetPage,
        cloneObjectId: state.start.cloneObjectId,
    }
}

const mapDispatchToProps = {
    changeCreateAuctionTab,

    changeCreateAuctionTitle,
    changeCreateAuctionDescription,
    changeCreateAuctionType,
    changeCreateAuctionTenderAttempts,
    changeCreateAuctionAccessDetails,
    changeCreateAuctionXDocumentRequirements,
    changeCreateAuctionXAdditionalInformation,
    changeCreateAuctionValueCurrency,
    changeCreateAuctionValueAmount,
    changeCreateAuctionValueTax,
    changeCreateAuctionGuaranteeCurrency,
    changeCreateAuctionGuaranteeAmount,
    changeCreateAuctionGuaranteeTax,
    changeCreateAuctionMinimalStepCurrency,
    changeCreateAuctionMinimalStepAmount,
    changeCreateAuctionMinimalStepTax,
    changeCreateAuctionDateStart,
    changeCreateAuctionDateEnd,
    changeCreateAuctionId,

    classificationsGetByScheme,
    getAPI,
    setPopUpSendAuction,

    auctionsGetAwards,
    auctionsGetAwardsId,
    getAuctionsBidsId,
    changePageNumber,

    contractsAuctionIdGet,
    setPopUpErrorAuction,
    setPopBankAccountId,
    setPopBankAccountIdArr,

    oldArrDocumentGet,

    changeDiscountPercent,
    changeDiscountPreviousAuctionCurrency,
    changeDiscountPreviousAuctionAmount,
    changeDiscountPreviousAuctionId,
    discountAuc,
    changeDiscountId,

    setLogo,

    setPopUpAuctionMyNull,

    setUniversalError,
    setEditAuctionFromId,

    changeLoader,

    //////////////////////////
    auctionsGetMy,
    changeAuctionsSwitchWindow,
    auctionsGetClassificationsByType,
    auctionsGetClassificationsByTimberSortment,
    auctionsGetClassificationsByTimberSortmentFromCashe,
    auctionsGetClassificationsByTimberSpecies,
    auctionsGetClassificationsByTimberSpeciesFromCashe,
    auctionsGetClassificationsByTimberClass,
    auctionsGetClassificationsByTimberClassFromCashe,
    auctionsGetClassificationsByTimberDiameter,
    auctionsGetClassificationsByTimberDiameterFromCashe,
    auctionsGetClassificationsByTimberLength,
    auctionsGetClassificationsByTimberLengthFromCashe,
    auctionsGetClassificationsByTimberStorage,
    auctionsGetClassificationsByTimberStorageFromCashe,
    auctionsGetClassificationsByTimberProductionYear,
    auctionsGetClassificationsByTimberProductionYearFromCashe,
    auctionsGetClassificationsByTimberProductionQuarter,
    auctionsGetClassificationsByTimberProductionQuarterFromCashe,
    auctionsGetClassificationsByWagonSpecies,
    auctionsGetClassificationsByWagonSpeciesFromCashe,
    auctionsGetClassificationsByTypeNadraEnglish,
    auctionsGetClassificationsByTypeNadraEnglishFromCashe,
    auctionsGetClassificationsByByShemaSubsoilUsageType,
    auctionsGetClassificationsByTypeRailway,
    auctionsGetClassificationsByCarryType,
    auctionsGetClassificationsByTypeRailwayCargoAdd,
    auctionsGetWagonRestrict,
    auctionsGetWagonRestrictType,
    changeCreateItemClassificationAddId,
    getMyAuction,
	bankAccountGet,
	bankAccountShemeGet,
	auctionsBidsGetMy,
	unitsGetByType,
	unitsGetByTypeEnergy,
	unitsGetByTypeRailway,
	unitsGetByTypeSubsoil,
    unitsGetByTypeZU,
    addressesGetMy,
    
    workMyPageCurrentAuction,
    workMyPageNextAuction,

    setBankAccountSave,
    setBankAccountAdd,
    setBankAccountDelete,
    setDataAddress,
}

export default connect(mapStateToProps, mapDispatchToProps)(Product)
