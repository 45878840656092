import React, { useEffect } from 'react';
import styles from './windowAuction.module.css';

import {connect} from 'react-redux';

import {
    changeCreateAuctionValueCurrency,
    changeCreateAuctionValueAmount,
    changeCreateAuctionValueTax,
    changeCreateAuctionGuaranteeAmount,
    changeCreateAuctionMinimalStepAmount,
    changeCreateAuctionRegistrationFeeAmount
} from '../../../redux/actions.js';

import { isSUE, isSUD, isLLE, isLLD, isLLP, isBSE, isBSD, isALE, isCSD,
    isCSE, isLRE, isLSE, isLSP, isBRE, isBRD } from '../../../redux/procedure';

import {
    changeDiscountPercent,
} from '../../../redux/actions/discount/discount.js';

import TextField from '@material-ui/core/TextField';
import {TranslationsUk} from '../../../redux/translations.uk.js';

import InputAmount from '../../elements/inputs/inputAmount';

import {disabledElement} from '../disabled'

const WindowAuctionCost = ( props ) => {
    let tmp = null;
    let tmpValue = 'null'
    if(props.auctionsMy !== null){
        if(props.auctionsMy.value !== null){
            if(props.auctionsMy.value.currency !== null){
                tmpValue = props.auctionsMy.value.currency
            }
        }
    }
    if(props.typeElem === 1){
        tmp = <div className={styles.group}>
                {summZU(props)}
        </div>
    }else{
        tmp = <div className={styles.group}>
            <div className={styles.titleValue}>{viewNameInput(props)}</div>
            <div className={styles.groupElem}>
                <div className={styles.valuta}>
                    <div>{TranslationsUk['legitimatePropertyLease-english'].legitimatePropertyLeaseEnglishProcedure.value.currency.legalName}</div>
                    <TextField
                        className={styles.valutaInput}
                        disabled
                        id="outlined-select-currency-native"
                        select
                        value={tmpValue}
                        SelectProps={{
                            native: true,
                        }}
                        helperText="Тип валюти"
                        variant="outlined"
                    >
                        {viewOptionCurrentCost(props, 1)}
                    </TextField>
                </div>
                {summZU(props)}
                {taxElement(props, 0)}
            </div>
        </div>
    }
    return tmp
}

function viewNameInput(props){
    if(
        isLLE(props.auctionsMy.sellingMethod) || 
        isLLD(props.auctionsMy.sellingMethod) || 
        isLLP(props.auctionsMy.sellingMethod)
    ){
        return TranslationsUk['legitimatePropertyLease-english'].legitimatePropertyLeaseEnglishProcedure.value.legalName
    }else{
        return 'Стартова ціна лота' + requiredText(props)
    }
}

function requiredText(props){
    if(
        isLRE(props.auctionsMy.sellingMethod) ||
        isLSE(props.auctionsMy.sellingMethod) ||
        isLSP(props.auctionsMy.sellingMethod)
    ){
        return ' *'
    }
    return ''
}

function taxElement(props, status){
    let tmp = null;
    let tmpTax = 'true'
    if(props.auctionsMy !== null){
        if(props.auctionsMy.value !== null){
            if(props.auctionsMy.value.valueAddedTaxIncluded !== null){
                if(props.auctionsMy.value.valueAddedTaxIncluded === true || props.auctionsMy.value.valueAddedTaxIncluded === 'true'){
                    tmpTax = 'true'
                }else{
                    tmpTax = 'false'
                }
            }
        }
    }
    switch(props.auctionsMy.sellingMethod){
        case "renewables":
        case "renewables-fast":
        case "renewables-fast-manual":
        case "renewables-initial-auction":
        case "renewables-initial-qualification":
        case "renewables-initial-qualification-fast":
            tmp = null
            break;
        case "timber-english":
        case "timber-english-fast":
        case "timber-english-fast-manual":
        case "timber-english-initial-auction":
        case "timber-english-initial-qualification":
        case "timber-english-initial-qualification-fast":
        case "dgf-english":
        case "dgf-english-fast":
        case "dgf-english-fast-manual":
        case "dgf-english-fast-auction-manual-qualification":
        case "dgf-english-initial-auction":
        case "dgf-english-initial-auction-manual":
        case "dgf-english-initial-qualification":
        case "legitimatePropertyLease-english":
        case "legitimatePropertyLease-english-fast":
        case "legitimatePropertyLease-english-fast-manual":
        case "legitimatePropertyLease-english-fast-auction-manual-qualification":
        case "legitimatePropertyLease-english-initial-auction":
        case "legitimatePropertyLease-english-initial-auction-manual":
        case "legitimatePropertyLease-english-initial-qualification":
        case "legitimatePropertyLease-english-initial-qualification-prod":
        case "legitimatePropertyLease-dutch":
        case "legitimatePropertyLease-dutch-fast":
        case "legitimatePropertyLease-dutch-fast-manual":
        case "legitimatePropertyLease-dutch-fast-auction-manual-qualification":
        case "legitimatePropertyLease-dutch-initial-auction":
        case "legitimatePropertyLease-dutch-initial-auction-manual":
        case "legitimatePropertyLease-dutch-initial-qualification-prod":
        case "legitimatePropertyLease-priorityEnglish":
        case "legitimatePropertyLease-priorityEnglish-fast":
        case "legitimatePropertyLease-priorityEnglish-fast-manual":
        case "legitimatePropertyLease-priorityEnglish-fast-auction-manual-qualification":
        case "legitimatePropertyLease-priorityEnglish-initial-auction":
        case "legitimatePropertyLease-priorityEnglish-initial-auction-manual":
        case "legitimatePropertyLease-priorityEnglish-initial-qualification":
        case "legitimatePropertyLease-priorityEnglish-initial-qualification-prod":
        case 'landRental-english':
        case 'landRental-english-fast':
        case 'landRental-english-fast-manual':
        case 'landRental-english-fast-auction-manual-qualification':
        case 'landRental-english-initial-auction':
        case 'landRental-english-initial-auction-manual':
        case 'landRental-english-initial-qualification':
        case 'landRental-english-initial-qualification-prod':
        case 'landSell-english':
        case 'landSell-english-fast':
        case 'landSell-english-fast-manual':
        case 'landSell-english-fast-auction-manual-qualification':
        case 'landSell-english-initial-auction':
        case 'landSell-english-initial-auction-manual':
        case 'landSell-english-initial-qualification':
        case 'landSell-english-initial-qualification-prod':
        case 'landSell-priorityEnglish':
        case 'landSell-priorityEnglish-fast':
        case 'landSell-priorityEnglish-fast-manual':
        case 'landSell-priorityEnglish-fast-auction-manual-qualification':
        case 'landSell-priorityEnglish-initial-auction':
        case 'landSell-priorityEnglish-initial-auction-manual':
        case 'landSell-priorityEnglish-initial-qualification':
        case 'landSell-priorityEnglish-initial-qualification-prod':
            if(status === 1){
                tmp = <div>
                    <div>{TranslationsUk['legitimatePropertyLease-english'].legitimatePropertyLeaseEnglishProcedure.value.valueAddedTaxIncluded.legalName}</div>
                    <TextField
                        error = {redBlock(props, tmpTax)}
                        disabled={disabledElement(props.auctionsMy, '00')}
                        id="outlined-select-currency-native"
                        select
                        value={tmpTax}
                        onChange={(event)=>{
                            props.changeCreateAuctionValueTax(event.target.value)
                        }}
                        SelectProps={{
                            native: true,
                        }}
                        helperText="Оберіть включати ПДВ чи ні"
                        variant="outlined"
                    >
                        <option key={0} selected disabled value='null'>Обрати</option>
                        <option key={1} value='true'>з ПДВ</option>
                        <option key={2} value='false'>без ПДВ</option>
                    </TextField>
                </div>
            }
            else{
                tmp = <div>
                    <div>{TranslationsUk['legitimatePropertyLease-english'].legitimatePropertyLeaseEnglishProcedure.value.valueAddedTaxIncluded.legalName}</div>
                    <TextField
                        error = {redBlock(props, tmpTax)}
                        disabled={disabledElement(props.auctionsMy, '00')}
                        id="outlined-select-currency-native"
                        select
                        value={tmpTax}
                        onChange={(event)=>{
                            props.changeCreateAuctionValueTax(event.target.value)
                        }}
                        SelectProps={{
                            native: true,
                        }}
                        helperText="Оберіть включати ПДВ чи ні"
                        variant="outlined"
                    >
                        <option key={0} selected disabled value='null'>Обрати</option>
                        <option key={1} value='true'>з ПДВ</option>
                        <option key={2} value='false'>без ПДВ</option>
                    </TextField>
                </div>
            }
            break;
        case 'basicSell-english':
        case 'basicSell-english-fast':
        case 'basicSell-english-fast-manual':
        case 'basicSell-english-fast-auction-manual-qualification':
        case 'basicSell-english-initial-auction':
        case 'basicSell-english-initial-auction-manual':
        case 'basicSell-english-initial-qualification':
        case 'basicSell-english-initial-qualification-prod':
        case 'basicSell-dutch':
        case 'basicSell-dutch-fast':
        case 'basicSell-dutch-fast-manual':
        case 'basicSell-dutch-fast-auction-manual-qualification':
        case 'basicSell-dutch-initial-auction':
        case 'basicSell-dutch-initial-auction-manual':
        case 'basicSell-dutch-initial-qualification':
        case 'basicSell-dutch-initial-qualification-prod':
        case 'alienation-english':
        case 'alienation-english-fast':
        case 'alienation-english-fast-manual':
        case 'alienation-english-fast-auction-manual-qualification':
        case 'alienation-english-initial-auction':
        case 'alienation-english-initial-auction-manual':
        case 'alienation-english-initial-qualification':
        case 'alienation-english-initial-qualification-prod':
        case 'commercialSell-english':
        case 'commercialSell-english-fast':
        case 'commercialSell-english-fast-manual':
        case 'commercialSell-english-fast-auction-manual-qualification':
        case 'commercialSell-english-initial-auction':
        case 'commercialSell-english-initial-auction-manual':
        case 'commercialSell-english-initial-qualification':
        case 'commercialSell-english-initial-qualification-prod':
        case 'commercialSell-dutch':
        case 'commercialSell-dutch-fast':
        case 'commercialSell-dutch-fast-manual':
        case 'commercialSell-dutch-fast-auction-manual-qualification':
        case 'commercialSell-dutch-initial-auction':
        case 'commercialSell-dutch-initial-auction-manual':
        case 'commercialSell-dutch-initial-qualification':
        case 'commercialSell-dutch-initial-qualification-prod':
        case 'bankRuptcy-english':
        case 'bankRuptcy-english-fast':
        case 'bankRuptcy-english-fast-manual':
        case 'bankRuptcy-english-fast-auction-manual-qualification':
        case 'bankRuptcy-english-initial-auction':
        case 'bankRuptcy-english-initial-auction-manual':
        case 'bankRuptcy-english-initial-qualification':
        case 'bankRuptcy-english-initial-qualification-prod':
        case 'bankRuptcy-dutch':
        case 'bankRuptcy-dutch-fast':
        case 'bankRuptcy-dutch-fast-manual':
        case 'bankRuptcy-dutch-fast-auction-manual-qualification':
        case 'bankRuptcy-dutch-initial-auction':
        case 'bankRuptcy-dutch-initial-auction-manual':
        case 'bankRuptcy-dutch-initial-qualification':
        case 'bankRuptcy-dutch-initial-qualification-prod':
            if(tmpTax === 'null' || tmpTax === null) props.changeCreateAuctionValueTax(true)
            if(status === 1){
                tmp = <div>
                    <div>{TranslationsUk['legitimatePropertyLease-english'].legitimatePropertyLeaseEnglishProcedure.value.valueAddedTaxIncluded.legalName}</div>
                    <TextField
                        error = {redBlock(props, tmpTax)}
                        disabled={disabledElement(props.auctionsMy, '00')}
                        id="outlined-select-currency-native"
                        select
                        value={tmpTax}
                        onChange={(event)=>{
                            props.changeCreateAuctionValueTax(event.target.value)
                        }}
                        SelectProps={{
                            native: true,
                        }}
                        helperText="Оберіть включати ПДВ чи ні"
                        variant="outlined"
                    >
                        <option key={0} selected disabled value='null'>Обрати</option>
                        <option key={1} value='true'>з ПДВ</option>
                        <option key={2} value='false'>без ПДВ</option>
                    </TextField>
                </div>
            }
            else{
                tmp = <div>
                    <div>{TranslationsUk['legitimatePropertyLease-english'].legitimatePropertyLeaseEnglishProcedure.value.valueAddedTaxIncluded.legalName}</div>
                    <TextField
                        error = {redBlock(props, tmpTax)}
                        disabled={disabledElement(props.auctionsMy, '00')}
                        id="outlined-select-currency-native"
                        select
                        value={tmpTax}
                        onChange={(event)=>{
                            props.changeCreateAuctionValueTax(event.target.value)
                        }}
                        SelectProps={{
                            native: true,
                        }}
                        helperText="Оберіть включати ПДВ чи ні"
                        variant="outlined"
                    >
                        <option key={0} selected disabled value='null'>Обрати</option>
                        <option key={1} value='true'>з ПДВ</option>
                        <option key={2} value='false'>без ПДВ</option>
                    </TextField>
                </div>
            }
            break;
        case "subsoil-english":
        case "subsoil-english-fast":
        case "subsoil-english-fast-manual":
        case "subsoil-english-initial-auction":
        case "subsoil-english-initial-qualification":
            tmp = null
            break;
        case "railwayCargo-english":
        case "railwayCargo-english-fast":
        case "railwayCargo-english-fast-manual":
        case "railwayCargo-english-initial-auction":
        case "railwayCargo-english-initial-qualification":
        case "railwayCargo-dutch":
        case "railwayCargo-dutch-fast":
        case "railwayCargo-dutch-fast-manual":
        case "railwayCargo-dutch-initial-auction":
        case "railwayCargo-dutch-initial-qualification":
        case "railwayCargo-dutch-fast-auction-manual-qualification":
        case "railwayCargo-dutch-initial-auction-manual":
            tmp = null
            break;
        default:
            tmp = null
            break;
    }
    return tmp
}

function viewOptionCurrentCost(props, position){
    let tmp = null;
    switch(props.auctionsMy.sellingMethod){
        case "renewables":
        case "renewables-fast":
        case "renewables-fast-manual":
        case "renewables-initial-auction":
        case "renewables-initial-qualification":
        case "renewables-initial-qualification-fast":
            if(position === 1){
                tmp = <>
                    <option key={4} value="eurocent">eurocent</option>
                </>
            }else if(position === 2){
                tmp = <>
                    <option key={3} value="EUR">EUR</option>
                </>
            }
            break;
        case "timber-english":
        case "timber-english-fast":
        case "timber-english-fast-manual":
        case "timber-english-initial-auction":
        case "timber-english-initial-qualification":
        case "timber-english-initial-qualification-fast":
        case "subsoil-english":
        case "subsoil-english-fast":
        case "subsoil-english-fast-manual":
        case "subsoil-english-initial-auction":
        case "subsoil-english-initial-qualification":
        case "railwayCargo-english":
        case "railwayCargo-english-fast":
        case "railwayCargo-english-fast-manual":
        case "railwayCargo-english-initial-auction":
        case "railwayCargo-english-initial-qualification":
        case "dgf-english":
        case "dgf-english-fast":
        case "dgf-english-fast-manual":
        case "dgf-english-fast-auction-manual-qualification":
        case "dgf-english-initial-auction":
        case "dgf-english-initial-auction-manual":
        case "dgf-english-initial-qualification":
        case "legitimatePropertyLease-english":
        case "legitimatePropertyLease-english-fast":
        case "legitimatePropertyLease-english-fast-manual":
        case "legitimatePropertyLease-english-fast-auction-manual-qualification":
        case "legitimatePropertyLease-english-initial-auction":
        case "legitimatePropertyLease-english-initial-auction-manual":
        case "legitimatePropertyLease-english-initial-qualification":
        case "legitimatePropertyLease-english-initial-qualification-prod":
        case "legitimatePropertyLease-dutch":
        case "legitimatePropertyLease-dutch-fast":
        case "legitimatePropertyLease-dutch-fast-manual":
        case "legitimatePropertyLease-dutch-fast-auction-manual-qualification":
        case "legitimatePropertyLease-dutch-initial-auction":
        case "legitimatePropertyLease-dutch-initial-auction-manual":
        case "legitimatePropertyLease-dutch-initial-qualification-prod":
        case "legitimatePropertyLease-priorityEnglish":
        case "legitimatePropertyLease-priorityEnglish-fast":
        case "legitimatePropertyLease-priorityEnglish-fast-manual":
        case "legitimatePropertyLease-priorityEnglish-fast-auction-manual-qualification":
        case "legitimatePropertyLease-priorityEnglish-initial-auction":
        case "legitimatePropertyLease-priorityEnglish-initial-auction-manual":
        case "legitimatePropertyLease-priorityEnglish-initial-qualification":
        case "legitimatePropertyLease-priorityEnglish-initial-qualification-prod":
            tmp = <>
                <option key={1} value="UAH">ГРН</option>
                {/*<option key={2} value="USD">USD</option>
                <option key={3} value="EUR">EUR</option>*/}
            </>
            break;
        default:
            tmp = <>
                <option key={1} value="UAH">ГРН</option>
                {/*<option key={2} value="USD">USD</option>
                <option key={3} value="EUR">EUR</option>*/}
            </>
            break;
    }
    return tmp
}

function summZU(props){
    let tmpAmount = ''
    let tmpTax = true
    let flag = true
    if(props.auctionsMy !== null){
        if(props.auctionsMy.value !== null){
            if(props.auctionsMy.value.amount !== null && props.auctionsMy.value.amount !== ''){
                tmpAmount = props.auctionsMy.value.amount
            }else{
                tmpAmount = 0.01
            }
            if(props.auctionsMy.value.valueAddedTaxIncluded !== null){
                if(props.auctionsMy.value.valueAddedTaxIncluded === true || props.auctionsMy.value.valueAddedTaxIncluded === 'true'){
                    tmpTax = true
                }else{
                    tmpTax = false
                }
            }
        }else{
            tmpAmount = 0.01
            tmpTax = true
        }
    }
    if(
        isLLE(props.auctionsMy.sellingMethod) ||
        isLLD(props.auctionsMy.sellingMethod) ||
        isLLP(props.auctionsMy.sellingMethod)
    ){
        if(props.auctionsZUAttemp !== null){
            if(props.auctionsZUAttemp.tenderAttempts === 1 && props.auctionsMy.tenderAttempts === '2'){
                //let tmp = tmpAmount / 2
                return (
                        <InputAmount
                    title={viewNameInput(props)}
                    lable={TranslationsUk['legitimatePropertyLease-english'].legitimatePropertyLeaseEnglishProcedure.value.amount.legalName}
                    data={
                        {amount: tmpAmount,
                        currency: 'UAH',
                        tax: tmpTax,
                    }
                    }
                    onChange={(event) => {
                        let reg=/[a-zA-Zа-яА-Яа-яіІїЇєЄ]/;
                        // if(reg.test(event.amount) !== true) {
                            props.changeCreateAuctionValueAmount(convertStrToNumber(event.amount))
                        // }
                        if( 
                            isLLE(props.auctionsMy.sellingMethod) || 
                            isLLD(props.auctionsMy.sellingMethod) || 
                            isLLP(props.auctionsMy.sellingMethod)
                        ){
                            if(reg.test(event.amount) !== true) {
                                if(props.discount !== null){
                                    if(props.discount.previousAuctionValue.amount !== null && props.discount.previousAuctionValue.amount !== ''){
                                        let tmp = null
                                        tmp = 100 - (convertStrToNumber(event.amount) * 100 / props.discount.previousAuctionValue.amount)
                                        props.changeDiscountPercent(tmp.toFixed(1))
                                    }
                                }
                                let tmpMinStep = (event.amount / 100) * 1
                                props.changeCreateAuctionMinimalStepAmount(tmpMinStep.toFixed(2))
                            }
                        }else{
                            let tmpGaranty = (event.amount / 100) * 5
                            props.changeCreateAuctionGuaranteeAmount(tmpGaranty.toFixed(2))
                            let tmpMinStep = (event.amount / 100) * 1
                            props.changeCreateAuctionMinimalStepAmount(tmpMinStep.toFixed(2))
                            if( isBSE(props.auctionsMy.sellingMethod) || isBSD(props.auctionsMy.sellingMethod) ||
                                isALE(props.auctionsMy.sellingMethod) ||
                                isCSE(props.auctionsMy.sellingMethod) || isCSD(props.auctionsMy.sellingMethod) ||
                                isBRE(props.auctionsMy.sellingMethod) || isBRD(props.auctionsMy.sellingMethod)
                            ){
                                if(Number(event.amount) <= 20000){
                                    props.changeCreateAuctionRegistrationFeeAmount(17)
                                }else if(Number(event.amount) <= 50000){
                                    props.changeCreateAuctionRegistrationFeeAmount(119)
                                }else if(Number(event.amount) <= 200000){
                                    props.changeCreateAuctionRegistrationFeeAmount(340)
                                }else if(Number(event.amount) <= 1000000){
                                    props.changeCreateAuctionRegistrationFeeAmount(510)
                                }else if(Number(event.amount) > 1000000){
                                    props.changeCreateAuctionRegistrationFeeAmount(1700)
                                }
                            }

                        }
                        props.changeCreateAuctionValueTax(event.addedTax)
                    }}
                    tax
                />
                )
            }
        }
    }
    if(props.typeElem === 1){
        if(isSUE(props.auctionsMy.sellingMethod) || isSUD(props.auctionsMy.sellingMethod)) {
            //tmpTax = true
            if(props.auctionsMy.status === "active_rectification") flag = false
        }
        if(flag){
            return (
                <InputAmount
                    title={viewNameInput(props)}
                    lable={TranslationsUk['legitimatePropertyLease-english'].legitimatePropertyLeaseEnglishProcedure.value.amount.legalName}
                    data={
                        {amount: tmpAmount,
                        currency: 'UAH',
                        addTax: tmpTax}
                    }
                    onChange={(event) => {
                        let reg=/[a-zA-Zа-яА-Яа-яіІїЇєЄ]/;
                        props.changeCreateAuctionValueAmount(event.amount)
                        if(
                            isLLE(props.auctionsMy.sellingMethod) || 
                            isLLD(props.auctionsMy.sellingMethod) || 
                            isLLP(props.auctionsMy.sellingMethod)
                        ){
                            if(reg.test(event.amount) !== true) {
                                if(props.discount !== null){
                                    if(props.discount.previousAuctionValue.amount !== null && props.discount.previousAuctionValue.amount !== ''){
                                        let tmp = null
                                        tmp = 100 - (convertStrToNumber(event.amount) * 100 / props.discount.previousAuctionValue.amount)
                                        props.changeDiscountPercent(tmp.toFixed(1))
                                    }
                                }
                                let tmpMinStep = (event.amount / 100) * 1
                                props.changeCreateAuctionMinimalStepAmount(tmpMinStep.toFixed(2))
                            }
                        }else if(
                            isLRE(props.auctionsMy.sellingMethod) ||
                            isLSE(props.auctionsMy.sellingMethod) ||
                            isLSP(props.auctionsMy.sellingMethod)
                        ){
                            //let tmpGaranty = (event.amount / 100) * 5
                            //props.changeCreateAuctionGuaranteeAmount(tmpGaranty.toFixed(2))
                            let tmpMinStep = (event.amount / 100) * 1
                            props.changeCreateAuctionMinimalStepAmount(tmpMinStep.toFixed(2))
                        }else{
                            if(
                                isBSE(props.auctionsMy.sellingMethod) || isBSD(props.auctionsMy.sellingMethod) ||
                                isALE(props.auctionsMy.sellingMethod) ||
                                isCSE(props.auctionsMy.sellingMethod) || isCSD(props.auctionsMy.sellingMethod) ||
                                isBRE(props.auctionsMy.sellingMethod) || isBRD(props.auctionsMy.sellingMethod)
                            ){
                                if(Number(event.amount) <= 20000){
                                    props.changeCreateAuctionRegistrationFeeAmount(17)
                                }else if(Number(event.amount) <= 50000){
                                    props.changeCreateAuctionRegistrationFeeAmount(119)
                                }else if(Number(event.amount) <= 200000){
                                    props.changeCreateAuctionRegistrationFeeAmount(340)
                                }else if(Number(event.amount) <= 1000000){
                                    props.changeCreateAuctionRegistrationFeeAmount(510)
                                }else if(Number(event.amount) > 1000000){
                                    props.changeCreateAuctionRegistrationFeeAmount(1700)
                                }
                            }else{
                                let tmpGaranty = (event.amount / 100) * 5
                                props.changeCreateAuctionGuaranteeAmount(tmpGaranty.toFixed(2))
                                let tmpMinStep = (event.amount / 100) * 1
                                props.changeCreateAuctionMinimalStepAmount(tmpMinStep.toFixed(2))
                            }
                        }
                        props.changeCreateAuctionValueTax(event.addTax)
                    }}
                    tax
                    disabled={disabledElement(props.auctionsMy, "11")}
                />
            )
        }else{
            return (
                <InputAmount
                    title={viewNameInput(props)}
                    lable={TranslationsUk['legitimatePropertyLease-english'].legitimatePropertyLeaseEnglishProcedure.value.amount.legalName}
                    data={
                        {amount: tmpAmount,
                        currency: 'UAH',
                        addTax: tmpTax}
                    }
                    onChange={(event) => {
                        let reg=/[a-zA-Zа-яА-Яа-яіІїЇєЄ]/;
                        props.changeCreateAuctionValueAmount(event.amount)
                        if(
                            isLLE(props.auctionsMy.sellingMethod) || 
                            isLLD(props.auctionsMy.sellingMethod) || 
                            isLLP(props.auctionsMy.sellingMethod)
                        ){
                            if(reg.test(event.amount) !== true) {
                                if(props.discount !== null){
                                    if(props.discount.previousAuctionValue.amount !== null && props.discount.previousAuctionValue.amount !== ''){
                                        let tmp = null
                                        tmp = 100 - (convertStrToNumber(event.amount) * 100 / props.discount.previousAuctionValue.amount)
                                        props.changeDiscountPercent(tmp.toFixed(1))
                                    }
                                }
                                let tmpMinStep = (event.amount / 100) * 1
                                props.changeCreateAuctionMinimalStepAmount(tmpMinStep.toFixed(2))
                            }
                        }else if(
                            isLRE(props.auctionsMy.sellingMethod) ||
                            isLSE(props.auctionsMy.sellingMethod) ||
                            isLSP(props.auctionsMy.sellingMethod)
                        ){
                            //let tmpGaranty = (event.amount / 100) * 5
                            //props.changeCreateAuctionGuaranteeAmount(tmpGaranty.toFixed(2))
                            let tmpMinStep = (event.amount / 100) * 1
                            props.changeCreateAuctionMinimalStepAmount(tmpMinStep.toFixed(2))
                        }else{
                            if(
                                isBSE(props.auctionsMy.sellingMethod) || isBSD(props.auctionsMy.sellingMethod) ||
                                isALE(props.auctionsMy.sellingMethod) ||
                                isCSE(props.auctionsMy.sellingMethod) || isCSD(props.auctionsMy.sellingMethod) ||
                                isBRE(props.auctionsMy.sellingMethod) || isBRD(props.auctionsMy.sellingMethod)
                            ){
                                if(Number(event.amount) <= 20000){
                                    props.changeCreateAuctionRegistrationFeeAmount(17)
                                }else if(Number(event.amount) <= 50000){
                                    props.changeCreateAuctionRegistrationFeeAmount(119)
                                }else if(Number(event.amount) <= 200000){
                                    props.changeCreateAuctionRegistrationFeeAmount(340)
                                }else if(Number(event.amount) <= 1000000){
                                    props.changeCreateAuctionRegistrationFeeAmount(510)
                                }else if(Number(event.amount) > 1000000){
                                    props.changeCreateAuctionRegistrationFeeAmount(1700)
                                }
                            }else{
                                let tmpGaranty = (event.amount / 100) * 5
                                props.changeCreateAuctionGuaranteeAmount(tmpGaranty.toFixed(2))
                                let tmpMinStep = (event.amount / 100) * 1
                                props.changeCreateAuctionMinimalStepAmount(tmpMinStep.toFixed(2))
                            }
                        }
                        props.changeCreateAuctionValueTax(event.addTax)
                    }}
                    tax
                />
            )
        }
        
    }else{
        return (
                <InputAmount
                    title={TranslationsUk['legitimatePropertyLease-english'].legitimatePropertyLeaseEnglishProcedure.value.amount.legalName}
                    data={
                        {amount: tmpAmount,
                        currency: 'uah'}
                    }
                    onChange={(event) => {
                        let reg=/[a-zA-Zа-яА-Яа-яіІїЇєЄ]/;
                        if(reg.test(event.amount) !== true) props.changeCreateAuctionValueAmount(convertStrToNumber(event.amount))
                        if(
                            isLLE(props.auctionsMy.sellingMethod) || 
                            isLLD(props.auctionsMy.sellingMethod) || 
                            isLLP(props.auctionsMy.sellingMethod)
                        ){
                            if(reg.test(event.amount) !== true) {
                                if(props.discount){
                                    if(props.discount.previousAuctionValue.amount !== null && props.discount.previousAuctionValue.amount !== ''){
                                        let tmp = null
                                        tmp = 100 - (convertStrToNumber(event.amount) * 100 / props.discount.previousAuctionValue.amount)
                                        props.changeDiscountPercent(tmp.toFixed(1))
                                    }
                                }
                                let tmpMinStep = (event.amount / 100) * 1
                                props.changeCreateAuctionMinimalStepAmount(tmpMinStep.toFixed(2))
                            }
                        }else if(
                            isLRE(props.auctionsMy.sellingMethod) ||
                            isLSE(props.auctionsMy.sellingMethod) ||
                            isLSP(props.auctionsMy.sellingMethod)
                        ){
                            ///let tmpGaranty = (event.amount / 100) * 5
                            //props.changeCreateAuctionGuaranteeAmount(tmpGaranty.toFixed(2))
                            let tmpMinStep = (event.amount / 100) * 1
                            props.changeCreateAuctionMinimalStepAmount(tmpMinStep.toFixed(2))
                        }else{
                            if(
                                isBSE(props.auctionsMy.sellingMethod) || isBSD(props.auctionsMy.sellingMethod) ||
                                isALE(props.auctionsMy.sellingMethod) ||
                                isCSE(props.auctionsMy.sellingMethod) || isCSD(props.auctionsMy.sellingMethod) ||
                                isBRE(props.auctionsMy.sellingMethod) || isBRD(props.auctionsMy.sellingMethod)
                            ){
                                if(Number(event.amount) <= 20000){
                                    props.changeCreateAuctionRegistrationFeeAmount(17)
                                }else if(Number(event.amount) <= 50000){
                                    props.changeCreateAuctionRegistrationFeeAmount(119)
                                }else if(Number(event.amount) <= 200000){
                                    props.changeCreateAuctionRegistrationFeeAmount(340)
                                }else if(Number(event.amount) <= 1000000){
                                    props.changeCreateAuctionRegistrationFeeAmount(510)
                                }else if(Number(event.amount) > 1000000){
                                    props.changeCreateAuctionRegistrationFeeAmount(1700)
                                }
                            }else{
                                let tmpGaranty = (event.amount / 100) * 5
                                props.changeCreateAuctionGuaranteeAmount(tmpGaranty.toFixed(2))
                                let tmpMinStep = (event.amount / 100) * 1
                                props.changeCreateAuctionMinimalStepAmount(tmpMinStep.toFixed(2))
                            }
                        }
                    }}
                    tax
                />
        )
    }
}

function redBlock(props, amount){
    if(
        isLLE(props.auctionsMy.sellingMethod) || 
        isLLD(props.auctionsMy.sellingMethod) || 
        isLLP(props.auctionsMy.sellingMethod)
    ){
        if(amount === null || amount === '' || amount === 'null'){
            return 'error'
        }
        return null
    }
    return null
}

function convertStrToNumber(data){
    let str = ''
    if(data !== null){
        if(String(data).indexOf(',') !== -1){
            str = String(data).replace(',', '.')
        }else{
            str = data
        }
        let strArr = null
        if(String(str).indexOf('.') !== -1){
            //if(Number(str) < 0.01) return 0.01
            strArr = String(str).split('.')
            if(strArr.length < 3){
                if(strArr[1].length < 3){
                    return str
                }else{
                    return strArr[0] + '.' + strArr[1].slice(0, 2)
                }
            }else{
                if(strArr[1].length === 0){
                    return strArr[0] + '.'
                }else if(strArr[1].length === 1){
                    return strArr[0] + '.' + strArr[1].slice(0, 1)
                }else if(strArr[1].length === 2){
                    return strArr[0] + '.' + strArr[1].slice(0, 2)
                } 
            }
        }
    }
    //if(Number(str) === 0) str = 0.01
    //if(Number(str) === 0.0) str = 0.01
    return str
}

const mapStateToProps = state => {
    return {
        auctionsMy: state.start.auctionsMy,
        auctionsZUAttemp: state.start.auctionsZUAttemp,
        discount: state.start.discount,
    }
}

const mapDispatchToProps = {
    changeCreateAuctionValueCurrency,
    changeCreateAuctionValueAmount,
    changeCreateAuctionValueTax,
    changeCreateAuctionGuaranteeAmount,
    changeCreateAuctionMinimalStepAmount,

    changeDiscountPercent,
    changeCreateAuctionRegistrationFeeAmount
}

export default connect(mapStateToProps, mapDispatchToProps)(WindowAuctionCost)