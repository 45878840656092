import {addProperty, addPropertyMonga, addPropertyValue, previousAuctionId, addPropertyDiscount, addPropertyBanksGroop, addAuctionPeriodProperty} from './utils'

export function createBodyBRW(data){
    if(data !== null){
        let body = {}
        body = addPropertyMonga(data, body, 'title')
        body = addPropertyMonga(data, body, 'description')
        body = addPropertyMonga(data, body, 'accessDetails')
        body = addProperty(data, body, 'tenderAttempts')
        body = addPropertyMonga(data, body, 'lotIdentifier')
        //body = addAuctionPeriodProperty(data, body, 'startDate')
        body = addProperty(data, body, 'sellingMethod')
        body = addPropertyMonga(data, body, 'xDocumentRequirements')
        body = addPropertyMonga(data, body, 'xAdditionalInformation')
        body = addProperty(data, body, 'minNumberOfQualifiedBids')
        //body = addProperty(data, body, 'valueAddedTaxCharged')
        //body = addPropertyValue(data, body, 'value')
        //body = addPropertyValue(data, body, 'minimalStep')
        //body = addPropertyValue(data, body, 'guarantee')
        //body = addPropertyValue(data, body, 'registrationFee')
        //body = previousAuctionId(data, body, 'previousAuctionId')
        //body = addPropertyDiscount(data, body, 'discount')
        body = addPropertyBanksGroop(data, body, 'banks')
        return body
    }
    return null
}