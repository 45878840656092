import React from 'react';
import styles from './relatedOrganizations.module.css';

import {connect} from 'react-redux';

import {TranslationsUk} from '../../../../redux/translations.uk.js';

import {
    setEditAuctionFromId,

    getMyAuction,
    changeLoader,
} from '../../../../redux/actions.js';

import {
    changeDiscountPercent,
    changeDiscountPreviousAuctionCurrency,
    changeDiscountPreviousAuctionAmount,
    changeDiscountPreviousAuctionId
} from '../../../../redux/actions/discount/discount.js';

import {
    changeCreateAuctionMinNumberOfQualifiedBids,
    changeCreateAuctionDgfVirtualDataRoom,
    changeCreateAuctionDgfEligibilityCriteria,
    changeCreateAuctionDgfX_dgfPlatformLegalDetails,
    changeCreateAuctionDgfDecisionId,
    changeCreateAuctionDgfDecisionDate,
    changeCreateAuctionСalcOnBookValue,
    changeCreateAuctionValueAddedTaxCharged,
    changeCreateAuctionRegistryId,
    changeCreateAuctionValuePeriod,
    changeCreateAuctionLeaseRulesIntendedUse,
    changeCreateAuctionLeaseRulesIntendedUseRestrictionMethod,
    changeCreateAuctionLeaseRulesIntendedUseRestrictionDescription,
    changeCreateAuctionLeaseRulesAdditionalLeaseConditions,
    changeCreateAuctionLeaseRulesSubleaseAllowed,
} from '../../../../redux/actions.js';

import {
    setDataAddress,
} from '../../../../redux/createAddress';

import {modifiDateString} from '../../../../redux/modifiDate'

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import RegistrationFee from '../windowAuctionRegistrationFee'
import Organization from '../zuAddressOrganization'
import { DateTimePicker, KeyboardDateTimePicker } from "@material-ui/pickers";

import RelatedOrganizationsOwnershipType from '../auctionsCommon/zuRelatedOrganizationsOwnershipType'

import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';

import {disabledElement} from '../../disabled'

const WindowAuctionDgf = ( props ) => {
    return viewRelatedOrganizations(props)
}

function viewRelatedOrganizations(props){
    if(props.auctionsMy !== null){
        let tmp = null
        if(
            props.auctionsMy.sellingMethod !== "legitimatePropertyLease-english" &&
            props.auctionsMy.sellingMethod !== "legitimatePropertyLease-english-fast" &&
            props.auctionsMy.sellingMethod !== "legitimatePropertyLease-english-fast-manual" &&
            props.auctionsMy.sellingMethod !== "legitimatePropertyLease-english-fast-auction-manual-qualification" &&
            props.auctionsMy.sellingMethod !== "legitimatePropertyLease-english-initial-auction" &&
            props.auctionsMy.sellingMethod !== "legitimatePropertyLease-english-initial-auction-manual" &&
            props.auctionsMy.sellingMethod !== "legitimatePropertyLease-english-initial-qualification" &&
            props.auctionsMy.sellingMethod !== "legitimatePropertyLease-english-initial-qualification-prod" &&
            props.auctionsMy.sellingMethod !== "legitimatePropertyLease-dutch" &&
            props.auctionsMy.sellingMethod !== "legitimatePropertyLease-dutch-fast" &&
            props.auctionsMy.sellingMethod !== "legitimatePropertyLease-dutch-fast-manual" &&
            props.auctionsMy.sellingMethod !== "legitimatePropertyLease-dutch-fast-auction-manual-qualification" &&
            props.auctionsMy.sellingMethod !== "legitimatePropertyLease-dutch-initial-auction" &&
            props.auctionsMy.sellingMethod !== "legitimatePropertyLease-dutch-initial-auction-manual" &&
            props.auctionsMy.sellingMethod !== "legitimatePropertyLease-dutch-initial-qualification-prod"
        ){
            //tmp = viewCurrentTenant(props)
        //}else{
            if(props.auctionsMy.relatedOrganizations !== null/* && props.addressNew.getAddress !== null*/){
                if(props.auctionsMy.relatedOrganizations.hasOwnProperty('currentTenant') === true){
                    if(props.auctionsMy.relatedOrganizations.currentTenant !== null){
                        tmp = viewCurrentTenant(props)
                    }
                }
            }
        }
        if(props.typeElem === 1){
            return (
                <>
                    {viewPropertyOwner(props)}
                    {viewSellingEntity(props)}
                    {tmp}
                    <RelatedOrganizationsOwnershipType typeElem={props.typeElem}/>
                </>
            )
        }else{
            return (
                <>
                    {viewPropertyOwner(props)}
                    {viewSellingEntity(props)}
                    {tmp}
                    <RelatedOrganizationsOwnershipType typeElem={props.typeElem}/>
                </>
            )
        }
    }
}

function viewPropertyOwner(props){
    let tmp = ''
    let tmpI = ''
    let tmpName = ''
    if(props.auctionsMy !== null){
        if(props.auctionsMy.relatedOrganizations !== null/* && props.addressNew.getAddress !== null*/){
            if(props.auctionsMy.relatedOrganizations.hasOwnProperty('propertyOwner') === true){
                if(props.auctionsMy.relatedOrganizations.propertyOwner !== null){
                    if(props.auctionsMy.relatedOrganizations.propertyOwner.hasOwnProperty('id') === true){
                        /*let tmpArr = props.addressNew.getAddress
                        let flag = false
                        for(let i = 0; i < props.addressNew.getAddress.length; i++){
                            if(props.addressNew.getAddress[i].id === props.auctionsMy.relatedOrganizations.propertyOwner.id){
                                flag = true
                            }
                        }
                        if(flag === false){
                            let tmpObj = props.auctionsMy.relatedOrganizations.propertyOwner
                            tmpObj.name = tmpObj.contactPoint.name 
                            tmpArr.push(tmpObj)
                            props.setDataAddress(tmpArr, 'GET_ADDRESS_NEW_BOOKS')
                        }else{
                            tmp = props.auctionsMy.relatedOrganizations.propertyOwner.id
                        }*/
                        tmp = props.auctionsMy.relatedOrganizations.propertyOwner.id
                        tmpI = props.auctionsMy.relatedOrganizations.propertyOwner
                    }
                    /*if(props.auctionsMy.relatedOrganizations.propertyOwner.hasOwnProperty('contactPoint') === true){
                        if(props.auctionsMy.relatedOrganizations.propertyOwner.contactPoint !== null){
                            if(props.auctionsMy.relatedOrganizations.propertyOwner.contactPoint.hasOwnProperty('name') === true){
                                tmpName = props.auctionsMy.relatedOrganizations.propertyOwner.contactPoint.name
                            }
                        }
                    }*/
                    if(props.auctionsMy.relatedOrganizations.propertyOwner.name !== null){
                        tmpName = props.auctionsMy.relatedOrganizations.propertyOwner.name
                    }else{
                        if(props.auctionsMy.relatedOrganizations.propertyOwner.hasOwnProperty('identifier') === true){
                            if(props.auctionsMy.relatedOrganizations.propertyOwner.identifier !== null){
                                tmpName = props.auctionsMy.relatedOrganizations.propertyOwner.identifier.legalName    
                            }else{
                                tmpName = props.auctionsMy.relatedOrganizations.propertyOwner.name        
                            }
                        }else{
                            tmpName = props.auctionsMy.relatedOrganizations.propertyOwner.name
                        }
                    }
                }
            }
        }else{
            props.setDataAddress({
                propertyOwner: {id: 'null'},
                sellingEntity: {id: 'null'},
                currentTenant: {id: 'null'},
                ownershipType: 'null',
            }, 'SET_AUCTION_RELATED_ORGANIZATIONS')
        }
        if(props.typeElem === 1){
            let tmpRO = null
            if(props.auctionsMy.relatedOrganizations !== null){
                tmpRO = redBlock(props, props.auctionsMy.relatedOrganizations.propertyOwner)
            }
            return (
                <>
                    <div className={styles.groupElem}>
                        <div>
                            <TextField
                                error = {tmpRO}
                                disabled={disabledElement(props.auctionsMy, '11')}
                                className={styles.inputText}
                                id="outlined-select-currency-native"
                                label="Балансоутримувач"
                                value={tmpName}
                                onChange={(e)=>{
                                    props.setDataAddress(e.target.value, 'SET_AUCTION_PROPERTY_OWNER_ID')
                                }}
                                variant="outlined"
                            >
                            </TextField>
                        </div>
                    </div>
                    <div className={styles.bottonBlock}>
                        <Button
                            error = {redBlock(props, props.auctionsMy.relatedOrganizations.propertyOwner)}
                            disabled={disabledElement(props.auctionsMy, '01')}
                            className={styles.buttonExitDocument}
                            variant="contained"
                            color="primary"
                            onClick={
                                () => {
                                    if(tmpI !== null && tmpI !== ''){
                                        props.setDataAddress(tmpI.id, 'CREATE_ADDRESS_NEW_ID')
                                        //props.setDataAddress(i.name, 'CREATE_ADDRESS_NEW_NAME')
                                        if(tmpI.name !== null){
                                            props.setDataAddress(tmpI.name, 'CREATE_ADDRESS_NEW_NAME')
                                        }else{
                                            if(tmpI.hasOwnProperty('identifier') === true){
                                                if(tmpI.identifier !== null){
                                                    props.setDataAddress(tmpI.identifier.legalName, 'CREATE_ADDRESS_NEW_NAME')
                                                }else{
                                                    props.setDataAddress(tmpI.name, 'CREATE_ADDRESS_NEW_NAME')       
                                                }
                                            }else{
                                                props.setDataAddress(tmpI.name, 'CREATE_ADDRESS_NEW_NAME')
                                            }
                                        }

                                        props.setDataAddress(tmpI.identifier.id, 'CREATE_ADDRESS_NEW_IDENTIFIER_ID')
                                        props.setDataAddress(tmpI.identifier.identifier, 'CREATE_ADDRESS_NEW_IDENTIFIER_IDENTIFIER')
                                        props.setDataAddress(tmpI.identifier.scheme, 'CREATE_ADDRESS_NEW_IDENTIFIER_SCHEME')
                                        props.setDataAddress(tmpI.identifier.legalName, 'CREATE_ADDRESS_NEW_IDENTIFIER_LEGAL_NAME')

                                        props.setDataAddress(tmpI.additionalIdentifiers.id, 'CREATE_ADDRESS_NEW_ADDITIONAL_IDENTIFIERS_ID')
                                        props.setDataAddress(tmpI.additionalIdentifiers.identifier, 'CREATE_ADDRESS_NEW_ADDITIONAL_IDENTIFIERS_IDENTIFIER')
                                        props.setDataAddress(tmpI.additionalIdentifiers.scheme, 'CREATE_ADDRESS_NEW_ADDITIONAL_IDENTIFIERS_SCHEME')
                                        props.setDataAddress(tmpI.additionalIdentifiers.legalName, 'CREATE_ADDRESS_NEW_ADDITIONAL_IDENTIFIERS_LEGAL_NAME')

                                        if(tmpI.address.addressIdentifier !== null){
                                            props.setDataAddress(tmpI.address.addressIdentifier.id, 'CREATE_ADDRESS_NEW_ADDRESS_ADDITIONAL_IDENTIFIERS_ID')
                                            props.setDataAddress(tmpI.address.addressIdentifier.identifier, 'CREATE_ADDRESS_NEW_ADDRESS_ADDITIONAL_IDENTIFIERS_IDENTIFIER')
                                            props.setDataAddress(tmpI.address.addressIdentifier.scheme, 'CREATE_ADDRESS_NEW_ADDRESS_ADDITIONAL_IDENTIFIERS_SCHEME')
                                            props.setDataAddress(tmpI.address.addressIdentifier.legalName, 'CREATE_ADDRESS_NEW_ADDRESS_ADDITIONAL_IDENTIFIERS_LEGAL_NAME')
                                        }
                                        props.setDataAddress(tmpI.address.id, 'CREATE_ADDRESS_NEW_ADDRESS_ID')
                                        props.setDataAddress(tmpI.address.streetAddress, 'CREATE_ADDRESS_NEW_ADDRESS_STREET_ADDRESS')
                                        let tmp = addressComparison(tmpI)
                                        /*let tmpRegionOption = null
                                        if(props.koatuu.region !== null){
                                            for(let tt = 0; tt < props.koatuu.region.length; tt++){
                                                if(props.koatuu.region[tt].description === tmp){
                                                    tmpRegionOption = `${props.koatuu.region[tt].classificationId}+:+${props.koatuu.region[tt].description}`
                                                }
                                            }
                                        }*/
                                        props.setDataAddress(tmp, 'CREATE_ADDRESS_NEW_ADDRESS_REGION')
                                        //props.setDataAddress(tmpRegionOption, 'CREATE_ADDRESS_NEW_ADDRESS_REGION')
                                        createLine(props, tmp)
                                        props.setDataAddress(tmpI.address.locality, 'CREATE_ADDRESS_NEW_ADDRESS_LOCALITY')
                                        props.setDataAddress(tmpI.address.postalCode, 'CREATE_ADDRESS_NEW_ADDRESS_POSTAL_CODE')
                                        props.setDataAddress(tmpI.address.countryName, 'CREATE_ADDRESS_NEW_ADDRESS_COUNTRY_NAME')

                                        props.setDataAddress(tmpI.contactPoint.id, 'CREATE_ADDRESS_NEW_CONTACT_POINT_ID')
                                        props.setDataAddress(tmpI.contactPoint.name, 'CREATE_ADDRESS_NEW_CONTACT_POINT_NAME')
                                        props.setDataAddress(tmpI.contactPoint.email, 'CREATE_ADDRESS_NEW_CONTACT_POINT_EMAIL')
                                        props.setDataAddress(tmpI.contactPoint.telephone, 'CREATE_ADDRESS_NEW_CONTACT_POINT_TELEPHONE')
                                        props.setDataAddress(tmpI.contactPoint.faxNumber, 'CREATE_ADDRESS_NEW_CONTACT_POINT_FAX_NUMBER')
                                        props.setDataAddress(tmpI.contactPoint.url, 'CREATE_ADDRESS_NEW_CONTACT_POINT_URL')

                                        props.setDataAddress(tmpI.representativeInfo, 'CREATE_ADDRESS_NEW_REPRESENTATIVE_INFO')

                                        if(tmpI.currentContractTime !== null){
                                            props.setDataAddress(tmpI.currentContractTime.startDate, 'SET_ADDRESS_NEW_CURRENT_CONTRACT_TIME_START_DATE')
                                            props.setDataAddress(tmpI.currentContractTime.endDate, 'SET_ADDRESS_NEW_CURRENT_CONTRACT_TIME_END_DATE')
                                        }

                                        props.setDataAddress(true, 'CREATE_ADDRESS_NEW_OPEN_CLOSE')
                                        props.setDataAddress(false, 'CREATE_ADDRESS_TYPE_ORG')
                                    }
                                }
                            }
                        >
                            Редагування чинного балансоутримувача
                        </Button>
                    </div>
                </>
            )
        }else{
            let tmpRO = null
            if(props.auctionsMy.relatedOrganizations !== null){
                tmpRO = redBlock(props, props.auctionsMy.relatedOrganizations.propertyOwner)
            }
            return (
                <>
                    <div className={styles.groupElem}>
                        <div>
                            <TextField
                                error = {tmpRO}
                                disabled={disabledElement(props.auctionsMy, '11')}
                                className={styles.inputText}
                                id="outlined-select-currency-native"
                                label="Балансоутримувач"
                                value={tmpName}
                                onChange={(e)=>{
                                    props.setDataAddress(e.target.value, 'SET_AUCTION_PROPERTY_OWNER_ID')
                                }}
                                variant="outlined"
                            >
                            </TextField>
                        </div>
                    </div>
                    <div className={styles.bottonBlock}>
                        <Button
                            disabled={disabledElement(props.auctionsMy, '01')}
                            className={styles.buttonExitDocument}
                            variant="contained"
                            color="primary"
                            onClick={
                                () => {
                                    if(tmpI !== null && tmpI !== ''){
                                        props.setDataAddress(tmpI.id, 'CREATE_ADDRESS_NEW_ID')
                                        //props.setDataAddress(i.name, 'CREATE_ADDRESS_NEW_NAME')
                                        if(tmpI.name !== null){
                                            props.setDataAddress(tmpI.name, 'CREATE_ADDRESS_NEW_NAME')
                                        }else{
                                            if(tmpI.hasOwnProperty('identifier') === true){
                                                if(tmpI.identifier !== null){
                                                    props.setDataAddress(tmpI.identifier.legalName, 'CREATE_ADDRESS_NEW_NAME')
                                                }else{
                                                    props.setDataAddress(tmpI.name, 'CREATE_ADDRESS_NEW_NAME')       
                                                }
                                            }else{
                                                props.setDataAddress(tmpI.name, 'CREATE_ADDRESS_NEW_NAME')
                                            }
                                        }

                                        props.setDataAddress(tmpI.identifier.id, 'CREATE_ADDRESS_NEW_IDENTIFIER_ID')
                                        props.setDataAddress(tmpI.identifier.identifier, 'CREATE_ADDRESS_NEW_IDENTIFIER_IDENTIFIER')
                                        props.setDataAddress(tmpI.identifier.scheme, 'CREATE_ADDRESS_NEW_IDENTIFIER_SCHEME')
                                        props.setDataAddress(tmpI.identifier.legalName, 'CREATE_ADDRESS_NEW_IDENTIFIER_LEGAL_NAME')

                                        props.setDataAddress(tmpI.additionalIdentifiers.id, 'CREATE_ADDRESS_NEW_ADDITIONAL_IDENTIFIERS_ID')
                                        props.setDataAddress(tmpI.additionalIdentifiers.identifier, 'CREATE_ADDRESS_NEW_ADDITIONAL_IDENTIFIERS_IDENTIFIER')
                                        props.setDataAddress(tmpI.additionalIdentifiers.scheme, 'CREATE_ADDRESS_NEW_ADDITIONAL_IDENTIFIERS_SCHEME')
                                        props.setDataAddress(tmpI.additionalIdentifiers.legalName, 'CREATE_ADDRESS_NEW_ADDITIONAL_IDENTIFIERS_LEGAL_NAME')

                                        if(tmpI.address.addressIdentifier !== null){
                                            props.setDataAddress(tmpI.address.addressIdentifier.id, 'CREATE_ADDRESS_NEW_ADDRESS_ADDITIONAL_IDENTIFIERS_ID')
                                            props.setDataAddress(tmpI.address.addressIdentifier.identifier, 'CREATE_ADDRESS_NEW_ADDRESS_ADDITIONAL_IDENTIFIERS_IDENTIFIER')
                                            props.setDataAddress(tmpI.address.addressIdentifier.scheme, 'CREATE_ADDRESS_NEW_ADDRESS_ADDITIONAL_IDENTIFIERS_SCHEME')
                                            props.setDataAddress(tmpI.address.addressIdentifier.legalName, 'CREATE_ADDRESS_NEW_ADDRESS_ADDITIONAL_IDENTIFIERS_LEGAL_NAME')
                                        }
                                        props.setDataAddress(tmpI.address.id, 'CREATE_ADDRESS_NEW_ADDRESS_ID')
                                        props.setDataAddress(tmpI.address.streetAddress, 'CREATE_ADDRESS_NEW_ADDRESS_STREET_ADDRESS')
                                        let tmp = addressComparison(tmpI)
                                        /*let tmpRegionOption = null
                                        if(props.koatuu.region !== null){
                                            for(let tt = 0; tt < props.koatuu.region.length; tt++){
                                                if(props.koatuu.region[tt].description === tmp){
                                                    tmpRegionOption = `${props.koatuu.region[tt].classificationId}+:+${props.koatuu.region[tt].description}`
                                                }
                                            }
                                        }*/
                                        props.setDataAddress(tmp, 'CREATE_ADDRESS_NEW_ADDRESS_REGION')
                                        //props.setDataAddress(tmpRegionOption, 'CREATE_ADDRESS_NEW_ADDRESS_REGION')
                                        createLine(props, tmp)
                                        props.setDataAddress(tmpI.address.locality, 'CREATE_ADDRESS_NEW_ADDRESS_LOCALITY')
                                        props.setDataAddress(tmpI.address.postalCode, 'CREATE_ADDRESS_NEW_ADDRESS_POSTAL_CODE')
                                        props.setDataAddress(tmpI.address.countryName, 'CREATE_ADDRESS_NEW_ADDRESS_COUNTRY_NAME')

                                        props.setDataAddress(tmpI.contactPoint.id, 'CREATE_ADDRESS_NEW_CONTACT_POINT_ID')
                                        props.setDataAddress(tmpI.contactPoint.name, 'CREATE_ADDRESS_NEW_CONTACT_POINT_NAME')
                                        props.setDataAddress(tmpI.contactPoint.email, 'CREATE_ADDRESS_NEW_CONTACT_POINT_EMAIL')
                                        props.setDataAddress(tmpI.contactPoint.telephone, 'CREATE_ADDRESS_NEW_CONTACT_POINT_TELEPHONE')
                                        props.setDataAddress(tmpI.contactPoint.faxNumber, 'CREATE_ADDRESS_NEW_CONTACT_POINT_FAX_NUMBER')
                                        props.setDataAddress(tmpI.contactPoint.url, 'CREATE_ADDRESS_NEW_CONTACT_POINT_URL')

                                        props.setDataAddress(tmpI.representativeInfo, 'CREATE_ADDRESS_NEW_REPRESENTATIVE_INFO')

                                        if(tmpI.currentContractTime !== null){
                                            props.setDataAddress(tmpI.currentContractTime.startDate, 'SET_ADDRESS_NEW_CURRENT_CONTRACT_TIME_START_DATE')
                                            props.setDataAddress(tmpI.currentContractTime.endDate, 'SET_ADDRESS_NEW_CURRENT_CONTRACT_TIME_END_DATE')
                                        }

                                        props.setDataAddress(true, 'CREATE_ADDRESS_NEW_OPEN_CLOSE')
                                        props.setDataAddress(false, 'CREATE_ADDRESS_TYPE_ORG')
                                    }
                                }
                            }
                        >
                            Редагування чинного балансоутримувача
                        </Button>
                    </div>
                </>
            )
        }
    }
}


function viewSellingEntity(props){
    let tmp = ''
    let tmpI = null
    let tmpName = null
    if(props.auctionsMy.relatedOrganizations !== null/* && props.addressNew.getAddress !== null*/){
        if(props.auctionsMy.relatedOrganizations.hasOwnProperty('sellingEntity') === true){
            if(props.auctionsMy.relatedOrganizations.sellingEntity !== null){
                if(props.auctionsMy.relatedOrganizations.sellingEntity.hasOwnProperty('id') === true){
                    /*let tmpArr = props.addressNew.getAddress
                    let flag = false
                    for(let i = 0; i < props.addressNew.getAddress.length; i++){
                        if(props.addressNew.getAddress[i].id === props.auctionsMy.relatedOrganizations.sellingEntity.id){
                            flag = true
                        }
                    }
                    if(flag === false){
                        let tmpObj = props.auctionsMy.relatedOrganizations.sellingEntity
                        tmpObj.name = tmpObj.contactPoint.name 
                        tmpArr.push(tmpObj)
                        props.setDataAddress(tmpArr, 'GET_ADDRESS_NEW_BOOKS')
                    }else{
                        tmp = props.auctionsMy.relatedOrganizations.sellingEntity.id
                    }*/
                    tmp = props.auctionsMy.relatedOrganizations.sellingEntity.id
                    tmpI = props.auctionsMy.relatedOrganizations.sellingEntity
                }
                /*if(props.auctionsMy.relatedOrganizations.sellingEntity.hasOwnProperty('contactPoint') === true){
                    if(props.auctionsMy.relatedOrganizations.sellingEntity.contactPoint !== null){
                        if(props.auctionsMy.relatedOrganizations.sellingEntity.contactPoint.hasOwnProperty('name') === true){
                            tmpName = props.auctionsMy.relatedOrganizations.sellingEntity.contactPoint.name
                        }
                    }
                }*/
                if(props.auctionsMy.relatedOrganizations.sellingEntity.name !== null){
                    tmpName = props.auctionsMy.relatedOrganizations.sellingEntity.name
                }else{
                    if(props.auctionsMy.relatedOrganizations.sellingEntity.hasOwnProperty('identifier') === true){
                        if(props.auctionsMy.relatedOrganizations.sellingEntity.identifier !== null){
                            tmpName = props.auctionsMy.relatedOrganizations.sellingEntity.identifier.legalName    
                        }else{
                            tmpName = props.auctionsMy.relatedOrganizations.sellingEntity.name        
                        }
                    }else{
                        tmpName = props.auctionsMy.relatedOrganizations.sellingEntity.name
                    }
                }
            }
        }
    }else{
        props.setDataAddress({
            propertyOwner: {id: 'null'},
            sellingEntity: {id: 'null'},
            currentTenant: {id: 'null'},
            ownershipType: 'null',
        }, 'SET_AUCTION_RELATED_ORGANIZATIONS')
    }
    if(props.typeElem === 1){
        let tmpRO = null
        if(props.auctionsMy.relatedOrganizations !== null){
            tmpRO = redBlock(props, props.auctionsMy.relatedOrganizations.sellingEntity)
        }
        return (
            <>
                <div className={styles.groupElem}>
                    <div>
                        <TextField
                            error = {tmpRO}
                            disabled={disabledElement(props.auctionsMy, '11')}
                            className={styles.inputText}
                            id="outlined-select-currency-native"
                            label="Орендодавець"
                            value={tmpName}
                            onChange={(e)=>{
                                props.setDataAddress(e.target.value, 'SET_AUCTION_SELLING_ENTITY_ID')
                            }}
                            variant="outlined"
                        >
                        </TextField>
                    </div>
                </div>
                <div className={styles.bottonBlock}>
                    <Button
                        disabled={disabledElement(props.auctionsMy, '01')}
                        className={styles.buttonExitDocument}
                        variant="contained"
                        color="primary"
                        onClick={
                            () => {
                                if(tmpI !== null && tmpI !== ''){
                                    props.setDataAddress(tmpI.id, 'CREATE_ADDRESS_NEW_ID')
                                    //props.setDataAddress(i.name, 'CREATE_ADDRESS_NEW_NAME')
                                    if(tmpI.name !== null){
                                        props.setDataAddress(tmpI.name, 'CREATE_ADDRESS_NEW_NAME')
                                    }else{
                                        if(tmpI.hasOwnProperty('identifier') === true){
                                            if(tmpI.identifier !== null){
                                                props.setDataAddress(tmpI.identifier.legalName, 'CREATE_ADDRESS_NEW_NAME')
                                            }else{
                                                props.setDataAddress(tmpI.name, 'CREATE_ADDRESS_NEW_NAME')       
                                            }
                                        }else{
                                            props.setDataAddress(tmpI.name, 'CREATE_ADDRESS_NEW_NAME')
                                        }
                                    }

                                    props.setDataAddress(tmpI.identifier.id, 'CREATE_ADDRESS_NEW_IDENTIFIER_ID')
                                    props.setDataAddress(tmpI.identifier.identifier, 'CREATE_ADDRESS_NEW_IDENTIFIER_IDENTIFIER')
                                    props.setDataAddress(tmpI.identifier.scheme, 'CREATE_ADDRESS_NEW_IDENTIFIER_SCHEME')
                                    props.setDataAddress(tmpI.identifier.legalName, 'CREATE_ADDRESS_NEW_IDENTIFIER_LEGAL_NAME')

                                    props.setDataAddress(tmpI.additionalIdentifiers.id, 'CREATE_ADDRESS_NEW_ADDITIONAL_IDENTIFIERS_ID')
                                    props.setDataAddress(tmpI.additionalIdentifiers.identifier, 'CREATE_ADDRESS_NEW_ADDITIONAL_IDENTIFIERS_IDENTIFIER')
                                    props.setDataAddress(tmpI.additionalIdentifiers.scheme, 'CREATE_ADDRESS_NEW_ADDITIONAL_IDENTIFIERS_SCHEME')
                                    props.setDataAddress(tmpI.additionalIdentifiers.legalName, 'CREATE_ADDRESS_NEW_ADDITIONAL_IDENTIFIERS_LEGAL_NAME')

                                    if(tmpI.address.addressIdentifier !== null){
                                        props.setDataAddress(tmpI.address.addressIdentifier.id, 'CREATE_ADDRESS_NEW_ADDRESS_ADDITIONAL_IDENTIFIERS_ID')
                                        props.setDataAddress(tmpI.address.addressIdentifier.identifier, 'CREATE_ADDRESS_NEW_ADDRESS_ADDITIONAL_IDENTIFIERS_IDENTIFIER')
                                        props.setDataAddress(tmpI.address.addressIdentifier.scheme, 'CREATE_ADDRESS_NEW_ADDRESS_ADDITIONAL_IDENTIFIERS_SCHEME')
                                        props.setDataAddress(tmpI.address.addressIdentifier.legalName, 'CREATE_ADDRESS_NEW_ADDRESS_ADDITIONAL_IDENTIFIERS_LEGAL_NAME')
                                    }
                                    props.setDataAddress(tmpI.address.id, 'CREATE_ADDRESS_NEW_ADDRESS_ID')
                                    props.setDataAddress(tmpI.address.streetAddress, 'CREATE_ADDRESS_NEW_ADDRESS_STREET_ADDRESS')
                                    let tmp = addressComparison(tmpI)
                                    /*let tmpRegionOption = null
                                    if(props.koatuu.region !== null){
                                        for(let tt = 0; tt < props.koatuu.region.length; tt++){
                                            if(props.koatuu.region[tt].description === tmp){
                                                tmpRegionOption = `${props.koatuu.region[tt].classificationId}+:+${props.koatuu.region[tt].description}`
                                            }
                                        }
                                    }*/
                                    props.setDataAddress(tmp, 'CREATE_ADDRESS_NEW_ADDRESS_REGION')
                                    //props.setDataAddress(tmpRegionOption, 'CREATE_ADDRESS_NEW_ADDRESS_REGION')
                                    createLine(props, tmp)
                                    props.setDataAddress(tmpI.address.locality, 'CREATE_ADDRESS_NEW_ADDRESS_LOCALITY')
                                    props.setDataAddress(tmpI.address.postalCode, 'CREATE_ADDRESS_NEW_ADDRESS_POSTAL_CODE')
                                    props.setDataAddress(tmpI.address.countryName, 'CREATE_ADDRESS_NEW_ADDRESS_COUNTRY_NAME')

                                    props.setDataAddress(tmpI.contactPoint.id, 'CREATE_ADDRESS_NEW_CONTACT_POINT_ID')
                                    props.setDataAddress(tmpI.contactPoint.name, 'CREATE_ADDRESS_NEW_CONTACT_POINT_NAME')
                                    props.setDataAddress(tmpI.contactPoint.email, 'CREATE_ADDRESS_NEW_CONTACT_POINT_EMAIL')
                                    props.setDataAddress(tmpI.contactPoint.telephone, 'CREATE_ADDRESS_NEW_CONTACT_POINT_TELEPHONE')
                                    props.setDataAddress(tmpI.contactPoint.faxNumber, 'CREATE_ADDRESS_NEW_CONTACT_POINT_FAX_NUMBER')
                                    props.setDataAddress(tmpI.contactPoint.url, 'CREATE_ADDRESS_NEW_CONTACT_POINT_URL')

                                    props.setDataAddress(tmpI.representativeInfo, 'CREATE_ADDRESS_NEW_REPRESENTATIVE_INFO')

                                    if(tmpI.currentContractTime !== null){
                                        props.setDataAddress(tmpI.currentContractTime.startDate, 'SET_ADDRESS_NEW_CURRENT_CONTRACT_TIME_START_DATE')
                                        props.setDataAddress(tmpI.currentContractTime.endDate, 'SET_ADDRESS_NEW_CURRENT_CONTRACT_TIME_END_DATE')
                                    }

                                    props.setDataAddress(true, 'CREATE_ADDRESS_NEW_OPEN_CLOSE')
                                    props.setDataAddress(false, 'CREATE_ADDRESS_TYPE_ORG')
                                }
                            }
                        }
                    >
                        Редагування чинного орендодавця
                    </Button>
                </div>
            </>
        )
    }else{
        let tmpRO = null
        if(props.auctionsMy.relatedOrganizations !== null){
            tmpRO = redBlock(props, props.auctionsMy.relatedOrganizations.sellingEntity)
        }
        return (
            <>
                <div className={styles.groupElem}>
                    <div>
                        <TextField
                            error = {tmpRO}
                            disabled={disabledElement(props.auctionsMy, '11')}
                            className={styles.inputText}
                            id="outlined-select-currency-native"
                            label="Орендодавець"
                            value={tmpName}
                            onChange={(e)=>{
                                props.setDataAddress(e.target.value, 'SET_AUCTION_SELLING_ENTITY_ID')
                            }}
                            variant="outlined"
                        >
                        </TextField>
                    </div>
                </div>
                <div className={styles.bottonBlock}>
                    <Button
                        disabled={disabledElement(props.auctionsMy, '01')}
                        className={styles.buttonExitDocument}
                        variant="contained"
                        color="primary"
                        onClick={
                            () => {
                                if(tmpI !== null && tmpI !== ''){
                                    props.setDataAddress(tmpI.id, 'CREATE_ADDRESS_NEW_ID')
                                    //props.setDataAddress(i.name, 'CREATE_ADDRESS_NEW_NAME')
                                    if(tmpI.name !== null){
                                        props.setDataAddress(tmpI.name, 'CREATE_ADDRESS_NEW_NAME')
                                    }else{
                                        if(tmpI.hasOwnProperty('identifier') === true){
                                            if(tmpI.identifier !== null){
                                                props.setDataAddress(tmpI.identifier.legalName, 'CREATE_ADDRESS_NEW_NAME')
                                            }else{
                                                props.setDataAddress(tmpI.name, 'CREATE_ADDRESS_NEW_NAME')       
                                            }
                                        }else{
                                            props.setDataAddress(tmpI.name, 'CREATE_ADDRESS_NEW_NAME')
                                        }
                                    }

                                    props.setDataAddress(tmpI.identifier.id, 'CREATE_ADDRESS_NEW_IDENTIFIER_ID')
                                    props.setDataAddress(tmpI.identifier.identifier, 'CREATE_ADDRESS_NEW_IDENTIFIER_IDENTIFIER')
                                    props.setDataAddress(tmpI.identifier.scheme, 'CREATE_ADDRESS_NEW_IDENTIFIER_SCHEME')
                                    props.setDataAddress(tmpI.identifier.legalName, 'CREATE_ADDRESS_NEW_IDENTIFIER_LEGAL_NAME')

                                    props.setDataAddress(tmpI.additionalIdentifiers.id, 'CREATE_ADDRESS_NEW_ADDITIONAL_IDENTIFIERS_ID')
                                    props.setDataAddress(tmpI.additionalIdentifiers.identifier, 'CREATE_ADDRESS_NEW_ADDITIONAL_IDENTIFIERS_IDENTIFIER')
                                    props.setDataAddress(tmpI.additionalIdentifiers.scheme, 'CREATE_ADDRESS_NEW_ADDITIONAL_IDENTIFIERS_SCHEME')
                                    props.setDataAddress(tmpI.additionalIdentifiers.legalName, 'CREATE_ADDRESS_NEW_ADDITIONAL_IDENTIFIERS_LEGAL_NAME')

                                    if(tmpI.address.addressIdentifier !== null){
                                        props.setDataAddress(tmpI.address.addressIdentifier.id, 'CREATE_ADDRESS_NEW_ADDRESS_ADDITIONAL_IDENTIFIERS_ID')
                                        props.setDataAddress(tmpI.address.addressIdentifier.identifier, 'CREATE_ADDRESS_NEW_ADDRESS_ADDITIONAL_IDENTIFIERS_IDENTIFIER')
                                        props.setDataAddress(tmpI.address.addressIdentifier.scheme, 'CREATE_ADDRESS_NEW_ADDRESS_ADDITIONAL_IDENTIFIERS_SCHEME')
                                        props.setDataAddress(tmpI.address.addressIdentifier.legalName, 'CREATE_ADDRESS_NEW_ADDRESS_ADDITIONAL_IDENTIFIERS_LEGAL_NAME')
                                    }
                                    props.setDataAddress(tmpI.address.id, 'CREATE_ADDRESS_NEW_ADDRESS_ID')
                                    props.setDataAddress(tmpI.address.streetAddress, 'CREATE_ADDRESS_NEW_ADDRESS_STREET_ADDRESS')
                                    let tmp = addressComparison(tmpI)
                                    /*let tmpRegionOption = null
                                    if(props.koatuu.region !== null){
                                        for(let tt = 0; tt < props.koatuu.region.length; tt++){
                                            if(props.koatuu.region[tt].description === tmp){
                                                tmpRegionOption = `${props.koatuu.region[tt].classificationId}+:+${props.koatuu.region[tt].description}`
                                            }
                                        }
                                    }*/
                                    props.setDataAddress(tmp, 'CREATE_ADDRESS_NEW_ADDRESS_REGION')
                                    //props.setDataAddress(tmpRegionOption, 'CREATE_ADDRESS_NEW_ADDRESS_REGION')
                                    createLine(props, tmp)
                                    props.setDataAddress(tmpI.address.locality, 'CREATE_ADDRESS_NEW_ADDRESS_LOCALITY')
                                    props.setDataAddress(tmpI.address.postalCode, 'CREATE_ADDRESS_NEW_ADDRESS_POSTAL_CODE')
                                    props.setDataAddress(tmpI.address.countryName, 'CREATE_ADDRESS_NEW_ADDRESS_COUNTRY_NAME')

                                    props.setDataAddress(tmpI.contactPoint.id, 'CREATE_ADDRESS_NEW_CONTACT_POINT_ID')
                                    props.setDataAddress(tmpI.contactPoint.name, 'CREATE_ADDRESS_NEW_CONTACT_POINT_NAME')
                                    props.setDataAddress(tmpI.contactPoint.email, 'CREATE_ADDRESS_NEW_CONTACT_POINT_EMAIL')
                                    props.setDataAddress(tmpI.contactPoint.telephone, 'CREATE_ADDRESS_NEW_CONTACT_POINT_TELEPHONE')
                                    props.setDataAddress(tmpI.contactPoint.faxNumber, 'CREATE_ADDRESS_NEW_CONTACT_POINT_FAX_NUMBER')
                                    props.setDataAddress(tmpI.contactPoint.url, 'CREATE_ADDRESS_NEW_CONTACT_POINT_URL')

                                    props.setDataAddress(tmpI.representativeInfo, 'CREATE_ADDRESS_NEW_REPRESENTATIVE_INFO')

                                    if(tmpI.currentContractTime !== null){
                                        props.setDataAddress(tmpI.currentContractTime.startDate, 'SET_ADDRESS_NEW_CURRENT_CONTRACT_TIME_START_DATE')
                                        props.setDataAddress(tmpI.currentContractTime.endDate, 'SET_ADDRESS_NEW_CURRENT_CONTRACT_TIME_END_DATE')
                                    }

                                    props.setDataAddress(true, 'CREATE_ADDRESS_NEW_OPEN_CLOSE')
                                    props.setDataAddress(false, 'CREATE_ADDRESS_TYPE_ORG')
                                }
                            }
                        }
                    >
                        Редагування чинного орендодавця
                    </Button>
                </div>
            </>
        )
    }
}

function viewCurrentTenant(props){
    let tmp = ''
    let tmpI = null
    let tmpName = null
    if(props.auctionsMy !== null){
        if(props.auctionsMy.relatedOrganizations !== null/* && props.addressNew.getAddress !== null*/){
            if(props.auctionsMy.relatedOrganizations.hasOwnProperty('currentTenant') === true){
                if(props.auctionsMy.relatedOrganizations.currentTenant !== null){
                    if(props.auctionsMy.relatedOrganizations.currentTenant.hasOwnProperty('id') === true){
                        /*let tmpArr = props.addressNew.getAddress
                        let flag = false
                        for(let i = 0; i < props.addressNew.getAddress.length; i++){
                            if(props.addressNew.getAddress[i].id === props.auctionsMy.relatedOrganizations.currentTenant.id){
                                flag = true
                            }
                        }
                        if(flag === false){
                            let tmpObj = props.auctionsMy.relatedOrganizations.currentTenant
                            tmpObj.name = tmpObj.contactPoint.name 
                            tmpArr.push(tmpObj)
                            props.setDataAddress(tmpArr, 'GET_ADDRESS_NEW_BOOKS')
                        }else{*/
                            tmp = props.auctionsMy.relatedOrganizations.currentTenant.id
                            tmpI = props.auctionsMy.relatedOrganizations.currentTenant
                        //}
                    }
                    /*if(props.auctionsMy.relatedOrganizations.currentTenant.hasOwnProperty('contactPoint') === true){
                        if(props.auctionsMy.relatedOrganizations.currentTenant.contactPoint !== null){
                            if(props.auctionsMy.relatedOrganizations.currentTenant.contactPoint.hasOwnProperty('name') === true){
                                tmpName = props.auctionsMy.relatedOrganizations.currentTenant.contactPoint.name
                            }
                        }
                    }*/
                    if(props.auctionsMy.relatedOrganizations.currentTenant.name !== null){
                        tmpName = props.auctionsMy.relatedOrganizations.currentTenant.name
                    }else{
                        if(props.auctionsMy.relatedOrganizations.currentTenant.hasOwnProperty('identifier') === true){
                            if(props.auctionsMy.relatedOrganizations.currentTenant.identifier !== null){
                                tmpName = props.auctionsMy.relatedOrganizations.currentTenant.identifier.legalName    
                            }else{
                                tmpName = props.auctionsMy.relatedOrganizations.currentTenant.name        
                            }
                        }else{
                            tmpName = props.auctionsMy.relatedOrganizations.currentTenant.name
                        }
                    }
                }
            }
        }else{
            props.setDataAddress({
                propertyOwner: {id: 'null'},
                sellingEntity: {id: 'null'},
                currentTenant: {id: 'null'},
                ownershipType: 'null',
            }, 'SET_AUCTION_RELATED_ORGANIZATIONS')
        }
        if(props.typeElem === 1){
            let tmpRO = null
            if(props.auctionsMy.relatedOrganizations !== null){
                tmpRO = redBlock(props, props.auctionsMy.relatedOrganizations.currentTenant)
            }
            return (
                <>
                    <div className={styles.groupElem}>
                        <div>
                            <TextField
                                error = {tmpRO}
                                disabled={disabledElement(props.auctionsMy, '11')}
                                className={styles.inputText}
                                id="outlined-select-currency-native"
                                label="Чинний орендар"
                                value={tmpName}
                                onChange={(e)=>{
                                    props.setDataAddress(e.target.value, 'SET_AUCTION_CURRENT_TENANT_ID')
                                }}
                                variant="outlined"
                            >
                            </TextField>
                        </div>
                    </div>
                    <div className={styles.groupElem + ' ' + styles.groupElemRed}>Заповніть дати договору Чинного Орендаря *</div>
                    <div className={styles.bottonBlock}>
                        <Button
                            disabled={disabledElement(props.auctionsMy, '00')}
                            className={styles.buttonExitDocument}
                            variant="contained"
                            color="primary"
                            onClick={
                                () => {
                                    if(tmpI !== null && tmpI !== ''){
                                        props.setDataAddress(tmpI.id, 'CREATE_ADDRESS_NEW_ID')
                                        //props.setDataAddress(i.name, 'CREATE_ADDRESS_NEW_NAME')
                                        if(tmpI.name !== null){
                                            props.setDataAddress(tmpI.name, 'CREATE_ADDRESS_NEW_NAME')
                                        }else{
                                            if(tmpI.hasOwnProperty('identifier') === true){
                                                if(tmpI.identifier !== null){
                                                    props.setDataAddress(tmpI.identifier.legalName, 'CREATE_ADDRESS_NEW_NAME')
                                                }else{
                                                    props.setDataAddress(tmpI.name, 'CREATE_ADDRESS_NEW_NAME')       
                                                }
                                            }else{
                                                props.setDataAddress(tmpI.name, 'CREATE_ADDRESS_NEW_NAME')
                                            }
                                        }

                                        props.setDataAddress(tmpI.identifier.id, 'CREATE_ADDRESS_NEW_IDENTIFIER_ID')
                                        props.setDataAddress(tmpI.identifier.identifier, 'CREATE_ADDRESS_NEW_IDENTIFIER_IDENTIFIER')
                                        props.setDataAddress(tmpI.identifier.scheme, 'CREATE_ADDRESS_NEW_IDENTIFIER_SCHEME')
                                        props.setDataAddress(tmpI.identifier.legalName, 'CREATE_ADDRESS_NEW_IDENTIFIER_LEGAL_NAME')

                                        props.setDataAddress(tmpI.additionalIdentifiers.id, 'CREATE_ADDRESS_NEW_ADDITIONAL_IDENTIFIERS_ID')
                                        props.setDataAddress(tmpI.additionalIdentifiers.identifier, 'CREATE_ADDRESS_NEW_ADDITIONAL_IDENTIFIERS_IDENTIFIER')
                                        props.setDataAddress(tmpI.additionalIdentifiers.scheme, 'CREATE_ADDRESS_NEW_ADDITIONAL_IDENTIFIERS_SCHEME')
                                        props.setDataAddress(tmpI.additionalIdentifiers.legalName, 'CREATE_ADDRESS_NEW_ADDITIONAL_IDENTIFIERS_LEGAL_NAME')

                                        if(tmpI.address.addressIdentifier !== null){
                                            props.setDataAddress(tmpI.address.addressIdentifier.id, 'CREATE_ADDRESS_NEW_ADDRESS_ADDITIONAL_IDENTIFIERS_ID')
                                            props.setDataAddress(tmpI.address.addressIdentifier.identifier, 'CREATE_ADDRESS_NEW_ADDRESS_ADDITIONAL_IDENTIFIERS_IDENTIFIER')
                                            props.setDataAddress(tmpI.address.addressIdentifier.scheme, 'CREATE_ADDRESS_NEW_ADDRESS_ADDITIONAL_IDENTIFIERS_SCHEME')
                                            props.setDataAddress(tmpI.address.addressIdentifier.legalName, 'CREATE_ADDRESS_NEW_ADDRESS_ADDITIONAL_IDENTIFIERS_LEGAL_NAME')
                                        }
                                        props.setDataAddress(tmpI.address.id, 'CREATE_ADDRESS_NEW_ADDRESS_ID')
                                        props.setDataAddress(tmpI.address.streetAddress, 'CREATE_ADDRESS_NEW_ADDRESS_STREET_ADDRESS')
                                        let tmp = addressComparison(tmpI)
                                        /*let tmpRegionOption = null
                                        if(props.koatuu.region !== null){
                                            for(let tt = 0; tt < props.koatuu.region.length; tt++){
                                                if(props.koatuu.region[tt].description === tmp){
                                                    tmpRegionOption = `${props.koatuu.region[tt].classificationId}+:+${props.koatuu.region[tt].description}`
                                                }
                                            }
                                        }*/
                                        props.setDataAddress(tmp, 'CREATE_ADDRESS_NEW_ADDRESS_REGION')
                                        //props.setDataAddress(tmpRegionOption, 'CREATE_ADDRESS_NEW_ADDRESS_REGION')
                                        createLine(props, tmp)
                                        props.setDataAddress(tmpI.address.locality, 'CREATE_ADDRESS_NEW_ADDRESS_LOCALITY')
                                        props.setDataAddress(tmpI.address.postalCode, 'CREATE_ADDRESS_NEW_ADDRESS_POSTAL_CODE')
                                        props.setDataAddress(tmpI.address.countryName, 'CREATE_ADDRESS_NEW_ADDRESS_COUNTRY_NAME')

                                        props.setDataAddress(tmpI.contactPoint.id, 'CREATE_ADDRESS_NEW_CONTACT_POINT_ID')
                                        props.setDataAddress(tmpI.contactPoint.name, 'CREATE_ADDRESS_NEW_CONTACT_POINT_NAME')
                                        props.setDataAddress(tmpI.contactPoint.email, 'CREATE_ADDRESS_NEW_CONTACT_POINT_EMAIL')
                                        props.setDataAddress(tmpI.contactPoint.telephone, 'CREATE_ADDRESS_NEW_CONTACT_POINT_TELEPHONE')
                                        props.setDataAddress(tmpI.contactPoint.faxNumber, 'CREATE_ADDRESS_NEW_CONTACT_POINT_FAX_NUMBER')
                                        props.setDataAddress(tmpI.contactPoint.url, 'CREATE_ADDRESS_NEW_CONTACT_POINT_URL')

                                        props.setDataAddress(tmpI.representativeInfo, 'CREATE_ADDRESS_NEW_REPRESENTATIVE_INFO')

                                        if(tmpI.currentContractTime !== null){
                                            props.setDataAddress(tmpI.currentContractTime.dateFrom, 'SET_ADDRESS_NEW_CURRENT_CONTRACT_TIME_START_DATE')
                                            props.setDataAddress(tmpI.currentContractTime.dateTill, 'SET_ADDRESS_NEW_CURRENT_CONTRACT_TIME_END_DATE')
                                        }

                                        props.setDataAddress(true, 'CREATE_ADDRESS_NEW_OPEN_CLOSE')
                                        props.setDataAddress(true, 'CREATE_ADDRESS_TYPE_ORG')
                                    }
                                }
                            }
                        >
                            Редагування чинного орендаря
                        </Button>
                    </div>
                </>
            )
        }else{
            let tmpRO = null
            if(props.auctionsMy.relatedOrganizations !== null){
                tmpRO = redBlock(props, props.auctionsMy.relatedOrganizations.currentTenant)
            }
            return (
                <>
                    <div className={styles.groupElem}>
                        <div>
                            <TextField
                                error = {tmpRO}
                                disabled={disabledElement(props.auctionsMy, '11')}
                                className={styles.inputText}
                                id="outlined-select-currency-native"
                                label="Чинний орендар"
                                value={tmpName}
                                onChange={(e)=>{
                                    props.setDataAddress(e.target.value, 'SET_AUCTION_CURRENT_TENANT_ID')
                                }}
                                variant="outlined"
                            >
                            </TextField>
                        </div>
                    </div>
                    <div className={styles.groupElem + ' ' + styles.groupElemRed}>Заповніть дати договору Чинного Орендаря *</div>
                    <div className={styles.bottonBlock}>
                        <Button
                            disabled={disabledElement(props.auctionsMy, '00')}
                            className={styles.buttonExitDocument}
                            variant="contained"
                            color="primary"
                            onClick={
                                () => {
                                    if(tmpI !== null && tmpI !== ''){
                                        props.setDataAddress(tmpI.id, 'CREATE_ADDRESS_NEW_ID')
                                        //props.setDataAddress(i.name, 'CREATE_ADDRESS_NEW_NAME')
                                        if(tmpI.name !== null){
                                            props.setDataAddress(tmpI.name, 'CREATE_ADDRESS_NEW_NAME')
                                        }else{
                                            if(tmpI.hasOwnProperty('identifier') === true){
                                                if(tmpI.identifier !== null){
                                                    props.setDataAddress(tmpI.identifier.legalName, 'CREATE_ADDRESS_NEW_NAME')
                                                }else{
                                                    props.setDataAddress(tmpI.name, 'CREATE_ADDRESS_NEW_NAME')       
                                                }
                                            }else{
                                                props.setDataAddress(tmpI.name, 'CREATE_ADDRESS_NEW_NAME')
                                            }
                                        }

                                        props.setDataAddress(tmpI.identifier.id, 'CREATE_ADDRESS_NEW_IDENTIFIER_ID')
                                        props.setDataAddress(tmpI.identifier.identifier, 'CREATE_ADDRESS_NEW_IDENTIFIER_IDENTIFIER')
                                        props.setDataAddress(tmpI.identifier.scheme, 'CREATE_ADDRESS_NEW_IDENTIFIER_SCHEME')
                                        props.setDataAddress(tmpI.identifier.legalName, 'CREATE_ADDRESS_NEW_IDENTIFIER_LEGAL_NAME')

                                        props.setDataAddress(tmpI.additionalIdentifiers.id, 'CREATE_ADDRESS_NEW_ADDITIONAL_IDENTIFIERS_ID')
                                        props.setDataAddress(tmpI.additionalIdentifiers.identifier, 'CREATE_ADDRESS_NEW_ADDITIONAL_IDENTIFIERS_IDENTIFIER')
                                        props.setDataAddress(tmpI.additionalIdentifiers.scheme, 'CREATE_ADDRESS_NEW_ADDITIONAL_IDENTIFIERS_SCHEME')
                                        props.setDataAddress(tmpI.additionalIdentifiers.legalName, 'CREATE_ADDRESS_NEW_ADDITIONAL_IDENTIFIERS_LEGAL_NAME')

                                        if(tmpI.address.addressIdentifier !== null){
                                            props.setDataAddress(tmpI.address.addressIdentifier.id, 'CREATE_ADDRESS_NEW_ADDRESS_ADDITIONAL_IDENTIFIERS_ID')
                                            props.setDataAddress(tmpI.address.addressIdentifier.identifier, 'CREATE_ADDRESS_NEW_ADDRESS_ADDITIONAL_IDENTIFIERS_IDENTIFIER')
                                            props.setDataAddress(tmpI.address.addressIdentifier.scheme, 'CREATE_ADDRESS_NEW_ADDRESS_ADDITIONAL_IDENTIFIERS_SCHEME')
                                            props.setDataAddress(tmpI.address.addressIdentifier.legalName, 'CREATE_ADDRESS_NEW_ADDRESS_ADDITIONAL_IDENTIFIERS_LEGAL_NAME')
                                        }
                                        props.setDataAddress(tmpI.address.id, 'CREATE_ADDRESS_NEW_ADDRESS_ID')
                                        props.setDataAddress(tmpI.address.streetAddress, 'CREATE_ADDRESS_NEW_ADDRESS_STREET_ADDRESS')
                                        let tmp = addressComparison(tmpI)
                                        /*let tmpRegionOption = null
                                        if(props.koatuu.region !== null){
                                            for(let tt = 0; tt < props.koatuu.region.length; tt++){
                                                if(props.koatuu.region[tt].description === tmp){
                                                    tmpRegionOption = `${props.koatuu.region[tt].classificationId}+:+${props.koatuu.region[tt].description}`
                                                }
                                            }
                                        }*/
                                        props.setDataAddress(tmp, 'CREATE_ADDRESS_NEW_ADDRESS_REGION')
                                        //props.setDataAddress(tmpRegionOption, 'CREATE_ADDRESS_NEW_ADDRESS_REGION')
                                        createLine(props, tmp)
                                        props.setDataAddress(tmpI.address.locality, 'CREATE_ADDRESS_NEW_ADDRESS_LOCALITY')
                                        props.setDataAddress(tmpI.address.postalCode, 'CREATE_ADDRESS_NEW_ADDRESS_POSTAL_CODE')
                                        props.setDataAddress(tmpI.address.countryName, 'CREATE_ADDRESS_NEW_ADDRESS_COUNTRY_NAME')

                                        props.setDataAddress(tmpI.contactPoint.id, 'CREATE_ADDRESS_NEW_CONTACT_POINT_ID')
                                        props.setDataAddress(tmpI.contactPoint.name, 'CREATE_ADDRESS_NEW_CONTACT_POINT_NAME')
                                        props.setDataAddress(tmpI.contactPoint.email, 'CREATE_ADDRESS_NEW_CONTACT_POINT_EMAIL')
                                        props.setDataAddress(tmpI.contactPoint.telephone, 'CREATE_ADDRESS_NEW_CONTACT_POINT_TELEPHONE')
                                        props.setDataAddress(tmpI.contactPoint.faxNumber, 'CREATE_ADDRESS_NEW_CONTACT_POINT_FAX_NUMBER')
                                        props.setDataAddress(tmpI.contactPoint.url, 'CREATE_ADDRESS_NEW_CONTACT_POINT_URL')

                                        props.setDataAddress(tmpI.representativeInfo, 'CREATE_ADDRESS_NEW_REPRESENTATIVE_INFO')

                                        if(tmpI.currentContractTime !== null){
                                            props.setDataAddress(tmpI.currentContractTime.dateFrom, 'SET_ADDRESS_NEW_CURRENT_CONTRACT_TIME_START_DATE')
                                            props.setDataAddress(tmpI.currentContractTime.dateTill, 'SET_ADDRESS_NEW_CURRENT_CONTRACT_TIME_END_DATE')
                                        }

                                        props.setDataAddress(true, 'CREATE_ADDRESS_NEW_OPEN_CLOSE')
                                        props.setDataAddress(true, 'CREATE_ADDRESS_TYPE_ORG')
                                    }
                                }
                            }
                        >
                            Редагування чинного орендаря
                        </Button>
                    </div>
                </>
            )
        }
    }
}

function addressComparison(data){
    let region = data.address.region
    if(region === 'Автономна Республіка Крим' || region === "АВТОНОМНА РЕСПУБЛІКА КРИМ/М.СІМФЕРОПОЛЬ"){
        return "0100000000+:+АВТОНОМНА РЕСПУБЛІКА КРИМ/М.СІМФЕРОПОЛЬ"
    }else if(region === 'Вінницька область' || region === "ВІННИЦЬКА ОБЛАСТЬ/М.ВІННИЦЯ"){
        return "0500000000+:+ВІННИЦЬКА ОБЛАСТЬ/М.ВІННИЦЯ"
    }else if(region === 'Волинська область' || region === "ВОЛИНСЬКА ОБЛАСТЬ/М.ЛУЦЬК"){
        return "0700000000+:+ВОЛИНСЬКА ОБЛАСТЬ/М.ЛУЦЬК"
    }else if(region === 'Дніпропетровська область' || region === "ДНІПРОПЕТРОВСЬКА ОБЛАСТЬ/М.ДНІПРО"){
        return "1200000000+:+ДНІПРОПЕТРОВСЬКА ОБЛАСТЬ/М.ДНІПРО"
    }else if(region === 'Донецька область' || region === "ДОНЕЦЬКА ОБЛАСТЬ/М.ДОНЕЦЬК"){
        return "1400000000+:+ДОНЕЦЬКА ОБЛАСТЬ/М.ДОНЕЦЬК"
    }else if(region === 'Житомирська область' || region === "ЖИТОМИРСЬКА ОБЛАСТЬ/М.ЖИТОМИР"){
        return "1800000000+:+ЖИТОМИРСЬКА ОБЛАСТЬ/М.ЖИТОМИР"
    }else if(region === 'Закарпатська область' || region === "ЗАКАРПАТСЬКА ОБЛАСТЬ/М.УЖГОРОД"){
        return "2100000000+:+ЗАКАРПАТСЬКА ОБЛАСТЬ/М.УЖГОРОД"
    }else if(region === 'Запорізька область' || region === "ЗАПОРІЗЬКА ОБЛАСТЬ/М.ЗАПОРІЖЖЯ"){
        return "2300000000+:+ЗАПОРІЗЬКА ОБЛАСТЬ/М.ЗАПОРІЖЖЯ"
    }else if(region === 'Івано-Франківська область' || region === "ІВАНО-ФРАНКІВСЬКА ОБЛАСТЬ/М.ІВАНО-ФРАНКІВСЬК"){
        return "2600000000+:+ІВАНО-ФРАНКІВСЬКА ОБЛАСТЬ/М.ІВАНО-ФРАНКІВСЬК"
    }else if(region === 'Київська область' || region === "КИЇВСЬКА ОБЛАСТЬ/М.КИЇВ"){
        return "3200000000+:+КИЇВСЬКА ОБЛАСТЬ/М.КИЇВ"
    }else if(region === 'Кіровоградська область' || region === "КІРОВОГРАДСЬКА ОБЛАСТЬ/М.КРОПИВНИЦЬКИЙ"){
        return "3500000000+:+КІРОВОГРАДСЬКА ОБЛАСТЬ/М.КРОПИВНИЦЬКИЙ"
    }else if(region === 'Луганська область' || region === "ЛУГАНСЬКА ОБЛАСТЬ/М.ЛУГАНСЬК"){
        return "4400000000+:+ЛУГАНСЬКА ОБЛАСТЬ/М.ЛУГАНСЬК"
    }else if(region === 'Львівська область' || region === "ЛЬВІВСЬКА ОБЛАСТЬ/М.ЛЬВІВ"){
        return "4600000000+:+ЛЬВІВСЬКА ОБЛАСТЬ/М.ЛЬВІВ"
    }else if(region === 'Миколаївська область' || region === "МИКОЛАЇВСЬКА ОБЛАСТЬ/М.МИКОЛАЇВ"){
        return "4800000000+:+МИКОЛАЇВСЬКА ОБЛАСТЬ/М.МИКОЛАЇВ"
    }else if(region === 'Одеська область' || region === "ОДЕСЬКА ОБЛАСТЬ/М.ОДЕСА"){
        return "5100000000+:+ОДЕСЬКА ОБЛАСТЬ/М.ОДЕСА"
    }else if(region === 'Полтавська область' || region === "ПОЛТАВСЬКА ОБЛАСТЬ/М.ПОЛТАВА"){
        return "5300000000+:+ПОЛТАВСЬКА ОБЛАСТЬ/М.ПОЛТАВА"
    }else if(region === 'Рівненська область' || region === "РІВНЕНСЬКА ОБЛАСТЬ/М.РІВНЕ"){
        return "5600000000+:+РІВНЕНСЬКА ОБЛАСТЬ/М.РІВНЕ"
    }else if(region === 'Сумська область' || region === "СУМСЬКА ОБЛАСТЬ/М.СУМИ"){
        return "5900000000+:+СУМСЬКА ОБЛАСТЬ/М.СУМИ"
    }else if(region === 'Тернопільська область' || region === "ТЕРНОПІЛЬСЬКА ОБЛАСТЬ/М.ТЕРНОПІЛЬ"){
        return "6100000000+:+ТЕРНОПІЛЬСЬКА ОБЛАСТЬ/М.ТЕРНОПІЛЬ"
    }else if(region === 'Харківська область' || region === "ХАРКІВСЬКА ОБЛАСТЬ/М.ХАРКІВ"){
        return "6300000000+:+ХАРКІВСЬКА ОБЛАСТЬ/М.ХАРКІВ"
    }else if(region === 'Херсонська область' || region === "ХЕРСОНСЬКА ОБЛАСТЬ/М.ХЕРСОН"){
        return "6500000000+:+ХЕРСОНСЬКА ОБЛАСТЬ/М.ХЕРСОН"
    }else if(region === 'Хмельницька область' || region === "ХМЕЛЬНИЦЬКА ОБЛАСТЬ/М.ХМЕЛЬНИЦЬКИЙ"){
        return "6800000000+:+ХМЕЛЬНИЦЬКА ОБЛАСТЬ/М.ХМЕЛЬНИЦЬКИЙ"
    }else if(region === 'Черкаська область' || region === "ЧЕРКАСЬКА ОБЛАСТЬ/М.ЧЕРКАСИ"){
        return "7100000000+:+ЧЕРКАСЬКА ОБЛАСТЬ/М.ЧЕРКАСИ"
    }else if(region === 'Чернівецька область' || region === "ЧЕРНІВЕЦЬКА ОБЛАСТЬ/М.ЧЕРНІВЦІ"){
        return "7300000000+:+ЧЕРНІВЕЦЬКА ОБЛАСТЬ/М.ЧЕРНІВЦІ"
    }else if(region === 'Чернігівська область' || region === "ЧЕРНІГІВСЬКА ОБЛАСТЬ/М.ЧЕРНІГІВ"){
        return "7400000000+:+ЧЕРНІГІВСЬКА ОБЛАСТЬ/М.ЧЕРНІГІВ"
    }else if(region === 'Київ' || region === "М.КИЇВ"){
        return "8000000000+:+М.КИЇВ"
    }else if(region === 'Севастополь' || region === "М.СЕВАСТОПОЛЬ"){
        return "8500000000+:+М.СЕВАСТОПОЛЬ"
    }
    return null
}

function redBlock(props, amount){
    if(
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-fast" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-fast-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-fast-auction-manual-qualification" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-auction" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-auction-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-qualification" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-qualification-prod" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-fast" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-fast-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-fast-auction-manual-qualification" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-initial-auction" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-initial-auction-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-initial-qualification-prod" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-auction-manual-qualification" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification-prod"
    ){
        if(amount === null || amount === '' || amount === 'null'){
            return 'error'
        }
        return null
    }
    return null
}

function setOption(props){
    if(props.addressNew.getAddress !== null){
        return props.addressNew.getAddress.map(
            (e, count) => {
                //return <option key={count+1} className={styles.hoverOptions} value={e.id}>{e.name}</option>
                return <option key={count+1} className={styles.hoverOptions} value={e.id}>{e.contactPoint.name}</option>
            }
        )
    }
}

function createLine(props, tmp){
    let tmpRegionOption = null
    if(props.koatuu.region !== null){
        for(let i = 0; i < props.koatuu.region.length; i++){
            if(props.koatuu.region[i].description === tmp){
                tmpRegionOption = `${props.koatuu.region[i].classificationId}+:+${props.koatuu.region[i].description}`
            }
        }
    }
    if(tmpRegionOption !== null){
        props.regionAddress(tmpRegionOption)
        let t = tmpRegionOption.substr(0, 2)
        props.setKoatuuGorod(null)
        props.localityAddress('null')
        getKoatuuGorod(props, t)
    }
}

async function getKoatuuGorod(props, id){
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/classifications/koatuu/byMask?mask=${id}`;
    const response = await fetch(endPoint, {
        method: "GET",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json1 = await response.json()
        if(json1.hasOwnProperty('message') === true){}
        else{
            props.setKoatuuGorod(json1)
        }
        
    }
}

const mapStateToProps = state => {
    return {
        token: state.start.token,
        auctionsMy: state.start.auctionsMy,
        dictionaries: state.start.dictionaries,
        addressNew: state.start.addressNew,
        schedule: state.start.schedule,
        objectReestr: state.start.objectReestr,
        shedullePeriod: state.start.shedullePeriod,
        koatuu: state.start.koatuu,
        auctionsZUAttemp: state.start.auctionsZUAttemp
    }
}

const mapDispatchToProps = {
    changeCreateAuctionMinNumberOfQualifiedBids,
    changeCreateAuctionDgfVirtualDataRoom,
    changeCreateAuctionDgfEligibilityCriteria,
    changeCreateAuctionDgfX_dgfPlatformLegalDetails,
    changeCreateAuctionDgfDecisionId,
    changeCreateAuctionDgfDecisionDate,
    changeCreateAuctionСalcOnBookValue,
    changeCreateAuctionValueAddedTaxCharged,
    changeCreateAuctionRegistryId,
    changeCreateAuctionValuePeriod,
    changeCreateAuctionLeaseRulesIntendedUse,
    changeCreateAuctionLeaseRulesIntendedUseRestrictionMethod,
    changeCreateAuctionLeaseRulesIntendedUseRestrictionDescription,
    changeCreateAuctionLeaseRulesAdditionalLeaseConditions,
    changeCreateAuctionLeaseRulesSubleaseAllowed,
    setDataAddress,

    setEditAuctionFromId,
    changeDiscountPreviousAuctionId,
    changeDiscountPreviousAuctionAmount,
    changeDiscountPercent,

    getMyAuction,
    changeLoader,
}

export default connect(mapStateToProps, mapDispatchToProps)(WindowAuctionDgf)
