import React, {useEffect} from 'react';
import style from './popupAward.module.css';

import {connect} from 'react-redux';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import {
    auctionsGetAwards,
    auctionsGetAwardsId,
    changeAwardRejectPopUpStatus,
    changeAwardErrorPopUpStatus,
    changeAwardRejectTitle,
    changeAwardRejectDescription,
    changeAwardRejectDocumentType,
    changeAwardDocumentStatus,
    changeAwardRejectRestrict,
} from '../../../redux/actions/awards/awards.js';

import {setUniversalError, changeLoader} from '../../../redux/actions.js';

import {
    changeDocumentId,
    changeDocumentTitle,
    changeDocumentDescription,
    changeDocumentLang,
    changeDocumentFileName,
    changeDocumentType
} from '../../../redux/actions/documents/documents.js';
import {
    togetherApiPopUpOnOff,
    togetherApiDocTitle,
    togetherApiDocDescription,
    togetherApiDocType,
    togetherApiDocLang,
    togetherApiDocFileName,
    togetherApiECP,
} from '../../../redux/togetherApi.js';
import {ecpApiPass, ecpApiList, changeCA} from '../../../redux/ecpApi.js';
import {contractsAuctionIdGet} from '../../../redux/actions/contracts/contracts.js';

import ChansellWaitingAward from './buttonsBlockChansellWaitingAuction.js';

import {setDataAddress} from '../../../redux/createAddress';
import {getUpdateDataAuction} from '../../product/updateData'

import {
    isRLE, isRLD, isCLE, isCLD, isBRD, isBRE, isBRW, isALE, isBSD, isBSE, isCSD, isCSE, isLRE, isLSE, isLSP, isAAE,
    isLLE, isLLD, isLLP, isSPE, isSPD, isNLE, isNLD, isLAE, isLAW, isLAP, isLPE, isAPE, isAPD, isREM
} from '../../../redux/procedure';

import { checkProp } from '../../../redux/check';

const ApproveOrRejectAward = ( props ) => {
    useEffect(() => {
        if(isLAE(props.auctions.sellingMethod) || isLAW(props.auctions.sellingMethod) || isLAP(props.auctions.sellingMethod)){
            props.changeAwardRejectRestrict(props.popUpAwardReject.terminationReason)
            
        }    
    }, [])
    

    let tmp = null
    if(props.popUpAwardReject !== null){
        if(props.popUpAwardReject.awards !== null){
            if(props.auctions.awards !== null){
                if(props.auctions.awards.length > 0){
                    let tmpTextSeller = false
                        if(props.auctions.awards[props.popUpAwardReject.awards]?.documents?.length > 0){
                            for(let ty = 0; ty < props.auctions.awards[props.popUpAwardReject.awards].documents.length; ty++){
                                if(props.auctions.awards[props.popUpAwardReject.awards].documents[ty].documentType === 'act'){
                                    if(props.auctions.awards[props.popUpAwardReject.awards].documents[ty].createdByCurrentUser === true){
                                        tmpTextSeller = true
                                    }
                                }
                            }
                        }
                    if(props.auctions.privateData.bids !== null && props.auctions.privateData.bids.length > 0){
                        if(props.auctions.awards[props.popUpAwardReject.awards].bidId === props.auctions.privateData.bids[0].bidId){
                            if(props.auctions.awards[props.popUpAwardReject.awards].status === 'pending_waiting'){
                                tmp = <ChansellWaitingAward />
                            }
                        }
                    }
                    let tmpTextBuyerReject = false
                    let tmpTextBuyerAppruve = false
                    if(props.auctions.awards[props.popUpAwardReject.awards].documents !== null){
                        if(props.auctions.awards[props.popUpAwardReject.awards].documents.length > 0){
                            for(let ty = 0; ty < props.auctions.awards[props.popUpAwardReject.awards].documents.length; ty++){
                                if(props.auctions.awards[props.popUpAwardReject.awards].documents[ty].documentType === 'act' || props.auctions.awards[props.popUpAwardReject.awards].documents[ty].documentType === 'rejectionProtocol'){
                                    tmpTextBuyerReject = true
                                }
                            }
                        }
                    }
                    if(props.auctions.awards[props.popUpAwardReject.awards].documents !== null){
                        if(props.auctions.awards[props.popUpAwardReject.awards].documents.length > 0){
                            for(let ty = 0; ty < props.auctions.awards[props.popUpAwardReject.awards].documents.length; ty++){
                                if(props.auctions.privateData.isOwner === true || (checkProp(props.userProfiles, 'isAdmin') && props.userProfiles.isAdmin === true)){
                                    if(props.auctions.awards[props.popUpAwardReject.awards].documents[ty].documentType === 'auctionProtocol'){
                                        tmpTextBuyerAppruve = true
                                    }
                                }
                            }
                        }
                    }
                    if(props.auctions.privateData.isOwner === true || (checkProp(props.userProfiles, 'isAdmin') && props.userProfiles.isAdmin === true)){
                        let buttonApprove = null
                        let buttonReject = null
                        if(tmpTextBuyerAppruve === true || props.auctions.sellingMethod === "bankRuptcy-withoutAuction"){

                            if(isLPE(props.auctions.sellingMethod)){
                                if(props.popUpAwardReject.status !== "pending_payment") buttonApprove = buttonApproveAuctionAwards(props)
                            }else{
                                buttonApprove = buttonApproveAuctionAwards(props)
                            }
                        }

                        switch(props.auctions.sellingMethod){
                            case "railwayCargo-english":
                            case "railwayCargo-english-fast":
                            case "railwayCargo-english-fast-manual":
                            case "railwayCargo-english-initial-auction":
                            case "railwayCargo-english-initial-qualification":
                            case "railwayCargo-dutch":
                            case "railwayCargo-dutch-fast":
                            case "railwayCargo-dutch-fast-manual":
                            case "railwayCargo-dutch-initial-auction":
                            case "railwayCargo-dutch-initial-qualification":
                            case "railwayCargo-dutch-fast-auction-manual-qualification":
                            case "railwayCargo-dutch-initial-auction-manual":
                                buttonApprove =  <div
                                    className={style.btnAccept}
                                    onClick={
                                        () => {
                                            approveWinerAward(props)
                                        }
                                    }
                                >
                                    Протокол затверджено
                                </div>
                                break;
                            default:
                                break;
                        }
                        tmpTextBuyerReject = statusNullIfStatusAwardCancelledOrunsuccessful(props, tmpTextBuyerReject)
                        if(tmpTextBuyerReject === true){
                            let formName = "Оберіть причину дискваліфікації або відхилення:"
                            if(isAPE(props.auctions.sellingMethod) || isAPD(props.auctions.sellingMethod)) formName = "Оберіть причину дискваліфікації/відмови учаснику:"
                            buttonReject = <>
                                <div className={style.rejectSelect}>{formName}</div>
                                <div className={style.selectWrap}>
                                    <select
                                        className={style.selectTypeFile}
                                        value={props.awardReject.restrict}
                                        placeholder='Причина діскваліфікації'
                                        onChange={
                                            (event)=>{
                                                props.changeAwardRejectRestrict(event.target.value)
                                            }
                                        }
                                    >
                                        {reasonOption(props)}
                                    </select>
                                </div>
                                {buttonRejectZemlyaSave(props)}
                                {buttonRejectZemlya(props)}
                            </>
                        }
                        tmp = <div className={style.bottomPart}>
                            {buttonReject}
                            {buttonApprove}
                        </div>
                    }
                }
            }
        }
    }

    return <>
            {tmp}
        </>
}

function reasonOption(props){
    if(
        isBSE(props.auctions.sellingMethod) || isBSD(props.auctions.sellingMethod) ||
        isALE(props.auctions.sellingMethod) ||
        isCSE(props.auctions.sellingMethod) || isCSD(props.auctions.sellingMethod) ||
        isRLE(props.auctions.sellingMethod) || isRLD(props.auctions.sellingMethod) ||
        isCLE(props.auctions.sellingMethod) || isCLD(props.auctions.sellingMethod)
    ){
        return (
            <>
                <option key='null' selected disabled value="null">Обрати</option>
                <option value='1'>Відмова або непідписання протоколу</option>
                <option value='2'>Відмова або непідписання договору</option>
                <option value='3'>Відсутні обов’язкові документи</option>
                <option value='4'>Невнесення передоплати або оплати за договором</option>
                <option value='5'>Участь в аукціоні з більше, ніж 1 майданчика</option>
            </>
        )
    }else if(isSPE(props.auctions.sellingMethod) || isSPD(props.auctions.sellingMethod)){
        return (
            <>
                <option key='null' selected disabled value="null">Обрати</option>
                <option value='1'>Не відповідає вимогам статті 8 ЗУ "Про приватизацію державного і комунального майна"</option>
                <option value='2'>Не подав документи або відомості, обов’язкове подання яких передбачено ЗУ "Про приватизацію державного і комунального майна"</option>
                <option value='3'>Подав неправдиві відомості про себе</option>
                <option value='4'>Відмовився від підписання протоколу про результати електронного аукціону</option>
                <option value='5'>Відмовився від укладення договору</option>
                <option value='6'>Відмовився від підписання протоколу аукціону або договору купівлі-продажу в аукціоні з продажу того самого об’єкта приватизації, що підтверджується відповідним актом"</option>
                <option value='7'>Не сплатив ціну продажу об'єкта приватизації у встановлений строк</option>
                <option value='8'>Не сплатив ціну продажу об’єкта приватизації у встановлений строк щодо того самого об’єкта приватизації, що підтверджується відповідним актом</option>
            </>
        )
    }else if(isBRE(props.auctions.sellingMethod) || isBRD(props.auctions.sellingMethod) || isBRW(props.auctions.sellingMethod)){
        return (
            <>
                <option key='null' selected disabled value="null">Обрати</option>
                <option value='1'>Відмова або непідписання протоколу</option>
                <option value='2'>Відмова або непідписання акту</option>
                <option value='3'>Невнесення оплати за лот</option>
            </>
        )
    }else if(isLLE(props.auctions.sellingMethod) || isLLD(props.auctions.sellingMethod) || isLLP(props.auctions.sellingMethod)){
        return (
            <>
                <option key='null' selected disabled value="null">Обрати</option>
                <option value='1'>Непідписання протоколу</option>
                <option value='2'>Непідписання договору учасником</option>
                <option value='3'>Невідповідність вимогам статті 4 ЗУ</option>
                <option value='4'>Неподання обов'язкових документів або відомостей</option>
                <option value='5'>Подання неправдивих відомостей</option>
                <option value='6'>Судові рішення та інші підстави</option>
                <option value='7'>Непідписання договору орендодавцем</option>
            </>
        )
    }else if(isNLE(props.auctions.sellingMethod) || isNLD(props.auctions.sellingMethod)){
        return (
            <>
                <option key='null' selected disabled value="null">Обрати</option>
                <option value='1'>Відмовився від підписання договору/протоколу</option>
                <option value='2'>Переможець аукціону є боржником та/або поручителем за кредитними договорами та договорами забезпечення виконання зобов'язань</option>
                <option value='3'>Свідомо надав неправдиву інформацію</option>
                <option value='4'>Повна оплата коштів за лот не здійснена в строк/відмовився від оплати</option>
                <option value='5'>Переможець аукціону брав участь в аукціонах з кількох майданчиків</option>
                <option value='6'>Інше</option>
            </>
        )
    }else if(isLAE(props.auctions.sellingMethod) || isLAW(props.auctions.sellingMethod) || isLAP(props.auctions.sellingMethod)){
        return (
            <>
                <option key='null' selected disabled value="null">Обрати</option>
                <option value='1'>Відмовився від підписання протоколу</option>
                <option value='2'>Відмовився від укладання акту/договору</option>
                <option value='3'>Не сплатив належну суму за придбаний лот та/або суму забезпечувального платежу</option>
                <option value='4'>Організатор не підписав протокол</option>
                <option value='5'>Організатор не підписав акт/договір</option>
            </>
        )
    }else if(isLPE(props.auctions.sellingMethod)){
        return (
            <>
                <option key='null' selected disabled value="null">Обрати</option>
                <option value='1'>Не відповідає вимогам статті 8ЗУ "Про приватизацію державного і комунального майна"</option>
                <option value='2'>Не подав документи або відомості, обов’язкове подання яких передбачено ЗУ "Про приватизацію державного і комунального майна"</option>
                <option value='3'>Подав неправдиві відомості про себе</option>
                <option value='4'>Відмовився від підписання договору або  протоколу про результати електронного аукціону</option>
                <option value='5'>Відмовився від підписання протоколу аукціону або договору купівлі-продажу в аукціоні з продажу того самого об’єкта приватизації, що підтверджується відповідним актом</option>
                <option value='6'>Є кінцевим бенефіціарним власником учасника, що відмовився  від підписання протоколу аукціону або договору в аукціоні з продажу того самого об’єкта приватизації, що підтверджується відповідним актом</option>
                <option value='7'>Не сплатив ціну продажу об’єкта приватизації у встановлений строк щодо того самого об’єкта приватизації, що підтверджується відповідним актом</option>
                <option value='8'>Є пов’язаною особою з іншим потенційним покупцем, який брав участь в аукціоні</option>
            </>
        )
    }else if(isAPE(props.auctions.sellingMethod) || isAPD(props.auctions.sellingMethod)){
        return (
            <>
                <option key='null' selected disabled value="null">Обрати</option>
                <option value='1'>Є працівником організатора торгів</option>
                <option value='2'>Не подав документи або відомості, обов’язкове подання яких передбачено цим Порядком</option>
                <option value='3'>Подав неправдиві відомості про себе</option>
                <option value='4'>Вже перемагав у попередніх аукціонах по цьому лоту, де не підписав протокол чи договір, не сплатив за лот вчасно або не в повному обсязі</option>
                <option value='5'>Не може бути покупцем згідно з пунктом 12 цього Порядку</option>
                <option value='6'>Відмовився від підписання протоколу</option>
                <option value='7'>Відмовився від підписання договору</option>
                <option value='8'>Не сплатив ціну продажу лота у необхідні строки</option>
            </>
        )
    }else{
        return (
            <>
                <option key='null' selected disabled value="null">Обрати</option>
                <option value='1'>Відмовився від підписання протоколу</option>
                <option value='2'>Відмовився від укладання договору</option>
                <option value='3'>Не надав обов’язкові документи або відомості /надав неправдиві відомості</option>
                <option value='4'>Не сплатив належну суму за придбаний лот та/або суму витрат на підготовку лота до продажу</option>
                <option value='5'>Не відповідає встановленим Кодексом вимогам</option>
                <option value='6'>Не отримав необхідне погодження ВРУ або КМУ</option>
            </>
        )
    }
}


function statusNullIfStatusAwardCancelledOrunsuccessful(props, data){
    if(props.auctions.awards[props.popUpAwardReject.awards].status === 'cancelled' || props.auctions.awards[props.popUpAwardReject.awards].status === 'unsuccessful '){
        return null
    }
    return data
}

function buttonRedjectAndCancell(props){
    let tmp = null
    if(props.auctionAwards[props.popUpAwardReject.awards].status !== 'pending_waiting'){
                    if(props.auctionAwards.length === 1){
                        if(props.auctionAwards[props.popUpAwardReject.awards].status === 'active'){
                            let tmpSellingMethod = props.auctionAwards[props.popUpAwardReject.awards].auction.sellingMethod.indexOf('railwayCargo-english')
                            if( tmpSellingMethod === -1){
                                if(
                                    props.auctionAwards[props.popUpAwardReject.awards].auction.sellingMethod === 'landRental-english' ||
                                    props.auctionAwards[props.popUpAwardReject.awards].auction.sellingMethod === 'landRental-english' ||
                                    props.auctionAwards[props.popUpAwardReject.awards].auction.sellingMethod === 'landRental-english-fast' ||
                                    props.auctionAwards[props.popUpAwardReject.awards].auction.sellingMethod === 'landRental-english-fast-manual' ||
                                    props.auctionAwards[props.popUpAwardReject.awards].auction.sellingMethod === 'landRental-english-fast-auction-manual-qualification' ||
                                    props.auctionAwards[props.popUpAwardReject.awards].auction.sellingMethod === 'landRental-english-initial-auction' ||
                                    props.auctionAwards[props.popUpAwardReject.awards].auction.sellingMethod === 'landRental-english-initial-auction-manual' ||
                                    props.auctionAwards[props.popUpAwardReject.awards].auction.sellingMethod === 'landRental-english-initial-qualification' ||
                                    props.auctionAwards[props.popUpAwardReject.awards].auction.sellingMethod === 'landRental-english-initial-qualification-prod' ||
                                    props.auctionAwards[props.popUpAwardReject.awards].auction.sellingMethod === 'landSell-english' ||
                                    props.auctionAwards[props.popUpAwardReject.awards].auction.sellingMethod === 'landSell-english-fast' ||
                                    props.auctionAwards[props.popUpAwardReject.awards].auction.sellingMethod === 'landSell-english-fast-manual' ||
                                    props.auctionAwards[props.popUpAwardReject.awards].auction.sellingMethod === 'landSell-english-fast-auction-manual-qualification' ||
                                    props.auctionAwards[props.popUpAwardReject.awards].auction.sellingMethod === 'landSell-english-initial-auction' ||
                                    props.auctionAwards[props.popUpAwardReject.awards].auction.sellingMethod === 'landSell-english-initial-auction-manual' ||
                                    props.auctionAwards[props.popUpAwardReject.awards].auction.sellingMethod === 'landSell-english-initial-qualification' ||
                                    props.auctionAwards[props.popUpAwardReject.awards].auction.sellingMethod === 'landSell-english-initial-qualification-prod' ||
                                    props.auctionAwards[props.popUpAwardReject.awards].auction.sellingMethod === 'landSell-priorityEnglish' ||
                                    props.auctionAwards[props.popUpAwardReject.awards].auction.sellingMethod === 'landSell-priorityEnglish-fast' ||
                                    props.auctionAwards[props.popUpAwardReject.awards].auction.sellingMethod === 'landSell-priorityEnglish-fast-manual' ||
                                    props.auctionAwards[props.popUpAwardReject.awards].auction.sellingMethod === 'landSell-priorityEnglish-fast-auction-manual-qualification' ||
                                    props.auctionAwards[props.popUpAwardReject.awards].auction.sellingMethod === 'landSell-priorityEnglish-initial-auction' ||
                                    props.auctionAwards[props.popUpAwardReject.awards].auction.sellingMethod === 'landSell-priorityEnglish-initial-auction-manual' ||
                                    props.auctionAwards[props.popUpAwardReject.awards].auction.sellingMethod === 'landSell-priorityEnglish-initial-qualification' ||
                                    props.auctionAwards[props.popUpAwardReject.awards].auction.sellingMethod === 'landSell-priorityEnglish-initial-qualification-prod'
                                ){
                                    tmp = <>
                                        <div className={style.rejectSelect}>Оберіть причину дискваліфікації або відхилення:</div>
                                        <div className={style.selectWrap}>
                                            <select
                                                className={style.selectTypeFile}
                                                value={props.awardReject.restrict}
                                                placeholder='Причина діскваліфікації'
                                                onChange={
                                                    (event)=>{
                                                        props.changeAwardRejectRestrict(event.target.value)
                                                    }
                                                }
                                            >
                                                <option key='null' selected disabled value="null">Обрати</option>
                                                <option value='1'>Відмовився від підписання протоколу</option>
                                                <option value='2'>Відмовився від укладання договору</option>
                                                <option value='3'>Не надав обов’язкові документи або відомості /надав неправдиві відомості</option>
                                                <option value='4'>Не сплатив належну суму за придбаний лот та/або суму витрат на підготовку лота до продажу</option>
                                                <option value='5'>Не відповідає встановленим Кодексом вимогам</option>
                                                <option value='6'>Не отримав необхідне погодження ВРУ або КМУ</option>
                                            </select>
                                        </div>
                                        {buttonRejectZemlya(props)}
                                    </>
                                }else{
                                    tmp = <>
                                        {buttonCancelled(props)}
                                        {buttonReject(props)}
                                    </>
                                }
                            }else{
                                if(
                                    props.auctionAwards[props.popUpAwardReject.awards].auction.sellingMethod === "legitimatePropertyLease-english" ||
                                    props.auctionAwards[props.popUpAwardReject.awards].auction.sellingMethod === "legitimatePropertyLease-english-fast" ||
                                    props.auctionAwards[props.popUpAwardReject.awards].auction.sellingMethod === "legitimatePropertyLease-english-fast-manual" ||
                                    props.auctionAwards[props.popUpAwardReject.awards].auction.sellingMethod === "legitimatePropertyLease-english-fast-auction-manual-qualification" ||
                                    props.auctionAwards[props.popUpAwardReject.awards].auction.sellingMethod === "legitimatePropertyLease-english-initial-auction" ||
                                    props.auctionAwards[props.popUpAwardReject.awards].auction.sellingMethod === "legitimatePropertyLease-english-initial-auction-manual" ||
                                    props.auctionAwards[props.popUpAwardReject.awards].auction.sellingMethod === "legitimatePropertyLease-english-initial-qualification" ||
                                    props.auctionAwards[props.popUpAwardReject.awards].auction.sellingMethod === "legitimatePropertyLease-english-initial-qualification-prod" ||
                                    props.auctionAwards[props.popUpAwardReject.awards].auction.sellingMethod === "legitimatePropertyLease-dutch" ||
                                    props.auctionAwards[props.popUpAwardReject.awards].auction.sellingMethod === "legitimatePropertyLease-dutch-fast" ||
                                    props.auctionAwards[props.popUpAwardReject.awards].auction.sellingMethod === "legitimatePropertyLease-dutch-fast-manual" ||
                                    props.auctionAwards[props.popUpAwardReject.awards].auction.sellingMethod === "legitimatePropertyLease-dutch-fast-auction-manual-qualification" ||
                                    props.auctionAwards[props.popUpAwardReject.awards].auction.sellingMethod === "legitimatePropertyLease-dutch-initial-auction" ||
                                    props.auctionAwards[props.popUpAwardReject.awards].auction.sellingMethod === "legitimatePropertyLease-dutch-initial-auction-manual" ||
                                    props.auctionAwards[props.popUpAwardReject.awards].auction.sellingMethod === "legitimatePropertyLease-dutch-initial-qualification-prod" ||
                                    props.auctionAwards[props.popUpAwardReject.awards].auction.sellingMethod === "legitimatePropertyLease-priorityEnglish" ||
                                    props.auctionAwards[props.popUpAwardReject.awards].auction.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast" ||
                                    props.auctionAwards[props.popUpAwardReject.awards].auction.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-manual" ||
                                    props.auctionAwards[props.popUpAwardReject.awards].auction.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-auction-manual-qualification" ||
                                    props.auctionAwards[props.popUpAwardReject.awards].auction.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction" ||
                                    props.auctionAwards[props.popUpAwardReject.awards].auction.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction-manual" ||
                                    props.auctionAwards[props.popUpAwardReject.awards].auction.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification" ||
                                    props.auctionAwards[props.popUpAwardReject.awards].auction.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification-prod"
                                ){
                                    tmp = <>
                                            <div className={style.selectWrap}>
                                                <select
                                                    className={style.selectTypeFile}
                                                    value={props.awardReject.restrict}
                                                    placeholder='Причина діскваліфікації'
                                                    onChange={
                                                        (event)=>{
                                                            props.changeAwardRejectRestrict(event.target.value)
                                                        }
                                                    }
                                                >
                                                    <option key='null' selected disabled value="null">Обрати</option>
                                                    <option value='1'>Непідписання протоколу</option>
                                                    <option value='2'>Непідписання договору</option>
                                                    <option value='3'>Невідповідність вимогам статті 4 ЗУ</option>
                                                    <option value='4'>Неподання обов'язкових документів або відомостей</option>
                                                    <option value='5'>Подання неправдивих відомостей</option>
                                                    <option value='6'>Судові рішення та інші підстави</option>
                                                </select>
                                            </div>
                                            {viewTypeButtons(props)}
                                    </>
                                }else{
                                    tmp = viewTypeButtons(props)
                                }
                            }
                        }else{
                            if(
                                props.auctionAwards[props.popUpAwardReject.awards].auction.sellingMethod === "legitimatePropertyLease-english" ||
                                props.auctionAwards[props.popUpAwardReject.awards].auction.sellingMethod === "legitimatePropertyLease-english-fast" ||
                                props.auctionAwards[props.popUpAwardReject.awards].auction.sellingMethod === "legitimatePropertyLease-english-fast-manual" ||
                                props.auctionAwards[props.popUpAwardReject.awards].auction.sellingMethod === "legitimatePropertyLease-english-fast-auction-manual-qualification" ||
                                props.auctionAwards[props.popUpAwardReject.awards].auction.sellingMethod === "legitimatePropertyLease-english-initial-auction" ||
                                props.auctionAwards[props.popUpAwardReject.awards].auction.sellingMethod === "legitimatePropertyLease-english-initial-auction-manual" ||
                                props.auctionAwards[props.popUpAwardReject.awards].auction.sellingMethod === "legitimatePropertyLease-english-initial-qualification" ||
                                props.auctionAwards[props.popUpAwardReject.awards].auction.sellingMethod === "legitimatePropertyLease-english-initial-qualification-prod" ||
                                props.auctionAwards[props.popUpAwardReject.awards].auction.sellingMethod === "legitimatePropertyLease-dutch" ||
                                props.auctionAwards[props.popUpAwardReject.awards].auction.sellingMethod === "legitimatePropertyLease-dutch-fast" ||
                                props.auctionAwards[props.popUpAwardReject.awards].auction.sellingMethod === "legitimatePropertyLease-dutch-fast-manual" ||
                                props.auctionAwards[props.popUpAwardReject.awards].auction.sellingMethod === "legitimatePropertyLease-dutch-fast-auction-manual-qualification" ||
                                props.auctionAwards[props.popUpAwardReject.awards].auction.sellingMethod === "legitimatePropertyLease-dutch-initial-auction" ||
                                props.auctionAwards[props.popUpAwardReject.awards].auction.sellingMethod === "legitimatePropertyLease-dutch-initial-auction-manual" ||
                                props.auctionAwards[props.popUpAwardReject.awards].auction.sellingMethod === "legitimatePropertyLease-dutch-initial-qualification-prod" ||
                                props.auctionAwards[props.popUpAwardReject.awards].auction.sellingMethod === "legitimatePropertyLease-priorityEnglish" ||
                                props.auctionAwards[props.popUpAwardReject.awards].auction.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast" ||
                                props.auctionAwards[props.popUpAwardReject.awards].auction.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-manual" ||
                                props.auctionAwards[props.popUpAwardReject.awards].auction.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-auction-manual-qualification" ||
                                props.auctionAwards[props.popUpAwardReject.awards].auction.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction" ||
                                props.auctionAwards[props.popUpAwardReject.awards].auction.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction-manual" ||
                                props.auctionAwards[props.popUpAwardReject.awards].auction.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification" ||
                                props.auctionAwards[props.popUpAwardReject.awards].auction.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification-prod"
                            ){
                                tmp = <>
                                        <div className={style.selectWrap}>
                                            <select
                                                className={style.selectTypeFile}
                                                value={props.awardReject.restrict}
                                                placeholder='Причина діскваліфікації'
                                                onChange={
                                                    (event)=>{
                                                        props.changeAwardRejectRestrict(event.target.value)
                                                    }
                                                }
                                            >
                                                <option key='null' selected disabled value="null">Обрати</option>
                                                <option value='1'>Непідписання протоколу</option>
                                                <option value='2'>Непідписання договору</option>
                                                <option value='3'>Невідповідність вимогам статті 4 ЗУ</option>
                                                <option value='4'>Неподання обов'язкових документів або відомостей</option>
                                                <option value='5'>Подання неправдивих відомостей</option>
                                                <option value='6'>Судові рішення та інші підстави</option>
                                            </select>
                                        </div>
                                        {buttonRejectMultyAward(props)}
                                </>
                            }else{
                                tmp = viewTypeButtons(props)
                            }
                        }
                    }else{
                        if(props.auctionAwards[props.popUpAwardReject.awards].status === 'active'){
                            if(
                                props.auctionAwards[props.popUpAwardReject.awards].auction.sellingMethod === "legitimatePropertyLease-english" ||
                                props.auctionAwards[props.popUpAwardReject.awards].auction.sellingMethod === "legitimatePropertyLease-english-fast" ||
                                props.auctionAwards[props.popUpAwardReject.awards].auction.sellingMethod === "legitimatePropertyLease-english-fast-manual" ||
                                props.auctionAwards[props.popUpAwardReject.awards].auction.sellingMethod === "legitimatePropertyLease-english-fast-auction-manual-qualification" ||
                                props.auctionAwards[props.popUpAwardReject.awards].auction.sellingMethod === "legitimatePropertyLease-english-initial-auction" ||
                                props.auctionAwards[props.popUpAwardReject.awards].auction.sellingMethod === "legitimatePropertyLease-english-initial-auction-manual" ||
                                props.auctionAwards[props.popUpAwardReject.awards].auction.sellingMethod === "legitimatePropertyLease-english-initial-qualification" ||
                                props.auctionAwards[props.popUpAwardReject.awards].auction.sellingMethod === "legitimatePropertyLease-english-initial-qualification-prod" ||
                                props.auctionAwards[props.popUpAwardReject.awards].auction.sellingMethod === "legitimatePropertyLease-dutch" ||
                                props.auctionAwards[props.popUpAwardReject.awards].auction.sellingMethod === "legitimatePropertyLease-dutch-fast" ||
                                props.auctionAwards[props.popUpAwardReject.awards].auction.sellingMethod === "legitimatePropertyLease-dutch-fast-manual" ||
                                props.auctionAwards[props.popUpAwardReject.awards].auction.sellingMethod === "legitimatePropertyLease-dutch-fast-auction-manual-qualification" ||
                                props.auctionAwards[props.popUpAwardReject.awards].auction.sellingMethod === "legitimatePropertyLease-dutch-initial-auction" ||
                                props.auctionAwards[props.popUpAwardReject.awards].auction.sellingMethod === "legitimatePropertyLease-dutch-initial-auction-manual" ||
                                props.auctionAwards[props.popUpAwardReject.awards].auction.sellingMethod === "legitimatePropertyLease-dutch-initial-qualification-prod" ||
                                props.auctionAwards[props.popUpAwardReject.awards].auction.sellingMethod === "legitimatePropertyLease-priorityEnglish" ||
                                props.auctionAwards[props.popUpAwardReject.awards].auction.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast" ||
                                props.auctionAwards[props.popUpAwardReject.awards].auction.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-manual" ||
                                props.auctionAwards[props.popUpAwardReject.awards].auction.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-auction-manual-qualification" ||
                                props.auctionAwards[props.popUpAwardReject.awards].auction.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction" ||
                                props.auctionAwards[props.popUpAwardReject.awards].auction.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction-manual" ||
                                props.auctionAwards[props.popUpAwardReject.awards].auction.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification" ||
                                props.auctionAwards[props.popUpAwardReject.awards].auction.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification-prod"
                            ){
                                tmp = <>
                                        <div className={style.selectWrap}>
                                            <select
                                                className={style.selectTypeFile}
                                                value={props.awardReject.restrict}
                                                placeholder='Причина діскваліфікації'
                                                onChange={
                                                    (event)=>{
                                                        props.changeAwardRejectRestrict(event.target.value)
                                                    }
                                                }
                                            >
                                                <option key='null' selected disabled value="null">Обрати</option>
                                                <option value='1'>Непідписання протоколу</option>
                                                <option value='2'>Непідписання договору</option>
                                                <option value='3'>Невідповідність вимогам статті 4 ЗУ</option>
                                                <option value='4'>Неподання обов'язкових документів або відомостей</option>
                                                <option value='5'>Подання неправдивих відомостей</option>
                                                <option value='6'>Судові рішення та інші підстави</option>
                                            </select>
                                        </div>
                                        {buttonRejectWinerAward(props, props.auctionAwards[props.popUpAwardReject.awards].auction.contracts[0].id)}
                                </>
                            }else{
                                tmp = buttonRejectWinerAward(props, props.auctionAwards[props.popUpAwardReject.awards].auction.contracts[0].id)
                            }
                        }else{
                            if(
                                props.auctionAwards[props.popUpAwardReject.awards].auction.sellingMethod === "legitimatePropertyLease-english" ||
                                props.auctionAwards[props.popUpAwardReject.awards].auction.sellingMethod === "legitimatePropertyLease-english-fast" ||
                                props.auctionAwards[props.popUpAwardReject.awards].auction.sellingMethod === "legitimatePropertyLease-english-fast-manual" ||
                                props.auctionAwards[props.popUpAwardReject.awards].auction.sellingMethod === "legitimatePropertyLease-english-fast-auction-manual-qualification" ||
                                props.auctionAwards[props.popUpAwardReject.awards].auction.sellingMethod === "legitimatePropertyLease-english-initial-auction" ||
                                props.auctionAwards[props.popUpAwardReject.awards].auction.sellingMethod === "legitimatePropertyLease-english-initial-auction-manual" ||
                                props.auctionAwards[props.popUpAwardReject.awards].auction.sellingMethod === "legitimatePropertyLease-english-initial-qualification" ||
                                props.auctionAwards[props.popUpAwardReject.awards].auction.sellingMethod === "legitimatePropertyLease-english-initial-qualification-prod" ||
                                props.auctionAwards[props.popUpAwardReject.awards].auction.sellingMethod === "legitimatePropertyLease-dutch" ||
                                props.auctionAwards[props.popUpAwardReject.awards].auction.sellingMethod === "legitimatePropertyLease-dutch-fast" ||
                                props.auctionAwards[props.popUpAwardReject.awards].auction.sellingMethod === "legitimatePropertyLease-dutch-fast-manual" ||
                                props.auctionAwards[props.popUpAwardReject.awards].auction.sellingMethod === "legitimatePropertyLease-dutch-fast-auction-manual-qualification" ||
                                props.auctionAwards[props.popUpAwardReject.awards].auction.sellingMethod === "legitimatePropertyLease-dutch-initial-auction" ||
                                props.auctionAwards[props.popUpAwardReject.awards].auction.sellingMethod === "legitimatePropertyLease-dutch-initial-auction-manual" ||
                                props.auctionAwards[props.popUpAwardReject.awards].auction.sellingMethod === "legitimatePropertyLease-dutch-initial-qualification-prod" ||
                                props.auctionAwards[props.popUpAwardReject.awards].auction.sellingMethod === "legitimatePropertyLease-priorityEnglish" ||
                                props.auctionAwards[props.popUpAwardReject.awards].auction.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast" ||
                                props.auctionAwards[props.popUpAwardReject.awards].auction.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-manual" ||
                                props.auctionAwards[props.popUpAwardReject.awards].auction.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-auction-manual-qualification" ||
                                props.auctionAwards[props.popUpAwardReject.awards].auction.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction" ||
                                props.auctionAwards[props.popUpAwardReject.awards].auction.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction-manual" ||
                                props.auctionAwards[props.popUpAwardReject.awards].auction.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification" ||
                                props.auctionAwards[props.popUpAwardReject.awards].auction.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification-prod"
                            ){
                                tmp = <>
                                        <div className={style.selectWrap}>
                                            <select
                                                className={style.selectTypeFile}
                                                value={props.awardReject.restrict}
                                                placeholder='Причина діскваліфікації'
                                                onChange={
                                                    (event)=>{
                                                        props.changeAwardRejectRestrict(event.target.value)
                                                    }
                                                }
                                            >
                                                <option key='null' selected disabled value="null">Обрати</option>
                                                <option value='1'>Непідписання протоколу</option>
                                                <option value='2'>Непідписання договору</option>
                                                <option value='3'>Невідповідність вимогам статті 4 ЗУ</option>
                                                <option value='4'>Неподання обов'язкових документів або відомостей</option>
                                                <option value='5'>Подання неправдивих відомостей</option>
                                                <option value='6'>Судові рішення та інші підстави</option>
                                            </select>
                                        </div>
                                        {buttonRejectMultyAward(props)}
                                </>
                            }else if(
                                props.auctionAwards[props.popUpAwardReject.awards].auction.sellingMethod === 'landRental-english' ||
                                props.auctionAwards[props.popUpAwardReject.awards].auction.sellingMethod === 'landRental-english-fast' ||
                                props.auctionAwards[props.popUpAwardReject.awards].auction.sellingMethod === 'landRental-english-fast-manual' ||
                                props.auctionAwards[props.popUpAwardReject.awards].auction.sellingMethod === 'landRental-english-fast-auction-manual-qualification' ||
                                props.auctionAwards[props.popUpAwardReject.awards].auction.sellingMethod === 'landRental-english-initial-auction' ||
                                props.auctionAwards[props.popUpAwardReject.awards].auction.sellingMethod === 'landRental-english-initial-auction-manual' ||
                                props.auctionAwards[props.popUpAwardReject.awards].auction.sellingMethod === 'landRental-english-initial-qualification' ||
                                props.auctionAwards[props.popUpAwardReject.awards].auction.sellingMethod === 'landRental-english-initial-qualification-prod' ||
                                props.auctionAwards[props.popUpAwardReject.awards].auction.sellingMethod === 'landSell-english' ||
                                props.auctionAwards[props.popUpAwardReject.awards].auction.sellingMethod === 'landSell-english-fast' ||
                                props.auctionAwards[props.popUpAwardReject.awards].auction.sellingMethod === 'landSell-english-fast-manual' ||
                                props.auctionAwards[props.popUpAwardReject.awards].auction.sellingMethod === 'landSell-english-fast-auction-manual-qualification' ||
                                props.auctionAwards[props.popUpAwardReject.awards].auction.sellingMethod === 'landSell-english-initial-auction' ||
                                props.auctionAwards[props.popUpAwardReject.awards].auction.sellingMethod === 'landSell-english-initial-auction-manual' ||
                                props.auctionAwards[props.popUpAwardReject.awards].auction.sellingMethod === 'landSell-english-initial-qualification' ||
                                props.auctionAwards[props.popUpAwardReject.awards].auction.sellingMethod === 'landSell-english-initial-qualification-prod' ||
                                props.auctionAwards[props.popUpAwardReject.awards].auction.sellingMethod === 'landSell-priorityEnglish' ||
                                props.auctionAwards[props.popUpAwardReject.awards].auction.sellingMethod === 'landSell-priorityEnglish-fast' ||
                                props.auctionAwards[props.popUpAwardReject.awards].auction.sellingMethod === 'landSell-priorityEnglish-fast-manual' ||
                                props.auctionAwards[props.popUpAwardReject.awards].auction.sellingMethod === 'landSell-priorityEnglish-fast-auction-manual-qualification' ||
                                props.auctionAwards[props.popUpAwardReject.awards].auction.sellingMethod === 'landSell-priorityEnglish-initial-auction' ||
                                props.auctionAwards[props.popUpAwardReject.awards].auction.sellingMethod === 'landSell-priorityEnglish-initial-auction-manual' ||
                                props.auctionAwards[props.popUpAwardReject.awards].auction.sellingMethod === 'landSell-priorityEnglish-initial-qualification' ||
                                props.auctionAwards[props.popUpAwardReject.awards].auction.sellingMethod === 'landSell-priorityEnglish-initial-qualification-prod'
                            ){
                                tmp = <>
                                        <div className={style.rejectSelect}>Оберіть причину дискваліфікації або відхилення:</div>
                                        <div className={style.selectWrap}>
                                            <select
                                                className={style.selectTypeFile}
                                                value={props.awardReject.restrict}
                                                placeholder='Причина діскваліфікації'
                                                onChange={
                                                    (event)=>{
                                                        props.changeAwardRejectRestrict(event.target.value)
                                                    }
                                                }
                                            >
                                                <option key='null' selected disabled value="null">Обрати</option>
                                                <option value='1'>Відмовився від підписання протоколу</option>
                                                <option value='2'>Відмовився від укладання договору</option>
                                                <option value='3'>Не надав обов’язкові документи або відомості /надав неправдиві відомості</option>
                                                <option value='4'>Не сплатив належну суму за придбаний лот та/або суму витрат на підготовку лота до продажу</option>
                                                <option value='5'>Не відповідає встановленим Кодексом вимогам</option>
                                                <option value='6'>Не отримав необхідне погодження ВРУ або КМУ</option>
                                            </select>
                                        </div>
                                        {buttonRejectZemlya(props)}
                                </>
                            }else{
                                tmp = <>
                                        <div className={style.selectWrap}>
                                            <select
                                                className={style.selectTypeFile}
                                                value={props.awardReject.restrict}
                                                placeholder='Причина діскваліфікації'
                                                onChange={
                                                    (event)=>{
                                                        props.changeAwardRejectRestrict(event.target.value)
                                                    }
                                                }
                                            >
                                                <option key='null' selected disabled value="null">Обрати</option>
                                                <option value='1'>Відмовився від підписання протоколу</option>
                                                <option value='2'>Невідповідність встановленим вимогам</option>
                                                <option value='3'>Відмовився від підписання договору купівлі-продажу лота</option>
                                                <option value='4'>Не підписав договір купівлі-продажу лота в установлений строк</option>
                                                <option value='5'>Вчасно не здійснив передоплату за договором купівлі-продажу лота</option>
                                            </select>
                                        </div>
                                        {buttonRejectMultyAward(props)}
                                </>
                            }
                        }
                    }

    }
    return tmp
}

function buttonCancelled(props){
    if(
        props.auctions.sellingMethod !== "legitimatePropertyLease-english" &&
        props.auctions.sellingMethod !== "legitimatePropertyLease-english-fast" &&
        props.auctions.sellingMethod !== "legitimatePropertyLease-english-fast-manual" &&
        props.auctions.sellingMethod !== "legitimatePropertyLease-english-fast-auction-manual-qualification" &&
        props.auctions.sellingMethod !== "legitimatePropertyLease-english-initial-auction" &&
        props.auctions.sellingMethod !== "legitimatePropertyLease-english-initial-auction-manual" &&
        props.auctions.sellingMethod !== "legitimatePropertyLease-english-initial-qualification" &&
        props.auctions.sellingMethod !== "legitimatePropertyLease-english-initial-qualification-prod" &&
        props.auctions.sellingMethod !== "legitimatePropertyLease-dutch" &&
        props.auctions.sellingMethod !== "legitimatePropertyLease-dutch-fast" &&
        props.auctions.sellingMethod !== "legitimatePropertyLease-dutch-fast-manual" &&
        props.auctions.sellingMethod !== "legitimatePropertyLease-dutch-fast-auction-manual-qualification" &&
        props.auctions.sellingMethod !== "legitimatePropertyLease-dutch-initial-auction" &&
        props.auctions.sellingMethod !== "legitimatePropertyLease-dutch-initial-auction-manual" &&
        props.auctions.sellingMethod !== "legitimatePropertyLease-dutch-initial-qualification-prod" &&
        props.auctions.sellingMethod !== "legitimatePropertyLease-priorityEnglish" &&
        props.auctions.sellingMethod !== "legitimatePropertyLease-priorityEnglish-fast" &&
        props.auctions.sellingMethod !== "legitimatePropertyLease-priorityEnglish-fast-manual" &&
        props.auctions.sellingMethod !== "legitimatePropertyLease-priorityEnglish-fast-auction-manual-qualification" &&
        props.auctions.sellingMethod !== "legitimatePropertyLease-priorityEnglish-initial-auction" &&
        props.auctions.sellingMethod !== "legitimatePropertyLease-priorityEnglish-initial-auction-manual" &&
        props.auctions.sellingMethod !== "legitimatePropertyLease-priorityEnglish-initial-qualification" &&
        props.auctions.sellingMethod !== "legitimatePropertyLease-priorityEnglish-initial-qualification-prod"
    ){
        return (
            <div
                className={style.btnAccept}
                onClick={
                    () => {
                        rejectWinerAwardCancelled(props)
                    }
                }
            >
                Відхилення
            </div>
        )
    }
    return null
}

function buttonReject(props){
    return (
        <div
            className={style.btnAccept}
            onClick={
                () => {
                    rejectWinerAwardReject(props)
                }
            }
        >
            Дискваліфікація
        </div>
    )
}

function viewTypeButtons(props){
    let tmp = null
    if(props.auctions.awards[props.popUpAwardReject.awards].documents.length > 0){
        for(let i = 0; i < props.auctions.awards[props.popUpAwardReject.awards].documents.length; i++){
            if(props.auctions.awards[props.popUpAwardReject.awards].documents[i].documentType !== 'act'){
                tmp = (
                    <>
                        {buttonCancelled(props)}
                        {buttonReject(props)}
                    </>
                )
            }
        }
        for(let i = 0; i < props.auctions.awards[props.popUpAwardReject.awards].documents.length; i++){
            if(props.auctions.awards[props.popUpAwardReject.awards].documents[i].documentType === 'act' || props.auctions.awards[props.popUpAwardReject.awards].documents[i].documentType === 'rejectionProtocol'){
                tmp = buttonReject(props)
            }
        }
    }
    return tmp
}

//////////////////////////////////////////////////////////////////////////////////
function buttonRejectMultyAward(props){
    return (
        <div
            className={style.btnAccept}
            onClick={
                () => {
                    if(props.awardReject.restrict === undefined && props.awardReject.restrict === 'undefined'){
                        let tmp = JSON.stringify({title: 'Помилка при відхиленні аварду', message: 'Оберіть причину відхилення'})
                        props.setUniversalError(tmp)
                    }else{
                        if(props.awardReject.restrict !== null && props.awardReject.restrict !== 'null'){
                            rejectWinerAwardCancelledZemlya(props)
                        }
                    }
                }
            }
        >
            Відхилити
        </div>
    )
}

function buttonCancelZemlya(props){
    return (
        <div
            className={style.btnAccept}
            onClick={
                () => {
                    if(props.awardReject.restrict === undefined && props.awardReject.restrict === 'undefined'){
                        let tmp = JSON.stringify({title: 'Помилка при відхиленні аварду', message: 'Оберіть причину відхилення'})
                        props.setUniversalError(tmp)
                    }else{
                        if(props.awardReject.restrict !== null && props.awardReject.restrict !== 'null'){
                            rejectWinerAwardReject(props)
                        }
                    }
                }
            }
        >
            Дискваліфікація
        </div>
    )
}

function buttonCancelZemlyaSave(props){
    return (
        <div
            className={style.btnAccept}
            onClick={
                () => {
                    if(props.awardReject.restrict === undefined && props.awardReject.restrict === 'undefined'){
                        let tmp = JSON.stringify({title: 'Помилка при відхиленні аварду', message: 'Оберіть причину відхилення'})
                        props.setUniversalError(tmp)
                    }else{
                        if(props.awardReject.restrict !== null && props.awardReject.restrict !== 'null'){
                            rejectWinerAwardRejectSave(props)
                        }
                    }
                }
            }
        >
            Зберегти
        </div>
    )
}

function buttonRejectZemlya(props){
    let tmpAct = null
    let tmpProtocol = null
    if(props.auctions.awards[props.popUpAwardReject.awards].documents.length > 0){
        for(let i = 0; i < props.auctions.awards[props.popUpAwardReject.awards].documents.length; i++){
            if(props.auctions.awards[props.popUpAwardReject.awards].documents[i].documentType === 'rejectionProtocol'){
                tmpProtocol = <>
                        {buttonCancelZemlya(props)}
                        {/*buttonRejectMultyAward(props)*/}
                    </>
            }
        }
        for(let i = 0; i < props.auctions.awards[props.popUpAwardReject.awards].documents.length; i++){
            if(props.auctions.awards[props.popUpAwardReject.awards].documents[i].documentType === 'act'){
                tmpAct = buttonCancelZemlya(props) //buttonRejectMultyAward(props)
            }
        }
    }
    if(tmpProtocol !== null) return tmpProtocol
    if(tmpAct !== null) return tmpAct
    return null
}
function buttonRejectZemlyaSave(props){
    if(!isLAE(props.auctions.sellingMethod) && !isLAW(props.auctions.sellingMethod) && !isLAP(props.auctions.sellingMethod)) return null
    return buttonCancelZemlyaSave(props)
}

async function rejectWinerAwardCancelledZemlya(props){
    let tmpReason = null
    if(props.awardReject.restrict !== null && props.awardReject.restrict !== 'null'){
        tmpReason = props.awardReject.restrict
    }
    let endPoint2 = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${props.popUpAwardReject.aucId}/award/${props.popUpAwardReject.awardId}/set_status/cancelled?reason=${tmpReason}`
    const response2 = await fetch(endPoint2, {
        method: "POST",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response2 === undefined || response2 === 'undefined'){
    }else{
        const json2 = await response2.json()
        await asyncAuction(props)
        if(json2.hasOwnProperty('message') === true){
            let tmp = JSON.stringify({title: 'Помилка при відхиленні аварду', message: JSON.stringify(json2)})
            props.setUniversalError(tmp)
        }else{
            let header = null;
            if(props.token !== null){
                header = {
                    'Authorization': props.token.access_token,
                    'Content-Type': 'application/json'
                }
            }else{
                header = {
                    'Content-Type': 'application/json'
                }
            }
            let data5 = {
                domen: process.env.REACT_APP_END_POINT_BD,
                version: 'v1',
                local: props.langFlag
            }
            await props.auctionsGetAwards(data5, header, props.popUpAwardReject.aucId)
            await props.contractsAuctionIdGet(data5, header, props.popUpAwardReject.aucId)
            await props.changeAwardRejectPopUpStatus(null)
            await props.changeAwardRejectTitle('')
            await props.changeAwardRejectDocumentType('null')
            await props.changeAwardRejectDescription('')
            await props.togetherApiDocFileName(null)
            await props.togetherApiECP(false)
            await props.ecpApiPass('')
            await props.changeCA('null')
            await props.changeDocumentFileName(null)
        }
    }
}



////////////////////////////////////////////////////////////////////////////////
function buttonRejectWinerAward(props, data){
    return (
        <div
            className={style.btnAccept}
            onClick={
                () => {
                    rejectWinerAward(props, data)
                }
            }
        >
            Відхилити
        </div>
    )
}

function buttonRejectWinerAward1(props){
    let tmp = null
    if(props.auctionAwards[props.popUpAwardReject.awards].documents.length > 0){
        for(let i = 0; i < props.auctionAwards[props.popUpAwardReject.awards].documents.length; i++){
            if(props.auctionAwards[props.popUpAwardReject.awards].documents[i].documentType !== 'act'){
                tmp = 'Відхилення'
                break
            }else{
                tmp = 'Діскваліфікація'
                break
            }
        }
    }
    return (
        <div
            className={style.btnAccept}
            onClick={
                () => {
                    rejectWinerAward1(props)
                }
            }
        >
            {tmp}
        </div>
    )
}

///////////////////////////////////////////////////////////



function buttonApproveAuctionAwards(props){
    let tmp = null
    if (
        isREM(props.auctions.sellingMethod) &&
        props.auctions.awards[props.popUpAwardReject.awards].status === 'protocol_signed'
    ) {
        return
    }
    if(props.auctions.awards[props.popUpAwardReject.awards].status !== 'active'){
        tmp = <div
            className={style.btnAccept}
            onClick={
                () => {
                    approveWinerAward(props)
                }
            }
        >
            {isLAW(props.auctions.sellingMethod)
                ? "Підтвердження підписання акту"
                : null
            }
            {isLAE(props.auctions.sellingMethod) || isLAP(props.auctions.sellingMethod)
                ? "Підтвердження протоколу"
                : null
            }
            {(!isLAE(props.auctions.sellingMethod) && !isLAW(props.auctions.sellingMethod) && !isLAP(props.auctions.sellingMethod))
                ? "Протокол затверджено"
                : null
            }
        </div>
    }else{
        tmp = null
    }
    return tmp
}

async function rejectWinerAward(props){
    props.changeLoader(true)
    let tmpReason = null
        if(props.awardReject !== null){
            if(props.awardReject.hasOwnProperty('restrict')){
                tmpReason = props.awardReject.restrict
            }else{
                tmpReason = '4'
            }
        }
        const endPoint2 = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction/${props.popUpAwardReject.aucId}/award/${props.popUpAwardReject.awardId}/set_status/unsuccessful`
        const response2 = await fetch(endPoint2, {
            method: "POST",
            headers: {
                'Authorization': props.token.access_token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                //reason: '4'
                reason: tmpReason
            })
        }).catch(console.error)
        if(response2 === undefined || response2 === 'undefined'){
            props.changeLoader(false)
        }else{
            const json2 = await response2.json()
            if(json2.hasOwnProperty('message') === true){
                let tmp = JSON.stringify({title: 'Помилка при відхиленні аварду', message: JSON.stringify(json2)})
                props.setUniversalError(tmp)
                props.changeLoader(false)
            }else{
                await setTimeout(() => combineUpadteAward(props), 2000)
                await props.changeAwardRejectPopUpStatus(null)
                await props.changeAwardRejectTitle('')
                await props.changeAwardRejectDocumentType('null')
                await props.changeAwardRejectDescription('')
                await props.togetherApiDocFileName(null)
                await props.togetherApiECP(false)
                await props.ecpApiPass('')
                await props.changeCA('null')
                await props.changeDocumentFileName(null)
            }
        }
    //}
}

async function rejectWinerAward1(props){
    props.changeLoader(true)
        let tmpReason = null
        if(props.awardReject !== null){
            if(props.awardReject.hasOwnProperty('restrict')){
                tmpReason = props.awardReject.restrict
            }else{
                tmpReason = '4'
            }
        }
        let endPoint2 = null
        let tmpSellingMethod = props.auctionsMy.sellingMethod.indexOf('railwayCargo-english')
        if( tmpSellingMethod === -1){
            endPoint2 = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction/${props.popUpAwardReject.aucId}/award/${props.popUpAwardReject.awardId}/set_status/cancelled?reason=${tmpReason}`
        }else{
            endPoint2 = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction/${props.popUpAwardReject.aucId}/award/${props.popUpAwardReject.awardId}/set_status/unsuccessful`
        }
        const response2 = await fetch(endPoint2, {
            method: "POST",
            headers: {
                'Authorization': props.token.access_token,
                'Content-Type': 'application/json'
            }
        }).catch(console.error)
        if(response2 === undefined || response2 === 'undefined'){
            props.changeLoader(false)
        }else{
            const json2 = await response2.json()
            if(json2.hasOwnProperty('message') === true){
                let tmp = JSON.stringify({title: 'Помилка при відхиленні аварду', message: JSON.stringify(json2)})
                props.setUniversalError(tmp)
                props.changeLoader(false)
            }else{
                await setTimeout(() => combineUpadteAward(props), 2000)
                await props.changeAwardRejectPopUpStatus(null)
                await props.changeAwardRejectTitle('')
                await props.changeAwardRejectDocumentType('null')
                await props.changeAwardRejectDescription('')
                await props.togetherApiDocFileName(null)
                await props.togetherApiECP(false)
                await props.ecpApiPass('')
                await props.changeCA('null')
                await props.changeDocumentFileName(null)
            }
        }
    //}
}

async function approveWinerAward(props){
    props.changeLoader(true)
    let endPoint = ``
    let newStatus = 'active'
    // console.log(props.popUpAwardReject)

    if((isSPD(props.auctions.sellingMethod) || isSPE(props.auctions.sellingMethod)
        || isBRD(props.auctions.sellingMethod) || isBRE(props.auctions.sellingMethod) || isBRW(props.auctions.sellingMethod)
        || isLPE(props.auctions.sellingMethod)
    ) && props.auctions.status === "active_qualification"){
        newStatus = 'pending_payment'
    }else{
        newStatus = 'active'
    }

    if (isREM(props.auctions.sellingMethod)) {
        newStatus = 'protocol_signed'
    }
    endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction/${props.popUpAwardReject.aucId}/award/${props.popUpAwardReject.awardId}/set_status/${newStatus}`

    const response = await fetch(endPoint, {
        method: "POST",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
        props.changeLoader(false)
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){
            //props.changeAwardErrorPopUpStatus(json)
            let tmp = JSON.stringify({title: 'Помилка при затвердженні аварду', message: JSON.stringify(json)})
            props.setUniversalError(tmp)
            props.changeLoader(false)
        }else{
            await setTimeout(() => combineUpadteAward(props), 2000)
            await props.changeAwardRejectPopUpStatus(null)
            await props.changeAwardRejectTitle('')
            await props.changeAwardRejectDocumentType('null')
            await props.changeAwardRejectDescription('')
            await props.togetherApiDocFileName(null)
            await props.togetherApiECP(false)
            await props.ecpApiPass('')
            await props.changeCA('null')
            await props.changeDocumentFileName(null)
        }
    }
}

//////////////////////

async function rejectWinerAwardCancelled(props){
    props.changeLoader(true)
    let tmpReason = null
    if(props.awardReject !== null){
        if(props.awardReject.hasOwnProperty('restrict')){
            tmpReason = props.awardReject.restrict
        }else{
            tmpReason = '4'
        }
    }
    let endPoint2 = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${props.popUpAwardReject.aucId}/award/${props.popUpAwardReject.awardId}/set_status/cancelled?reason=${tmpReason}`
    const response2 = await fetch(endPoint2, {
        method: "POST",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response2 === undefined || response2 === 'undefined'){
        props.changeLoader(false)
    }else{
        const json2 = await response2.json()
        if(json2.hasOwnProperty('message') === true){
            let tmp = JSON.stringify({title: 'Помилка при відхиленні аварду', message: JSON.stringify(json2)})
            props.setUniversalError(tmp)
            props.changeLoader(false)
        }else{
            await setTimeout(() => combineUpadteAward(props), 2000)
            await props.changeAwardRejectPopUpStatus(null)
            await props.changeAwardRejectTitle('')
            await props.changeAwardRejectDocumentType('null')
            await props.changeAwardRejectDescription('')
            await props.togetherApiDocFileName(null)
            await props.togetherApiECP(false)
            await props.ecpApiPass('')
            await props.changeCA('null')
            await props.changeDocumentFileName(null)
        }
    }
}

async function rejectWinerAwardReject(props){
    props.changeLoader(true)
    let tmpReason = null
    if(props.awardReject !== null){
        if(props.awardReject.hasOwnProperty('restrict')){
            tmpReason = props.awardReject.restrict
        }else{
            tmpReason = '4'
        }
    }
    let endPoint2 = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction/${props.popUpAwardReject.aucId}/award/${props.popUpAwardReject.awardId}/set_status/unsuccessful`
    const response2 = await fetch(endPoint2, {
        method: "POST",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            //reason: '4'
            reason: tmpReason
        })
    }).catch(console.error)
    if(response2 === undefined || response2 === 'undefined'){
        props.changeLoader(false)
    }else{
        const json2 = await response2.json()
        if(json2.hasOwnProperty('message') === true){
            let tmp = JSON.stringify({title: 'Помилка при відхиленні аварду', message: JSON.stringify(json2)})
            props.setUniversalError(tmp)
            props.changeLoader(false)
        }else{
            await setTimeout(() => combineUpadteAward(props), 2000)
            await props.changeAwardRejectPopUpStatus(null)
            await props.changeAwardRejectTitle('')
            await props.changeAwardRejectDocumentType('null')
            await props.changeAwardRejectDescription('')
            await props.togetherApiDocFileName(null)
            await props.togetherApiECP(false)
            await props.ecpApiPass('')
            await props.changeCA('null')
            await props.changeDocumentFileName(null)
        }
    }
}
async function rejectWinerAwardRejectSave(props){
    props.changeLoader(true)
    let tmpReason = null
    if(props.awardReject !== null){
        if(props.awardReject.hasOwnProperty('restrict')){
            tmpReason = props.awardReject.restrict
        }else{
            tmpReason = null
        }
    }
    let endPoint2 = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction/${props.popUpAwardReject.aucId}/award/${props.popUpAwardReject.awardId}/termination_reason/${tmpReason}`
    const response2 = await fetch(endPoint2, {
        method: "PATCH",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        },
    }).catch(console.error)
    if(response2 === undefined || response2 === 'undefined'){
        props.changeLoader(false)
    }else{
        const json2 = await response2.json()
        if(json2.hasOwnProperty('message') === true){
            let tmp = JSON.stringify({title: 'Помилка при відхиленні аварду', message: JSON.stringify(json2)})
            props.setUniversalError(tmp)
            props.changeLoader(false)
        }else{
            await setTimeout(() => combineUpadteAward(props), 2000)
            await props.changeAwardRejectPopUpStatus(null)
            await props.changeAwardRejectTitle('')
            await props.changeAwardRejectDocumentType('null')
            await props.changeAwardRejectDescription('')
            await props.togetherApiDocFileName(null)
            await props.togetherApiECP(false)
            await props.ecpApiPass('')
            await props.changeCA('null')
            await props.changeDocumentFileName(null)
        }
    }
}

async function combineUpadteAward(props){
    await getUpdateDataAuction(props, props.auctions.id)
    await props.changeLoader(false)
}

async function asyncAuction(props){
    const url = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${props.popUpAwardReject.aucId}/sync`;
    const response = await fetch(url, {
	method: "GET",
	headers: {
		'Authorization': props.token.access_token,
		'Content-Type': 'application/json'
	}
    }).catch(console.error)
}

const mapStateToProps = state => {
    return {
        token: state.start.token,
        //auctions: state.start.auctionsGet,
        auctions: state.start.auctionFull,

        langFlag: state.start.langFlag,
        createAuctionTab: state.start.createAuctionTab,
        createAuction: state.start.createAuction,
        createItems: state.start.createItems,
        auctionsMy: state.start.auctionsMy,
        auctionsItemsSwitchWindow: state.start.auctionsItemsSwitchWindow,
        endPoint: state.start.endPoint,
        classificationsGets: state.start.classificationsGet,
        unitsGet: state.start.unitsGet,
        userProfiles: state.start.userProfiles,
        classificationsTimber: state.start.classificationsTimber,
        classificationsEnergy: state.start.classificationsEnergy,
        auctionsList: state.start.auctionsGetMy,
        auctionAwards: state.start.auctionAwards,
        popUpAwardReject: state.start.popUpAwardReject,
        awardReject: state.start.awardReject,
        auction: state.start.auctionsGetId,

        togetherApiPage: state.start.togetherApiPage,
        ecpData: state.start.ecpData,

        createDocuments: state.start.createDocuments,
    }
}

const mapDispatchToProps = {
    auctionsGetAwards,
    auctionsGetAwardsId,
    changeAwardRejectPopUpStatus,
    changeAwardErrorPopUpStatus,
    changeAwardRejectTitle,
    changeAwardRejectDescription,
    changeAwardRejectDocumentType,
    changeAwardDocumentStatus,

    setUniversalError,

    changeDocumentId,
    changeDocumentTitle,
    changeDocumentDescription,
    changeDocumentLang,
    changeDocumentFileName,
    changeDocumentType,

    togetherApiPopUpOnOff,
    togetherApiDocTitle,
    togetherApiDocDescription,
    togetherApiDocType,
    togetherApiDocLang,
    togetherApiDocFileName,
    togetherApiECP,

    ecpApiPass,
    ecpApiList,
    changeCA,

    changeLoader,
    changeAwardRejectRestrict,
    contractsAuctionIdGet,
    setDataAddress,
}

export default connect(mapStateToProps, mapDispatchToProps)(ApproveOrRejectAward)