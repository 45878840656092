import React from 'react';
import styles from './styles.module.css';

import {connect} from 'react-redux';

import {paymentPopup, paymentPopup1, paymentPopupGetData} from '../../redux/paymentPopup'
import {modifiDateString} from '../../redux/modifiDate'
import {setUniversalError} from '../../redux/actions'
import {
    isTIE, isSUE, isSUD, isRCE, isRCD, isGFE, isGFD,
    isLLE, isLLD, isLLP, isLRE, isLSE, isLSP,
    isBSE, isBSD, isALE, isCSE, isCSD, isAAE, isBRE, isBRD, isBRW,
    isRLE, isRLD, isCLE, isCLD, isSPE, isSPD, isLAE, isLAW, isLAP, isLPE,
    isAPE, isAPD, isNLE, isNLD
} from "../../redux/procedure";

import { getAuctionsPeriods } from "../../api/dictonaries/statusAuctions"

const RightBlock = (props) => {
    let buttonsPay = null
    switch (true) {
        case (isLLE(props.auctionId.sellingMethod) || isLLD(props.auctionId.sellingMethod) || isLLP(props.auctionId.sellingMethod) ||
            isLRE(props.auctionId.sellingMethod) ||
            isLSE(props.auctionId.sellingMethod) || isLSP(props.auctionId.sellingMethod) ||
            isRLE(props.auctionId.sellingMethod) || isRLD(props.auctionId.sellingMethod) ||
            isCLE(props.auctionId.sellingMethod) || isCLD(props.auctionId.sellingMethod) ||
            isSPE(props.auctionId.sellingMethod) || isSPD(props.auctionId.sellingMethod) ||
            isLPE(props.auctionId.sellingMethod) ||
            isLAE(props.auctionId.sellingMethod) || isLAP(props.auctionId.sellingMethod) ||
            isAPE(props.auctionId.sellingMethod) || isAPD(props.auctionId.sellingMethod)):
            buttonsPay = viewBalanceRegistration(props);
            break;
        case isNLE(props.auctionId.sellingMethod) || isNLD(props.auctionId.sellingMethod):
            buttonsPay = props.auctionId.hasOwnProperty('registrationFee') ?
                viewBalanceRegistration(props) : buttonsPay = viewBalanceGuarantee(props);
            break;
        default:
            buttonsPay = viewBalanceGuarantee(props)
    }
    return (
        <div className={styles.auctionSteps}>
            {!isLAE(props.auctionId.sellingMethod)
            && !isLAW(props.auctionId.sellingMethod)
            && !isLAP(props.auctionId.sellingMethod)
                ? <h2>Періоди процедури</h2>
                : null
            }
            {isLAE(props.auctionId.sellingMethod) || isLAP(props.auctionId.sellingMethod) ? <h2>Кроки проведення</h2> : null}
            {!isLAW(props.auctionId.sellingMethod)
            && !isAPE(props.auctionId.sellingMethod)
            && !isAPD(props.auctionId.sellingMethod)
                ? <div className={styles.stepBlock}>
                    <div className={styles.stepIcon}>
                        {cheackDateViewIcons(props, 'start')}
                    </div>
                    <div className={styles.stepInfo}>
                        <h3 className={cheackDateFontText(props, 'start')}>{getAuctionsPeriods(props.auctionId.sellingMethod, "start")}</h3>
                        <p>{viewCreateDate(props.auctionId)}</p>
                    </div>
                </div>
                : null
            }
            {!isLAW(props.auctionId.sellingMethod) ? viewRectificationPeriod(props) : null}
            {!isLAW(props.auctionId.sellingMethod) ? viewQuestionPeriod(props) : null }
            {isLAP(props.auctionId.sellingMethod) ? viewTransferPriorityPeriodLAP(props) : null}
            {!isLAW(props.auctionId.sellingMethod) ? viewTransferPriorityPeriod(props) : null}
            {!isLAW(props.auctionId.sellingMethod) ? viewTenderPeriod(props) : null}
            {!isLAW(props.auctionId.sellingMethod)
                ? <div className={styles.stepBlock}>
                    <div className={styles.stepIcon}>
                        {cheackDateViewIcons(props, 'auctionPeriod')}
                    </div>
                    <div className={styles.stepInfo}>
                        <h3 className={cheackDateFontText(props, 'auctionPeriod')}>{getAuctionsPeriods(props.auctionId.sellingMethod, "active_auction")}</h3>
                        <p>{viewDate(props.auctionId, 'auctionPeriod', 'start', 3)}</p>
                        <p></p>
                    </div>
                </div>
                : null
            }
            {(isSPE(props.auctionId.sellingMethod) || isLPE(props.auctionId.sellingMethod))
            && props.auctionId.hasOwnProperty('admissionPeriod')
                ? viewAdmissionPeriod(props)
                : null
            }
            {!isLAW(props.auctionId.sellingMethod) ? viewAwards(props) : null}
            {!isLAW(props.auctionId.sellingMethod) ? viewContracts(props) : null}
            {!isLAW(props.auctionId.sellingMethod) ? viewContractsPayment(props) : null}
            {!isLAW(props.auctionId.sellingMethod)
                ? <div className={styles.stepBlock}>
                    <div className={styles.stepIcon}>
                        {cheackDateViewIcons(props, 'auctionEnd')}
                    </div>
                    <div className={styles.stepInfo}>
                        <h3 className={cheackDateFontText(props, 'auctionEnd')}>{getAuctionsPeriods(props.auctionId.sellingMethod, "auctionEnd")}</h3>
                        <p></p>
                        <p>Завершення торгів: організатор завершує торги</p>
                    </div>
                </div>
                : null
            }
            {buttonsPay}
            {viewIsFinAuth(props)}
            {buttonsAdmin(props)}
            {buttonsSign(props)}
        </div>
    )
}

function viewTenderPeriod(props){
    if(props.auctionId.tenderPeriod){
        return (
            <div className={styles.stepBlock}>
                <div className={styles.stepIcon}>
                    {cheackDateViewIcons(props, 'tenderPeriod')}
                </div>
                <div className={styles.stepInfo}>
                    <h3 className={cheackDateFontText(props, 'tenderPeriod')}>
                        {getAuctionsPeriods(props.auctionId.sellingMethod, "active_tendering")}
                    </h3>
                    <p>з {viewDate(props.auctionId, 'tenderPeriod', 'start', 3)}</p>
                    <p>по {viewDate(props.auctionId, 'tenderPeriod', 'end', 3)}</p>
                    <p>Реєстрація заявок на аукціон</p>
                </div>
            </div>
        )
    }
    return null
}
function viewAdmissionPeriod(props){
    if(props.auctionId.tenderPeriod){
        return (
            <div className={styles.stepBlock}>
                <div className={styles.stepIcon}>
                    {cheackDateViewIcons(props, 'admissionPeriod')}
                </div>
                <div className={styles.stepInfo}>
                    <h3 className={cheackDateFontText(props, 'admissionPeriod')}>
                        {getAuctionsPeriods(props.auctionId.sellingMethod, "pending_admission")}
                    </h3>
                    <p>з {viewDate(props.auctionId, 'admissionPeriod', 'start', 3)}</p>
                    <p>по {viewDate(props.auctionId, 'admissionPeriod', 'end', 3)}</p>
                </div>
            </div>
        )
    }
    return null
}

function viewRectificationPeriod(props){
    if(props.auctionId.rectificationPeriod !== null){
        return (
            <div className={styles.stepBlock}>
                <div className={styles.stepIcon}>
                    {cheackDateViewIcons(props, 'rectificationPeriod')}
                </div>
                <div className={styles.stepInfo}>
                    <h3 className={cheackDateFontText(props, 'rectificationPeriod')}>
                        {getAuctionsPeriods(props.auctionId.sellingMethod, "active_rectification")}
                    </h3>
                    <p>з {viewDate(props.auctionId, 'rectificationPeriod', 'start', 3)}</p>
                    <p>по {viewDate(props.auctionId, 'rectificationPeriod', 'end', 3)}</p>
                </div>
            </div>
        )
    }
    return null
}

function viewQuestionPeriod(props){
    if(props.auctionId.questionPeriod !== null){
        return (
            <div className={styles.stepBlock}>
                <div className={styles.stepIcon}>
                    {cheackDateViewIcons(props, 'questionPeriod')}
                </div>
                <div className={styles.stepInfo}>
                    <h3 className={cheackDateFontText(props, 'questionPeriod')}>
                        {getAuctionsPeriods(props.auctionId.sellingMethod, "questionPeriod")}
                    </h3>
                    <p>з {viewDate(props.auctionId, 'questionPeriod', 'start', 3)}</p>
                    <p>по {viewDate(props.auctionId, 'questionPeriod', 'end', 3)}</p>
                    <p>Майбутні учасники можуть задавати запитання організатору</p>
                </div>
            </div>
        )
    }
    return null
}

function viewTransferPriorityPeriodLAP(props){
    if(props.auctionId.questionPeriod !== null){
        return (
            <div className={styles.stepBlock}>
                <div className={styles.stepIcon}>
                    {cheackDateViewIcons(props, 'transferPriorityPeriod')}
                </div>
                <div className={styles.stepInfo}>
                    <h3 className={cheackDateFontText(props, 'transferPriorityPeriod')}>
                        {getAuctionsPeriods(props.auctionId.sellingMethod, "transferPriorityPeriod")}
                    </h3>
                    <p>з {viewDate(props.auctionId, 'transferPriorityPeriod', 'start', 3)}</p>
                    <p>по {viewDate(props.auctionId, 'transferPriorityPeriod', 'end', 3)}</p>
                    <p>Організатор може змінювати учасника з переважним правом</p>
                </div>
            </div>
        )
    }
    return null
}

function buttonsAdmin(props){
    if(props.userProfiles !== null && props.auctionId !== null){
        if(props.userProfiles.hasOwnProperty('isAdmin') === true){
            if(props.userProfiles.isAdmin){
                return (
                    <div className={styles.balance}>
                        <a
                            className={styles.balanceBtn + ' ' + styles.Btn}
                            href={`${process.env.REACT_APP_END_POINT_BD}/uk/admin/app/baseauction/${props.auctionId.id}/show`}
                        >Модерація аукціону</a>
                    </div>
                )
            }
        }
    }
    return null
}

function buttonsSign(props){
    if(props.token === null && (props.auctionId.status === 'active_auction' || props.auctionId.status === 'active_tendering')){
        return (
            <a
                href="https://www.dto.com.ua/registration/sellers?referer=true"
            >
                <div className={styles.balance + ' ' + styles.balanceBtn1}>
                    <span>Зареєструйтесь та візьміть участь в аукціоні</span><br />
                    <span>або авторизуйтеся, якщо ви вже є учасником</span>
                </div>
            </a>
        )
    }
    return null
}

function viewTransferPriorityPeriod(props){
    if(
        props.auctionId.sellingMethod === 'landSell-priorityEnglish' ||
        props.auctionId.sellingMethod === 'landSell-priorityEnglish-fast' ||
        props.auctionId.sellingMethod === 'landSell-priorityEnglish-fast-manual' ||
        props.auctionId.sellingMethod === 'landSell-priorityEnglish-fast-auction-manual-qualification' ||
        props.auctionId.sellingMethod === 'landSell-priorityEnglish-initial-auction' ||
        props.auctionId.sellingMethod === 'landSell-priorityEnglish-initial-auction-manual' ||
        props.auctionId.sellingMethod === 'landSell-priorityEnglish-initial-qualification' ||
        props.auctionId.sellingMethod === 'landSell-priorityEnglish-initial-qualification-prod'
    ){
        if(props.auctionId.hasOwnProperty('transferPriorityPeriod')){
            return (
                <div className={styles.stepBlock}>
                    <div className={styles.stepIcon}>
                        {cheackDateViewIcons(props, 'transferPriorityPeriod')}
                    </div>
                    <div className={styles.stepInfo}>
                        <h3 className={cheackDateFontText(props, 'transferPriorityPeriod')}>{getAuctionsPeriods(props.auctionId.sellingMethod, "transferPriorityPeriod")}</h3>
                        <p>з {viewDate(props.auctionId, 'transferPriorityPeriod', 'start', 3)}</p>
                        <p>по {viewDate(props.auctionId, 'transferPriorityPeriod', 'end', 3)}</p>
                        <p>Організатор може змінювати учасника з пріорітетним правом</p>
                    </div>
                </div>
            )
        }
    }
    return null
}

function viewAwards(props){
    if(props.auctionId.status === 'active_qualification' ){
        return(
            <div className={styles.stepBlock}>
                <div className={styles.stepIcon}>
                    {viewOnOffIconDate(2)}
                </div>
                <div className={styles.stepInfo}>
                    <h3 className={cheackDateFontText(props, 'active_qualification')}>{getAuctionsPeriods(props.auctionId.sellingMethod, "active_qualification")}</h3>
                    <p>{!isAPE(props.auctionId.sellingMethod) && !isAPD(props.auctionId.sellingMethod)
                        ? "Організатор кваліфікує переможця та оприлюднює протокол"
                        : "Організатор кваліфікує переможця. Оприлюднює протокол та підписує договір"
                    }</p>
                </div>
            </div>
        )
    }else if(
        props.auctionId.status === 'active_awarded'
    ){
        return (
            <div className={styles.stepBlock}>
                <div className={styles.stepIcon}>
                    {viewOnOffIconDate(3)}
                </div>
                <div className={styles.stepInfo}>
                    <h3 className={cheackDateFontText(props, 'active_awarded')}>{getAuctionsPeriods(props.auctionId.sellingMethod, "active_qualification")}</h3>
                    <p>{!isAPE(props.auctionId.sellingMethod) && !isAPD(props.auctionId.sellingMethod)
                        ? "Організатор кваліфікує переможця та оприлюднює протокол"
                        : "Організатор кваліфікує переможця. Оприлюднює протокол та підписує договір"
                    }</p>
                </div>
            </div>
        )
    }else if(
        props.auctionId.status === 'pending_payment'
    ){
        return (
            <div className={styles.stepBlock}>
                <div className={styles.stepIcon}>
                    {viewOnOffIconDate(3)}
                </div>
                <div className={styles.stepInfo}>
                    <h3 className={cheackDateFontText(props, 'pending_payment')}>{getAuctionsPeriods(props.auctionId.sellingMethod, "active_qualification")}</h3>
                    <p>{!isAPE(props.auctionId.sellingMethod) && !isAPD(props.auctionId.sellingMethod)
                        ? "Організатор кваліфікує переможця та оприлюднює протокол"
                        : "Організатор кваліфікує переможця. Оприлюднює протокол та підписує договір"
                    }</p>
                </div>
            </div>
        )
    }else if(
        props.auctionId.status === 'complete'
    ){
        return (
            <div className={styles.stepBlock}>
                <div className={styles.stepIcon}>
                    {viewOnOffIconDate(3)}
                </div>
                <div className={styles.stepInfo}>
                    <h3 className={cheackDateFontText(props, 'complete')}>{getAuctionsPeriods(props.auctionId.sellingMethod, "active_qualification")}</h3>
                    <p>{!isAPE(props.auctionId.sellingMethod) && !isAPD(props.auctionId.sellingMethod)
                        ? "Організатор кваліфікує переможця та оприлюднює протокол"
                        : "Організатор кваліфікує переможця. Оприлюднює протокол та підписує договір"
                    }</p>
                </div>
            </div>
        )
    }
    return (
        <div className={styles.stepBlock}>
            <div className={styles.stepIcon}>
                {viewOnOffIconDate(1)}
            </div>
            <div className={styles.stepInfo}>
                <h3 className={styles.inactive}>{getAuctionsPeriods(props.auctionId.sellingMethod, "active_qualification")}</h3>
                <p>{!isAPE(props.auctionId.sellingMethod) && !isAPD(props.auctionId.sellingMethod)
                        ? "Організатор кваліфікує переможця та оприлюднює протокол"
                        : "Організатор кваліфікує переможця. Оприлюднює протокол та підписує договір"
                    }
                </p>
            </div>
        </div>
    )
}

function viewContracts(props){
    if(isAPE(props.auctionId.sellingMethod) || isAPD(props.auctionId.sellingMethod)) return null
    if(
        props.auctionId.status === 'active_awarded'
        /* || props.auctionId.status === 'pending_payment'*/
    ){
        return(
            <div className={styles.stepBlock}>
                <div className={styles.stepIcon}>
                    {viewOnOffIconDate(2)}
                </div>
                <div className={styles.stepInfo}>
                    <h3 className={cheackDateFontText(props, 'active_awarded')}>{getAuctionsPeriods(props.auctionId.sellingMethod, "active_awarded")}</h3>
                    <p>Організатор укладає та оприлюднює договір</p>
                </div>
            </div>
        )
    }else if(props.auctionId.status === 'complete'){
        return (
            <div className={styles.stepBlock}>
                <div className={styles.stepIcon}>
                    {viewOnOffIconDate(3)}
                </div>
                <div className={styles.stepInfo}>
                    <h3 className={cheackDateFontText(props, 'complete')}>{getAuctionsPeriods(props.auctionId.sellingMethod, "active_awarded")}</h3>
                    <p>Організатор укладає та оприлюднює договір</p>
                </div>
            </div>
        )
    }
    return (
        <div className={styles.stepBlock}>
            <div className={styles.stepIcon}>
                {viewOnOffIconDate(1)}
            </div>
            <div className={styles.stepInfo}>
                <h3 className={styles.inactive}>{getAuctionsPeriods(props.auctionId.sellingMethod, "active_awarded")}</h3>
                <p>Очікується підписання договору: організатор укладає та оприлюднює договір</p>
            </div>
        </div>
    )
}

function viewContractsPayment(props){
    if(
        props.auctionId.sellingMethod === 'landRental-english' ||
        props.auctionId.sellingMethod === 'landRental-english-fast' ||
        props.auctionId.sellingMethod === 'landRental-english-fast-manual' ||
        props.auctionId.sellingMethod === 'landRental-english-fast-auction-manual-qualification' ||
        props.auctionId.sellingMethod === 'landRental-english-initial-auction' ||
        props.auctionId.sellingMethod === 'landRental-english-initial-auction-manual' ||
        props.auctionId.sellingMethod === 'landRental-english-initial-qualification' ||
        props.auctionId.sellingMethod === 'landRental-english-initial-qualification-prod' ||
        props.auctionId.sellingMethod === 'landSell-english' ||
        props.auctionId.sellingMethod === 'landSell-english-fast' ||
        props.auctionId.sellingMethod === 'landSell-english-fast-manual' ||
        props.auctionId.sellingMethod === 'landSell-english-fast-auction-manual-qualification' ||
        props.auctionId.sellingMethod === 'landSell-english-initial-auction' ||
        props.auctionId.sellingMethod === 'landSell-english-initial-auction-manual' ||
        props.auctionId.sellingMethod === 'landSell-english-initial-qualification' ||
        props.auctionId.sellingMethod === 'landSell-english-initial-qualification-prod' ||
        props.auctionId.sellingMethod === 'landSell-priorityEnglish' ||
        props.auctionId.sellingMethod === 'landSell-priorityEnglish-fast' ||
        props.auctionId.sellingMethod === 'landSell-priorityEnglish-fast-manual' ||
        props.auctionId.sellingMethod === 'landSell-priorityEnglish-fast-auction-manual-qualification' ||
        props.auctionId.sellingMethod === 'landSell-priorityEnglish-initial-auction' ||
        props.auctionId.sellingMethod === 'landSell-priorityEnglish-initial-auction-manual' ||
        props.auctionId.sellingMethod === 'landSell-priorityEnglish-initial-qualification' ||
        props.auctionId.sellingMethod === 'landSell-priorityEnglish-initial-qualification-prod'
    ){
        if(props.auctionId.status === 'pending_payment'){
            return(
                <div className={styles.stepBlock}>
                    <div className={styles.stepIcon}>
                        {viewOnOffIconDate(2)}
                    </div>
                    <div className={styles.stepInfo}>
                        <h3 className={styles.inactive}>{getAuctionsPeriods(props.auctionId.sellingMethod, "pending_payment")}</h3>
                        {/*<p>Очікується оплата: організатор укладає та оприлюднює договір</p>*/}
                    </div>
                </div>
            )
        }else if(props.auctionId.status === 'complete'){
            return (
                <div className={styles.stepBlock}>
                    <div className={styles.stepIcon}>
                        {viewOnOffIconDate(3)}
                    </div>
                    <div className={styles.stepInfo}>
                        <h3 className={styles.inactive}>{getAuctionsPeriods(props.auctionId.sellingMethod, "pending_payment")}</h3>
                        {/*<p>Очікується оплата: організатор укладає та оприлюднює договір</p>*/}
                    </div>
                </div>
            )
        }
        return (
            <div className={styles.stepBlock}>
                <div className={styles.stepIcon}>
                    {viewOnOffIconDate(1)}
                </div>
                <div className={styles.stepInfo}>
                    <h3 className={styles.inactive}>{getAuctionsPeriods(props.auctionId.sellingMethod, "pending_payment")}</h3>
                    {/*<p>Очікується оплата: організатор укладає та оприлюднює договір</p>*/}
                </div>
            </div>
        )
    }
    return null
}
//////////////////////////////////////////////////
function viewBalanceGuarantee(props){
    if(isSPD(props.auctionId.sellingMethod)) return null
    if(props.userProfiles !== null){
        if(props.userProfiles.canCreateBid === true){
            return (
                <div className={styles.balance}>
                    <h2>Поповнити баланс</h2>
                    <p>На Вашому балансі {startCostAuction(props.userProfiles.balance)} грн.</p>
                    <div 
                        className={styles.balanceBtn + ' ' + styles.Btn}
                        onClick={
                            () => {
                                getInvoiceData(props, 'guarantee')
                                props.paymentPopup(props.auctionId)
                            }
                        }
                    >Сформувати рахунки</div>
                </div>
            )
        }
    }
    return null
}

function startCostAuction(data){
    let tmp = null
    let amount = null
    if(data !== null){
        amount = String(data);
        if(amount !== null){
            if(amount.indexOf('.') !== -1){
                amount = amount.split('.');
                tmp = String(amount[0]);
                tmp = tmp.split("").reverse().join("");
                let tmp1 = [];
                for(let i = 0; i < tmp.length; i++){
                    if(((i+1)%3) === 0){
                        if(i !== tmp.length-1){
                            tmp1[i] = tmp[i] + '\'';
                        }else{
                            tmp1[i] = tmp[i];
                        }
                    }else{
                        tmp1[i] = tmp[i];
                    }
                }
                tmp = tmp1.join('')
                tmp = tmp.split("").reverse().join("");
                amount = amount[1];
            }else{
                tmp = String(amount);
                tmp = tmp.split("").reverse().join("");
                let tmp1 = [];
                for(let i = 0; i < tmp.length; i++){
                    if(((i+1)%3) === 0){
                        if(i !== tmp.length-1){
                            tmp1[i] = tmp[i] + '\'';
                        }else{
                            tmp1[i] = tmp[i];
                        }
                    }else{
                        tmp1[i] = tmp[i];
                    }
                }
                tmp = tmp1.join('')
                tmp = tmp.split("").reverse().join("");
                amount = '00'    
            }
        }
    }
    return tmp + ',' + amount
}

function viewBalanceRegistration(props){
    if(props.userProfiles !== null){
        if(props.userProfiles.canCreateBid === true){
            return (
                <div className={styles.balance}>
                    <h2>Поповнити баланс</h2>
                    <p>На Вашому балансі {props.userProfiles.balance} грн.</p>
                    <p>Сформуйте рахунки</p>
                    <div 
                        className={styles.balanceBtn + ' ' + styles.Btn}
                        onClick={
                            () => {
                                getInvoiceData(props)
                                props.paymentPopup(props.auctionId)
                            }
                        }
                    >Сформувати рахунки</div>
                </div>
            )
        }
    }
    return null
}

async function getInvoiceData(props) {
    let endPoint = null
    switch (true) {
        case props.auctionId.sellingMethod.startsWith("railwayCargo-english"):
        case props.auctionId.sellingMethod.startsWith("railwayCargo-dutch"):
            endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/userProfile/payments/bill/auction/${props.auctionId.id}/registration`;
            break
        default:
            endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/userProfile/payments/bill/auction/${props.auctionId.id}/guarantee`

    }
    const response = await fetch(endPoint, {
        method: "GET",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if (response === undefined || response === 'undefined') {
        console.error('response error!')
        //@todo refactor
    } else {
        const json = await response.json()
        if (json.hasOwnProperty('message') === true && json.hasOwnProperty('bill') === false) {
            let tmp = JSON.stringify({title: 'Помилка при отриманні даних для рахунка', message: JSON.stringify(json)})
            props.setUniversalError(tmp)
        } else {
            let tmpArr = []
            tmpArr[0] = json
            if (
                props.auctionId.sellingMethod.startsWith("legitimatePropertyLease-english") ||
                props.auctionId.sellingMethod.startsWith("legitimatePropertyLease-dutch") ||
                props.auctionId.sellingMethod.startsWith("legitimatePropertyLease-priorityEnglish") ||
                props.auctionId.sellingMethod.startsWith("landRental-english") ||
                props.auctionId.sellingMethod.startsWith("landSell-english") ||
                props.auctionId.sellingMethod.startsWith("landSell-priorityEnglish") ||
                props.auctionId.sellingMethod.startsWith("timber-english") ||

                isBSE(props.auctionId.sellingMethod) || isBSD(props.auctionId.sellingMethod) ||
                isALE(props.auctionId.sellingMethod) ||
                isCSE(props.auctionId.sellingMethod) || isCSD(props.auctionId.sellingMethod) ||
                isRLE(props.auctionId.sellingMethod) || isRLD(props.auctionId.sellingMethod) ||
                isCLE(props.auctionId.sellingMethod) || isCLD(props.auctionId.sellingMethod) ||
                isSPE(props.auctionId.sellingMethod) || isSPD(props.auctionId.sellingMethod) ||
                isLPE(props.auctionId.sellingMethod) ||
                isLAE(props.auctionId.sellingMethod) || isLAP(props.auctionId.sellingMethod) ||
                isAPE(props.auctionId.sellingMethod) || isAPD(props.auctionId.sellingMethod) ||
                isNLE(props.auctionId.sellingMethod) || isNLD(props.auctionId.sellingMethod)
            ) {
                let endPoint2 = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/userProfile/payments/bill/auction/${props.auctionId.id}/registration`;
                const response2 = await fetch(endPoint2, {
                    method: "GET",
                    headers: {
                        'Authorization': props.token.access_token,
                        'Content-Type': 'application/json'
                    }
                }).catch(console.error)
                if (response2 === undefined || response2 === 'undefined') {

                } else {
                    const json2 = await response2.json()
                    if (json2.hasOwnProperty('message') === true && json2.hasOwnProperty('bill') === false) {
                        let tmp = JSON.stringify({
                            title: 'Помилка при отриманні даних для рахунка',
                            message: JSON.stringify(json2)
                        })
                        props.setUniversalError(tmp)
                    } else {
                        tmpArr[1] = json2
                        props.paymentPopupGetData(tmpArr)
                    }
                }
            } else {
                props.paymentPopupGetData(tmpArr)
            }
        }
    }
}

function viewCreateDate(data){
    if(data !== null){
        if(data.hasOwnProperty('datePublished') !== null){
            return modifiDateString(data.datePublished, 1, false)
        }
    }
}

function viewDate(data, typeDate, startEnd, flag){
    if(data !== null){
        if(data[typeDate] !== null){
            if(startEnd === 'start'){
                return modifiDateString(data[typeDate].startDate, flag, false)
            }
            if(startEnd === 'end'){
                return modifiDateString(data[typeDate].endDate, flag, false)
            }
        }
    }
}

function viewOnOffIconDate(data){
    if(data === 1){
        return <svg width="32" height="32" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
            <g id="Слой_2" data-name="Слой 2">
                <g id="Слой_1-2" data-name="Слой 1">
                    <path className="" fill="#e2e2ea" d="M0,16A16,16,0,0,1,16,0h0A16,16,0,0,1,32,16h0A16,16,0,0,1,16,32h0A16,16,0,0,1,0,16Z"/>
                    <path className="" fill="#fff" fillRule="evenodd" d="M12.68,14V12.73a3.37,3.37,0,1,1,6.73,0V14h.95a1,1,0,0,1,1,1v6.75a1,1,0,0,1-1,1H11.64a1,1,0,0,1-1-1V14.93a1,1,0,0,1,1-1Zm-.8,1.21v6.29h8.24V15.16Zm6.32-1.23v-1.2a2.15,2.15,0,1,0-4.3,0v1.2Z"/>
                </g>
            </g>
        </svg>
    }else if(data === 2){
        return <svg width="32" height="32" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
            <g id="Слой_2" data-name="Слой 2">
                <g id="Слой_1-2" data-name="Слой 1">
                    <path fill="#00aff0" d="M0,16A16,16,0,0,1,16,0h0A16,16,0,0,1,32,16h0A16,16,0,0,1,16,32h0A16,16,0,0,1,0,16Z"/>
                    <path fill="#fff" fillRule="evenodd" d="M9.33,16A6.67,6.67,0,1,0,16,9.33,6.67,6.67,0,0,0,9.33,16Zm4.56,3.19a.7.7,0,0,0,1,.21l4.35-2.81a.7.7,0,0,0,0-1.18L14.86,12.6a.76.76,0,0,0-.38-.11.7.7,0,0,0-.7.7v5.62A.7.7,0,0,0,13.89,19.19Z"/>
                </g>
            </g>
        </svg>
    }else if(data === 3){
        return <svg width="32" height="32" xmlns="http://www.w3.org/2000/svg" viewBox="-9 5 28 6">
            <g id="Слой_2" data-name="Слой 2">
                <g id="Слой_1-2" data-name="Слой 1">
                    <path className="svgIcon" fill="#00b191"fillRule="evenodd" d="M1.14,2.65a.68.68,0,0,0-.95,0,.68.68,0,0,0,0,1L4.08,7.44a.67.67,0,0,0,1,0L10.5,1.1a.66.66,0,1,0-1-.87L4.51,6Z"/>
                </g>
            </g>
        </svg>
    }else{
        return <svg width="32" height="32" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
            <g id="Слой_2" data-name="Слой 2">
                <g id="Слой_1-2" data-name="Слой 1">
                    <path className="" fill="#e2e2ea" d="M0,16A16,16,0,0,1,16,0h0A16,16,0,0,1,32,16h0A16,16,0,0,1,16,32h0A16,16,0,0,1,0,16Z"/>
                    <path className="" fill="#fff" fillRule="evenodd" d="M12.68,14V12.73a3.37,3.37,0,1,1,6.73,0V14h.95a1,1,0,0,1,1,1v6.75a1,1,0,0,1-1,1H11.64a1,1,0,0,1-1-1V14.93a1,1,0,0,1,1-1Zm-.8,1.21v6.29h8.24V15.16Zm6.32-1.23v-1.2a2.15,2.15,0,1,0-4.3,0v1.2Z"/>
                </g>
            </g>
        </svg>
    }
}

function cheackDateViewIcons(props, typeDate){
    if(props.auctionId !== null){
        if(typeDate === 'start'){
            return viewOnOffIconDate(3)
        }else if(typeDate === 'rectificationPeriod'){
            if(props.auctionId.rectificationPeriod !== null){
                let tmp = Date.now() //текущий датастамп
                let tmpStart = new Date(props.auctionId.rectificationPeriod.startDate)
                tmpStart = tmpStart.getTime()
                let tmpEnd = new Date(props.auctionId.rectificationPeriod.endDate)
                tmpEnd = tmpEnd.getTime()
                if(tmpStart > tmp){
                    return viewOnOffIconDate(1)
                }else if(tmpStart <= tmp && tmpEnd >= tmp){
                    return viewOnOffIconDate(2)
                }else if(tmpEnd < tmp){
                    return viewOnOffIconDate(3)
                }
            }
        }else if(typeDate === 'questionPeriod'){
            if(props.auctionId.questionPeriod !== null){
                let tmp = Date.now() //текущий датастамп
                let tmpStart = new Date(props.auctionId.questionPeriod.startDate)
                tmpStart = tmpStart.getTime()
                let tmpEnd = new Date(props.auctionId.questionPeriod.endDate)
                tmpEnd = tmpEnd.getTime()
                if(tmpStart > tmp){
                    return viewOnOffIconDate(1)
                }else if(tmpStart <= tmp && tmpEnd >= tmp){
                    return viewOnOffIconDate(2)
                }else if(tmpEnd < tmp){
                    return viewOnOffIconDate(3)
                }
            }
        }else if(typeDate === 'transferPriorityPeriod'){
            if(props.auctionId.hasOwnProperty('transferPriorityPeriod')){
                let tmp = Date.now() //текущий датастамп
                let tmpStart = new Date(props.auctionId.transferPriorityPeriod.startDate)
                tmpStart = tmpStart.getTime()
                let tmpEnd = new Date(props.auctionId.transferPriorityPeriod.endDate)
                tmpEnd = tmpEnd.getTime()
                if(tmpStart > tmp){
                    return viewOnOffIconDate(1)
                }else if(tmpStart <= tmp && tmpEnd >= tmp){
                    return viewOnOffIconDate(2)
                }else if(tmpEnd < tmp){
                    return viewOnOffIconDate(3)
                }
            }
        }else if(typeDate === 'tenderPeriod'){
            let tmp = Date.now() //текущий датастамп
            let tmpStart = new Date(props.auctionId.tenderPeriod.startDate)
            tmpStart = tmpStart.getTime()
            let tmpEnd = new Date(props.auctionId.tenderPeriod.endDate)
            tmpEnd = tmpEnd.getTime()
            if(tmpStart > tmp){
                return viewOnOffIconDate(1)
            }else if(tmpStart <= tmp && tmpEnd >= tmp){
                return viewOnOffIconDate(2)
            }else if(tmpEnd < tmp){
                return viewOnOffIconDate(3)
            }
        }else if(typeDate === 'auctionPeriod'){
            if(props.auctionId.auctionPeriod !== null){
                let tmp = Date.now() //текущий датастамп
                let tmpStart = new Date(props.auctionId.auctionPeriod.startDate)
                tmpStart = tmpStart.getTime()
                if(tmpStart > tmp){
                    return viewOnOffIconDate(1)
                }else if(tmpStart <= tmp){
                    if(props.auctionId.status === 'active_auction'){
                        return viewOnOffIconDate(2)
                    }else{
                        return viewOnOffIconDate(3)
                    }
                }
            }
        }else if(typeDate === 'admissionPeriod'){
            if(props.auctionId.hasOwnProperty("admissionPeriod") && props.auctionId.admissionPeriod !== null){
                let tmp = Date.now() //текущий датастамп
                let tmpStart = new Date(props.auctionId.admissionPeriod.startDate)
                tmpStart = tmpStart.getTime()
                if(tmpStart > tmp){
                    return viewOnOffIconDate(1)
                }else if(tmpStart <= tmp){
                    if(props.auctionId.status === 'active_qualification'){
                        return viewOnOffIconDate(2)
                    }else{
                        return viewOnOffIconDate(3)
                    }
                }
            }else{
                return viewOnOffIconDate(1)
            }
        }else if(typeDate === 'activeQualification'){
            if(props.auctionId.status === 'active_qualification' || props.auctionId.status === 'active_awarded'){
                return viewOnOffIconDate(2)
            }else if(props.auctionId.status === 'complete'){
                return viewOnOffIconDate(3)
            }else{
                return viewOnOffIconDate(1)
            }
        }else if(typeDate === 'auctionEnd'){
            if(props.auctionId.status === 'complete' || props.auctionId.status === 'cancelled' || props.auctionId.status === 'unsuccessful'){
                return viewOnOffIconDate(3)
            }else{
                return viewOnOffIconDate(1)
            }
        }
    }
    return null
}

function cheackDateFontText(props, typeDate){
    if(props.auctionId !== null){
        if(typeDate === 'start'){
            return null
        }else if(typeDate === 'questionPeriod'){
            if(props.auctionId.questionPeriod !== null){
                let tmp = Date.now() //текущий датастамп
                let tmpStart = new Date(props.auctionId.questionPeriod.startDate)
                tmpStart = tmpStart.getTime()
                let tmpEnd = new Date(props.auctionId.questionPeriod.endDate)
                tmpEnd = tmpEnd.getTime()
                if(tmpStart > tmp){
                    return styles.inactive
                }else if(tmpStart <= tmp && tmpEnd >= tmp){
                    return null
                }else if(tmpEnd < tmp){
                    return null
                }
            }
        }else if(typeDate === 'transferPriorityPeriod'){
            if(props.auctionId.hasOwnProperty('transferPriorityPeriod')){
                let tmp = Date.now() //текущий датастамп
                let tmpStart = new Date(props.auctionId.transferPriorityPeriod.startDate)
                tmpStart = tmpStart.getTime()
                let tmpEnd = new Date(props.auctionId.transferPriorityPeriod.endDate)
                tmpEnd = tmpEnd.getTime()
                if(tmpStart > tmp){
                    return styles.inactive
                }else if(tmpStart <= tmp && tmpEnd >= tmp){
                    return null
                }else if(tmpEnd < tmp){
                    return null
                }
            }
        }else if(typeDate === 'tenderPeriod'){

            let tmp = Date.now() //текущий датастамп
            let tmpStart = new Date(props.auctionId.tenderPeriod.startDate)
            tmpStart = tmpStart.getTime()
            let tmpEnd = new Date(props.auctionId.tenderPeriod.endDate)
            tmpEnd = tmpEnd.getTime()
            if(tmpStart > tmp){
                return styles.inactive
            }else if(tmpStart <= tmp && tmpEnd >= tmp){
                return null
            }else if(tmpEnd < tmp){
                return null
            }
        }else if(typeDate === 'auctionPeriod'){
            if(props.auctionId.auctionPeriod !== null){
                let tmp = Date.now() ///текущий датастамп
                let tmpStart = new Date(props.auctionId.auctionPeriod.startDate)
                tmpStart = tmpStart.getTime()
                if(tmpStart > tmp){
                    return styles.inactive
                }else if(tmpStart <= tmp){
                    if(props.auctionId.status === 'active_auction'){
                        return null
                    }else{
                        return null
                    }
                }
            }
        }else if(typeDate === 'admissionPeriod'){
            if(props.auctionId.hasOwnProperty("admissionPeriod") && props.auctionId.admissionPeriod !== null){
                let tmp = Date.now()
                let tmpStart = new Date(props.auctionId.admissionPeriod.startDate)
                tmpStart = tmpStart.getTime()
                if(tmpStart > tmp){
                    return styles.inactive
                }else if(tmpStart <= tmp){
                    if(props.auctionId.status === 'active_qualification'){
                        return null
                    }else{
                        return null
                    }
                }
            }else{
                return styles.inactive
            }
        }else if(typeDate === 'activeQualification'){
            if(props.auctionId.status === 'active_qualification' || props.auctionId.status === 'active_awarded'){
                return null
            }else if(props.auctionId.status === 'complete'){
                return null
            }else{
                return styles.inactive
            }
        }else if(typeDate === 'auctionEnd'){
            if(props.auctionId.status === 'complete' || props.auctionId.status === 'cancelled' || props.auctionId.status === 'unsuccessful'){
                return null
            }else{
                return styles.inactive
            }
        }
    }
    return null
}

function viewIsFinAuth(props){
    if(props.userProfiles === null) return null
    if(props.userProfiles.isFinAuth === true) return null
    if(props.userProfiles?.organization?.identifier?.scheme === "UA-IPN"){
        return (
            <div className={styles.balance}>
                <h2>Пройти фінансову ідентифікацію</h2>
                <div>Для авторизації кабінету Вам необхідно направити на пошту info@dto.com.ua копії паспорту та ІПН</div>
            </div>
        )
    }else{
        return (
            <div className={styles.balance}>
                <h2>Пройти фінансову ідентифікацію</h2>
                <div 
                    className={styles.balanceBtn + ' ' + styles.Btn}
                    onClick={
                        () => {
                            getIsFinAuth(props)
                            props.paymentPopup1(props.auctionId)
                        }
                    }
                >Фінансова ідентифікація</div>
            </div>
        )
    }
}

async function getIsFinAuth(props){
    let endPoint = null
    endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/userProfile/payments/finAuth`;

    const response = await fetch(endPoint, {
        method: "GET",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
        
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true && json.hasOwnProperty('bill') === false){
            let tmp = JSON.stringify({title: 'Помилка при отриманні даних для рахунка', message: JSON.stringify(json)})
	        props.setUniversalError(tmp)
        }else{
            let tmpArr = []
            tmpArr[0] = json
            props.paymentPopupGetData(tmpArr)
        }
    }
}


const mapStateToProps = state => {
    return {
        auctionId: state.start.auctionFull,
        token: state.start.token,
        userProfiles: state.start.userProfiles,
    }
}

const mapDispatchToProps = {
    paymentPopup,
    paymentPopup1,
    paymentPopupGetData,
    setUniversalError,
}

export default connect(mapStateToProps, mapDispatchToProps)(RightBlock)