import React, {useState, useEffect} from 'react'
import styles from './addDocPopup.module.css'

import {connect} from 'react-redux';

import {
    auctionsBidsGetId,
} from '../../../redux/actions/auctionBid/auctionBid.js';

import {
    auctionsGetAwards,
} from '../../../redux/actions/awards/awards.js';
import {
    saveArrBids,
} from '../../../redux/allAuctionBids.js';
import {contractsAuctionIdGet} from '../../../redux/actions/contracts/contracts.js';

import ESignCheckBlock from '../../elements/ecp/ecpCheckBlock';

import Input from '../docInputs/input'
import TextArea from '../docInputs/textArea'
import SelectDocType from '../docType/docTypeSelect'

import { setBidDocTypes, setAucDocTypes } from '../../../redux/docTypes';
import { bidKEPCreate } from '../buttons/logic/bidLogic'
import { createAwardKEPUrl } from '../buttons/logic/awardLogic';
import { createAucKEPUrl } from '../buttons/logic/aucLogic';
import { contractKEPCreate} from '../buttons/logic/contractLogic';

import {
    isLLE,
    isLLD, isLLP, isLRE, isLSE, isLSP, isGFE, isGFD, isBSD, isBSE,
    isALE, isCSE, isCSD, isBRE, isBRD, isBRW, isRLE, isRLD,
    isCLE, isCLD, isNLE, isNLD, isLAE, isLAW, isLAP,
    isLPE, isAPE, isAPD
} from '../../../redux/procedure';

import { checkProp, isMongo } from '../../../redux/check';

import {setDataAddress} from '../../../redux/createAddress';

import {getUpdateDataAuction, getUpdateDataBid} from '../../product/updateData'

import {
    changeLoader,
    setUniversalError
} from '../../../redux/actions.js';

const AddDocPopUp = (props) => {
    const [dropArea, setDropArea] = useState({ text: 'Додайте файли документів', style: styles.dropZone })
    const [docTypeValue, setDocTypeValue] = useState(checkProp(props, 'docData') && props.docData !== undefined ? props.docData.documentType : null)
    const [docTitleValue, setDocTitleValue] = useState(checkProp(props, 'docData') && checkProp(props.docData, 'title') ? isMongo(props.docData.title) : '')
    const [docImageSort, setDocImageSort] = useState(checkProp(props, 'docData') && props.docData.index !== undefined ? props.docData.index : '')
    const [docDesriptionValue, setDocDesriptionValue] = useState(checkProp(props, 'docData') && checkProp(props.docData, 'description') ? isMongo(props.docData.description) : '')
    const [upFile, setUpFile] = useState(null)
    const [ecpView, setEcpView] = useState(false)

    useEffect(() => {
        document.body.style.overflow = 'hidden'
        document.body.style.paddingRight = '5px'
        return () => {
            document.body.style.overflow = 'auto'
            document.body.style.paddingRight = '0'
        }
    }, [])

    function dragStartHandler(e) {
        e.preventDefault()
        setDropArea({text: 'Відпустіть мишу для завантаження файлу', style: styles.dropZoneActive })
    }

    function dragLeaveHandler(e) {
        e.preventDefault()
        setDropArea({text: 'Додайте файли документів', style: styles.dropZone })
    }
    
    function onDporHandler(e) {
        e.preventDefault()
        setUpFile(e.dataTransfer.files[0])
        setDropArea({ style: styles.dropZoneActive })
    }
    let dataFilesAtribute = {}
        dataFilesAtribute.actionNo = props.actionClose
        dataFilesAtribute.aucType = props.scopeData.aucType
        dataFilesAtribute.aucStatus = props.scopeData.aucStatus
        dataFilesAtribute.reload = props.scopeData.reload
        // dataFilesAtribute.closeAC = null
    
    let URLs = getKEPUrls(props)

    function getKEPUrls(props) {
        switch (props.scopeData.typeOf) {
            case 'bid':
                return bidKEPCreate(props)
            case 'award':
            case 'bidAward':
                return createAwardKEPUrl(props)
            case 'auctionEdit':
                return createAucKEPUrl(props)
            case 'contract':
                return contractKEPCreate(props)
        }
    }

    function setAucDocTypesList(props) {
        if (props.scopeData !== undefined && props.scopeData !== null) {
            let tmpType = null
            if(props.auctionId !== null){
                tmpType = props.auctionId.isPerishable
            }
            switch (props.scopeData.typeOf) {
                case 'bid':
                    if (isLRE(props.scopeData.aucType) || isLSE(props.scopeData.aucType) || isLSP(props.scopeData.aucType)) {
                        return setBidDocTypes(props.scopeData.aucType, props.scopeData.preDisqual)
                    }
                    if(isLPE(props.scopeData.aucType)){
                        return setBidDocTypes(props.scopeData.aucType, null, props.userProfiles.organization.identifier.scheme)
                    }
                    if(isAPE(props.scopeData.aucType) || isAPD(props.scopeData.aucType)){
                        if(props.auctionFull.disqualifiedBids === null || props.auctionFull.disqualifiedBids.length === 0){
                            return setBidDocTypes(props.scopeData.aucType, null, props.userProfiles.organization.identifier.scheme)
                        }
                        if(props.auctionFull.disqualifiedBids !== null
                            || props.auctionFull.disqualifiedBids.length > 0
                        ){
                            for(let tt = 0; tt <= props.auctionFull.disqualifiedBids.length; tt++){
                                if(props.auctionFull.disqualifiedBids[tt] === props.userProfiles.organization.identifier.identifier) return setBidDocTypes(props.scopeData.aucType, null, props.userProfiles.organization.identifier.scheme, true)
                            }
                        }
                        return setBidDocTypes(props.scopeData.aucType, null, props.userProfiles.organization.identifier.scheme)

                    }
                    return setBidDocTypes(props.scopeData.aucType)
                case 'auction': return setAucDocTypes(props.auctionId)
                case 'auctionEdit': return setAucDocTypes(props.auctionId)
                case 'award': return props.scopeData.docTypesList
                case 'bidAward': return props.scopeData.docTypesList
                case 'contract': return props.scopeData.docTypesList
                case 'prolongation': return props.scopeData.docTypesList
                case 'cancellation': 
                    //return props.scopeData.docTypesList
                    if(isAPE(props.scopeData.aucType) || isAPD(props.scopeData.aucType)){
                        return [{ value: 'cancellationDetails', name: 'Рішення про скасування', main: null }]    
                    }
                    return [{ value: 'cancellationDetails', name: 'Причини скасування', main: null }]
                default: return null
            }
        }
    }

    if(upFile !== null){
        dataFilesAtribute.fileName = docTitleValue
        dataFilesAtribute.fileType = docTypeValue
        dataFilesAtribute.fileDiscription = docDesriptionValue
        dataFilesAtribute.fileFile = upFile
        dataFilesAtribute.uploadUrlNew = checkProp(URLs, 'urlNew') ? URLs.urlNew : null
        dataFilesAtribute.uploadUrl = checkProp(URLs, 'url') ? URLs.url : null
        dataFilesAtribute.uploadUrlKep = checkProp(URLs, 'urlKep') ? URLs.urlKep : null
        dataFilesAtribute.documentOf = checkProp(props, 'docData') ? props.docData.documentOf : props.scopeData.typeOf
        dataFilesAtribute.id = checkProp(props, 'docData') ? props.docData.id : null
        dataFilesAtribute.relatedDocument = checkProp(props, 'docData') ? props.docData.relatedDocument : null
        dataFilesAtribute.changeLoader = props.changeLoader
        //dataFilesAtribute.combineUpadateKEP = combineUpadateKEP
        // dataFilesAtribute.auctionsGetAwards = props.auctionsGetAwards
        // dataFilesAtribute.contractsAuctionIdGet = props.contractsAuctionIdGet
        // dataFilesAtribute.auctionsBidsGetId = props.auctionsBidsGetId
        dataFilesAtribute.aucId = props.scopeData.aucId
        dataFilesAtribute.bidId = props.scopeData.bidId
        dataFilesAtribute.viewBtns = ecpView
        dataFilesAtribute.setViewBtns = setEcpView
        dataFilesAtribute.reload = props.scopeData.reload
    }

    let docType = {}
    docType.label = 'Тип документу'
    docType.target = docTypeValue
    docType.action = setDocTypeValue
    docType.necessarily = true
    docType.docList = setAucDocTypesList(props)
    docType.disabled = false

    let docTitle = {}
    docTitle.label = 'Публічна назва документу'
    docTitle.target = docTitleValue
    docTitle.action = setDocTitleValue
    docTitle.necessarily = true

    let docDescpiption = {}
    docDescpiption.label = 'Опис документу'
    docDescpiption.target = docDesriptionValue
    docDescpiption.action = setDocDesriptionValue

    let docImgIndex = {}
    docImgIndex.label = 'Порядок сортування зображень'
    docImgIndex.target = docImageSort
    docImgIndex.action = setDocImageSort
    docImgIndex.type = 'number'
    docImgIndex.range = {min: 0, max: 100}

    function activateECP(props) {
        // if (
        //     props.scopeData.aucStatus === 'draft' ||
        //     props.scopeData.aucStatus === null ||
        //     props.scopeData.bidStatus === 'draft' ||
        //     props.scopeData.bidStatus === null ||
        //     props.scopeData.bidId === null
        // ) {
        //     return null
        // }
        if(
            docTitleValue !== '' &&
            docTitleValue !== null &&
            docTypeValue !== null &&
            upFile !== null
        ){
            return viewECP(dataFilesAtribute, true)
        }
        return viewECP(dataFilesAtribute, false)
    }

    function viewECP(data, status){
        return <ESignCheckBlock 
                    dataFilesAtribute={data}
                    active={status}
                /> 
    }
    
    function buttonsBlock(props) {
        if(!ecpView){
            return (
                <div className={styles.bottomPart}>
                    <div className={styles.btnCancel}
                        onClick={
                            () => {
                                props.actionClose()
                            }
                        }
                    >
                        Скасувати
                    </div>
                    {viewButtons(props)}
                </div>
            )
        }
    return null
    }

    function viewButtons(props) {
        if (
            props.hasOwnProperty('docData') &&
            props.docData.hasOwnProperty('id') &&
            docTitleValue.trim() !== '' &&
            docTitleValue !== null &&
            docTypeValue !== null &&
            docTypeValue !== 'null' &&
            upFile !== null
        ){
            return (
                <div className={styles.btnDone}
                    onClick={() => patchNewDocFiles(props, docTypeValue, docTitleValue, docDesriptionValue, docImageSort, upFile)}
                >
                    Зберегти
                </div>
            )
        } else if (
            props.hasOwnProperty('docData') &&
            props.docData.hasOwnProperty('id') &&
            docTitleValue.trim() !== '' &&
            docTitleValue !== null &&
            docTypeValue !== null &&
            docTypeValue !== 'null' &&
            upFile === null
        ) {
            return (
                <div className={styles.btnDone}
                    onClick={() => patchNewDocWithoutFiles(props, docTypeValue, docTitleValue, docDesriptionValue, docImageSort)}
                >
                    Зберегти
                </div>
            )
        } else if(
            docTitleValue.trim() !== '' &&
            docTitleValue !== null &&
            docTypeValue !== null &&
            docTypeValue !== 'null' &&
            upFile !== null
        ) {
            return (
                <div className={styles.btnDone}
                    onClick={() => uploadNewDoc(props, docTypeValue, docTitleValue, docDesriptionValue, docImageSort, upFile)}
                >
                    Зберегти
                </div>
            )
        }
        return null
    }

    return (
            <div className={styles.wrapMain}>
                <div className={styles.wrap}>
                    <div
                        className={styles.close}
                        onClick={
                            ()=>{
                                props.actionClose()
                                // props.setDataAddress(null, 'BID_POPUP_EDIT_DOC')
                            }
                        }
                    >
                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path className={styles.closeSVG} fillRule="evenodd" clipRule="evenodd" d="M8.41421 6.99989L13.364 11.9496C13.7545 12.3402 13.7545 12.9733 13.364 13.3639C12.9734 13.7544 12.3403 13.7544 11.9497 13.3639L7 8.41411L2.05025 13.3639C1.65973 13.7544 1.02656 13.7544 0.636039 13.3639C0.245514 12.9733 0.245515 12.3402 0.636039 11.9496L5.58579 6.99989L0.636039 2.05015C0.245515 1.65962 0.245514 1.02646 0.636039 0.635934C1.02656 0.245409 1.65973 0.245409 2.05025 0.635934L7 5.58568L11.9497 0.635934C12.3403 0.245409 12.9734 0.245409 13.364 0.635934C13.7545 1.02646 13.7545 1.65962 13.364 2.05015L8.41421 6.99989Z" fill="#92929D"/>
                        </svg>
                    </div>
                        {namePopUp(props, docType)}
                    <div className={styles.bidLotCost_input}>
                        {/* {viewSelect(props, docTypeValue, docType)} */}
                        <SelectDocType data={docType} />
                        <Input data={docTitle} />
                        <TextArea data={docDescpiption} />
                        {docTypeValue === 'illustration' ? <Input data={docImgIndex} /> : null}
                        <div className={dropArea.style}
                            onDragStart={ e => dragStartHandler(e)}
                            onDragLeave={ e => dragLeaveHandler(e)}
                            onDragOver={ e => dragStartHandler(e)}
                            onDrop={ e => onDporHandler(e)}
                        >
                            <div>
                                {upFile ? <>
                                            <div>{`Додано файл:
                                                ${upFile.name.length > 45 ? upFile.name.substr(0, 50) + '...' : upFile.name}`}
                                            </div>
                                            <div>
                                                {`розмір: ${(upFile.size / 1000000).toFixed(2)} Mb, тип: ${upFile.type}`}
                                            </div>
                                        </> : dropArea.text
                                }
                            </div>
                            <label className={styles.btnPin}>Прикріпити
                                <input type="file"
                                    name="file"
                                    id="inputFile"
                                    onInput={(e) => {
                                        e.preventDefault()
                                        setUpFile(e.target.files[0])
                                        setDropArea({
                                            style: styles.dropZoneActive
                                        })
                                    }}>
                                </input>
                            </label>
                    </div>
                        {activateECP(props)}
                    </div>
                    {buttonsBlock(props, upFile, docTypeValue, docTitleValue, docDesriptionValue)}
                </div>
            </div>
    )
}

function namePopUp(props, docType){
    //scopeData.aucOwner
    if(props?.auctionId?.status === null){
        return (
            <>
                <div className={styles.popupTitle}>Редагувати документ</div>
                <div className={styles.description}>Ви додаєте документ до вашої процедури</div>
            </>
        )
    }
    if(props.docData !== null && props.docData !== undefined){
        if (props.docData.hasOwnProperty('id')) {
            docType.disabled = true
            return (
                <>
                    <div className={styles.popupTitle}>Редагувати документ</div>
                    {props?.auctionFull?.privateData?.isOwner === true
                        ? <div className={styles.description}>Ви додаєте документ до вашої процедури</div>
                        : <div className={styles.description}>Ви додаєте документ до вашої пропозиції</div>
                    }
                </>
            )
        }
    }
    return (
        <>
            <div className={styles.popupTitle}>Додати документ</div>
            {props?.auctionFull?.privateData?.isOwner === true
                ? <div className={styles.description}>Ви додаєте документ до вашої процедури</div>
                : <div className={styles.description}>Ви додаєте документ до вашої пропозиції</div>
            }
        </>
    )
}

async function uploadNewDoc(props, docTypeValue, docTitleValue, docDesriptionValue, docImageSort, upFile){
    await props.changeLoader(true)
    let endPoint = ``;
    if( isGFE(props.scopeData.aucType) || isGFD(props.scopeData.aucType)){
        endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/documents/upload?isDgf=1`;
    }else{
        endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/documents/upload`;
        //endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/documents/upload`;
        //endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/documents/upload?documentType=${docTypeValue}&scope=${tmpUrlScope}`;
    }
    const formData = new FormData();
    formData.append('documentType', docTypeValue)
    let uPFile = upFile
    // uPFile.name = ` ${upFile.name}` //TODO: Пофиксить русский язык файлов
    formData.append('file', uPFile)
    // if( (isSPE(props.scopeData.aucType) || isSPD(props.scopeData.aucType)) && (docTypeValue === 'noRestrictionsStatement' || docTypeValue === 'objectAcquaintance')){
    //     formData.append('scope', 'private')
    // }else{
    //     formData.append('scope', 'public')
    // }
    if(isLPE(props.scopeData.aucType)){
        if(docTypeValue === 'agreement'){
            formData.append('scope', 'private')
        }else{
            formData.append('scope', 'public')
        }
    }
    const response = await fetch(endPoint, {
        method: "POST",
        body: formData,
        headers: {
            'Authorization': props.token.access_token,
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){
            let tmp = JSON.stringify({title: 'Помилка при збереженні файлу', message: JSON.stringify(json)})
            props.setUniversalError(tmp)
            await props.changeLoader(false)
        }else{
            let endPoint1 = ''
            let body = {}
            if(isNLE(props.scopeData.aucType) || isNLD(props.scopeData.aucType)){
                //if(docTitleValue !== null && docTitleValue !== '') body.title = { uk_UA: docTitleValue.trim() }
                if(docTitleValue !== null && docTitleValue !== '') body.title = docTitleValue.trim()
                //if(docDesriptionValue !== null && docDesriptionValue !== '') body.description = { uk_UA: docDesriptionValue.trim() }
                if(docDesriptionValue !== null && docDesriptionValue !== '') body.description = docDesriptionValue.trim()
            }else{
                if(docTitleValue !== null && docTitleValue !== '') body.title = docTitleValue.trim()
                if(docDesriptionValue !== null && docDesriptionValue !== '') body.description = docDesriptionValue.trim()
            }
            if(docTypeValue !== null && docTypeValue !== '') body.documentType = docTypeValue
            if(docImageSort !== null && docImageSort !== '') body.index = docImageSort
            body.language = 'uk'
            if(props.scopeData.typeOf === 'prolongation'){
                body.documentOf = 'prolongation'
            }else if(props.scopeData.typeOf === 'auctionEdit'){
                body.documentOf = 'auction'
            }else if(props.scopeData.typeOf === 'bidAward'){
                    body.documentOf = 'bid'
            }else{
                body.documentOf = props.scopeData.typeOf
            }

            if(!isLAE(props.scopeData.aucType) && !isLAW(props.scopeData.aucType) && !isLAP(props.scopeData.aucType)){
                endPoint1 = `${props.scopeData.uploadNewDocURL}`
            }else{
                if(props.scopeData.typeOf === 'bidAward' && docTypeValue === 'act'){
                    endPoint1 = `${props.scopeData.uploadNewDocURLAct}`
                    body.documentOf = 'award'
                }else{
                    endPoint1 = `${props.scopeData.uploadNewDocURL}`
                }
                
            }

            body.token = json.jwt
            const response1 = await fetch(endPoint1, {
                method: "POST",
                body: JSON.stringify(body),
                headers: {
                    'Authorization': props.token.access_token,
                    'Content-Type': 'application/json'
                }
            }).catch(console.error)
            if(response1 === undefined || response1 === 'undefined'){
            }else{
                const json2 = await response1.json()
                if(json2.hasOwnProperty('message') === true){
                    let tmp = JSON.stringify({title: 'Помилка при збереженні документу', message: JSON.stringify(json2)})
                    props.setUniversalError(tmp)
                    await props.changeLoader(false)
                }else{
                }
            }
        }
    }
    await setTimeout(() => {props.scopeData.reload()}, 2000)
    // await setTimeout(() => { 
    //     getUpdateDataAuctions(props)
        
    // }, 2000)
    await props.actionClose()
    await props.changeLoader(false)
}

async function patchNewDocWithoutFiles(props, docTypeValue, docTitleValue, docDesriptionValue, docImageSort){
    await props.changeLoader(true)
    let method = "PATCH"

    if(
        (props.scopeData.typeOf === 'auctionEdit' ||  props.scopeData.typeOf === 'auction')
        && (
        isLLE(props.scopeData.aucType) || isLLD(props.scopeData.aucType) || isLLP(props.scopeData.aucType) ||
        isBSE(props.scopeData.aucType) || isBSD(props.scopeData.aucType) ||
        isALE(props.scopeData.aucType) ||
        isCSE(props.scopeData.aucType) || isCSD(props.scopeData.aucType) ||
        isBRE(props.scopeData.aucType) || isBRD(props.scopeData.aucType) || isBRW(props.scopeData.aucType) ||
        isRLE(props.scopeData.aucType) || isRLD(props.scopeData.aucType) ||
        isCLE(props.scopeData.aucType) || isCLD(props.scopeData.aucType) ||
        isNLE(props.scopeData.aucType) || isNLD(props.scopeData.aucType) ||
        isAPE(props.scopeData.aucType) || isAPD(props.scopeData.aucType)
        || isLRE(props.scopeData.aucType) || isLSE(props.scopeData.aucType) || isLSP(props.scopeData.aucType)
    //     isLAE(props.scopeData.aucType) || isLAW(props.scopeData.aucType) || isLAP(props.scopeData.aucType)
        )
        && props.scopeData.aucStatus === null
    ) method = 'PUT' //заглушка для апи в2
    // if(isSPE(props.scopeData.aucType) && props.scopeData.aucStatus !== null && props.docData.documentOf === 'bid' ){
    //     method = 'PUT'
    // }
    // if(isSPD(props.scopeData.aucType) && props.scopeData.aucStatus !== null && props.docData.documentOf === 'bid' ){
    //     if(props.scopeData.bidStatus === 'draft' || props.scopeData.bidStatus === 'active'){
    //         method = 'PATCH'
    //     }else{
    //         method = 'PUT'
    //     }
    // }

    let endPoint1 = ''
     // выбираэм кудой загружать доки в бид аварда - URL
        if(props.scopeData.typeOf === 'bidAward' && props.docData.documentOf === 'bid') endPoint1 = props.scopeData.patchNewDocWoutFilesURL
        else endPoint1 = `${props.scopeData.patchNewDocWoutFilesURL}/${props.docData.id}`


    let body = {}

    if(isNLE(props.scopeData.aucType) || isNLD(props.scopeData.aucType)){
        //if(docTitleValue !== null && docTitleValue !== '') body.title = { uk_UA: docTitleValue.trim() }
        if(docTitleValue !== null && docTitleValue !== '') body.title = docTitleValue.trim()
        //if(docDesriptionValue !== null && docDesriptionValue !== '') body.description = { uk_UA: docDesriptionValue.trim() }
        if(docDesriptionValue !== null && docDesriptionValue !== '') body.description = docDesriptionValue.trim()
    }else{
        if(docTitleValue !== null && docTitleValue !== '') body.title = docTitleValue.trim()
        if(docDesriptionValue !== null && docDesriptionValue !== '') body.description = docDesriptionValue.trim()
    }
    if(docTypeValue !== null && docTypeValue !== '') body.documentType = docTypeValue
    if(docImageSort !== null && docImageSort !== '') body.index = docImageSort
    body.language = 'uk'
    //body.documentOf = props.docData.documentOf
    //body.documentOf = props.scopeData.typeOf === 'auctionEdit' ? 'auction' : props.scopeData.typeOf
    if(props.scopeData.typeOf === 'prolongation'){
        body.documentOf = 'prolongation'
    }else if(props.scopeData.typeOf === 'auctionEdit'){
        body.documentOf = 'auction'
    }else if(props.scopeData.typeOf === 'bidAward'){
            body.documentOf = 'bid'
    }else{
        body.documentOf = props.scopeData.typeOf
    }


    const response1 = await fetch(endPoint1, {
        method: method,
        body: JSON.stringify(body),
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response1 === undefined || response1 === 'undefined'){
    }else{
        const json2 = await response1.json()
        if(json2.hasOwnProperty('message') === true){
            let tmp = JSON.stringify({title: 'Помилка при збереженні документу', message: JSON.stringify(json2)})
            props.setUniversalError(tmp)
            await props.changeLoader(false)
        }else{
        }
    }
    await setTimeout(() => { props.scopeData.reload() }, 2000)
    // await setTimeout(() => { getUpdateDataAuctions(props) }, 2000)
    await props.actionClose()
    await props.changeLoader(false)
}

async function patchNewDocFiles(props, docTypeValue, docTitleValue, docDesriptionValue, docImageSort, upFile){
    await props.changeLoader(true)
    let method = "PATCH"

    if(
        (props.scopeData.typeOf === 'auctionEdit' ||  props.scopeData.typeOf === 'auction')
        && (
        isLLE(props.scopeData.aucType) || isLLD(props.scopeData.aucType) || isLLP(props.scopeData.aucType) ||
        isBSE(props.scopeData.aucType) || isBSD(props.scopeData.aucType) ||
        isALE(props.scopeData.aucType) ||
        isCSE(props.scopeData.aucType) || isCSD(props.scopeData.aucType) ||
        isBRE(props.scopeData.aucType) || isBRD(props.scopeData.aucType) || isBRW(props.scopeData.aucType) ||
        isRLE(props.scopeData.aucType) || isRLD(props.scopeData.aucType) ||
        isCLE(props.scopeData.aucType) || isCLD(props.scopeData.aucType) ||
        isNLE(props.scopeData.aucType) || isNLD(props.scopeData.aucType) ||
        isAPE(props.scopeData.aucType) || isAPD(props.scopeData.aucType)
        || isLRE(props.scopeData.aucType) || isLSE(props.scopeData.aucType) || isLSP(props.scopeData.aucType)
    //     isLAE(props.scopeData.aucType) || isLAW(props.scopeData.aucType) || isLAP(props.scopeData.aucType)
        )
        && props.scopeData.aucStatus === null
    ) method = 'PUT' //заглушка для апи в2
    // if(isSPD(props.scopeData.aucType) && props.scopeData.aucStatus !== null && props.docData.documentOf === 'bid' ){
    //     if(props.scopeData.bidStatus === 'draft' || props.scopeData.bidStatus === 'active'){
    //         method = 'PATCH'
    //     }else{
    //         method = 'PUT'
    //     }
    // }

    let endPoint = ``;
    if( isGFE(props.scopeData.aucType) || isGFD(props.scopeData.aucType)){
        endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/documents/upload?isDgf=1`;
    }else{
        endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/documents/upload`;
    }
    const formData = new FormData();
    formData.append('documentType', docTypeValue)
    formData.append('file', upFile)
    const response =  await fetch(endPoint, {
        method: "POST",
        body: formData,
        headers: {
            'Authorization': props.token.access_token,
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){
            let tmp = JSON.stringify({title: 'Помилка при збереженні файлу', message: JSON.stringify(json)})
            props.setUniversalError(tmp)
            await props.changeLoader(false)
        } else {
            let endPoint1 = ''
            // выбираэм кудой загружать доки в бид аварда - URL
                if(props.scopeData.typeOf === 'bidAward' && props.docData.documentOf === 'bid') endPoint1 = props.scopeData.patchNewDocWoutFilesURL
                //else if(props.scopeData.typeOf === 'prolongation') endPoint1 = props.scopeData.patchNewDocWoutFilesURL
                else endPoint1 = `${props.scopeData.patchNewDocWoutFilesURL}/${props.docData.id}`
                    // let endPoint1 = `${props.scopeData.patchNewDocFilesURL}/${props.docData.id}`
            let body = {}
            if(isNLE(props.scopeData.aucType) || isNLD(props.scopeData.aucType)){
                // if(docTitleValue !== null && docTitleValue !== '') body.title = { uk_UA: docTitleValue.trim() }
                if(docTitleValue !== null && docTitleValue !== '') body.title = docTitleValue.trim()
                // if(docDesriptionValue !== null && docDesriptionValue !== '') body.description = { uk_UA: docDesriptionValue.trim() }
                if(docDesriptionValue !== null && docDesriptionValue !== '') body.description = docDesriptionValue.trim()
            }else{
                if(docTitleValue !== null && docTitleValue !== '') body.title = docTitleValue.trim()
                if(docDesriptionValue !== null && docDesriptionValue !== '') body.description = docDesriptionValue.trim()
            }
            if(docTypeValue !== null && docTypeValue !== '') body.documentType = docTypeValue
            if(docImageSort !== null && docImageSort !== '') body.index = docImageSort
            body.language = 'uk'
            if(props.scopeData.typeOf === 'prolongation'){
                body.documentOf = 'prolongation'
            }else if(props.scopeData.typeOf === 'auctionEdit'){
                body.documentOf = 'auction'
            }else if(props.scopeData.typeOf === 'bidAward'){
                    body.documentOf = 'bid'
            }else{
                body.documentOf = props.scopeData.typeOf
            }
            //body.documentOf = props.scopeData.typeOf !== 'prolongation' ? props.docData.documentOf : 'prolongation'
            //body.documentOf = props.scopeData.typeOf === 'auctionEdit' ? 'auction' : props.scopeData.typeOf
            body.token = json.jwt
            const response1 = await fetch(endPoint1, {
                method: method,
                body: JSON.stringify(body),
                headers: {
                    'Authorization': props.token.access_token,
                    'Content-Type': 'application/json'
                }
            }).catch(console.error)
            if(response1 === undefined || response1 === 'undefined'){
            }else{
                const json2 = await response1.json()
                if(json2.hasOwnProperty('message') === true){
                    let tmp = JSON.stringify({title: 'Помилка при збереженні документу', message: JSON.stringify(json2)})
                    props.setUniversalError(tmp)
                    await props.changeLoader(false)
                }else{
                }
            }
        }
    }
    await setTimeout(() => { props.scopeData.reload() }, 2000)
    // await setTimeout(() => { getUpdateDataAuctions(props) }, 2000)
    await props.actionClose()
    await props.changeLoader(false)
}

async function getUpdateDataAuctions(props){
    const url = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/public/auction/${props.auctionFull.id}`
    //const url = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/public/auction/by_public_id/${props.auctionFull.aucId}`
    let header = null
    if(props.token !== null){
        header = {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }else{
        header = {
            'Content-Type': 'application/json'
        }
    }
    const response = await fetch(url, {
        method: "GET",
        headers: header
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
        //props.setDataAddress(null, 'SET_FULL_AUCTION')
    }else{
        if(
            response.status !== 200 &&
            response.status !== 301 &&
            response.status !== 302
        ){
            props.setDataAddress(response.status, 'SET_FULL_AUCTION')
        }
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){

        }else{
            props.setDataAddress(json, 'SET_FULL_AUCTION')
        }
    }
}

const mapStateToProps = state => {
    return {
        token: state.start.token,
        auctionId: state.start.auctionsMy,
        auctionFull: state.start.auctionFull,
        // bidEdit: state.start.bidEdit,
        togetherApiPage: state.start.togetherApiPage,
        userProfiles: state.start.userProfiles
    }
}

const mapDispatchToProps = {
    // changeBidPopUpEditDocType,
    // changeBidPopUpEditDocTitle,
    auctionsGetAwards,
    auctionsBidsGetId,
    changeLoader,
    setUniversalError,
    contractsAuctionIdGet,
    setDataAddress,
    saveArrBids,
    // changeBidPopUpEditDocDescription,
}

export default connect(mapStateToProps, mapDispatchToProps)(AddDocPopUp)