import React from 'react';

import {connect} from 'react-redux';

import TextHalf from '../../../elements/viewData/textHalf'

import {
    isTIE,
    isSUE, isSUD,
    isRCE, isRCD,
    isGFE, isGFD,
    isLLE, isLLD, isLLP,
    isLRE,
    isLSE, isLSP,
   
    isBSE, isBSD,
    isALE,
    isCSE, isCSD,
    isAAE,
    isBRE, isBRD, isBRW,
    isRLE, isRLD,
    isCLE, isCLD,
    isSPE, isSPD,
    isNLE, isNLD,
    isLAE, isLAW, isLAP,
    isLPE,
    isAPE, isAPD
} from '../../../../redux/procedure.js';

const AccessDetailsString = (props) => {
    if(props.auctionId !== null){
        if(props.auctionId.accessDetails !== null){
            if(isLRE(props.auctionId.sellingMethod) || isLSE(props.auctionId.sellingMethod) || isLSP(props.auctionId.sellingMethod)){
                return (
                    <TextHalf
                        title="Порядок та можливий час ознайомлення з лотом"
                        data={props.auctionId.accessDetails.uk_UA}
                    />
                )    
            }
            return (
                <TextHalf
                    title="Порядок ознайомлення з майном, час і місце проведення огляду об’єкта"
                    data={props.auctionId.accessDetails.uk_UA}
                />
            )
        }
    }
    return null
}

const mapStateToProps = state => {
    return {
        //auctionId: state.start.auctionsGetId,
        auctionId: state.start.auctionFull,
    }
}

export default connect(mapStateToProps, null)(AccessDetailsString)