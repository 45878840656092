import React from 'react';

import {connect} from 'react-redux';

import {setUniversalError} from '../../../../../redux/actions.js';

import {changeCreateAuctionType, changeCreateAuctionTypePrefix} from '../../../../../redux/actions.js';

import {TranslationsUk} from '../../../../../redux/translations.uk.js';

import Select from '../../../../elements/inputs/inputSelect';

import {
    changeCreateAuctionValueTax,
} from '../../../../../redux/actions.js';

import { isLLD, isLLE, isLLP } from '../../../../../redux/procedure';

const WindowAuctionType = ( props ) => {
    let sellingMethodSelect = null
    let sellingMethodDevPrefixes = null
    if(props.auctionsMy !== null && props.userProfiles !== null){
        sellingMethodSelect = <Select
            data={{
                label:TranslationsUk['legitimatePropertyLease-english'].legitimatePropertyLeaseEnglishProcedure.sellingMethod.legalName,
                target: props.auctionsMy.sellingMethod,
                info: 'оберіть тип процедури, за яким проходитиме аукціон',
                required: true
            }}
            onChange={(event)=>{
                props.changeCreateAuctionType(event)
            }}
            options={getSellingMethodOptions(props)}
            error = {redBlock(props, props.auctionsMy.sellingMethod)}
            descr='Оберіть тип аукціону'
        />
        if(Number(process.env.REACT_APP_BUILD_STATUS) === 0){
            //PROD
            //@todo refactor
        }else{
            // DEV
            sellingMethodDevPrefixes = <Select
                data={{
                    label: 'оберіть ПІДТИП процедури (Опція для розробників)',
                    target: props.auctionsMy.sellingMethodPrefix,
                    info: 'оберіть ПІДТИП процедури (Опція для розробників)',
                    required: true
                }}
                onChange={(event) => {
                    props.changeCreateAuctionTypePrefix(event)
                }}
                options={getPrefixOptions(props)}
                disabled={props.auctionsMy.id ? true : false}
                descr='оберіть ПІДТИП процедури (Опція для розробників)'
            />
        }
    }
    return (<>
        {sellingMethodSelect}
        {sellingMethodDevPrefixes}
    </>)
}

function getSellingMethodPrefixList() {
    return [
        '',
        '-auction-prod',
        '-auction-qualification',
        '-fast',
        '-fast-auction-prod',
        '-ultra',
        '-ultra-fast',
        '-manual',
        '-fast-manual',
        '-fast-auction-manual-qualification',
        '-initial-auction',
        '-initial-auction-manual',
        '-initial-qualification',
        '-initial-qualification-prod',
        '-initial-qualification-fast',
    ]
}

//@todo refactor
function getAuctionDataPermissions(){
    return [
        {sellingMethod: "alienation-english", name: "Відчуження майна. Англійський", roles: [  'canCreateAlienation', ]},
        {sellingMethod: "armaProperty-english", name: "Арештовані активи АРМА. Англійський аукціон", roles: ['canCreateArma', ]},
        {sellingMethod: "armaProperty-dutch", name: "Арештовані активи АРМА. Голандський аукціон", roles: ['canCreateArma',] },
        {sellingMethod: "bankRuptcy-english", name: "Продаж майна боржників у справах про банкрутство, Англійський аукціон", roles: ['canCreateBankruptAuction']},
        {sellingMethod: "bankRuptcy-dutch", name: "Продаж майна боржників у справах про банкрутство, Голландський аукціон", roles: ['canCreateBankruptAuction']},
        {sellingMethod: "bankRuptcy-withoutAuction", name: "Продаж майна боржників у справах про банкрутство без аукціону", roles: ['canCreateBankruptAuction']},
        {sellingMethod: "basicSell-english", name: "Базова продаж. Англійський", roles: [ 'canCreateBasicSell',]},
        {sellingMethod: "basicSell-dutch", name: "Базова продаж. Голландський", roles: ['canCreateBasicSell',]},
        {sellingMethod: "commercialPropertyLease-english", name: "Комерційна оренда (англійський аукціон)", roles: ['canCreateAuction',] },
        {sellingMethod: "commercialPropertyLease-dutch", name: "Комерційна оренда (голландський аукціон)", roles: ['canCreateAuction',  ]},
        {sellingMethod: "commercialSell-english", name: "Комерційний продаж. Англійський", roles: [ 'canCreateCommercialSellAuction']},
        {sellingMethod: "commercialSell-dutch", name: "Комерційний продаж. Голландський", roles: [  'canCreateCommercialSellAuction']},
        {sellingMethod: "landArrested-english", name: "Арештована земля. Англійський аукціон",  roles: ['canCreateLandArrestedAuction']},
        {sellingMethod: "landArrested-withoutAuction", name: "Арештована земля. Викуп", roles:  ['canCreateLandArrestedAuction']},
        {sellingMethod: "landArrested-priorityEnglish", name: "Арештована земля з переважним правом",   roles:['canCreateLandArrestedAuction']},
        {sellingMethod: "landRental-english", name: "Земельні торги - оренда", roles: [ 'canCreateLandAuction',]},
        {sellingMethod: "landSell-english", name: "Земельні торги - продаж", roles: ['canCreateLandAuction',]},
        {sellingMethod: "landSell-priorityEnglish", name: "Земельні торги - продаж з переважним правом", roles: [ 'canCreateLandAuction', ]},
        {sellingMethod: "largePrivatization-english", name: "Продаж об'єктів великої приватизації. Англійський",  roles:[ 'canCreatePrivatization']},
        {sellingMethod: "legitimatePropertyLease-english", name: "Оренда державного/комунального майна", roles: [ 'canCreateLeaseAuction',] },
        {sellingMethod: "legitimatePropertyLease-dutch", name: "Оренда державного/комунального майна з покроковим зниженням", roles: [ 'canCreateLeaseAuction',]},
        {sellingMethod: "legitimatePropertyLease-priorityEnglish", name: "Оренда державного/комунального майна з використанням переважного права", roles: [ 'canCreateLeaseAuction',]},
        {sellingMethod: "nonperformingLoans-english", name: "Продаж непрацюючих кредитів державних банків: англійський аукціон", roles: ['canCreateAuction', ]},
        {sellingMethod: "nonperformingLoans-dutch", name: "Продаж непрацюючих кредитів державних банків: голандський аукціон", roles: ['canCreateAuction', ]},
        {sellingMethod: "railwayCargo-english", name: "Вантажні залізничні вагони. Англійський", roles: ['canCreateAuction',]},
        {sellingMethod: "railwayCargo-dutch", name: "Вантажні залізничні вагони. Голландський", roles: ['canCreateAuction',] },
        {sellingMethod: "regulationsPropertyLease-english", name: "Оренда за регламентом (англійський аукціон)", roles: ['canCreateAuction', ]},
        {sellingMethod: "regulationsPropertyLease-dutch", name: "Оренда за регламентом (голландський аукціон)", roles: ['canCreateAuction',] },
        {sellingMethod: "renewables-multiAwards", name: "Зелена енергетика: продаж квот", roles:[ 'canCreateRenewablesAuction', ]},
        {sellingMethod: "smallPrivatization-english", name: "Продаж об'єктів малої приватизації. Англійський", roles: ['canCreatePrivatization']},
        {sellingMethod: "smallPrivatization-dutch", name: "Продаж об'єктів малої приватизації. Голландський", roles: [  'canCreatePrivatization']},
        {sellingMethod: "subsoil-english", name: "Надра. Англійський", roles: ['canCreateAuction']},
        {sellingMethod: "subsoil-dutch", name: "Надра. Голландський", roles: ['canCreateAuction']},
    ]
}

function getAllowedSellingMethodList(props) {
    const userProfile = props.userProfiles
    return getAuctionDataPermissions(props)
        .filter(
            (item) => {
                for (const role of item.roles) {
                    if (userProfile?.[role]) {
                        return true
                    }
                }
            }
        )
}
function getPrefixOptions(props) {
    return getSellingMethodPrefixList()
        .map(
            (item) => {
                return {value: item, name: item}
            }
        )
}

function getSellingMethodOptions(props) {
    return getAllowedSellingMethodList(props)
        .map(
            (item) => {
                let name = item.name
                if (Number(process.env.REACT_APP_BUILD_STATUS) !== 0) {
                    name = name + ' ' + item.sellingMethod
                }
                return {value: item.sellingMethod, name: name}
            }
        )
}

function redBlock(props, amount){
    if(
        isLLE(props.auctionsMy.sellingMethod) ||
        isLLD(props.auctionsMy.sellingMethod) ||
        isLLP(props.auctionsMy.sellingMethod)
    ){
        if(amount === null || amount === '' || amount === 'null'){
            return 'error'
        }
        return null
    }
    return null
}

const mapStateToProps = state => {
    return {
        auctionsMy: state.start.auctionsMy,
        bildStatus: state.start.bildStatus,
        auctionsZUAttemp: state.start.auctionsZUAttemp,
        auctionsSwitchWindow: state.start.auctionsSwitchWindow,
        userProfiles: state.start.userProfiles,
    }
}

const mapDispatchToProps = {
    changeCreateAuctionType,
    changeCreateAuctionTypePrefix,
    setUniversalError,
    changeCreateAuctionValueTax,
}

export default connect(mapStateToProps, mapDispatchToProps)(WindowAuctionType)
