import React, { useEffect } from 'react';
import styles from './windowAuction.module.css';

import {connect} from 'react-redux';

import {changeCreateAuctionMinimalStepCurrency, changeCreateAuctionMinimalStepAmount} from '../../../redux/actions.js';

import {
    changeDiscountPercent,
    changeDiscountPreviousAuctionCurrency,
    changeDiscountPreviousAuctionAmount,
    changeDiscountPreviousAuctionId,
    discountAuc,
} from '../../../redux/actions/discount/discount.js';

import {
    changeCreateAuctionValueAmount,
} from '../../../redux/actions.js';

import TextField from '@material-ui/core/TextField';
import {TranslationsUk} from '../../../redux/translations.uk.js';

import {disabledElement} from '../disabled'

import {
    setDataAddress,
} from '../../../redux/createAddress';

import { isBRD, isBRE, isALE, isBSD, isBSE, isCSD, isCSE, isLRE, isLSE, isLSP, isAAE } from '../../../redux/procedure';

const WindowAuctionDiscount = ( props ) => {
    useEffect(() => {
        if(props.auctionsMy !== null){
            if(props.auctionsMy.discount !== null){
                props.discountAuc(true)
            }else{
                props.discountAuc(false)
            } 
            if(props.discount.previousAuctionId === null || props.discount.previousAuctionId === ''){
                props.changeDiscountPreviousAuctionId(props.auctionsMy.previousAuctionId)
            }
            if(props.auctionsMy.discount !== null){
                if(props.discount.discountPercent === null || props.discount.discountPercent === '' || props.discount.discountPercent === '0'){
                    props.changeDiscountPercent(props.auctionsMy.discount.discountPercent)
                }
                if(props.auctionsMy.discount.hasOwnProperty('previousAuctionValue') && props.auctionsMy.discount.previousAuctionValue !== null){
                    if(props.discount.previousAuctionValue.amount === null || props.discount.previousAuctionValue.amount === ''){
                        props.changeDiscountPreviousAuctionAmount(props.auctionsMy.discount.previousAuctionValue.amount)
                    }
                    if(!props.discount.previousAuctionValue.hasOwnProperty('valueAddedTaxIncluded') || props.discount.previousAuctionValue.valueAddedTaxIncluded === null || props.discount.previousAuctionValue.valueAddedTaxIncluded === '' || props.discount.previousAuctionValue.valueAddedTaxIncluded === 'null'){
                        props.setDataAddress(props.auctionsMy.discount.previousAuctionValue.valueAddedTaxIncluded, 'CREATE_AUCTION_RAILWAY_DUTCH_STEP_VALUE_ADDED_TAX_INCLUDED')
                    }
                }
            }
        }
    }, [])
    if(
        props.auctionsMy.sellingMethod === 'landRental-english' ||
        props.auctionsMy.sellingMethod === 'landRental-english-fast' ||
        props.auctionsMy.sellingMethod === 'landRental-english-fast-manual' ||
        props.auctionsMy.sellingMethod === 'landRental-english-fast-auction-manual-qualification' ||
        props.auctionsMy.sellingMethod === 'landRental-english-initial-auction' ||
        props.auctionsMy.sellingMethod === 'landRental-english-initial-auction-manual' ||
        props.auctionsMy.sellingMethod === 'landRental-english-initial-qualification' ||
        props.auctionsMy.sellingMethod === 'landRental-english-initial-qualification-prod' ||
        props.auctionsMy.sellingMethod === 'landSell-english' ||
        props.auctionsMy.sellingMethod === 'landSell-english-fast' ||
        props.auctionsMy.sellingMethod === 'landSell-english-fast-manual' ||
        props.auctionsMy.sellingMethod === 'landSell-english-fast-auction-manual-qualification' ||
        props.auctionsMy.sellingMethod === 'landSell-english-initial-auction' ||
        props.auctionsMy.sellingMethod === 'landSell-english-initial-auction-manual' ||
        props.auctionsMy.sellingMethod === 'landSell-english-initial-qualification' ||
        props.auctionsMy.sellingMethod === 'landSell-english-initial-qualification-prod' ||
        props.auctionsMy.sellingMethod === 'landSell-priorityEnglish' ||
        props.auctionsMy.sellingMethod === 'landSell-priorityEnglish-fast' ||
        props.auctionsMy.sellingMethod === 'landSell-priorityEnglish-fast-manual' ||
        props.auctionsMy.sellingMethod === 'landSell-priorityEnglish-fast-auction-manual-qualification' ||
        props.auctionsMy.sellingMethod === 'landSell-priorityEnglish-initial-auction' ||
        props.auctionsMy.sellingMethod === 'landSell-priorityEnglish-initial-auction-manual' ||
        props.auctionsMy.sellingMethod === 'landSell-priorityEnglish-initial-qualification' ||
        props.auctionsMy.sellingMethod === 'landSell-priorityEnglish-initial-qualification-prod'
    ){
        if(Number(props.auctionsMy.tenderAttempts) > 1){
            return (
                <div className={styles.groupElem}>
                    <TextField
                        className={styles.previousAuctionId}
                        disabled={disabledElement(props.auctionsMy, '11')}
                        id="standard-number"
                        label={TranslationsUk['legitimatePropertyLease-english'].legitimatePropertyLeaseEnglishProcedure.previousAuctionId.legalName}
                        value={props.discount.previousAuctionId}
                        variant="outlined"
                        onChange={(event)=>{
                            props.changeDiscountPreviousAuctionId(event.target.value)
                        }}
                    />
                </div>
            )
        }
    }
    let tmp = null;
    let tmpStyle = null
    /*if(props.discount.discountOnOff === false){
        tmpStyle = styles.pseudoCheckboxOn
    }else{
        tmpStyle = styles.pseudoCheckboxOff
    }*/

    if(
        props.auctionsMy.sellingMethod === "renewables" ||
        props.auctionsMy.sellingMethod === "renewables-fast" ||
        props.auctionsMy.sellingMethod === "renewables-fast-manual" ||
        props.auctionsMy.sellingMethod === "renewables-initial-auction" ||
        props.auctionsMy.sellingMethod === "renewables-initial-qualification" ||
        props.auctionsMy.sellingMethod === "renewables-initial-qualification-fast"
    ){
        tmp = null
    }else if(
        isCSE(props.auctionsMy.sellingMethod) || isCSD(props.auctionsMy.sellingMethod) ||
        isBRE(props.auctionsMy.sellingMethod) || isBRD(props.auctionsMy.sellingMethod)
    ){
        tmp = <div className={styles.group}>
            {cseCsdText(props)}
            {viewDisc1(props)}
        </div>
    }else{
        tmp = viewDisc(props)
    }

    return tmp
}

function viewDisc(props){
    let tmp = null
    let tmpStyle = null
    /*if(props.discount.discountOnOff === false){
        tmpStyle = styles.pseudoCheckboxOn
    }else{
        tmpStyle = styles.pseudoCheckboxOff
    }*/
    if(Number(props.auctionsMy.tenderAttempts) > 1){
        if(props.typeElem === 1){
            tmp = <div className={styles.group}>
                {cseCsdText(props)}
                {summZU(props)}
                <div className={styles.chekbox}>
                    <div>{TranslationsUk['legitimatePropertyLease-english'].legitimatePropertyLeaseEnglishProcedure.discount.legalName}</div>
                    <div
                        className={tmpStyle}
                        onClick={
                            () => {
                                if(props.discount.discountOnOff === false){
                                    props.discountAuc(true)
                                }else{
                                    props.discountAuc(false)
                                }
                            }
                        }
                    ></div>
                </div>
                {discountOn(props)}
                {valueAddedTaxIncluded(props)}
            </div>
        }else{
            tmp = <div className={styles.group}>
                {cseCsdText(props)}
                {summZU(props)}
                {discountOn(props)}
                {valueAddedTaxIncluded(props)}
            </div>
        }
    }
    return tmp
}

function viewDisc1(props){
    if(props.discount.discountOnOff){
        return (
            <>
                {summZU1(props)}
                {discountOn(props)}
            </>
        )
    }
    return null
}

function cseCsdText(props){
    if(
        isCSE(props.auctionsMy.sellingMethod) || isCSD(props.auctionsMy.sellingMethod) ||
        isBRE(props.auctionsMy.sellingMethod) || isBRD(props.auctionsMy.sellingMethod)
    ){
        let tmpStyle = null
        if(props.discount.discountOnOff === false){
            tmpStyle = styles.pseudoCheckboxOn
        }else{
            tmpStyle = styles.pseudoCheckboxOff
        }
        return (
        <>
            <div className={styles.infoBlock}>
                Якщо Ви хочете  зробити знижку - необхідно обов'язково вказати ID попереднього аукціону, відсоток знижки та сумму попереднього аукціону
            </div>
            <div className={styles.groupElem}>
                <TextField
                    className={styles.previousAuctionId}
                    disabled={disabledElement(props.auctionsMy, '11')}
                    id="standard-number"
                    label={TranslationsUk['legitimatePropertyLease-english'].legitimatePropertyLeaseEnglishProcedure.previousAuctionId.legalName}
                    value={props.discount.previousAuctionId}
                    variant="outlined"
                    onChange={(event)=>{
                        props.changeDiscountPreviousAuctionId(event.target.value)
                    }}
                />
            </div>
            <div className={styles.chekbox}>
                <div>Знижка</div>
                <div
                    className={tmpStyle}
                    onClick={
                        () => {
                            if(props.discount.discountOnOff === false){
                                props.discountAuc(true)
                            }else{
                                props.discountAuc(false)
                            }
                        }
                    }
                ></div>
            </div>
        </>
        )
    }
    return null
}

function discountOn(props){
    if(
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-fast" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-fast-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-fast-auction-manual-qualification" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-auction" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-auction-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-qualification" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-qualification-prod"
    ){
        if(props.auctionsMy !== null){
            let tmp = 0
            if(props.discount.discountPercent === null){
                tmp = 0
            }else{
                tmp = props.discount.discountPercent
            }
            if(Number(props.auctionsMy.tenderAttempts) >= 2){
                //Открыл по просьбе Саши
                //disabled={disabledElement(props.auctionsMy, '00')} 
                return (
                    <div className={styles.groupElem}>
                        <div>
                            <TextField
                                id="standard-number"
                                label={TranslationsUk['legitimatePropertyLease-english'].legitimatePropertyLeaseEnglishProcedure.discount.discountPercent.legalName}
                                value={tmp}
                                variant="outlined"
                                onChange={(event)=>{
                                    if(event.target.value !== ''){
                                        let tmp = null
                                        tmp = props.discount.previousAuctionValue.amount - (props.discount.previousAuctionValue.amount / 100 * Number(event.target.value))
                                        props.changeCreateAuctionValueAmount(tmp)
                                    }
                                    props.changeDiscountPercent(event.target.value)
                                }}
                            />
                        </div>
                    </div>
                )
            }
        }
    }else{
        if(props.typeElem === 1){
            let tmp = 0
            if(props.discount.discountPercent === null){
                tmp = 0
            }else{
                tmp = props.discount.discountPercent
            }
            return (
                <div className={styles.groupElem}>
                    <div>
                        <TextField
                            disabled={disabledElement(props.auctionsMy, '00')}
                            id="standard-number"
                            label={TranslationsUk['legitimatePropertyLease-english'].legitimatePropertyLeaseEnglishProcedure.discount.discountPercent.legalName}
                            value={tmp}
                            variant="outlined"
                            onChange={(event)=>{
                                if(event.target.value !== ''){
                                    let tmp = null
                                    tmp = props.discount.previousAuctionValue.amount - (props.discount.previousAuctionValue.amount / 100 * Number(event.target.value))
                                    props.changeCreateAuctionValueAmount(tmp)
                                }
                                props.changeDiscountPercent(event.target.value)
                            }}
                        />
                    </div>
                </div>
            )
        }else{
            let tmp = 0
            if(props.discount.discountPercent === null){
                tmp = 0
            }else{
                tmp = props.discount.discountPercent
            }
            return (
                <div className={styles.groupElem}>
                    <div>
                        <TextField
                            disabled={disabledElement(props.auctionsMy, '00')}
                            id="standard-number"
                            label={TranslationsUk['legitimatePropertyLease-english'].legitimatePropertyLeaseEnglishProcedure.discount.discountPercent.legalName}
                            value={tmp}
                            variant="outlined"
                            onChange={(event)=>{
                                if(event.target.value !== ''){
                                    let tmp = null
                                    tmp = props.discount.previousAuctionValue.amount - (props.discount.previousAuctionValue.amount / 100 * Number(event.target.value))
                                    props.changeCreateAuctionValueAmount(tmp)
                                }
                                props.changeDiscountPercent(event.target.value)
                            }}
                        />
                    </div>
                </div>
            )
        }
    }
    return null
}

function previousAuction(props){
    if(props.typeElem === 1){
        return (
            <>
                <div className={styles.groupElem}>
                    <TextField
                        className={styles.previousAuctionId}
                        disabled={disabledElement(props.auctionsMy, '11')}
                        id="standard-number"
                        label={TranslationsUk['legitimatePropertyLease-english'].legitimatePropertyLeaseEnglishProcedure.previousAuctionId.legalName}
                        value={props.discount.previousAuctionId}
                        variant="outlined"
                        onChange={(event)=>{
                            props.changeDiscountPreviousAuctionId(event.target.value)
                        }}
                    />
                    <div>{TranslationsUk['legitimatePropertyLease-english'].legitimatePropertyLeaseEnglishProcedure.discount.legalName}</div>
                    <div>
                        <TextField
                            disabled={disabledElement(props.auctionsMy, '11')}
                            id="outlined-select-currency-native"
                            select
                            label={TranslationsUk['legitimatePropertyLease-english'].legitimatePropertyLeaseEnglishProcedure.discount.previousAuctionValue.currency.legalName}
                            value={props.discount.previousAuctionValue.currency}
                            onChange={(event)=>{
                                props.changeDiscountPreviousAuctionCurrency(event.target.value)
                            }}
                            SelectProps={{
                                native: true,
                            }}
                            variant="outlined"
                        >
                            {viewOptionCurrentCost(props, 2)}
                        </TextField>
                    </div>
                    <div>
                        <TextField
                            disabled={disabledElement(props.auctionsMy, '00')}
                            label={TranslationsUk['legitimatePropertyLease-english'].legitimatePropertyLeaseEnglishProcedure.discount.previousAuctionValue.amount.legalName}
                            value={props.discount.previousAuctionValue.amount}
                            onChange={(event) => {
                                let reg=/[a-zA-Zа-яА-Яа-яіІїЇєЄ]/;
                                if(reg.test(event.target.value) !== true) props.changeDiscountPreviousAuctionAmount(convertStrToNumber(event.target.value))
                            }}
                            variant="outlined"
                        />
                    </div>
                </div>
            </>
        )
    }else{
        return (
            <>
                <div className={styles.groupElem}>
                    <TextField
                        disabled={disabledElement(props.auctionsMy, '11')}
                        id="standard-number"
                        label={TranslationsUk['legitimatePropertyLease-english'].legitimatePropertyLeaseEnglishProcedure.previousAuctionId.legalName}
                        value={props.discount.previousAuctionId}
                        variant="outlined"
                        onChange={(event)=>{
                            props.changeDiscountPreviousAuctionId(event.target.value)
                        }}
                    />
                    <div>{TranslationsUk['legitimatePropertyLease-english'].legitimatePropertyLeaseEnglishProcedure.discount.legalName}</div>
                    <div>
                        <TextField
                            disabled={disabledElement(props.auctionsMy, '11')}
                            id="outlined-select-currency-native"
                            select
                            label={TranslationsUk['legitimatePropertyLease-english'].legitimatePropertyLeaseEnglishProcedure.discount.previousAuctionValue.currency.legalName}
                            value={props.discount.previousAuctionValue.currency}
                            onChange={(event)=>{
                                props.changeDiscountPreviousAuctionCurrency(event.target.value)
                            }}
                            SelectProps={{
                                native: true,
                            }}
                            variant="outlined"
                        >
                            {viewOptionCurrentCost(props, 2)}
                        </TextField>
                    </div>
                    <div>
                        <TextField
                            disabled={disabledElement(props.auctionsMy, '00')}
                            label={TranslationsUk['legitimatePropertyLease-english'].legitimatePropertyLeaseEnglishProcedure.discount.previousAuctionValue.amount.legalName}
                            value={props.discount.previousAuctionValue.amount}
                            onChange={(event) => {
                                let reg=/[a-zA-Zа-яА-Яа-яіІїЇєЄ]/;
                                if(reg.test(event.target.value) !== true) props.changeDiscountPreviousAuctionAmount(convertStrToNumber(event.target.value))
                            }}
                            variant="outlined"
                        />
                    </div>
                </div>
            </>
        )
    }
}

function previousAuction1(props){
    if(props.typeElem === 1){
        return (
            <>
                <div className={styles.groupElem}>
                    <div>{TranslationsUk['legitimatePropertyLease-english'].legitimatePropertyLeaseEnglishProcedure.discount.legalName}</div>
                    <div>
                        <TextField
                            disabled={disabledElement(props.auctionsMy, '11')}
                            id="outlined-select-currency-native"
                            select
                            label={TranslationsUk['legitimatePropertyLease-english'].legitimatePropertyLeaseEnglishProcedure.discount.previousAuctionValue.currency.legalName}
                            value={props.discount.previousAuctionValue.currency}
                            onChange={(event)=>{
                                props.changeDiscountPreviousAuctionCurrency(event.target.value)
                            }}
                            SelectProps={{
                                native: true,
                            }}
                            variant="outlined"
                        >
                            {viewOptionCurrentCost(props, 2)}
                        </TextField>
                    </div>
                    <div>
                        <TextField
                            disabled={disabledElement(props.auctionsMy, '00')}
                            label={TranslationsUk['legitimatePropertyLease-english'].legitimatePropertyLeaseEnglishProcedure.discount.previousAuctionValue.amount.legalName}
                            value={props.discount.previousAuctionValue.amount}
                            onChange={(event) => {
                                let reg=/[a-zA-Zа-яА-Яа-яіІїЇєЄ]/;
                                if(reg.test(event.target.value) !== true) props.changeDiscountPreviousAuctionAmount(convertStrToNumber(event.target.value))
                            }}
                            variant="outlined"
                        />
                    </div>
                    {valueAddedTaxIncluded(props)}
                </div>
            </>
        )
    }else{
        return (
            <>
                <div className={styles.groupElem}>
                    <div>{TranslationsUk['legitimatePropertyLease-english'].legitimatePropertyLeaseEnglishProcedure.discount.legalName}</div>
                    <div>
                        <TextField
                            disabled={disabledElement(props.auctionsMy, '11')}
                            id="outlined-select-currency-native"
                            select
                            label={TranslationsUk['legitimatePropertyLease-english'].legitimatePropertyLeaseEnglishProcedure.discount.previousAuctionValue.currency.legalName}
                            value={props.discount.previousAuctionValue.currency}
                            onChange={(event)=>{
                                props.changeDiscountPreviousAuctionCurrency(event.target.value)
                            }}
                            SelectProps={{
                                native: true,
                            }}
                            variant="outlined"
                        >
                            {viewOptionCurrentCost(props, 2)}
                        </TextField>
                    </div>
                    <div>
                        <TextField
                            disabled={disabledElement(props.auctionsMy, '00')}
                            label={TranslationsUk['legitimatePropertyLease-english'].legitimatePropertyLeaseEnglishProcedure.discount.previousAuctionValue.amount.legalName}
                            value={props.discount.previousAuctionValue.amount}
                            onChange={(event) => {
                                let reg=/[a-zA-Zа-яА-Яа-яіІїЇєЄ]/;
                                if(reg.test(event.target.value) !== true) props.changeDiscountPreviousAuctionAmount(convertStrToNumber(event.target.value))
                            }}
                            variant="outlined"
                        />
                    </div>
                    {valueAddedTaxIncluded(props)}
                </div>
            </>
        )
    }
}

function elementDisabled(props){
    let tmpStatus = null;
    if(props.auctionsMy.status !== null){
        if(props.auctionsMy.status !== 'active_rectification'){
            tmpStatus = 'disabled'
        }
    }
    return tmpStatus
}

function viewOptionCurrentCost(props, position){
    let tmp = null;
    switch(props.auctionsMy.sellingMethod){
        case "renewables":
        case "renewables-fast":
        case "renewables-fast-manual":
        case "renewables-initial-auction":
        case "renewables-initial-qualification":
        case "renewables-initial-qualification-fast":
            if(position === 1){
                tmp = <>
                    <option key={4} value="eurocent">eurocent</option>
                </>
            }else if(position === 2){
                tmp = <>
                    <option key={3} value="EUR">EUR</option>
                </>
            }
            break;
        case "timber-english":
        case "timber-english-fast":
        case "timber-english-fast-manual":
        case "timber-english-initial-auction":
        case "timber-english-initial-qualification":
        case "timber-english-initial-qualification-fast":
        case "subsoil-english":
        case "subsoil-english-fast":
        case "subsoil-english-fast-manual":
        case "subsoil-english-initial-auction":
        case "subsoil-english-initial-qualification":
        case "railwayCargo-english":
        case "railwayCargo-english-fast":
        case "railwayCargo-english-fast-manual":
        case "railwayCargo-english-initial-auction":
        case "railwayCargo-english-initial-qualification":
            tmp = <>
                <option key={1} value="UAH">ГРН</option>
                {/*<option key={2} value="USD">USD</option>
                <option key={3} value="EUR">EUR</option>*/}
            </>
            break;
        default:
            tmp = <>
                <option key={1} value="UAH">ГРН</option>
                {/*<option key={2} value="USD">USD</option>
                <option key={3} value="EUR">EUR</option>*/}
            </>
            break;
    }
    return tmp
}

function summZU(props){
    let tmp = ''
    if(props.auctionsMy !== null){
        if(props.auctionsMy.discount !== null){
            if(props.auctionsMy.discount.hasOwnProperty('previousAuctionValue') === true){
                if(props.auctionsMy.discount.previousAuctionValue !== null){
                    if(props.auctionsMy.discount.previousAuctionValue.hasOwnProperty('amount') === true){
                        if(props.auctionsMy.discount.previousAuctionValue.amount !== null){
                            tmp = props.auctionsMy.discount.previousAuctionValue.amount    
                        }
                    }
                }
            }
        }
    }
    if(
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-fast-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-fast-auction-manual-qualification" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-auction" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-auction-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-qualification" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-qualification-prod" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-fast" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-fast-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-fast-auction-manual-qualification" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-initial-auction" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-initial-auction-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-initial-qualification-prod" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-auction-manual-qualification" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification-prod"
    ){
        let tmpStyle = null
        if(props.discount !== null){
            if(props.discount.discountOnOff === false){
                tmpStyle = styles.pseudoCheckboxOn
            }else{
                tmpStyle = styles.pseudoCheckboxOff
            }
        }else{
            tmpStyle = styles.pseudoCheckboxOff
        }
        return (
            <>
                <div className={styles.groupElem}>
                    <div
                        className={tmpStyle}
                        onClick={
                            () => {
                                if(props.discount.discountOnOff === false){
                                    props.discountAuc(true)
                                }else{
                                    props.discountAuc(false)
                                }
                            }
                        }
                    ></div>
                    <div>Знижка</div>
                </div>
                <div className={styles.groupElem}>
                    <TextField
                        className={styles.previousAuctionId}
                        disabled={disabledElement(props.auctionsMy, '11')}
                        id="standard-number"
                        label={TranslationsUk['legitimatePropertyLease-english'].legitimatePropertyLeaseEnglishProcedure.previousAuctionId.legalName}
                        value={props.discount.previousAuctionId}
                        variant="outlined"
                        onChange={(event)=>{
                            props.changeDiscountPreviousAuctionId(event.target.value)
                        }}
                    />
                    {/*<div>{TranslationsUk['legitimatePropertyLease-english'].legitimatePropertyLeaseEnglishProcedure.discount.legalName}</div>*/}
                    <div>
                        <TextField
                            disabled={disabledElement(props.auctionsMy, '11')}
                            id="outlined-select-currency-native"
                            select
                            label={TranslationsUk['legitimatePropertyLease-english'].legitimatePropertyLeaseEnglishProcedure.discount.previousAuctionValue.currency.legalName}
                            value={props.discount.previousAuctionValue.currency}
                            onChange={(event)=>{
                                props.changeDiscountPreviousAuctionCurrency(event.target.value)
                            }}
                            SelectProps={{
                                native: true,
                            }}
                            variant="outlined"
                        >
                            {viewOptionCurrentCost(props, 2)}
                        </TextField>
                    </div>
                    <div>
                        <TextField
                            disabled={disabledElement(props.auctionsMy, '00')}
                            label={TranslationsUk['legitimatePropertyLease-english'].legitimatePropertyLeaseEnglishProcedure.discount.previousAuctionValue.amount.legalName}
                            value={props.discount.previousAuctionValue.amount}
                            onChange={(event) => {
                                let reg=/[a-zA-Zа-яА-Яа-яіІїЇєЄ]/;
                                if(reg.test(event.target.value) !== true) props.changeDiscountPreviousAuctionAmount(convertStrToNumber(event.target.value))
                            }}
                            variant="outlined"
                            helperText="Сума попреднього аукціону"
                        />
                    </div>
                </div>
            </>
        )
    }
    return previousAuction(props)
}

function summZU1(props){
    return previousAuction1(props)
}

function convertStrToNumber(data){
    let str = ''
    if(data.indexOf(',') !== -1){
        str = data.replace(',', '.')
    }else{
        str = data
    }
    let strArr = null
    if(str.indexOf('.') !== -1){
        strArr = str.split('.')
        if(strArr.length < 3){
            if(strArr[1].length < 3){
                return str
            }else{
                return strArr[0] + '.' + strArr[1].slice(0, 2)
            }
        }else{
            if(strArr[1].length === 0){
                return strArr[0] + '.'
            }else if(strArr[1].length === 1){
                return strArr[0] + '.' + strArr[1].slice(0, 1)
            }else if(strArr[1].length === 2){
                return strArr[0] + '.' + strArr[1].slice(0, 2)
            }    
        }
    }
    return str
}

function valueAddedTaxIncluded(props){
    let tmpTax = props.discount.previousAuctionValue.valueAddedTaxIncluded
    if(props.auctionsMy !== null){
        /*if(props.auctionsMy.discount !== null){
            if(props.auctionsMy.discount.hasOwnProperty('previousAuctionValue') === true){
                if(props.auctionsMy.discount.previousAuctionValue !== null){
                    if(props.auctionsMy.discount.previousAuctionValue.hasOwnProperty('valueAddedTaxIncluded') === true){
                        if(props.auctionsMy.discount.previousAuctionValue.valueAddedTaxIncluded !== null){
                            tmpTax = props.discount.previousAuctionValue.valueAddedTaxIncluded
                        }
                    }
                }
            }
        }*/
        if(
            props.auctionsMy.sellingMethod === 'basicSell-english' ||
            props.auctionsMy.sellingMethod === 'basicSell-english-fast' ||
            props.auctionsMy.sellingMethod === 'basicSell-english-fast-manual' ||
            props.auctionsMy.sellingMethod === 'basicSell-english-fast-auction-manual-qualification' ||
            props.auctionsMy.sellingMethod === 'basicSell-english-initial-auction' ||
            props.auctionsMy.sellingMethod === 'basicSell-english-initial-auction-manual' ||
            props.auctionsMy.sellingMethod === 'basicSell-english-initial-qualification' ||
            props.auctionsMy.sellingMethod === 'basicSell-english-initial-qualification-prod' ||
            props.auctionsMy.sellingMethod === 'basicSell-dutch' ||
            props.auctionsMy.sellingMethod === 'basicSell-dutch-fast' ||
            props.auctionsMy.sellingMethod === 'basicSell-dutch-fast-manual' ||
            props.auctionsMy.sellingMethod === 'basicSell-dutch-fast-auction-manual-qualification' ||
            props.auctionsMy.sellingMethod === 'basicSell-dutch-initial-auction' ||
            props.auctionsMy.sellingMethod === 'basicSell-dutch-initial-auction-manual' ||
            props.auctionsMy.sellingMethod === 'basicSell-dutch-initial-qualification' ||
            props.auctionsMy.sellingMethod === 'basicSell-dutch-initial-qualification-prod' ||
            props.auctionsMy.sellingMethod === 'alienation-english' ||
            props.auctionsMy.sellingMethod === 'alienation-english-fast' ||
            props.auctionsMy.sellingMethod === 'alienation-english-fast-manual' ||
            props.auctionsMy.sellingMethod === 'alienation-english-fast-auction-manual-qualification' ||
            props.auctionsMy.sellingMethod === 'alienation-english-initial-auction' ||
            props.auctionsMy.sellingMethod === 'alienation-english-initial-auction-manual' ||
            props.auctionsMy.sellingMethod === 'alienation-english-initial-qualification' ||
            props.auctionsMy.sellingMethod === 'alienation-english-initial-qualification-prod' ||
            isCSE(props.auctionsMy.sellingMethod) || isCSD(props.auctionsMy.sellingMethod) ||
            isBRE(props.auctionsMy.sellingMethod) || isBRD(props.auctionsMy.sellingMethod)
        ){
            return (
                <div>
                    <div>Податок</div>
                    <TextField
                        disabled={disabledElement(props.auctionsMy, '00')}
                        id="outlined-select-currency-native"
                        select
                        value={tmpTax}
                        onChange={(event)=>{
                            props.setDataAddress(event.target.value, 'CREATE_AUCTION_RAILWAY_DUTCH_STEP_VALUE_ADDED_TAX_INCLUDED')
                        }}
                        SelectProps={{
                            native: true,
                        }}
                        helperText="Оберіть включати ПДВ чи ні"
                        variant="outlined"
                    >
                        <option key={0} selected disabled value='null'>Обрати</option>
                        <option key={1} value={true}>з ПДВ</option>
                        <option key={2} value={false}>без ПДВ</option>
                    </TextField>
                </div>
            )
        }
    }
    return null
}

const mapStateToProps = state => {
    return {
        auctionsMy: state.start.auctionsMy,
        discount: state.start.discount,
        auctionsZUAttemp: state.start.auctionsZUAttemp,
    }
}

const mapDispatchToProps = {
    changeDiscountPercent,
    changeDiscountPreviousAuctionCurrency,
    changeDiscountPreviousAuctionAmount,
    changeDiscountPreviousAuctionId,
    discountAuc,

    changeCreateAuctionValueAmount,
    setDataAddress,
}

export default connect(mapStateToProps, mapDispatchToProps)(WindowAuctionDiscount)
