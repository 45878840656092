import React from 'react';
import styles from './mainBlock.module.css';
import stylesZu from './mainFindZu.module.css';

import {connect} from 'react-redux';
import { Link } from 'react-router-dom';

//import TextField from '@material-ui/core/TextField';

import {
    PLACEHOLDER_FIRST_FIND_LANG,
    FIRST_SELECTED_FIRST_FIND_LANG,
    SECOND_SELECTED_FIRST_FIND_LANG,
    BUTTON_FIRST_FIND_LANG
} from '../../../model/lang/firstFindLine.js';
import {
    auctionsSearch,
    auctionsSearchTest,
    auctionsSearchСount,
    auctionsSearchOld,
    auctionsSearchСountOld,
    changeCurentPage,
    setSearchTextSting,
    setSearchSelectTypeKeyword,
    changeSearchDateStart,
    changeSearchDateEnd,
    changeStatusAuction,
} from '../../../redux/actions/auctions/auctions';

import {setAuctionCategory} from '../../../redux/actions/support.js';
import {setDataAddress} from '../../../redux/createAddress';
import {
    streetAddress,
    localityAddress,
    regionAddress,
    postalCodeAddress,
    countryNameAddress,
    popUpAddress,
    popUpAddressId,
    setPopAddressError,
    setPopAddressComplite,
    setKoatuuRegion, 
    setKoatuuGorod,
} from '../../../redux/actions/address/address.js';

//import SnoozeIcon from "@material-ui/icons/Snooze";
//import AlarmIcon from "@material-ui/icons/AddAlarm";
//import { IconButton, InputAdornment } from "@material-ui/core";
import { KeyboardDateTimePicker } from "@material-ui/pickers";
import TextField from '@material-ui/core/TextField';

import { getRegion } from '../../../api/dictonaries/region';

import Input from '../../elements/inputs/input';
import Select from '../../elements/inputs/inputSelect'
import Button from '../../elements/buttons/button';
import InputDate from '../../elements/inputs/inputDate';
import SlimmerBlock from '../../elements/slimmerBlock/slimmerBlock';
import { isLLE } from '../../../redux/procedure.js';

const MainBlock = ( props ) => {

    function view(){
        return(
            <>
                <Select //'Лот виставляється',
                    data={{
                        label: 'Статус аукціону',
                        target: props.search.status,
                        // info: 'Отака от штука-дрюка',
                        // required: true,
                    }}
                    onChange={(e) => { 
                        props.changeStatusAuction(e)
                    }}
                    options={[
                        {value: "", name: "Всі статуси", styleFontSize: true},
                        {value: "active_rectification", name: "Редагування доступне"},
                        {value: "active_tendering", name: "Прийняття заяв на участь"},
                        {value: "active_auction", name: "Аукціон"},
                        {value: "active_qualification", name: "Очікується опублікування протоколу"},
                        {value: "active_awarded", name: "Очікується підписання договору"},
                        {value: "pending_payment", name: "Очікується оплата"},
                        {value: "complete", name: "Аукціон завершено. Договір підписано"},
                        {value: "unsuccessful", name: "Аукціон не відбувся"},
                        {value: "cancelled", name: "Торги відмінено"},
                    ]}
                />
                <div className={styles.startDate}>
                    <div className={styles.startDateDiv}>
                        <InputDate
                            data={{
                                label: "Дата початку аукціону",
                                target: props.search.startDateFrom,
                                time: true
                            }}
                            onChange={ (e) => {
                                props.changeSearchDateStart(e)
                            }}
                            placeholder="Дата аукціону"
                        />
                    </div>
                    <div className={styles.startDateDiv}>
                        <InputDate
                            data={{
                                label: "Дата завершення аукціону",
                                target: props.search.startDateTo,
                                time: true
                            }}
                            onChange={ (e) => {
                                props.changeSearchDateEnd(e)
                            }}
                            placeholder="Дата аукціону"
                        />
                    </div>
                </div>
                <div className={styles.startDate}>
                    <div className={styles.startDateDiv}>
                        <Input //Назва аукціону"
                            label="Вартість процедури від" 
                            value={props.search.priceFrom}
                            onChange={(e)=>{
                                props.setDataAddress(e, 'SET_PRICE_FROM')
                            }}
                        />
                    </div>
                    <div className={styles.startDateDiv}>
                        <Input //Назва аукціону"
                            label="Вартість процедури до" 
                            value={props.search.priceTo}
                            onChange={(e)=>{
                                props.setDataAddress(e, 'SET_PRICE_TO')
                            }}
                        />
                    </div>
                </div>
                {classificators(props)}
                {classificatorsAlt(props)}
                {itemType(props)}
                {address(props)}
                {numberOfCurrentTenants(props)}
                {area(props)}
                {landArea(props)}
                {leaseDuration(props)}
                {validfilterSelect(props)}
                {cadastralNumber(props)}
                
                
                {landRentalFind(props)}
            </>
        )
    }

    function classificators(props){
        if(
            props.search.category !== 'legitimatePropertyLease'
            && props.search.category !== 'legitimatePropertyLease-english'
            && props.search.category !== 'legitimatePropertyLease-dutch'
            && props.search.category !== 'legitimatePropertyLease-priorityEnglish'
        ) return null
        return (
            <div className={styles.startDate}>
                <div className={styles.startDateDiv}>
                    <Select //'Лот виставляється',
                        data={{
                            label: "Класифікатор об'єкта",
                            target: props.search.classification
                            // info: 'Отака от штука-дрюка',
                            // required: true,
                        }}
                        onChange={(e) => { 
                            props.setDataAddress(e, 'SERCH_CLASSIFICATION')
                        }}
                        options={[{value: 'null', name: 'Обрати'}, ...classificationIdOption(props)]}
                    />
                </div>
                <div className={styles.startDateDiv}>
                    <Select //'Лот виставляється',
                        data={{
                            label: "Додатковий класифікатор об'єкта",
                            target: props.search.additionalClassifications
                            // info: 'Отака от штука-дрюка',
                            // required: true,
                        }}
                        onChange={(e) => { 
                            props.setDataAddress(e, 'SERCH_ADDITIONAL_CLASSIFICATION')
                        }}
                        options={[{value: 'null', name: 'Оберіть'}, ...addClassificationIdOption(props)]}
                    />
                </div>
            </div>
        )
    }

    function classificatorsAlt(props){
        if(
            props.search.category !== 'basicSell-all'
            && props.search.category !== 'basicSell-english'
            && props.search.category !== 'basicSell-dutch'
            && props.search.category !== 'alienation'
            && props.search.category !== 'alienation-english'
            && props.search.category !== 'commercialSell-all'
            && props.search.category !== 'commercialSell-english'
            && props.search.category !== 'commercialSell-dutch'
            && props.search.category !== 'landArrested'
            && props.search.category !== 'landArrested-english'
            && props.search.category !== 'landArrested-withoutAuction'
            && props.search.category !== 'landArrested-priorityEnglish'
        ) return null
        let tmp = mainGroops()
        if(props.search.category === 'alienation'
            || props.search.category === 'alienation-english'
            || props.search.category === 'commercialSell-all'
            || props.search.category === 'commercialSell-english'
            || props.search.category === 'commercialSell-dutch'
            || props.search.category === 'landArrested'
            || props.search.category === 'landArrested-english'
            || props.search.category === 'landArrested-withoutAuction'
            || props.search.category === 'landArrested-priorityEnglish'
        ) tmp = mainGroopsA()
        
        return (
            <div className={styles.startDate}>
                <div className={styles.startDateDiv}>
                    <Select //'Лот виставляється',
                        data={{
                            label: "Основний класифікатор",
                            target: props.createItems.zu.baseClasif
                            // info: 'Отака от штука-дрюка',
                            // required: true,
                        }}
                        onChange={(e) => { 
                            props.setDataAddress(e, 'CREATE_ITEMS_LAND_RENTAL_BASE_CLASSIFICATION')
                            smartClasif(props, e)
                        }}
                        options={[{value: 'null', name: 'Оберіть'}, ...tmp]}
                    />
                </div>
                <div className={styles.startDateDiv}>
                    <Select //'Лот виставляється',
                        data={{
                            label: "Класифікатори",
                            target: props.search.classification
                            // info: 'Отака от штука-дрюка',
                            // required: true,
                        }}
                        onChange={(e) => { 
                            props.setDataAddress(e, 'SERCH_CLASSIFICATION')
                        }}
                        options={[{value: 'null', name: 'Оберіть'}, ...classificationIdOption(props)]}
                    />
                </div>
            </div>
        )
    }
    
    function numberOfCurrentTenants(props){
        if(
            props.search.category !== 'landSell-priorityEnglish'
            && props.search.category !== 'landArrested-priorityEnglish'
        ) return null
        return (
            <div className={styles.startDate}>
                <div className={styles.startDateDiv}>
                    <Select //'Лот виставляється',
                        data={{
                            label: "Кількість учасників з переважним правом",
                            target: props.search.numberOfCurrentTenants
                            // info: 'Отака от штука-дрюка',
                            // required: true,
                        }}
                        onChange={(e) => { 
                            props.setDataAddress(e, 'CREATE_SEARCH_NUMBER_OF_CURRENT_TENANTS')
                        }}
                        options={[
                            {value: 'null', name: 'Оберіть'},
                            {value: '1', name: "1"},
                            {value: '2', name: "2"}
                        ]}
                    />
                </div>
            </div>
        )
    }

    function address(props){
        if(
            props.search.category !== 'legitimatePropertyLease'
            && props.search.category !== 'legitimatePropertyLease-english'
            && props.search.category !== 'legitimatePropertyLease-dutch'
            && props.search.category !== 'legitimatePropertyLease-priorityEnglish'
            
            && props.search.category !== 'landSell'
            && props.search.category !== 'landSell-english'
            && props.search.category !== 'landSell-priorityEnglish'
            && props.search.category !== 'landRental'
            && props.search.category !== 'landRental-english'
            && props.search.category !== 'basicSell-all'
            && props.search.category !== 'basicSell-english'
            && props.search.category !== 'basicSell-dutch'
            && props.search.category !== 'alienation'
            && props.search.category !== 'alienation-english'
            && props.search.category !== 'commercialSell-all'
            && props.search.category !== 'commercialSell-english'
            && props.search.category !== 'commercialSell-dutch'
            && props.search.category !== 'landArrested'
            && props.search.category !== 'landArrested-english'
            && props.search.category !== 'landArrested-withoutAuction'
            && props.search.category !== 'landArrested-priorityEnglish'
        ) return null
        if(props.koatuu.region === null){
            getKoatuuRegion(props)
            return null
        }
        let tmpRegionOption = props.koatuu.region.map(
            (i) => {
                return {value: i.classificationId, name: i.description}
            }
        )
        let tmpGorodOption = null
        if(props.koatuu.gorod !== null){
            tmpGorodOption = props.koatuu.gorod.map(
                (i, count) => {
                    return {value: i.classificationId, name: i.description}
                }
            )
        }
        return (
            <div className={styles.startDate}>
                <div className={styles.startDateDiv}>
                    <Select //'Лот виставляється',
                        data={{
                            label: 'Місцезнаходження об’єкта область',
                            target: props.addressNew.address.region
                            // info: 'Отака от штука-дрюка',
                            // required: true,
                        }}
                        onChange={(e) => { 
                            props.setDataAddress(e, 'CREATE_ADDRESS_NEW_ADDRESS_REGION')
                            let t = e.substr(0, 2)
                            props.setKoatuuGorod(null)
                            props.setDataAddress('null', 'CREATE_ADDRESS_NEW_ADDRESS_LOCALITY')
                            getKoatuuGorod(props, t)
                        }}
                        options={[{value: 'null', name: 'Оберіть'}, ...tmpRegionOption]}
                    />
                </div>
                <div className={styles.startDateDiv}>
                    <Select //'Лот виставляється',
                        data={{
                            label: "Місцезнаходження об’єкта місто",
                            target: props.addressNew.address.locality
                            // info: 'Отака от штука-дрюка',
                            // required: true,
                        }}
                        onChange={(e) => { 
                            props.setDataAddress(e, 'CREATE_ADDRESS_NEW_ADDRESS_LOCALITY')
                        }}
                        options={tmpGorodOption !== null ? [{value: 'null', name: 'Оберіть'}, ...tmpGorodOption] : tmpGorodOption}
                    />
                </div>
            </div>
        )
    }

    function area(props){
        if(
            props.search.category !== 'legitimatePropertyLease'
            && props.search.category !== 'legitimatePropertyLease-english'
            && props.search.category !== 'legitimatePropertyLease-dutch'
            && props.search.category !== 'legitimatePropertyLease-priorityEnglish'
            // && props.search.category !== 'landSell'
            // && props.search.category !== 'landSell-english'
            // && props.search.category !== 'landSell-priorityEnglish'
            // && props.search.category !== 'landRental'
            // && props.search.category !== 'landRental-english'
            // && props.search.category !== 'basicSell-all'
            // && props.search.category !== 'basicSell-english'
            // && props.search.category !== 'basicSell-dutch'
        ) return null
        return (
            <div className={styles.startDate}>
                <div className={styles.startDateDiv}>
                    <Input //Назва аукціону"
                        label="Площа об'єкта, що здається в оренду від" 
                        value={props.search.areaFrom}
                        onChange={(e)=>{
                            props.setDataAddress(e, 'SEARCH_AREA_FROM')
                        }}
                    />
                </div>
                <div className={styles.startDateDiv}>
                    <Input //Назва аукціону"
                        label="Площа об'єкта, що здається в оренду до" 
                        value={props.search.areaTo}
                        onChange={(e)=>{
                            props.setDataAddress(e, 'SEARCH_AREA_TO')
                        }}
                    />
                </div>
            </div>
        )
    }
    function landArea(props){
        if(
            props.search.category !== 'landSell'
            && props.search.category !== 'landSell-english'
            && props.search.category !== 'landSell-priorityEnglish'
            && props.search.category !== 'landRental'
            && props.search.category !== 'landRental-english'
            && props.search.category !== 'landArrested'
            && props.search.category !== 'landArrested-english'
            && props.search.category !== 'landArrested-withoutAuction'
            && props.search.category !== 'landArrested-priorityEnglish'
        ) return null
        return (
            <div className={styles.startDate}>
                <div className={styles.startDateDiv}>
                    <Input //Назва аукціону"
                        label="Площа земельної ділянки від" 
                        value={props.search.landAreaFrom}
                        onChange={(e)=>{
                            props.setDataAddress(e, 'SET_LAND_AREA_FROM')
                        }}
                    />
                </div>
                <div className={styles.startDateDiv}>
                    <Input //Назва аукціону"
                        label="Площа земельної ділянки до" 
                        value={props.search.landAreaTo}
                        onChange={(e)=>{
                            props.setDataAddress(e, 'SET_LAND_AREA_TO')
                        }}
                    />
                </div>
            </div>
        )
    }

    function leaseDuration(props){
        if(
            props.search.category !== 'legitimatePropertyLease'
            && props.search.category !== 'legitimatePropertyLease-english'
            && props.search.category !== 'legitimatePropertyLease-dutch'
            && props.search.category !== 'legitimatePropertyLease-priorityEnglish'
        ) return null
        return (
            <div className={styles.startDate}>
                <h4 style={{width:"100%", marginTop:-10}}>Строк оренди</h4>
                <div className={styles.startDateDiv + " " + styles.startDateDiv1}>
                    <Input //Назва аукціону"
                        label="від, років" 
                        value={props.search.leaseDurationFrom.year}
                        onChange={(e)=>{
                            props.setDataAddress(e, 'SEARCH_LEASE_DURATION_FROM_YEAR')
                        }}
                    />
                    <Input //Назва аукціону"
                        label="від, місяців" 
                        value={props.search.leaseDurationFrom.month}
                        onChange={(e)=>{
                            props.setDataAddress(e, 'SEARCH_LEASE_DURATION_FROM_MONTH')
                        }}
                    />
                    <Input //Назва аукціону"
                        label="від, днів" 
                        value={props.search.leaseDurationFrom.day}
                        onChange={(e)=>{
                            props.setDataAddress(e, 'SEARCH_LEASE_DURATION_FROM_DAY')
                        }}
                    />
                </div>
                <div className={styles.startDateDiv + " " + styles.startDateDiv1}>
                    <Input //Назва аукціону"
                        label="до, років" 
                        value={props.search.leaseDurationTo.year}
                        onChange={(e)=>{
                            props.setDataAddress(e, 'SEARCH_LEASE_DURATION_TO_YEAR')
                        }}
                    />
                    <Input //Назва аукціону"
                        label="до, місяців" 
                        value={props.search.leaseDurationTo.month}
                        onChange={(e)=>{
                            props.setDataAddress(e, 'SEARCH_LEASE_DURATION_TO_MONTH')
                        }}
                    />
                    <Input //Назва аукціону"
                        label="до, днів" 
                        value={props.search.leaseDurationTo.day}
                        onChange={(e)=>{
                            props.setDataAddress(e, 'SEARCH_LEASE_DURATION_TO_DAY')
                        }}
                    />
                </div>
            </div>
        )
    }

    function cadastralNumber(props){
        if(
            props.search.category !== 'landSell'
            && props.search.category !== 'landSell-english'
            && props.search.category !== 'landSell-priorityEnglish'
            && props.search.category !== 'landRental'
            && props.search.category !== 'landRental-english'
            && props.search.category !== 'landArrested'
            && props.search.category !== 'landArrested-english'
            && props.search.category !== 'landArrested-withoutAuction'
            && props.search.category !== 'landArrested-priorityEnglish'
        ) return null
        return (
            <div className={styles.startDate}>
                <div className={styles.startDateDiv}>
                    <Input //Назва аукціону"
                        label="Кадастровий номер" 
                        value={props.search.cadastralNumber}
                        onChange={(e)=>{
                            props.setDataAddress(e, 'SET_CADASTRAL_NUMBER')
                        }}
                    />
                </div>
            </div>
        )
    }

    function validfilterSelect(props){
        if(
            props.search.category !== 'landSell'
            && props.search.category !== 'landSell-english'
            && props.search.category !== 'landSell-priorityEnglish'
            && props.search.category !== 'landRental'
            && props.search.category !== 'landRental-english'
            // && props.search.category !== 'landArrested'
            // && props.search.category !== 'landArrested-english'
            // && props.search.category !== 'landArrested-withoutAuction'
            // && props.search.category !== 'landArrested-priorityEnglish'
        ) return null
        let tmp = classificationIdOption(props)
        if(props.search.category === 'landArrested' ||
        props.search.category === 'landArrested-english' ||
        props.search.category === 'landArrested-withoutAuction' ||
        props.search.category === 'landArrested-priorityEnglish') tmp = classificationIdOptionLAE(props)
        return (
            <div className={styles.startDate}>
                <div className={styles.startDateDiv}>
                    <Select //'Лот виставляється',
                        data={{
                            label: "Класифікатор об'єкта",
                            target: props.search.classification
                            // info: 'Отака от штука-дрюка',
                            // required: true,
                        }}
                        onChange={(e) => { 
                            props.setDataAddress(e, 'SERCH_CLASSIFICATION')
                        }}
                        options={[{value: 'null', name: 'Оберіть'}, ...tmp]}
                    />
                </div>
            </div>
        )
    }

    function itemType(props){
        if(
            props.search.category !== 'basicSell-all'
            && props.search.category !== 'basicSell-english'
            && props.search.category !== 'basicSell-dutch'
            && props.search.category !== 'alienation'
            && props.search.category !== 'alienation-english'
            && props.search.category !== 'commercialSell-all'
            && props.search.category !== 'commercialSell-english'
            && props.search.category !== 'commercialSell-dutch'
        ) return null
        return (
            <div className={styles.startDate}>
                <div className={styles.startDateDiv}>
                    <Select //'Лот виставляється',
                        data={{
                            label: "Тип об'єкта (майна)",
                            target: props.search.itemType
                            // info: 'Отака от штука-дрюка',
                            // required: true,
                        }}
                        onChange={(e) => { 
                            props.setDataAddress(e, 'SERCH_ITEM_TYPE')
                        }}
                        options={[
                            {value: 'null', name: 'Оберіть'},
                            {value:"land", name: "Земельна ділянка"},
                            {value:"jointPropertyComplex", name: "Об'єкт ЄМК"},
                            {value:"vehicle", name: "Транспортний засіб"},
                            {value:"realEstate", name: "Нерухоме майно"},
                            {value:"otherProperty", name: "Інше"}
                        ]}
                    />
                </div>
                {isPerishable(props)}
            </div>
        )
    }

    function isPerishable(props){
        if(
            props.search.category !== 'basicSell-all'
            && props.search.category !== 'basicSell-english'
            && props.search.category !== 'basicSell-dutch'
        ) return null
        return (
            <div className={styles.startDateDiv}>
                <Select //'Лот виставляється',
                    data={{
                        label: "Фільтр для майна що швидко псується",
                        target: props.search.isPerishable
                        // info: 'Отака от штука-дрюка',
                        // required: true,
                    }}
                    onChange={(e) => { 
                        props.setDataAddress(e, 'SERCH_IS_PERISHABLE')
                    }}
                    options={[
                        {value: 'null', name: 'Оберіть'},
                        {value:"true", name: "Так"},
                        {value: "false", name: "Ні"}
                    ]}
                />
            </div>
        )
    }

    return (
        <div className={styles.costBlock}>
            <div className={styles.startDate}>
                <div className={styles.startDateDivInput}>
                    <Input //Назва аукціону"
                        label="" 
                        value={props.search.textSting}
                        placeholder="визначте слово для пошуку"
                        onChange={(e)=>{
                            props.setSearchTextSting(e)
                        }}
                    />
                </div>
                <div className={styles.startDateDivSelect}>
                    <Select
                        data={{
                            label: '',
                            target: props.search.selectTypeKeyword,
                            // info: 'Отака от штука-дрюка',
                            // required: true,
                        }}
                        onChange={(e) => { 
                            props.setSearchSelectTypeKeyword(e)
                        }}
                        options={[
                            {value: '0', name: "Оберить крітерій пошуку", styleFontSize: true},
                            {value: '1', name: "ID аукціону", styleFontSize: true},
                            {value: '3', name: "Організатор / ЕДРПОУ / ІПН", styleFontSize: true},
                            {value: '2', name: "Назва аукціону,опис аукціону, опис лоту", styleFontSize: true},
                            {value: '4', name: "Ідентифікатор об’єкта", styleFontSize: true},
                        ]}
                    />
                </div>
                <Link to={`${props.aucyionsPath}/search${getUrlFiltersParam(props.search)}`}>
                    <Button
                        color='blue'
                        data={'Пошук'}
                        onClick={() =>{
                            viewButtonSearch(props, props.search.textSting)
                        }}
                    />
                </Link>
            </div>
            <SlimmerBlock
                title='Розширений пошук'
                content={view()}
                slim={false}
            />
        </div>
    )
}

async function viewButtonSearch(props, objId){
    let keyword, authorName, classification, auctionId = null;
    let selectTypeKeyword = null;
    let startDateFrom = null;
    let startDateTo = null;
    let registryId = null

    let priceFrom = null
    let priceTo = null
    if(props.search.selectTypeKeyword !== null && props.search.selectTypeKeyword !== '') selectTypeKeyword = props.search.selectTypeKeyword
    if(props.search.startDateFrom !== null && props.search.startDateFrom !== '') startDateFrom = props.search.startDateFrom
    if(props.search.startDateTo !== null && props.search.startDateTo !== '') startDateTo = props.search.startDateTo

    if(props.search.priceFrom !== null && props.search.priceFrom !== '') priceFrom = props.search.priceFrom
    if(props.search.priceTo !== null && props.search.priceTo !== '') priceTo = props.search.priceTo
    switch (selectTypeKeyword){
        case '0':
            keyword = props.search.textSting;
            authorName = null;
            classification = null;
            auctionId = null;
            registryId = null;
            break;
        case '1':
            keyword = null;
            authorName = null;
            classification = null;
            auctionId = props.search.textSting;
            registryId = null;
            break;
        case '2':
            keyword = props.search.textSting;
            authorName = null;
            classification = null;
            auctionId = null;
            registryId = null;
            break;
        case '3':
            keyword = null;
            authorName = props.search.textSting;
            classification = null;
            auctionId = null;
            registryId = null;
            break;
        case '4':
            keyword = null;
            authorName = null;
            classification = null;
            auctionId = null;
            registryId = objId;
            break;
        default:
            keyword = props.search.textSting;
            authorName = null;
            classification = null;
            auctionId = null;
            break;
    }

    let dataSearch = {}
    dataSearch.keyword = keyword
    dataSearch.authorName = authorName
    dataSearch.classification = classification
    dataSearch.auctionId = auctionId
    dataSearch.category = props.search.category
    dataSearch.startDateFrom = startDateFrom
    dataSearch.startDateTo = startDateTo
    dataSearch.registryId = registryId;
    dataSearch.priceFrom = priceFrom
    dataSearch.priceTo = priceTo
    if(props.search.areaTo !== null && props.search.areaTo !== '') dataSearch.areaTo = Number(props.search.areaTo)
    if(props.search.areaFrom !== null && props.search.areaFrom !== '') dataSearch.areaFrom = Number(props.search.areaFrom)
    
    if(props.search.landAreaTo !== null && props.search.landAreaTo !== '') dataSearch.landAreaTo = Number(props.search.landAreaTo)
    if(props.search.landAreaFrom !== null && props.search.landAreaFrom !== '') dataSearch.landAreaFrom = Number(props.search.landAreaFrom)
    if(props.search.itemType !== null && props.search.itemType !== 'null') dataSearch.itemType = props.search.itemType

    if(props.addressNew.address.region !== null && props.addressNew.address.region !== 'null') dataSearch.region = getRegion(props.addressNew.address.region)
    if(props.addressNew.address.locality !== null && props.addressNew.address.locality !== 'null') dataSearch.locality = props.addressNew.address.locality

    if(
        props.search.numberOfCurrentTenants !== null
        && props.search.numberOfCurrentTenants !== 'null'
    ) dataSearch.numberOfCurrentTenants = Number(props.search.numberOfCurrentTenants)
    //console.log(dataSearch)
    if(props.search.status === null){
        dataSearch.status = null
    }else if(props.search.status === ''){
        dataSearch.status = [
            "active_rectification",
            "active_tendering",
            "active_auction",
            "active_qualification",
            "active_awarded",
            "pending_payment",
            "complete",
            "unsuccessful",
            "cancelled"
        ]
    }else{
        dataSearch.status = [props.search.status]
    }
    
    if(
        props.search.category === 'basicSell-all' ||
        props.search.category === 'basicSell-english' ||
        props.search.category === 'basicSell-dutch' ||
        props.search.category === 'alienation' ||
        props.search.category === 'alienation-english' ||
        props.search.category === 'commercialSell-all' ||
        props.search.category === 'commercialSell-english' ||
        props.search.category === 'commercialSell-dutch'

    ){
        // if(props.createItems.zu.baseClasif !== 'null' && props.createItems.zu.baseClasif !== null)  dataSearch.classificationMain = props.createItems.zu.baseClasif
        // if(props.search.classification !== null && props.search.classification !== 'null') dataSearch.classification = props.search.classification
        // if(props.addressNew.address.region !== null && props.addressNew.address.region !== 'null'){
        //     let r = props.addressNew.address.region.split('+:+')
        //     dataSearch.region = getRegion(r[1])
        // }
        // if(props.addressNew.address.locality !== null && props.addressNew.address.locality !== 'null') dataSearch.locality = props.addressNew.address.locality
        //if(props.search.itemType !== null && props.search.itemType !== 'null') dataSearch.itemType = props.search.itemType
        if(props.search.isPerishable !== null && props.search.isPerishable !== 'null') dataSearch.isPerishable = props.search.isPerishable
    }


    if(
        props.search.category === 'landSell' ||
        props.search.category === 'landSell-english' ||
        props.search.category === 'landSell-priorityEnglish' ||
        props.search.category === 'landRental' ||
        props.search.category === 'landRental-english'
    ){
        //if(props.search.classification !== null && props.search.classification !== 'null') dataSearch.classification = props.search.classification
        if(props.search.additionalClassifications !== null && props.search.additionalClassifications !== 'null') dataSearch.additionalClassifications = props.search.additionalClassifications
        // if(props.addressNew.address.region !== null && props.addressNew.address.region !== 'null'){
        //     let r = props.addressNew.address.region.split('+:+')
        //     dataSearch.region = getRegion(r[1])
        // }
        // if(props.addressNew.address.locality !== null && props.addressNew.address.locality !== 'null') dataSearch.locality = props.addressNew.address.locality
        if(props.search.cadastralNumber !== null && props.search.cadastralNumber !== '') dataSearch.cadastralNumber = props.search.cadastralNumber
        //if(props.search.landAreaTo !== null && props.search.landAreaTo !== '') dataSearch.landAreaTo = Number(props.search.landAreaTo)
        //if(props.search.landAreaFrom !== null && props.search.landAreaFrom !== '') dataSearch.landAreaFrom = Number(props.search.landAreaFrom)
        
    }
    if(props.search.category === 'legitimatePropertyLease' || props.search.category === 'legitimatePropertyLease-english' || props.search.category === 'legitimatePropertyLease-dutch'){
        //if(props.search.classification !== null && props.search.classification !== 'null') dataSearch.classification = props.search.classification
        if(props.search.additionalClassifications !== null && props.search.additionalClassifications !== 'null') dataSearch.additionalClassifications = props.search.additionalClassifications
        // if(props.search.areaFrom !== null && props.search.areaFrom !== '') dataSearch.areaFrom = Number(props.search.areaFrom)
        // if(props.search.areaTo !== null && props.search.areaTo !== '') dataSearch.areaTo = Number(props.search.areaTo)
        // if(props.addressNew.address.region !== null && props.addressNew.address.region !== 'null'){
        //     let r = props.addressNew.address.region.split('+:+')
        //     dataSearch.region = getRegion(r[1])
        // }
        // if(props.addressNew.address.locality !== null && props.addressNew.address.locality !== 'null' && props.addressNew.address.locality !== '') dataSearch.locality = props.addressNew.address.locality

        let tmpLeaseDurationFrom = 0
        if(props.search.leaseDurationFrom.year !== ''){
            tmpLeaseDurationFrom = tmpLeaseDurationFrom + ((((365 * 24) * 60) * 60) * props.search.leaseDurationFrom.year)
        }
        if(props.search.leaseDurationFrom.month !== ''){
            tmpLeaseDurationFrom = tmpLeaseDurationFrom + ((((31 * 24) * 60) * 60) * props.search.leaseDurationFrom.month)
        }
        if(props.search.leaseDurationFrom.day !== ''){
            tmpLeaseDurationFrom = tmpLeaseDurationFrom + (((24 * 60) * 60) * props.search.leaseDurationFrom.day)
        }
        if(tmpLeaseDurationFrom !== 0){
            dataSearch.leaseDurationFrom = tmpLeaseDurationFrom
        }
        let tmpLeaseDurationTo = 0
        if(props.search.leaseDurationTo.year !== ''){
            tmpLeaseDurationTo = tmpLeaseDurationTo + ((((365 * 24) * 60) * 60) * props.search.leaseDurationTo.year)
        }
        if(props.search.leaseDurationTo.month !== ''){
            tmpLeaseDurationTo = tmpLeaseDurationTo + ((((31 * 24) * 60) * 60) * props.search.leaseDurationTo.month)
        }
        if(props.search.leaseDurationTo.day !== ''){
            tmpLeaseDurationTo = tmpLeaseDurationTo + (((24 * 60) * 60) * props.search.leaseDurationTo.day)
        }
        if(tmpLeaseDurationTo !== 0){
            dataSearch.leaseDurationTo = tmpLeaseDurationTo
        }
    }
    if(
        props.search.category === 'landArrested' ||
        props.search.category === 'landArrested-english' ||
        props.search.category === 'landArrested-withoutAuction' ||
        props.search.category === 'landArrested-priorityEnglish'
    ){
        //if(props.search.classification !== null && props.search.classification !== 'null') dataSearch.classification = props.search.classification
        if(props.search.additionalClassifications !== null && props.search.additionalClassifications !== 'null') dataSearch.additionalClassifications = props.search.additionalClassifications
        // if(props.addressNew.address.region !== null && props.addressNew.address.region !== 'null'){
        //     let r = props.addressNew.address.region.split('+:+')
        //     dataSearch.region = getRegion(r[1])
        // }
        // if(props.addressNew.address.locality !== null && props.addressNew.address.locality !== 'null') dataSearch.locality = props.addressNew.address.locality
        if(props.search.cadastralNumber !== null && props.search.cadastralNumber !== '') dataSearch.cadastralNumber = props.search.cadastralNumber
        //if(props.search.landAreaTo !== null && props.search.landAreaTo !== '') dataSearch.landAreaTo = Number(props.search.landAreaTo)
        //if(props.search.landAreaFrom !== null && props.search.landAreaFrom !== '') dataSearch.landAreaFrom = Number(props.search.landAreaFrom)
    }

    if(props.createItems.zu.baseClasif !== 'null' && props.createItems.zu.baseClasif !== null) dataSearch.classificationMain = props.createItems.zu.baseClasif
    if(props.search.classification !== null && props.search.classification !== 'null') dataSearch.classification = props.search.classification

    /*let dataSearch = {
        order: props.search.order,
        status: props.search.status,
        region: props.search.region,
        category: props.search.category, 
        priceCurrency: props.search.priceCurrency,
        priceFrom: props.search.priceFrom,
        priceTo: props.search.priceTo,
        measures: props.search.measures,
        measuresFrom: props.search.measuresFrom,
        measuresTo: props.search.measuresTo

        //"order": "asc",       сортировка
        //"orderBy": "string",    любой критерий по которому ищем в базовый поиск
        //"keyword": "string",    по словам по которому ищем в базовый поиск
        //"authorName": "string", по создателю по которому ищем в базовый поиск
        //"auctionId": "string",  по айди аукциона по которому ищем в базовый поиск
        //"itemId": "string",     по айди итема по которому ищем в базовый поиск
        //"region": "string",     по региону --- не пашет
        //"status": "active_rectification",   по статусу
        //"category": "string",   по категории
        //"priceCurrency": "UAH", валюта
        //"priceFrom": 0,         мин цена
        //"priceTo": 0,           макс цена
        //"measures": "string",   ед.измерения
        //"measuresFrom": 0,      ед.измерения от
        //"measuresTo": 0,        ед.измерения до
        //"startDateFrom": "string",  дата начала
        //"startDateTo": "string",    дата окончания
        //"classification": "string",     классификатор
        //"additionalClassifications": [  доп классификатор
            //"string"
        //]
    };*/
    let data = {
        domen: process.env.REACT_APP_END_POINT_BD,
        version: 'v1',
        local: props.langFlag,
        params: '?offset=0'
    }
    let dataS = {
        domen: process.env.REACT_APP_END_POINT_BD,
        version: 'v1',
        local: props.langFlag,
        params: ''
    }
    if(props.token !== null){
        let header = {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
        props.auctionsSearch(data, header, dataSearch);
        //props.auctionsSearchСount(dataS, header, dataSearch);
        /////////////////
        //Закрыть
        //props.auctionsSearchOld(data, header, dataSearch);
        //props.auctionsSearchСountOld(dataS, header, dataSearch);
    }else{
        let header = {
            'Content-Type': 'application/json'
        }
        props.auctionsSearch(data, header, dataSearch);
        //props.auctionsSearchСount(dataS, header, dataSearch);
        /////////////////
        //Закрыть
        //props.auctionsSearchOld(data, header, dataSearch);
        //props.auctionsSearchСountOld(dataS, header, dataSearch);
    }
}

function getUrlFiltersParam(search){
    let tmp = null
    if(search.isPerishable !== null && search.isPerishable !== 'null'){
        if(tmp === null){
            tmp = '?isPerishable=' + search.isPerishable
        }else{
            tmp += '&isPerishable=' + search.isPerishable
        }
    }
    if(search.itemType !== null && search.itemType !== 'null'){
        if(tmp === null){
            tmp = '?itemType=' + search.itemType
        }else{
            tmp += '&itemType=' + search.itemType
        }
    }
    if(search.orderBy !== null){
        if(tmp === null){
            tmp = '?orderBy=' + search.orderBy
        }else{
            tmp += '&orderBy=' + search.orderBy
        }
    }
    if(search.status !== null){
        if(tmp === null){
            tmp = '?status=' + search.status
        }else{
            tmp += '&status=' + search.status
        }
    }
    if(search.region !== null && search.region !== 'null'){
        if(tmp === null){
            tmp = '?region=' + search.region
        }else{
            tmp += '&region=' + search.region
        }
    }
    if(search.category !== null){
        if(tmp === null){
            tmp = `?category=` + search.category
        }else{
            tmp += `&category=` + search.category
        }
    }
    if(search.priceCurrency !== null){
        if(tmp === null){
            tmp = '?priceCurrency=' + search.priceCurrency
        }else{
            tmp += '&priceCurrency=' + search.priceCurrency
        }
    }
    if(search.priceFrom !== null){
        if(tmp === null){
            tmp = '?priceFrom=' + search.priceFrom
        }else{
            tmp += '&priceFrom=' + search.priceFrom
        }
    }
    if(search.priceFromBag !== false){
        if(tmp === null){
            tmp = '?priceFromBag=true'
        }else{
            tmp += '&priceFromBag=true'
        }
    }
    if(search.priceTo !== null){
        if(tmp === null){
            tmp = '?priceTo=' + search.priceTo
        }else{
            tmp += '&priceTo=' + search.priceTo
        }
    }
    if(search.priceToBag !== false){
        if(tmp === null){
            tmp = '?priceToBag=true'
        }else{
            tmp += '&priceToBag=true'
        }
    }
    if(search.measures !== null){
        if(tmp === null){
            tmp = '?measures=' + search.measures
        }else{
            tmp += '&measures=' + search.measures
        }
    }
    if(search.measuresFrom !== null){
        if(tmp === null){
            tmp = '?measuresFrom=' + search.measuresFrom
        }else{
            tmp += '&measuresFrom=' + search.measuresFrom
        }
    }
    if(search.measuresTo !== null){
        if(tmp === null){
            tmp = '?measuresTo=' + search.measuresTo
        }else{
            tmp += '&measuresTo=' + search.measuresTo
        }
    }

    switch (search.selectTypeKeyword){
        case '1':
            if(search.textSting !== null){
                if(tmp === null){
                    tmp = '?auctionId=' + search.textSting
                }else{
                    tmp += '&auctionId=' + search.textSting
                }
            }
            break;
        case '2':
            if(search.textSting !== null){
                if(tmp === null){
                    tmp = '?keyword=' + search.textSting
                }else{
                    tmp += '&keyword=' + search.textSting
                }
            }
            break;
        case '3':
            if(search.textSting !== null){
                if(tmp === null){
                    tmp = '?authorName=' + search.textSting
                }else{
                    tmp += '&authorName=' + search.textSting
                }
            }
            break;
        default:
            if(search.textSting !== null){
                if(tmp === null){
                    tmp = '?keyword=' + search.textSting
                }else{
                    tmp += '&keyword=' + search.textSting
                }
            }
            break;
    }
    if(search.selectTypeKeyword !== null){
        if(tmp === null){
            tmp = '?selectTypeKeyword=' + search.selectTypeKeyword
        }else{
            tmp += '&selectTypeKeyword=' + search.selectTypeKeyword
        }
    }
    if(tmp !== null){
        return tmp
    }
}

function landRentalFind(props){
    // if(
    //     props.search.category === 'landSell'
    //     || props.search.category === 'landSell-english'
    //     || props.search.category === 'landSell-priorityEnglish'
    //     || props.search.category === 'landRental'
    //     || props.search.category === 'landRental-english'
    //     || props.search.category === 'basicSell-all'
    //     || props.search.category === 'basicSell-english'
    //     || props.search.category === 'basicSell-dutch'
    //     || props.search.category === 'alienation'
    //     || props.search.category === 'alienation-english'
    //     || props.search.category === 'commercialSell-all'
    //     || props.search.category === 'commercialSell-english'
    //     || props.search.category === 'commercialSell-dutch'
    //     || props.search.category === 'landArrested'
    //     || props.search.category === 'landArrested-english'
    //     || props.search.category === 'landArrested-withoutAuction'
    //     || props.search.category === 'landArrested-priorityEnglish'
    // ){
        
        if(props.createItems.landRental.additionalClassificationsArr === null){
            getAdditionalClassificationZU(props)
            loadMainClassifAll(props)
        }
    // }
    return null
}

function mainGroops(){
    return (
        [
            {value: '03', name: "CAV 03 Сільськогосподарська, фермерська продукція, продукція рибальства, лісівництва та супутня продукція (та усі вкладені коди)"},
            {value: '04', name: "CAV 04 Нерухоме майно (та усі вкладені коди)"},
            {value: '05', name: "CAV 05 Цілісний майновий комплекс або майновий комплекс - комплекс активів підприємства (та усі вкладені коди)"},
            {value: '06', name: "CAV 06 Земельні ділянки (та усі вкладені коди)"},
            {value: '15', name: "CAV 15 Продукти харчування, напої, тютюн та супутня продукція"},
            {value: '16', name: "CAV 16 Сільськогосподарська техніка (та усі вкладені коди)"},
            {value: '30', name: "CAV 30 Офісна та комп’ютерна техніка, устаткування та приладдя, крім меблів та пакетів програмного забезпечення (та усі вкладені коди)"},
            {value: '32', name: "CAV 32 Радіо-, телевізійна, комунікаційна, телекомунікаційна та супутня апаратура й обладнання (та усі вкладені коди)"},
            {value: '34', name: "CAV 34 Транспортне обладнання та допоміжне приладдя до нього (та усі вкладені коди)"},
            {value: '39', name: "CAV 39 Меблі (у тому числі офісні меблі), меблево-декоративні вироби, побутова техніка (крім освітлювального обладнання) та засоби для чищення (та усі вкладені коди)"},
            {value: '42', name: "CAV 42 Промислова техніка (та усі вкладені коди)"},
            {value: '07', name: "CAV 07 Права вимоги за кредитними договорами (та усі вкладені коди)"},
            {value: '08', name: "CAV 08 Корпоративні права (та усі вкладені коди)"},
            {value: '45', name: "CAV 45 Будівельні роботи (та усі вкладені коди)"},
            {value: '48', name: "CAV 48 Пакети програмного забезпечення та інформаційні системи (та усі вкладені коди)"},
            {value: '50', name: "CAV 50 Послуги з ремонту і технічного обслуговування (та усі вкладені коди)"},
            {value: '51', name: "CAV 51 Послуги зі встановлення (крім програмного забезпечення) (та усі вкладені коди)"},
            {value: '55', name: "CAV 55 Готельні, ресторанні послуги та послуги з роздрібної торгівлі (та усі вкладені коди)"},
            {value: '60', name: "CAV 60 Транспортні послуги (крім транспортування відходів) (та усі вкладені коди)"},
            {value: '63', name: "CAV 63 Додаткові та допоміжні транспортні послуги; послуги туристичних агентств (та усі вкладені коди)"},
            {value: '64', name: "CAV 64 Поштові та телекомунікаційні послуги (та усі вкладені коди)"},
            {value: '65', name: "CAV 65 Комунальні послуги (та усі вкладені коди)"},
            {value: '66', name: "CAV 66 Фінансові та страхові послуги (та усі вкладені коди)"},
            {value: '71', name: "CAV 71 Архітектурні, будівельні, інженерні та інспекційні послуги (та усі вкладені коди)"},
            {value: '72', name: "CAV 72 Послуги у сфері інформаційних технологій: консультування, розробка програмного забезпечення, послуги мережі Інтернет і послуги з підтримки (та усі вкладені коди)"},
            {value: '73', name: "CAV 73 Послуги у сфері НДДКР та пов’язані консультаційні послуги (та усі вкладені коди)"},
            {value: '75', name: "CAV 75 Адміністративні, оборонні послуги та послуги у сфері соціального захисту (та усі вкладені коди)"},
            {value: '76', name: "CAV 76 Послуги, пов’язані з нафтогазовою промисловістю (та усі вкладені коди)"},
            {value: '77', name: "CAV 77 Послуги у сфері сільського господарства, лісівництва, рослинництва, водного господарства та бджільництва (та усі вкладені коди)"},
            {value: '79', name: "CAV 79 Ділові послуги: юридичні, маркетингові, консультаційні, кадрові, поліграфічні та охоронні (та усі вкладені коди)"},
            {value: '80', name: "CAV 80 Послуги у сфері освіти та навчання (та усі вкладені коди)"},
            {value: '85', name: "CAV 85 Послуги у сфері охорони здоров’я та соціальної допомоги (та усі вкладені коди)"},
            {value: '90', name: "CAV 90 Послуги у сферах поводження зі стічними водами та сміттям, послуги у сферах санітарії та охорони довкілля (та усі вкладені коди)"},
            {value: '92', name: "CAV 92 Послуги у сфері відпочинку, культури та спорту (та усі вкладені коди)"},
            {value: '98', name: "CAV 98 Інші громадські, соціальні та особисті послуги (та усі вкладені коди)"}
        ]
    )
}

function mainGroopsA(){
    return (
        [
            {value: '04', name: "CAV 04 Нерухоме майно (та усі вкладені коди)"},
            {value: '05', name: "CAV 05 Цілісний майновий комплекс або майновий комплекс - комплекс активів підприємства (та усі вкладені коди)"},
            {value: '16', name: "CAV 16 Сільськогосподарська техніка (та усі вкладені коди)"},
            {value: '18', name: "CAV 18 Одяг, взуття, сумки та аксесуари (та усі вкладені коди)"},
            {value: '19', name: "CAV 19 Шкіряні та текстильні, пластмасові та гумові матеріали (та усі вкладені коди)"},
            {value: '22', name: "CAV 22 Друкована та супутня продукція (та усі вкладені коди)"},
            {value: '30', name: "CAV 30 Офісна та комп’ютерна техніка, устаткування та приладдя, крім меблів та пакетів програмного забезпечення (та усі вкладені коди)"},
            {value: '31', name: "CAV 31 Електротехнічне устаткування, апаратура, обладнання та матеріали; освітлювальне устаткування (та усі вкладені коди)"},
            {value: '32', name: "CAV 32 Радіо-, телевізійна, комунікаційна, телекомунікаційна та супутня апаратура й обладнання (та усі вкладені коди)"},
            {value: '33', name: "CAV 33 Медичне обладнання, фармацевтична продукція та засоби особистої гігієни (та усі вкладені коди)"},
            {value: '34', name: "CAV 34 Транспортне обладнання та допоміжне приладдя до нього (та усі вкладені коди)"},
            {value: '35', name: "CAV 35 Охоронне, протипожежне, поліцейське та оборонне обладнання (та усі вкладені коди)"},
            {value: '37', name: "CAV 37 Музичні інструменти, спортивні товари, ігри, іграшки, ремісничі, художні матеріали та приладдя (та усі вкладені коди)"},
            {value: '38', name: "CAV 38 Лабораторне, оптичне та високоточне обладнання (крім лінз) (та усі вкладені коди)"},
            {value: '39', name: "CAV 39 Меблі (у тому числі офісні меблі), меблево-декоративні вироби, побутова техніка (крім освітлювального обладнання) та засоби для чищення (та усі вкладені коди)"},
            {value: '42', name: "CAV 42 Промислова техніка (та усі вкладені коди)"},
            {value:'43', name: "CAV 43 Гірничодобувне та будівельне обладнання (та усі вкладені коди)"},
            {value:'44', name: "CAV 44 Конструкції та конструкційні матеріали; допоміжна будівельна продукція (крім електроапаратури) (та усі вкладені коди)"}
        ]
    )
}

async function smartClasif(props, data){
    let tmp0 = await getDictionaryJointPropertyComplex(props, data)
    //props.setDataAddress(tmp0, 'SET_CLASSIFICATIONS_BSE_BSD')
    props.setDataAddress(tmp0, 'SET_CLASSIFICATIONS_BY_TYPE_DGF_ADD')
}

async function loadMainClassifAll(props){
    let tmp = await getDictionaryJointPropertyComplex(props, '06121000-6')
    let tmp2 = tmp.concat(await getDictionaryJointPropertyComplex(props, '06111000-3'))
    let tmp3 = tmp2.concat(await getDictionaryJointPropertyComplex(props, '06112000-0'))
    let tmp4 = tmp3.concat(await getDictionaryJointPropertyComplex(props, '06122000-3'))
    let tmp5 = tmp4.concat(await getDictionaryJointPropertyComplex(props, '06123000-0'))
    let tmp6 = tmp5.concat(await getDictionaryJointPropertyComplex(props, '06124000-7'))
    let tmp7 = tmp6.concat(await getDictionaryJointPropertyComplex(props, '06125000-4'))
    let tmp8 = tmp7.concat(await getDictionaryJointPropertyComplex(props, '06126000-1'))
    let tmp9 = tmp8.concat(await getDictionaryJointPropertyComplex(props, '06127000-8'))
    let tmp10 = tmp9.concat(await getDictionaryJointPropertyComplex(props, '06128000-5'))
    let tmp11 = tmp10.concat(await getDictionaryJointPropertyComplex(props, '06129000-2'))
    //props.setDataAddress(tmp11, 'SERCH_CLASSIFICATION')
    props.setDataAddress(tmp11, 'SET_CLASSIFICATIONS_BY_TYPE_DGF_ADD')
}

async function getDictionaryJointPropertyComplex(props, mask){
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/classifications/autocomplete/classification_id?limit=1000&offset=0&classification_id=${mask}&scheme=CAV&mask=${mask}`;
    const response = await fetch(endPoint, {
        method: "GET",
        headers: {
            //'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        let json = await response.json()
        return json
        /*let tmp = props.classificationsDgf.classificationsByTypeMain
        let tmp2 = []
        if(tmp !== null){
            if(tmp.length > 0){
                tmp2 = tmp.concat(json);
            }
        }*/

        //props.setDataAddress(json[0].classificationId, 'SERCH_CLASSIFICATION')
        //props.setDataAddress(tmp2, 'SET_CLASSIFICATIONS_BY_TYPE_DGF_ADD')
    }
}

function classificationIdOption(props){
    if(props.classificationsDgf.classificationsByTypeMain !== null){
        return props.classificationsDgf.classificationsByTypeMain.map(
            (i, count) => {
                let tmp = i.scheme + ' ' + i.classificationId + ' ' + i.description
                return {value: i.classificationId, name: tmp}
                //return <option key={count} value={i.classificationId}>{i.scheme + ' ' + i.classificationId + ' ' + i.description}</option>
            }
        )
    }
}
function classificationIdOptionLAE(props){
    if(props.classificationsDgf.classificationsByTypeMain !== null){
        return props.classificationsDgf.classificationsByTypeMain
        /*.filter()(
            (i) => {
                return i.classificationId[0] === '0' && i.classificationId[0] === '6'
            }
        )*/
        .map(
            (i, count) => {
                let tmp = i.scheme + ' ' + i.classificationId + ' ' + i.description
                return {value: i.classificationId, name: tmp}
                //return <option key={count} value={i.classificationId}>{i.scheme + ' ' + i.classificationId + ' ' + i.description}</option>
            }
        )
    }
}
function addClassificationIdOption(props){
    if(props.classificationsDgf.classificationsAdd !== null){
        if(props.classificationsDgf.classificationsAdd.length > 0){
            return props.classificationsDgf.classificationsAdd.map(
                (i, count) => {
                    let tmp1 = i[0].scheme + ' ' + i[0].classificationId + ' ' + i[0].description
                    return {value: i[0].classificationId, name: tmp1}
                }
            )
        }
    }
    return []
}

async function getCPUObj(props){
    let endPoint = ``
    if(Number(process.env.REACT_APP_BUILD_STATUS) === 0){
        //endPoint = `https://registry.dto.com.ua/api/1/uk/public/registry_objects/byGlobalId/${props, props.search.textSting}`
        endPoint = `https://registry.dto.com.ua/api/1/uk/public/registry_objects/byGlobalId/${props, props.search.textSting}`
    }else{
        //endPoint = `https://staging-registry.dto.com.ua/object/${props, props.search.textSting}`
        endPoint = `https://sandbox-registry.dto.com.ua/object/${props, props.search.textSting}`
        //endPoint = `https://dev-registry.dto.com.ua/object/${props, props.search.textSting}`
    }
    const response = await fetch(endPoint, {
        method: "GET",
        headers: {
            /*'Authorization': props.token.access_token,*/
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){
            //let tmp = JSON.stringify({title: "Помилка при створенні Тип переліку, до якого віднесено об'єкт", message: JSON.stringify(json)})
            //props.setUniversalError(tmp)
        }else{
            //props.setDataAddress(json, 'SET_CREATE_AUCTION_ZU_DATA')
            await viewButtonSearch(props, json.id)
        }
    }
}

function handleID(props, advansButton){
    return (
        <div className={styles.buttonSearch}>
            <Link to={`${props.aucyionsPath}/search${getUrlFiltersParam(props.search)}`}>
                <div
                    className={styles.findStart}
                    onClick={
                        ()=>{
                            // if(props.search.selectTypeKeyword !== ''){
                                // if(props.search.selectTypeKeyword === '4'){
                                //     if(props.search.textSting.indexOf('-') === -1){
                                //         viewButtonSearch(props, props.search.textSting)
                                //     }else{
                                //         getCPUObj(props)
                                //     }
                                // }else{
                                    // viewButtonSearch(props, props.search.textSting)
                                // }
                            // }else{
                                viewButtonSearch(props, props.search.textSting)
                            // }
                        }
                    }
                >
                    {advansButton}
                </div>
            </Link>
        </div>
    )
}

function getSearchArr(props, value){
    let dataSearch = {
        keyword: props.search.keyword,
        authorName: props.search.authorName,
        classification: props.search.classification,
        auctionId: props.search.auctionId,
        category: props.search.category,
        startDateFrom: props.search.startDateFrom,
        startDateTo: props.search.startDateTo,
        status: props.search.status,
    }
    if(props.token !== null){
        let header = {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
        props.auctionsSearchTest(props, header, dataSearch);
        //props.auctionsSearchOld(data, header, props.search);
    }else{
        let header = {
            'Content-Type': 'application/json'
        }
        props.auctionsSearchTest(props, header, dataSearch);
        //props.auctionsSearchOld(data, header, props.search);
    }
}

async function getKoatuuRegion(props){
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/classifications/koatuu/byMask`;
    const response = await fetch(endPoint, {
        method: "GET",
        headers: {
            /*'Authorization': props.token.access_token,*/
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json1 = await response.json()
        if(json1.hasOwnProperty('message') === true){}
        else{
            props.setKoatuuRegion(json1)
        }
        
    }
}

async function getKoatuuGorod(props, id){
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/classifications/koatuu/byMask?mask=${id}`;
    const response = await fetch(endPoint, {
        method: "GET",
        headers: {
            /*'Authorization': props.token.access_token,*/
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json1 = await response.json()
        if(json1.hasOwnProperty('message') === true){}
        else{
            props.setKoatuuGorod(json1)
        }
        
    }
}

async function getAdditionalClassificationZU(props){
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/public/dictionaries/kvtspz?limit=1000`;
    const response = await fetch(endPoint, {
        method: "GET",
        headers: {
            //'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json = await response.json()
        await props.setDataAddress(json, 'CREATE_ITEMS_LAND_RENTAL_ADDITIONAL_CLASSIFICATIONS_ARR')
    }
}

const mapStateToProps = state => {
    return {
        token: state.start.token,
        langFlag: state.start.langFlag,
        aucyionsPath: state.start.aucyionsPath,
        search: state.start.search,
        bildStatus: state.start.bildStatus,
        classificationsDgf: state.start.classificationsDgf,
        koatuu: state.start.koatuu,
        addressNew: state.start.addressNew,
        createItems: state.start.createItems,
    }
}

const mapDispatchToProps = {
    auctionsSearch,
    auctionsSearchTest,
    auctionsSearchСount,
    auctionsSearchOld,
    auctionsSearchСountOld,
    changeCurentPage,
    setSearchTextSting,
    setSearchSelectTypeKeyword,
    changeSearchDateStart,
    changeSearchDateEnd,
    changeStatusAuction,

    setAuctionCategory,
    setDataAddress,

    setKoatuuRegion, 
    setKoatuuGorod,
}

export default connect(mapStateToProps, mapDispatchToProps)(MainBlock)