import React, {useState, useEffect} from 'react';
import styles from './bankAccounts.module.css';
import {useSelector} from 'react-redux';

import {connect} from 'react-redux';

import Button from '../../../../elements/buttons/button';
import Select from '../../../../elements/inputs/inputSelect';

import { disabledElement } from '../../../disabled';

import BankAccountsGroups from './bankAccountsGroups'
// import {TranslationsUk} from '../../../../../redux/translations.uk.js';

import {
    isBSE, isBSD,
    isCSE, isCSD,
    isALE,
    isLLE, isLLD, isLLP,
    isBRE, isBRD, isBRW,
    isRLE, isRLD,
    isCLE, isCLD,
    isSPE, isSPD,
    isNLE, isNLD,
    isLAE, isLAW, isLAP,
    isLPE,
    isAPE, isAPD
} from '../../../../../redux/procedure'

import {
    openBankWindows,
} from '../../../../../redux/actions/bankAccount/bankAccount.js';
// import { checkProp } from '../../../../../redux/check';

// import {disabledElement} from '../disabled'

const BankAccounts = ( {data, bankAccounts, setBankAccounts, ...props} ) => {
    //const [auction, setAuction] = useSelector(state => state.start.auctionsMy)
    const [banksGroupe, setBanksGroupe] = useState(null)
    const [bank, setBank] = useState(null)

    function elementDisabledCreate(props){
        if(props.auctionsMy.status !== null){
            if(props.auctionsMy.status !== 'active_rectification'){
                let tmpStartData = null
                let tmpEndData = null
                    if(props.auctionsMy.rectificationPeriod !== null){
                        tmpStartData = new Date(props.auctionsMy.rectificationPeriod.startDate).getTime()
                        tmpEndData = new Date(props.auctionsMy.rectificationPeriod.endDate).getTime()
                    }
                if(
                    (
                        isBSE(props.auctionsMy.sellingMethod) || isBSD(props.auctionsMy.sellingMethod) ||
                        isALE(props.auctionsMy.sellingMethod) ||
                        isCSE(props.auctionsMy.sellingMethod) || isCSD(props.auctionsMy.sellingMethod) ||
                        isRLE(props.auctionsMy.sellingMethod) || isRLD(props.auctionsMy.sellingMethod) ||
                        isCLE(props.auctionsMy.sellingMethod) || isCLD(props.auctionsMy.sellingMethod) ||
                        isNLE(props.auctionsMy.sellingMethod) || isNLD(props.auctionsMy.sellingMethod) ||
                        isLAE(props.auctionsMy.sellingMethod) || isLAW(props.auctionsMy.sellingMethod) || isLAP(props.auctionsMy.sellingMethod) ||
                        isAPE(props.auctionsMy.sellingMethod) || isAPD(props.auctionsMy.sellingMethod)
                        || isLLE(props.auctionsMy.sellingMethod) || isLLD(props.auctionsMy.sellingMethod) || isLLP(props.auctionsMy.sellingMethod)
                        ) &&
                    (tmpStartData < Date.now() && tmpEndData > Date.now())
                ){
                    return null
                }else if(isBRE(props.auctionsMy.sellingMethod) || isBRD(props.auctionsMy.sellingMethod) || isBRW(props.auctionsMy.sellingMethod)){
                    if(
                        props.auctionsMy.status === 'complete' ||
                        props.auctionsMy.status === 'cancelled' ||
                        props.auctionsMy.status === 'unsuccessful'
                    ){
                        return 'disabled'
                    }else if(
                        props.auctionsMy.status === 'active_auction' ||
                        props.auctionsMy.status === 'pending_payment' ||
                        props.auctionsMy.status === 'active_qualification'
                    ){
                        return null
                    }else if(props.auctionsMy.status === 'active_awarded'){
                        if(props.auctionsMy.contracts !== null && props.auctionsMy.contracts.length > 0){
                            let tmp = []
                            tmp = props.auctionsMy.contracts.filter(
                                (e) => {
                                    return (e.status === 'pending')
                                }
                            )
                            if(tmp.length > 0) return 'disabled'
                        }
                    }
                    return null
                }else if(isSPE(props.auctionsMy.sellingMethod) || isSPD(props.auctionsMy.sellingMethod)){
                    return null
                }else if(isLPE(props.auctionsMy.sellingMethod)){
                    return null
                }else{
                    return 'disabled'
                }
            }
        }
    }

    function viewCreatGroup(props){
        if(banksGroupe !== null){
            return (
                <Select 
                        data={{
                            label:"Рахунки",
                            target: bank,
                            descr: "Обрати банківський рахунок"
                        }}
                        onChange={ e => setBank(e) }
                        options={selectBankAuction(props)}
                    />
            )
        }
        return null
    }

    if(props.bankAccountZU !== null){
        return <div style={{display: 'flex', width: '100%', flexFlow: 'column'}}>
            <BankAccountsGroups 
                bankAccounts={bankAccounts}
                setBankAccounts={setBankAccounts}
            />
            <div style={{display: 'flex', width: '100%', flexFlow: 'column'}}></div>
            <Select 
                data={{
                    label:"Банківські рахунки",
                    target: banksGroupe,
                    descr: "Обрати банківський рахунок"
                }}
                onChange={ 
                    e => setBanksGroupe(e)
                }
                options={optionBankAccount(props)}
                disabled={isLAW(props.auctionsMy.sellingMethod) ? false : elementDisabledCreate(props)}
            />
            {viewCreatGroup(props)}
            <div className={styles.editBanks}>
                <Button
                    color="blue"
                    onClick={
                        async () => {
                            let tmp = {
                                id: bank,
                                groupe: banksGroupe,
                                type: props.createBankAccountZU.groupType
                            }
                            let newBank = await getBank(props, tmp)
                            const tmpBank  = JSON.parse(JSON.stringify(newBank))
                            tmpBank.accountIdentifications.forEach( i => {
                                i.id = i.id
                                //i.id = i.identifier
                                //delete i.identifier
                            })
                            
                            tmpBank.new = true
                            let tmpBanksAccounts = bankAccounts
                            if (tmpBanksAccounts.length > 0){
                                for (let key in bankAccounts){
                                    if ( tmpBanksAccounts[key].accountType === banksGroupe) {
                                        tmpBanksAccounts[key].accounts.push(tmpBank)
                                        setBankAccounts(tmpBanksAccounts)
                                        setBanksGroupe(null)
                                        setBank(null)
                                        return
                                    }
                                }

                                let tmp = {}
                                tmp.accountType = banksGroupe
                                tmp.accounts= []
                                tmp.accounts.push(tmpBank)
                                tmpBanksAccounts.push(tmp)
                                setBankAccounts(tmpBanksAccounts)
                            }
                            else {
                                let tmp = {}
                                tmp.accountType = banksGroupe
                                tmp.accounts= []
                                tmp.accounts.push(tmpBank)
                                setBankAccounts([tmp])
                            }

                            setBanksGroupe(null)
                            setBank(null)
                        }
                    }
                    disabled={(banksGroupe === null || bank === null) ? true : false}
                >
                    Додати
                </Button>
                <Button
                    disabled={isLAW(props.auctionsMy.sellingMethod) ? false : elementDisabledCreate(props)}
                    color={'gray'}
                    onClick={
                        () => {
                            props.openBankWindows(true)
                        }
                    }
                >
                    Створити та редагувати банківський рахунок
                </Button>
            </div>
        </div>
    }
}

function getBank(props, data){
    if(props.bankAccount !== null){
        for(let key in props.bankAccount) {
            if (props.bankAccount[key].id === data.id) return props.bankAccount[key]
        }
    }
    return null
}

function optionBankAccount(props){
    if( 
        isLLE(props.auctionsMy.sellingMethod) || isLLD(props.auctionsMy.sellingMethod) || isLLP(props.auctionsMy.sellingMethod)
    ){
        return [
                {value: 'registrationFee', name: 'Реєстраційний внесок', style: 'bold'},
                {value: 'guarantee', name: 'Гарантійний внесок', style: 'bold'},
                {value: 'securityDeposit', name: 'Забезпечувальний депозит'},
                {value: 'improvementCompensation', name: 'Компенсація невід’ємних поліпшень'},
                {value: 'other', name: 'Інші платежі'},

                {value: 'advancePayment', name: 'Рахунок для авансового внеску'},
                {value: 'lease', name: 'Рахунок для оплати оренди'}
            ]
    }
    if( 
        isALE(props.auctionsMy.sellingMethod)
    ){
        return [
                {value: 'registrationFee', name: 'Реєстраційний внесок', style: 'bold'},
                {value: 'guarantee', name: 'Гарантійний внесок', style: 'bold'},
                {value: 'other', name: 'Інші платежі'}
            ]
    }
    if( 
       isBRE(props.auctionsMy.sellingMethod) || isBRD(props.auctionsMy.sellingMethod) || isBRW(props.auctionsMy.sellingMethod)
    ){
        return [
                {value: 'payment', name: 'Плата за лот', style: 'bold'},
                /*{value: 'registrationFee', name: 'Реєстраційний внесок'},
                {value: 'guarantee', name: 'Гарантійний внесок'},
                {value: 'organizer', name: 'Організатор'},
                {value: 'advancePayment', name: 'Авансовий внесок'},
                {value: 'securityDeposit', name: 'Забезпечувальний депозит'},
                {value: 'improvementCompensation', name: 'Компенсація невід’ємних поліпшень'},
                {value: 'lease', name: 'Орендні платежі'},
                {value: 'preparationPayment', name: 'Плата за підготовку лота'},
                {value: 'other', name: 'Інші платежі'}*/
            ]
    }
    if(isRLE(props.auctionsMy.sellingMethod) || isRLD(props.auctionsMy.sellingMethod)){
        return [
            {value: 'lease', name: 'Орендні платежі', style: 'bold'},
            {value: 'registrationFee', name: 'Реєстраційний внесок'},
            {value: 'guarantee', name: 'Гарантійний внесок'},
            {value: 'organizer', name: 'Організатор'}
        ]
    }
    if(isCLE(props.auctionsMy.sellingMethod) || isCLD(props.auctionsMy.sellingMethod)){
        return [
            {value: 'lease', name: 'Орендні платежі', style: 'bold'},
            {value: 'registrationFee', name: 'Реєстраційний внесок'},
            {value: 'guarantee', name: 'Гарантійний внесок'},
            {value: 'other', name: 'Інші платежі'}
        ]
    }
    if(isSPE(props.auctionsMy.sellingMethod) || isSPD(props.auctionsMy.sellingMethod)){
        return [
                {value: 'registrationFee', name: 'Реєстраційний внесок'},
                {value: 'guarantee', name: 'Гарантійний внесок'},
                {value: 'payment', name: 'Плата за лот'},
            ]
    }
    if(isNLE(props.auctionsMy.sellingMethod) || isNLD(props.auctionsMy.sellingMethod)){
        return [
                {value: 'payment', name: 'Плата за лот'},
                {value: 'guarantee', name: 'Гарантійний внесок', style: 'bold'},
                {value: 'other', name: 'Інші платежі'}
            ]
    }
    if(isLAE(props.auctionsMy.sellingMethod) || isLAP(props.auctionsMy.sellingMethod)){
        return [
                {value: 'payment', name: 'Плата за лот', style: 'bold'},
                {value: 'guarantee', name: 'Гарантійний внесок'},
                {value: 'preparationPayment', name: 'Плата за підготовку лота'},
                {value: 'securityDeposit', name: 'Забезпечувальний депозит'}
            ]
    }
    if(isLAW(props.auctionsMy.sellingMethod)){
        return [
                {value: 'payment', name: 'Плата за лот'},
                {value: 'guarantee', name: 'Гарантійний внесок'},
                {value: 'preparationPayment', name: 'Плата за підготовку лота'},
                {value: 'securityDeposit', name: 'Забезпечувальний депозит'}
            ]
    }
    if(isLPE(props.auctionsMy.sellingMethod)){
        return [
                {value: 'registrationFee', name: 'Реєстраційний внесок'},
                {value: 'guarantee', name: 'Гарантійний внесок'},
                {value: 'payment', name: 'Плата за лот'},
            ]
    }
    if(isAPE(props.auctionsMy.sellingMethod)){
        return [
                {value: 'guarantee', name: 'Гарантійний внесок', style: 'bold'},
                {value: 'registrationFee', name: 'Реєстраційний внесок', style: 'bold'},
                {value: 'payment', name: 'Плата за лот'},
                {value: 'other', name: 'Інші платежі'}
            ]
    }
    if(isAPD(props.auctionsMy.sellingMethod)){
        return [
                {value: 'guarantee', name: 'Гарантійний внесок', style: 'bold'},
                {value: 'registrationFee', name: 'Реєстраційний внесок', style: 'bold'},
                {value: 'payment', name: 'Плата за лот'},
                {value: 'other', name: 'Інші платежі'}
            ]
    }
    return [
            {value: 'registrationFee', name: 'Реєстраційний внесок'},
            {value: 'guarantee', name: 'Гарантійний внесок', style: 'bold'},
            {value: 'other', name: 'Інші платежі'}
        ]
}

function selectBankAuction(props){
    let tmp = null;
    if(props.bankAccount !== null){
        tmp = props.bankAccount
        .map(
            (i) => {
                let block = <div style={{width: '100%', display: 'flex', justifyContent: 'space-between'}}>
                                <div >
                                    {i.bankName}
                                </div>
                                <div style={{marginRight: '0', color: '#92929D'}}>
                                    {`${i.currency} - 
                                    ${i.accountIdentifications ? i.accountIdentifications[i.accountIdentifications.length - 1].id : null
                                    /*i.accountIdentifications[i.accountIdentifications.length - 1].id}*/}`}
                                </div>
                            </div>
                return {value: i.id, name: block}
            }
        )
    }
    return tmp
}

function elementDisabled(props){
    let tmpStatus = null;
    if(props.auctionsMy.status !== null){
        if(props.auctionsMy.status !== 'active_rectification'){
            tmpStatus = 'disabled'
        }
    }
    return tmpStatus
}

const mapStateToProps = state => {
    return {
        token: state.start.token,
        auctionsMy: state.start.auctionsMy,
        dictionaries: state.start.dictionaries,
        addressNew: state.start.addressNew,
        schedule: state.start.schedule,
        bankAccountZU: state.start.bankAccountZU,
        bankAccount: state.start.bankAccount,
        createBankAccountZU: state.start.createBankAccountZU,
        createBankAccountZUtimestamp: state.start.createBankAccountZUtimestamp,
        auctionsZUAttemp: state.start.auctionsZUAttemp,
    }
}

const mapDispatchToProps = {
    openBankWindows
}

export default connect(mapStateToProps, mapDispatchToProps)(BankAccounts)