import React from 'react';
import styles from './windowAuction.module.css';

import {connect} from 'react-redux';

import {TranslationsUk} from '../../../../redux/translations.uk.js';

import {
    setDataAddress,
} from '../../../../redux/createAddress';

import {changeCreateItemDateUse} from '../../../../redux/actions/auctionItems/auctionItems.js';

import TextField from '@material-ui/core/TextField';

import {disabledElement} from '../../disabled'

const LegitimatePropertyLeaseLeaseDuration = ( props ) => {
    let tmp = null;
    switch(props.auctionsMy.sellingMethod){
        case "legitimatePropertyLease-english":
        case "legitimatePropertyLease-english-fast":
        case "legitimatePropertyLease-english-fast-manual":
        case "legitimatePropertyLease-english-fast-auction-manual-qualification":
        case "legitimatePropertyLease-english-initial-auction":
        case "legitimatePropertyLease-english-initial-auction-manual":
        case "legitimatePropertyLease-english-initial-qualification":
        case "legitimatePropertyLease-english-initial-qualification-prod":
        case "legitimatePropertyLease-dutch":
        case "legitimatePropertyLease-dutch-fast":
        case "legitimatePropertyLease-dutch-fast-manual":
        case "legitimatePropertyLease-dutch-fast-auction-manual-qualification":
        case "legitimatePropertyLease-dutch-initial-auction":
        case "legitimatePropertyLease-dutch-initial-auction-manual":
        case "legitimatePropertyLease-dutch-initial-qualification-prod":
        case "legitimatePropertyLease-priorityEnglish":
        case "legitimatePropertyLease-priorityEnglish-fast":
        case "legitimatePropertyLease-priorityEnglish-fast-manual":
        case "legitimatePropertyLease-priorityEnglish-fast-auction-manual-qualification":
        case "legitimatePropertyLease-priorityEnglish-initial-auction":
        case "legitimatePropertyLease-priorityEnglish-initial-auction-manual":
        case "legitimatePropertyLease-priorityEnglish-initial-qualification":
        case "legitimatePropertyLease-priorityEnglish-initial-qualification-prod":
            tmp = <>
                <div className={styles.groupElem + ' ' + styles.groupElemCategory}>
                    {TranslationsUk['legitimatePropertyLease-english'].legitimatePropertyLeaseEnglishProcedure.leaseDuration.legalName}
                </div>
                <div className={styles.groupElem + ' ' + styles.groupElemCategory}>
                    <div>
                        <TextField
                            error = {redBlock(props, props.auctionsMy.leaseDuration)}
                            disabled={disabledElement(props.auctionsMy, '00')}
                            label="Років"
                            type="number"
                            value={handleViewDate(props, 1)}
                            onChange={(event) => {
                                let tmp = Math.trunc(Number(event.target.value))
                                if(tmp < 0){
                                    tmp = 0
                                }
                                handleDate(props, tmp, 1)
                            }}
                            name="numberformat"
                            id="formatted-numberformat-input"
                            helperText="Років"
                            variant="outlined"
                        />
                    </div>
                    <div>
                        <TextField
                            error = {redBlock(props, props.auctionsMy.leaseDuration)}
                            disabled={disabledElement(props.auctionsMy, '00')}
                            label="Місяців"
                            type="number"
                            value={handleViewDate(props, 2)}
                            onChange={(event) => {
                                let tmp = Math.trunc(Number(event.target.value))
                                if(tmp < 0){
                                    tmp = 0
                                }
                                if(tmp < 13){
                                    handleDate(props, tmp, 2)
                                }
                            }}
                            name="numberformat"
                            id="formatted-numberformat-input"
                            helperText="Місяців"
                            variant="outlined"
                        />
                    </div>
                    <div>
                        <TextField
                            error = {redBlock(props, props.auctionsMy.leaseDuration)}
                            disabled={disabledElement(props.auctionsMy, '00')}
                            label="Днів"
                            type="number"
                            value={handleViewDate(props, 3)}
                            onChange={(event) => {
                                let tmp = Math.trunc(Number(event.target.value))
                                if(tmp < 0){
                                    tmp = 0
                                }
                                if(tmp < 32){
                                    handleDate(props, tmp, 3)
                                }
                            }}
                            name="numberformat"
                            id="formatted-numberformat-input"
                            helperText="Днів"
                            variant="outlined"
                        />
                    </div>
                </div>
            </>
            break;
        case 'landRental-english':
        case 'landRental-english-fast':
        case 'landRental-english-fast-manual':
        case 'landRental-english-fast-auction-manual-qualification':
        case 'landRental-english-initial-auction':
        case 'landRental-english-initial-auction-manual':
        case 'landRental-english-initial-qualification':
        case 'landRental-english-initial-qualification-prod':
        /*case 'landSell-english':
        case 'landSell-english-fast':
        case 'landSell-english-fast-manual':
        case 'landSell-english-fast-auction-manual-qualification':
        case 'landSell-english-initial-auction':
        case 'landSell-english-initial-auction-manual':
        case 'landSell-english-initial-qualification':
        case 'landSell-english-initial-qualification-prod':
        case 'landSell-priorityEnglish':
        case 'landSell-priorityEnglish-fast':
        case 'landSell-priorityEnglish-fast-manual':
        case 'landSell-priorityEnglish-fast-auction-manual-qualification':
        case 'landSell-priorityEnglish-initial-auction':
        case 'landSell-priorityEnglish-initial-auction-manual':
        case 'landSell-priorityEnglish-initial-qualification':
        case 'landSell-priorityEnglish-initial-qualification-prod':*/
            tmp = <>
                <div className={styles.groupElem + ' ' + styles.groupElemCategory}>
                    {TranslationsUk['legitimatePropertyLease-english'].legitimatePropertyLeaseEnglishProcedure.leaseDuration.legalName}*
                </div>
                <div className={styles.groupElem + ' ' + styles.groupElemCategory}>
                    <div>
                        <TextField
                            error = {redBlock(props, props.auctionsMy.leaseDuration)}
                            disabled={disabledElement(props.auctionsMy, '00')}
                            label="Років"
                            type="number"
                            value={handleViewDate(props, 1)}
                            onChange={(event) => {
                                let tmp = Math.trunc(Number(event.target.value))
                                if(tmp < 0){
                                    tmp = 0
                                }
                                handleDate(props, tmp, 1)
                            }}
                            name="numberformat"
                            id="formatted-numberformat-input"
                            helperText="Років"
                            variant="outlined"
                        />
                    </div>
                    <div>
                        <TextField
                            error = {redBlock(props, props.auctionsMy.leaseDuration)}
                            disabled={disabledElement(props.auctionsMy, '00')}
                            label="Місяців"
                            type="number"
                            value={handleViewDate(props, 2)}
                            onChange={(event) => {
                                let tmp = Math.trunc(Number(event.target.value))
                                if(tmp < 0){
                                    tmp = 0
                                }
                                if(tmp < 13){
                                    handleDate(props, tmp, 2)
                                }
                            }}
                            name="numberformat"
                            id="formatted-numberformat-input"
                            helperText="Місяців"
                            variant="outlined"
                        />
                    </div>
                    <div>
                        <TextField
                            error = {redBlock(props, props.auctionsMy.leaseDuration)}
                            disabled={disabledElement(props.auctionsMy, '00')}
                            label="Днів"
                            type="number"
                            value={handleViewDate(props, 3)}
                            onChange={(event) => {
                                let tmp = Math.trunc(Number(event.target.value))
                                if(tmp < 0){
                                    tmp = 0
                                }
                                if(tmp < 32){
                                    handleDate(props, tmp, 3)
                                }
                            }}
                            name="numberformat"
                            id="formatted-numberformat-input"
                            helperText="Днів"
                            variant="outlined"
                        />
                    </div>
                </div>
            </>
            break;
        default:
            break;
    }
    return tmp
}

function handleViewDate(props, type){
    let tmp = null;
    if(props.auctionsMy.hasOwnProperty('leaseDuration') === true && props.auctionsMy.leaseDuration !== null){
        let tmpArr = props.auctionsMy.leaseDuration.split('P')
        let tmpArr1 = tmpArr[1].split('Y')
        let tmpArr2 = tmpArr1[1].split('M')
        let tmpArr3 = tmpArr2[1].split('D')
        if(type === 1){
            if(tmpArr1[0] !== '0'){
                tmp = tmpArr1[0]
            }else{
                tmp = ''
            }
        }else if(type === 2){
            if(tmpArr2[0] !== '0'){
                tmp = tmpArr2[0]
            }else{
                tmp = ''
            }
        }else if(type === 3){
            if(tmpArr3[0] !== '0'){
                tmp = tmpArr3[0]
            }else{
                tmp = ''
            }
        }
    }else{
        if(type === 1){
            tmp = ``
        }else if(type === 2){
            tmp = ``
        }else if(type === 3){
            tmp = ``
        }
    }
    return tmp
}

function redBlock(props, amount){
    if(
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-fast" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-fast-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-fast-auction-manual-qualification" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-auction" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-auction-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-qualification" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-qualification-prod" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-fast" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-fast-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-fast-auction-manual-qualification" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-initial-auction" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-initial-auction-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-initial-qualification-prod" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-auction-manual-qualification" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification-prod"
    ){
        if(amount === null || amount === '' || amount === 'null'){
            return 'error'
        }
        return null
    }
    return null
}

function handleDate(props, date, type){
    let tmp = null;
    if(props.auctionsMy.leaseDuration !== null){
        let tmpArr = props.auctionsMy.leaseDuration.split('P')
        let tmpArr1 = tmpArr[1].split('Y')
        let tmpArr2 = tmpArr1[1].split('M')
        let tmpArr3 = tmpArr2[1].split('D')
        if(type === 1){
            if(date === ''){
                tmp = `P0Y${tmpArr2[0]}M${tmpArr3[0]}D`
            }else{
                tmp = `P${date}Y${tmpArr2[0]}M${tmpArr3[0]}D`
            }
        }else if(type === 2){
            tmp = `P${tmpArr1[0]}Y${date}M${tmpArr3[0]}D`
            if(date === ''){
                tmp = `P${tmpArr1[0]}Y0M${tmpArr3[0]}D`
            }else{
                tmp = `P${tmpArr1[0]}Y${date}M${tmpArr3[0]}D`
            }
        }
        else if(type === 3){
            tmp = `P${tmpArr1[0]}Y${tmpArr2[0]}M${date}D`
            if(date === ''){
                tmp = `P${tmpArr1[0]}Y${tmpArr2[0]}M0D`
            }else{
                tmp = `P${tmpArr1[0]}Y${tmpArr2[0]}M${date}D`
            }
        }
    }else{
        if(type === 1){
            tmp = `P${date}Y0M0D`
        }else if(type === 2){
            tmp = `P0Y${date}M0D`
        }else if(type === 3){
            tmp = `P0Y0M${date}D`
        }

    }
    props.changeCreateItemDateUse(tmp)
}

function elementDisabledCreate(props){
    let tmpStatus = null;
    if(props.auctionsMy.status !== null){
        if(props.auctionsMy.status !== 'active_rectification'){
            tmpStatus = 'disabled'
        }
    }
    return tmpStatus
}

const mapStateToProps = state => {
    return {
        auctionsMy: state.start.auctionsMy,
        auctionsZUAttemp: state.start.auctionsZUAttemp,
    }
}

const mapDispatchToProps = {
    changeCreateItemDateUse,
    setDataAddress,
}

export default connect(mapStateToProps, mapDispatchToProps)(LegitimatePropertyLeaseLeaseDuration)