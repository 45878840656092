import React from 'react';
import styles from './auctions.module.css';

import {connect} from 'react-redux';
import { Link } from 'react-router-dom';
////////////////////////
import {
    auctionsGetMy,
    changeAuctionsSwitchWindow,
    auctionsGetClassificationsByType,
    auctionsGetClassificationsByTimberSortment,
    auctionsGetClassificationsByTimberSortmentFromCashe,
    auctionsGetClassificationsByTimberSpecies,
    auctionsGetClassificationsByTimberSpeciesFromCashe,
    auctionsGetClassificationsByTimberClass,
    auctionsGetClassificationsByTimberClassFromCashe,
    auctionsGetClassificationsByTimberDiameter,
    auctionsGetClassificationsByTimberDiameterFromCashe,
    auctionsGetClassificationsByTimberLength,
    auctionsGetClassificationsByTimberLengthFromCashe,
    auctionsGetClassificationsByTimberStorage,
    auctionsGetClassificationsByTimberStorageFromCashe,
    auctionsGetClassificationsByTimberProductionYear,
    auctionsGetClassificationsByTimberProductionYearFromCashe,
    auctionsGetClassificationsByTimberProductionQuarter,
    auctionsGetClassificationsByTimberProductionQuarterFromCashe,
    auctionsGetClassificationsByWagonSpecies,
    auctionsGetClassificationsByWagonSpeciesFromCashe,
    auctionsGetClassificationsByTypeNadraEnglish,
    auctionsGetClassificationsByTypeNadraEnglishFromCashe,
    auctionsGetClassificationsByByShemaSubsoilUsageType,
    auctionsGetClassificationsByTypeRailway,
    auctionsGetClassificationsByCarryType,
    auctionsGetClassificationsByTypeRailwayCargoAdd,
    auctionsGetWagonRestrict,
    auctionsGetWagonRestrictType,
} from '../../../redux/actions/auctions/auctions.js';

import {
    setDataAddress,
} from '../../../redux/createAddress';
import { setAucDocTypes } from '../../../redux/docTypes';

import {
    changeCreateItemClassificationAddId,
    getMyAuction
} from '../../../redux/actions.js';

import {bankAccountGet, bankAccountShemeGet, setBankAccountSave, setBankAccountAdd, setBankAccountDelete} from '../../../redux/actions/bankAccount/bankAccount.js';

import {auctionsBidsGetMy} from  '../../../redux/actions/auctionBid/auctionBid.js';

import {unitsGetByType, unitsGetByTypeEnergy, unitsGetByTypeRailway, unitsGetByTypeSubsoil} from '../../../redux/actions/itemUnits/itemUnits.js';

import {addressesGetMy} from '../../../redux/actions/address/address.js';

import {
    workMyPageCurrentAuction,
    workMyPageNextAuction
} from '../../../redux/togetherApi.js';

////////////////////////

import {changeCreateAuctionTab} from '../../../redux/actions.js';
import {setPopUpSendAuction, setPopUpErrorAuction} from '../../../redux/actions/auctions/auctions.js';
import {classificationsGetByScheme} from '../../../redux/actions/classifications/classifications.js';
import {
    changeCreateAuctionTitle,
    changeCreateAuctionDescription,
    changeCreateAuctionType,
    changeCreateAuctionTenderAttempts,
    changeCreateAuctionAccessDetails,
    changeCreateAuctionXDocumentRequirements,
    changeCreateAuctionXAdditionalInformation,
    changeCreateAuctionValueCurrency,
    changeCreateAuctionValueAmount,
    changeCreateAuctionValueTax,
    changeCreateAuctionGuaranteeCurrency,
    changeCreateAuctionGuaranteeAmount,
    changeCreateAuctionGuaranteeTax,
    changeCreateAuctionMinimalStepCurrency,
    changeCreateAuctionMinimalStepAmount,
    changeCreateAuctionMinimalStepTax,
    changeCreateAuctionDateStart,
    changeCreateAuctionDateEnd,
    changeCreateAuctionId,
    changePageNumber,
    setEditAuctionFromId,
    changeLoader,
} from '../../../redux/actions.js';

import {
    changeDiscountPercent,
    changeDiscountPreviousAuctionCurrency,
    changeDiscountPreviousAuctionId,
    discountAuc,
    changeDiscountId,
} from '../../../redux/actions/discount/discount.js';

import {setUniversalError} from '../../../redux/actions.js';

import {auctionsGetAwards, auctionsGetAwardsId} from '../../../redux/actions/awards/awards.js';
import {getAuctionsBidsId} from '../../../redux/actions/auctionBid/auctionBid.js';
import {contractsAuctionIdGet} from '../../../redux/actions/contracts/contracts.js';
import {setPopBankAccountId, setPopBankAccountIdArr} from '../../../redux/actions/bankAccount/bankAccount.js';

import {getAPI} from '../../../redux/actions/crud.js';

import {setLogo} from '../../../redux/actions/support.js';

import {setPopUpAuctionMyNull} from '../../../redux/actions/auctions/auctions.js';

import DropDownMenu from './dropDownMenu/dropDownMenu'
import Paginator from './paginator/paging'
import MyAuctionsHeader from './myAuctionsHeader/myAuctionsHeader'
import {checkProp, getShorter, isMongo} from '../../../redux/check'
import { viewAuctionType } from '../../../redux/procedure';

import { isBSE, isBSD, isALE, isCSE, isCSD, isBRE, isBRD, isBRW, isSPE,
    isSPD, isNLE, isNLD, isLAE, isLAW, isLAP, isLPE, isAPE, isAPD
} from "../../../redux/procedure";
import { getAuctionsStatus } from "../../../api/dictonaries/statusAuctions"

const Auctions = ( props ) => {
    let tmpAuc = null
    if(props.auctionsMy !== null){
        if(props.auctionsMy.length > 0){
            tmpAuc = createListAuctions(props.auctionsMy, props)
        }
    }
    if(
        props.userProfiles.canCreateAuction === true ||
        props.userProfiles.canCreateLandAuction === true ||
        props.userProfiles.canCreateLeaseAuction === true ||
        props.userProfiles.canCreateAlienation === true ||
        props.userProfiles.canCreateBasicSell === true ||
        props.userProfiles.canCreateCommercialSellAuction === true ||
        props.userProfiles.canCreateBankruptAuction === true ||
        props.userProfiles.canCreatePrivatization === true ||
        props.userProfiles.canCreateArma === true// ||
    ){
        return (
            <>
                <MyAuctionsHeader />
                <div className={styles.wrap_listAuctions}>
                    <div className={styles.header_line}>
                        {/*<div className={styles.listAuctions_id + ' ' + styles.header}>ID DTO</div>*/}
                        <div className={styles.listAuctions_title + ' ' + styles.header}>Назва аукціону</div>
                        {/* <div className={styles.listAuctions_idProzzoro + ' ' + styles.header}>ID Prozorro</div> */}
                        <div className={styles.listAuctions_status + ' ' + styles.header}>Статус аукціону</div>
                        <div className={styles.listAuctions_help + ' ' + styles.header}>Зверніть увагу</div>
                        <div className={styles.header}>Дії</div>
                    </div>
                    {tmpAuc}
                    <Paginator />
                </div>
            </>
        )
    }
    return null
}

function createListAuctions(data, props){
    return data
    // .filter((i) => {
    //     return i.sellingMethod !== null
    // })
    .map(
        (i, count) => {
            return (
                <div key={i.id} className={styles.listAuctions}>
                    {/*<div className={styles.listAuctions_id}>{idView(i)}</div>*/}
                    <div className={styles.listAuctions_title}>
                        <div className={styles.listAuctions_id}>
                        <div className={styles.listAuctions_badge}>{viewAuctionType(i.sellingMethod)}</div>
                            { checkProp(i,'auctionId') ? 
                                <div className={styles.listAuctions_type}>
                                    <div className={styles.listAuctions_type_grey}>
                                        ID Prozorro: 
                                    </div>
                                    <div>{i.auctionId}</div>
                                </div> : null 
                            }
                        </div>
                        {titleView(i)}
                    </div>
                    {/* <div className={styles.listAuctions_idProzzoro}>{i.auctionId}</div> */}
                    <div className={styles.listAuctions_status}>{setTextStatus(i.status, i.sellingMethod)}</div>
                    {createHelpBlock(i)}
                    <DropDownMenu i={i} count={count}/>
                </div>
            )
        }
    )
}

function setWarning(data, props){
    if(data.status !== null) return null
    if(data.items === null) return null
    if(data.items.length === 0) return null
    if(data.items[0].itemProps === null) return null
    if(data.items[0].itemProps.cadastralNumber === null) return null
    if(searchCadastr(data.items[0].itemProps.cadastralNumber, props)){
        return (
            <div className={styles.listAuctions_help}>
                <div className={styles.attentionsHelp}>
                    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12.6666 8.66666V15.3333C12.6666 16.0697 13.2636 16.6667 14 16.6667C14.7363 16.6667 15.3333 16.0697 15.3333 15.3333V8.66666C15.3333 7.93028 14.7363 7.33333 14 7.33333C13.2636 7.33333 12.6666 7.93028 12.6666 8.66666Z" fill="#139DD9"/>
                        <path d="M15.3333 19.3333C15.3333 20.0697 14.7363 20.6667 14 20.6667C13.2636 20.6667 12.6666 20.0697 12.6666 19.3333C12.6666 18.5969 13.2636 18 14 18C14.7363 18 15.3333 18.5969 15.3333 19.3333Z" fill="#139DD9"/>
                        <path fillRule="evenodd" clipRule="evenodd" d="M27.3333 14C27.3333 21.3638 21.3638 27.3333 14 27.3333C6.63616 27.3333 0.666626 21.3638 0.666626 14C0.666626 6.6362 6.63616 0.666664 14 0.666664C21.3638 0.666664 27.3333 6.6362 27.3333 14ZM3.33329 14C3.33329 19.891 8.10892 24.6667 14 24.6667C19.891 24.6667 24.6666 19.891 24.6666 14C24.6666 8.10896 19.891 3.33333 14 3.33333C8.10892 3.33333 3.33329 8.10896 3.33329 14Z" fill="#139DD9"/>
                    </svg>
                </div>
                <div><p>Для вказаної вами земельної ділянки вже було опублікований аукціон іншим Організатором, ви впевнені, що хочете продовжити публікацію?</p></div>
            </div>
        )
    }
    return null
}

async function searchCadastr(data, props){
    let url = `https://procedure-sandbox.prozorro.sale/api/search/procedures/byText/${data}`;
    const response1 = await fetch(url, {
        method: "GET",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response1 === undefined || response1 === 'undefined'){
    }else{
        const json1 = await response1.json()
        if(json1.hasOwnProperty('message') === true){
            let tmp = JSON.stringify({title: 'Помилка при створенні копії', message: JSON.stringify(json1)})
	        props.setUniversalError(tmp)
        }else{
            let tmp = json1.filter((i) => {
                return i.owner !== "dto.com.ua"
            })
            if(tmp.length > 0) return true
            return false
        }
    }
}




function idView(data){
    if(data.hasOwnProperty('id') === true){
        if(data.id !== null){
            if(data.auctionId === null) return data.id
        }
    }
    return null
}

function titleView(data){
    if(checkProp(data,'title') && checkProp(data,'auctionId')) {
        return <div className={styles.listAuctions_titleAuc}>
                    <Link className={styles.listAuctions_titleLink}
                        to={`/auctions/${data.auctionId}`} 
                        title="Посилання на аукціон">{getShorter(isMongo(data.title), 240)}
                    </Link>
                </div>
    }
    if(checkProp(data,'title')) return <div className={styles.listAuctions_titleAuc}>{getShorter(isMongo(data.title), 240)}</div>
    return null
}

function createHelpBlock(data){
    if(helpTextPablish(data) !== null
        || helpTextDocs(data) !== null
        || helpTextItems(data) !== null
    ){
        return (
            <div className={styles.listAuctions_help}>
                <div className={styles.attentionsHelp}>
                    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12.6666 8.66666V15.3333C12.6666 16.0697 13.2636 16.6667 14 16.6667C14.7363 16.6667 15.3333 16.0697 15.3333 15.3333V8.66666C15.3333 7.93028 14.7363 7.33333 14 7.33333C13.2636 7.33333 12.6666 7.93028 12.6666 8.66666Z" fill="#139DD9"/>
                        <path d="M15.3333 19.3333C15.3333 20.0697 14.7363 20.6667 14 20.6667C13.2636 20.6667 12.6666 20.0697 12.6666 19.3333C12.6666 18.5969 13.2636 18 14 18C14.7363 18 15.3333 18.5969 15.3333 19.3333Z" fill="#139DD9"/>
                        <path fillRule="evenodd" clipRule="evenodd" d="M27.3333 14C27.3333 21.3638 21.3638 27.3333 14 27.3333C6.63616 27.3333 0.666626 21.3638 0.666626 14C0.666626 6.6362 6.63616 0.666664 14 0.666664C21.3638 0.666664 27.3333 6.6362 27.3333 14ZM3.33329 14C3.33329 19.891 8.10892 24.6667 14 24.6667C19.891 24.6667 24.6666 19.891 24.6666 14C24.6666 8.10896 19.891 3.33333 14 3.33333C8.10892 3.33333 3.33329 8.10896 3.33329 14Z" fill="#139DD9"/>
                    </svg>
                </div>
                <div>
                    {helpTextPablish(data)}
                    {helpTextDocs(data)}
                    {helpTextItems(data)}
                </div>
            </div>
        )
    }
    return <div className={styles.listAuctions_help}></div>
}

function helpTextPablish(data){
    if(
        data.status === 'active_rectification' ||
        data.status === 'active_tendering' ||
        data.status === 'active_auction' ||
        data.status === 'active_qualification' ||
        data.status === 'active_awarded' ||
        data.status === 'pending_admission' ||
        data.status === 'pending_payment' ||
        data.status === 'complete'
    ){
        return <div>Аукціон опубліковано</div>
    }
    return null
}
function helpTextDocs(data){
    if(
        data.status === 'active_rectification' ||
        data.status === 'active_tendering' ||
        data.status === 'active_auction' ||
        data.status === 'active_qualification' ||
        data.status === 'active_awarded' ||
        data.status === 'pending_admission' ||
        data.status === 'pending_payment' ||
        data.status === 'complete'
    ){
        return null
    }
    if(data.hasOwnProperty('documents') === true){
        if(resultRevisionData(data.documents, data) && needMainDocs(data)){
            if(data.status === null){
                return <div>Необхідно додати документи</div>
            }
        }
    }
    return null
}
function helpTextItems(data){
    if(
        data.status === 'active_rectification' ||
        data.status === 'active_tendering' ||
        data.status === 'active_auction' ||
        data.status === 'active_qualification' ||
        data.status === 'active_awarded' ||
        data.status === 'pending_admission' ||
        data.status === 'pending_payment' ||
        data.status === 'complete'
    ){
        return null
    }
    if(data.hasOwnProperty('items') === true){
        if(resultRevisionData(data.items)){
            return <div>Необхідно додати лот</div>
        }
    }
    return null
}

function needMainDocs(data){
    let tmpMain = setAucDocTypes(data)
    let tmpNeedMain = []
    if (tmpMain !== null && tmpMain.length > 0){
        tmpNeedMain = tmpMain.filter( i => {return i.main === true})
        if (tmpNeedMain.length > 0) return true
    }
   return false
}

function resultRevisionData(data, auc){
    if(data !== null){
        if(data.length === 0){
            return true
        }else{
            if(
                auc !== null &&
                auc !== undefined &&
                auc !== 'undefined'
            ){
                // if(
                //     auc.sellingMethod === "legitimatePropertyLease-english" ||
                //     auc.sellingMethod === "legitimatePropertyLease-english-fast" ||
                //     auc.sellingMethod === "legitimatePropertyLease-english-fast-manual" ||
                //     auc.sellingMethod === "legitimatePropertyLease-english-fast-auction-manual-qualification" ||
                //     auc.sellingMethod === "legitimatePropertyLease-english-initial-auction" ||
                //     auc.sellingMethod === "legitimatePropertyLease-english-initial-auction-manual" ||
                //     auc.sellingMethod === "legitimatePropertyLease-english-initial-qualification" ||
                //     auc.sellingMethod === "legitimatePropertyLease-english-initial-qualification-prod" ||
                //     auc.sellingMethod === "legitimatePropertyLease-dutch" ||
                //     auc.sellingMethod === "legitimatePropertyLease-dutch-fast" ||
                //     auc.sellingMethod === "legitimatePropertyLease-dutch-fast-manual" ||
                //     auc.sellingMethod === "legitimatePropertyLease-dutch-fast-auction-manual-qualification" ||
                //     auc.sellingMethod === "legitimatePropertyLease-dutch-initial-auction" ||
                //     auc.sellingMethod === "legitimatePropertyLease-dutch-initial-auction-manual" ||
                //     auc.sellingMethod === "legitimatePropertyLease-dutch-initial-qualification-prod" ||
                //     auc.sellingMethod === "legitimatePropertyLease-priorityEnglish" ||
                //     auc.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast" ||
                //     auc.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-manual" ||
                //     auc.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-auction-manual-qualification" ||
                //     auc.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction" ||
                //     auc.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction-manual" ||
                //     auc.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification" ||
                //     auc.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification-prod"
                // ){
                //     return documentsTrue(data)
                //     /*let tmpContractProformaZU = false
                //     let tmpIllustrationZU = false
                //     for(let tr = 0; tr < data.length; tr++){
                //         if(data[tr].documentType === "contractProforma") tmpContractProformaZU = true
                //         if(data[tr].documentType === "illustration") tmpIllustrationZU = true
                //     }
                //     if(
                //         tmpContractProformaZU === true &&
                //         tmpIllustrationZU === true
                //     ){
                //         return false
                //     }else{
                //         return true
                //     }*/
                // }
            }
        }
    }
    return false
}

function documentsTrue(data){
    let tmpContractProformaZU = false
    let tmpIllustrationZU = false
    for(let tr = 0; tr < data.length; tr++){
        if(data[tr].documentType === "contractProforma") tmpContractProformaZU = true
        if(data[tr].documentType === "illustration") tmpIllustrationZU = true
    }
    if(
        tmpContractProformaZU === true &&
        tmpIllustrationZU === true
    ){
        return false
    }else{
        return true
    }
}

function setTextStatus(data, sellingMethod){
    if(data === null) return getAuctionsStatus(sellingMethod, data)
    if(data === 'active_rectification') return getAuctionsStatus(sellingMethod, data)
    if(data === 'active_tendering') return <div style={{color: '#00B191'}}>{getAuctionsStatus(sellingMethod, data)}</div>
    if(data === 'active_auction') return <div style={{color: '#FFA047'}}>{getAuctionsStatus(sellingMethod, data)}</div>
    if(data === 'active_qualification') return <div style={{color: '#00B191'}}>{getAuctionsStatus(sellingMethod, data)}</div>
    if(data === 'active_awarded') return <div style={{color: '#00B191'}}>{getAuctionsStatus(sellingMethod, data)}</div>
    if(data === 'pending_payment') return <div style={{color: '#00B191'}}>{getAuctionsStatus(sellingMethod, data)}</div>
    if(data === 'pending_admission') return <div style={{color: '#A68800'}}>{getAuctionsStatus(sellingMethod, data)}</div>
    if(data === 'cancelled') return <div style={{color: '#696974'}}>{getAuctionsStatus(sellingMethod, data)}</div>
    if(data === 'unsuccessful') return <div style={{color: '#696974'}}>{getAuctionsStatus(sellingMethod, data)}</div>
    if(data === 'complete') return <div style={{color: '#171725'}}>{getAuctionsStatus(sellingMethod, data)}</div>
    return data
}

const mapStateToProps = state => {
    return {
        token: state.start.token,
        auctions: state.start.auctionsGet,
        auctionsMy: state.start.auctionsGetMy,
        auctionsMySingl: state.start.auctionsMy,
        langFlag: state.start.langFlag,
        createAuctionTab: state.start.createAuctionTab,
        auctionsSwitchWindow: state.start.auctionsSwitchWindow,
        auctionsMyNew: state.start.auctionsMyNew,
        userProfiles: state.start.userProfiles,
        myBids: state.start.myBids,
        myCabinetPage: state.start.myCabinetPage,
        cloneObjectId: state.start.cloneObjectId,
    }
}

const mapDispatchToProps = {
    changeCreateAuctionTab,

    changeCreateAuctionTitle,
    changeCreateAuctionDescription,
    changeCreateAuctionType,
    changeCreateAuctionTenderAttempts,
    changeCreateAuctionAccessDetails,
    changeCreateAuctionXDocumentRequirements,
    changeCreateAuctionXAdditionalInformation,
    changeCreateAuctionValueCurrency,
    changeCreateAuctionValueAmount,
    changeCreateAuctionValueTax,
    changeCreateAuctionGuaranteeCurrency,
    changeCreateAuctionGuaranteeAmount,
    changeCreateAuctionGuaranteeTax,
    changeCreateAuctionMinimalStepCurrency,
    changeCreateAuctionMinimalStepAmount,
    changeCreateAuctionMinimalStepTax,
    changeCreateAuctionDateStart,
    changeCreateAuctionDateEnd,
    changeCreateAuctionId,

    classificationsGetByScheme,
    getAPI,
    setPopUpSendAuction,

    auctionsGetAwards,
    auctionsGetAwardsId,
    getAuctionsBidsId,
    changePageNumber,

    contractsAuctionIdGet,
    setPopUpErrorAuction,
    setPopBankAccountId,
    setPopBankAccountIdArr,

    changeDiscountPercent,
    changeDiscountPreviousAuctionCurrency,
    changeDiscountPreviousAuctionId,
    discountAuc,
    changeDiscountId,

    setLogo,

    setPopUpAuctionMyNull,

    setUniversalError,
    setEditAuctionFromId,

    changeLoader,

    //////////////////////////
    auctionsGetMy,
    changeAuctionsSwitchWindow,
    auctionsGetClassificationsByType,
    auctionsGetClassificationsByTimberSortment,
    auctionsGetClassificationsByTimberSortmentFromCashe,
    auctionsGetClassificationsByTimberSpecies,
    auctionsGetClassificationsByTimberSpeciesFromCashe,
    auctionsGetClassificationsByTimberClass,
    auctionsGetClassificationsByTimberClassFromCashe,
    auctionsGetClassificationsByTimberDiameter,
    auctionsGetClassificationsByTimberDiameterFromCashe,
    auctionsGetClassificationsByTimberLength,
    auctionsGetClassificationsByTimberLengthFromCashe,
    auctionsGetClassificationsByTimberStorage,
    auctionsGetClassificationsByTimberStorageFromCashe,
    auctionsGetClassificationsByTimberProductionYear,
    auctionsGetClassificationsByTimberProductionYearFromCashe,
    auctionsGetClassificationsByTimberProductionQuarter,
    auctionsGetClassificationsByTimberProductionQuarterFromCashe,
    auctionsGetClassificationsByWagonSpecies,
    auctionsGetClassificationsByWagonSpeciesFromCashe,
    auctionsGetClassificationsByTypeNadraEnglish,
    auctionsGetClassificationsByTypeNadraEnglishFromCashe,
    auctionsGetClassificationsByByShemaSubsoilUsageType,
    auctionsGetClassificationsByTypeRailway,
    auctionsGetClassificationsByCarryType,
    auctionsGetClassificationsByTypeRailwayCargoAdd,
    auctionsGetWagonRestrict,
    auctionsGetWagonRestrictType,
    changeCreateItemClassificationAddId,
    getMyAuction,
	bankAccountGet,
	bankAccountShemeGet,
	auctionsBidsGetMy,
	unitsGetByType,
	unitsGetByTypeEnergy,
	unitsGetByTypeRailway,
	unitsGetByTypeSubsoil,
    addressesGetMy,
    
    workMyPageCurrentAuction,
    workMyPageNextAuction,

    setBankAccountSave,
    setBankAccountAdd,
    setBankAccountDelete,
    setDataAddress,
}

export default connect(mapStateToProps, mapDispatchToProps)(Auctions)
