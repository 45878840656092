export function getDefaultHeaders(props) {
    let headers = {
        'Content-Type': 'application/json'
    }
    if (props.token !== null) {
        headers['Authorization'] = props.token.access_token
    }
    return headers;
}

export async function getUserBidsListByAuction(props, auctionId) {
    return await fetch(
        `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction/${auctionId}/bids/all`
        , {
            method: "GET",
            headers: getDefaultHeaders(props)
        })
        .catch(error => {
            props.changeLoader(false)
            console.error('Api request error:', error);
        })
        .then(response => {
            response.json()
                .catch(error => {
                    props.changeLoader(false)
                    console.error('Json parse error:', error);
                }).then(json => {
                props.saveArrBids(json)
            })
        })
}
