import React, { useState, useEffect } from 'react';
import styles from './localization.module.css';

import {connect} from 'react-redux';
import Input from '../../../elements/inputs/input'
import Select from '../../../elements/inputs/inputSelect'

import { isBRD, isBRE, isBRW, isALE, isBSD, isBSE, isCSD, isCSE, isLRE, isLSE, isLSP, isRLE, isRLD, isCLE, isCLD,
    isSPE, isSPD, isNLE, isNLD, isLAE, isLAW, isLAP, isLPE, isAPE, isAPD,
    isLLE
} from '../../../../redux/procedure'


import { popUpAddress } from '../../../../redux/actions/address/address.js';
import { setDataAddress } from '../../../../redux/createAddress';
//import { disabledElement } from '../../disabled';
import { checkProp, isMongo } from '../../../../redux/check';

const LocationOrg = ({address, setAdress, dataText, disabled, ...props}) => {

    const [country, setCountry] = useState(checkProp(address, 'country') ? isMongo(address.country) : 'Україна')
    const [region, setRegion] = useState(checkProp(address, 'region') ? isMongo(address.region) : null)
    const [locality, setLocality] = useState(checkProp(address, 'locality') ? isMongo(address.locality) : null)
    const [street, setStreet] = useState(checkProp(address, 'streetAddress') ? isMongo(address.streetAddress) : null)
    const [postal, setPostal] = useState(checkProp(address, 'postalCode') ? address.postalCode : null)

    useEffect(() => {
        setAdress({
            addressID: {
                scheme: 'koatuu',
                /*name: {
                    uk_UA: region,
                },*/
                id: setKoatuu(region)
            },
            streetAddress: street !== null && street !== '' ? {uk_UA: street} : null,
            locality: locality !== null && locality !== '' ? {uk_UA: locality} : null,
            region: {
                uk_UA: region,
            },
            postalCode: postal !== null && postal !== '' ? postal : null,
            countryName: {
                uk_UA: country,
            }
        })
        if (region === 'Київ') setLocality('Київ')
    }, [country, region, locality, street, postal])

    function redBlock(amount){
        if(amount === null || amount === '' || amount === 'null'){
            return 'error'
        }
        return null
    }

    function redBlockIndex(props, data){
    if(props.auction === undefined || props.auction === 'undefined'){
        return null
    }else{
        if(props.auction !== null){
            if(data === null || data === ''){
                return null
            }else{
                if(data.length !== 5) return 'error'
            }
        }
    }
    return null
    }
    
    return (
            <div className={styles.wrapBlock}>
            <div className={styles.wrapBlockTitle}>{dataText}</div>
            <Input
                label='Країна'
                disabled
                value={country}
                onChange={(event)=>{
                    setCountry(event)
                }}
                required
                // error={redBlock(props, props.createItems.landRental.addressItem.countryName)}
            />
            <Select
                data={{
                    label: "Область",
                    target: region,
                    // info: 'Отака от штука-дрюка',
                    required: true,
                }}
                disabled={disabled}
                onChange={(e) => { setRegion(e) }}
                // error={redBlock(props.createItems.landRental.addressItem.region)}
                options={regionsList}
            />
            <Input
                label='Населенний пункт'
                value={locality}
                disabled={disabled}
                onChange={(event)=>{
                    setLocality(event)
                }}
                required
                // error={redBlock(props, props.createItems.landRental.addressItem.locality)}
            />
            <Input
                label='Адреса'
                value={street}
                disabled={disabled}
                onChange={(event)=>{
                    setStreet(event)
                }}
                required
                // error={redBlock(props, props.createItems.landRental.addressItem.streetAddress)}
            />
            <Input
                label='Поштовий індекс'
                value={postal}
                disabled={disabled}
                onChange={(event)=>{
                    setPostal(event)
                }}
                maxlength={'5'}
                // required
                // disabled={blokedInput(props, '11') 45654987-84654654}
                error={postal !== null && postal.length > 5 ? 'Індекс не може бути довшим ніж 5 цифр' : false}
            />
        </div>
    )
}

function setKoatuu(data){
    for (let key in regionsList) { 
        if( data === regionsList[key].value) return regionsList[key].koatuu
    } 
}

const regionsList = [
        { name: 'Севастополь', value: 'Севастополь', koatuu: "8500000000"},
        { name: 'Київ', value: 'Київ', koatuu: "8000000000"},
        { name: 'Чернігівська область', value: 'Чернігівська область', koatuu: "7400000000"},
        { name: 'Чернівецька область', value: 'Чернівецька область', koatuu: "7300000000"},
        { name: 'Черкаська область', value: 'Черкаська область', koatuu: "7100000000"},
        { name: 'Хмельницька область', value: 'Хмельницька область', koatuu: "6800000000"},
        { name: 'Херсонська область', value: 'Херсонська область', koatuu: "6500000000"},
        { name: 'Харківська область', value: 'Харківська область', koatuu: "6300000000"},
        { name: 'Тернопільська область', value: 'Тернопільська область', koatuu: "6100000000"},
        { name: 'Сумська область', value: 'Сумська область', koatuu: "5900000000"},
        { name: 'Рівненська область', value: 'Рівненська область', koatuu: "5600000000"},
        { name: 'Полтавська область', value: 'Полтавська область', koatuu: "5300000000"},
        { name: 'Одеська область', value: 'Одеська область', koatuu: "5100000000"},
        { name: 'Миколаївська область', value: 'Миколаївська область', koatuu: "4800000000"},
        { name: 'Львівська область', value: 'Львівська область', koatuu: "4600000000"},
        { name: 'Луганська область', value: 'Луганська область', koatuu: "4400000000"},
        { name: 'Кіровоградська область', value: 'Кіровоградська область', koatuu: "3500000000"},
        { name: 'Київська область', value: 'Київська область', koatuu: "3200000000"},
        { name: 'Івано-Франківська область', value: 'Івано-Франківська область', koatuu: "2600000000"},
        { name: 'Запорізька область', value: 'Запорізька область', koatuu: "2300000000"},
        { name: 'Закарпатська область', value: 'Закарпатська область', koatuu: "2100000000"},
        { name: 'Житомирська область', value: 'Житомирська область', koatuu: "1800000000"},
        { name: 'Донецька область', value: 'Донецька область', koatuu: "1400000000"},
        { name: 'Дніпропетровська область', value: 'Дніпропетровська область', koatuu: "1200000000"},
        { name: 'Волинська область', value: 'Волинська область', koatuu: "0700000000"},
        { name: 'Вінницька область', value: 'Вінницька область', koatuu: "0500000000"},
        { name: 'Автономна Республіка Крим', value: 'Автономна Республіка Крим', koatuu: "0100000000"},
]

const mapStateToProps = state => {
    return {
        token: state.start.token,
        auctions: state.start.auctionsGet,
        auction: state.start.auctionsMy,
        langFlag: state.start.langFlag,
        createItems: state.start.createItems,
        dictionaries: state.start.dictionaries,
        addressGetId: state.start.addressGetId,
        userProfiles: state.start.userProfiles,
    }
}

const mapDispatchToProps = {
    setDataAddress,
    // changeLoader,
    // getMyAuction,
    popUpAddress,
    // setUniversalError,
}

export default connect(mapStateToProps, mapDispatchToProps)(LocationOrg)