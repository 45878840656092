import React, {useState} from 'react';
import styles from './newAward.module.css';

import {connect} from 'react-redux';

import {changeAwardRejectPopUpStatus,} from '../../../redux/actions/awards/awards.js';

import {setAwardAllUserPopUp, setAwardAllUserPopUpUser} from '../../../redux/actions.js';

import {
    changeContractApprowePopUpStatus,
    setContractTitle,
    setContractDescription,
    setContractContractNumber,
    setContractDateFrom,
    setContractDateTill,
    setContractStartDate,
    setContractEndDate,
    setContractCurrency,
    setContractAmount,
    setContractDateSigned,
    setContractDatePaid,
    setContractDocId,
    setContractDocTitle,
    setContractDocType,
    setContractDocDescription,
    setContractDocDocumentOf,
} from '../../../redux/actions/contracts/contracts.js';

import {getUpdateDataAuction, getUpdateDataBid} from '../../product/updateData';
import {saveArrBids} from '../../../redux/allAuctionBids';
import {setDataAddress} from '../../../redux/createAddress';

import TextHalf from '../../elements/viewData/textHalf'
import PopDown from '../../elements/viewData/popdownBlock/popDownBlock'
import AddDocPopup from '../../documents/addDocPopup/addDocPopup';
import DocItem from '../../documents/docItem';

import {
    historyAuctions,
    historyAwards,
    historyContrasts,
    historyBids,
} from '../../../redux/history.js';
import {setUniversalError, changeLoader} from '../../../redux/actions.js';
import {contractsAuctionIdGet} from '../../../redux/actions/contracts/contracts.js';

import {modifiDateString} from '../../../redux/modifiDate'
import {viewStringValue} from '../../../api/valueToString.js'

const AwardREM = (props) => {
    const [slim, setSlim] = useState({state: false, text: 'Розгорнути'})
    const [popAddDoc, setPopAddDoc] = useState(false)

    function Slimmer() {
        slim.state ? setSlim({state: false, text: 'Розгорнути'}) : setSlim({state: true, text: 'Згорнути'})
    }

    if (props?.auctionId?.awards?.length > 0) {
        return (
            <div className={styles.contractBlock}>
                <div className={styles.contractBlock_title}>Кваліфікація учасників</div>
                <div className={styles.slimmer} onClick={() => Slimmer()}>{slim.text}</div>
                {slim.state ?
                    <div className={styles.contractBlockContent}>
                        {viewAllAwards(props, popAddDoc, setPopAddDoc)}
                    </div> : null
                }
            </div>
        )
    }
    return null
}


function viewAllAwards(props, popAddDoc, setPopAddDoc) {
    if (props?.auctionId?.awards?.length > 0) {
        return props.auctionId.awards.map(
            (award, count) => {
                return (<div className={styles.contractBlock} key={count}>
                        <TextHalf title='Назва' data={award?.title}/>
                        <TextHalf title='Назва учасника' data={award?.buyers[0].name?.uk_UA}/>
                        {viewBidValue(props, award)}
                        {initialValueAmount(props, award)}
                        {award.terminationReason ?
                            <TextHalf title='Причина відмови'
                                      data={viewTypeTerminationReason(props, award?.terminationReason)}
                            />
                            :
                            null
                        }
                        {viewStatus(props, award)}
                        <TextHalf title='Юридична адреса' data={parseAddressText(award)}/>
                        {dataViewVerificationPeriod(award)}
                        {dataViewSigningPeriod(award)}
                        {award.terminationReason === null ? <div className={styles.btnBlock}>
                            {viewAwardActionButtonsBlock(props, award, count, setPopAddDoc, popAddDoc, setAwardDocTypes)}
                        </div> : null}
                        {viewDocumentsBlock(props, award, count)}
                    </div>

                )
            }
        )
    }
    return null
}

function isBidOwner(props, award) {
    if (props.auctionId?.privateData?.bids?.length > 0) {
        for (let key in props.auctionId.privateData.bids) {
            if (award.bidId === props.auctionId.privateData.bids[key].bidId) return true
        }
    }
    return false
}

function getBidByAward(props, award) {
    if (props.auctionId?.privateData?.bids?.length > 0) {
        for (let key in props.auctionId.privateData.bids) {
            if (award.bidId === props.auctionId.privateData.bids[key].bidId) {
                return props.auctionId.privateData.bids[key]
            }
        }
    }
    return null
}

function viewAwardActionButtonsBlock(props, award, count, setPopAddDoc, popAddDoc) { // Кнопка "Документи кваліфікації"
    let tmp = null
    const docScopeData = {}
    docScopeData.aucId = props.auctionId.id
    docScopeData.aucStatus = props.auctionId.status
    docScopeData.aucType = props.auctionId.sellingMethod
    docScopeData.awardId = award.id
    docScopeData.awardStatus = award.status
    docScopeData.aucOwner = props?.auctionId?.privateData?.isOwner
    docScopeData.bidOwner = isBidOwner(props, award)
    docScopeData.bidId = award.bidId
    docScopeData.docTypesList = setAwardDocTypes(props, award)
    docScopeData.reload = () => {
        getUpdateDataAuction(props, props.auctionId.id)
        if (!props?.auctionId?.privateData?.isOwner) getUpdateDataBid(props, props.auctionsAllBids.arr.id)
    }
    docScopeData.docList = award.documents

    if (props?.auctionId?.privateData?.isOwner) {
        docScopeData.typeOf = 'award'
        docScopeData.uploadNewDocURL = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction/${props.auctionId.id}/award/${award.id}/documents`
    } else if (props.auctionId?.privateData?.hasBids) {
        let bid = getBidByAward(props, award)
        docScopeData.typeOf = 'bidAward'
        docScopeData.uploadNewDocURL = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/bid/${bid?.id}/documents`
    }
    if (
        props.auctionId.status === 'cancelled' ||
        props.auctionId.status === 'unsuccessful' ||
        props.auctionId.status === 'complete' ||
        award.status === 'cancelled' ||
        award.status === 'unsuccessful'
    ) {
        return null
    }

    if (props.auctionId?.privateData?.isOwner === true) {
        tmp = (
            <>
                {
                    popAddDoc ?
                        <AddDocPopup
                            scopeData={docScopeData}
                            actionClose={() => {
                                setPopAddDoc(false)
                            }}
                            actionNo={() => {
                                setPopAddDoc(false)
                            }}
                        />
                        : null
                }
                <div
                    className={styles.btnAccept}
                    onClick={
                        () => {
                            setPopAddDoc(true)
                        }
                    }
                >
                    Документи кваліфікації
                </div>
                {viewUserActionButtonsBlock(props, award, count, 4)}
            </>
        )
    } else if (props.auctionId?.privateData?.hasBids) {
        if (award.status === 'pending_waiting') {
            tmp = (
                <>
                    {
                        popAddDoc ?
                            <AddDocPopup
                                scopeData={docScopeData}
                                actionClose={() => {
                                    setPopAddDoc(false)
                                }}
                                actionNo={() => {
                                    setPopAddDoc(false)
                                }}
                            />
                            : null
                    }
                    {viewUserActionButtonsBlock(props, award, count)}
                </>
            )
        } else {
            if (award.status !== 'active') {
                if (isBidOwner(props, award)) {
                    tmp = (
                        <>
                            {
                                popAddDoc ?
                                    <AddDocPopup
                                        scopeData={docScopeData}
                                        actionClose={() => {
                                            setPopAddDoc(false)
                                        }}
                                        actionNo={() => {
                                            setPopAddDoc(false)
                                        }}
                                    />
                                    : null
                            }
                            <div
                                className={styles.btnAccept}
                                onClick={
                                    () => {
                                        setPopAddDoc(true)
                                    }
                                }
                            >
                                Документи кваліфікації
                            </div>
                            {viewUserActionButtonsBlock(props, award, count)}
                        </>
                    )
                }
            }
        }
    }
    return tmp
}


function setAwardDocTypes(props, i) {
    return [
        {value: 'auctionProtocol', name: 'Протокол аукціону', main: null},
        {value: 'rejectionProtocol', name: 'Акт про невідповідність', main: null},
        {value: 'act', name: 'Акт про відмову', main: null},
    ]
}

function viewBidValue(props, award) {
    if (props?.auctionId?.bids?.length > 0) {
        for (let tmp = 0; tmp < props.auctionId.bids.length; tmp++) {
            if (props.auctionId.bids[tmp].value !== null) {
                if (props.auctionId.bids[tmp].id === award.bidId) {
                    let tmpValue = viewStringValue(props.auctionId.bids[tmp].value)
                    return (
                        <TextHalf
                            title='Розмір цінової пропозиції'
                            data={tmpValue}
                        />
                    )
                }
            }
        }
    }
    return null
}

function initialValueAmount(props, award) {
    if (props.auctionId?.bids?.length > 0) {
        for (let tmp = 0; tmp < props.auctionId.bids.length; tmp++) {
            if (props.auctionId.bids[tmp].value !== null) {
                if (props.auctionId.bids[tmp].id === award.bidId) {
                    let tmpCost = {
                        amount: props.auctionId.bids[tmp].initialValueAmount,
                        currency: "eurocent"
                    }
                    let tmpValue = viewStringValue(tmpCost)
                    return props.auctionId.bids[tmp].initialValueAmount ? <TextHalf
                        title='Розмір закритої цінової пропозиції'
                        data={tmpValue}
                    /> : null
                }
            }
        }

    }
    return null
}

function viewTypeTerminationReason(props, data) {

    switch (data) {
        case '1':
            return "Непідписання протоколу"
        case '2':
            return "Непідписання договору"
        case '3':
            return "Поширюються обмежувальні заходи (санкції)"
        case '4':
            return "Непроведення реєстрації представництва"
        case '5':
            return "Невнесення сплати в установлений строк"
        case '6':
            return "Неотримання висновку з оцінки впливу на довкілля"
        case '7':
            return "Отримання висновку з оцінки впливу на довкілля з обґрунтуванням недопустимості провадження планованої діяльності"
        default:
            return data
    }
}

function viewStatus(props, data) {
    if (data.status !== null && data.status !== '' && data.status !== 'null') {
        return (
            <TextHalf
                title='Статус'
                data={viewStatusAwards(props, data.status)}
            />
        )
    }
    return null
}

function viewStatusAwards(props, status) {
    switch (status) {
        case 'pending':
            return 'Очікується протокол'
        case 'pending_waiting':
            return 'Очікується рішення'
        case 'active':
            if (props.auctionId.status === "complete") {
                return 'Переможець. Аукціон завершено. Договір підписано'
            } else {
                return 'Переможець. Очікується договір'
            }
        case 'unsuccessful':
            return 'Дискваліфіковано'
        case 'cancelled':
            return 'Учасник не став переможцем'
        case 'protocol_signed':
            return 'Протокол затверджено'
        default:
            return status
    }

}

function parseAddressText(award) {
    let tmp = null
    if (award.buyers !== null) {
        if (award.buyers.length > 0) {
            tmp = award.buyers[0].address.countryName.uk_UA + ', ' +
                award.buyers[0].address.postalCode + ', ' +
                award.buyers[0].address.locality.uk_UA + ', ' +
                award.buyers[0].address.region.uk_UA + ', ' +
                award.buyers[0].address.streetAddress.uk_UA
        }

    }
    return tmp
}

function dataViewVerificationPeriod(data) {
    if (data.hasOwnProperty('verificationPeriod')) {
        if (data?.verificationPeriod !== null) {
            let tmp = `${modifiDateString(data.verificationPeriod.startDate, 3)} - ${modifiDateString(data.verificationPeriod.endDate, 3)}`
            return (
                <TextHalf
                    title='Термін на завантаження та підтвердження протоколу'
                    data={tmp}
                />
            )
        }
    }
    return null
}

function dataViewSigningPeriod(data) {
    if (data.hasOwnProperty('signingPeriod')) {
        if (data.signingPeriod !== null) {
            let tmp = `${modifiDateString(data.signingPeriod.startDate, 3)} - ${modifiDateString(data.signingPeriod.endDate, 3)}`
            return (
                <TextHalf
                    title='Термін на завантаження та підтвердження договору'
                    data={tmp}
                />
            )
        }
    }
    return null
}

function viewDocumentsBlock(props, award, count, setPopAddDoc) {
    if (award?.documents?.length > 0) {
        let TmpBidDocs = viewAwardBidDocuments(props, award)
        let tmpArr = TmpBidDocs !== null ? TmpBidDocs.length : null
        if ((award.documents !== null && award.documents.length > 0) || (TmpBidDocs !== null && TmpBidDocs.length > 0)) {// || viewAwardBidDocumentsCount(props, award) !== null){
            let docOrg = null
            let docUch = null
            if (award.documents.length !== 0) docOrg =
                <div className={styles.blockHeader}>Документи організатора:</div>
            if (viewAwardBidDocuments(props, award) !== null && viewAwardBidDocuments(props, award).length !== 0) docUch =
                <div className={styles.blockHeader}>Документи учасника:</div>
            return <PopDown
                title={`Додані документи (${award.documents.length + tmpArr})`}
                content={
                    <>
                        {docOrg}
                        {viewAwardDocuments(award, count, props, setPopAddDoc)}
                        {docUch}
                        {viewAwardBidDocuments(props, award)}
                    </>
                }
            />
        }
    }

    return null
}

function viewAwardDocuments(data, countContract, props, setPopAddDoc) { // документы организатора

    function dataECPMapper(data, t) {
        let tmp = data
            .filter(
                (item) => {
                    return item.documentType === 'digitalSignature' && item.relatedDocument === t.id
                })
        if (tmp.length === 0) return null
        return tmp
    }

    if (data !== null) {
        if (data === undefined || data === 'undefined') {
        } else {
            let tmp = data.documents
                .map(
                    (t) => {

                        const getHistoryURL = (props, data) => {
                            if (t.documentOf === 'award') {
                                return `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/public/history/auction/${props.auctionId.id}/awards/${data.id}/documents`
                            }
                            if (t.documentOf === 'bid') {
                                return `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/public/history/auction/${props.auctionId.id}/bids/${data.bidId}/documents`
                            }
                        }

                        const isBidOwner = (props, data) => {
                            if (props.auctionId.privateData.hasBids) {
                                for (let key in props.auctionId.privateData.bids) {
                                    if (data.bidId === props.auctionId.privateData.bids[key].bidId) return true
                                }
                            }
                            return false
                        }

                        let tmp = null
                        const docScopeData = {}
                        docScopeData.aucId = props.auctionId.id
                        docScopeData.aucStatus = props.auctionId.status
                        docScopeData.aucType = props.auctionId.sellingMethod
                        docScopeData.reload = () => {
                            if (!props?.auctionId?.privateData?.isOwner) getUpdateDataBid(props, props.auctionsAllBids.arr.id)
                            getUpdateDataAuction(props, props.auctionId.id)
                        }
                        docScopeData.aucOwner = props?.auctionId?.privateData?.isOwner
                        docScopeData.historyUrl = getHistoryURL(props, data)


                        docScopeData.bidOwner = isBidOwner(props, data)
                        docScopeData.docTypesList = setAwardDocTypes(props, data)
                        docScopeData.awardStatus = data.status
                        docScopeData.docList = data.documents

                        if (props?.auctionId?.privateData?.isOwner) {
                            docScopeData.typeOf = 'award'
                            docScopeData.uploadNewDocURL = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction/${props.auctionId.id}/award/${data.id}/documents`
                            docScopeData.patchNewDocFilesURL = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction/${props.auctionId.id}/award/${data.id}/documents`
                            docScopeData.patchNewDocWoutFilesURL = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction/${props.auctionId.id}/award/${data.id}/documents`
                        }

                        return (
                            <DocItem
                                data={t}
                                scopeData={docScopeData}
                                dataECP={dataECPMapper(data.documents, t)}
                                key={t.id}
                            />
                        )
                    }
                )
            return tmp
        }
    }
}

function viewAwardBidDocuments(props, award) {
    function dataECPMapper(documents, t) {
        let tmp = documents
            .filter(
                (item) => {
                    console.log(item)
                    return item.documentType === 'digitalSignature' && item.relatedDocument === t.documentId
                })
        if (tmp.length === 0) return null
        return tmp
    }

    let tmpArr = null
    if (props.auctionId?.bids?.length > 0) {
        for (let key in props.auctionId.bids) {
            if (props.auctionId.bids[key].id === award.bidId && props.auctionId.bids[key]?.documents?.length > 0) {
                tmpArr = props.auctionId.bids[key].documents.filter(
                    (t) => {
                        return t.documentType === 'auctionProtocol'
                    }
                )
                break
            }
        }
    }

    if (tmpArr !== null) {
        let tmp = tmpArr.map(
            (t) => {
                const docScopeData = {}
                docScopeData.aucId = props.auctionId.id
                docScopeData.aucStatus = props.auctionId.status
                docScopeData.aucType = props.auctionId.sellingMethod
                docScopeData.reload = () => {
                    if (!props?.auctionId?.privateData?.isOwner) getUpdateDataBid(props, props.auctionsAllBids.arr.id)
                    getUpdateDataAuction(props, props.auctionId.id)
                }
                docScopeData.aucOwner = props?.auctionId?.privateData?.isOwner

                docScopeData.bidOwner = isBidOwner(props, award)
                docScopeData.docTypesList = setAwardDocTypes(props, award)
                docScopeData.awardStatus = award.status
                docScopeData.typeOf = 'bidAward'
                docScopeData.docList = award.documents

                function getDocId(id) {
                    for (let key in props.auctionsAllBids.arr.documents) {
                        if (props.auctionsAllBids.arr.documents[key].documentId === id) return props.auctionsAllBids.arr.documents[key].id
                    }
                }

                let bid = getBidByAward(props, award)
                docScopeData.historyUrl = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/public/history/auction/${props.auctionId.id}/bids/${award.bidId}/documents`
                if (props.auctionId?.privateData?.bids?.length > 0) {
                    docScopeData.patchNewDocFilesURL = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/bid/${bid?.id}/documents/${getDocId(t.id)}`
                    docScopeData.patchNewDocWoutFilesURL = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/bid/${bid?.id}/documents/${getDocId(t.id)}`
                }
                return (
                    <>
                        <DocItem
                            data={t}
                            scopeData={docScopeData}
                            key={t.id}
                            dataECP={dataECPMapper(tmpArr, t)}
                        />
                    </>
                )
            }
        )
        return tmp
    }
    return null
}

//////////////////////////////////////////
function viewUserActionButtonsBlock(props, award, count) {
    if (
        props.auctionId.status !== 'cancelled' &&
        props.auctionId.status !== 'unsuccessful' &&
        props.auctionId.status !== 'complete'
    ) {
        if (props.auctionId?.privateData?.hasBids) {
            return viewBidderActionButtonsBlock(props, award, count)
        }
        if (props.auctionId?.privateData?.isOwner) {
            return viewOwnerActionButtonsBlock(props, award, count)
        }
    }
    return null
}

function viewOwnerActionButtonsBlock(props, award, count) {
    let rejectionProtocolWasAdded = false,
        actWasAdded = false,
        protocolWasAdded = false

    for (let tt = 0; tt < award.documents.length; tt++) {
        if (award.documents[tt].documentType === 'rejectionProtocol') {
            rejectionProtocolWasAdded = true
        }
        if (award.documents[tt].documentType === 'act') {
            actWasAdded = true
        }
        if (award.documents[tt].documentType === 'auctionProtocol') {
            protocolWasAdded = true
        }
    }
    switch (award.status) {
        case 'active':
            if (!rejectionProtocolWasAdded && !actWasAdded) {
                return (
                    <div className={styles.btnDecline}>
                        Дії з учасником
                    </div>
                )
            }
        //NO BREAK HERE!
        // eslint-disable-next-line no-fallthrough
        case 'pending':
        case 'pending_waiting':
            if (!protocolWasAdded) {
                if (!rejectionProtocolWasAdded && !actWasAdded) {
                    return (
                        <div className={styles.btnDecline}>
                            Дії з учасником
                        </div>
                    )
                }
            }
            return (
                <div
                    className={styles.btnAccept}
                    onClick={
                        () => {
                            props.changeAwardRejectPopUpStatus({
                                aucId: props.auctionId.id,
                                awardId: award.id,
                                status: award.status,
                                awards: count,
                                sellingMethod: props.auctionId.sellingMethod
                            })
                        }
                    }
                >
                    Дії з учасником
                </div>
            )
        default:
            return (
                <div className={styles.btnDecline}>
                    Дії з учасником
                </div>
            )

    }
}

function viewBidderActionButtonsBlock(props, award, count) {
    if (isBidOwner(award)) {
        return (
            <div
                className={styles.btnAccept}
                onClick={
                    () => {
                        props.changeAwardRejectPopUpStatus({
                            aucId: props.auctionId.id,
                            awardId: award.id,
                            status: award.status,
                            awards: count,
                            sellingMethod: props.auctionId.sellingMethod
                        })
                    }
                }
            >
                Відмовитись від очікування
            </div>
        )
    }
    return null
}

const mapStateToProps = state => {
    return {
        token: state.start.token,
        langFlag: state.start.langFlag,
        auctionAwards: state.start.auctionAwards,
        allUserPopUp: state.start.allUserPopUp,
        allUserPopUpUser: state.start.allUserPopUpUser,
        contractAuctionIdGet: state.start.contractAuctionIdGet,
        timberContractPut: state.start.timberContractPut,
        pageNumber: state.start.pageNumber,
        auctionsGetId: state.start.auctionsGetId,
        auctionsAllBids: state.start.auctionsAllBids,
        auctionId: state.start.auctionFull,
        bidEdit: state.start.bidEdit,
        userProfiles: state.start.userProfiles
    }
}

const mapDispatchToProps = {
    changeAwardRejectPopUpStatus,
    setAwardAllUserPopUp,
    setAwardAllUserPopUpUser,

    changeContractApprowePopUpStatus,

    setContractTitle,
    setContractDescription,
    setContractContractNumber,
    setContractDateFrom,
    setContractDateTill,
    setContractStartDate,
    setContractEndDate,
    setContractCurrency,
    setContractAmount,
    setContractDateSigned,

    historyAuctions,
    historyAwards,
    historyContrasts,
    historyBids,

    setUniversalError,
    changeLoader,
    contractsAuctionIdGet,
    setContractDatePaid,

    setContractDocId,
    setContractDocTitle,
    setContractDocType,
    setContractDocDescription,
    setContractDocDocumentOf,
    setDataAddress,
    saveArrBids
}

export default connect(mapStateToProps, mapDispatchToProps)(AwardREM)
