import React from 'react';

import {connect} from 'react-redux';

import {
    isRLE, isRLD, isCLE, isCLD, isBRD, isBRE, isBRW, isALE, isBSD, isBSE, isCSD, isCSE,
    isLLD, isLLE, isLLP, isLRE, isLSE, isLSP, isRCD, isRCE, isSUD, isSUE, isTIE, isSPE, isSPD,
    isNLE, isNLD, isLAE, isLAW, isLAP, isLPE, isGFE, isGFD, isAPE, isAPD
} from '../../../redux/procedure';

import RenewablesItem from "./renewablesMultiAwards/RenewablesItem";

//@todo refactor components below
import Timber from '../../createAuction/windowItems/timber/timber.js';
import Energy from '../../createAuction/windowItems/energy/energy.js';
import Railway from '../../createAuction/windowItems/railway/railway.js';
import Subsoil from '../../createAuction/windowItems/subsoil/subsoil.js';
import Dgf from '../../createAuction/windowItems/dgf/dgf.js';
import BasicSell from '../../createAuction/windowItems/bsebsd/bsebsd'
import Alienation from '../../createAuction/windowItems/ale/ale'
import CommSell from '../../createAuction/windowItems/commSell/commSell';
import BRESell from '../../createAuction/windowItems/BRE/BRE.js';
import BRWSell from '../../createAuction/windowItems/BRW/BRW.js';
import NLE from '../../createAuction/windowItems/nlenld/nlenld'
import LAE from '../../createAuction/windowItems/lae/lae'
import LAW from '../../createAuction/windowItems/law/law'
import LAP from '../../createAuction/windowItems/lap/lap'
import LPE from '../../createAuction/windowItems/lpe/bsebsd'
import CLE from '../../createAuction/windowItems/CLE/bsebsd'
import RLE from '../../createAuction/windowItems/RLE/bsebsd'
import APE from '../../createAuction/windowItems/ape/bsebsd'
import LLE from '../../createAuction/windowItems/lle/bsebsd'
import LRE from '../../createAuction/windowItems/lre/bsebsd'

const WindowItems = (props) => {
    switch (true) {
        case props.auctionsMy === null:
            return null
        case isTIE(props.auctionsMy.sellingMethod):
            return <Timber/> //@deprecated
        case isSUD(props.auctionsMy.sellingMethod):
        case isSUE(props.auctionsMy.sellingMethod):
            return <Subsoil/> //@todo refactor
        case isRCD(props.auctionsMy.sellingMethod):
        case isRCE(props.auctionsMy.sellingMethod):
            return <Railway/> //@todo refactor
        case isGFE(props.auctionsMy.sellingMethod):
        case isGFD(props.auctionsMy.sellingMethod):
            return <Dgf/> //@todo refactor

        case isLLE(props.auctionsMy.sellingMethod):
        case isLLD(props.auctionsMy.sellingMethod):
        case isLLP(props.auctionsMy.sellingMethod):
            return <LLE/> //@todo refactor

        case isLRE(props.auctionsMy.sellingMethod):
        case isLSE(props.auctionsMy.sellingMethod):
        case isLSP(props.auctionsMy.sellingMethod):
            return <LRE/> //@todo refactor

        case isBSD(props.auctionsMy.sellingMethod):
        case isBSE(props.auctionsMy.sellingMethod):
            return <BasicSell/> //@todo refactor
        case isALE(props.auctionsMy.sellingMethod):
            return <Alienation/> //@todo refactor
        case isCSD(props.auctionsMy.sellingMethod):
        case isCSE(props.auctionsMy.sellingMethod):
            return <CommSell/> //@todo refactor
        // if(isAAE(props.auctionsMy.sellingMethod))
        case isBRD(props.auctionsMy.sellingMethod):
        case isBRE(props.auctionsMy.sellingMethod):
            return <BRESell/> //@todo refactor
        case isBRW(props.auctionsMy.sellingMethod):
            return <BRWSell/> //@todo refactor
        case isRLE(props.auctionsMy.sellingMethod):
        case isRLD(props.auctionsMy.sellingMethod):
            return <RLE/> //@todo refactor
        case isCLE(props.auctionsMy.sellingMethod):
        case isCLD(props.auctionsMy.sellingMethod):
            return <CLE/> //@todo refactor
        case isSPE(props.auctionsMy.sellingMethod):
        case isSPD(props.auctionsMy.sellingMethod):
            return <LPE/> //@todo refactor
        case isNLE(props.auctionsMy.sellingMethod):
        case isNLD(props.auctionsMy.sellingMethod):
            return <NLE/> //@todo refactor
        case isLAE(props.auctionsMy.sellingMethod):
            return <LAE/> //@todo refactor
        case isLAW(props.auctionsMy.sellingMethod):
            return <LAW/> //@todo refactor
        case isLAP(props.auctionsMy.sellingMethod):
            return <LAP/> //@todo refactor
        case isLPE(props.auctionsMy.sellingMethod):
            return <LPE/> //@todo refactor

        case isAPE(props.auctionsMy.sellingMethod):
        case isAPD(props.auctionsMy.sellingMethod):
            return <APE/> //@todo refactor

        case props.auctionsMy.sellingMethod.startsWith('renewables'):
        case props.auctionsMy.sellingMethod.startsWith('renewables-multiAwards'):
            return <RenewablesItem/>
        default :
            return null
    }
}

const mapStateToProps = state => {
    return {
        auctionsMy: state.start.auctionsMy,
    }
}

export default connect(mapStateToProps, null)(WindowItems)
