import React from 'react';

import {connect} from 'react-redux';
import {changeBidAward} from '../../../redux/actions.js';
import {
    auctionsBidsGetId,
    changeCreateBidDocType,
    changeBidErrorPopUpStatus
} from '../../../redux/actions/auctionBid/auctionBid.js';

import {
    changeDocumentBidBlockStatus,
    changeDocumentBidPublicName
} from '../../../redux/actions/documents/documents.js';


import procedureHelper from '../../../redux/procedure'
import ViewBidList from "./viewList/viewBidList";
import CreateBid from "./create/createBid";

const BidComponent = (props) => {
    let userBids = props?.auctionId?.privateData?.bids ?? []
    if (userBids?.length > 0) {
        return (
            <div>
                <ViewBidList/>
            </div>
        )
    }
    let canCreateBid = false
    if (
        (userBids.length === 0 || procedureHelper.isREM(props.auctionId.sellingMethod)) &&
        props?.userProfiles?.canCreateBid &&
        (props.auctionId.status === "active_tendering" || checkBidPermissions(props))
    ) {
        canCreateBid = true
    }
    if (canCreateBid) {
        return (
            <div>
                <CreateBid/>
            </div>
        )
    }

    return (<div></div>)
}

//@todo refactor date checks
function checkBidPermissions(props) {
    if (
        //@todo remove?
        //@todo check dutchPart of auction module docs on prozorro
        //@deprecated - old cope part
        (props.auctionId.status === "active_auction") && procedureHelper.isDutch(props.auctionId.sellingMethod)
    ) {
        let startDate = new Date(props.auctionId.auctionPeriod.startDate)
        let tmpDate = startDate.getTime()
        let timePoyas = ((1000 * 60) * 60) * 2
        let timeDutch = (((1000 * 60) * 60) * 6) + ((1000 * 60) * 45)
        let tmpSumm = tmpDate + timePoyas + timeDutch
        if (tmpSumm > Date.now()) {
            return true
        }
    }

    if (props?.auctionId?.tenderPeriod) {
        const tenderPeriodStartDate = new Date(props.auctionId.tenderPeriod.startDate)
        const tenderPeriodEndDate = new Date(props.auctionId.tenderPeriod.endDate)
        const now = new Date()
        if (tenderPeriodStartDate < now && now < tenderPeriodEndDate) {
            return true
        }
    }
    return false
}

const mapStateToProps = state => {
    return {
        token: state.start.token,
        auctionId: state.start.auctionFull,
        langFlag: state.start.langFlag,
        pageNumber: state.start.pageNumber,
        auctionsGetIdNotFound: state.start.auctionsGetIdNotFound,
        bidAward: state.start.bidAward,
        documentCount: state.start.documentCount,
        userProfiles: state.start.userProfiles,
        curentBidLot: state.start.curentBidLot,
        bidCvalification: state.start.bidCvalification,
        auctionsAllBids: state.start.auctionsAllBids,
    }
}

const mapDispatchToProps = {
    changeBidAward,
    changeDocumentBidBlockStatus,
    changeDocumentBidPublicName,
    auctionsBidsGetId,
    changeCreateBidDocType,
    changeBidErrorPopUpStatus,
}

export default connect(mapStateToProps, mapDispatchToProps)(BidComponent)