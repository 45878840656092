import React from 'react';
import styles from './auctionResultTab.module.css';

import Title from '../../../../product/title/title.js'
import Tabs from '../../../../product/tabs/tabs'

import {connect} from 'react-redux';

import AllContract from '../../../../product/contractNew/newContract'

import ProtocolLink from '../../../../product/protocolLink/protocolLink';
import Cancellations from '../../../../product/cancellationsNew/newAward'
import SidebarRightViewProcedure from '../../../../sidebarRightViewProcedure'

import { isLAW } from '../../../../../redux/procedure';
import ViewAwardList from "../../../award/viewAwardList";
import Contract from "../../../../product/awardNew/newAward";

const auctionResultTab = (props) => {
    if (props.auctionId === null) return null
    return (
        <div className={styles.wrapNew}>
            <div className={styles.mainBlock}>
                <Title />
                <div className={styles.aucProperties}>
                    <Tabs />
                    <ViewAwardList type={true} />
                    <ViewAwardList type={false} />
                    <Contract />
                    {isLAW(props.auctionId.sellingMethod) !== true ? <AllContract /> : null}
                    <Cancellations />
                    {isLAW(props.auctionId.sellingMethod) ? null : <ProtocolLink /> }
                </div>
            </div>
            <SidebarRightViewProcedure />
        </div>
    )
}

const mapStateToProps = state => {
    return {
        auctionId: state.start.auctionFull,
    }
}

export default connect(mapStateToProps, null)(auctionResultTab)