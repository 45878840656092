import React from 'react';

import {connect} from 'react-redux';

import {setPopUpDelAuction} from '../../redux/actions/auctions/auctions.js';

import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';

const buttonsCancelAndDeleteAuction = ( props ) => {
    let tmp = null;
    switch (props.i.status ) {
        case props.i.status === 'cancelled':
        case props.i.status === 'unsuccessful':
            tmp = <div title="Видалення аукціону">
                <IconButton
                    disabled
                    aria-label="delete"
                >
                    <DeleteIcon />
                </IconButton>
            </div>
            break;
        default:
            tmp = <div
                onClick={
                    ()=>{
                        props.setPopUpDelAuction(props.i)
                    }
                }
                title="Видалення аукціону"
            >
                <IconButton aria-label="delete">
                    <DeleteIcon />
                </IconButton>
            </div>
    }

    return tmp
}

const mapStateToProps = state => {
    return {
        token: state.start.token,
    }
}

const mapDispatchToProps = {
    setPopUpDelAuction,
}

export default connect(mapStateToProps, mapDispatchToProps)(buttonsCancelAndDeleteAuction)
