import React, {useState, useEffect} from 'react';
import styles from '../procedure.module.css'

import {useSelector} from 'react-redux';
import {TranslationsUk} from '../../../../../../redux/translations.uk.js';

import { isMongo, checkProp } from '../../../../../../redux/check';

import TextArea from '../../../../../elements/inputs/textArea'
import Input from '../../../../../elements/inputs/input';
import Select from '../../../../../elements/inputs/inputSelect'
import Button from '../../../../../elements/buttons/button';
import InputAmount from '../../../../../elements/inputs/inputAmount';
import InputDate from '../../../../../elements/inputs/inputDate';
import Checkbox from '../../../../../elements/inputs/checkBox';

import LeaseDuration from '../../../../elems/leaseDuration/leaseDuration';
import LeaseRules from '../leaseRules/leaseRules';

import BankAccount from '../../bankAccount/bankAccount';

import { disabledElement } from '../../../../disabled';

//import { setTenderAttemptList, createBodyCSE } from './utils'; 
import { createBody } from '../lib/createBody';
import { setTenderAttemptList } from '../lib/utils';

import {editAuc, createAuc} from '../../../createEditAndCloneAuction/edit/editTabMonga'

const AuctionRLE = ( {data, ...props} ) => {
    const auction = useSelector(state => state.start.auctionsMy)

    const [lotId, setLotId] = useState(checkProp(data, 'lotId') ? data.lotId : null)
    const [title, setTitle] = useState(checkProp(data, 'title') ? isMongo(data.title) : null)
    const [description, setDescription] = useState(checkProp(data, 'description') ? isMongo(data.description) : null)
    const [tenderAttempts, setTenderAttempts] = useState(checkProp(data, 'tenderAttempts') ? data.tenderAttempts : null)
    const [accessDetails, setAccessDetails] = useState(checkProp(data, 'accessDetails') ? isMongo(data.accessDetails) : null)
    const [x_documentRequirements, setX_documentRequirements] = useState(checkProp(data, 'x_documentRequirements') ? isMongo(data.x_documentRequirements) : null)
    const [x_additionalInformation, setX_additionalInformation] = useState(checkProp(data, 'x_additionalInformation') ? isMongo(data.x_additionalInformation) : null)
    const [minNumberOfQualifiedBids, setMinNumberOfQualifiedBids] = useState(
        checkProp(data, 'minNumberOfQualifiedBids') && data.minNumberOfQualifiedBids !== null&& data.minNumberOfQualifiedBids !== "null" ? data.minNumberOfQualifiedBids : 1)
    const [previousAuctionId, setPreviousAuctionId] = useState(checkProp(data, 'previousAuctionId') ? data.previousAuctionId : null)
    const [discountPercent, setDiscountPercent] = useState(checkProp(data,'discount') && checkProp(data.discount, 'discountPercent') ? data.discount.discountPercent : null)
    const [previousAuctionValue, setPreviousAuctionValue] = useState(checkProp(data,'discount') && checkProp(data.discount, 'previousAuctionValue') ? data.discount.previousAuctionValue : null)
    const [value, setValue] = useState(checkProp(data, 'value') ? data.value : null)
    const [guarantee, setGuarantee] = useState(checkProp(data, 'guarantee') ? data.guarantee : null)
    const [minimalStep, setMinimalStep] = useState(checkProp(data, 'minimalStep') ? data.minimalStep : null)
    const [registrationFee, setRegistrationFee] = useState(checkProp(data, 'registrationFee') ? data.registrationFee : null)
    const [valueAddedTaxCharged, setValueAddedTaxCharged] = useState(checkProp(data, 'valueAddedTaxCharged') ? data.valueAddedTaxCharged && data.valueAddedTaxCharged !== null && data.valueAddedTaxCharged !== 'null': true)
    const [startDate, setStartDate] = useState(checkProp(data, 'auctionPeriod') && checkProp(data.auctionPeriod, 'startDate') ? data.auctionPeriod.startDate : null)
    const [bankAccounts, setBankAccounts] = useState(checkProp(data,'bankAccounts') ? data.bankAccounts : [])
    const [discount, setDiscount] = useState(checkProp(data,'discount') && checkProp(data.discount,'discount') ? data.discount.discount : false)

    const [leaseDuration, setLeaseDuration] = useState(checkProp(data, 'leaseDuration') ? data.leaseDuration : null)
    const [valuePeriod, setValuePeriod] = useState(checkProp(data, 'valuePeriod') && data.valuePeriod !== null && data.valuePeriod !== 'null' ? data.valuePeriod : 'month')
    const [schedule, setSchedule] = useState(checkProp(data, 'schedule') ? data.schedule : null)

    useEffect(()=>{
        if(checkProp(data, 'schedule')) setSchedule(data.schedule)
    }, [])

    let requestBody = {
        title: {
            uk_UA: title
        },
        description: {
            uk_UA: description
        },
        accessDetails: {
            uk_UA: accessDetails
        },
        tenderAttempts: +tenderAttempts,
        lotId: lotId,
        auctionPeriod: {
            startDate: startDate
        },
        sellingMethod: data.sellingMethod,
        x_documentRequirements: {
            uk_UA: x_documentRequirements
        },
        x_additionalInformation: {
            uk_UA: x_additionalInformation
        },
        minNumberOfQualifiedBids: +minNumberOfQualifiedBids,
        valueAddedTaxCharged: valueAddedTaxCharged,
        value: {
            currency: checkProp(value,'currency') ? value.currency : null,
            amount: checkProp(value,'amount') ? +value.amount : null,
            valueAddedTaxIncluded: checkProp(value,'addTax') ? value.addTax : true
        },
        minimalStep: {
            currency: checkProp(minimalStep,'currency') ? minimalStep.currency : null,
            amount: checkProp(minimalStep,'amount') ? +minimalStep.amount : null,
        },
        guarantee: {
            currency: checkProp(guarantee,'currency') ? guarantee.currency : null,
            amount: checkProp(guarantee,'amount') ? +guarantee.amount : null
        },
        registrationFee: {
            currency: checkProp(registrationFee,'currency') ? registrationFee.currency : null,
            amount: checkProp(registrationFee,'amount') ? +registrationFee.amount : null
        },
        previousAuctionId: previousAuctionId,
        discount: {
            discount: discount,
            discountPercent: discountPercent,
            previousAuctionValue:{
                currency: checkProp(previousAuctionValue,'currency') ? previousAuctionValue.currency : null,
                amount: checkProp(previousAuctionValue,'amount') ? +previousAuctionValue.amount : null,
                valueAddedTaxIncluded: checkProp(previousAuctionValue,'addTax') ? previousAuctionValue.addTax : null
            }
        },
        bankAccounts: bankAccounts,

        leaseDuration: leaseDuration,
        valuePeriod: valuePeriod,
        schedule: schedule,
    }

    function discountBlock(){
        if (tenderAttempts > 1){
            return(
                <div className={styles.costBlock}>
                    <div className={styles.costBlockTitle}>Дані попереднього аукціону</div>
                    <div className={styles.twoInLine}>
                        <Input //"Ідентифікатор попереднього аукціону"
                            label={TranslationsUk['basicSell-english'].basicSellEnglishProcedure.previousAuctionId.legalName} 
                            value={previousAuctionId}
                            onChange={(e)=>{ setPreviousAuctionId(e) }}
                            disabled={disabledElement(auction, '01')}
                            required
                        />
                    </div>
                    { discount 
                    ? 
                        <>
                            <Checkbox 
                                value={discount}
                                onChange={setDiscount}
                            >
                            Застосувати знижку
                            </Checkbox>
                            <div 
                            // className={styles.twoInLine}
                                style={{width: '60%', height: '180px', display: 'flex', flexFlow: 'column', justifyContent: 'space-between'}}
                            >
                                <InputAmount //"Стартова вартість попереднього аукціону"
                                    title={TranslationsUk['basicSell-english'].basicSellEnglishProcedure.discount.previousAuctionValue.legalName}
                                    lable={TranslationsUk['basicSell-english'].basicSellEnglishProcedure.discount.previousAuctionValue.amount.legalName}
                                    data={{
                                        amount: checkProp(previousAuctionValue,'amount') ? previousAuctionValue.amount : null,
                                        currency: checkProp(previousAuctionValue,'currency') ? previousAuctionValue.currency : null,
                                        addTax: checkProp(previousAuctionValue,'valueAddedTaxIncluded') ? previousAuctionValue.valueAddedTaxIncluded : true
                                    }}
                                    onChange={(e) => {
                                        setPreviousAuctionValue(e)
                                        if(discount !== null
                                            && discount === true
                                            && discountPercent !== null
                                            && discountPercent !== ''
                                            && e.amount !== null
                                            && e.amount !== ''
                                        ){
                                            let tmpDisValue = {
                                                currency: value.currency,
                                                amount: Number(e.amount) - (Number(e.amount)/100)*Number(discountPercent),
                                                addTax: value.valueAddedTaxIncluded
                                            }
                                            setValue(tmpDisValue)
                                        }
                                    }}
                                    disabled={disabledElement(auction, '01')}
                                    required
                                    tax
                                />
                                <Input //"Розмір знижки від попереднього аукціону, %"
                                    label={TranslationsUk['basicSell-english'].basicSellEnglishProcedure.discount.discountPercent.legalName} 
                                    value={discountPercent}
                                    type='number'
                                    onChange={(e)=>{
                                        setDiscountPercent(e)
                                        if(discount !== null
                                            && discount === true
                                            && e !== null
                                            && e !== ''
                                            && previousAuctionValue.amount !== null
                                            && previousAuctionValue.amount !== ''
                                        ){
                                            let tmpDisValue = {
                                                currency: value.currency,
                                                amount: Number(previousAuctionValue.amount) - (Number(previousAuctionValue.amount)/100)*Number(e),
                                                addTax: value.valueAddedTaxIncluded
                                            }
                                            setValue(tmpDisValue)
                                        }
                                    }}
                                    disabled={disabledElement(auction, '01')}
                                    required
                                />
                            </div>
                        </>
                    : 
                        <Checkbox 
                            value={discount}
                            onChange={setDiscount}
                        >
                            Застосувати знижку
                        </Checkbox>}
                </div>
            )
        } else {
            requestBody.discount = null
        }
        return null
    }

    return (
        <>
            <Input //"Номер лота"
                label={TranslationsUk['basicSell-english'].basicSellEnglishProcedure.lotId.legalName} 
                value={lotId}
                onChange={(e)=>{ setLotId(e) }}
                disabled={disabledElement(auction, '01')}
                required
            />
            <Input //Назва аукціону"
                label={TranslationsUk['basicSell-english'].basicSellEnglishProcedure.title.legalName} 
                value={title}
                onChange={(e)=>{ setTitle(e) }}
                disabled={disabledElement(auction, '01')}
                required
            />
            <TextArea //"Опис аукціону"
                value={description}
                disabled={disabledElement(auction, '01')}
                label={TranslationsUk['basicSell-english'].basicSellEnglishProcedure.description.legalName} 
                required
                onChange={(e)=>{ setDescription(e) }}
            />
            <Select //'Лот виставляється',
                data={{
                    label: TranslationsUk['basicSell-english'].basicSellEnglishProcedure.tenderAttempts.legalName, 
                    target: tenderAttempts,
                    // info: 'Отака от штука-дрюка',
                    required: true,
                }}
                disabled={disabledElement(auction, '01')}
                onChange={(e) => { setTenderAttempts(e) }}
                options={setTenderAttemptList()}
            />
            <Input //"Порядок ознайомлення з майном, час і місце проведення огляду об’єкта"
                label={TranslationsUk['basicSell-english'].basicSellEnglishProcedure.accessDetails.legalName} 
                value={accessDetails}
                onChange={(e)=>{ setAccessDetails(e) }}
                disabled={disabledElement(auction, '01')}
            />
            <Input //"Перелік та вимоги до оформлення документів"
                label={TranslationsUk['basicSell-english'].basicSellEnglishProcedure.x_documentRequirements.legalName} 
                value={x_documentRequirements}
                onChange={(e)=>{ setX_documentRequirements(e) }}
                disabled={disabledElement(auction, '01')}
            />
            <Input
                label={TranslationsUk['basicSell-english'].basicSellEnglishProcedure.x_additionalInformation.legalName} //"Додаткові відомості"
                value={x_additionalInformation}
                onChange={(e)=>{ setX_additionalInformation(e) }}
                disabled={disabledElement(auction, '01')}
            />
            <Select //"Мінімальна кількість заяв" 
                data={{
                    label: TranslationsUk['basicSell-english'].basicSellEnglishProcedure.minNumberOfQualifiedBids.legalName, 
                    target: minNumberOfQualifiedBids,
                    // info: 'Отака от штука-дрюка',
                    required: true,
                }}
                disabled={disabledElement(auction, '01')}
                onChange={(e) => { setMinNumberOfQualifiedBids(e) }}
                options={
                    [
                        {value: 'null', name: 'Обрати', selected: true},
                        {value: 1, name: '1'},
                        {value: 2, name: '2'}
                    ]
                }
            />



            {discountBlock()}
            <div className={styles.costBlock}>
                    <div className={styles.costBlockTitle}>Вартісні характеристики аукціону</div>
                    <InputAmount //"Стартова ціна лота"
                        title={TranslationsUk['basicSell-english'].basicSellEnglishProcedure.value.legalName}
                        lable={TranslationsUk['basicSell-english'].basicSellEnglishProcedure.value.amount.legalName}
                        data={{
                            amount: checkProp(value,'amount') ? value.amount : null,
                            currency: checkProp(value,'currency') ? value.currency : null,
                            addTax: checkProp(value,'valueAddedTaxIncluded') ? value.valueAddedTaxIncluded : true
                        }}
                        onChange={(e) => {
                                    setValue(e)
                                    if(Number(e.amount) <= 20000){
                                        setRegistrationFee({registrationFee, amount: 17})
                                    }else if(Number(e.amount) <= 50000){
                                        setRegistrationFee({registrationFee, amount: 119})
                                    }else if(Number(e.amount) <= 200000){
                                        setRegistrationFee({registrationFee, amount: 340})
                                    }else if(Number(e.amount) <= 1000000){
                                        setRegistrationFee({registrationFee, amount: 510})
                                    }else if(Number(e.amount) > 1000000){
                                        setRegistrationFee({registrationFee, amount: 1700})
                                    }
                        }}
                        disabled={disabledElement(auction, '01')}
                        tax
                        required
                    />
                    <InputAmount //"Гарантійний внесок"
                        title={TranslationsUk['basicSell-english'].basicSellEnglishProcedure.guarantee.legalName}
                        lable={TranslationsUk['basicSell-english'].basicSellEnglishProcedure.guarantee.amount.legalName}
                        data={{
                            amount: checkProp(guarantee,'amount') ? guarantee.amount : null,
                            currency: checkProp(guarantee,'currency') ? guarantee.currency : null,
                        }}
                        onChange={(e) => { setGuarantee(e) }}
                        disabled={disabledElement(auction, '01')}
                        required
                    />
                    <InputAmount //"Розмір кроку аукціону"
                        title={TranslationsUk['basicSell-english'].basicSellEnglishProcedure.minimalStep.legalName}
                        lable={TranslationsUk['basicSell-english'].basicSellEnglishProcedure.minimalStep.amount.legalName}
                        data={{
                            amount: checkProp(minimalStep,'amount') ? minimalStep.amount : null,
                            currency: checkProp(minimalStep,'currency') ? minimalStep.currency : null,
                        }}
                        onChange={(e) => { setMinimalStep(e) }}
                        disabled={disabledElement(auction, '01')}
                        required
                    />
                    <InputAmount //"Реєстраційний внесок"
                        title={TranslationsUk['basicSell-english'].basicSellEnglishProcedure.registrationFee.legalName}
                        lable={TranslationsUk['basicSell-english'].basicSellEnglishProcedure.registrationFee.amount.legalName}
                        data={{
                            amount: checkProp(registrationFee,'amount') ? registrationFee.amount : null,
                            currency: checkProp(registrationFee,'currency') ? registrationFee.currency : null,
                        }}
                        onChange={(e) => { setRegistrationFee(e) }}
                        disabled={disabledElement(auction, '01')}
                        required
                    />
                    <div style={{height: '20px'}}></div>
                    <Checkbox 
                            value={valueAddedTaxCharged}
                            onChange={setValueAddedTaxCharged}
                            disabled={disabledElement(auction, '01')}
                        >
                            На фінальну суму нараховується ПДВ
                    </Checkbox>

                    {/* <Select 
                        data={{
                            label: 'На фінальну суму нараховується ПДВ',
                            target: valueAddedTaxCharged,
                            // info: 'Отака от штука-дрюка',
                            // required: true,
                        }}
                        onChange={(e) => { setValueAddedTaxCharged(e) }}
                        options={[
                            {name: 'Обрати', value: null},
                            {name: 'з ПДВ', value: true},
                            {name: 'без ПДВ', value: false},
                        ]}
                        disabled={disabledElement(auction, '01')}
                    /> */}
            </div>

            <Select //"Мінімальна кількість заяв" 
                data={{
                    label: 'Період розрахунку стартової вартості', 
                    target: valuePeriod,
                    // info: 'Отака от штука-дрюка',
                    required: true,
                }}
                disabled={disabledElement(auction, '01')}
                onChange={(e) => { setValuePeriod(e) }}
                options={
                    [
                        {value: 'null', name: 'Обрати'},
                        {value: 'month', name: 'Стартова вартість за місяць'},
                        {value: 'day', name: 'Стартова вартість за день'},
                        {value: 'hour', name: 'тартова вартість за годину'}
                    ]
                }
            />
            <LeaseDuration
                data={leaseDuration}
                setData={setLeaseDuration}
                required
            />
            <LeaseRules data={schedule} main={checkProp(data, 'schedule') ? data.schedule : null} updateData={setSchedule} />

            <div className={styles.startDate}>
                <div className={styles.costBlockTitle}>
                    {`${TranslationsUk['basicSell-english'].basicSellEnglishProcedure.auctionPeriod.startDate.legalName} аукціону`}
                </div>
                <InputDate
                    data={{
                        target: startDate,
                        time: true
                    }}
                    onChange={ e => setStartDate(e) }
                    disabled={disabledElement(auction, '11')}
                    required
                />
            </div>
            <BankAccount bankAccounts={bankAccounts} setBankAccounts={setBankAccounts} />

            <div className={styles.bottomButtonBlock}>
                <Button
                    height={'50px'}
                    font={'16px'}
                    color='blue'
                    data={'Зберегти зміни'}
                    onClick={
                        () =>{
                            let tmp = null
                            let bodyObj = {
                               base: requestBody,
                            }
                            tmp = createBody(bodyObj)
                            if(auction.id !== null && auction.id !== ''){
                                editAuc(props.updateAuc, tmp)
                            }else{
                                createAuc(props.updateAuc, tmp)
                            }
                        }
                    }
                />
            </div>
        </>
    )
}

export default AuctionRLE