import React from 'react';
import style from './popupContractAppruve.module.css';

import {connect} from 'react-redux';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { TimePicker, DateTimePicker, KeyboardDateTimePicker } from "@material-ui/pickers";

import {
    auctionsGetAwards,
    auctionsGetAwardsId,
    changeAwardRejectPopUpStatus,
    changeAwardErrorPopUpStatus,
    changeAwardRejectTitle,
    changeAwardRejectDescription,
    changeAwardRejectDocumentType,
} from '../../../../redux/actions/awards/awards.js';

import {
    changeContractApprowePopUpStatus,
    changeContractErrorPopUpStatus,

    setContractTitle,
    setContractDescription,
    setContractContractNumber,
    setContractDateFrom,
    setContractDateTill,
    setContractStartDate,
    setContractEndDate,
    setContractCurrency,
    setContractAmount,
    setContractDateSigned,

    setContractDocTitle,
    setContractDocType,
    setContractDocDescription,
    setContractDatePaid,
} from '../../../../redux/actions/contracts/contracts.js';

import {setUniversalError, changeLoader} from '../../../../redux/actions.js';

import {contractsAuctionIdGet} from '../../../../redux/actions/contracts/contracts.js';

import {
    togetherApiPopUpOnOff,
    togetherApiDocTitle,
    togetherApiDocDescription,
    togetherApiDocType,
    togetherApiDocLang,
    togetherApiDocFileName,
    togetherApiECP,
} from '../../../../redux/togetherApi.js';
import {ecpApiPass, ecpApiList, changeCA} from '../../../../redux/ecpApi.js';

import {
    changeDocumentId,
    changeDocumentTitle,
    changeDocumentDescription,
    changeDocumentLang,
    changeDocumentFileName,
    changeDocumentType
} from '../../../../redux/actions/documents/documents.js';
import { styles } from '@material-ui/pickers/views/Calendar/Calendar';

import InstructionBlock from '../../../elements/viewData/instructionBlock/instructionBlock';
import InputText from '../../../elements/inputs/input';
import TextArea from '../../../elements/inputs/textArea';
import InputDate from '../../../elements/inputs/inputDate';
import InputAmount from '../../../elements/inputs/inputAmount';




import {setDataAddress} from '../../../../redux/createAddress';
import {getUpdateDataAuction} from '../../../product/updateData'
import { isTIE, isSUE, isSUD, isRCE, isRCD, isGFE, isGFD, isLLE, isLLD, isLLP,
    isLRE, isLSE, isLSP, isBSE, isBSD, isALE, isCSE, isCSD, isAAE,
    isBRE, isBRD, isBRW, isSPE, isSPD, isNLE, isNLD, isLAE, isLAW, isLAP, isLPE, isAPE, isAPD } from '../../../../redux/procedure';

//Блокировка кнопки если нет документа contractSigned (Підписаний договір)
//Очиска полей при закрытии попапа


import ContractTIE from './contractTIE'
import ContractBRE_BSD from './contractBRE_BSD'
import ContractOther from './contractOther'
import ContractSPE from './contractSPE';
import ContractNLE from './contractNLE';
import ContractLAE from './contractLAE';
import ContractLPE from './contractLPE';
import ContractAPE from './contractAPE';

const AuctionsType = ( props ) => {
    if(isTIE(props.auctionFull.sellingMethod)) return <ContractTIE />
    if(isBRE(props.auctionFull.sellingMethod) || isBRD(props.auctionFull.sellingMethod) || isBRW(props.auctionFull.sellingMethod)) return <ContractBRE_BSD />
    if(isSPE(props.auctionFull.sellingMethod) || isSPD(props.auctionFull.sellingMethod)) return <ContractSPE />
    if(isNLE(props.auctionFull.sellingMethod) || isNLD(props.auctionFull.sellingMethod)) return <ContractNLE />
    if(isLAE(props.auctionFull.sellingMethod) || isLAW(props.auctionFull.sellingMethod) || isLAP(props.auctionFull.sellingMethod)) return <ContractLAE />
    if(isLPE(props.auctionFull.sellingMethod)) return <ContractLPE />
    if(isAPE(props.auctionFull.sellingMethod) || isAPD(props.auctionFull.sellingMethod)) return <ContractAPE />
    return <ContractOther />
}

const mapStateToProps = state => {
    return {
        token: state.start.token,
        //auctions: state.start.auctionsGet,
        auctionFull: state.start.auctionFull,
        langFlag: state.start.langFlag,
        createAuctionTab: state.start.createAuctionTab,
        createAuction: state.start.createAuction,
        createItems: state.start.createItems,
        auctionsMy: state.start.auctionsMy,
        auctionsItemsSwitchWindow: state.start.auctionsItemsSwitchWindow,
        classificationsGets: state.start.classificationsGet,
        unitsGet: state.start.unitsGet,
        userProfiles: state.start.userProfiles,
        classificationsTimber: state.start.classificationsTimber,
        classificationsEnergy: state.start.classificationsEnergy,
        auctionsList: state.start.auctionsGetMy,
        auctionAwards: state.start.auctionAwards,
        popUpAwardReject: state.start.popUpAwardReject,
        awardReject: state.start.awardReject,
        auction: state.start.auctionsGetId,
        contractAuctionIdGet: state.start.contractAuctionIdGet,
        popUpContractApprowe: state.start.popUpContractApprowe,
        popUpContractUniversal: state.start.popUpContractUniversal,
        timberContractPut: state.start.timberContractPut,

        togetherApiPage: state.start.togetherApiPage,
        ecpData: state.start.ecpData,

        createDocuments: state.start.createDocuments,
    }
}

export default connect(mapStateToProps, null)(AuctionsType)