import React from 'react';
import styles from './windowAuction.module.css';

import {connect} from 'react-redux';

import {TranslationsUk} from '../../../redux/translations.uk.js';
import { isBRD, isBRE, isALE, isBSD, isBSE, isCSD, isCSE, isLRE, isLSE, isLSP, isAAE, isNLE, isNLD } from '../../../redux/procedure';
import {
    getMyAuction,
    changeCreateAuctionTitle,
    changeCreateAuctionDescription,
    changeCreateAuctionType,
    changeCreateAuctionTenderAttempts,
    changeCreateAuctionAccessDetails,
    changeCreateAuctionXDocumentRequirements,
    changeCreateAuctionXAdditionalInformation,
    changeCreateAuctionValueCurrency,
    changeCreateAuctionValueAmount,
    changeCreateAuctionValueTax,
    changeCreateAuctionGuaranteeCurrency,
    changeCreateAuctionGuaranteeAmount,
    changeCreateAuctionGuaranteeTax,
    changeCreateAuctionMinimalStepCurrency,
    changeCreateAuctionMinimalStepAmount,
    changeCreateAuctionMinimalStepTax,
    changeCreateAuctionDateStart,
    changeCreateAuctionDateEnd,
    changeCreateAuctionId,
    changeCreateAuctionLotIdentifier,
    changeCreateAuctionTab,
    setCarryType,
    minNumberOfQualifiedBids,
    changeCreateAuctionMinNumberOfQualifiedBids,
    changeCreateAuctionDgfVirtualDataRoom,
    changeCreateAuctionDgfEligibilityCriteria,
    changeCreateAuctionDgfX_dgfPlatformLegalDetails,
    changeCreateAuctionDgfDecisionId,
    changeCreateAuctionDgfDecisionDate,
} from '../../../redux/actions.js';

import {
    changeLoader,
} from '../../../redux/actions.js';

import {
    changeAuctionsSwitchWindow,
    auctionsGetMy,
    setPopUpAuctionCreate,
    setPopUpAuctionMyNull,
} from '../../../redux/actions/auctions/auctions.js';

import {
    setDataAddress,
} from '../../../redux/createAddress';

import {
    changePopUpBankAccount,
    setPopBankAccountId,
    setPopBankAccountIdArr,
    setBankAccountSave,
    setBankAccountAdd,
    setBankAccountDelete,
    openBankWindows,
} from '../../../redux/actions/bankAccount/bankAccount.js';

import {
    changeCreateItemGeologivDataValue,
    changeCreateItemDocumentationValue,
    changeCreateItemDateUse
} from '../../../redux/actions/auctionItems/auctionItems.js';

import {
    changeDiscountPercent,
    changeDiscountPreviousAuctionCurrency,
    changeDiscountPreviousAuctionAmount,
    changeDiscountPreviousAuctionId,
    discountAuc,
} from '../../../redux/actions/discount/discount.js';

import {setUniversalError} from '../../../redux/actions.js';

/////////////////////////////////////
import SnoozeIcon from "@material-ui/icons/Snooze";
import AlarmIcon from "@material-ui/icons/AddAlarm";
import { IconButton, InputAdornment } from "@material-ui/core";
import { DateTimePicker, KeyboardDateTimePicker } from "@material-ui/pickers";

import 'date-fns';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';

import WindowAuctionSelfNumber from './windowAuctionSelfNumber.js';
import WindowAuctionTitle from './windowAuctionTitle.js';
import WindowAuctionDescription from './windowAuctionDescription.js';
import WindowAuctionTenderAttempt from './windowAuctionTenderAttempt.js';
import WindowAuctionAccessDetails from './windowAuctionAccessDetails.js';
import WindowAuctionDocumentRequirements from './windowAuctionDocumentRequirements.js';
import WindowAuctionAdditionalInformation from './windowAuctionAdditionalInformation.js';
import WindowAuctionType from './auctionsCommon/windowAuctionType/windowAuctionType.js';
import WindowAuctionCost from './windowAuctionCost.js';
import WindowAuctionGaranty from './windowAuctionGaranty.js';
import WindowAuctionMinimalStep from './windowAuctionMinimalStep.js';
import WindowAuctionDiscount from './windowAuctionDiscount.js';

import WindowAuctionDutchSteps from './windowAuctionDutchSteps.js';
import WindowAuctionDgf from './dgf.js';
import WindowAuctionZU from './zu.js';
import WindowAuctionZUBanlAccounts from './zuBankAccounts'

import ReturnButton from '../returnButton'
import ViewAddBankAccont from './viewAddBankAccont.js';

import CreateAuctionLoader from '../createAuctionLoader.js';

import SwitcherTypeWorkAuction from './createEditAndCloneAuction/windowAuction'
import NewDiz from './createEditAndCloneAuction/createProcedures'

import {disabledElement} from '../disabled'

const WindowAuction = ( props ) => {
    return (
        <>
            {/*<NewDiz />*/}
            <SwitcherTypeWorkAuction />
        </>
    )
}

function zuDutchStep(props){
    if(
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-fast" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-fast-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-fast-auction-manual-qualification" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-initial-auction" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-initial-auction-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-initial-qualification-prod"
    ){
        return <WindowAuctionDutchSteps />
    }
}

function viewDgf(props, type){
    switch(props.auctionsMy.sellingMethod){
    }
}

function viewMinNumberOfQualifiedBids(props, type){
    switch(true){
        case props.auctionsMy.sellingMethod.startsWith("dgf-english"):
        case props.auctionsMy.sellingMethod.startsWith("dgf-dutch"):
            return <WindowAuctionDgf typeElem={type} />
        case props.auctionsMy.sellingMethod.startsWith("legitimatePropertyLease-english"):
        case props.auctionsMy.sellingMethod.startsWith("legitimatePropertyLease-dutch"):
        case props.auctionsMy.sellingMethod.startsWith("legitimatePropertyLease-priorityEnglish"):
            return <>
                {zuDutchStep(props)}
                <WindowAuctionZU typeElem={type} />
                </>
        default:
            return null
    }
}

function viewRailWayCargoDutch(props){
    switch(true){
        case props.auctionsMy.sellingMethod.startsWith("railwayCargo-english"):
        case props.auctionsMy.sellingMethod.startsWith("railwayCargo-dutch"):
            return (
                <div className={styles.groupElem}>
                    <div>
                        <TextField
                            disabled={disabledElement(props.auctionsMy, '01')}
                            id="outlined-select-currency-native"
                            select
                            label="Мінімальне число учасників"
                            value={props.auctionsMy.minNumberOfQualifiedBids}
                            onChange={(event)=>{
                                props.minNumberOfQualifiedBids(event.target.value)
                            }}
                            SelectProps={{
                                native: true,
                            }}
                            helperText="Обрати мінімальне число учасників"
                            variant="outlined"
                        >
                            <option key={0} selected disabled value='null'>Обрати</option>
                            <option key={1} value='1'>1</option>
                            <option key={2} value='2'>2</option>
                        </TextField>
                    </div>
                </div>
            )
        default:
            return null
    }
}

function viewSubsoil(props){
    let tmp = null;
    switch(true){
        case props.auctionsMy.sellingMethod.startsWith("subsoil-english"):
        case props.auctionsMy.sellingMethod.startsWith("subsoil-dutch"):
            tmp = <>
                <div className={styles.groupElem + ' ' + styles.groupElemCategory}>
                    <div className={styles.sue}>
                        <TextField
                            disabled={disabledElement(props.auctionsMy, '01')}
                            label="Вартість геологічної інформації"
                            value={viewGeologivDataValue(props)}
                            onChange={(event) => {
                                props.changeCreateItemGeologivDataValue(event.target.value)
                            }}
                            name="numberformat"
                            id="formatted-numberformat-input"
                            helperText="Вартість геологічної інформації"
                            variant="outlined"
                        />
                    </div>
                </div>
                <div className={styles.groupElem + ' ' + styles.groupElemCategory}>
                    <div className={styles.sue}>
                        <TextField
                            disabled={disabledElement(props.auctionsMy, '01')}
                            label="Вартість пакета аукціонної документації, що сплачується переможцем"
                            value={viewDocumentationValue(props)}
                            onChange={(event) => {
                                props.changeCreateItemDocumentationValue(event.target.value)
                            }}
                            name="numberformat"
                            id="formatted-numberformat-input"
                            //helperText="Вартість пакета аукціонної документації, що сплачується переможцем"
                            variant="outlined"
                        />
                    </div>
                </div>
                <div className={styles.groupElem + ' ' + styles.groupElemCategory}>
                    Строк, на який надається дозвіл
                </div>
                <div className={styles.groupElem + ' ' + styles.groupElemCategory}>
                    <div>
                        <TextField
                            disabled={disabledElement(props.auctionsMy, '00')}
                            label="Років"
                            type="number"
                            value={handleViewDate(props, 1)}
                            onChange={(event) => {
                                handleDate(props, event.target.value, 1)
                            }}
                            name="numberformat"
                            id="formatted-numberformat-input"
                            helperText="Років"
                            variant="outlined"
                        />
                    </div>
                    <div>
                        <TextField
                            disabled={disabledElement(props.auctionsMy, '00')}
                            label="Місяців"
                            type="number"
                            value={handleViewDate(props, 2)}
                            onChange={(event) => {
                                if(Number(event.target.value) < 13){
                                    handleDate(props, event.target.value, 2)
                                }
                            }}
                            name="numberformat"
                            id="formatted-numberformat-input"
                            helperText="Місяців"
                            variant="outlined"
                        />
                    </div>
                </div>
            </>
            break;
        default:
            break;
    }
    return tmp
}

function viewLegitimatePropertyLease(props){
    let tmp = null;
    switch(true){
        case props.auctionsMy.sellingMethod.startsWith("legitimatePropertyLease-english"):
        case props.auctionsMy.sellingMethod.startsWith("legitimatePropertyLease-dutch"):
        case props.auctionsMy.sellingMethod.startsWith("legitimatePropertyLease-priorityEnglish"):
            tmp = <>
                <div className={styles.groupElem + ' ' + styles.groupElemCategory}>
                    {TranslationsUk['legitimatePropertyLease-english'].legitimatePropertyLeaseEnglishProcedure.leaseDuration.legalName}
                </div>
                <div className={styles.groupElem + ' ' + styles.groupElemCategory}>
                    <div>
                        <TextField
                            disabled={disabledElement(props.auctionsMy, '00')}
                            label="Років"
                            type="number"
                            value={handleViewDate(props, 1)}
                            onChange={(event) => {
                                handleDate(props, event.target.value, 1)
                            }}
                            name="numberformat"
                            id="formatted-numberformat-input"
                            helperText="Років"
                            variant="outlined"
                        />
                    </div>
                    <div>
                        <TextField
                            disabled={disabledElement(props.auctionsMy, '00')}
                            label="Місяців"
                            type="number"
                            value={handleViewDate(props, 2)}
                            onChange={(event) => {
                                if(Number(event.target.value) < 13){
                                    handleDate(props, event.target.value, 2)
                                }
                            }}
                            name="numberformat"
                            id="formatted-numberformat-input"
                            helperText="Місяців"
                            variant="outlined"
                        />
                    </div>
                    <div>
                        <TextField
                            disabled={disabledElement(props.auctionsMy, '00')}
                            label="Днів"
                            type="number"
                            value={handleViewDate(props, 3)}
                            onChange={(event) => {
                                if(Number(event.target.value) < 32){
                                    handleDate(props, event.target.value, 3)
                                }
                            }}
                            name="numberformat"
                            id="formatted-numberformat-input"
                            helperText="Днів"
                            variant="outlined"
                        />
                    </div>
                </div>
            </>
            break;
        default:
            break;
    }
    return tmp
}

function handleViewDate(props, type){
    let tmp = null;
    if(props.auctionsMy.leaseDuration !== null){
        let tmpArr = props.auctionsMy.leaseDuration.split('P')
        let tmpArr1 = tmpArr[1].split('Y')
        let tmpArr2 = tmpArr1[1].split('M')
        let tmpArr3 = tmpArr2[1].split('D')
        if(type === 1){
            if(tmpArr1[0] !== '0'){
                tmp = tmpArr1[0]
            }else{
                tmp = ''
            }
        }else if(type === 2){
            if(tmpArr2[0] !== '0'){
                tmp = tmpArr2[0]
            }else{
                tmp = ''
            }
        }else if(type === 3){
            if(tmpArr3[0] !== '0'){
                tmp = tmpArr3[0]
            }else{
                tmp = ''
            }
        }
    }else{
        if(type === 1){
            tmp = ``
        }else if(type === 2){
            tmp = ``
        }else if(type === 3){
            tmp = ``
        }
    }
    return tmp
}

function handleDate(props, date, type){
    let tmp = null;
    if(props.auctionsMy.leaseDuration !== null){
        let tmpArr = props.auctionsMy.leaseDuration.split('P')
        let tmpArr1 = tmpArr[1].split('Y')
        let tmpArr2 = tmpArr1[1].split('M')
        let tmpArr3 = tmpArr2[1].split('D')
        if(type === 1){
            if(date === ''){
                tmp = `P0Y${tmpArr2[0]}M${tmpArr3[0]}D`
            }else{
                tmp = `P${date}Y${tmpArr2[0]}M${tmpArr3[0]}D`
            }
        }else if(type === 2){
            tmp = `P${tmpArr1[0]}Y${date}M${tmpArr3[0]}D`
            if(date === ''){
                tmp = `P${tmpArr1[0]}Y0M${tmpArr3[0]}D`
            }else{
                tmp = `P${tmpArr1[0]}Y${date}M${tmpArr3[0]}D`
            }
        }
        else if(type === 3){
            tmp = `P${tmpArr1[0]}Y${tmpArr2[0]}M${date}D`
            if(date === ''){
                tmp = `P${tmpArr1[0]}Y${tmpArr2[0]}M0D`
            }else{
                tmp = `P${tmpArr1[0]}Y${tmpArr2[0]}M${date}D`
            }
        }
    }else{
        if(type === 1){
            tmp = `P${date}Y0M0D`
        }else if(type === 2){
            tmp = `P0Y${date}M0D`
        }else if(type === 3){
            tmp = `P0Y0M${date}D`
        }

    }
    props.changeCreateItemDateUse(tmp)
}

function viewGeologivDataValue(props){
    let tmp = null;
    if(props.auctionsMy.geologicDataValue !== null){
        tmp = props.auctionsMy.geologicDataValue.amount
    }
    return tmp
}

function viewDocumentationValue(props){
    let tmp = null;
    if(props.auctionsMy.documentationValue !== null){
        tmp = props.auctionsMy.documentationValue.amount
    }
    return tmp
}

function dateStartAuction1(props){
    let tmp = null;
    if(props.auctionsMy.hasOwnProperty('dateUses') !== false){
        if(props.auctionsMy.dateUses !== null){
            tmp = props.auctionsMy.dateUses.slice(0, 16)
        }
    }
    return tmp
}

function viewIdElements(props){
    let tmp = null;
    let tmpIdAuction = null;
    let tmpIdProcedure = null;
    if(props.auctionsMy){
        if(props.auctionsMy.auctionId !== null){
            tmpIdAuction = <div>ID аукціону Prozorro: <span>{props.auctionsMy.auctionId}</span></div>
        }
        if(props.auctionsMy.procedureId !== null){
            tmpIdProcedure = <div>ID процедури: <span>{props.auctionsMy.procedureId}</span></div>
        }
        tmp = <div>
            {tmpIdAuction}
            {tmpIdProcedure}
        </div>
    }
    return tmp
}

function viewWagonSelected(props){
    let tmp = null;
    switch(props.auctionsMy.sellingMethod){
        case "railwayCargo-english":
        case "railwayCargo-english-fast":
        case "railwayCargo-english-fast-manual":
        case "railwayCargo-english-initial-auction":
        case "railwayCargo-english-initial-qualification":
        case "railwayCargo-dutch":
        case "railwayCargo-dutch-fast":
        case "railwayCargo-dutch-fast-manual":
        case "railwayCargo-dutch-initial-auction":
        case "railwayCargo-dutch-initial-qualification":
        case "railwayCargo-dutch-fast-auction-manual-qualification":
        case "railwayCargo-dutch-initial-auction-manual":
            tmp = <div className={styles.groupElem}>
                    <div>
                        <TextField
                            disabled={disabledElement(props.auctionsMy, '01')}
                            id="outlined-select-currency-native"
                            select
                            label="Тип послуги з використанням вагонів"
                            value={props.auctionsMy.carryType}
                            onChange={(event)=>{
                                props.setCarryType(event.target.value)
                            }}
                            SelectProps={{
                                native: true,
                            }}
                            helperText="Обрати тип послуги з використанням вагонів"
                            variant="outlined"
                        >
                            <option key={0} selected disabled value='null'>Обрати</option>
                            {wagonTypeOption(props)}
                        </TextField>
                    </div>
                </div>
            break;
        default:
            break;
    }
    return tmp
}

function wagonTypeOption(props){
    let tmp = null;
    let tmpArr = null;
    if(props.carryType !== null){
        tmpArr = props.carryType.map(
            (i) => {
                //return <option value={i.classificationId}>{i.description}</option>
                return <option value={i.id}>{i.description}</option>
            }
        )
    }
    switch(props.auctionsMy.sellingMethod){
        case "railwayCargo-english":
        case "railwayCargo-english-fast":
        case "railwayCargo-english-fast-manual":
        case "railwayCargo-english-initial-auction":
        case "railwayCargo-english-initial-qualification":
        case "railwayCargo-dutch":
        case "railwayCargo-dutch-fast":
        case "railwayCargo-dutch-fast-manual":
        case "railwayCargo-dutch-initial-auction":
        case "railwayCargo-dutch-initial-qualification":
        case "railwayCargo-dutch-fast-auction-manual-qualification":
        case "railwayCargo-dutch-initial-auction-manual":
            tmp = tmpArr
            break;
        default:
            break;
    }
    return tmp
}

function addArrayBankAccounts(props, elem){
    let tmp = props.bankAccountIdArr;
    if(tmp !== null){
        if(tmp.length > 0){
            if(tmp.includes(Number(elem)) !== true){
                tmp.push(Number(elem))
                props.setPopBankAccountIdArr(tmp)
            }
        }else{
            tmp.push(Number(elem))
            props.setPopBankAccountIdArr(tmp)
        }
    }else{
        tmp = []
        tmp.push(Number(elem))
        props.setPopBankAccountIdArr(tmp)
    }
}

function removeArrayBankAccounts(props, elem){
    let tmp = props.bankAccountIdArr;
    let index = tmp.indexOf(Number(elem));
    if(tmp.length === 1){
        props.setPopBankAccountIdArr(null)
    }else{
        if(index > -1){
            tmp.splice(index, 1);
            props.setPopBankAccountIdArr(tmp)
        }
    }
}

/*function allBanckAccountProduct(props){
    let tmp = null;
    if(props.bankAccount !== null && props.bankAccountIdArr !== null){
        tmp = props.bankAccount
        .filter(
            (i) => {
                return props.bankAccountIdArr.includes(Number(i.id)) === true
            }
        )
        .map(
            (i) => {
                return (
                    <div className={styles.bankAccountElement}>
                        <div>
                            <div>Назва банку: {i.bankName}</div>
                            <div>Опис банку: {i.description}</div>
                        </div>
                        <div>
                            <div>Опис банківського аккаунту: {i.accountIdentifications[0].description}</div>
                            <div>Ідінтіфікатор банківського аккаунту: {i.accountIdentifications[0].identifier}</div>
                            <div>Схема банківського аккаунту: {i.accountIdentifications[0].scheme}</div>
                        </div>
                        <div
                            onClick={
                                ()=>{
                                    //editBankAccounts(props, i)
                                }
                            }
                        >Редагувати</div>
                        <div
                            onClick={
                                ()=>{
                                    removeArrayBankAccounts(props, i.id)
                                }
                            }
                        >Вилучити</div>
                    </div>
                )
            }
        )
    }else{
        tmp = <div>Підключені банки відсутні</div>
    }
    return tmp
}*/

function elementDisabled(props, minStep, minStepAmo){
    let tmpStatus = null;
    switch(props.auctionsMy.sellingMethod){
        case "railwayCargo-english":
        case "railwayCargo-english-fast":
        case "railwayCargo-english-fast-manual":
        case "railwayCargo-english-initial-auction":
        case "railwayCargo-english-initial-qualification":
        case "railwayCargo-dutch":
        case "railwayCargo-dutch-fast":
        case "railwayCargo-dutch-fast-manual":
        case "railwayCargo-dutch-initial-auction":
        case "railwayCargo-dutch-initial-qualification":
        case "railwayCargo-dutch-fast-auction-manual-qualification":
        case "railwayCargo-dutch-initial-auction-manual":
        case "subsoil-english":
        case "subsoil-english-fast":
        case "subsoil-english-fast-manual":
        case "subsoil-english-initial-auction":
        case "subsoil-english-initial-qualification":
        case "subsoil-dutch":
        case "subsoil-dutch-fast":
        case "subsoil-dutch-fast-manual":
        case "subsoil-dutch-initial-auction":
        case "subsoil-dutch-initial-qualification":
        case "subsoil-dutch-initial-auction-manual":
        case "subsoil-english-fast-auction-manual-qualification":
            if(
                props.auctionsMy.lotIdentifier === null ||
                props.auctionsMy.lotIdentifier === '' ||
                props.auctionsMy.lotIdentifier === 'null' ||
                props.auctionsMy.title === null ||
                props.auctionsMy.title === '' ||
                props.auctionsMy.title === 'null' ||
                props.auctionsMy.description === null ||
                props.auctionsMy.description === '' ||
                props.auctionsMy.description === 'null' ||
                props.auctionsMy.tenderAttempts === null ||
                props.auctionsMy.tenderAttempts === '' ||
                props.auctionsMy.tenderAttempts === 'null' ||
                props.auctionsMy.accessDetails === null ||
                props.auctionsMy.accessDetails === '' ||
                props.auctionsMy.accessDetails === 'null' ||
                //props.auctionsMy.xDocumentRequirements === null ||
                //props.auctionsMy.xDocumentRequirements === '' ||
                //props.auctionsMy.xDocumentRequirements === 'null' ||
                props.auctionsMy.xAdditionalInformation === null ||
                props.auctionsMy.xAdditionalInformation === '' ||
                props.auctionsMy.xAdditionalInformation === 'null' ||
                props.auctionsMy.sellingMethod === null ||
                props.auctionsMy.sellingMethod === '' ||
                props.auctionsMy.sellingMethod === 'null' ||
                //props.auctionsMy.value.currency === null ||
                //props.auctionsMy.value.currency === '' ||
                //props.auctionsMy.value.currency === 'null' ||
                props.auctionsMy.value.amount === null ||
                props.auctionsMy.value.amount === '' ||
                props.auctionsMy.value.amount === 'null' ||
                //props.auctionsMy.guarantee.currency === null ||
                //props.auctionsMy.guarantee.currency === '' ||
                //props.auctionsMy.guarantee.currency === 'null' ||
                //props.auctionsMy.guarantee.amount === null ||
                //props.auctionsMy.guarantee.amount === '' ||
                //props.auctionsMy.guarantee.amount === 'null' ||
                //minStep === null ||
                //minStep === '' ||
                //minStep === 'null' ||
                minStepAmo === null ||
                minStepAmo === '' ||
                minStepAmo === 'null' ||
                props.auctionsMy.startDate === null ||
                props.auctionsMy.startDate === '' ||
                props.auctionsMy.startDate === 'null' ||
                props.bankAccountId === null ||
                props.bankAccountId === '' ||
                props.bankAccountId === 'null'
            ){
                tmpStatus = 'disabled'
            }
            break;
        case "legitimatePropertyLease-english":
        case "legitimatePropertyLease-english-fast":
        case "legitimatePropertyLease-english-fast-manual":
        case "legitimatePropertyLease-english-fast-auction-manual-qualification":
        case "legitimatePropertyLease-english-initial-auction":
        case "legitimatePropertyLease-english-initial-auction-manual":
        case "legitimatePropertyLease-english-initial-qualification":
        case "legitimatePropertyLease-english-initial-qualification-prod":
        case "legitimatePropertyLease-dutch":
        case "legitimatePropertyLease-dutch-fast":
        case "legitimatePropertyLease-dutch-fast-manual":
        case "legitimatePropertyLease-dutch-fast-auction-manual-qualification":
        case "legitimatePropertyLease-dutch-initial-auction":
        case "legitimatePropertyLease-dutch-initial-auction-manual":
        case "legitimatePropertyLease-dutch-initial-qualification-prod":
        case "legitimatePropertyLease-priorityEnglish":
        case "legitimatePropertyLease-priorityEnglish-fast":
        case "legitimatePropertyLease-priorityEnglish-fast-manual":
        case "legitimatePropertyLease-priorityEnglish-fast-auction-manual-qualification":
        case "legitimatePropertyLease-priorityEnglish-initial-auction":
        case "legitimatePropertyLease-priorityEnglish-initial-auction-manual":
        case "legitimatePropertyLease-priorityEnglish-initial-qualification":
        case "legitimatePropertyLease-priorityEnglish-initial-qualification-prod":
                    /*if(
                        props.auctionsMy.title === null ||
                        props.auctionsMy.title === '' ||
                        props.auctionsMy.title === 'null' ||
                        props.auctionsMy.description === null ||
                        props.auctionsMy.description === '' ||
                        props.auctionsMy.description === 'null' ||
                        props.auctionsMy.startDate === null ||
                        props.auctionsMy.startDate === '' ||
                        props.auctionsMy.startDate === 'null' ||
                        props.auctionsMy.tenderAttempts === null ||
                        props.auctionsMy.tenderAttempts === '' ||
                        props.auctionsMy.tenderAttempts === 'null' ||*/
                /*props.auctionsMy.accessDetails === null ||
                props.auctionsMy.accessDetails === '' ||
                props.auctionsMy.accessDetails === 'null' ||*/

                /*props.auctionsMy.minNumberOfQualifiedBids === null ||
                props.auctionsMy.minNumberOfQualifiedBids === '' ||
                props.auctionsMy.minNumberOfQualifiedBids === 'null' ||*/


                        /*props.auctionsMy.value.amount === null ||
                        props.auctionsMy.value.amount === '' ||
                        props.auctionsMy.value.amount === 'null' ||
                        props.auctionsMy.valueAddedTaxCharged === null ||
                        props.auctionsMy.valueAddedTaxCharged === '' ||
                        props.auctionsMy.valueAddedTaxCharged === 'null' ||
                        props.auctionsMy.calcOnBookValue === null ||
                        props.auctionsMy.calcOnBookValue === '' ||
                        props.auctionsMy.calcOnBookValue === 'null' ||
                        props.auctionsMy.valuePeriod === null ||
                        props.auctionsMy.valuePeriod === '' ||
                        props.auctionsMy.valuePeriod === 'null' ||*/

                //props.auctionsMy.leaseDuration === null ||
                //props.auctionsMy.leaseDuration === '' ||
                //props.auctionsMy.leaseDuration === 'null' ||
                
                        /*props.auctionsMy.guarantee.amount === null ||
                        props.auctionsMy.guarantee.amount === '' ||
                        props.auctionsMy.guarantee.amount === 'null' ||
                        props.auctionsMy.registrationFee.amount === null ||
                        props.auctionsMy.registrationFee.amount === '' ||
                        props.auctionsMy.registrationFee.amount === 'null' ||
                        props.bankAccountZU === null ||
                        props.bankAccountZU === '' ||
                        props.bankAccountZU === 'null'
                    ){
                        tmpStatus = 'disabled'
                    }*/
            break;
        default:
            if(
                props.auctionsMy.lotIdentifier === null ||
                props.auctionsMy.lotIdentifier === '' ||
                props.auctionsMy.lotIdentifier === 'null' ||
                props.auctionsMy.title === null ||
                props.auctionsMy.title === '' ||
                props.auctionsMy.title === 'null' ||
                props.auctionsMy.description === null ||
                props.auctionsMy.description === '' ||
                props.auctionsMy.description === 'null' ||
                props.auctionsMy.tenderAttempts === null ||
                props.auctionsMy.tenderAttempts === '' ||
                props.auctionsMy.tenderAttempts === 'null' ||
                props.auctionsMy.accessDetails === null ||
                props.auctionsMy.accessDetails === '' ||
                props.auctionsMy.accessDetails === 'null' ||
                //props.auctionsMy.xDocumentRequirements === null ||
                //props.auctionsMy.xDocumentRequirements === '' ||
                //props.auctionsMy.xDocumentRequirements === 'null' ||
                props.auctionsMy.xAdditionalInformation === null ||
                props.auctionsMy.xAdditionalInformation === '' ||
                props.auctionsMy.xAdditionalInformation === 'null' ||
                props.auctionsMy.sellingMethod === null ||
                props.auctionsMy.sellingMethod === '' ||
                props.auctionsMy.sellingMethod === 'null' ||
                //props.auctionsMy.value.currency === null ||
                //props.auctionsMy.value.currency === '' ||
                //props.auctionsMy.value.currency === 'null' ||
                props.auctionsMy.value.amount === null ||
                props.auctionsMy.value.amount === '' ||
                props.auctionsMy.value.amount === 'null' ||
                //props.auctionsMy.guarantee.currency === null ||
                //props.auctionsMy.guarantee.currency === '' ||
                //props.auctionsMy.guarantee.currency === 'null' ||
                props.auctionsMy.guarantee.amount === null ||
                props.auctionsMy.guarantee.amount === '' ||
                props.auctionsMy.guarantee.amount === 'null' ||
                //minStep === null ||
                //minStep === '' ||
                //minStep === 'null' ||
                minStepAmo === null ||
                minStepAmo === '' ||
                minStepAmo === 'null' ||
                props.auctionsMy.startDate === null ||
                props.auctionsMy.startDate === '' ||
                props.auctionsMy.startDate === 'null' ||
                props.bankAccountId === null ||
                props.bankAccountId === '' ||
                props.bankAccountId === 'null'
            ){
                tmpStatus = 'disabled'
            }
            break;
    }
    if(props.auctionsMy.status !== null){
        if(props.auctionsMy.status !== 'active_rectification'){
            tmpStatus = 'disabled'
        }
    }
    return tmpStatus
}

function elementDisabledCreate(props){
    let tmpStatus = null;
    if(props.auctionsMy.status !== null){
        if(props.auctionsMy.status !== 'active_rectification'){
            let tmpStartData = null
            let tmpEndData = null
            for(let tt = 0; tt < props.auctionsMy.auctionPeriods.length; tt++){
                if(props.auctionsMy.auctionPeriods[tt].status === 'rectificationPeriod'){
                    tmpStartData = new Date(props.auctionsMy.auctionPeriods[tt].startDate).getTime()
                    tmpEndData = new Date(props.auctionsMy.auctionPeriods[tt].endDate).getTime()
                }
            }
            if(
                (isBSE(props.auctionsMy.sellingMethod) || isBSD(props.auctionsMy.sellingMethod) ||
                isALE(props.auctionsMy.sellingMethod) ||
                isCSE(props.auctionsMy.sellingMethod) || isCSD(props.auctionsMy.sellingMethod) ||
                isBRE(props.auctionsMy.sellingMethod) || isBRD(props.auctionsMy.sellingMethod)) &&
                (tmpStartData < Date.now() && tmpEndData > Date.now())
            ){
                return null
            }else{
                return 'disabled'
            }
        }
    }
    return tmpStatus
}

function selectBankAuction(props){
    let tmp = null;
    if(props.bankAccount !== null){
        tmp = props.bankAccount
        .map(
            (i, count) => {
            return <option key={count+1} value={i.id}>{i.bankName}</option>
            }
        )
    }
    return tmp
}

function dateStartAuction(props){
    let tmp = null;
    if(props.auctionsMy.procedureId === null){
        tmp = <KeyboardDateTimePicker
        disabled={disabledElement(props.auctionsMy, '10')}
        className={styles.dateSearch}
        value={props.auctionsMy.startDate}
        onChange={
            (e) => {
                props.changeCreateAuctionDateStart(e)
            }
        }
        onError={console.log}
        minDate={new Date("1900-01-01T00:00")}
        ampm={false}
        format="yyyy/MM/dd HH:mm"
        helperText="Формат дати: гггг/мм/дд чч:мм"
    />
    }else{
        tmp = <KeyboardDateTimePicker
        disabled={disabledElement(props.auctionsMy, '10')}
        className={styles.dateSearch}
        value={props.auctionsMy.startDate}
        onChange={
            (e) => {
                props.changeCreateAuctionDateStart(e)
            }
        }
        onError={console.log}
        minDate={new Date("1900-01-01T00:00")}
        ampm={false}
        format="yyyy/MM/dd HH:mm"
    />
    }

    if(props.auctionsSwitchWindow === 3){
        tmp = <KeyboardDateTimePicker
        disabled={disabledElement(props.auctionsMy, '10')}
        className={styles.dateSearch}
        value={props.auctionsMy.startDate}
        onChange={
            (e) => {
                props.changeCreateAuctionDateStart(e)
            }
        }
        onError={console.log}
        minDate={new Date("1900-01-01T00:00")}
        ampm={false}
        format="yyyy/MM/dd HH:mm"
    />
    }
    return tmp
}

function createOrEdit(props, minStep, minStepAmo){
    let tmp = null;
    if(props.auctionsSwitchWindow === 2){
        tmp = (
            <Button
                disabled={elementDisabled(props, minStep, minStepAmo)}
                variant="contained"
                color="primary"
                onClick={
                    () => {
                        //Тут проверка если это ЗУ создаем по данным от Сереги, потом сохраняю и только потом вызываю новый созданный аук
                        /*if(
                            props.auctionsMy.sellingMethod === "legitimatePropertyLease-english" ||
                            props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-fast" ||
                            props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-fast-manual" ||
                            props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-fast-auction-manual-qualification" ||
                            props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-auction" ||
                            props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-auction-manual" ||
                            props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-qualification" ||
                            props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-qualification-prod"
                        ){
                            createObject(props, props.auctionsMy.registryId)
                        }else{*/
                            createAuc(props)    
                        //}
                    }
                }
            >
                Зберегти аукціон
            </Button>
        )
    }else if(props.auctionsSwitchWindow === 3){
        tmp = (
            <Button
                variant="contained"
                color="primary"
                onClick={
                    () => {
                        //if(Number(props.auctionsMy.tenderAttempts) !== 1){
                            cloneAuc(props)
                            //props.changeAuctionsSwitchWindow(0)
                        //}else{
                            //createAuc(props)
                        //}
                    }
                }
            >
                Копіювання аукціону
            </Button>
        )
    }else if(props.auctionsSwitchWindow === 1){
        tmp = (
            <Button
                disabled={elementDisabled(props, minStep, minStepAmo)}
                variant="contained"
                color="primary"
                onClick={
                    () => {
                        editAuc(props)
                    }
                }
            >
                Зберегти зміни
            </Button>
        )
    }

    return tmp
}

async function createDiscountForAuction(props, auctionId){
    //const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction`;
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${auctionId}/discount`
    let tmpDiscount = null;
    if(props.auctionsMy.tenderAttempts > 1){
        if(
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-english" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-fast" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-fast-manual" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-fast-auction-manual-qualification" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-auction" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-auction-manual" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-qualification" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-qualification-prod" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-manual" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-auction-manual-qualification" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction-manual" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification-prod"
        ){
            //if(props.auctionsZUAttemp.tenderAttempts === 1 && props.auctionsMy.tenderAttempts === '2'){
                //let tmpZUd = props.auctionsZUAttemp.value.amount / 2;
                let body = {}
                body.discount = true
                //body.discountPercent = '50'
                body.discountPercent = props.discount.discountPercent
                body.previousAuctionValue = {
                    currency: 'UAH',
                    //amount: tmpZUd,
                    amount: props.discount.previousAuctionValue.amount
                }
                const response = await fetch(endPoint, {
                    method: "POST",
                    body: JSON.stringify(body),
                    headers: {
                        'Authorization': props.token.access_token,
                        'Content-Type': 'application/json'
                    }
                }).catch(console.error) 
                if(response === undefined || response === 'undefined'){
                }else{
                    const json = await response.json()
                    if(json.hasOwnProperty('message') === true){
                        let tmp = JSON.stringify({title: 'Помилка при створенні знижки', message: JSON.stringify(json)})
                        props.setUniversalError(tmp)
                    }else{
                        props.changeDiscountPreviousAuctionCurrency('null')
                        props.changeDiscountPreviousAuctionAmount('')
                        props.changeDiscountPercent('')
                        props.changeDiscountPreviousAuctionId('')
                        //props.discountAuc(false)
                    }
                }
            //}
        }else{
            if(props.discount.previousAuctionId !== ''){
                if(props.discount.discountPercent !== ''){
                    let body = null
                    if(props.discount.discountOnOff === false){
                        body = {
                            discount: false,
                        }
                    }else{
                        if(props.discount.discountPercent !== ''){
                            body = {
                                discount: true,
                                discountPercent: props.discount.discountPercent,
                                previousAuctionValue: {
                                    currency: 'UAH',
                                    amount: props.discount.previousAuctionValue.amount,
                                }
                            }
                        }else{
                            body = {
                                discount: true,
                                discountPercent: '0',
                                previousAuctionValue: {
                                    currency: 'UAH',
                                    amount: props.discount.previousAuctionValue.amount,
                                }
                            }
                        }
                    }
                    const response = await fetch(endPoint, {
                        method: "POST",
                        body: JSON.stringify(body),
                        headers: {
                            'Authorization': props.token.access_token,
                            'Content-Type': 'application/json'
                        }
                    }).catch(console.error) 
                    if(response === undefined || response === 'undefined'){
                    }else{
                        const json = await response.json()
                        if(json.hasOwnProperty('message') === true){
                            let tmp = JSON.stringify({title: 'Помилка при створенні знижки', message: JSON.stringify(json)})
                            props.setUniversalError(tmp)
                        }else{
                            props.changeDiscountPreviousAuctionCurrency('null')
                            props.changeDiscountPreviousAuctionAmount('')
                            props.changeDiscountPercent('')
                            props.changeDiscountPreviousAuctionId('')
                            //props.discountAuc(false)
                        }
                    }
                }
            }
        }
    }
}

async function editDiscountForAuction(props, auctionId){
    //const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction`;
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${auctionId}/discount`
    let tmpDiscount = null;
    if(props.auctionsMy.tenderAttempts > 1){
        if(props.discount.previousAuctionId !== ''){
            if(props.discount.discountPercent !== ''){
                let body = null
                if(props.discount.discountOnOff === false){
                    body = {
                        discount: false,
                    }
                }else{
                    if(props.discount.discountPercent !== ''){
                        body = {
                            discount: true,
                            discountPercent: props.discount.discountPercent,
                            previousAuctionValue: {
                                currency: 'UAH',
                                amount: props.discount.previousAuctionValue.amount,
                            }
                        }
                    }else{
                        body = {
                            discount: true,
                            discountPercent: '0',
                            previousAuctionValue: {
                                currency: 'UAH',
                                amount: props.discount.previousAuctionValue.amount,
                            }
                        }
                    }
                }
                const response = await fetch(endPoint, {
                    method: "PUT",
                    body: JSON.stringify(body),
                    headers: {
                        'Authorization': props.token.access_token,
                        'Content-Type': 'application/json'
                    }
                }).catch(console.error) 
                if(response === undefined || response === 'undefined'){
                }else{
                    const json = await response.json()
                    if(json.hasOwnProperty('message') === true){
                        let tmp = JSON.stringify({title: 'Помилка при створенні знижки', message: JSON.stringify(json)})
	                    props.setUniversalError(tmp)
                    }else{
                        props.changeDiscountPreviousAuctionCurrency('null')
                        props.changeDiscountPreviousAuctionAmount('')
                        props.changeDiscountPercent('')
                        props.changeDiscountPreviousAuctionId('')
                        //props.discountAuc(false)
                    }
                }
            }
        }
    }
}

async function createAuc(props){
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction`;
    let tmpPreviousAuctionId = null;
    if(props.auctionsMy.tenderAttempts !== 1){
        if(props.discount.previousAuctionId !== ''){
            tmpPreviousAuctionId = props.discount.previousAuctionId
        }
    }
    let tmpDocumentationValue = null;
    let tmpGeologivDataValue = null;
    let tmpGuarantee = null;
    let tmpMinStep = null;
    let tmpValue = null;
    switch(props.auctionsMy.sellingMethod){
        case "renewables":
        case "renewables-fast":
        case "renewables-fast-manual":
        case "renewables-initial-auction":
        case "renewables-initial-qualification":
        case "renewables-initial-qualification-fast":
            tmpGuarantee = {
                currency: 'EUR',
                amount: Number(props.auctionsMy.guarantee.amount),
            }
            tmpMinStep = {
                currency: 'EUR',
                amount: Number(props.auctionsMy.minimalStep.amount),
            }
            tmpValue = {
                currency: 'eurocent',
                amount: Number(props.auctionsMy.value.amount),
            }
            break;
        case "subsoil-english":
        case "subsoil-english-fast":
        case "subsoil-english-fast-manual":
        case "subsoil-english-initial-auction":
        case "subsoil-english-initial-qualification":
        case "subsoil-dutch":
        case "subsoil-dutch-fast":
        case "subsoil-dutch-fast-manual":
        case "subsoil-dutch-initial-auction":
        case "subsoil-dutch-initial-qualification":
        case "subsoil-dutch-initial-auction-manual":
        case "subsoil-english-fast-auction-manual-qualification":
            tmpDocumentationValue = {currency: 'UAH', amount: props.auctionsMy.documentationValue.amount}
            tmpGeologivDataValue = {currency: 'UAH', amount: Number(props.auctionsMy.geologicDataValue.amount)}
            tmpGuarantee = {
                currency: 'UAH',
                amount: Number(props.auctionsMy.guarantee.amount),
                //valueAddedTaxIncluded: props.auctionsMy.guarantee.valueAddedTaxIncluded,
            }
            tmpMinStep = {
                currency: 'UAH',
                amount: Number(props.auctionsMy.minimalStep.amount),
                //valueAddedTaxIncluded: props.auctionsMy.minimalStep.valueAddedTaxIncluded,
            }
            tmpValue = {
                currency: 'UAH',
                amount: Number(props.auctionsMy.value.amount),
                //valueAddedTaxIncluded: props.auctionsMy.value.valueAddedTaxIncluded,
            }
            break;
        case "timber-english":
        case "timber-english-fast":
        case "timber-english-fast-manual":
        case "timber-english-initial-auction":
        case "timber-english-initial-qualification":
        case "timber-english-initial-qualification-fast":
        case "railwayCargo-english":
        case "railwayCargo-english-fast":
        case "railwayCargo-english-fast-manual":
        case "railwayCargo-english-initial-auction":
        case "railwayCargo-english-initial-qualification":
        case "railwayCargo-dutch":
        case "railwayCargo-dutch-fast":
        case "railwayCargo-dutch-fast-manual":
        case "railwayCargo-dutch-initial-auction":
        case "railwayCargo-dutch-initial-qualification":
        case "railwayCargo-dutch-fast-auction-manual-qualification":
        case "railwayCargo-dutch-initial-auction-manual":
        case "dgf-english":
        case "dgf-english-fast":
        case "dgf-english-fast-manual":
        case "dgf-english-fast-auction-manual-qualification":
        case "dgf-english-initial-auction":
        case "dgf-english-initial-auction-manual":
        case "dgf-english-initial-qualification":
        case "dgf-dutch":
        case "dgf-dutch-fast":
        case "dgf-dutch-fast-manual":
        case "dgf-dutch-fast-auction-manual-qualification":
        case "dgf-dutch-initial-auction":
        case "dgf-dutch-initial-qualification":
        case "dgf-dutch-initial-auction-manual":
        case "legitimatePropertyLease-english":
        case "legitimatePropertyLease-english-fast":
        case "legitimatePropertyLease-english-fast-manual":
        case "legitimatePropertyLease-english-fast-auction-manual-qualification":
        case "legitimatePropertyLease-english-initial-auction":
        case "legitimatePropertyLease-english-initial-auction-manual":
        case "legitimatePropertyLease-english-initial-qualification":
        case "legitimatePropertyLease-english-initial-qualification-prod":
        case "legitimatePropertyLease-dutch":
        case "legitimatePropertyLease-dutch-fast":
        case "legitimatePropertyLease-dutch-fast-manual":
        case "legitimatePropertyLease-dutch-fast-auction-manual-qualification":
        case "legitimatePropertyLease-dutch-initial-auction":
        case "legitimatePropertyLease-dutch-initial-auction-manual":
        case "legitimatePropertyLease-dutch-initial-qualification-prod":
        case "legitimatePropertyLease-priorityEnglish":
        case "legitimatePropertyLease-priorityEnglish-fast":
        case "legitimatePropertyLease-priorityEnglish-fast-manual":
        case "legitimatePropertyLease-priorityEnglish-fast-auction-manual-qualification":
        case "legitimatePropertyLease-priorityEnglish-initial-auction":
        case "legitimatePropertyLease-priorityEnglish-initial-auction-manual":
        case "legitimatePropertyLease-priorityEnglish-initial-qualification":
        case "legitimatePropertyLease-priorityEnglish-initial-qualification-prod":
            tmpGuarantee = {
                currency: 'UAH',
                amount: Number(props.auctionsMy.guarantee.amount),
                //valueAddedTaxIncluded: props.auctionsMy.guarantee.valueAddedTaxIncluded,
            }
            tmpMinStep = {
                currency: 'UAH',
                amount: Number(props.auctionsMy.minimalStep.amount),
                //valueAddedTaxIncluded: props.auctionsMy.minimalStep.valueAddedTaxIncluded,
            }
            let tmpValueAddedTaxIncluded = null
            if(props.auctionsMy.value.valueAddedTaxIncluded === 'false'){
                tmpValueAddedTaxIncluded = false
            }else{
                tmpValueAddedTaxIncluded = true
            }
            tmpValue = {
                currency: 'UAH',
                amount: Number(props.auctionsMy.value.amount),
                valueAddedTaxIncluded: tmpValueAddedTaxIncluded,
            }
            break
        default:
            tmpGuarantee = {
                currency: props.auctionsMy.guarantee.currency,
                amount: Number(props.auctionsMy.guarantee.amount),
            }
            tmpMinStep = {
                currency: props.auctionsMy.minimalStep.currency,
                amount: Number(props.auctionsMy.minimalStep.amount),
            }
            tmpValue = {
                currency: props.auctionsMy.value.currency,
                amount: Number(props.auctionsMy.value.amount),
            }
            break;
    }

    if(
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-auction-manual-qualification" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification-prod"
    ){

    }

    let tmpBank = null;
    if(props.bankAccountIdArr === null){
        tmpBank = []
    }else{
        tmpBank = props.bankAccountIdArr
    }
    let tmpDateDay = Date.parse(props.auctionsMy.startDate);
    let tmpMonth = null;
    if(new Date(tmpDateDay).getMonth() < 10){
        tmpMonth = '0' + (new Date(tmpDateDay).getMonth() + 1)
    }else{
        tmpMonth = new Date(tmpDateDay).getMonth() + 1
    }
    let tmpDay = null;
    if(new Date(tmpDateDay).getDate() < 10){
        tmpDay = '0' + new Date(tmpDateDay).getDate()
    }else{
        tmpDay = new Date(tmpDateDay).getDate()
    }

    let tmpDateString = new Date(tmpDateDay).getFullYear() + '-' + tmpMonth + '-' + tmpDay
    let minNumberOfQualifiedBids = returnDataRailway(props)
    if(minNumberOfQualifiedBids === 'null') minNumberOfQualifiedBids = null

    let tmpBody = {}

    if(
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-auction-manual-qualification" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification-prod"
    ){
        if(props.auctionsMy.currentTenantGuaranteeFee.amount !== ''){
            tmpBody.currentTenantGuaranteeFee = {
                currency: 'UAH',
                amount: Number(props.auctionsMy.currentTenantGuaranteeFee.amount)
            }
        }
    }

    tmpBody.documentationValue = tmpDocumentationValue
    tmpBody.geologicDataValue = tmpGeologivDataValue
    tmpBody.leaseDuration = props.auctionsMy.leaseDuration
    tmpBody.carryTypeId = props.auctionsMy.carryType
    tmpBody.previousAuctionId = tmpPreviousAuctionId
    tmpBody.title = props.auctionsMy.title
    tmpBody.description = props.auctionsMy.description
    tmpBody.bankAccountIds = createArrBanks(props, 1, props.auctionsMy.id)
    tmpBody.sellingMethod = props.auctionsMy.sellingMethod
    tmpBody.tenderAttempts = props.auctionsMy.tenderAttempts
    if(props.auctionsMy.accessDetails !== null){
        tmpBody.accessDetails = props.auctionsMy.accessDetails
    }
    if(
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-fast" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-fast-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-fast-auction-manual-qualification" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-auction" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-auction-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-qualification" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-qualification-prod" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-fast" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-fast-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-fast-auction-manual-qualification" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-initial-auction" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-initial-auction-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-initial-qualification-prod" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-auction-manual-qualification" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification-prod"
    ){
        //tmpBody.xDocumentRequirements = props.auctionsMy.xDocumentRequirements
    }else{
        tmpBody.lotIdentifier = props.auctionsMy.lotIdentifier
        tmpBody.xDocumentRequirements = props.auctionsMy.xDocumentRequirements
    }
    if(props.auctionsMy.xAdditionalInformation !== null){
        tmpBody.xAdditionalInformation = props.auctionsMy.xAdditionalInformation
    }
    tmpBody.value = tmpValue
    tmpBody.guarantee = tmpGuarantee
    tmpBody.minimalStep = tmpMinStep
    tmpBody.minNumberOfQualifiedBids = minNumberOfQualifiedBids
    tmpBody.startDate = props.auctionsMy.startDate
    tmpBody.documents = props.auctionsMy.documents

    switch(props.auctionsMy.sellingMethod){
        case "dgf-english":
        case "dgf-english-fast":
        case "dgf-english-fast-manual":
        case "dgf-english-fast-auction-manual-qualification":
        case "dgf-english-initial-auction":
        case "dgf-english-initial-auction-manual":
        case "dgf-english-initial-qualification":
        case "dgf-dutch":
        case "dgf-dutch-fast":
        case "dgf-dutch-fast-manual":
        case "dgf-dutch-fast-auction-manual-qualification":
        case "dgf-dutch-initial-auction":
        case "dgf-dutch-initial-qualification":
        case "dgf-dutch-initial-auction-manual":
            tmpBody.minNumberOfQualifiedBids = props.auctionsMy.minNumberOfQualifiedBids
            tmpBody.virtualDataRoom = props.auctionsMy.virtualDataRoom
            tmpBody.eligibilityCriteria = props.auctionsMy.eligibilityCriteria
            tmpBody.decision = {
                decisionId: props.auctionsMy.decision.decisionId,
                decisionDate: props.auctionsMy.decision.decisionDate
            }
            tmpBody.x_dgfPlatformLegalDetails = props.auctionsMy.x_dgfPlatformLegalDetails
            break;
        case "legitimatePropertyLease-english":
        case "legitimatePropertyLease-english-fast":
        case "legitimatePropertyLease-english-fast-manual":
        case "legitimatePropertyLease-english-fast-auction-manual-qualification":
        case "legitimatePropertyLease-english-initial-auction":
        case "legitimatePropertyLease-english-initial-auction-manual":
        case "legitimatePropertyLease-english-initial-qualification":
        case "legitimatePropertyLease-english-initial-qualification-prod":
        case "legitimatePropertyLease-dutch":
        case "legitimatePropertyLease-dutch-fast":
        case "legitimatePropertyLease-dutch-fast-manual":
        case "legitimatePropertyLease-dutch-fast-auction-manual-qualification":
        case "legitimatePropertyLease-dutch-initial-auction":
        case "legitimatePropertyLease-dutch-initial-auction-manual":
        case "legitimatePropertyLease-dutch-initial-qualification-prod":
        case "legitimatePropertyLease-priorityEnglish":
        case "legitimatePropertyLease-priorityEnglish-fast":
        case "legitimatePropertyLease-priorityEnglish-fast-manual":
        case "legitimatePropertyLease-priorityEnglish-fast-auction-manual-qualification":
        case "legitimatePropertyLease-priorityEnglish-initial-auction":
        case "legitimatePropertyLease-priorityEnglish-initial-auction-manual":
        case "legitimatePropertyLease-priorityEnglish-initial-qualification":
        case "legitimatePropertyLease-priorityEnglish-initial-qualification-prod":
            if(Number(props.auctionsMy.minNumberOfQualifiedBids) === 1 || Number(props.auctionsMy.minNumberOfQualifiedBids) === 2){
                tmpBody.minNumberOfQualifiedBids = Number(props.auctionsMy.minNumberOfQualifiedBids)
            }else{
                tmpBody.minNumberOfQualifiedBids = 1
            }
            tmpBody.calcOnBookValue = props.auctionsMy.calcOnBookValue
            tmpBody.valueAddedTaxCharged = props.auctionsMy.valueAddedTaxCharged
            tmpBody.registryId = props.auctionsMy.registryId
            /*if(props.auctionsMy.registrationFee.valueAddedTaxIncluded === true || props.auctionsMy.registrationFee.valueAddedTaxIncluded === 'true'){
                tmpBody.registrationFee = {
                    currency: 'UAH',
                    amount: Number(props.auctionsMy.registrationFee.amount),
                    //valueAddedTaxIncluded: true
                }
            }else if(props.auctionsMy.registrationFee.valueAddedTaxIncluded === false || props.auctionsMy.registrationFee.valueAddedTaxIncluded === 'false'){
                tmpBody.registrationFee = {
                    currency: 'UAH',
                    amount: Number(props.auctionsMy.registrationFee.amount),
                    //valueAddedTaxIncluded: false
                }
            }else{*/
                tmpBody.registrationFee = {
                    currency: 'UAH',
                    amount: Number(props.auctionsMy.registrationFee.amount),
                }
            //}
            tmpBody.valuePeriod = props.auctionsMy.valuePeriod
            //tmpBody.xDocumentRequirements = ' ' //Єту строку после фикса от Сереги отключить
            break;
        default:
            break;
    }

    const response = await fetch(endPoint, {
        method: "POST",
        //timberEnglish
        body: JSON.stringify(tmpBody),
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){
            let tmp = JSON.stringify({title: 'Помилка при створенні аукціону', message: JSON.stringify(json)})
	        props.setUniversalError(tmp)
            
        }else{
            if(
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-english" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-fast" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-fast-manual" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-fast-auction-manual-qualification" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-auction" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-auction-manual" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-qualification" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-qualification-prod" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-fast" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-fast-manual" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-fast-auction-manual-qualification" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-initial-auction" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-initial-auction-manual" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-initial-qualification-prod" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-manual" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-auction-manual-qualification" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction-manual" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification-prod"
            ){
                createAuctionLeaseRules(props, json.id)
            }
            if(
                props.auctionsMy.sellingMethod === "railwayCargo-dutch" ||
                props.auctionsMy.sellingMethod === "railwayCargo-dutch-fast" ||
                props.auctionsMy.sellingMethod === "railwayCargo-dutch-fast-manual" ||
                props.auctionsMy.sellingMethod === "railwayCargo-dutch-initial-auction" ||
                props.auctionsMy.sellingMethod === "railwayCargo-dutch-initial-qualification" ||
                props.auctionsMy.sellingMethod === "railwayCargo-dutch-fast-auction-manual-qualification" ||
                props.auctionsMy.sellingMethod === "railwayCargo-dutch-initial-auction-manual"
            ){
                if(dutchData(props) !== false){
                    let dutchStep = {
                        dutchStepQuantity: props.auctionsMy.dutchStep.dutchStepQuantity,
                        dutchStepPercent: props.auctionsMy.dutchStep.dutchStepPercent,
                        dutchStepValue: {
                            currency: 'UAH',
                            amount: props.auctionsMy.dutchStep.dutchStepValue.amount,
                            //valueAddedTaxIncluded: null,
                        },
                    }
                    const endPoint3 = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${json.id}/dutch_step`
                    const response3 = await fetch(endPoint3, {
                        method: "POST",
                        body: JSON.stringify(dutchStep),
                        headers: {
                            'Authorization': props.token.access_token,
                            'Content-Type': 'application/json'
                        }
                    }).catch(console.error)
                    if(response3 === undefined || response3 === 'undefined'){
                    }else{
                        const json3 = await response3.json()
                        if(json3.hasOwnProperty('message') === true){
                            let tmp = JSON.stringify({title: 'Помилка при створенні даних голандського аукціону', message: JSON.stringify(json3)})
                            props.setUniversalError(tmp)
                            //props.setPopUpAuctionCreate(1)
                        }else{
                            let tmpAucData = json
                            tmpAucData.dutchStep = json3
                            //props.setPopUpAuctionCreate(json)
                            props.setPopUpAuctionCreate(tmpAucData)
                            createDiscountForAuction(props, json.id)
                            props.changeDiscountPreviousAuctionCurrency('null')
                            props.changeDiscountPreviousAuctionAmount('')
                            props.changeDiscountPercent('')
                            props.changeDiscountPreviousAuctionId('')
                            props.discountAuc(false)
                        }
                    }
                }else{
                    props.setPopUpAuctionCreate(json)
                    createDiscountForAuction(props, json.id)
                    props.changeDiscountPreviousAuctionCurrency('null')
                    props.changeDiscountPreviousAuctionAmount('')
                    props.changeDiscountPercent('')
                    props.changeDiscountPreviousAuctionId('')
                    props.discountAuc(false)
                }
            }else{
                props.setPopUpAuctionCreate(json)
                createDiscountForAuction(props, json.id)
                props.changeDiscountPreviousAuctionCurrency('null')
                props.changeDiscountPreviousAuctionAmount('')
                props.changeDiscountPercent('')
                props.changeDiscountPreviousAuctionId('')
                props.discountAuc(false)
            }
        }
    }
    /*props.changeCreateAuctionLotIdentifier('')
    props.changeCreateAuctionTitle('')
    props.changeCreateAuctionDescription('')
    props.changeCreateAuctionTenderAttempts('')
    props.changeCreateAuctionAccessDetails('')
    props.changeCreateAuctionXDocumentRequirements('')
    props.changeCreateAuctionXAdditionalInformation('')
    props.changeCreateAuctionType('null')
    props.changeCreateAuctionValueCurrency('null')
    props.changeCreateAuctionValueAmount('')
    props.changeCreateAuctionGuaranteeCurrency('null')
    props.changeCreateAuctionGuaranteeAmount('')
    props.changeCreateAuctionMinimalStepCurrency('null')
    props.changeCreateAuctionMinimalStepAmount('')
    props.changeCreateAuctionDateStart('')
    props.setPopBankAccountId('null')
    props.setPopBankAccountIdArr(null)
    props.changeDiscountPreviousAuctionCurrency('null')
    props.changeDiscountPreviousAuctionAmount('')
    props.changeDiscountPercent('')
    props.changeDiscountPreviousAuctionId('')
    props.discountAuc(false)
    props.setBankAccountSave([])
    props.setBankAccountAdd([])
    props.setBankAccountDelete([])

    props.changeCreateAuctionMinNumberOfQualifiedBids('2')
    props.changeCreateAuctionDgfVirtualDataRoom('')
    props.changeCreateAuctionDgfEligibilityCriteria('null')
    props.changeCreateAuctionDgfX_dgfPlatformLegalDetails('')
    props.changeCreateAuctionDgfDecisionId('')
    props.changeCreateAuctionDgfDecisionDate('')*/
}

function dutchData(props){
    switch(props.auctionsMy.sellingMethod){
        case "railwayCargo-dutch":
        case "railwayCargo-dutch-fast":
        case "railwayCargo-dutch-fast-manual":
        case "railwayCargo-dutch-initial-auction":
        case "railwayCargo-dutch-initial-qualification":
        case "railwayCargo-dutch-fast-auction-manual-qualification":
        case "railwayCargo-dutch-initial-auction-manual":
        case "subsoil-dutch":
        case "subsoil-dutch-fast":
        case "subsoil-dutch-fast-manual":
        case "subsoil-dutch-initial-auction":
        case "subsoil-dutch-initial-qualification":
        case "subsoil-dutch-initial-auction-manual":
        case "subsoil-english-fast-auction-manual-qualification":
        case "dgf-dutch":
        case "dgf-dutch-fast":
        case "dgf-dutch-fast-manual":
        case "dgf-dutch-fast-auction-manual-qualification":
        case "dgf-dutch-initial-auction":
        case "dgf-dutch-initial-qualification":
        case "dgf-dutch-initial-auction-manual":
        case "legitimatePropertyLease-dutch":
        case "legitimatePropertyLease-dutch-fast":
        case "legitimatePropertyLease-dutch-fast-manual":
        case "legitimatePropertyLease-dutch-fast-auction-manual-qualification":
        case "legitimatePropertyLease-dutch-initial-auction":
        case "legitimatePropertyLease-dutch-initial-auction-manual":
        case "legitimatePropertyLease-dutch-initial-qualification-prod":
            return true
        default:
            return false
    }
}

async function cloneAuc(props){
    let tmpCarryType = null;
    if(props.auctionsMy.carryType !== null) {tmpCarryType = props.auctionsMy.carryType}
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction`;
    let tmpBank = null;
    if(props.bankAccountIdArr === null){
        tmpBank = []
    }else{
        tmpBank = props.bankAccountIdArr
    }
    let tmpDateDay = Date.parse(props.auctionsMy.startDate);
    let tmpMonth = null;
    if(new Date(tmpDateDay).getMonth() < 10){
        tmpMonth = '0' + (new Date(tmpDateDay).getMonth() + 1)
    }else{
        tmpMonth = new Date(tmpDateDay).getMonth() + 1
    }
    let tmpDay = null;
    if(new Date(tmpDateDay).getDate() < 10){
        tmpDay = '0' + new Date(tmpDateDay).getDate()
    }else{
        tmpDay = new Date(tmpDateDay).getDate()
    }
    let tmpDateString = new Date(tmpDateDay).getFullYear() + '-' + tmpMonth + '-' + tmpDay
    let minNumberOfQualifiedBids = returnDataRailway(props)
    if(minNumberOfQualifiedBids === 'null') minNumberOfQualifiedBids = null
    let tmpBody = {}
    if(
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-auction-manual-qualification" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification-prod"
    ){
        if(props.auctionsMy.currentTenantGuaranteeFee.amount !== ''){
            tmpBody.currentTenantGuaranteeFee = {
                currency: 'UAH',
                amount: Number(props.auctionsMy.currentTenantGuaranteeFee.amount)
            }
        }
    }
    tmpBody.documentationValue = props.auctionsMy.documentationValue
    tmpBody.geologicDataValue = props.auctionsMy.geologicDataValue
    tmpBody.leaseDuration = props.auctionsMy.leaseDuration
    tmpBody.carryTypeId = tmpCarryType
    tmpBody.title = props.auctionsMy.title
    tmpBody.description = props.auctionsMy.description
    tmpBody.bankAccountIds = createArrBanks(props, 1, props.auctionsMy.id)//tmpBank,
    tmpBody.sellingMethod = props.auctionsMy.sellingMethod
    tmpBody.tenderAttempts = props.auctionsMy.tenderAttempts
    if(props.auctionsMy.accessDetails !== null){
        tmpBody.accessDetails = props.auctionsMy.accessDetails
    }
    if(
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-fast" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-fast-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-fast-auction-manual-qualification" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-auction" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-auction-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-qualification" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-qualification-prod" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-fast" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-fast-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-fast-auction-manual-qualification" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-initial-auction" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-initial-auction-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-initial-qualification-prod" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-auction-manual-qualification" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification-prod"
    ){
        //tmpBody.xDocumentRequirements = props.auctionsMy.xDocumentRequirements
    }else{
        tmpBody.lotIdentifier = props.auctionsMy.lotIdentifier
        tmpBody.xDocumentRequirements = props.auctionsMy.xDocumentRequirements
    }
    if(props.auctionsMy.xAdditionalInformation !== null){
        tmpBody.xAdditionalInformation = props.auctionsMy.xAdditionalInformation
    }
    if(
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-fast" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-fast-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-fast-auction-manual-qualification" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-auction" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-auction-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-qualification" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-qualification-prod" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-auction-manual-qualification" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification-prod"
    ){
        if(props.auctionsZUAttemp.tenderAttempts === 1 && props.auctionsMy.tenderAttempts === '2'){
            //let tmpZU = props.auctionsZUAttemp.value.amount / 2
            let tmpZU = props.auctionsZUAttemp.value.amount
            //let tmpGarantyZU = (tmpZU / 100) * 5
            let tmpGarantyZU = props.auctionsZUAttemp.guarantee.amount
            //let tmpMinStepZU = (tmpZU / 100) * 1
            let tmpMinStepZU = props.auctionsZUAttemp.minimalStep.amount
            tmpBody.value = {
                currency: props.auctionsMy.value.currency,
                amount: Number(tmpZU),
                valueAddedTaxIncluded: props.auctionsMy.value.valueAddedTaxIncluded
            }
            tmpBody.guarantee = {
                currency: props.auctionsMy.guarantee.currency,
                amount: Number(tmpGarantyZU),
            }
            tmpBody.minimalStep = {
                currency: props.auctionsMy.minimalStep.currency,
                amount: Number(tmpMinStepZU),
            }
        }else{
            tmpBody.value = {
                currency: props.auctionsMy.value.currency,
                amount: Number(props.auctionsMy.value.amount),
                valueAddedTaxIncluded: props.auctionsMy.value.valueAddedTaxIncluded
            }
            tmpBody.guarantee = {
                currency: props.auctionsMy.guarantee.currency,
                amount: Number(props.auctionsMy.guarantee.amount),
            }
            tmpBody.minimalStep = {
                currency: props.auctionsMy.minimalStep.currency,
                amount: Number(props.auctionsMy.minimalStep.amount),
            }
        }
    }else{
        tmpBody.value = {
            currency: props.auctionsMy.value.currency,
            amount: Number(props.auctionsMy.value.amount),
            valueAddedTaxIncluded: props.auctionsMy.value.valueAddedTaxIncluded
        }
        tmpBody.guarantee = {
            currency: props.auctionsMy.guarantee.currency,
            amount: Number(props.auctionsMy.guarantee.amount),
        }
        tmpBody.minimalStep = {
            currency: props.auctionsMy.minimalStep.currency,
            amount: Number(props.auctionsMy.minimalStep.amount),
        }
    }
    tmpBody.minNumberOfQualifiedBids = minNumberOfQualifiedBids
    tmpBody.startDate = props.auctionsMy.startDate
    tmpBody.documents = props.auctionsMy.documents
    if(Number(props.auctionsMy.tenderAttempts) !== 1){
        tmpBody.previousAuctionId = props.discount.previousAuctionId
    }
    
    switch(props.auctionsMy.sellingMethod){
        case "dgf-english":
        case "dgf-english-fast":
        case "dgf-english-fast-manual":
        case "dgf-english-fast-auction-manual-qualification":
        case "dgf-english-initial-auction":
        case "dgf-english-initial-auction-manual":
        case "dgf-english-initial-qualification":
        case "dgf-dutch":
        case "dgf-dutch-fast":
        case "dgf-dutch-fast-manual":
        case "dgf-dutch-fast-auction-manual-qualification":
        case "dgf-dutch-initial-auction":
        case "dgf-dutch-initial-qualification":
        case "dgf-dutch-initial-auction-manual":
            tmpBody.minNumberOfQualifiedBids = props.auctionsMy.minNumberOfQualifiedBids
            tmpBody.virtualDataRoom = props.auctionsMy.virtualDataRoom
            tmpBody.eligibilityCriteria = props.auctionsMy.eligibilityCriteria
            tmpBody.decision = {
                decisionId: props.auctionsMy.decision.decisionId,
                decisionDate: props.auctionsMy.decision.decisionDate
            }
            tmpBody.x_dgfPlatformLegalDetails = props.auctionsMy.x_dgfPlatformLegalDetails
            break;
        case "legitimatePropertyLease-english":
        case "legitimatePropertyLease-english-fast":
        case "legitimatePropertyLease-english-fast-manual":
        case "legitimatePropertyLease-english-fast-auction-manual-qualification":
        case "legitimatePropertyLease-english-initial-auction":
        case "legitimatePropertyLease-english-initial-auction-manual":
        case "legitimatePropertyLease-english-initial-qualification":
        case "legitimatePropertyLease-english-initial-qualification-prod":
        case "legitimatePropertyLease-dutch":
        case "legitimatePropertyLease-dutch-fast":
        case "legitimatePropertyLease-dutch-fast-manual":
        case "legitimatePropertyLease-dutch-fast-auction-manual-qualification":
        case "legitimatePropertyLease-dutch-initial-auction":
        case "legitimatePropertyLease-dutch-initial-auction-manual":
        case "legitimatePropertyLease-dutch-initial-qualification-prod":
        case "legitimatePropertyLease-priorityEnglish":
        case "legitimatePropertyLease-priorityEnglish-fast":
        case "legitimatePropertyLease-priorityEnglish-fast-manual":
        case "legitimatePropertyLease-priorityEnglish-fast-auction-manual-qualification":
        case "legitimatePropertyLease-priorityEnglish-initial-auction":
        case "legitimatePropertyLease-priorityEnglish-initial-auction-manual":
        case "legitimatePropertyLease-priorityEnglish-initial-qualification":
        case "legitimatePropertyLease-priorityEnglish-initial-qualification-prod":
            if(Number(props.auctionsMy.minNumberOfQualifiedBids) === 1 || Number(props.auctionsMy.minNumberOfQualifiedBids) === 2){
                tmpBody.minNumberOfQualifiedBids = Number(props.auctionsMy.minNumberOfQualifiedBids)
            }else{
                tmpBody.minNumberOfQualifiedBids = 1
            }
            tmpBody.calcOnBookValue = props.auctionsMy.calcOnBookValue
            tmpBody.valueAddedTaxCharged = props.auctionsMy.valueAddedTaxCharged
            tmpBody.registryId = props.auctionsMy.registryId
            if(props.auctionsMy.registrationFee.valueAddedTaxIncluded === true || props.auctionsMy.registrationFee.valueAddedTaxIncluded === 'true'){
                tmpBody.registrationFee = {
                    currency: 'UAH',
                    amount: Number(props.auctionsMy.registrationFee.amount),
                    valueAddedTaxIncluded: true
                }
            }else if(props.auctionsMy.registrationFee.valueAddedTaxIncluded === false || props.auctionsMy.registrationFee.valueAddedTaxIncluded === 'false'){
                tmpBody.registrationFee = {
                    currency: 'UAH',
                    amount: Number(props.auctionsMy.registrationFee.amount),
                    valueAddedTaxIncluded: false
                }
            }else{
                tmpBody.registrationFee = {
                    currency: 'UAH',
                    amount: Number(props.auctionsMy.registrationFee.amount),
                }
            }
            tmpBody.valuePeriod = props.auctionsMy.valuePeriod
            break;
        default:
            break;
    }

    const response = await fetch(endPoint, {
        method: "POST",

        //timberEnglish
        body: JSON.stringify(tmpBody),
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
        props.changeAuctionsSwitchWindow(0)
        props.changeCreateAuctionTab(0);
        getData(props)
    }else{
        //props.setPopUpAuctionMyNull()
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){
            let tmp = JSON.stringify({title: 'Помилка при створенні копії аукціону', message: JSON.stringify(json)})
            props.setUniversalError(tmp)
            props.changeAuctionsSwitchWindow(0)
            props.changeCreateAuctionTab(0);
            getData(props)
        }else{
            if(
                json.sellingMethod === "legitimatePropertyLease-english" ||
                json.sellingMethod === "legitimatePropertyLease-english-fast" ||
                json.sellingMethod === "legitimatePropertyLease-english-fast-manual" ||
                json.sellingMethod === "legitimatePropertyLease-english-fast-auction-manual-qualification" ||
                json.sellingMethod === "legitimatePropertyLease-english-initial-auction" ||
                json.sellingMethod === "legitimatePropertyLease-english-initial-auction-manual" ||
                json.sellingMethod === "legitimatePropertyLease-english-initial-qualification" ||
                json.sellingMethod === "legitimatePropertyLease-english-initial-qualification-prod" ||
                json.sellingMethod === "legitimatePropertyLease-dutch" ||
                json.sellingMethod === "legitimatePropertyLease-dutch-fast" ||
                json.sellingMethod === "legitimatePropertyLease-dutch-fast-manual" ||
                json.sellingMethod === "legitimatePropertyLease-dutch-fast-auction-manual-qualification" ||
                json.sellingMethod === "legitimatePropertyLease-dutch-initial-auction" ||
                json.sellingMethod === "legitimatePropertyLease-dutch-initial-auction-manual" ||
                json.sellingMethod === "legitimatePropertyLease-dutch-initial-qualification-prod" ||
                json.sellingMethod === "legitimatePropertyLease-priorityEnglish" ||
                json.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast" ||
                json.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-manual" ||
                json.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-auction-manual-qualification" ||
                json.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction" ||
                json.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction-manual" ||
                json.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification" ||
                json.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification-prod"
            ){
                createAuctionLeaseRules(props, json.id)
                let data = {}
                if(props.auctionsZUAttemp.bankAccountsGroups !== null){
                    if(props.auctionsZUAttemp.bankAccountsGroups.length !== null){
                        for(let i = 0; i < props.auctionsZUAttemp.bankAccountsGroups.length; i++){
                            if(props.auctionsZUAttemp.bankAccountsGroups[i].type === "registrationFeeAccounts"){
                                data.id = props.auctionsZUAttemp.bankAccountsGroups[i].id
                                data.group = props.auctionsZUAttemp.bankAccountsGroups[i].type
                                if(props.auctionsZUAttemp.bankAccountsGroups[i].bankAccountsForeign.length > 0){
                                    data.type = 'bankAccountsForeign'
                                }else if(props.auctionsZUAttemp.bankAccountsGroups[i].bankAccountsUa.length > 0){
                                    data.type = 'bankAccountsUa'
                                }
                                data.gropType = 'default'
                                await createZUBanksPOST(props, data, json.id)
                            }
                            if(props.auctionsZUAttemp.bankAccountsGroups[i].type === "guaranteeAccounts"){
                                data.id = props.auctionsZUAttemp.bankAccountsGroups[i].id
                                data.group = props.auctionsZUAttemp.bankAccountsGroups[i].type
                                if(props.auctionsZUAttemp.bankAccountsGroups[i].bankAccountsForeign.length > 0){
                                    data.type = 'bankAccountsForeign'
                                }else if(props.auctionsZUAttemp.bankAccountsGroups[i].bankAccountsUa.length > 0){
                                    data.type = 'bankAccountsUa'
                                }
                                data.gropType = 'default'
                                await createZUBanksPOST(props, data, json.id)
                            }
                            if(props.auctionsZUAttemp.bankAccountsGroups[i].type === "advancePaymentAccounts"){
                                data.id = props.auctionsZUAttemp.bankAccountsGroups[i].id
                                data.group = props.auctionsZUAttemp.bankAccountsGroups[i].type
                                if(props.auctionsZUAttemp.bankAccountsGroups[i].bankAccountsForeign.length > 0){
                                    data.type = 'bankAccountsForeign'
                                }else if(props.auctionsZUAttemp.bankAccountsGroups[i].bankAccountsUa.length > 0){
                                    data.type = 'bankAccountsUa'
                                }
                                data.gropType = 'default'
                                await createZUBanksPOST(props, data, json.id)
                            }
                            if(props.auctionsZUAttemp.bankAccountsGroups[i].type === "securityDepositAccounts"){
                                data.id = props.auctionsZUAttemp.bankAccountsGroups[i].id
                                data.group = props.auctionsZUAttemp.bankAccountsGroups[i].type
                                if(props.auctionsZUAttemp.bankAccountsGroups[i].bankAccountsForeign.length > 0){
                                    data.type = 'bankAccountsForeign'
                                }else if(props.auctionsZUAttemp.bankAccountsGroups[i].bankAccountsUa.length > 0){
                                    data.type = 'bankAccountsUa'
                                }
                                data.gropType = 'default'
                                await createZUBanksPOST(props, data, json.id)
                            }
                            if(props.auctionsZUAttemp.bankAccountsGroups[i].type === "improvementsCompensationAccounts"){
                                data.id = props.auctionsZUAttemp.bankAccountsGroups[i].id
                                data.group = props.auctionsZUAttemp.bankAccountsGroups[i].type
                                if(props.auctionsZUAttemp.bankAccountsGroups[i].bankAccountsForeign.length > 0){
                                    data.type = 'bankAccountsForeign'
                                }else if(props.auctionsZUAttemp.bankAccountsGroups[i].bankAccountsUa.length > 0){
                                    data.type = 'bankAccountsUa'
                                }
                                data.gropType = 'default'
                                await createZUBanksPOST(props, data, json.id)
                            }
                            if(props.auctionsZUAttemp.bankAccountsGroups[i].type === "otherAccounts"){
                                data.id = props.auctionsZUAttemp.bankAccountsGroups[i].id
                                data.group = props.auctionsZUAttemp.bankAccountsGroups[i].type
                                if(props.auctionsZUAttemp.bankAccountsGroups[i].bankAccountsForeign.length > 0){
                                    data.type = 'bankAccountsForeign'
                                }else if(props.auctionsZUAttemp.bankAccountsGroups[i].bankAccountsUa.length > 0){
                                    data.type = 'bankAccountsUa'
                                }
                                data.gropType = 'default'
                                await createZUBanksPOST(props, data, json.id)
                            }
                        }
                    }
                }
                if(props.auctionsMy.items !== null){
                    if(props.auctionsMy.items.length > 0){
                        for(let i = 0; i < props.auctionsMy.items.length; i++){
                            await createItemZU(props, props.auctionsMy.items[i], json.id)
                        }
                    }
                }
            }else{
                if(props.auctionsMy.items !== null){
                    if(props.auctionsMy.items.length > 0){
                        for(let i = 0; i < props.auctionsMy.items.length; i++){
                            await createItemEnergy(props, props.auctionsMy.items[i], json.id)
                        }
                    }
                }
            }
            //if(props.discount.discountOnOff === true){
            await createDiscountForAuction(props, json.id)
            //}
            if(dutchData(props) !== false){
                let dutchStep = {
                    dutchStepQuantity: props.auctionsMy.dutchStep.dutchStepQuantity,
                    dutchStepPercent: props.auctionsMy.dutchStep.dutchStepPercent,
                    dutchStepValue: {
                        currency: 'UAH',
                        amount: props.auctionsMy.dutchStep.dutchStepValue.amount,
                        //valueAddedTaxIncluded: null,
                    },
                }
                const endPoint3 = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${json.id}/dutch_step`
                const response3 = await fetch(endPoint3, {
                    method: "POST",
                    body: JSON.stringify(dutchStep),
                    headers: {
                        'Authorization': props.token.access_token,
                        'Content-Type': 'application/json'
                    }
                }).catch(console.error)
                if(response3 === undefined || response3 === 'undefined'){
                }else{
                    const json3 = await response3.json()
                    if(json3.hasOwnProperty('message') === true){
                        let tmp = JSON.stringify({title: 'Помилка при створенні аукціону', message: JSON.stringify(json3)})
                        props.setUniversalError(tmp)
                        //props.setPopUpAuctionCreate(1)
                        props.changeAuctionsSwitchWindow(0)
                        props.changeCreateAuctionTab(0);
                        getData(props)
                    }else{
                        let tmpAucData = json
                        tmpAucData.dutchStep = json3
                        //props.setPopUpAuctionCreate(json)
                        props.setPopUpAuctionCreate(tmpAucData)
                        createDiscountForAuction(props, json.id)
                        props.changeDiscountPreviousAuctionCurrency('null')
                        props.changeDiscountPreviousAuctionAmount('')
                        props.changeDiscountPercent('')
                        props.changeDiscountPreviousAuctionId('')
                        props.discountAuc(false)
                    }
                }
            }else{
                props.changeDiscountPreviousAuctionCurrency('null')
                props.changeDiscountPreviousAuctionAmount('')
                props.changeDiscountPercent('')
                props.changeDiscountPreviousAuctionId('')
                props.discountAuc(false)
                //props.setPopUpAuctionCreate(json)
                props.changeAuctionsSwitchWindow(0)
                props.changeCreateAuctionTab(0);
                getData(props)
            }
        }
    }

    /*let data = {
        domen: process.env.REACT_APP_END_POINT_BD,
        version: 'v1',
        local: props.langFlag,
        params: '?limit=25'
    }
    let header = {
        'Authorization': props.token.access_token,
        'Content-Type': 'application/json'
    }
    props.auctionsGetMy(data, header)*/

    props.changeCreateAuctionLotIdentifier('')
    props.changeCreateAuctionTitle('')
    props.changeCreateAuctionDescription('')
    props.changeCreateAuctionTenderAttempts('')
    props.changeCreateAuctionAccessDetails('')
    props.changeCreateAuctionXDocumentRequirements('')
    props.changeCreateAuctionXAdditionalInformation('')
    props.changeCreateAuctionType('null')
    props.changeCreateAuctionValueCurrency('null')
    props.changeCreateAuctionValueAmount('')
    props.changeCreateAuctionGuaranteeCurrency('null')
    props.changeCreateAuctionGuaranteeAmount('')
    props.changeCreateAuctionMinimalStepCurrency('null')
    props.changeCreateAuctionMinimalStepAmount('')
    props.changeCreateAuctionDateStart('')
    props.setPopBankAccountId('null')
    props.setPopBankAccountIdArr(null)
    //const json = await response.json()
    props.changeDiscountPreviousAuctionCurrency('null')
    props.changeDiscountPreviousAuctionAmount('')
    props.changeDiscountPercent('')
    props.changeDiscountPreviousAuctionId('')
    props.discountAuc(false)
    props.setBankAccountSave([])
    props.setBankAccountAdd([])
    props.setBankAccountDelete([])
    props.changeCreateAuctionMinNumberOfQualifiedBids('2')
    props.changeCreateAuctionDgfVirtualDataRoom('')
    props.changeCreateAuctionDgfEligibilityCriteria('null')
    props.changeCreateAuctionDgfX_dgfPlatformLegalDetails('')
    props.changeCreateAuctionDgfDecisionId('')
    props.changeCreateAuctionDgfDecisionDate('')
}

async function getData(props){
    let data = {
        domen: process.env.REACT_APP_END_POINT_BD,
        version: 'v1',
        local: props.langFlag,
        params: '?offset=0'
    }
    let header = {
        'Authorization': props.token.access_token,
        'Content-Type': 'application/json'
    }
    //props.auctionsBidsGetMy(data, header)
    //props.bankAccountGet(data, header)
    props.auctionsGetMy(data, header)
    /*await props.workMyPageCurrentAuction(0)
    if(props.auctionsMy !== null){
        if(props.auctionsMy.length === 10) {
            await props.workMyPageNextAuction(1)
        }else{
            await props.workMyPageNextAuction(null)
        }
    }*/
}

function returnDataRailway(props){
    switch(props.auctionsMy.sellingMethod){
        case "railwayCargo-english":
        case "railwayCargo-english-fast":
        case "railwayCargo-english-fast-manual":
        case "railwayCargo-english-initial-auction":
        case "railwayCargo-english-initial-qualification":
        case "railwayCargo-dutch":
        case "railwayCargo-dutch-fast":
        case "railwayCargo-dutch-fast-manual":
        case "railwayCargo-dutch-initial-auction":
        case "railwayCargo-dutch-initial-qualification":
        case "railwayCargo-dutch-fast-auction-manual-qualification":
        case "railwayCargo-dutch-initial-auction-manual":
        case "legitimatePropertyLease-english":
        case "legitimatePropertyLease-english-fast":
        case "legitimatePropertyLease-english-fast-manual":
        case "legitimatePropertyLease-english-fast-auction-manual-qualification":
        case "legitimatePropertyLease-english-initial-auction":
        case "legitimatePropertyLease-english-initial-auction-manual":
        case "legitimatePropertyLease-english-initial-qualification":
        case "legitimatePropertyLease-english-initial-qualification-prod":
        case "legitimatePropertyLease-dutch":
        case "legitimatePropertyLease-dutch-fast":
        case "legitimatePropertyLease-dutch-fast-manual":
        case "legitimatePropertyLease-dutch-fast-auction-manual-qualification":
        case "legitimatePropertyLease-dutch-initial-auction":
        case "legitimatePropertyLease-dutch-initial-auction-manual":
        case "legitimatePropertyLease-dutch-initial-qualification-prod":
        case "legitimatePropertyLease-priorityEnglish":
        case "legitimatePropertyLease-priorityEnglish-fast":
        case "legitimatePropertyLease-priorityEnglish-fast-manual":
        case "legitimatePropertyLease-priorityEnglish-fast-auction-manual-qualification":
        case "legitimatePropertyLease-priorityEnglish-initial-auction":
        case "legitimatePropertyLease-priorityEnglish-initial-auction-manual":
        case "legitimatePropertyLease-priorityEnglish-initial-qualification":
        case "legitimatePropertyLease-priorityEnglish-initial-qualification-prod":
            return props.auctionsMy.minNumberOfQualifiedBids
        default:
            return 1
    }
}

///////////////////////////////////////////////////////
//КЛОНИРОВАНИЕ ИТЕМОВ
//////////////////////////////////////////////////////
async function createItemZU(props, item, auctionId){
    if(item.itemType === 'otherProperty'){
        createItemZUOtherPropertyItem(props, item, auctionId)
    }else if(item.itemType === 'jointPropertyComplex'){
        createItemZUJointPropertyComplexItem(props, item, auctionId)
    }else if(item.itemType === 'vehicle'){
        createItemZUVehicleItem(props, item, auctionId)
    }else if(item.itemType === 'realEstate'){
        createItemZURealEstateItem(props, item, auctionId)
    }
}
async function createItemZUOtherPropertyItem(props, item, auctionId){
    if(props.userProfiles !== null){
        if(props.userProfiles.organization.address.id !== null){
            let body = {}
            body.title = item.title
            body.description = item.description
            body.classificationId = Number(item.classification.id)
            body.additionalClassificationIds = item.additionalClassification
            body.addressId = Number(item.address.id)
            if(item.location !== null){
                body.location = {
                    latitude: Number(item.location.latitude),
                    longitude: Number(item.location.longitude),
                    //elevation: item.location.elevation
                }
            }
            body.itemType = item.itemType
            body.quantity = item.quantity
            body.unitCode = item.unit.code
            const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${auctionId}/item`;
            const response = await fetch(endPoint, {
                method: "POST",
                body: JSON.stringify(body),
                headers: {
                    'Authorization': props.token.access_token,
                    'Content-Type': 'application/json'
                }
            }).catch(console.error)
            if(response === undefined || response === 'undefined'){
            }else{
                const json1 = await response.json()
                if(json1.hasOwnProperty('message') === true){
                    props.changeLoader(false)
                }else{
                    if(item.registrationDetails !== null){
                        await createRegDetal(props, auctionId, json1.id, item)
                    }
                }
            }
        }
    }
}

async function createItemZUVehicleItem(props, item, auctionId){
    if(props.userProfiles !== null){
        if(props.userProfiles.organization.address.id !== null){
            let body = {}
            body.title = item.title
            body.description = item.description
            body.classificationId = Number(item.classification.id)
            body.additionalClassificationIds = item.additionalClassification
            body.addressId = Number(item.address.id)
            if(item.location !== null){
                body.location = {
                    latitude: Number(item.location.latitude),
                    longitude: Number(item.location.longitude),
                    //elevation: item.location.elevation
                }
            }
            body.itemType = item.itemType
            body.quantity = item.quantity
            body.unitCode = item.unit.code
            const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${auctionId}/item`;
            const response = await fetch(endPoint, {
                method: "POST",
                body: JSON.stringify(body),
                headers: {
                    'Authorization': props.token.access_token,
                    'Content-Type': 'application/json'
                }
            }).catch(console.error)
            if(response === undefined || response === 'undefined'){
            }else{
                const json = await response.json()
                if(item.registrationDetails !== null){
                    await createRegDetal(props, auctionId, json.id, item)
                }
                if(item.vehicleProps !== null){
                    await saveItemZuVehicleProps(props, item.vehicleProps, auctionId, json.id)
                }
            }
        }
    }
}
async function saveItemZuVehicleProps(props, item, auctionId, itemId){
    let body = {}
    body = {
        brand: item.brand,
        model: item.model,
        productionYear: item.productionYear,
        engineCapacity: item.engineCapacity,
        fuelType: item.fuelType,
        transmission: item.transmission,
        color: item.color,
        kilometrage: item.kilometrage,
        identificationNumber: item.identificationNumber,
        configuration: item.configuration,
        condition: item.condition,
        damagedDescription: item.damagedDescription,
        damagePresence: item.damagePresence,
    }
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${auctionId}/item/${itemId}/property_props/VehicleProps`;
    const response = await fetch(endPoint, {
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        /*let header = {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
        const endPoint2t = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${auctionId}`;
        props.changeLoader(true)
        const response2t = await fetch(endPoint2t, {
                method: "GET",
                headers: header
        }).catch(console.error)
        if(response2t === undefined || response2t === 'undefined'){
        }else{
            const json2t = await response2t.json()
            if(json2t.hasOwnProperty('message') === true){
                props.changeLoader(false)
            }else{
                props.getMyAuction(json2t)
                props.changeLoader(false)
            }
        }*/
    }
}

async function createItemZURealEstateItem(props, item, auctionId){
    if(props.userProfiles !== null){
        if(props.userProfiles.organization.address.id !== null){
            let body = {}
            body.title = item.title
            body.description = item.description
            body.classificationId = Number(item.classification.id)
            body.additionalClassificationIds = item.additionalClassification
            body.addressId = Number(item.address.id)
            if(item.location !== null){
                body.location = {
                    latitude: Number(item.location.latitude),
                    longitude: Number(item.location.longitude),
                    //elevation: item.location.elevation
                }
            }
            body.itemType = item.itemType
            body.unitCode = item.unit.code
            const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${auctionId}/item`;
            const response = await fetch(endPoint, {
                method: "POST",
                body: JSON.stringify(body),
                headers: {
                    'Authorization': props.token.access_token,
                    'Content-Type': 'application/json'
                }
            }).catch(console.error)
            if(response === undefined || response === 'undefined'){
            }else{
                const json = await response.json()
                if(item.registrationDetails !== null){
                    await createRegDetal(props, auctionId, json.id, item)
                }
                if(item.realEstateProps !== null){
                    await saveItemZuReProps(props, item.realEstateProps, auctionId, json.id)
                }
            }
        }
    }
}
async function saveItemZuReProps(props, item, auctionId, itemId){
    let body = {}
    body = {
        totalBuildingArea: item.totalBuildingArea,
        totalObjectArea: item.totalObjectArea,
        usableArea: item.usableArea,
        constructionYear: item.constructionYear,
        constructionTechnology: item.constructionTechnology,
        livingArea: item.livingArea,
        kitchenArea: item.kitchenArea,
        landArea: item.landArea,
        locationInBuilding: item.locationInBuilding,
        floors: item.floors,
        generalCondition: item.generalCondition,
        serviceElectricity: item.serviceElectricity,
        powerSupplyCapacity: item.powerSupplyCapacity,
        powerSupplyClass: item.powerSupplyClass,
        serviceWater: item.serviceWater,
        serviceSewerage: item.serviceSewerage,
        serviceGas: item.serviceGas,
        serviceCentralHeating: item.serviceCentralHeating,
        serviceAutonomousHeating: item.serviceAutonomousHeating,
        serviceHeatingCounter: item.serviceHeatingCounter,
        serviceVentilation: item.serviceVentilation,
        serviceAirConditioning: item.serviceAirConditioning,
        servicePhone: item.servicePhone,
        serviceTV: item.serviceTV,
        serviceInternet: item.serviceInternet,
        serviceElevator: item.serviceElevator,
        serviceSecurityAlarm: item.serviceSecurityAlarm,
        serviceFireAlarm: item.serviceFireAlarm,
        servicesDescription: item.servicesDescription,
        servicesAccounting: item.servicesAccounting,
        landTax: item.landTax,
    }
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${auctionId}/item/${itemId}/property_props/RealEstateProps`;
    const response = await fetch(endPoint, {
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        /*let header = {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
        const endPoint2t = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${auctionId}`;
        props.changeLoader(true)
        const response2t = await fetch(endPoint2t, {
                method: "GET",
                headers: header
        }).catch(console.error)
        if(response2t === undefined || response2t === 'undefined'){
        }else{
            const json2t = await response2t.json()
            if(json2t.hasOwnProperty('message') === true){
                props.changeLoader(false)
            }else{
                props.getMyAuction(json2t)
                props.changeLoader(false)
            }
        }*/
    }
}

async function createItemZUJointPropertyComplexItem(props, item, auctionId){
    if(props.userProfiles !== null){
        if(props.userProfiles.organization.address.id !== null){
            let body = {}
            body.title = item.title
            body.description = item.description
            body.classificationId = Number(item.classification.id)
            body.additionalClassificationIds = item.additionalClassification
            body.addressId = Number(item.address.id)
            if(item.location !== null){
                body.location = {
                    latitude: Number(item.location.latitude),
                    longitude: Number(item.location.longitude),
                    //elevation: item.location.elevation
                }
            }
            body.itemType = item.itemType
            body.quantity = item.quantity
            body.unitCode = item.unit.code
            const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${auctionId}/item`;
            const response = await fetch(endPoint, {
                method: "POST",
                body: JSON.stringify(body),
                headers: {
                    'Authorization': props.token.access_token,
                    'Content-Type': 'application/json'
                }
            }).catch(console.error)
            if(response === undefined || response === 'undefined'){
            }else{
                const json = await response.json()
                if(json.hasOwnProperty('message') === true){
                    let tmp = JSON.stringify({title: 'Помилка', message: JSON.stringify(json)})
                    props.setUniversalError(tmp)
                }
                if(item.registrationDetails !== null){
                    await createRegDetal(props, auctionId, json.id, item)
                }
                if(item.jointPropertyComplexProps !== null){
                    await saveItemZuJointPropertyComplexProps(props, item.jointPropertyComplexProps, auctionId, json.id)
                }
                //await saveItemZuJointPropertyComplexPropsLand(props, json.id, 'POST')
            }
        }
    }
}
async function saveItemZuJointPropertyComplexProps(props, item, auctionId, itemId){
    let body = {}
    body = {
        quantityAndNomenclature: item.quantityAndNomenclature,
        workplacesQuantity: item.workplacesQuantity,
        buildingsInformation: item.buildingsInformation,
        landPlotInformation: item.landPlotInformation,
        mainObligations: item.mainObligations
    }
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${auctionId}/item/${itemId}/property_props/JointPropertyComplexProps`;
    const response = await fetch(endPoint, {
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){
            let tmp = JSON.stringify({title: 'Помилка', message: JSON.stringify(json)})
	        props.setUniversalError(tmp)
        }
        if(item.landProps !== null){
            await saveItemZuJointPropertyComplexPropsLand(props, item.landProps, auctionId, itemId)
        }
    }
}
async function saveItemZuJointPropertyComplexPropsLand(props, item, auctionId, itemId){
    let body = {}
    body = {
        landArea: item.landArea,
        cadastralNumber: item.cadastralNumber,
        ownershipType: item.ownershipType,
        encumbrances: item.encumbrances,
        jointOwnership: item.jointOwnership,
        utilitiesAvailability: item.utilitiesAvailability,
        landIntendedUse: item.landIntendedUse
    }
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${auctionId}/item/${itemId}/property_props/JointPropertyComplexProps/land_props`;
    const response = await fetch(endPoint, {
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        /*let header = {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
        const endPoint2t = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${auctionId}`;
        props.changeLoader(true)
        const response2t = await fetch(endPoint2t, {
                method: "GET",
                headers: header
        }).catch(console.error)
        if(response2t === undefined || response2t === 'undefined'){
        }else{
            const json2t = await response2t.json()
            if(json2t.hasOwnProperty('message') === true){
                props.changeLoader(false)
            }else{
                props.getMyAuction(json2t)
                props.changeLoader(false)
            }
        }*/
    }
}

async function createRegDetal(props, auctionId, jsonId, item){
    if(props.userProfiles !== null){
        if(props.userProfiles.organization.address.id !== null){
            const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${auctionId}/item/${jsonId}/registration_details`;
            const response = await fetch(endPoint, {
                method: "POST",
                body: JSON.stringify({
                    registrationStatus: item.registrationDetails.registrationStatus,
                    registrationID: item.registrationDetails.registrationID,
                    registrationDate: item.registrationDetails.registrationDate,
                }),
                headers: {
                    'Authorization': props.token.access_token,
                    'Content-Type': 'application/json'
                }
            }).catch(console.error)
            if(response === undefined || response === 'undefined'){
            }else{
                //const json = await response.json()
            }
        }
    }
}
//////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////
async function createItemEnergy(props, item, auctionId){
    if(props.userProfiles !== null){
        if(props.userProfiles.organization.address.id !== null){
            const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${auctionId}/item`;
            const arrAddClass = [];
            let body = null;
            switch(props.auctionsMy.sellingMethod){
                case "timber-english":
                case "timber-english-fast":
                case "timber-english-fast-manual":
                case "timber-english-initial-auction":
                case "timber-english-initial-qualification":
                case "timber-english-initial-qualification-fast":
                    if(item.additionalClassifications !== null){
                        if(item.additionalClassifications.length > 0){
                            for(let tt = 0; tt < item.additionalClassifications.length; tt++){
                                arrAddClass[tt] = {scheme: item.additionalClassifications[tt].scheme, classificationId: item.additionalClassifications[tt].classificationId}
                            }
                        }
                    }
                    body = JSON.stringify({
                        classificationId: Number(item.classification.id),
                        additionalClassifications: arrAddClass,
                        addressId: props.userProfiles.organization.address.id,
                        description: item.description,
                        unitCode: item.unit.code,
                        quantity: `${item.quantity}`,
                        unitValue: {
                            currency: 'UAH',                                 //+            //Валюта
                            amount: item.unitValue.amount,      //+                               //Цена
                        },
                    })
                    break;
                case "renewables":
                case "renewables-fast":
                case "renewables-fast-manual":
                case "renewables-initial-auction":
                case "renewables-initial-qualification":
                case "renewables-initial-qualification-fast":
                case "subsoil-english":
                case "subsoil-english-fast":
                case "subsoil-english-fast-manual":
                case "subsoil-english-initial-auction":
                case "subsoil-english-initial-qualification":
                case "subsoil-dutch":
                case "subsoil-dutch-fast":
                case "subsoil-dutch-fast-manual":
                case "subsoil-dutch-initial-auction":
                case "subsoil-dutch-initial-qualification":
                case "subsoil-dutch-initial-auction-manual":
                case "subsoil-english-fast-auction-manual-qualification":
                case "railwayCargo-english":
                case "railwayCargo-english-fast":
                case "railwayCargo-english-fast-manual":
                case "railwayCargo-english-initial-auction":
                case "railwayCargo-english-initial-qualification":
                case "railwayCargo-dutch":
                case "railwayCargo-dutch-fast":
                case "railwayCargo-dutch-fast-manual":
                case "railwayCargo-dutch-initial-auction":
                case "railwayCargo-dutch-initial-qualification":
                case "railwayCargo-dutch-fast-auction-manual-qualification":
                case "railwayCargo-dutch-initial-auction-manual":
                case "dgf-english":
                case "dgf-english-fast":
                case "dgf-english-fast-manual":
                case "dgf-english-fast-auction-manual-qualification":
                case "dgf-english-initial-auction":
                case "dgf-english-initial-auction-manual":
                case "dgf-english-initial-qualification":
                case "dgf-dutch":
                case "dgf-dutch-fast":
                case "dgf-dutch-fast-manual":
                case "dgf-dutch-fast-auction-manual-qualification":
                case "dgf-dutch-initial-auction":
                case "dgf-dutch-initial-qualification":
                case "dgf-dutch-initial-auction-manual":
                case "legitimatePropertyLease-english":
                case "legitimatePropertyLease-english-fast":
                case "legitimatePropertyLease-english-fast-manual":
                case "legitimatePropertyLease-english-fast-auction-manual-qualification":
                case "legitimatePropertyLease-english-initial-auction":
                case "legitimatePropertyLease-english-initial-auction-manual":
                case "legitimatePropertyLease-english-initial-qualification":
                case "legitimatePropertyLease-english-initial-qualification-prod":
                case "legitimatePropertyLease-dutch":
                case "legitimatePropertyLease-dutch-fast":
                case "legitimatePropertyLease-dutch-fast-manual":
                case "legitimatePropertyLease-dutch-fast-auction-manual-qualification":
                case "legitimatePropertyLease-dutch-initial-auction":
                case "legitimatePropertyLease-dutch-initial-auction-manual":
                case "legitimatePropertyLease-dutch-initial-qualification-prod":
                case "legitimatePropertyLease-priorityEnglish":
                case "legitimatePropertyLease-priorityEnglish-fast":
                case "legitimatePropertyLease-priorityEnglish-fast-manual":
                case "legitimatePropertyLease-priorityEnglish-fast-auction-manual-qualification":
                case "legitimatePropertyLease-priorityEnglish-initial-auction":
                case "legitimatePropertyLease-priorityEnglish-initial-auction-manual":
                case "legitimatePropertyLease-priorityEnglish-initial-qualification":
                case "legitimatePropertyLease-priorityEnglish-initial-qualification-prod":
                    if(item.additionalClassifications !== null){
                        if(item.additionalClassifications.length > 0){
                            for(let tt = 0; tt < item.additionalClassifications.length; tt++){
                                arrAddClass[tt] = Number(item.additionalClassifications[tt].id)
                            }
                        }
                    }

                    let tmpRequestLifeTimeStartDate = null
                    if(item.hasOwnProperty('requestLifeTime') === true){
                        if(item.requestLifeTime !== null){
                            if(item.requestLifeTime.hasOwnProperty('startDate') === true){
                                if(item.requestLifeTime.startDate !== null){
                                    tmpRequestLifeTimeStartDate = item.requestLifeTime.startDate
                                }
                            }
                        }
                    }
                    let tmpRequestLifeTimeEndDate = null
                    if(item.hasOwnProperty('requestLifeTime') === true){
                        if(item.requestLifeTime !== null){
                            if(item.requestLifeTime.hasOwnProperty('endDate') === true){
                                if(item.requestLifeTime.endDate !== null){
                                    tmpRequestLifeTimeEndDate = item.requestLifeTime.endDate
                                }
                            }
                        }
                    }
                    let tmpLoadLifeTimeStartDate = null
                    if(item.hasOwnProperty('loadLifeTime') === true){
                        if(item.loadLifeTime !== null){
                            if(item.loadLifeTime.hasOwnProperty('startDate') === true){
                                if(item.loadLifeTime.startDate !== null){
                                    tmpLoadLifeTimeStartDate = item.loadLifeTime.startDate
                                }
                            }
                        }
                    }
                    let tmpLoadLifeTimeEndDate = null
                    if(item.hasOwnProperty('loadLifeTime') === true){
                        if(item.loadLifeTime !== null){
                            if(item.loadLifeTime.hasOwnProperty('endDate') === true){
                                if(item.loadLifeTime.endDate !== null){
                                    tmpLoadLifeTimeEndDate = item.loadLifeTime.endDate
                                }
                            }
                        }
                    }


                    body = JSON.stringify({
                        classificationId: Number(item.classification.id),
                        additionalClassificationIds: arrAddClass,
                        addressId: props.userProfiles.organization.address.id,
                        description: item.description,
                        unitCode: item.unit.code,
                        quantity: `${item.quantity}`,
                        requestLifeTime: {
                            startDate: tmpRequestLifeTimeStartDate,
                            endDate: tmpRequestLifeTimeEndDate
                        },
                        loadLifeTime: {
                            startDate: tmpLoadLifeTimeStartDate,
                            endDate: tmpLoadLifeTimeEndDate
                        },
                        routeQuantity: item.routeQuantity,
                        routesAccountingRouteNumber: item.routesAccountingRouteNumber,
                        routesAccountingWagonsPerRouteQuantity: item.routesAccountingWagonsPerRouteQuantity,
                        wagonTypes: item.wagonTypes,
                        wagonSpecies: item.wagonSpecies,
                        loadingRestriction: item.loadingRestriction,

                    })
                    break;
                default:
                    break;
            }
            
            const response = await fetch(endPoint, {
                method: "post",
                body: body,
                headers: {
                    'Authorization': props.token.access_token,
                    'Content-Type': 'application/json'
                }
            })
            .catch(console.error)
            if(response === undefined || response === 'undefined'){
            }else{
                const json = await response.json()
                if(json.hasOwnProperty('message') === true){
                    let tmp = JSON.stringify({title: 'Помилка при копіюванні лоту аукціону ', message: JSON.stringify(json)})
                    props.setUniversalError(tmp)

                }else{
                    if(item.auctionRestriction !== null){
                        if(item.auctionRestriction.length > 0){
                            for(let mi = 0; mi < item.auctionRestriction.length; mi++){
                                createRestrictLoadUnload(props, auctionId, json.id, item.auctionRestriction[mi])
                            }
                        }
                    }
                }
            }
        }
    }
} 

async function createRestrictLoadUnload(props, auctionId, itemId, item){
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${auctionId}/item/${itemId}/restriction`
    const response = await fetch(endPoint, {
        method: "POST",
        body: JSON.stringify({
            loadObject: item.loadObject,
            loadObjectCode: item.loadObjectCode,
            loadObjectName: item.loadObjectName,
            unloadObject: item.unloadObject,
            unloadObjectCode: item.unloadObjectCode,
            unloadObjectName: item.unloadObjectName
        }),
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
}

async function editAuc(props, aucId=null){
    let endPoint = null;
    if(aucId === null){
        endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${props.auctionsMy.id}`
    }else{
        endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${aucId}`
    }
    let tmpDocumentationValue = null;
    let tmpGeologivDataValue = null;
    let tmpGuarantee = null;
    let tmpMinStep = null;
    let tmpValue = null;
    switch(props.auctionsMy.sellingMethod){
        case "renewables":
        case "renewables-fast":
        case "renewables-fast-manual":
        case "renewables-initial-auction":
        case "renewables-initial-qualification":
        case "renewables-initial-qualification-fast":
            tmpGuarantee = {
                currency: 'EUR',
                amount: Number(props.auctionsMy.guarantee.amount),
            }
            tmpMinStep = {
                currency: 'EUR',
                amount: Number(props.auctionsMy.minimalStep.amount),
            }
            tmpValue = {
                currency: 'eurocent',
                amount: Number(props.auctionsMy.value.amount),
            }
            break;
        case "subsoil-english":
        case "subsoil-english-fast":
        case "subsoil-english-fast-manual":
        case "subsoil-english-initial-auction":
        case "subsoil-english-initial-qualification":
        case "subsoil-dutch":
        case "subsoil-dutch-fast":
        case "subsoil-dutch-fast-manual":
        case "subsoil-dutch-initial-auction":
        case "subsoil-dutch-initial-qualification":
        case "subsoil-dutch-initial-auction-manual":
        case "subsoil-english-fast-auction-manual-qualification":
            tmpDocumentationValue = {currency: 'UAH', amount: props.auctionsMy.documentationValue.amount}
            tmpGeologivDataValue = {currency: 'UAH', amount: Number(props.auctionsMy.geologicDataValue.amount)}
            tmpGuarantee = {
                currency: 'UAH',
                amount: Number(props.auctionsMy.guarantee.amount),
            }
            tmpMinStep = {
                currency: 'UAH',
                amount: Number(props.auctionsMy.minimalStep.amount),
            }
            tmpValue = {
                currency: 'UAH',
                amount: Number(props.auctionsMy.value.amount),
            }
            break;
        case "timber-english":
        case "timber-english-fast":
        case "timber-english-fast-manual":
        case "timber-english-initial-auction":
        case "timber-english-initial-qualification":
        case "timber-english-initial-qualification-fast":
        case "railwayCargo-english":
        case "railwayCargo-english-fast":
        case "railwayCargo-english-fast-manual":
        case "railwayCargo-english-initial-auction":
        case "railwayCargo-english-initial-qualification":
        case "railwayCargo-dutch":
        case "railwayCargo-dutch-fast":
        case "railwayCargo-dutch-fast-manual":
        case "railwayCargo-dutch-initial-auction":
        case "railwayCargo-dutch-initial-qualification":
        case "railwayCargo-dutch-fast-auction-manual-qualification":
        case "railwayCargo-dutch-initial-auction-manual":
        case "dgf-english":
        case "dgf-english-fast":
        case "dgf-english-fast-manual":
        case "dgf-english-fast-auction-manual-qualification":
        case "dgf-english-initial-auction":
        case "dgf-english-initial-auction-manual":
        case "dgf-english-initial-qualification":
        case "dgf-dutch":
        case "dgf-dutch-fast":
        case "dgf-dutch-fast-manual":
        case "dgf-dutch-fast-auction-manual-qualification":
        case "dgf-dutch-initial-auction":
        case "dgf-dutch-initial-qualification":
        case "dgf-dutch-initial-auction-manual":
        case "legitimatePropertyLease-english":
        case "legitimatePropertyLease-english-fast":
        case "legitimatePropertyLease-english-fast-manual":
        case "legitimatePropertyLease-english-fast-auction-manual-qualification":
        case "legitimatePropertyLease-english-initial-auction":
        case "legitimatePropertyLease-english-initial-auction-manual":
        case "legitimatePropertyLease-english-initial-qualification":
        case "legitimatePropertyLease-english-initial-qualification-prod":
        case "legitimatePropertyLease-dutch":
        case "legitimatePropertyLease-dutch-fast":
        case "legitimatePropertyLease-dutch-fast-manual":
        case "legitimatePropertyLease-dutch-fast-auction-manual-qualification":
        case "legitimatePropertyLease-dutch-initial-auction":
        case "legitimatePropertyLease-dutch-initial-auction-manual":
        case "legitimatePropertyLease-dutch-initial-qualification-prod":
        case "legitimatePropertyLease-priorityEnglish":
        case "legitimatePropertyLease-priorityEnglish-fast":
        case "legitimatePropertyLease-priorityEnglish-fast-manual":
        case "legitimatePropertyLease-priorityEnglish-fast-auction-manual-qualification":
        case "legitimatePropertyLease-priorityEnglish-initial-auction":
        case "legitimatePropertyLease-priorityEnglish-initial-auction-manual":
        case "legitimatePropertyLease-priorityEnglish-initial-qualification":
        case "legitimatePropertyLease-priorityEnglish-initial-qualification-prod":
            tmpGuarantee = {
                currency: 'UAH',
                amount: Number(props.auctionsMy.guarantee.amount),
                //valueAddedTaxIncluded: props.auctionsMy.guarantee.valueAddedTaxIncluded,
            }
            tmpMinStep = {
                currency: 'UAH',
                amount: Number(props.auctionsMy.minimalStep.amount),
                //valueAddedTaxIncluded: props.auctionsMy.minimalStep.valueAddedTaxIncluded,
            }
            let tmpValueAddedTaxIncluded = null
            if(props.auctionsMy.value.valueAddedTaxIncluded === 'false'){
                tmpValueAddedTaxIncluded = false
            }else{
                tmpValueAddedTaxIncluded = true
            }
            tmpValue = {
                currency: 'UAH',
                amount: Number(props.auctionsMy.value.amount),
                valueAddedTaxIncluded: props.auctionsMy.value.valueAddedTaxIncluded,
            }
            break
        default:
            tmpGuarantee = {
                currency: 'UAH',
                amount: Number(props.auctionsMy.guarantee.amount),
            }
            tmpMinStep = {
                currency: 'UAH',
                amount: Number(props.auctionsMy.minimalStep.amount),
            }
            tmpValue = {
                currency: 'UAH',
                amount: Number(props.auctionsMy.value.amount),
            }
            break;
    }

    let tmpCarryType = null;
    if(
        props.auctionsMy.sellingMethod === "railwayCargo-english" ||
        props.auctionsMy.sellingMethod === "railwayCargo-english-fast" ||
        props.auctionsMy.sellingMethod === "railwayCargo-english-fast-manual" ||
        props.auctionsMy.sellingMethod === "railwayCargo-english-initial-auction" ||
        props.auctionsMy.sellingMethod === "railwayCargo-english-initial-qualification" ||
        props.auctionsMy.sellingMethod === "railwayCargo-dutch" ||
        props.auctionsMy.sellingMethod === "railwayCargo-dutch-fast" ||
        props.auctionsMy.sellingMethod === "railwayCargo-dutch-fast-manual" ||
        props.auctionsMy.sellingMethod === "railwayCargo-dutch-initial-auction" ||
        props.auctionsMy.sellingMethod === "railwayCargo-dutch-initial-qualification" ||
        props.auctionsMy.sellingMethod === "railwayCargo-dutch-fast-auction-manual-qualification" ||
        props.auctionsMy.sellingMethod === "railwayCargo-dutch-initial-auction-manual"
    ){
        if(props.auctionsMy.carryType !== null) {tmpCarryType = props.auctionsMy.carryType}
    }
    let tmpBank = null;
    if(props.bankAccountIdArr === null){
        tmpBank = []
    }else{
        tmpBank = props.bankAccountIdArr
    }
    let tmpDateDay = Date.parse(props.auctionsMy.startDate);
    let tmpMonth = null;
    if(new Date(tmpDateDay).getMonth() < 10){
        tmpMonth = '0' + (new Date(tmpDateDay).getMonth() + 1)
    }else{
        tmpMonth = new Date(tmpDateDay).getMonth() + 1
    }
    let tmpDay = null;
    if(new Date(tmpDateDay).getDate() < 10){
        tmpDay = '0' + new Date(tmpDateDay).getDate()
    }else{
        tmpDay = new Date(tmpDateDay).getDate()
    }

    let minNumberOfQualifiedBids = returnDataRailway(props)
    if(minNumberOfQualifiedBids === 'null') minNumberOfQualifiedBids = null
    let tmpDateString = new Date(tmpDateDay).getFullYear() + '-' + tmpMonth + '-' + tmpDay

    /////////////////////////////////////////////
    /////////////////////////////////////////////
    let tmpBody = null
    let tmpValueAddedTaxIncluded1 = null
    if(props.auctionsMy.value.valueAddedTaxIncluded === 'false'){
        tmpValueAddedTaxIncluded1 = false
    }else{
        tmpValueAddedTaxIncluded1 = true
    }
    if(Number(props.auctionsMy.tenderAttempts) !== 1){
        tmpBody = {}
        if(
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-manual" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-auction-manual-qualification" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction-manual" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification-prod"
        ){
            if(props.auctionsMy.currentTenantGuaranteeFee.amount !== ''){
                tmpBody.currentTenantGuaranteeFee = {
                    currency: 'UAH',
                    amount: Number(props.auctionsMy.currentTenantGuaranteeFee.amount)
                }
            }
        }
        if(aucId === null){
            tmpBody.documentationValue = props.auctionsMy.documentationValue
            tmpBody.geologicDataValue = props.auctionsMy.geologicDataValue
        }
        tmpBody.leaseDuration = props.auctionsMy.leaseDuration
        tmpBody.carryTypeId = tmpCarryType
        tmpBody.previousAuctionId = props.discount.previousAuctionId
        tmpBody.title = props.auctionsMy.title
        tmpBody.description = props.auctionsMy.description
        tmpBody.bankAccountIds = createArrBanks(props, 1, props.auctionsMy.id)
        tmpBody.sellingMethod = props.auctionsMy.sellingMethod
        tmpBody.tenderAttempts = props.auctionsMy.tenderAttempts
        if(props.auctionsMy.accessDetails !== null){
            tmpBody.accessDetails = props.auctionsMy.accessDetails
        }
        if(
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-english" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-fast" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-fast-manual" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-fast-auction-manual-qualification" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-auction" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-auction-manual" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-qualification" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-qualification-prod" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-fast" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-fast-manual" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-fast-auction-manual-qualification" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-initial-auction" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-initial-auction-manual" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-initial-qualification-prod" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-manual" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-auction-manual-qualification" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction-manual" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification-prod"
        ){
            //tmpBody.xDocumentRequirements = props.auctionsMy.xDocumentRequirements
        }else{
            tmpBody.lotIdentifier = props.auctionsMy.lotIdentifier
            tmpBody.xDocumentRequirements = props.auctionsMy.xDocumentRequirements
        }
        if(props.auctionsMy.xAdditionalInformation !== null){
            tmpBody.xAdditionalInformation = props.auctionsMy.xAdditionalInformation
        }
        tmpBody.value = {
            currency: 'UAH',//props.auctionsMy.value.currency,
            amount: Number(props.auctionsMy.value.amount),
            valueAddedTaxIncluded: tmpValueAddedTaxIncluded1,
        }
        tmpBody.guarantee = {
                currency: 'UAH',//props.auctionsMy.guarantee.currency,
                amount: Number(props.auctionsMy.guarantee.amount),
                //valueAddedTaxIncluded: props.auctionsMy.guarantee.valueAddedTaxIncluded,
        }
        tmpBody.minNumberOfQualifiedBids = minNumberOfQualifiedBids
        tmpBody.minimalStep = {
            currency: 'UAH',//props.auctionsMy.minimalStep.currency,
            amount: Number(props.auctionsMy.minimalStep.amount),
            //valueAddedTaxIncluded: props.auctionsMy.minimalStep.valueAddedTaxIncluded,
        }
        tmpBody.startDate = props.auctionsMy.startDate
        tmpBody.documents = props.auctionsMy.documents

    }else{
        if(props.auctionsMy.status !== null){
            tmpBody = {}
            if(aucId === null){
                tmpBody.documentationValue = props.auctionsMy.documentationValue
                tmpBody.geologicDataValue = props.auctionsMy.geologicDataValue
            }
            tmpBody.leaseDuration = props.auctionsMy.leaseDuration
            tmpBody.carryTypeId = tmpCarryType
            tmpBody.title = props.auctionsMy.title
            tmpBody.description = props.auctionsMy.description
            tmpBody.bankAccountIds = createArrBanks(props, 1, props.auctionsMy.id)
            tmpBody.sellingMethod = props.auctionsMy.sellingMethod
            tmpBody.tenderAttempts = props.auctionsMy.tenderAttempts
            if(props.auctionsMy.accessDetails !== null){
                tmpBody.accessDetails = props.auctionsMy.accessDetails
            }
            if(
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-english" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-fast" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-fast-manual" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-fast-auction-manual-qualification" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-auction" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-auction-manual" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-qualification" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-qualification-prod" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-fast" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-fast-manual" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-fast-auction-manual-qualification" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-initial-auction" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-initial-auction-manual" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-initial-qualification-prod" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-manual" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-auction-manual-qualification" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction-manual" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification-prod"
            ){}else{
                tmpBody.lotIdentifier = props.auctionsMy.lotIdentifier
                tmpBody.xDocumentRequirements = props.auctionsMy.xDocumentRequirements
            }
            if(props.auctionsMy.xAdditionalInformation !== null){
                tmpBody.xAdditionalInformation = props.auctionsMy.xAdditionalInformation
            }
            tmpBody.value = {
                currency: 'UAH',//props.auctionsMy.value.currency,
                amount: Number(props.auctionsMy.value.amount),
                valueAddedTaxIncluded: tmpValueAddedTaxIncluded1,
            }
            tmpBody.guarantee = {
                currency: 'UAH',//props.auctionsMy.guarantee.currency,
                amount: Number(props.auctionsMy.guarantee.amount),
            }
            tmpBody.minNumberOfQualifiedBids = minNumberOfQualifiedBids
            tmpBody.minimalStep = {
                currency: 'UAH',//props.auctionsMy.minimalStep.currency,
                amount: Number(props.auctionsMy.minimalStep.amount),
            }
            tmpBody.startDate = props.auctionsMy.startDate
            tmpBody.documents = props.auctionsMy.documents
        }else{
            tmpBody = {}
            if(aucId === null){
                tmpBody.documentationValue = props.auctionsMy.documentationValue
                tmpBody.geologicDataValue = props.auctionsMy.geologicDataValue
            }
            tmpBody.leaseDuration = props.auctionsMy.leaseDuration
            tmpBody.carryTypeId = tmpCarryType
            tmpBody.previousAuctionId = null
            tmpBody.title = props.auctionsMy.title
            tmpBody.description = props.auctionsMy.description
            tmpBody.bankAccountIds = createArrBanks(props, 1, props.auctionsMy.id)
            tmpBody.sellingMethod = props.auctionsMy.sellingMethod
            tmpBody.tenderAttempts = props.auctionsMy.tenderAttempts
            if(props.auctionsMy.accessDetails !== null){
                tmpBody.accessDetails = props.auctionsMy.accessDetails
            }
            if(
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-english" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-fast" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-fast-manual" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-fast-auction-manual-qualification" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-auction" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-auction-manual" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-qualification" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-qualification-prod" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-fast" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-fast-manual" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-fast-auction-manual-qualification" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-initial-auction" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-initial-auction-manual" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-initial-qualification-prod" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-manual" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-auction-manual-qualification" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction-manual" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification-prod"
            ){}else{
                tmpBody.lotIdentifier = props.auctionsMy.lotIdentifier
                tmpBody.xDocumentRequirements = props.auctionsMy.xDocumentRequirements
            }
            if(props.auctionsMy.xAdditionalInformation !== null){
                tmpBody.xAdditionalInformation = props.auctionsMy.xAdditionalInformation
            }
            tmpBody.value = {
                currency: 'UAH',//props.auctionsMy.value.currency,
                amount: Number(props.auctionsMy.value.amount),
                valueAddedTaxIncluded: tmpValueAddedTaxIncluded1,
            }
            tmpBody.guarantee = {
                currency: 'UAH',//props.auctionsMy.guarantee.currency,
                amount: Number(props.auctionsMy.guarantee.amount),
            }
            tmpBody.minNumberOfQualifiedBids = minNumberOfQualifiedBids
            tmpBody.minimalStep = {
                currency: 'UAH',//props.auctionsMy.minimalStep.currency,
                amount: Number(props.auctionsMy.minimalStep.amount),
            }
            tmpBody.startDate = props.auctionsMy.startDate
            tmpBody.documents = props.auctionsMy.documents
        }
    }
    /////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////
    if(aucId === null){
        if(Number(props.auctionsMy.tenderAttempts) === 1){
            if(props.auctionsMy.discount !== null){
                await deleteDiscount(props, props.auctionsMy.id)
            }
        }
    }

    switch(props.auctionsMy.sellingMethod){
        case "dgf-english":
        case "dgf-english-fast":
        case "dgf-english-fast-manual":
        case "dgf-english-fast-auction-manual-qualification":
        case "dgf-english-initial-auction":
        case "dgf-english-initial-auction-manual":
        case "dgf-english-initial-qualification":
        case "dgf-dutch":
        case "dgf-dutch-fast":
        case "dgf-dutch-fast-manual":
        case "dgf-dutch-fast-auction-manual-qualification":
        case "dgf-dutch-initial-auction":
        case "dgf-dutch-initial-qualification":
        case "dgf-dutch-initial-auction-manual":
            tmpBody.minNumberOfQualifiedBids = Number(props.auctionsMy.minNumberOfQualifiedBids)
            tmpBody.virtualDataRoom = props.auctionsMy.virtualDataRoom
            tmpBody.eligibilityCriteria = props.auctionsMy.eligibilityCriteria
            tmpBody.decision = {
                decisionId: props.auctionsMy.decision.decisionId,
                decisionDate: props.auctionsMy.decision.decisionDate
            }
            tmpBody.x_dgfPlatformLegalDetails = props.auctionsMy.x_dgfPlatformLegalDetails
            break;
        case "legitimatePropertyLease-english":
        case "legitimatePropertyLease-english-fast":
        case "legitimatePropertyLease-english-fast-manual":
        case "legitimatePropertyLease-english-fast-auction-manual-qualification":
        case "legitimatePropertyLease-english-initial-auction":
        case "legitimatePropertyLease-english-initial-auction-manual":
        case "legitimatePropertyLease-english-initial-qualification":
        case "legitimatePropertyLease-english-initial-qualification-prod":
        case "legitimatePropertyLease-dutch":
        case "legitimatePropertyLease-dutch-fast":
        case "legitimatePropertyLease-dutch-fast-manual":
        case "legitimatePropertyLease-dutch-fast-auction-manual-qualification":
        case "legitimatePropertyLease-dutch-initial-auction":
        case "legitimatePropertyLease-dutch-initial-auction-manual":
        case "legitimatePropertyLease-dutch-initial-qualification-prod":
        case "legitimatePropertyLease-priorityEnglish":
        case "legitimatePropertyLease-priorityEnglish-fast":
        case "legitimatePropertyLease-priorityEnglish-fast-manual":
        case "legitimatePropertyLease-priorityEnglish-fast-auction-manual-qualification":
        case "legitimatePropertyLease-priorityEnglish-initial-auction":
        case "legitimatePropertyLease-priorityEnglish-initial-auction-manual":
        case "legitimatePropertyLease-priorityEnglish-initial-qualification":
        case "legitimatePropertyLease-priorityEnglish-initial-qualification-prod":
            if(Number(props.auctionsMy.minNumberOfQualifiedBids) === 1 || Number(props.auctionsMy.minNumberOfQualifiedBids) === 2){
                tmpBody.minNumberOfQualifiedBids = Number(props.auctionsMy.minNumberOfQualifiedBids)
            }else{
                tmpBody.minNumberOfQualifiedBids = 1
            }
            tmpBody.calcOnBookValue = props.auctionsMy.calcOnBookValue
            tmpBody.valueAddedTaxCharged = props.auctionsMy.valueAddedTaxCharged
            tmpBody.registryId = props.auctionsMy.registryId
            if(props.auctionsMy.registrationFee.valueAddedTaxIncluded === true || props.auctionsMy.registrationFee.valueAddedTaxIncluded === 'true'){
                tmpBody.registrationFee = {
                    currency: 'UAH',
                    amount: Number(props.auctionsMy.registrationFee.amount),
                    valueAddedTaxIncluded: true
                }
            }else if(props.auctionsMy.registrationFee.valueAddedTaxIncluded === false || props.auctionsMy.registrationFee.valueAddedTaxIncluded === 'false'){
                tmpBody.registrationFee = {
                    currency: 'UAH',
                    amount: Number(props.auctionsMy.registrationFee.amount),
                    valueAddedTaxIncluded: false
                }
            }else{
                tmpBody.registrationFee = {
                    currency: 'UAH',
                    amount: Number(props.auctionsMy.registrationFee.amount),
                }
            }
            tmpBody.valuePeriod = props.auctionsMy.valuePeriod
            break;
        default:
            break;
    }

    if(
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-auction-manual-qualification" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification-prod"
    ){
        if(props.auctionsMy.currentTenantGuaranteeFee.amount !== ''){
            tmpBody.currentTenantGuaranteeFee = {
                currency: 'UAH',
                amount: Number(props.auctionsMy.currentTenantGuaranteeFee.amount)
            }
        }
    }

    const response = await fetch(endPoint, {
        method: "PUT",

        //timberEnglish
        /*body: JSON.stringify({
            documentationValue: tmpDocumentationValue,
            geologicDataValue: tmpGeologivDataValue,
            leaseDuration: props.auctionsMy.leaseDuration,
            carryTypeId: tmpCarryType,
            lotIdentifier: props.auctionsMy.lotIdentifier,
            title: props.auctionsMy.title,
            description: props.auctionsMy.description,
            bankAccountIds: createArrBanks(props),//tmpBank,
            sellingMethod: props.auctionsMy.sellingMethod,
            tenderAttempts: props.auctionsMy.tenderAttempts,
            accessDetails: props.auctionsMy.accessDetails,
            xDocumentRequirements: props.auctionsMy.xDocumentRequirements,
            xAdditionalInformation: props.auctionsMy.xAdditionalInformation,
            value: tmpValue,
            guarantee: tmpGuarantee,
            minimalStep: tmpMinStep,minNumberOfQualifiedBids
            minNumberOfQualifiedBids: ,
            startDate: props.auctionsMy.startDate,
            //startDate: tmpDateString,
            documents: props.auctionsMy.documents,
        }),*/
        body: JSON.stringify(tmpBody),
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){
            let tmp = JSON.stringify({title: 'Помилка при редагуванні аукціону', message: JSON.stringify(json)})
            props.setUniversalError(tmp)
        }else{
            if(
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-english" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-fast" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-fast-manual" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-fast-auction-manual-qualification" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-auction" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-auction-manual" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-qualification" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-qualification-prod" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-fast" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-fast-manual" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-fast-auction-manual-qualification" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-initial-auction" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-initial-auction-manual" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-initial-qualification-prod" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-manual" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-auction-manual-qualification" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction-manual" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification-prod"
            ){
                if(aucId === null){
                    createArrBanks(props, 2, props.auctionsMy.id)
                    createAuctionLeaseRules(props, json.id)
                }else{
                    createArrBanks(props, 2, aucId)
                    createAuctionLeaseRules(props, json.id)
                }
            }
            if(aucId === null){
                if(props.discount.discountOnOff === true){
                    if(props.discount.id === null){
                        createDiscountForAuction(props, props.auctionsMy.id)
                    }else{
                        editDiscountForAuction(props, props.auctionsMy.id)
                    }
                }
            }else{
                if(props.discount.discountOnOff === true){
                    if(props.discount.id === null){
                        createDiscountForAuction(props, aucId)
                    }else{
                        editDiscountForAuction(props, aucId)
                    }
                }
            }
            if(dutchData(props) !== false){
                let dutchStep = {
                    dutchStepQuantity: props.auctionsMy.dutchStep.dutchStepQuantity,
                    dutchStepPercent: props.auctionsMy.dutchStep.dutchStepPercent,
                    dutchStepValue: {
                        currency: 'UAH',
                        amount: props.auctionsMy.dutchStep.dutchStepValue.amount,
                        //valueAddedTaxIncluded: null,
                    },
                }
                const endPoint3 = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${json.id}/dutch_step`
                const response3 = await fetch(endPoint3, {
                    method: "PUT",
                    body: JSON.stringify(dutchStep),
                    headers: {
                        'Authorization': props.token.access_token,
                        'Content-Type': 'application/json'
                    }
                }).catch(console.error)
                if(response3 === undefined || response3 === 'undefined'){
                }else{
                    const json3 = await response3.json()
                    if(json3.hasOwnProperty('message') === true){
                        let tmp = JSON.stringify({title: 'Помилка при редагуванні аукціону', message: JSON.stringify(json3)})
                        props.setUniversalError(tmp)
                        props.setPopUpAuctionCreate(1)
                    }else{
                        let tmpAucData = json
                        tmpAucData.dutchStep = json3
                        props.setPopUpAuctionCreate(tmpAucData)
                    }
                }
            }else{
                //props.setPopUpAuctionCreate(2)

                props.setPopUpAuctionCreate(json)
            }
            /*props.changeDiscountPreviousAuctionCurrency('null')
            props.changeDiscountPreviousAuctionAmount('')
            props.changeDiscountPercent('')
            props.changeDiscountPreviousAuctionId('')*/
        }
    }
    //props.setBankAccountSave([])
    props.setBankAccountAdd([])
    props.setBankAccountDelete([])
    /*let data = {
        domen: process.env.REACT_APP_END_POINT_BD,
        version: 'v1',
        local: props.langFlag,
        params: '?limit=100'
    }
    let header = {
        'Authorization': props.token.access_token,
        'Content-Type': 'application/json'
    }
    props.auctionsGetMy(data, header)*/

    /*props.changeCreateAuctionLotIdentifier('')
    props.changeCreateAuctionTitle('')
    props.changeCreateAuctionDescription('')
    props.changeCreateAuctionTenderAttempts('')
    props.changeCreateAuctionAccessDetails('')
    props.changeCreateAuctionXDocumentRequirements('')
    props.changeCreateAuctionXAdditionalInformation('')
    props.changeCreateAuctionType('null')
    props.changeCreateAuctionValueCurrency('null')
    props.changeCreateAuctionValueAmount('')
    props.changeCreateAuctionGuaranteeCurrency('null')
    props.changeCreateAuctionGuaranteeAmount('')
    props.changeCreateAuctionMinimalStepCurrency('null')
    props.changeCreateAuctionMinimalStepAmount('')
    props.changeCreateAuctionDateStart('')
    props.setPopBankAccountId('null')
    props.setPopBankAccountIdArr(null)
    //const json = await response.json()
    props.changeDiscountPreviousAuctionCurrency('null')
    props.changeDiscountPreviousAuctionAmount('')
    props.changeDiscountPercent('')
    props.changeDiscountPreviousAuctionId('')*/
    props.changeLoader(false)
}
 
async function deleteDiscount(props, auctionId){
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${auctionId}/discount`
    const response = await fetch(endPoint, {
        method: "DELETE",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error) 
    if(response === undefined || response === 'undefined'){
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){
            let tmp = JSON.stringify({title: 'Помилка при видаленні знижки', message: JSON.stringify(json)})
            props.setUniversalError(tmp)
        }else{
        }
    }
}

function createArrBanks(props, type, aucId){
    if(
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-fast" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-fast-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-fast-auction-manual-qualification" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-auction" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-auction-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-qualification" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-qualification-prod" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-fast" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-fast-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-fast-auction-manual-qualification" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-initial-auction" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-initial-auction-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-initial-qualification-prod" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-auction-manual-qualification" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification-prod"
    ){
        if(type === 2){
            createZUBanks(props, aucId)
        }
    }else{
        let tmp = []
        for(let i = 0; i < props.auctionsMy.bankAccounts.length; i++){
            if(props.bankAccountWork.deleteBank.includes(String(props.auctionsMy.bankAccounts[i].id)) === false) tmp.push(props.auctionsMy.bankAccounts[i].id)
        }
        for(let i = 0; i < props.bankAccountWork.addNewBank.length; i++){
            tmp.push(Number(props.bankAccountWork.addNewBank[i]))
        }
        return tmp
    }
}

//
async function createAuctionLeaseRules(props, aucId){
    let body = {}
    body.intendedUseRestrictionMethod = props.auctionsMy.leaseRules.intendedUseRestrictionMethod
    body.intendedUseRestrictionDescription = props.auctionsMy.leaseRules.intendedUseRestrictionDescription
    body.additionalLeaseConditions = props.auctionsMy.leaseRules.additionalLeaseConditions
    body.subleaseAllowed = props.auctionsMy.leaseRules.subleaseAllowed
    if(props.auctionsMy.leaseRules !== null){
        if(props.auctionsMy.leaseRules.hasOwnProperty('intendedUseRestrictionMethod') === true){
            if(props.auctionsMy.leaseRules.intendedUseRestrictionMethod === 'exceptDescribed'){
                body.intendedUse = props.auctionsMy.leaseRules.intendedUse
            }
        }
    }
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${aucId}/lease_rules`
    const response = await fetch(endPoint, {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){
            let tmp = JSON.stringify({title: "Помилка при створенні Тип переліку, до якого віднесено об'єкт", message: JSON.stringify(json)})
            props.setUniversalError(tmp)
        }else{
            await createAuctionLeaseRulesShedule(props, aucId)
            //if(props.schedule.daysBy.value === '' || props.schedule.daysBy.value === null){
                //if(props.schedule.hoursBy.value === '' || props.schedule.hoursBy.value === null){
                    if(props.shedullePeriod.newFullPeriod.length > 0){
                        for(let it=0; it < props.shedullePeriod.newFullPeriod.length; it++){
                            await createAuctionLeaseRulesShedulePeriod(props, aucId, props.shedullePeriod.newFullPeriod[it])
                        }
                    }
                //}
            //}
            await createAuctionRelatedOrganizations(props, aucId)
        }
    }
}

async function createAuctionLeaseRulesShedule(props, aucId){
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${aucId}/lease_rules/schedule`
    let body = {}
    if(
        props.schedule.hoursBy.by !== 'null' &&
        props.schedule.hoursBy.by !== null &&
        props.schedule.hoursBy.value !== '' &&
        props.schedule.hoursBy.value !== null
    ){
        body.hoursBy = {
            value: props.schedule.hoursBy.value,
            by: props.schedule.hoursBy.by
        }
    }else{
        body.hoursBy = {}
    }
    if(
        props.schedule.daysBy.by !== 'null' &&
        props.schedule.daysBy.by !== null &&
        props.schedule.daysBy.value !== '' &&
        props.schedule.daysBy.value !== null
    ){
        body.daysBy = {
            value: props.schedule.daysBy.value,
            by: props.schedule.daysBy.by
        }
    }else{
        body.daysBy = {}
    }
    if(props.schedule.daysBy.value === '' || props.schedule.daysBy.value === null){
        if(props.schedule.hoursBy.value === '' || props.schedule.hoursBy.value === null){
            body.other = props.schedule.other
        }
    }
    const response = await fetch(endPoint, {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){
            let tmp = JSON.stringify({title: "Помилка при створенні розкладу", message: JSON.stringify(json)})
            props.setUniversalError(tmp)
        }
    }
}

//Периоды
async function createAuctionLeaseRulesShedulePeriod(props, aucId, data){
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${aucId}/lease_rules/schedule/period`
    let body = {}
    if(data.timeStart !== ''){
        let tmp = data.timeStart.split('/');
        body.startDate = tmp[2] + '-' + tmp[1] + '-' + tmp[0] + 'T00:00:00+00:00'
    }
    if(data.timeEnd !== ''){
        let tmp = data.timeEnd.split('/');
        body.endDate = tmp[2] + '-' + tmp[1] + '-' + tmp[0] + 'T00:00:00+00:00'
    }
    if(data.weekdays.length > 0){
        body.weekdays = data.weekdays
    }
    const response = await fetch(endPoint, {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){
            let tmp = JSON.stringify({title: "Помилка при створенні періодов", message: JSON.stringify(json)})
            props.setUniversalError(tmp)
        }
    }
    await props.setDataAddress([], 'SET_NEW_FULL_PERIOD_ARR')
}

async function createAuctionRelatedOrganizations(props, aucId){
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/auction/${aucId}/related_organizations`
    let body = {}
    if(props.auctionsMy.relatedOrganizations.propertyOwner !== null){
        if(props.auctionsMy.relatedOrganizations.propertyOwner.id !== 'null') body.propertyOwnerId = Number(props.auctionsMy.relatedOrganizations.propertyOwner.id)
    }
    if(props.auctionsMy.relatedOrganizations.sellingEntity !== null){
        if(props.auctionsMy.relatedOrganizations.sellingEntity.id !== 'null') body.sellingEntityId = Number(props.auctionsMy.relatedOrganizations.sellingEntity.id)
    }
    if(props.auctionsMy.relatedOrganizations.currentTenant !== null){
        if(props.auctionsMy.relatedOrganizations.currentTenant.id !== 'null') body.currentTenantId = Number(props.auctionsMy.relatedOrganizations.currentTenant.id)
    }
    if(props.auctionsMy.relatedOrganizations.ownershipType !== '') body.ownershipType = props.auctionsMy.relatedOrganizations.ownershipType
    const response = await fetch(endPoint, {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){
            let tmp = JSON.stringify({title: "Помилка при створенні relatedOrganizations", message: JSON.stringify(json)})
            props.setUniversalError(tmp)
        }
    }
}

async function createZUBanks(props, aucId){
    let tmpRregistrationFee = [], 
        tmpGuarantee = [],
        tmpSecurityDeposit = [],
        tmpImprovementCompensation = [],
        tmpOther = [],
        tmpAdvancePayment = [],
        tmpLease = []

    for(let i=0; i < props.bankAccountZU.length; i++){
        if(props.bankAccountZU[i].groupe === 'registrationFee'){
            tmpRregistrationFee.push(props.bankAccountZU[i])
        }else if(props.bankAccountZU[i].groupe === 'guarantee'){
            tmpGuarantee.push(props.bankAccountZU[i])
        }else if(props.bankAccountZU[i].groupe === 'securityDeposit'){
            tmpSecurityDeposit.push(props.bankAccountZU[i])
        }else if(props.bankAccountZU[i].groupe === 'improvementCompensation'){
            tmpImprovementCompensation.push(props.bankAccountZU[i])
        }else if(props.bankAccountZU[i].groupe === 'other'){
            tmpOther.push(props.bankAccountZU[i])
        }else if(props.bankAccountZU[i].groupe === 'advancePayment'){
            tmpAdvancePayment.push(props.bankAccountZU[i])
        }else if(props.bankAccountZU[i].groupe === 'lease'){
            tmpLease.push(props.bankAccountZU[i])
        }
    }
    if(tmpRregistrationFee.length > 0){
        let data = {}
        let tmp = []
        for(let i=0; i < tmpRregistrationFee.length; i++){
            tmp[i] = Number(tmpRregistrationFee[i].id)
        }
        data.id = tmp
        data.groupe = "registrationFee"
        createZUBanksPOST(props, data, aucId)
    }
    if(tmpGuarantee.length > 0){
        let data = {}
        let tmp = []
        for(let i=0; i < tmpGuarantee.length; i++){
            tmp[i] = Number(tmpGuarantee[i].id)
        }
        data.id = tmp
        data.groupe = "guarantee"
        createZUBanksPOST(props, data, aucId)
    }
    if(tmpSecurityDeposit.length > 0){
        let data = {}
        let tmp = []
        for(let i=0; i < tmpSecurityDeposit.length; i++){
            tmp[i] = Number(tmpSecurityDeposit[i].id)
        }
        data.id = tmp
        data.groupe = "securityDeposit"
        createZUBanksPOST(props, data, aucId)
    }
    if(tmpImprovementCompensation.length > 0){
        let data = {}
        let tmp = []
        for(let i=0; i < tmpImprovementCompensation.length; i++){
            tmp[i] = Number(tmpImprovementCompensation[i].id)
        }
        data.id = tmp
        data.groupe = "improvementCompensation"
        createZUBanksPOST(props, data, aucId)
    }
    if(tmpOther.length > 0){
        let data = {}
        let tmp = []
        for(let i=0; i < tmpOther.length; i++){
            tmp[i] = Number(tmpOther[i].id)
        }
        data.id = tmp
        data.groupe = "other"
        createZUBanksPOST(props, data, aucId)
    }
    if(tmpAdvancePayment.length > 0){
        let data = {}
        let tmp = []
        for(let i=0; i < tmpAdvancePayment.length; i++){
            //tmp[i] = Number(tmpAdvancePayment[i].id)
            data.id = Number(tmpAdvancePayment[i].id)
            data.groupe = "advancePayment"    
            if(tmpAdvancePayment[i].percent !== ''){
                data.percent = tmpAdvancePayment[i].percent
            }
            if(tmpAdvancePayment[i].holderType !== 'null'){
                data.holderType = tmpAdvancePayment[i].holderType
            }
            createZUBanksPOST(props, data, aucId)
        }
        /*data.id = tmp
        data.groupe = "advancePayment"
        if(tmpAdvancePayment[0].percent !== ''){
            data.percent = tmpAdvancePayment[0].percent
        }
        if(tmpAdvancePayment[0].holderType !== 'null'){
            data.holderType = tmpAdvancePayment[0].holderType
        }
        createZUBanksPOST(props, data, aucId)*/
    }
    if(tmpLease.length > 0){
        let data = {}
        let tmp = []
        for(let i=0; i < tmpLease.length; i++){
            //tmp[i] = Number(tmpLease[i].id)
            data.id = Number(tmpLease[i].id)
            data.groupe = "lease"
            if(tmpLease[i].percent !== ''){
                data.percent = tmpLease[i].percent
            }
            if(tmpLease[i].holderType !== 'null'){
                data.holderType = tmpLease[i].holderType
            }
            createZUBanksPOST(props, data, aucId)
        }
        /*data.id = tmp
        data.groupe = "lease"
        if(tmpLease[0].percent !== ''){
            data.percent = tmpLease[0].percent
        }
        if(tmpLease[0].holderType !== 'null'){
            data.holderType = tmpLease[0].holderType
        }
        createZUBanksPOST(props, data, aucId)*/
    }
}

async function createZUBanksPOST(props, data, aucId){
    let body = {}
    body.bankAccountIds = data.id
    if(
        data.groupe === 'advancePayment' ||
        data.groupe === 'lease'
    ){
        if(data.holderType !== 'null') body.holderType = data.holderType
        if(data.percent !== '') body.percent = data.percent
    }
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${aucId}/bank_account_groups/${data.groupe}`
    const response = await fetch(endPoint, {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){
            let tmp = JSON.stringify({title: "Помилка при створенні Тип переліку, до якого віднесено об'єкт", message: JSON.stringify(json)})
            props.setUniversalError(tmp)
        }else{
            await addArrayBankAccountsClear(props)
            await addBankAccountZUtimestamp(props)
        }
    }
}

async function createZUBanksPOSTOld(props, data, aucId){
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${aucId}/bank_account_groups/${data.group}/${data.gropType}/${data.type}`
    const response = await fetch(endPoint, {
        method: "POST",
        body: JSON.stringify({
            bankAccountIds: data.id
        }),
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){
            let tmp = JSON.stringify({title: "Помилка при створенні Тип переліку, до якого віднесено об'єкт", message: JSON.stringify(json)})
            props.setUniversalError(tmp)
        }else{
            await addArrayBankAccountsClear(props)
            await addBankAccountZUtimestamp(props)
        }
    }
}

async function addArrayBankAccountsClear(props){
    props.setDataAddress([], 'SET_POP_UP_BANK_ACCOUNT_ZU')
}
async function addBankAccountZUtimestamp(props){
    let tmp = new Date()
    props.setDataAddress(String(tmp), 'SET_POP_UP_BANK_ACCOUNT_ZU_TIMESTAMP')
}

async function createObject(props, objId){
    props.changeLoader(true)
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/auction/bridge/${objId}`
    const response = await fetch(endPoint, {
        method: "POST",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){
            //let tmp = JSON.stringify({title: "Помилка при створенні Тип переліку, до якого віднесено об'єкт", message: JSON.stringify(json)})
            //props.setUniversalError(tmp)
        }else{
            //Тут подключаю файл редактирования аука
            //getAucionFromId(props, json.id)
            await editAuc(props, json.id)
        }
    }
}

const mapStateToProps = state => {
    return {
        token: state.start.token,
        auctions: state.start.auctionsGet,
        langFlag: state.start.langFlag,
        createAuctionTab: state.start.createAuctionTab,
        createAuction: state.start.createAuction,
        auctionsSwitchWindow: state.start.auctionsSwitchWindow,
        endPoint: state.start.endPoint,
        classificationsGets: state.start.classificationsGet,
        auctionsMy: state.start.auctionsMy,
        bankAccount: state.start.bankAccount,
        bankAccountId: state.start.bankAccountId,
        bankAccountIdArr: state.start.bankAccountIdArr,
        discount: state.start.discount,
        userProfiles: state.start.userProfiles,
        carryType: state.start.carryType,
        bankAccountWork: state.start.bankAccountWork,
        addressNew: state.start.addressNew,
        bankAccountZU: state.start.bankAccountZU,
        createBankAccountZU: state.start.createBankAccountZU,
        schedule: state.start.schedule,
        auctionsZUAttemp: state.start.auctionsZUAttemp,
        shedullePeriod: state.start.shedullePeriod,
    }
}

const mapDispatchToProps = {
    changeCreateAuctionTitle,
    changeCreateAuctionDescription,

    changeCreateAuctionType,
    changeCreateAuctionTenderAttempts,
    changeCreateAuctionAccessDetails,
    changeCreateAuctionXDocumentRequirements,
    changeCreateAuctionXAdditionalInformation,
    changeCreateAuctionValueCurrency,
    changeCreateAuctionValueAmount,
    changeCreateAuctionValueTax,
    changeCreateAuctionGuaranteeCurrency,
    changeCreateAuctionGuaranteeAmount,
    changeCreateAuctionGuaranteeTax,
    changeCreateAuctionMinimalStepCurrency,
    changeCreateAuctionMinimalStepAmount,
    changeCreateAuctionMinimalStepTax,
    changeCreateAuctionDateStart,
    changeCreateAuctionDateEnd,

    changeAuctionsSwitchWindow,

    auctionsGetMy,
    getMyAuction,
    changeCreateAuctionLotIdentifier,

    changePopUpBankAccount,
    setPopBankAccountId,
    setPopBankAccountIdArr,
    changeCreateAuctionTab,

    setPopUpAuctionCreate,

    changeCreateItemGeologivDataValue,
    changeCreateItemDocumentationValue,
    changeCreateItemDateUse,
    setCarryType,

    setPopUpAuctionMyNull,

    changeDiscountPercent,
    changeDiscountPreviousAuctionCurrency,
    changeDiscountPreviousAuctionAmount,
    changeDiscountPreviousAuctionId,
    discountAuc,

    minNumberOfQualifiedBids,
    setUniversalError,

    setBankAccountSave,
    setBankAccountAdd,
    setBankAccountDelete,

    changeCreateAuctionMinNumberOfQualifiedBids,
    changeCreateAuctionDgfVirtualDataRoom,
    changeCreateAuctionDgfEligibilityCriteria,
    changeCreateAuctionDgfX_dgfPlatformLegalDetails,
    changeCreateAuctionDgfDecisionId,
    changeCreateAuctionDgfDecisionDate,
    setDataAddress,

    changeLoader,
    openBankWindows,
}

export default connect(mapStateToProps, mapDispatchToProps)(WindowAuction)