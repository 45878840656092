import React, {useEffect, useState} from 'react';
import styles from './renewables.module.css';

import {connect} from 'react-redux';

import 'date-fns';

import RenewablesItemProps from "./RenewablesItemProps";
import {setDataAddress} from '../../../../redux/createAddress';
import ReturnButton from "../../../createAuction/returnButton";
import Sidebar from "../../../createAuction/auctionEditNew/components/sidebar/sidebar";

const RenewablesItem = (props) => {

    const [generationType, setGenerationTypeList] = useState([]);
    const [currentItem, setCurrentItem] = useState((props.auctionsMy?.items !== null ||
        props.auctionsMy?.items.length > 0) ? props.auctionsMy?.items[0] : [])

    // props.changeCreateItemAuctionId(props.auctionsMy.id)

    //@todo move to api component
    useEffect( () => {
        async function getGenerationTypeList() {
            await fetch(`${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/public/dictionaries/generationType`)
                .then( (response) => {if (response.status == 200) return response.json()})
                .then( data => setGenerationTypeList(data))
        }
        if (generationType.length === 0) getGenerationTypeList()
    }, []);

    useEffect(() => {
        props.setDataAddress(currentItem?.description?.uk_UA, 'CREATE_ITEMS_DESCRIPTION')
        props.setDataAddress(currentItem?.classification, 'CREATE_ITEMS_CLASSIFICATION')
        props.setDataAddress(currentItem?.classification?.id, 'CREATE_ITEMS_CLASSIFICATION_ID')
        props.setDataAddress(currentItem?.itemProps?.techParams, 'CREATE_ITEMS_TECH_PARAMS')
        props.setDataAddress(currentItem?.itemProps?.timeSlots, 'CREATE_ITEMS_TIME_SLOTS')
        props.setDataAddress(currentItem?.itemProps?.loadProfiles, 'CREATE_ITEMS_LOAD_PROFILES')
        props.setDataAddress(currentItem?.quantity, 'CREATE_ITEMS_QUANTITY')
        props.setDataAddress(currentItem?.unitCode, 'CREATE_ITEMS_UNIT_CODE')
        props.setDataAddress(currentItem?.itemProps?.regions, 'CREATE_ITEMS_REGION')

    }, []);

    //@todo refactor - move section below to common item component
    return (
        <div className={styles.wra}>
            <div className={styles.mainWrap}>
                <Sidebar />
                <div className={styles.wrapBlock}>
                    <RenewablesItemProps data={currentItem}/>
                </div>
            </div>
            <div className={styles.bottonBlock}>
                <ReturnButton/>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        token: state.start.token,
        auctions: state.start.auctionsGet,
        langFlag: state.start.langFlag,
        createAuctionTab: state.start.createAuctionTab,
        createAuction: state.start.createAuction,
        createItems: state.start.createItems,
        auctionsMy: state.start.auctionsMy,
        classificationsGets: state.start.classificationsGet,
        unitsGet: state.start.unitsGet,
        userProfiles: state.start.userProfiles,
        classificationsEnergy: state.start.classificationsEnergy,
        getAuctionsMy: state.start.auctionsGetMy,
        setMask: state.start.setMask,
    }
}

const mapDispatchToProps = {
    setDataAddress,
}

export default connect(mapStateToProps, mapDispatchToProps)(RenewablesItem)