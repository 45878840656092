import React, {useState, useEffect} from 'react';
import styles from '../procedure.module.css'
import stylesInput from './inputAmount.module.css'
import stylesInputCheck from './checkBox.module.css'

import {useSelector} from 'react-redux';
import {TranslationsUk} from '../../../../../../redux/translations.uk.js';

import { isMongo, checkProp } from '../../../../../../redux/check';

import TextArea from '../../../../../elements/inputs/textArea'
import Input from '../../../../../elements/inputs/input';
import Select from '../../../../../elements/inputs/inputSelect'
import Button from '../../../../../elements/buttons/button';
import InputAmountNLE from '../../../../../elements/inputs/inputAmountNLE';
import InputAmount from '../../../../../elements/inputs/inputAmount';
import InputDate from '../../../../../elements/inputs/inputDate';
import Checkbox from '../../../../../elements/inputs/checkBox';

import BankAccount from '../../bankAccount/bankAccount';

import { disabledElement } from '../../../../disabled';

//import { setTenderAttemptList, createBodyCSE } from './utils'; 
import { createBody } from '../lib/createBody';
import { setTenderAttemptList } from '../lib/utils';

import {editAuc, createAuc} from '../../../createEditAndCloneAuction/edit/editTabMonga'

import {changeLoader} from '../../../../../../redux/actions.js';
import {setDataAddress} from '../../../../../../redux/createAddress';
import {setUniversalError} from '../../../../../../redux/actions.js';

const AuctionNLE = ( {data, ...props} ) => {
    const token = useSelector(state => state.start.token)
    const auction = useSelector(state => state.start.auctionsMy)

    const [lotId, setLotId] = useState(checkProp(data, 'lotId') ? data.lotId : null)
    const [title, setTitle] = useState(checkProp(data, 'title') ? isMongo(data.title) : null)
    const [description, setDescription] = useState(checkProp(data, 'description') ? isMongo(data.description) : null)
    const [tenderAttempts, setTenderAttempts] = useState(checkProp(data, 'tenderAttempts') ? data.tenderAttempts : null)
    const [accessDetails, setAccessDetails] = useState(checkProp(data, 'accessDetails') ? isMongo(data.accessDetails) : null)
    const [x_documentRequirements, setX_documentRequirements] = useState(checkProp(data, 'x_documentRequirements') ? isMongo(data.x_documentRequirements) : null)
    const [x_additionalInformation, setX_additionalInformation] = useState(checkProp(data, 'x_additionalInformation') ? isMongo(data.x_additionalInformation) : null)
    const [minNumberOfQualifiedBids, setMinNumberOfQualifiedBids] = useState(checkProp(data, 'minNumberOfQualifiedBids') && data.minNumberOfQualifiedBids !== 'null' ? data.minNumberOfQualifiedBids : 1)
    const [previousAuctionId, setPreviousAuctionId] = useState(checkProp(data, 'previousAuctionId') ? data.previousAuctionId : null)
    const [discountPercent, setDiscountPercent] = useState(checkProp(data,'discount') && checkProp(data.discount, 'discountPercent') ? data.discount.discountPercent : null)
    const [previousAuctionValue, setPreviousAuctionValue] = useState(checkProp(data,'discount') && checkProp(data.discount, 'previousAuctionValue') ? data.discount.previousAuctionValue : null)
    
    const [value, setValue] = useState(checkProp(data, 'value') ? data.value : null)
    
    const [guarantee, setGuarantee] = useState(checkProp(data, 'guarantee') ? data.guarantee : 0)
    const [minimalStep, setMinimalStep] = useState(checkProp(data, 'minimalStep') ? data.minimalStep : 0)
    const [registrationFee, setRegistrationFee] = useState(checkProp(data, 'registrationFee') ? data.registrationFee : null)
    const [valueAddedTaxCharged, setValueAddedTaxCharged] = useState(checkProp(data, 'valueAddedTaxCharged') && (data.valueAddedTaxCharged !== null && data.valueAddedTaxCharged !== 'null' && data.valueAddedTaxCharged !== 'false') ? data.valueAddedTaxCharged : false)
    const [startDate, setStartDate] = useState(checkProp(data, 'auctionPeriod') && checkProp(data.auctionPeriod, 'startDate') ? data.auctionPeriod.startDate : null)
    const [bankAccounts, setBankAccounts] = useState(checkProp(data,'bankAccounts') ? data.bankAccounts : [])
    const [discount, setDiscount] = useState(checkProp(data,'discount') && checkProp(data.discount,'discount') ? data.discount.discount : false)


    const [valueAmount, setValueAmount] = useState(checkProp(data, 'value') && data?.value !== null && data?.value?.amount !== null ? data?.value?.amount : '')
    const [valueCurrency, setValueCurrency] = useState(checkProp(data, 'value') && data?.value !== null && data?.value?.currency !== null ? data?.value?.currency : 'UAH')
    const [valueAddedTaxIncluded, setValueAddedTaxIncluded] = useState(checkProp(data, 'value') && data?.value !== null && data?.value?.valueAddedTaxIncluded !== null ? data?.value?.valueAddedTaxIncluded : true)

    /*useEffect(() => {
        if(value === null) {
            setValue(
                {
                    amount: null,
                    currency: 'UAH',
                    addTax: true
                }
            )
            setValueAmount(null)
            setValueCurrency('UAH')
            setValueAddedTaxIncluded(true)
        }
        if(value !== null){
            if(
                (value.currency === 'null' && value.amount === null && value.addTax === false)
                || (value.currency === 'null' && value.amount === 0.01 && value.addTax === false)
                || (value.currency === 'null' && value.amount === 0.01 && value.valueAddedTaxIncluded === false)
             ) {
                setValue(
                    {
                        amount: null,
                        currency: 'UAH',
                        addTax: true
                    }
                )
                setValueAmount(null)
                setValueCurrency('UAH')
                setValueAddedTaxIncluded(true)
            }
        }
    }, []);
    useEffect(() => {
        if(value !== null){
            if(
                (value.currency === 'null' && value.amount === null && value.addTax === false)
                || (value.currency === 'null' && value.amount === 0.01 && value.addTax === false)
                || (value.currency === 'null' && value.amount === 0.01 && value.valueAddedTaxIncluded === false)
             ) {
                setValue(
                    {
                        amount: null,
                        currency: 'UAH',
                        addTax: true
                    }
                )
                setValueAmount(null)
                setValueCurrency('UAH')
                setValueAddedTaxIncluded(true)
            }
        }
    }, [value]);
    useEffect(() => {
        if(value !== null){
            if(valueAmount === 0.01) {
                setValue(
                    {
                        amount: null,
                        currency: 'UAH',
                        addTax: true
                    }
                )
                setValueAmount(null)
                setValueCurrency('UAH')
                setValueAddedTaxIncluded(true)
            }
        }
    }, [valueAmount]);*/

    function showRegistrationFee(props) {
        if (data.items !== null && auction.items.length > 0) {
            let needToRemove = false;
            for (let itemIndex in auction.items) {
                let classificationId = null;
                let nonperformingLoansItemType = null;
                if (auction.items[itemIndex].hasOwnProperty('classification')
                    && auction.items[itemIndex].classification.hasOwnProperty('id')
                ) {
                    classificationId = auction.items[itemIndex].classification.id;
                    if (classificationId.startsWith('07') || classificationId.startsWith('08')) {
                        needToRemove = true;
                    }
                }
                if (auction.items[itemIndex].hasOwnProperty('nonperformingLoansItemType')) {
                    nonperformingLoansItemType = auction.items[itemIndex].nonperformingLoansItemType;
                }
                if (nonperformingLoansItemType === 'claimRights' ||
                    (classificationId !== null && (classificationId.startsWith('07') || classificationId.startsWith('08')))
                ) {
                    needToRemove = true;
                } else {
                    //if at least one iteration has - show field
                    return true;
                }
            }

            if (needToRemove === true) {
                if (props.hasOwnProperty('registrationFee')) {
                    setRegistrationFee(null)
                }
                return false;
            }
        }
        return true;
    }

    function setBaseUrl(){
        if(window.location.pathname.indexOf('/editNew/') !== -1){
            return window.location.pathname.split('/editNew/');
        }
        return null
    }

    function setPageId(){
        let tmp = setBaseUrl()
        if(tmp !== null){
            return tmp[1].split('/')
        }
        return null
    }

    let tmpId = setPageId()
    useEffect(() => {
        getAucions(props, tmpId[0])
        if(valueAmount === 0.01) setValueAmount('')
        if(valueCurrency === 'null') setValueCurrency('UAH')
        if(valueAddedTaxIncluded === false && valueAmount === 0.01 && valueCurrency === 'null') setValueAddedTaxIncluded(true)
    }, [])

    async function getAucions(props, id){
        changeLoader(true)
        const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction-draft/${id}`;
        const response = await fetch(endPoint, {
            method: "GET",
            headers: {
                'Authorization': token.access_token,
                'Content-Type': 'application/json'
            }
        }).catch(console.error)
        if(response === undefined || response === 'undefined'){
        }else{
            const json = await response.json()
            if(json.hasOwnProperty('message') === true){
                const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/public/auction/${id}`;
                const response = await fetch(endPoint, {
                    method: "GET",
                    headers: {
                        'Authorization': token.access_token,
                        'Content-Type': 'application/json'
                    }
                }).catch(console.error)
                if(response === undefined || response === 'undefined'){
                }else{
                    const json = await response.json()
                    if(json.hasOwnProperty('message') === true){
                        let tmp = JSON.stringify({title: 'Помилка при завантаженні аукціонів', message: JSON.stringify(json)})
                        setUniversalError(tmp)
                    }else{
                        setDataAddress(json, 'EDIT_AUCTION_FROM_ID')
                    }
                }
            }else{
                setDataAddress(json, 'EDIT_AUCTION_FROM_ID')
            }
        }
        changeLoader(false)
    }
    
    let requestBody = {
        title: {
            uk_UA: title
        },
        description: {
            uk_UA: description
        },
        accessDetails: {
            uk_UA: accessDetails
        },
        tenderAttempts: +tenderAttempts,
        lotId: lotId,
        auctionPeriod: {
            startDate: startDate
        },
        sellingMethod: data.sellingMethod,
        x_documentRequirements: {
            uk_UA: x_documentRequirements
        },
        x_additionalInformation: {
            uk_UA: x_additionalInformation
        },
        minNumberOfQualifiedBids: +minNumberOfQualifiedBids,
        valueAddedTaxCharged: valueAddedTaxCharged,
        // value: {
        //     currency: checkProp(value,'currency') ? value.currency : null,
        //     amount: checkProp(value,'amount') ? +value.amount : null,
        //     valueAddedTaxIncluded: checkProp(value,'addTax') && value?.addTax !== null && value?.addTax !== 'null' && value?.addTax !== '' ? value.addTax : true
        // },
        value: {
            currency: valueCurrency,
            amount: valueAmount === '' ? null : +valueAmount,
            valueAddedTaxIncluded: valueAddedTaxIncluded
        },
        minimalStep: {
            currency: checkProp(minimalStep,'currency') ? minimalStep.currency : null,
            amount: checkProp(minimalStep,'amount') ? +minimalStep.amount : null,
        },
        guarantee: {
            currency: checkProp(guarantee,'currency') ? guarantee.currency : null,
            amount: checkProp(guarantee,'amount') ? +guarantee.amount : null
        },
        registrationFee: {
            currency: checkProp(registrationFee,'currency') ? registrationFee.currency : null,
            amount: checkProp(registrationFee,'amount') ? +registrationFee.amount : null
        },
        previousAuctionId: previousAuctionId,
        discount: {
            discount: discount,
            discountPercent: discountPercent,
            previousAuctionValue:{
                currency: checkProp(previousAuctionValue,'currency') ? previousAuctionValue.currency : null,
                amount: checkProp(previousAuctionValue,'amount') ? +previousAuctionValue.amount : null,
                valueAddedTaxIncluded: checkProp(previousAuctionValue,'addTax') ? previousAuctionValue.addTax : null
            }
        },
        bankAccounts: bankAccounts,
    }

    function discountBlock(){
        if (tenderAttempts > 1){
            return(
                <div className={styles.costBlock}>
                    <div className={styles.costBlockTitle}>Дані попереднього аукціону</div>
                    <div className={styles.twoInLine}>
                        <Input //"Ідентифікатор попереднього аукціону"
                            label={TranslationsUk['basicSell-english'].basicSellEnglishProcedure.previousAuctionId.legalName} 
                            value={previousAuctionId}
                            onChange={(e)=>{ setPreviousAuctionId(e) }}
                            disabled={disabledElement(auction, '01')}
                            required
                        />
                    </div>
                    { discount 
                    ? 
                        <>
                            <Checkbox 
                                value={discount}
                                onChange={setDiscount}
                            >
                            Застосувати знижку
                            </Checkbox>
                            <div 
                            // className={styles.twoInLine}
                                style={{width: '60%', height: '180px', display: 'flex', flexFlow: 'column', justifyContent: 'space-between'}}
                            >
                                <InputAmount //"Стартова вартість попереднього аукціону"
                                    title={TranslationsUk['basicSell-english'].basicSellEnglishProcedure.discount.previousAuctionValue.legalName}
                                    lable={TranslationsUk['basicSell-english'].basicSellEnglishProcedure.discount.previousAuctionValue.amount.legalName}
                                    data={{
                                        amount: checkProp(previousAuctionValue,'amount') ? previousAuctionValue.amount : null,
                                        currency: checkProp(previousAuctionValue,'currency') ? previousAuctionValue.currency : null,
                                        addTax: checkProp(previousAuctionValue,'valueAddedTaxIncluded') ? previousAuctionValue.valueAddedTaxIncluded : true,
                                        type: 2
                                    }}
                                    onChange={(e) => { setPreviousAuctionValue(e) }}
                                    disabled={disabledElement(auction, '01')}
                                    required
                                    tax
                                />
                                <Input //"Розмір знижки від попереднього аукціону, %"
                                    label={TranslationsUk['basicSell-english'].basicSellEnglishProcedure.discount.discountPercent.legalName} 
                                    value={discountPercent}
                                    type='number'
                                    onChange={(e)=>{ setDiscountPercent(e) }}
                                    disabled={disabledElement(auction, '01')}
                                    required
                                />
                            </div>
                        </>
                    : 
                        <Checkbox 
                            value={discount}
                            onChange={setDiscount}
                        >
                            Застосувати знижку
                        </Checkbox>}
                </div>
            )
        } else {
            requestBody.discount = null
        }
        return null
    }


    return (
        <>
            <Input //"Номер лота"
                label={TranslationsUk['basicSell-english'].basicSellEnglishProcedure.lotId.legalName} 
                value={lotId}
                onChange={(e)=>{ setLotId(e) }}
                disabled={disabledElement(auction, '01')}
                required
            />
            <Input //Назва аукціону"
                label={TranslationsUk['basicSell-english'].basicSellEnglishProcedure.title.legalName} 
                value={title}
                onChange={(e)=>{ setTitle(e) }}
                disabled={disabledElement(auction, '01')}
                required
            />
            <TextArea //"Опис аукціону"
                value={description}
                disabled={disabledElement(auction, '01')}
                label={TranslationsUk['basicSell-english'].basicSellEnglishProcedure.description.legalName} 
                required
                onChange={(e)=>{ setDescription(e) }}
            />
            <Select //'Лот виставляється',
                data={{
                    label: TranslationsUk['basicSell-english'].basicSellEnglishProcedure.tenderAttempts.legalName, 
                    target: tenderAttempts,
                    // info: 'Отака от штука-дрюка',
                    // required: true,
                }}
                disabled={disabledElement(auction, '01')}
                onChange={(e) => { setTenderAttempts(e) }}
                options={setTenderAttemptList()}
            />
            <Input //"Порядок ознайомлення з майном, час і місце проведення огляду об’єкта"
                label={TranslationsUk['basicSell-english'].basicSellEnglishProcedure.accessDetails.legalName} 
                value={accessDetails}
                onChange={(e)=>{ setAccessDetails(e) }}
                disabled={disabledElement(auction, '01')}
            />
            <Input //"Перелік та вимоги до оформлення документів"
                label={TranslationsUk['basicSell-english'].basicSellEnglishProcedure.x_documentRequirements.legalName} 
                value={x_documentRequirements}
                onChange={(e)=>{ setX_documentRequirements(e) }}
                disabled={disabledElement(auction, '01')}
            />
            <Input
                label={TranslationsUk['basicSell-english'].basicSellEnglishProcedure.x_additionalInformation.legalName} //"Додаткові відомості"
                value={x_additionalInformation}
                onChange={(e)=>{ setX_additionalInformation(e) }}
                disabled={disabledElement(auction, '01')}
            />
            <Select //"Мінімальна кількість заяв" 
                data={{
                    label: TranslationsUk['basicSell-english'].basicSellEnglishProcedure.minNumberOfQualifiedBids.legalName, 
                    target: minNumberOfQualifiedBids,
                    // info: 'Отака от штука-дрюка',
                    // required: true,
                }}
                disabled={disabledElement(auction, '01')}
                onChange={(e) => { setMinNumberOfQualifiedBids(e) }}
                options={
                    [
                        {/*value: 'null', name: 'Обрати', selected: true*/},
                        {value: 1, name: '1'},
                        {value: 2, name: '2'}
                    ]
                }
            />

            {discountBlock()}
            <div className={styles.costBlock}>
                <div className={styles.costBlockTitle}>Вартісні характеристики аукціону</div>



                {/*const [valueAddedTaxIncluded, setValueAddedTaxIncluded] = useState(checkProp(data, 'value') && data?.value !== null && data?.value?.valueAddedTaxIncluded !== null ? data?.value?.valueAddedTaxIncluded : true)*/}

                <div className={stylesInput.inPutWrap}>
                    <div className={stylesInput.title}>Стартова ціна лота</div>
                    <div className={stylesInput.content}>
                        <div className={stylesInput.inPutSelectWrap}>
                            <select className={stylesInput.inPutSelect}
                                value={valueCurrency}
                                onChange={setValueCurrency}
                                disabled={disabledElement(auction, '11')}
                            >
                                <option value="UAH" key='UAH' default>Гривня</option>
                                {/* <option value="USD" key='USD' disabled>Долар США</option>
                                <option value="EUR" key='EUR' disabled>Євро</option> */}
                            </select>
                            <div className={stylesInput.labelCur}>Валюта</div>
                        </div>
                        <input
                            className={disabledElement(auction, '01') ? stylesInput.disabled : stylesInput.inPutAmount}
                            type='number'
                            onInput={e => {
                                let reg=/[a-zA-Zа-яА-Яа-яіІїЇєЄ]/;
                                let reg2=/^\d*(\.\d{0,2})?$/;
                                if(reg.test(e.target.value) !== true && reg2.test(e.target.value)) setValueAmount(e.target.value)
                            }}
                            disabled={disabledElement(auction, '01')}
                            value={valueAmount}
                        />
                        <>
                            <div className={stylesInputCheck.inPutInfo}>
                                <label className={disabledElement(auction, '01')
                                    ? `${stylesInputCheck.check} ${stylesInputCheck.disabled} ${stylesInputCheck.option}`
                                    : `${stylesInputCheck.check} ${stylesInputCheck.option}`}>
                                    <input 
                                        checked={valueAddedTaxIncluded}
                                        className={stylesInputCheck.checkInput}
                                        onChange={() => setValueAddedTaxIncluded(!valueAddedTaxIncluded)}
                                        type='checkbox'
                                        disabled={disabledElement(auction, '01')}
                                    >
                                    </input>
                                    <span className={stylesInputCheck.checkBox}></span>
                                </label>
                                <div className={stylesInputCheck.descript}>Податок</div>
                            </div>
                        </>
                    </div>
                </div>
                
                {/*<InputAmountNLE //"Стартова ціна лота"
                    title={TranslationsUk['basicSell-english'].basicSellEnglishProcedure.value.legalName}
                    lable={TranslationsUk['basicSell-english'].basicSellEnglishProcedure.value.amount.legalName}
                    data={{
                        amount: value === null ? null : value.amount,
                        currency: value === null ? 'UAH' : value.currency,
                        addTax: value === null ? true : value.valueAddedTaxIncluded,
                        type: 2
                    }}
                    onChange={(e) => {
                        setValue(e)
                    }}
                    disabled={disabledElement(auction, '01')}
                    tax
                />*/}
                <InputAmount //"Гарантійний внесок"
                    title={TranslationsUk['basicSell-english'].basicSellEnglishProcedure.guarantee.legalName}
                    lable={TranslationsUk['basicSell-english'].basicSellEnglishProcedure.guarantee.amount.legalName}
                    data={{
                        amount: checkProp(guarantee,'amount') ? guarantee.amount : null,
                        currency: checkProp(guarantee,'currency') ? guarantee.currency : null,
                    }}
                    onChange={(e) => { setGuarantee(e) }}
                    disabled={disabledElement(auction, '01')}
                    required
                />
                { showRegistrationFee(props) &&
                    <InputAmount //"Реєстраційний внесок"
                        title={TranslationsUk['basicSell-english'].basicSellEnglishProcedure.registrationFee.legalName}
                        lable={TranslationsUk['basicSell-english'].basicSellEnglishProcedure.registrationFee.amount.legalName}
                        data={{
                            amount: checkProp(registrationFee, 'amount') ? registrationFee.amount : null,
                            currency: checkProp(registrationFee, 'currency') ? registrationFee.currency : null,
                        }}
                        onChange={(e) => {
                            setRegistrationFee(e)
                        }}
                        disabled={disabledElement(auction, '01')}
                    />
                }
                <InputAmount //"Розмір кроку аукціону"
                    title={TranslationsUk['basicSell-english'].basicSellEnglishProcedure.minimalStep.legalName}
                    lable={TranslationsUk['basicSell-english'].basicSellEnglishProcedure.minimalStep.amount.legalName}
                    data={{
                        amount: checkProp(minimalStep,'amount') ? minimalStep.amount : null,
                        currency: checkProp(minimalStep,'currency') ? minimalStep.currency : null,
                    }}
                    onChange={(e) => { setMinimalStep(e) }}
                    disabled={disabledElement(auction, '01')}
                />
                <div style={{height: '20px'}}></div>
                <Checkbox 
                        value={valueAddedTaxCharged}
                        onChange={setValueAddedTaxCharged}
                        disabled={disabledElement(auction, '01')}
                    >
                        На фінальну суму нараховується ПДВ
                </Checkbox>
            </div>
            <div className={styles.startDate}>
                <div className={styles.costBlockTitle}>
                    {`${TranslationsUk['basicSell-english'].basicSellEnglishProcedure.auctionPeriod.startDate.legalName} аукціону`}
                </div>
                <InputDate
                    data={{
                        target: startDate,
                        time: true
                    }}
                    onChange={ e => setStartDate(e) }
                    disabled={disabledElement(auction, '11')}
                />
            </div>
            <BankAccount bankAccounts={bankAccounts} setBankAccounts={setBankAccounts} />

            <div className={styles.bottomButtonBlock}>
                <Button
                    height={'50px'}
                    font={'16px'}
                    color='blue'
                    data={'Зберегти зміни'}
                    onClick={
                        () =>{
                            let tmp = null
                            let bodyObj = {
                               base: requestBody,
                            }
                            tmp = createBody(bodyObj)
                            if(auction.id !== null && auction.id !== ''){
                                editAuc(props.updateAuc, tmp)
                            }else{
                                createAuc(props.updateAuc, tmp)
                            }
                        }
                    }
                />
            </div>
        </>
    )
}

export default AuctionNLE