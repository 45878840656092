import React, { useState, useEffect } from 'react';
import styles from './period.module.css';

import InputDate from '../../../../../../elements/inputs/inputDate';
import WeekDayPeriod from './weekDayPeriod'
import { checkProp } from '../../../../../../../redux/check';
import AddlIcon from '../../../../../../elements/svg/addlIcon';
import Button from '../../../../../../elements/buttons/button'
import DeleteIcon from '../../../../../../elements/svg/deleteIcon';

const Period = ({data, setPeriod, period, index, ...props}) => {
    const [weekDaysPeriod, setWeekDaysPeriod] = useState(period[index].weekdays !== null && period[index].weekdays.length > 0 ? period[index].weekdays : []);
    const [timeStamp, setTimeStamp] = useState(Date.now());

    function tryPeriodDate(){
        if (Date.parse(period[index].startPeriod) > Date.parse(period[index].endPeriod)) return true
        return null
    }

    return (
        <div className={styles.period}>
        <div className={styles.periodLabel}>Період</div>
            { tryPeriodDate() ? 
            <div className={styles.fullWidth}>
                <div style={{color: '#FFA047', fontWeight: '600'}}>Дата закінчення періоду не може бути менше дати початку</div>
            </div> : null
            }
            <div className={styles.fullWidth}>
                <div className={styles.halfWidth}>
                    <InputDate
                        data={{
                            label: "Початок періоду",
                            target: period[index].startDate,
                            time: true
                        }}
                        onChange={ (e) => {
                            let tmp = {
                                startDate: e,
                                endDate: period[index].endDate,
                                weekdays: period[index].weekdays
                            }
                            let tmpPeriod = period
                            tmpPeriod[index] = tmp
                            setPeriod(tmpPeriod)
                            setTimeStamp(Date.now())
                        }}
                        // disabled={disabledElement(auction, '01')}
                    />
                </div>
                <div className={styles.halfWidth}>
                    <InputDate
                        data={{
                            label: "Кінець періоду",
                            target: period[index].endDate,
                            alert: tryPeriodDate(),
                            time: true
                        }}
                        onChange={ (e) => {
                            let tmp = {
                                startDate: period[index].startDate,
                                endDate: e,
                                weekdays: period[index].weekdays
                            }
                            let tmpPeriod = period
                            tmpPeriod[index] = tmp
                            setPeriod(tmpPeriod)
                            setTimeStamp(Date.now())
                        }}
                    />
                </div>
            </div>
            
            {period[index].weekdays !== null && period[index].weekdays.length > 0 ?
                period[index].weekdays.map (
                    (item, count) => {
                        return (
                            <div className={styles.time}>
                                <WeekDayPeriod 
                                    key={count}
                                    index={count}
                                    indexPeriod={index}
                                    data={item}
                                    weekDaysPeriod={weekDaysPeriod}
                                    setWeekDaysPeriod={setWeekDaysPeriod}
                                    setTimeStamp={setTimeStamp}
                                    weekdays={period[index].weekdays}
                                    setPeriod={setPeriod}
                                    period={period}
                                />
                                <div className={styles.weekDaysDelete} 
                                        title='Видалити графік'
                                        onClick={ async ()=>{
                                            let tmp2 = period[index].weekdays
                                            tmp2.splice(count, 1);
                                            let tmp = {
                                                startDate: period[index].startDate,
                                                endDate: period[index].endDate,
                                                weekdays: tmp2
                                            }
                                            let tmpPeriod = period
                                            tmpPeriod[index] = tmp
                                            setPeriod(tmpPeriod)
                                            setTimeStamp(Date.now())
                                        }}
                                >
                                    <DeleteIcon 
                                        color={'#BFBFC6'}
                                        style={styles.weekDaysDeleteSVG}
                                        widthHeight={[20,20]}
                                        
                                    />
                                </div>
                            </div>
                        )
                    }
                ) : null
            }
            <div className={styles.buttonsBlock}>
                <div className={styles.weekDaysAdd}
                    title='Додати графік'
                    onClick={()=> {

                        setWeekDaysPeriod(
                            [...weekDaysPeriod,
                                { weekday: null,
                                    endTime: '00:00',
                                    startTime: '00:00'
                                }
                            ]
                        )
                        /*setWeekDaysPeriod(...weekDaysPeriod, {
                            weekday: {
                                uk_UA: null
                            },
                            endTime: '00:00',
                            startTime: '00:00'
                        })*/

                        let tmpWeekdays = [...period[index].weekdays, {
                            weekday: {
                                uk_UA: null
                            },
                            endTime: '00:00',
                            startTime: '00:00'
                        }]
                        let tmp = {
                            startDate: period[index].startDate,
                            endDate: period[index].endDate,
                            weekdays: tmpWeekdays
                        }
                        let tmpPeriod = period
                        tmpPeriod[index] = tmp
                        setPeriod(tmpPeriod)

                        
                    }}
                    >
                    <AddlIcon
                        style={styles.addSVG}
                        widthHeight={[16, 16]}
                    />
                </div>
                <Button
                    color='gray'
                    onClick={async ()=> {
                        if(period.length === 1){
                            setPeriod([])
                        }else{
                            let tmp = [...period]
                            tmp.splice(index, 1)
                            setPeriod(null)
                            setPeriod(tmp)
                        }
                    }}
                >
                    Видалити період
                </Button>
            </div>
            
        </div>
    )
}
export default Period;