import React, { useEffect } from 'react';
import styles from './windowAuction.module.css';

import {connect} from 'react-redux';

import {createBodyOld} from '../createBodyPostAndPatch/createBodyAuction'

import {TranslationsUk} from '../../../../../redux/translations.uk.js';
import { isBSE, isBSD, isALE, isCSE, isCSD, isBRE, isBRD, isNLE, isNLD } from "../../../../../redux/procedure";
import {
    getMyAuction,
    changeCreateAuctionTitle,
    changeCreateAuctionDescription,
    changeCreateAuctionType,
    changeCreateAuctionTenderAttempts,
    changeCreateAuctionAccessDetails,
    changeCreateAuctionXDocumentRequirements,
    changeCreateAuctionXAdditionalInformation,
    changeCreateAuctionValueCurrency,
    changeCreateAuctionValueAmount,
    changeCreateAuctionValueTax,
    changeCreateAuctionGuaranteeCurrency,
    changeCreateAuctionGuaranteeAmount,
    changeCreateAuctionGuaranteeTax,
    changeCreateAuctionMinimalStepCurrency,
    changeCreateAuctionMinimalStepAmount,
    changeCreateAuctionMinimalStepTax,
    changeCreateAuctionDateStart,
    changeCreateAuctionDateEnd,
    changeCreateAuctionId,
    changeCreateAuctionLotIdentifier,
    changeCreateAuctionTab,
    setCarryType,
    minNumberOfQualifiedBids,
    changeCreateAuctionMinNumberOfQualifiedBids,
    changeCreateAuctionDgfVirtualDataRoom,
    changeCreateAuctionDgfEligibilityCriteria,
    changeCreateAuctionDgfX_dgfPlatformLegalDetails,
    changeCreateAuctionDgfDecisionId,
    changeCreateAuctionDgfDecisionDate,
} from '../../../../../redux/actions.js';

import {
    changeLoader,
} from '../../../../../redux/actions.js';

import {
    changeAuctionsSwitchWindow,
    auctionsGetMy,
    setPopUpAuctionCreate,
    setPopUpAuctionMyNull,
} from '../../../../../redux/actions/auctions/auctions.js';

import {
    setDataAddress,
} from '../../../../../redux/createAddress';

import {
    bankAccountGet,
    changePopUpBankAccount,
    setPopBankAccountId,
    setPopBankAccountIdArr,
    setBankAccountSave,
    setBankAccountAdd,
    setBankAccountDelete,
    openBankWindows,
} from '../../../../../redux/actions/bankAccount/bankAccount.js';

import {
    changeCreateItemGeologivDataValue,
    changeCreateItemDocumentationValue,
    changeCreateItemDateUse
} from '../../../../../redux/actions/auctionItems/auctionItems.js';

import {
    changeDiscountPercent,
    changeDiscountPreviousAuctionCurrency,
    changeDiscountPreviousAuctionAmount,
    changeDiscountPreviousAuctionId,
    discountAuc,
} from '../../../../../redux/actions/discount/discount.js';

import {setUniversalError} from '../../../../../redux/actions.js';

import SnoozeIcon from "@material-ui/icons/Snooze";
import AlarmIcon from "@material-ui/icons/AddAlarm";
import { IconButton, InputAdornment } from "@material-ui/core";
import { DateTimePicker, KeyboardDateTimePicker } from "@material-ui/pickers";

import 'date-fns';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';

import WindowAuctionSelfNumber from '../../windowAuctionSelfNumber.js';
import WindowAuctionTitle from '../../windowAuctionTitle.js';
import WindowAuctionDescription from '../../windowAuctionDescription.js';
import WindowAuctionTenderAttempt from '../../windowAuctionTenderAttempt.js';
import MartialLawDisclaimer from '../../martialLawDisclaimer.js'
import WindowAuctionAccessDetails from '../../windowAuctionAccessDetails.js';
import WindowAuctionDocumentRequirements from '../../windowAuctionDocumentRequirements.js';
import WindowAuctionAdditionalInformation from '../../windowAuctionAdditionalInformation.js';
import WindowAuctionType from '../../auctionsCommon/windowAuctionType/windowAuctionType.js';
import WindowAuctionCost from '../../windowAuctionCost.js';

import ValueAddedTaxCharged from '../../valueAddedTaxCharged.js';
import IsPerishable  from '../../isPerishable.js';

import WindowAuctionGaranty from '../../windowAuctionGaranty.js';
import WindowAuctionMinimalStep from '../../windowAuctionMinimalStep.js';
import WindowAuctionDiscount from '../../windowAuctionDiscount.js';

import WindowAuctionDutchSteps from '../../windowAuctionDutchSteps.js';
import MinNumberOfQualifiedBids from '../../minNumberOfQualifiedBids'

import WindowAuctionDgf from '../../dgf.js';
import LandRental from '../../landRental'
import WindowAuctionZU from '../../zu.js';
import WindowAuctionZUBanlAccounts from '../../zuBankAccounts'
import WindowAuctionLandRentalBanlAccounts from '../../WindowAuctionLandRentalBanlAccounts'
import WindowAuctionBaseSellBankAccounts from '../../WindowAuctionBaseSellBankAccounts'

import ReturnButton from '../../../returnButton'
import ViewAddBankAccont from '../../viewAddBankAccont.js';

import CreateAuctionLoader from '../../../createAuctionLoader.js';

import LegitimatePropertyLeaseLeaseDuration from '../legitimatePropertyLeaseLeaseDuration'
import RailWayCargoDutchMinNumberOfQualifiedBids from '../railWayCargoDutchMinNumberOfQualifiedBids'

import RegistrationFee from '../../windowAuctionRegistrationFee'

import {disabledElement, onOffCurrentTenat} from '../../../disabled'

import LandRentalLeaseType from '../../landRental_leaseType'

import {setJsonValue} from '../../../../../redux/setJson'

import {checkingFilledFields} from '../checkingFilledFields'

import { getRegion } from '../../../../../api/dictonaries/region';

const WindowAuction = ( props ) => {
    let tmp = null;
    let tmpBank = null
    let tmpBankSelect = null
    let tmpRegistrationFee = null
    let tmpLandRentalLeaseType = null

    function setBaseUrl(){
        if(window.location.pathname.indexOf('/edit/') !== -1){
            return window.location.pathname.split('/edit/');
        }
        return null
    }

    function setPageId(){
        let tmp = setBaseUrl()
        if(tmp !== null){
            return tmp[1].split('/')
        }
        return null
    }

    let tmpId = setPageId()
    useEffect(() => {
        getAucions(props, tmpId[0])
        let header = {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
        let dataBank = {}
        dataBank.domen = process.env.REACT_APP_END_POINT_BD
        dataBank.version = 'v1'
        dataBank.local = props.langFlag
        dataBank.params = `?offset=0`

        //TODO: Нерабочий АПИ 20211018
        props.bankAccountGet(dataBank, header)
    }, [])

    if (props.auctionsMy !== null) {
        switch (true) {
            case props.auctionsMy.sellingMethod.includes('landSell-english'):
                tmpRegistrationFee = <RegistrationFee/>
                tmpLandRentalLeaseType = <LandRentalLeaseType/>
                break;
            case props.auctionsMy.sellingMethod.includes('landRental-english'):
                tmpRegistrationFee = <RegistrationFee/>
                tmpLandRentalLeaseType = <LandRentalLeaseType/>
                break;
            case props.auctionsMy.sellingMethod.includes('landSell-priorityEnglish'):
                tmpRegistrationFee = <RegistrationFee/>
                tmpLandRentalLeaseType = <LandRentalLeaseType/>
                break;
            case isBSE(props.auctionsMy.sellingMethod):
            case isBSD(props.auctionsMy.sellingMethod):
            case isALE(props.auctionsMy.sellingMethod):
            case isCSE(props.auctionsMy.sellingMethod):
            case isCSD(props.auctionsMy.sellingMethod):
            case isBRE(props.auctionsMy.sellingMethod):
            case isBRD(props.auctionsMy.sellingMethod):
            case isNLE(props.auctionsMy.sellingMethod):
            case isNLD(props.auctionsMy.sellingMethod):
            case props.auctionsMy.sellingMethod.includes('railwayCargo-english'):
            case props.auctionsMy.sellingMethod.includes('railwayCargo-dutch'):
                tmpRegistrationFee = <RegistrationFee/>
                break;
        }
    }

    if(props.auctionsMy !== null){
        if (
            props.auctionsMy.sellingMethod.includes('legitimatePropertyLease-english') ||
            props.auctionsMy.sellingMethod.includes('legitimatePropertyLease-dutch') ||
            props.auctionsMy.sellingMethod.includes('legitimatePropertyLease-priorityEnglish') ||
            props.auctionsMy.sellingMethod.includes('legitimatePropertyLease-english')
        ) {
            if(props.auctionsSwitchWindow !== 2){
                if(props.auctionsMy.hasOwnProperty('leaseRules') === true){
                    if(props.auctionsMy.leaseRules !== null){
                        if(props.auctionsMy.leaseRules.hasOwnProperty('schedule') === true){
                            if(props.auctionsMy.leaseRules.schedule !== null){
                                if(props.schedule.flag === false){
                                    props.setDataAddress(true, 'SET_SCHEDULE_FLAG')
                                    if(props.auctionsMy.leaseRules.schedule.hasOwnProperty('hoursBy') === true){
                                        if(props.auctionsMy.leaseRules.schedule.hoursBy !== null){
                                            if(props.auctionsMy.leaseRules.schedule.hoursBy.value !== null && props.auctionsMy.leaseRules.schedule.hoursBy.value !== ''){
                                                if(props.schedule.hoursBy.value !== props.auctionsMy.leaseRules.schedule.hoursBy.value){
                                                    props.setDataAddress(props.auctionsMy.leaseRules.schedule.hoursBy.value, 'SET_SCHEDULE_HOURS_BY_VALUE')
                                                }
                                            }
                                            if(props.auctionsMy.leaseRules.schedule.hoursBy.by !== null && props.auctionsMy.leaseRules.schedule.hoursBy.by !== 'null'){
                                                if(props.schedule.hoursBy.by !== props.auctionsMy.leaseRules.schedule.hoursBy.by){
                                                    props.setDataAddress(props.auctionsMy.leaseRules.schedule.hoursBy.by, 'SET_SCHEDULE_HOURS_BY_BY')
                                                }
                                            }
                                        }
                                    }
                                    if(props.auctionsMy.leaseRules.schedule.hasOwnProperty('daysBy') === true){
                                        if(props.auctionsMy.leaseRules.schedule.daysBy !== null){
                                            if(props.auctionsMy.leaseRules.schedule.daysBy.value !== null && props.auctionsMy.leaseRules.schedule.daysBy.value !== ''){
                                                if(props.schedule.daysBy.value !== props.auctionsMy.leaseRules.schedule.daysBy.value){
                                                    props.setDataAddress(props.auctionsMy.leaseRules.schedule.daysBy.value, 'SET_SCHEDULE_DAYS_BY_VALUE')
                                                }
                                            }
                                            if(props.auctionsMy.leaseRules.schedule.daysBy.by !== null && props.auctionsMy.leaseRules.schedule.daysBy.by !== 'null'){
                                                if(props.schedule.daysBy.by !== props.auctionsMy.leaseRules.schedule.daysBy.by){
                                                    props.setDataAddress(props.auctionsMy.leaseRules.schedule.daysBy.by, 'SET_SCHEDULE_DAYS_BY_BY')
                                                }
                                            }
                                        }
                                    }
                                }
                                if(props.schedule.other === null){
                                    if(props.auctionsMy.leaseRules.schedule.other !== null && props.auctionsMy.leaseRules.schedule.other !== ''){
                                        if(props.schedule.other === null){
                                            props.setDataAddress(props.auctionsMy.leaseRules.schedule.other, 'SET_SCHEDULE_OTHER')
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            tmpBank = <WindowAuctionZUBanlAccounts />
            tmpBankSelect = null
        } else if (
            props.auctionsMy.sellingMethod.includes('landRental-english') ||
            props.auctionsMy.sellingMethod.includes('landSell-english') ||
            props.auctionsMy.sellingMethod.includes('landSell-priorityEnglish')
        ) {
            tmpBank = <WindowAuctionLandRentalBanlAccounts />
        } else if(
            isBSE(props.auctionsMy.sellingMethod) || isBSD(props.auctionsMy.sellingMethod) ||
            isALE(props.auctionsMy.sellingMethod) ||
            isCSE(props.auctionsMy.sellingMethod) || isCSD(props.auctionsMy.sellingMethod) ||
            isBRE(props.auctionsMy.sellingMethod) || isBRD(props.auctionsMy.sellingMethod) ||
            isNLE(props.auctionsMy.sellingMethod) || isNLD(props.auctionsMy.sellingMethod)
        ){
            tmpBank = <WindowAuctionBaseSellBankAccounts />
        }else{
            tmpBank = <ViewAddBankAccont />
            tmpBankSelect = <TextField
                className={styles.inputMaxBank}
                disabled={disabledElement(props.auctionsMy, '01')}
                id="outlined-select-currency-native"
                select
                label="Банківський рахунок"
                value={props.bankAccountId}
                onChange={(event)=>{
                    let tmpBankAdd = props.bankAccountWork.addNewBank
                    tmpBankAdd.push(event.target.value)
                    props.setBankAccountAdd(tmpBankAdd)
                    props.setPopBankAccountId(event.target.value)
                    addArrayBankAccounts(props, event.target.value)
                }}
                SelectProps={{
                    native: true,
                }}
                helperText="Обрати банківський рахунок"
                variant="outlined"
            >
                <option key={0} selected disabled value='null'>Обрати</option>
                {selectBankAuction(props)}
            </TextField>
        }
    }

    if(props.auctionsMy !== null){
        let startData = props.auctionsMy.startDate
        let endData = props.auctionsMy.startDate

        let minStep = null;
        let minStepAmo = null;
        if(props.auctionsMy.hasOwnProperty('minimalStep') === true){
            if(props.auctionsMy.minimalStep !== null){
                if(props.auctionsMy.minimalStep.hasOwnProperty("currency") === true){
                    if(props.auctionsMy.minimalStep.currency !== null){
                        minStep = props.auctionsMy.minimalStep.currency
                    }
                }
                if(props.auctionsMy.minimalStep.hasOwnProperty("amount") === true){
                    if(props.auctionsMy.minimalStep.amount !== null){
                        minStepAmo = props.auctionsMy.minimalStep.amount
                    }
                }
            }
        }

        if(props.auctionsMy.hasOwnProperty('bankAccounts') === true){
            if(props.auctionsMy.bankAccounts.length !== null){
                if(props.auctionsMy.bankAccounts.length > 0){
                    props.setPopBankAccountId(props.auctionsMy.bankAccounts[0].id)
                }
            }
        }

        /*let texpRegistBlok = null
        if(
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-english" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-fast" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-fast-manual" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-fast-auction-manual-qualification" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-auction" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-auction-manual" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-qualification" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-qualification-prod" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-fast" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-fast-manual" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-fast-auction-manual-qualification" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-initial-auction" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-initial-auction-manual" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-initial-qualification-prod" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-manual" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-auction-manual-qualification" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction-manual" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification-prod" ||
            props.auctionsMy.sellingMethod === 'landRental-english' ||
            props.auctionsMy.sellingMethod === 'landRental-english-fast' ||
            props.auctionsMy.sellingMethod === 'landRental-english-fast-manual' ||
            props.auctionsMy.sellingMethod === 'landRental-english-fast-auction-manual-qualification' ||
            props.auctionsMy.sellingMethod === 'landRental-english-initial-auction' ||
            props.auctionsMy.sellingMethod === 'landRental-english-initial-auction-manual' ||
            props.auctionsMy.sellingMethod === 'landRental-english-initial-qualification' ||
            props.auctionsMy.sellingMethod === 'landRental-english-initial-qualification-prod' ||
            props.auctionsMy.sellingMethod === 'landSell-english' ||
            props.auctionsMy.sellingMethod === 'landSell-english-fast' ||
            props.auctionsMy.sellingMethod === 'landSell-english-fast-manual' ||
            props.auctionsMy.sellingMethod === 'landSell-english-fast-auction-manual-qualification' ||
            props.auctionsMy.sellingMethod === 'landSell-english-initial-auction' ||
            props.auctionsMy.sellingMethod === 'landSell-english-initial-auction-manual' ||
            props.auctionsMy.sellingMethod === 'landSell-english-initial-qualification' ||
            props.auctionsMy.sellingMethod === 'landSell-english-initial-qualification-prod' ||
            props.auctionsMy.sellingMethod === 'landSell-priorityEnglish' ||
            props.auctionsMy.sellingMethod === 'landSell-priorityEnglish-fast' ||
            props.auctionsMy.sellingMethod === 'landSell-priorityEnglish-fast-manual' ||
            props.auctionsMy.sellingMethod === 'landSell-priorityEnglish-fast-auction-manual-qualification' ||
            props.auctionsMy.sellingMethod === 'landSell-priorityEnglish-initial-auction' ||
            props.auctionsMy.sellingMethod === 'landSell-priorityEnglish-initial-auction-manual' ||
            props.auctionsMy.sellingMethod === 'landSell-priorityEnglish-initial-qualification' ||
            props.auctionsMy.sellingMethod === 'landSell-priorityEnglish-initial-qualification-prod'
        ){
            texpRegistBlok = <div className={styles.helpBlok}>
                Шановні користувачі! Починаючи з 01.01.2024р. мінімальна заробітна плата в Україні встановлена на рівні 7100 грн. Просимо враховувати це при встановленні реєстраційного та гарантійного внесків!
            </div>
        }*/
        tmp = <div>
            {/*texpRegistBlok*/}
            <div className={styles.flexBlock}>
                <div>
                    {viewIdElements(props)}
                    <div className={styles.group}>
                        <WindowAuctionSelfNumber typeElem={1} />
                        <WindowAuctionTitle typeElem={1} />
                        <WindowAuctionDescription typeElem={1} />
                        <div className={styles.groupElem}>
                            <WindowAuctionTenderAttempt typeElem={1} />
                        </div>
                        <div className={styles.groupElem}>
                            <MartialLawDisclaimer />
                        </div>
                        <div className={styles.groupElem}>
                            <WindowAuctionAccessDetails typeElem={1} />
                        </div>
                        <div className={styles.groupElem}>
                            <WindowAuctionDocumentRequirements typeElem={1} />
                        </div>
                        <div className={styles.groupElem}>
                            <WindowAuctionAdditionalInformation typeElem={1} />
                        </div>
                        <div className={styles.groupElem}>
                            <WindowAuctionType typeElem={1} />
                        </div>
                        <div>
                            {viewMinNumberOfQualifiedBids(props, 1)}
                        </div>
                        {viewRailWayCargoDutch(props, 1)}
                        <IsPerishable />
                    </div>
                </div>
                <div className={styles.flexBlockTwo}>
                    <WindowAuctionCost typeElem={1} />
                    <ValueAddedTaxCharged />
                    <WindowAuctionGaranty typeElem={1} />
                    <WindowAuctionMinimalStep typeElem={1} />
                    {tmpRegistrationFee}
                    {tmpLandRentalLeaseType}
                    <WindowAuctionDiscount typeElem={1} />
                    <div className={styles.group}>
                        {closeClock(props)}
                        {viewSubsoil(props)}
                        {viewLegitimatePropertyLease(props)}
                        {viewWagonSelected(props)}
                        {bankAccountView(props, tmpBank, tmpBankSelect)}
                    </div>
                </div>
            </div>
            <div className={styles.bottonBlock}>
                {createOrEdit(props, minStep, minStepAmo)}
            </div>
            <div className={styles.bottonBlock}>
                <ReturnButton />
            </div>
            <div className={styles.helper}>* Поля обов'язкові для заповнення</div>
        </div>
    }
    return tmp
}

function closeClock(props){
    if(props.auctionsMy.isPerishable === true || props.auctionsMy.isPerishable === 'true'){
        return (
            <>
            <div>Дата аукціону</div>
            {dateStartAuction(props, 'disabled')}
            </>
        )
    }
    return (
        <>
        <div>Дата аукціону</div>
        {dateStartAuction(props, null)}
        </>
    )
}

function bankAccountView(props, tmpBank, tmpBankSelect){
    if(
        props.auctionsMy.sellingMethod !== "subsoil-english" &&
        props.auctionsMy.sellingMethod !== "subsoil-english-fast" &&
        props.auctionsMy.sellingMethod !== "subsoil-english-fast-manual" &&
        props.auctionsMy.sellingMethod !== "subsoil-english-initial-auction" &&
        props.auctionsMy.sellingMethod !== "subsoil-english-initial-qualification" &&
        props.auctionsMy.sellingMethod !== "subsoil-dutch" &&
        props.auctionsMy.sellingMethod !== "subsoil-dutch-fast" &&
        props.auctionsMy.sellingMethod !== "subsoil-dutch-fast-manual" &&
        props.auctionsMy.sellingMethod !== "subsoil-dutch-initial-auction" &&
        props.auctionsMy.sellingMethod !== "subsoil-dutch-initial-qualification" &&
        props.auctionsMy.sellingMethod !== "subsoil-dutch-initial-auction-manual" &&
        props.auctionsMy.sellingMethod !== "subsoil-english-fast-auction-manual-qualification"
    ){
        return (
            <>
                <div className={styles.groupElem + ' ' + styles.bankAccountElements}>
                    <div className={styles.bankAccountTitle}>Банківські реквізити</div>
                    {tmpBank}
                </div>
                <div className={styles.groupElem}>
                    <div>
                        {tmpBankSelect}
                        <div className={styles.bottonBlock}>
                            <Button
                                disabled={elementDisabledCreate(props)}
                                className={styles.buttonExitDocument}
                                variant="contained"
                                color="primary"
                                onClick={
                                    () => {
                                        //props.changePopUpBankAccount(true)
                                        props.openBankWindows(true)
                                    }
                                }
                            >
                                Створити та редагувати банківський рахунок
                            </Button>
                        </div>
                    </div>
                </div>
            </>
        )
    }
    return null
}

function zuDutchStep(props){
    if(
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-fast" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-fast-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-fast-auction-manual-qualification" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-initial-auction" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-initial-auction-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-initial-qualification-prod" ||
        props.auctionsMy.sellingMethod === "railwayCargo-dutch" ||
        props.auctionsMy.sellingMethod === "railwayCargo-dutch-fast" ||
        props.auctionsMy.sellingMethod === "railwayCargo-dutch-fast-manual" ||
        props.auctionsMy.sellingMethod === "railwayCargo-dutch-initial-auction" ||
        props.auctionsMy.sellingMethod === "railwayCargo-dutch-initial-qualification" ||
        props.auctionsMy.sellingMethod === "railwayCargo-dutch-fast-auction-manual-qualification" ||
        props.auctionsMy.sellingMethod === "railwayCargo-dutch-initial-auction-manual" ||
        props.auctionsMy.sellingMethod === 'basicSell-dutch' ||
        props.auctionsMy.sellingMethod === 'basicSell-dutch-fast' ||
        props.auctionsMy.sellingMethod === 'basicSell-dutch-fast-manual' ||
        props.auctionsMy.sellingMethod === 'basicSell-dutch-fast-auction-manual-qualification' ||
        props.auctionsMy.sellingMethod === 'basicSell-dutch-initial-auction' ||
        props.auctionsMy.sellingMethod === 'basicSell-dutch-initial-auction-manual' ||
        props.auctionsMy.sellingMethod === 'basicSell-dutch-initial-qualification' ||
        props.auctionsMy.sellingMethod === 'basicSell-dutch-initial-qualification-prod' ||
        props.auctionsMy.sellingMethod === 'commercialSell-dutch' ||
        props.auctionsMy.sellingMethod === 'commercialSell-dutch-fast' ||
        props.auctionsMy.sellingMethod === 'commercialSell-dutch-fast-manual' ||
        props.auctionsMy.sellingMethod === 'commercialSell-dutch-fast-auction-manual-qualification' ||
        props.auctionsMy.sellingMethod === 'commercialSell-dutch-initial-auction' ||
        props.auctionsMy.sellingMethod === 'commercialSell-dutch-initial-auction-manual' ||
        props.auctionsMy.sellingMethod === 'commercialSell-dutch-initial-qualification' ||
        props.auctionsMy.sellingMethod === 'commercialSell-dutch-initial-qualification-prod' ||
        props.auctionsMy.sellingMethod === 'bankRuptcy-dutch' ||
        props.auctionsMy.sellingMethod === 'bankRuptcy-dutch-fast' ||
        props.auctionsMy.sellingMethod === 'bankRuptcy-dutch-fast-manual' ||
        props.auctionsMy.sellingMethod === 'bankRuptcy-dutch-fast-auction-manual-qualification' ||
        props.auctionsMy.sellingMethod === 'bankRuptcy-dutch-initial-auction' ||
        props.auctionsMy.sellingMethod === 'bankRuptcy-dutch-initial-auction-manual' ||
        props.auctionsMy.sellingMethod === 'bankRuptcy-dutch-initial-qualification' ||
        props.auctionsMy.sellingMethod === 'bankRuptcy-dutch-initial-qualification-prod' ||
        props.auctionsMy.sellingMethod === "nonperformingLoans-dutch" ||
        props.auctionsMy.sellingMethod === "nonperformingLoans-dutch-fast" ||
        props.auctionsMy.sellingMethod === "nonperformingLoans-dutch-fast-manual" ||
        props.auctionsMy.sellingMethod === "nonperformingLoans-dutch-fast-auction-manual-qualification" ||
        props.auctionsMy.sellingMethod === "nonperformingLoans-dutch-fast-auction-prod" ||
        props.auctionsMy.sellingMethod === "nonperformingLoans-dutch-initial-auction" ||
        props.auctionsMy.sellingMethod === "nonperformingLoans-dutch-initial-auction-manual" ||
        props.auctionsMy.sellingMethod === "nonperformingLoans-dutch-initial-qualification" ||
        props.auctionsMy.sellingMethod === "nonperformingLoans-dutch-initial-qualification-prod"
    ){
        return <WindowAuctionDutchSteps />
    }
}

function viewMinNumberOfQualifiedBids(props, type){
    if(props.auctionsMy !== null){
        switch(props.auctionsMy.sellingMethod){
            case "railwayCargo-dutch":
            case "railwayCargo-dutch-fast":
            case "railwayCargo-dutch-fast-manual":
            case "railwayCargo-dutch-initial-auction":
            case "railwayCargo-dutch-initial-qualification":
            case "railwayCargo-dutch-fast-auction-manual-qualification":
            case "railwayCargo-dutch-initial-auction-manual":
                return <>
                    {zuDutchStep(props)}
                </>
            case "dgf-english":
            case "dgf-english-fast":
            case "dgf-english-fast-manual":
            case "dgf-english-fast-auction-manual-qualification":
            case "dgf-english-initial-auction":
            case "dgf-english-initial-auction-manual":
            case "dgf-english-initial-qualification":
            case "dgf-dutch":
            case "dgf-dutch-fast":
            case "dgf-dutch-fast-manual":
            case "dgf-dutch-fast-auction-manual-qualification":
            case "dgf-dutch-initial-auction":
            case "dgf-dutch-initial-qualification":
            case "dgf-dutch-initial-auction-manual":
                return <WindowAuctionDgf typeElem={type} />
            case "legitimatePropertyLease-english":
            case "legitimatePropertyLease-english-fast":
            case "legitimatePropertyLease-english-fast-manual":
            case "legitimatePropertyLease-english-fast-auction-manual-qualification":
            case "legitimatePropertyLease-english-initial-auction":
            case "legitimatePropertyLease-english-initial-auction-manual":
            case "legitimatePropertyLease-english-initial-qualification":
            case "legitimatePropertyLease-english-initial-qualification-prod":
            case "legitimatePropertyLease-dutch":
            case "legitimatePropertyLease-dutch-fast":
            case "legitimatePropertyLease-dutch-fast-manual":
            case "legitimatePropertyLease-dutch-fast-auction-manual-qualification":
            case "legitimatePropertyLease-dutch-initial-auction":
            case "legitimatePropertyLease-dutch-initial-auction-manual":
            case "legitimatePropertyLease-dutch-initial-qualification-prod":
            case "legitimatePropertyLease-priorityEnglish":
            case "legitimatePropertyLease-priorityEnglish-fast":
            case "legitimatePropertyLease-priorityEnglish-fast-manual":
            case "legitimatePropertyLease-priorityEnglish-fast-auction-manual-qualification":
            case "legitimatePropertyLease-priorityEnglish-initial-auction":
            case "legitimatePropertyLease-priorityEnglish-initial-auction-manual":
            case "legitimatePropertyLease-priorityEnglish-initial-qualification":
            case "legitimatePropertyLease-priorityEnglish-initial-qualification-prod":
                return <>
                    {zuDutchStep(props)}
                    <WindowAuctionZU typeElem={type} />
                    </>
            case 'landRental-english':
            case 'landRental-english-fast':
            case 'landRental-english-fast-manual':
            case 'landRental-english-fast-auction-manual-qualification':
            case 'landRental-english-initial-auction':
            case 'landRental-english-initial-auction-manual':
            case 'landRental-english-initial-qualification':
            case 'landRental-english-initial-qualification-prod':
                //return <LandRental typeElem={type} />
                return null
            case 'landSell-english':
            case 'landSell-english-fast':
            case 'landSell-english-fast-manual':
            case 'landSell-english-fast-auction-manual-qualification':
            case 'landSell-english-initial-auction':
            case 'landSell-english-initial-auction-manual':
            case 'landSell-english-initial-qualification':
            case 'landSell-english-initial-qualification-prod':
                return null
            case 'landSell-priorityEnglish':
            case 'landSell-priorityEnglish-fast':
            case 'landSell-priorityEnglish-fast-manual':
            case 'landSell-priorityEnglish-fast-auction-manual-qualification':
            case 'landSell-priorityEnglish-initial-auction':
            case 'landSell-priorityEnglish-initial-auction-manual':
            case 'landSell-priorityEnglish-initial-qualification':
            case 'landSell-priorityEnglish-initial-qualification-prod':
                return <LandRental typeElem={type} />
            case 'basicSell-english':
            case 'basicSell-english-fast':
            case 'basicSell-english-fast-manual':
            case 'basicSell-english-fast-auction-manual-qualification':
            case 'basicSell-english-initial-auction':
            case 'basicSell-english-initial-auction-manual':
            case 'basicSell-english-initial-qualification':
            case 'basicSell-english-initial-qualification-prod':
            case 'alienation-english':
            case 'alienation-english-fast':
            case 'alienation-english-fast-manual':
            case 'alienation-english-fast-auction-manual-qualification':
            case 'alienation-english-initial-auction':
            case 'alienation-english-initial-auction-manual':
            case 'alienation-english-initial-qualification':
            case 'alienation-english-initial-qualification-prod':
            case 'commercialSell-english':
            case 'commercialSell-english-fast':
            case 'commercialSell-english-fast-manual':
            case 'commercialSell-english-fast-auction-manual-qualification':
            case 'commercialSell-english-initial-auction':
            case 'commercialSell-english-initial-auction-manual':
            case 'commercialSell-english-initial-qualification':
            case 'commercialSell-english-initial-qualification-prod':
            case 'bankRuptcy-english':
            case 'bankRuptcy-english-fast':
            case 'bankRuptcy-english-fast-manual':
            case 'bankRuptcy-english-fast-auction-manual-qualification':
            case 'bankRuptcy-english-initial-auction':
            case 'bankRuptcy-english-initial-auction-manual':
            case 'bankRuptcy-english-initial-qualification':
            case 'bankRuptcy-english-initial-qualification-prod':
            case "nonperformingLoans-english":
            case "nonperformingLoans-english-fast":
            case "nonperformingLoans-english-fast-manual":
            case "nonperformingLoans-english-fast-auction-manual-qualification":
            case "nonperformingLoans-english-fast-auction-prod":
            case "nonperformingLoans-english-initial-qualification":
            case "nonperformingLoans-english-initial-qualification-prod":
            case "nonperformingLoans-english-initial-auction":
            case "nonperformingLoans-english-initial-auction-manual":
                return <MinNumberOfQualifiedBids typeElem={type} />
            case 'basicSell-dutch':
            case 'basicSell-dutch-fast':
            case 'basicSell-dutch-fast-manual':
            case 'basicSell-dutch-fast-auction-manual-qualification':
            case 'basicSell-dutch-initial-auction':
            case 'basicSell-dutch-initial-auction-manual':
            case 'basicSell-dutch-initial-qualification':
            case 'basicSell-dutch-initial-qualification-prod':
            case 'commercialSell-dutch':
            case 'commercialSell-dutch-fast':
            case 'commercialSell-dutch-fast-manual':
            case 'commercialSell-dutch-fast-auction-manual-qualification':
            case 'commercialSell-dutch-initial-auction':
            case 'commercialSell-dutch-initial-auction-manual':
            case 'commercialSell-dutch-initial-qualification':
            case 'commercialSell-dutch-initial-qualification-prod':
            case 'bankRuptcy-dutch':
            case 'bankRuptcy-dutch-fast':
            case 'bankRuptcy-dutch-fast-manual':
            case 'bankRuptcy-dutch-fast-auction-manual-qualification':
            case 'bankRuptcy-dutch-initial-auction':
            case 'bankRuptcy-dutch-initial-auction-manual':
            case 'bankRuptcy-dutch-initial-qualification':
            case 'bankRuptcy-dutch-initial-qualification-prod':
            case "nonperformingLoans-dutch":
            case "nonperformingLoans-dutch-fast":
            case "nonperformingLoans-dutch-fast-manual":
            case "nonperformingLoans-dutch-fast-auction-manual-qualification":
            case "nonperformingLoans-dutch-fast-auction-prod":
            case "nonperformingLoans-dutch-initial-auction":
            case "nonperformingLoans-dutch-initial-auction-manual":
            case "nonperformingLoans-dutch-initial-qualification":
            case "nonperformingLoans-dutch-initial-qualification-prod":
                return zuDutchStep(props)
            case 'arrestedAssets-english':
            case 'arrestedAssets-english-fast':
            case 'arrestedAssets-english-fast-manual':
            case 'arrestedAssets-english-fast-auction-manual-qualification':
            case 'arrestedAssets-english-initial-auction':
            case 'arrestedAssets-english-initial-auction-manual':
            case 'arrestedAssets-english-initial-qualification':
            case 'arrestedAssets-english-initial-qualification-prod':
                return <>
                    <MinNumberOfQualifiedBids typeElem={type} />
                    {zuDutchStep(props)}
                </>
            default:
                return null
        }
    }
}

function viewRailWayCargoDutch(){
    return <RailWayCargoDutchMinNumberOfQualifiedBids />
}

function viewSubsoil(props){
    let tmp = null;
    switch(props.auctionsMy.sellingMethod){
        case "subsoil-english":
        case "subsoil-english-fast":
        case "subsoil-english-fast-manual":
        case "subsoil-english-initial-auction":
        case "subsoil-english-initial-qualification":
        case "subsoil-dutch":
        case "subsoil-dutch-fast":
        case "subsoil-dutch-fast-manual":
        case "subsoil-dutch-initial-auction":
        case "subsoil-dutch-initial-qualification":
        case "subsoil-dutch-initial-auction-manual":
        case "subsoil-english-fast-auction-manual-qualification":
            tmp = <>
                <div className={styles.groupElem + ' ' + styles.groupElemCategory}>
                    <div className={styles.sue}>
                        <TextField
                            disabled={disabledElement(props.auctionsMy, '01')}
                            label="Вартість геологічної інформації"
                            value={viewGeologivDataValue(props)}
                            onChange={(event) => {
                                props.changeCreateItemGeologivDataValue(event.target.value)
                            }}
                            name="numberformat"
                            id="formatted-numberformat-input"
                            //helperText="Вартість геологічної інформації"
                            variant="outlined"
                        />
                    </div>
                </div>
                <div className={styles.groupElem + ' ' + styles.groupElemCategory}>
                    <div className={styles.sue}>
                        <TextField
                            disabled={disabledElement(props.auctionsMy, '01')}
                            label="Вартість пакета аукціонної документації, що сплачується переможцем"
                            value={viewDocumentationValue(props)}
                            onChange={(event) => {
                                props.changeCreateItemDocumentationValue(event.target.value)
                            }}
                            name="numberformat"
                            id="formatted-numberformat-input"
                            //helperText="Вартість пакета аукціонної документації, що сплачується переможцем"
                            variant="outlined"
                        />
                    </div>
                </div>
                <div className={styles.groupElem + ' ' + styles.groupElemCategory}>
                    Строк, на який надається дозвіл
                </div>
                <div className={styles.groupElem + ' ' + styles.groupElemCategory}>
                    <div>
                        <TextField
                            disabled={disabledElement(props.auctionsMy, '00')}
                            label="Років"
                            type="number"
                            value={handleViewDate(props, 1)}
                            onChange={(event) => {
                                handleDate(props, event.target.value, 1)
                            }}
                            name="numberformat"
                            id="formatted-numberformat-input"
                            helperText="Років"
                            variant="outlined"
                        />
                    </div>
                    <div>
                        <TextField
                            disabled={disabledElement(props.auctionsMy, '00')}
                            label="Місяців"
                            type="number"
                            value={handleViewDate(props, 2)}
                            onChange={(event) => {
                                if(Number(event.target.value) < 13){
                                    handleDate(props, event.target.value, 2)
                                }
                            }}
                            name="numberformat"
                            id="formatted-numberformat-input"
                            helperText="Місяців"
                            variant="outlined"
                        />
                    </div>
                </div>
            </>
            break;
        default:
            break;
    }
    return tmp
}

function viewLegitimatePropertyLease(props){
    return <LegitimatePropertyLeaseLeaseDuration />
}

function handleViewDate(props, type){
    let tmp = null;
    if(props.auctionsMy.leaseDuration !== null){
        let tmpArr = props.auctionsMy.leaseDuration.split('P')
        let tmpArr1 = tmpArr[1].split('Y')
        let tmpArr2 = tmpArr1[1].split('M')
        let tmpArr3 = tmpArr2[1].split('D')
        if(type === 1){
            if(tmpArr1[0] !== '0'){
                tmp = tmpArr1[0]
            }else{
                tmp = ''
            }
        }else if(type === 2){
            if(tmpArr2[0] !== '0'){
                tmp = tmpArr2[0]
            }else{
                tmp = ''
            }
        }else if(type === 3){
            if(tmpArr3[0] !== '0'){
                tmp = tmpArr3[0]
            }else{
                tmp = ''
            }
        }
    }else{
        if(type === 1){
            tmp = ``
        }else if(type === 2){
            tmp = ``
        }else if(type === 3){
            tmp = ``
        }
    }
    return tmp
}

function handleDate(props, date, type){
    let tmp = null;
    if(props.auctionsMy.leaseDuration !== null){
        let tmpArr = props.auctionsMy.leaseDuration.split('P')
        let tmpArr1 = tmpArr[1].split('Y')
        let tmpArr2 = tmpArr1[1].split('M')
        let tmpArr3 = tmpArr2[1].split('D')
        if(type === 1){
            if(date === ''){
                tmp = `P0Y${tmpArr2[0]}M${tmpArr3[0]}D`
            }else{
                tmp = `P${date}Y${tmpArr2[0]}M${tmpArr3[0]}D`
            }
        }else if(type === 2){
            tmp = `P${tmpArr1[0]}Y${date}M${tmpArr3[0]}D`
            if(date === ''){
                tmp = `P${tmpArr1[0]}Y0M${tmpArr3[0]}D`
            }else{
                tmp = `P${tmpArr1[0]}Y${date}M${tmpArr3[0]}D`
            }
        }
        else if(type === 3){
            tmp = `P${tmpArr1[0]}Y${tmpArr2[0]}M${date}D`
            if(date === ''){
                tmp = `P${tmpArr1[0]}Y${tmpArr2[0]}M0D`
            }else{
                tmp = `P${tmpArr1[0]}Y${tmpArr2[0]}M${date}D`
            }
        }
    }else{
        if(type === 1){
            tmp = `P${date}Y0M0D`
        }else if(type === 2){
            tmp = `P0Y${date}M0D`
        }else if(type === 3){
            tmp = `P0Y0M${date}D`
        }

    }
    props.changeCreateItemDateUse(tmp)
}

function viewGeologivDataValue(props){
    let tmp = null;
    if(props.auctionsMy.geologicDataValue !== null){
        tmp = props.auctionsMy.geologicDataValue.amount
    }
    return tmp
}

function viewDocumentationValue(props){
    let tmp = null;
    if(props.auctionsMy.documentationValue !== null){
        tmp = props.auctionsMy.documentationValue.amount
    }
    return tmp
}

function dateStartAuction1(props){
    let tmp = null;
    if(props.auctionsMy.hasOwnProperty('dateUses') !== false){
        if(props.auctionsMy.dateUses !== null){
            tmp = props.auctionsMy.dateUses.slice(0, 16)
        }
    }
    return tmp
}

function viewIdElements(props){
    let tmp = null;
    let tmpIdAuction = null;
    let tmpIdProcedure = null;
    if(props.auctionsMy){
        if(props.auctionsMy.auctionId !== null){
            tmpIdAuction = <div>ID аукціону Prozorro: <span>{props.auctionsMy.auctionId}</span></div>
        }
        if(props.auctionsMy.procedureId !== null){
            tmpIdProcedure = <div>ID процедури: <span>{props.auctionsMy.procedureId}</span></div>
        }
        tmp = <div>
            {tmpIdAuction}
            {tmpIdProcedure}
        </div>
    }
    return tmp
}

function viewWagonSelected(props){
    let tmp = null;
    switch(props.auctionsMy.sellingMethod){
        case "railwayCargo-english":
        case "railwayCargo-english-fast":
        case "railwayCargo-english-fast-manual":
        case "railwayCargo-english-initial-auction":
        case "railwayCargo-english-initial-qualification":
        case "railwayCargo-dutch":
        case "railwayCargo-dutch-fast":
        case "railwayCargo-dutch-fast-manual":
        case "railwayCargo-dutch-initial-auction":
        case "railwayCargo-dutch-initial-qualification":
        case "railwayCargo-dutch-fast-auction-manual-qualification":
        case "railwayCargo-dutch-initial-auction-manual":
            tmp = <div className={styles.groupElem}>
                    <div>
                        <TextField
                            disabled={disabledElement(props.auctionsMy, '01')}
                            id="outlined-select-currency-native"
                            select
                            label="Тип аукціону"
                            value={props.auctionsMy.carryType}
                            onChange={(event)=>{
                                props.setCarryType(event.target.value)
                            }}
                            SelectProps={{
                                native: true,
                            }}
                            helperText="Обрати тип аукціону"
                            variant="outlined"
                        >
                            <option key={0} selected disabled value='null'>Обрати</option>
                            {wagonTypeOption(props)}
                        </TextField>
                    </div>
                </div>
            break;
        default:
            break;
    }
    return tmp
}

function wagonTypeOption(props){
    let tmp = null;
    let tmpArr = null;
    if(props.carryType !== null){
        tmpArr = props.carryType.map(
            (i) => {
                //return <option value={i.classificationId}>{i.description}</option>
                return <option value={i.name}>{i.description.uk_UA}</option>
            }
        )
    }
    switch(props.auctionsMy.sellingMethod){
        case "railwayCargo-english":
        case "railwayCargo-english-fast":
        case "railwayCargo-english-fast-manual":
        case "railwayCargo-english-initial-auction":
        case "railwayCargo-english-initial-qualification":
        case "railwayCargo-dutch":
        case "railwayCargo-dutch-fast":
        case "railwayCargo-dutch-fast-manual":
        case "railwayCargo-dutch-initial-auction":
        case "railwayCargo-dutch-initial-qualification":
        case "railwayCargo-dutch-fast-auction-manual-qualification":
        case "railwayCargo-dutch-initial-auction-manual":
            tmp = tmpArr
            break;
        default:
            break;
    }
    return tmp
}

function addArrayBankAccounts(props, elem){
    let tmp = props.bankAccountIdArr;
    if(tmp !== null){
        if(tmp.length > 0){
            if(tmp.includes(Number(elem)) !== true){
                tmp.push(Number(elem))
                props.setPopBankAccountIdArr(tmp)
            }
        }else{
            tmp.push(Number(elem))
            props.setPopBankAccountIdArr(tmp)
        }
    }else{
        tmp = []
        tmp.push(Number(elem))
        props.setPopBankAccountIdArr(tmp)
    }
}

function removeArrayBankAccounts(props, elem){
    let tmp = props.bankAccountIdArr;
    let index = tmp.indexOf(Number(elem));
    if(tmp.length === 1){
        props.setPopBankAccountIdArr(null)
    }else{
        if(index > -1){
            tmp.splice(index, 1);
            props.setPopBankAccountIdArr(tmp)
        }
    }
}

/*function allBanckAccountProduct(props){
    let tmp = null;
    if(props.bankAccount !== null && props.bankAccountIdArr !== null){
        tmp = props.bankAccount
        .filter(
            (i) => {
                return props.bankAccountIdArr.includes(Number(i.id)) === true
            }
        )
        .map(
            (i) => {
                return (
                    <div className={styles.bankAccountElement}>
                        <div>
                            <div>Назва банку: {i.bankName}</div>
                            <div>Опис банку: {i.description}</div>
                        </div>
                        <div>
                            <div>Опис банківського аккаунту: {i.accountIdentifications[0].description}</div>
                            <div>Ідінтіфікатор банківського аккаунту: {i.accountIdentifications[0].identifier}</div>
                            <div>Схема банківського аккаунту: {i.accountIdentifications[0].scheme}</div>
                        </div>
                        <div
                            onClick={
                                ()=>{
                                    //editBankAccounts(props, i)
                                }
                            }
                        >Редагувати</div>
                        <div
                            onClick={
                                ()=>{
                                    removeArrayBankAccounts(props, i.id)
                                }
                            }
                        >Вилучити</div>
                    </div>
                )
            }
        )
    }else{
        tmp = <div>Підключені банки відсутні</div>
    }
    return tmp
}*/

function valueTest(props){
    if(props.auctionsMy.value === null) return true
    if(
        props.auctionsMy.value.amount === null ||
        props.auctionsMy.value.amount === '' ||
        props.auctionsMy.value.amount === 'null'
    ) return true
}

function elementDisabledCreate(props){
    if(props.auctionsMy !== null){
    //let tmpStatus = null;
    /*if(props.auctionsMy.status !== null){
        if(props.auctionsMy.status !== 'active_rectification'){
            tmpStatus = 'disabled'
        }
    }*/
    if(props.auctionsMy.status !== null){
        if(props.auctionsMy.status !== 'active_rectification'){
            let tmpStartData = null
            let tmpEndData = null
            if(props.auctionsMy.auctionPeriods.length > 0){
                for(let tt = 0; tt < props.auctionsMy.auctionPeriods.length; tt++){
                    if(props.auctionsMy.auctionPeriods[tt].status === 'rectificationPeriod'){
                        tmpStartData = new Date(props.auctionsMy.auctionPeriods[tt].startDate).getTime()
                        tmpEndData = new Date(props.auctionsMy.auctionPeriods[tt].endDate).getTime()
                    }
                }
            }
            if(
                (
                isBSE(props.auctionsMy.sellingMethod) || isBSD(props.auctionsMy.sellingMethod) ||
                isALE(props.auctionsMy.sellingMethod) ||
                isCSE(props.auctionsMy.sellingMethod) || isCSD(props.auctionsMy.sellingMethod) ||
                isBRE(props.auctionsMy.sellingMethod) || isBRD(props.auctionsMy.sellingMethod) ||
                isNLE(props.auctionsMy.sellingMethod) || isNLD(props.auctionsMy.sellingMethod)
            ) &&
                (tmpStartData < Date.now() && tmpEndData > Date.now())
            ){
                return null
            }else{
                return 'disabled'
            }
        }
    }
    }
    return null
    //return tmpStatus
}

function selectBankAuction(props){
    let tmp = null;
    if(props.bankAccount !== null){
        tmp = props.bankAccount
        .map(
            (i, count) => {
            return <option key={count+1} value={i.id}>{i.bankName}</option>
            }
        )
    }
    return tmp
}

function dateStartAuction(props, onOff){
    let tmp = null;
    if(onOff === null){
        if(
            props.auctionsMy.status === null
        ){
            if(props.auctionsMy.procedureId === null){
                tmp = <KeyboardDateTimePicker
                error = {redBlock(props, props.auctionsMy.startDate)}
                disabled={disabledElement(props.auctionsMy, '10')}
                className={styles.dateSearch}
                value={props.auctionsMy.startDate}
                onChange={
                    (e) => {
                        props.changeCreateAuctionDateStart(e)
                    }
                }
                onError={console.log}
                minDate={new Date("1900-01-01T00:00")}
                ampm={false}
                format="yyyy/MM/dd HH:mm"
                helperText="Формат дати: гггг/мм/дд чч:мм"
            />
            }else{
                tmp = <KeyboardDateTimePicker
                error = {redBlock(props, props.auctionsMy.startDate)}
                disabled={disabledElement(props.auctionsMy, '10')}
                className={styles.dateSearch}
                value={props.auctionsMy.startDate}
                onChange={
                    (e) => {
                        props.changeCreateAuctionDateStart(e)
                    }
                }
                onError={console.log}
                minDate={new Date("1900-01-01T00:00")}
                ampm={false}
                format="yyyy/MM/dd HH:mm"
            />
            }
            if(props.auctionsSwitchWindow === 3){
                tmp = <KeyboardDateTimePicker
                error = {redBlock(props, props.auctionsMy.startDate)}
                disabled={disabledElement(props.auctionsMy, '10')}
                className={styles.dateSearch}
                value={props.auctionsMy.startDate}
                onChange={
                    (e) => {
                        props.changeCreateAuctionDateStart(e)
                    }
                }
                onError={console.log}
                minDate={new Date("1900-01-01T00:00")}
                ampm={false}
                format="yyyy/MM/dd HH:mm"
            />
            }
        }else{
            tmp = <KeyboardDateTimePicker
                error = {redBlock(props, props.auctionsMy.startDate)}
                disabled
                className={styles.dateSearch}
                value={props.auctionsMy.startDate}
                onError={console.log}
                minDate={new Date("1900-01-01T00:00")}
                ampm={false}
                format="yyyy/MM/dd HH:mm"
            />
        }
    }else{
        tmp = <>
            <KeyboardDateTimePicker
                error = {redBlock(props, props.auctionsMy.startDate)}
                disabled='disabled'
                className={styles.dateSearch}
                value={props.auctionsMy.startDate}
                onChange={
                    (e) => {
                        props.changeCreateAuctionDateStart(e)
                    }
                }
                onError={console.log}
                minDate={new Date("1900-01-01T00:00")}
                ampm={false}
                format="yyyy/MM/dd HH:mm"
            />
            <div>Дата аукціону буде запланована автоматично: через 1 повний робочий день з моменту публікації</div>
        </>
    }
    return tmp
}

function redBlock(props, amount){
    if(
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-fast" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-fast-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-fast-auction-manual-qualification" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-auction" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-auction-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-qualification" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-qualification-prod" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-fast" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-fast-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-fast-auction-manual-qualification" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-initial-auction" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-initial-auction-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-initial-qualification-prod" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-auction-manual-qualification" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification-prod"
    ){
        if(amount === null || amount === '' || amount === 'null'){
            return 'error'
        }
    }
    return null
}

function createOrEdit(props, minStep, minStepAmo){
    return (
        <Button
            disabled={checkingFilledFields(props, onOffCurrentTenat)}
            variant="contained"
            color="primary"
            onClick={
                () => {
                    if(
                        props.auctionsMy.status === null || props.auctionsMy.status === 'active_rectification'
                    ){
                        props.changeLoader(true)
                        buttonEditHandler(props)
                    }else{
                        if(
                            isBSE(props.auctionsMy.sellingMethod) || isBSD(props.auctionsMy.sellingMethod) ||
                            isALE(props.auctionsMy.sellingMethod) ||
                            isCSE(props.auctionsMy.sellingMethod) || isCSD(props.auctionsMy.sellingMethod) ||
                            isBRE(props.auctionsMy.sellingMethod) || isBRD(props.auctionsMy.sellingMethod) ||
                            isNLE(props.auctionsMy.sellingMethod) || isNLD(props.auctionsMy.sellingMethod)
                        ){
                            props.changeLoader(true)
                            buttonEditHandler(props)
                        }else{
                            props.changeLoader(true)
                            if(blokCurrentTenant(props)) createCurrentTenant(props, true)
                        }
                    }
                }
            }
        >
            Зберегти зміни
        </Button>
    )
}

async function buttonEditHandler(props){
    if(
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-fast" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-fast-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-fast-auction-manual-qualification" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-auction" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-auction-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-qualification" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-qualification-prod" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-fast" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-fast-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-fast-auction-manual-qualification" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-initial-auction" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-initial-auction-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-initial-qualification-prod" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-auction-manual-qualification" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification-prod"
    ){
        await createAuctionLeaseRules(props, props.auctionsMy.id)
    }
    await editAuc(props)
}

async function createDiscountForAuction(props, auctionId){
    //const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction`;
    if(props.discount.discountOnOff === true){
        if(
            props.auctionsMy.sellingMethod !== "landRental-english" &&
            props.auctionsMy.sellingMethod !== "landRental-english-fast" &&
            props.auctionsMy.sellingMethod !== "landRental-english-fast-manual" &&
            props.auctionsMy.sellingMethod !== "landRental-english-fast-auction-manual-qualification" &&
            props.auctionsMy.sellingMethod !== "landRental-english-initial-auction" &&
            props.auctionsMy.sellingMethod !== "landRental-english-initial-auction-manual" &&
            props.auctionsMy.sellingMethod !== "landRental-english-initial-qualification" &&
            props.auctionsMy.sellingMethod !== "landRental-english-initial-qualification-prod"
        ){
            const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${auctionId}/discount`
            let tmpDiscount = null;
            if(Number(props.auctionsMy.tenderAttempts) > 1){
                if(
                    props.auctionsMy.sellingMethod === "legitimatePropertyLease-english" ||
                    props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-fast" ||
                    props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-fast-manual" ||
                    props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-fast-auction-manual-qualification" ||
                    props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-auction" ||
                    props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-auction-manual" ||
                    props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-qualification" ||
                    props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-qualification-prod" ||
                    props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish" ||
                    props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast" ||
                    props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-manual" ||
                    props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-auction-manual-qualification" ||
                    props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction" ||
                    props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction-manual" ||
                    props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification" ||
                    props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification-prod"
                ){
                    //if(props.auctionsZUAttemp.tenderAttempts === 1 && props.auctionsMy.tenderAttempts === '2'){
                        //let tmpZUd = props.auctionsZUAttemp.value.amount / 2;
                        let body = {}
                        if(props.discount.discountOnOff === true){
                            body.discount = props.discount.discountOnOff
                            if(props.discount.discountPercent === ''){
                                body.discountPercent = null
                            }else{
                                body.discountPercent = props.discount.discountPercent
                            }
                            let tmpDisAmount = null
                            if(props.discount.previousAuctionValue.amount !== '') tmpDisAmount = props.discount.previousAuctionValue.amount
                            if(props.discount.previousAuctionValue.amount === ''){
                                body.previousAuctionValue = {
                                    currency: 'UAH',
                                    amount: tmpDisAmount
                                }
                            }else{
                                let tmpDisAmount = null
                                if(props.discount.previousAuctionValue.amount !== '') tmpDisAmount = props.discount.previousAuctionValue.amount
                                body.previousAuctionValue = {
                                    currency: 'UAH',
                                    amount: tmpDisAmount
                                }
                            }
                            const response = await fetch(endPoint, {
                                method: "POST",
                                body: JSON.stringify(body),
                                headers: {
                                    'Authorization': props.token.access_token,
                                    'Content-Type': 'application/json'
                                }
                            }).catch(console.error)
                            if(response === undefined || response === 'undefined'){
                            }else{
                                const json = await response.json()
                                if(json.hasOwnProperty('message') === true){
                                    let tmp = JSON.stringify({title: 'Помилка при створенні знижки', message: JSON.stringify(json)})
                                    props.setUniversalError(tmp)
                                }else{
                                    props.changeDiscountPreviousAuctionCurrency('null')
                                    props.changeDiscountPreviousAuctionAmount('')
                                    props.changeDiscountPercent('')
                                    props.changeDiscountPreviousAuctionId('')
                                    //props.discountAuc(false)
                                }
                            }
                        }else{
                            if(props.auctionsMy.discount !== null){
                                const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${auctionId}/discount`
                                const response = await fetch(endPoint, {
                                    method: "DELETE",
                                    headers: {
                                        'Authorization': props.token.access_token,
                                        'Content-Type': 'application/json'
                                    }
                                }).catch(console.error)
                                if(response === undefined || response === 'undefined'){
                                }else{
                                    const json = await response.json()
                                    if(json.hasOwnProperty('message') === true){
                                        let tmp = JSON.stringify({title: 'Помилка при створенні знижки', message: JSON.stringify(json)})
                                        props.setUniversalError(tmp)
                                    }else{
                                        props.changeDiscountPreviousAuctionCurrency('null')
                                        props.changeDiscountPreviousAuctionAmount('')
                                        props.changeDiscountPercent('')
                                        props.changeDiscountPreviousAuctionId('')
                                        //props.discountAuc(false)
                                    }
                                }
                            }
                        }
                    //}
                }else if(
                    isBSE(props.auctionsMy.sellingMethod) || isBSD(props.auctionsMy.sellingMethod) ||
                    isALE(props.auctionsMy.sellingMethod) ||
                    isCSE(props.auctionsMy.sellingMethod) || isCSD(props.auctionsMy.sellingMethod) ||
                    isBRE(props.auctionsMy.sellingMethod) || isBRD(props.auctionsMy.sellingMethod) ||
                    isNLE(props.auctionsMy.sellingMethod) || isNLD(props.auctionsMy.sellingMethod)
                ){
                    if(props.discount.previousAuctionId !== ''){
                        let body = {}
                        if(props.discount.discountPercent !== '' && props.discount.discountPercent !== null && props.discount.discountPercent !== 0 && props.discount.discountPercent !== '0'){
                            body.discount = true
                            if(props.discount.discountPercent !== '' && props.discount.discountPercent !== null){
                                body.discountPercent = props.discount.discountPercent
                            }else{
                                body.discountPercent = '0'
                            }
                            if(props.discount.previousAuctionValue.amount !== '' && props.discount.previousAuctionValue.amount !== null){
                                body.previousAuctionValue = {}
                                body.previousAuctionValue.currency = 'UAH'
                                body.previousAuctionValue.amount = props.discount.previousAuctionValue.amount
                                if(props.discount.previousAuctionValue.valueAddedTaxIncluded !== '' && props.discount.previousAuctionValue.valueAddedTaxIncluded !== null){
                                    body.previousAuctionValue.valueAddedTaxIncluded = props.discount.previousAuctionValue.valueAddedTaxIncluded
                                }else{
                                    body.previousAuctionValue.valueAddedTaxIncluded = true
                                }
                            }
                            const response = await fetch(endPoint, {
                                method: "POST",
                                body: JSON.stringify(body),
                                headers: {
                                    'Authorization': props.token.access_token,
                                    'Content-Type': 'application/json'
                                }
                            }).catch(console.error)
                            if(response === undefined || response === 'undefined'){
                            }else{
                                const json = await response.json()
                                if(json.hasOwnProperty('message') === true){
                                    let tmp = JSON.stringify({title: 'Помилка при створенні знижки', message: JSON.stringify(json)})
                                    props.setUniversalError(tmp)
                                }else{
                                    props.changeDiscountPreviousAuctionCurrency('null')
                                    props.changeDiscountPreviousAuctionAmount('')
                                    props.changeDiscountPercent('')
                                    props.changeDiscountPreviousAuctionId('')
                                    //props.discountAuc(false)
                                }
                            }
                        }else{
                            if(props.auctionsMy.discount !== null){
                                const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${auctionId}/discount`
                                const response = await fetch(endPoint, {
                                    method: "DELETE",
                                    headers: {
                                        'Authorization': props.token.access_token,
                                        'Content-Type': 'application/json'
                                    }
                                }).catch(console.error)
                                if(response === undefined || response === 'undefined'){
                                }else{
                                    const json = await response.json()
                                    if(json.hasOwnProperty('message') === true){
                                        let tmp = JSON.stringify({title: 'Помилка при створенні знижки', message: JSON.stringify(json)})
                                        props.setUniversalError(tmp)
                                    }else{
                                        props.changeDiscountPreviousAuctionCurrency('null')
                                        props.changeDiscountPreviousAuctionAmount('')
                                        props.changeDiscountPercent('')
                                        props.changeDiscountPreviousAuctionId('')
                                        //props.discountAuc(false)
                                    }
                                }
                            }
                        }
                    }
                }else{
                    /*if(props.discount.previousAuctionId !== ''){
                        if(props.discount.discountPercent !== ''){
                            let body = null
                            if(props.discount.discountPercent !== ''){
                                let tmpDisAmount = null
                                if(props.discount.previousAuctionValue.amount !== '') tmpDisAmount = props.discount.previousAuctionValue.amount
                                body = {
                                    discount: true,
                                    discountPercent: props.discount.discountPercent,
                                    previousAuctionValue: {
                                        currency: 'UAH',
                                        amount: tmpDisAmount,
                                    }
                                }
                            }else{
                                let tmpDisAmount = null
                                if(props.discount.previousAuctionValue.amount !== '') tmpDisAmount = props.discount.previousAuctionValue.amount
                                body = {
                                    discount: true,
                                    discountPercent: '0',
                                    previousAuctionValue: {
                                        currency: 'UAH',
                                        amount: tmpDisAmount,
                                    }
                                }
                            }
                            const response = await fetch(endPoint, {
                                method: "POST",
                                body: JSON.stringify(body),
                                headers: {
                                    'Authorization': props.token.access_token,
                                    'Content-Type': 'application/json'
                                }
                            }).catch(console.error)
                            if(response === undefined || response === 'undefined'){
                            }else{
                                const json = await response.json()
                                if(json.hasOwnProperty('message') === true){
                                    let tmp = JSON.stringify({title: 'Помилка при створенні знижки', message: JSON.stringify(json)})
                                    props.setUniversalError(tmp)
                                }else{
                                    props.changeDiscountPreviousAuctionCurrency('null')
                                    props.changeDiscountPreviousAuctionAmount('')
                                    props.changeDiscountPercent('')
                                    props.changeDiscountPreviousAuctionId('')
                                    //props.discountAuc(false)
                                }
                            }
                        }
                    }*/
                    if(props.discount.previousAuctionId !== ''){
                        let body = {}
                        if(props.discount.discountPercent !== '' && props.discount.discountPercent !== null && props.discount.discountPercent !== 0 && props.discount.discountPercent !== '0'){
                            body.discount = true
                            if(props.discount.discountPercent !== '' && props.discount.discountPercent !== null){
                                body.discountPercent = props.discount.discountPercent
                            }else{
                                body.discountPercent = '0'
                            }
                            if(props.discount.previousAuctionValue.amount !== '' && props.discount.previousAuctionValue.amount !== null){
                                body.previousAuctionValue = {}
                                body.previousAuctionValue.currency = 'UAH'
                                body.previousAuctionValue.amount = props.discount.previousAuctionValue.amount
                                if(props.discount.previousAuctionValue.valueAddedTaxIncluded !== '' && props.discount.previousAuctionValue.valueAddedTaxIncluded !== null){
                                    body.previousAuctionValue.valueAddedTaxIncluded = props.discount.previousAuctionValue.valueAddedTaxIncluded
                                }else{
                                    body.previousAuctionValue.valueAddedTaxIncluded = true
                                }
                            }
                            const response = await fetch(endPoint, {
                                method: "POST",
                                body: JSON.stringify(body),
                                headers: {
                                    'Authorization': props.token.access_token,
                                    'Content-Type': 'application/json'
                                }
                            }).catch(console.error)
                            if(response === undefined || response === 'undefined'){
                            }else{
                                const json = await response.json()
                                if(json.hasOwnProperty('message') === true){
                                    let tmp = JSON.stringify({title: 'Помилка при створенні знижки', message: JSON.stringify(json)})
                                    props.setUniversalError(tmp)
                                }else{
                                    props.changeDiscountPreviousAuctionCurrency('null')
                                    props.changeDiscountPreviousAuctionAmount('')
                                    props.changeDiscountPercent('')
                                    props.changeDiscountPreviousAuctionId('')
                                    //props.discountAuc(false)
                                }
                            }
                        }else{
                            if(props.auctionsMy.discount !== null){
                                const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${auctionId}/discount`
                                const response = await fetch(endPoint, {
                                    method: "DELETE",
                                    headers: {
                                        'Authorization': props.token.access_token,
                                        'Content-Type': 'application/json'
                                    }
                                }).catch(console.error)
                                if(response === undefined || response === 'undefined'){
                                }else{
                                    const json = await response.json()
                                    if(json.hasOwnProperty('message') === true){
                                        let tmp = JSON.stringify({title: 'Помилка при створенні знижки', message: JSON.stringify(json)})
                                        props.setUniversalError(tmp)
                                    }else{
                                        props.changeDiscountPreviousAuctionCurrency('null')
                                        props.changeDiscountPreviousAuctionAmount('')
                                        props.changeDiscountPercent('')
                                        props.changeDiscountPreviousAuctionId('')
                                        //props.discountAuc(false)
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }else{
        if(props.auctionsMy.discount !== null){
            const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${auctionId}/discount`
            const response = await fetch(endPoint, {
                method: "DELETE",
                headers: {
                    'Authorization': props.token.access_token,
                    'Content-Type': 'application/json'
                }
            }).catch(console.error)
            if(response === undefined || response === 'undefined'){
            }else{
                const json = await response.json()
                if(json.hasOwnProperty('message') === true){
                    let tmp = JSON.stringify({title: 'Помилка при створенні знижки', message: JSON.stringify(json)})
                    props.setUniversalError(tmp)
                }else{
                    props.changeDiscountPreviousAuctionCurrency('null')
                    props.changeDiscountPreviousAuctionAmount('')
                    props.changeDiscountPercent('')
                    props.changeDiscountPreviousAuctionId('')
                    props.discountAuc(false)
                }
            }
        }
    }
}

async function deleteDiscountForAuction(props, auctionId){
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${auctionId}/discount`
    const response = await fetch(endPoint, {
        method: "DELETE",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){
            let tmp = JSON.stringify({title: 'Помилка при видаленні знижки', message: JSON.stringify(json)})
            props.setUniversalError(tmp)
        }else{
            props.changeDiscountPreviousAuctionCurrency('null')
            props.changeDiscountPreviousAuctionAmount('')
            props.changeDiscountPercent('')
            props.changeDiscountPreviousAuctionId('')
            //props.discountAuc(false)
        }
    }
}

async function editDiscountForAuction(props, auctionId){
    if(props.discount.discountOnOff === true){
        if(
            isBSE(props.auctionsMy.sellingMethod) || isBSD(props.auctionsMy.sellingMethod) ||
            isALE(props.auctionsMy.sellingMethod) ||
            isCSE(props.auctionsMy.sellingMethod) || isCSD(props.auctionsMy.sellingMethod) ||
            isBRE(props.auctionsMy.sellingMethod) || isBRD(props.auctionsMy.sellingMethod) ||
            isNLE(props.auctionsMy.sellingMethod) || isNLD(props.auctionsMy.sellingMethod)
        ){
            if(props.discount.previousAuctionId !== ''){
                let body = {}
                if(props.discount.discountPercent !== '' && props.discount.discountPercent !== null && props.discount.discountPercent !== 0 && props.discount.discountPercent !== '0'){
                    body.discount = true
                    if(props.discount.discountPercent !== '' && props.discount.discountPercent !== null){
                        body.discountPercent = props.discount.discountPercent
                    }else{
                        body.discountPercent = '0'
                    }
                    if(props.discount.previousAuctionValue.amount !== '' && props.discount.previousAuctionValue.amount !== null){
                        body.previousAuctionValue = {}
                        body.previousAuctionValue.currency = 'UAH'
                        body.previousAuctionValue.amount = props.discount.previousAuctionValue.amount
                        if(props.discount.previousAuctionValue.valueAddedTaxIncluded !== '' && props.discount.previousAuctionValue.valueAddedTaxIncluded !== null){
                            if(props.discount.previousAuctionValue.valueAddedTaxIncluded === true || props.discount.previousAuctionValue.valueAddedTaxIncluded === 'true'){
                                body.previousAuctionValue.valueAddedTaxIncluded = true
                            }else{
                                body.previousAuctionValue.valueAddedTaxIncluded = false
                            }
                        }else{
                            body.previousAuctionValue.valueAddedTaxIncluded = true
                        }
                    }
                    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${auctionId}/discount`
                    const response = await fetch(endPoint, {
                        method: "PUT",
                        body: JSON.stringify(body),
                        headers: {
                            'Authorization': props.token.access_token,
                            'Content-Type': 'application/json'
                        }
                    }).catch(console.error)
                    if(response === undefined || response === 'undefined'){
                    }else{
                        const json = await response.json()
                        if(json.hasOwnProperty('message') === true){
                            let tmp = JSON.stringify({title: 'Помилка при створенні знижки', message: JSON.stringify(json)})
                            props.setUniversalError(tmp)
                        }else{
                            props.changeDiscountPreviousAuctionCurrency('null')
                            props.changeDiscountPreviousAuctionAmount('')
                            props.changeDiscountPercent('')
                            props.changeDiscountPreviousAuctionId('')
                            props.discountAuc(false)
                        }
                    }
                }else{
                    if(props.auctionsMy.discount !== null){
                        const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${auctionId}/discount`
                        const response = await fetch(endPoint, {
                            method: "DELETE",
                            headers: {
                                'Authorization': props.token.access_token,
                                'Content-Type': 'application/json'
                            }
                        }).catch(console.error)
                        if(response === undefined || response === 'undefined'){
                        }else{
                            const json = await response.json()
                            if(json.hasOwnProperty('message') === true){
                                let tmp = JSON.stringify({title: 'Помилка при створенні знижки', message: JSON.stringify(json)})
                                props.setUniversalError(tmp)
                            }else{
                                props.changeDiscountPreviousAuctionCurrency('null')
                                props.changeDiscountPreviousAuctionAmount('')
                                props.changeDiscountPercent('')
                                props.changeDiscountPreviousAuctionId('')
                                props.discountAuc(false)
                            }
                        }
                    }
                }
            }
        }else if(
            props.auctionsMy.sellingMethod !== "landRental-english" &&
            props.auctionsMy.sellingMethod !== "landRental-english-fast" &&
            props.auctionsMy.sellingMethod !== "landRental-english-fast-manual" &&
            props.auctionsMy.sellingMethod !== "landRental-english-fast-auction-manual-qualification" &&
            props.auctionsMy.sellingMethod !== "landRental-english-initial-auction" &&
            props.auctionsMy.sellingMethod !== "landRental-english-initial-auction-manual" &&
            props.auctionsMy.sellingMethod !== "landRental-english-initial-qualification" &&
            props.auctionsMy.sellingMethod !== "landRental-english-initial-qualification-prod"
        ){
            if(props.auctionsMy.tenderAttempts > 1){
                let body = {}
                if(props.discount.discountPercent !== '' && props.discount.discountPercent !== null && props.discount.discountPercent !== 0 && props.discount.discountPercent !== '0'){
                    body.discount = true
                    if(props.discount.discountPercent !== '' && props.discount.discountPercent !== null){
                        body.discountPercent = props.discount.discountPercent
                    }else{
                        body.discountPercent = '0'
                    }
                    if(props.discount.previousAuctionValue.amount !== '' && props.discount.previousAuctionValue.amount !== null){
                        body.previousAuctionValue = {}
                        body.previousAuctionValue.currency = 'UAH'
                        body.previousAuctionValue.amount = props.discount.previousAuctionValue.amount
                        if(props.discount.previousAuctionValue.valueAddedTaxIncluded !== '' && props.discount.previousAuctionValue.valueAddedTaxIncluded !== null){
                            if(props.discount.previousAuctionValue.valueAddedTaxIncluded === true || props.discount.previousAuctionValue.valueAddedTaxIncluded === 'true'){
                                body.previousAuctionValue.valueAddedTaxIncluded = true
                            }else{
                                body.previousAuctionValue.valueAddedTaxIncluded = false
                            }
                        }else{
                            body.previousAuctionValue.valueAddedTaxIncluded = true
                        }
                    }
                    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${auctionId}/discount`
                    const response = await fetch(endPoint, {
                        method: "PUT",
                        body: JSON.stringify(body),
                        headers: {
                            'Authorization': props.token.access_token,
                            'Content-Type': 'application/json'
                        }
                    }).catch(console.error)
                    if(response === undefined || response === 'undefined'){
                    }else{
                        const json = await response.json()
                        if(json.hasOwnProperty('message') === true){
                            let tmp = JSON.stringify({title: 'Помилка при створенні знижки', message: JSON.stringify(json)})
                            props.setUniversalError(tmp)
                        }else{
                            props.changeDiscountPreviousAuctionCurrency('null')
                            props.changeDiscountPreviousAuctionAmount('')
                            props.changeDiscountPercent('')
                            props.changeDiscountPreviousAuctionId('')
                            props.discountAuc(false)
                        }
                    }
                }else{
                    if(props.auctionsMy.discount !== null){
                        const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${auctionId}/discount`
                        const response = await fetch(endPoint, {
                            method: "DELETE",
                            headers: {
                                'Authorization': props.token.access_token,
                                'Content-Type': 'application/json'
                            }
                        }).catch(console.error)
                        if(response === undefined || response === 'undefined'){
                        }else{
                            const json = await response.json()
                            if(json.hasOwnProperty('message') === true){
                                let tmp = JSON.stringify({title: 'Помилка при створенні знижки', message: JSON.stringify(json)})
                                props.setUniversalError(tmp)
                            }else{
                                props.changeDiscountPreviousAuctionCurrency('null')
                                props.changeDiscountPreviousAuctionAmount('')
                                props.changeDiscountPercent('')
                                props.changeDiscountPreviousAuctionId('')
                                props.discountAuc(false)
                            }
                        }
                    }
                }
            }
        }
    }else{
        if(props.auctionsMy.discount !== null){
            const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${auctionId}/discount`
            const response = await fetch(endPoint, {
                method: "DELETE",
                headers: {
                    'Authorization': props.token.access_token,
                    'Content-Type': 'application/json'
                }
            }).catch(console.error)
            if(response === undefined || response === 'undefined'){
            }else{
                const json = await response.json()
                if(json.hasOwnProperty('message') === true){
                    let tmp = JSON.stringify({title: 'Помилка при створенні знижки', message: JSON.stringify(json)})
                    props.setUniversalError(tmp)
                }else{
                    props.changeDiscountPreviousAuctionCurrency('null')
                    props.changeDiscountPreviousAuctionAmount('')
                    props.changeDiscountPercent('')
                    props.changeDiscountPreviousAuctionId('')
                    props.discountAuc(false)
                }
            }
        }
    }
    props.discountAuc(false)
    props.changeDiscountPreviousAuctionId(null)
    props.changeDiscountPercent(null)
    props.changeDiscountPreviousAuctionAmount(null)
    props.setDataAddress('null', 'CREATE_AUCTION_RAILWAY_DUTCH_STEP_VALUE_ADDED_TAX_INCLUDED')
}


async function getAucionsNewData(props, id){
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/auction/${id}`;
    const response = await fetch(endPoint, {
        method: "GET",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){
            let tmp = JSON.stringify({title: 'Помилка при завантаженні аукціонів', message: JSON.stringify(json)})
            props.setUniversalError(tmp)
        }else{
            props.setDataAddress(json, 'EDIT_AUCTION_FROM_ID')
            return json
        }
    }
}

function dutchData(props){
    switch(props.auctionsMy.sellingMethod){
        case "railwayCargo-dutch":
        case "railwayCargo-dutch-fast":
        case "railwayCargo-dutch-fast-manual":
        case "railwayCargo-dutch-initial-auction":
        case "railwayCargo-dutch-initial-qualification":
        case "railwayCargo-dutch-fast-auction-manual-qualification":
        case "railwayCargo-dutch-initial-auction-manual":
        case "subsoil-dutch":
        case "subsoil-dutch-fast":
        case "subsoil-dutch-fast-manual":
        case "subsoil-dutch-initial-auction":
        case "subsoil-dutch-initial-qualification":
        case "subsoil-dutch-initial-auction-manual":
        case "subsoil-english-fast-auction-manual-qualification":
        case "dgf-dutch":
        case "dgf-dutch-fast":
        case "dgf-dutch-fast-manual":
        case "dgf-dutch-fast-auction-manual-qualification":
        case "dgf-dutch-initial-auction":
        case "dgf-dutch-initial-qualification":
        case "dgf-dutch-initial-auction-manual":
        case "legitimatePropertyLease-dutch":
        case "legitimatePropertyLease-dutch-fast":
        case "legitimatePropertyLease-dutch-fast-manual":
        case "legitimatePropertyLease-dutch-fast-auction-manual-qualification":
        case "legitimatePropertyLease-dutch-initial-auction":
        case "legitimatePropertyLease-dutch-initial-auction-manual":
        case "legitimatePropertyLease-dutch-initial-qualification-prod":
        case 'basicSell-dutch':
        case 'basicSell-dutch-fast':
        case 'basicSell-dutch-fast-manual':
        case 'basicSell-dutch-fast-auction-manual-qualification':
        case 'basicSell-dutch-initial-auction':
        case 'basicSell-dutch-initial-auction-manual':
        case 'basicSell-dutch-initial-qualification':
        case 'basicSell-dutch-initial-qualification-prod':
        case 'alienation-english':
        case 'alienation-english-fast':
        case 'alienation-english-fast-manual':
        case 'alienation-english-fast-auction-manual-qualification':
        case 'alienation-english-initial-auction':
        case 'alienation-english-initial-auction-manual':
        case 'alienation-english-initial-qualification':
        case 'alienation-english-initial-qualification-prod':
        case 'commercialSell-dutch':
        case 'commercialSell-dutch-fast':
        case 'commercialSell-dutch-fast-manual':
        case 'commercialSell-dutch-fast-auction-manual-qualification':
        case 'commercialSell-dutch-initial-auction':
        case 'commercialSell-dutch-initial-auction-manual':
        case 'commercialSell-dutch-initial-qualification':
        case 'commercialSell-dutch-initial-qualification-prod':
        case 'bankRuptcy-dutch':
        case 'bankRuptcy-dutch-fast':
        case 'bankRuptcy-dutch-fast-manual':
        case 'bankRuptcy-dutch-fast-auction-manual-qualification':
        case 'bankRuptcy-dutch-initial-auction':
        case 'bankRuptcy-dutch-initial-auction-manual':
        case 'bankRuptcy-dutch-initial-qualification':
        case 'bankRuptcy-dutch-initial-qualification-prod':
        case 'basicSell-dutch':
        case 'basicSell-dutch-fast':
        case 'basicSell-dutch-fast-manual':
        case 'basicSell-dutch-fast-auction-manual-qualification':
        case 'basicSell-dutch-initial-auction':
        case 'basicSell-dutch-initial-auction-manual':
        case 'basicSell-dutch-initial-qualification':
        case 'basicSell-dutch-initial-qualification-prod':
            return true
        default:
            return false
    }
}

function returnDataRailway(props){
    switch(props.auctionsMy.sellingMethod){
        case "railwayCargo-english":
        case "railwayCargo-english-fast":
        case "railwayCargo-english-fast-manual":
        case "railwayCargo-english-initial-auction":
        case "railwayCargo-english-initial-qualification":
        case "railwayCargo-dutch":
        case "railwayCargo-dutch-fast":
        case "railwayCargo-dutch-fast-manual":
        case "railwayCargo-dutch-initial-auction":
        case "railwayCargo-dutch-initial-qualification":
        case "railwayCargo-dutch-fast-auction-manual-qualification":
        case "railwayCargo-dutch-initial-auction-manual":
        case "legitimatePropertyLease-english":
        case "legitimatePropertyLease-english-fast":
        case "legitimatePropertyLease-english-fast-manual":
        case "legitimatePropertyLease-english-fast-auction-manual-qualification":
        case "legitimatePropertyLease-english-initial-auction":
        case "legitimatePropertyLease-english-initial-auction-manual":
        case "legitimatePropertyLease-english-initial-qualification":
        case "legitimatePropertyLease-english-initial-qualification-prod":
        case "legitimatePropertyLease-dutch":
        case "legitimatePropertyLease-dutch-fast":
        case "legitimatePropertyLease-dutch-fast-manual":
        case "legitimatePropertyLease-dutch-fast-auction-manual-qualification":
        case "legitimatePropertyLease-dutch-initial-auction":
        case "legitimatePropertyLease-dutch-initial-auction-manual":
        case "legitimatePropertyLease-dutch-initial-qualification-prod":
        case "legitimatePropertyLease-priorityEnglish":
        case "legitimatePropertyLease-priorityEnglish-fast":
        case "legitimatePropertyLease-priorityEnglish-fast-manual":
        case "legitimatePropertyLease-priorityEnglish-fast-auction-manual-qualification":
        case "legitimatePropertyLease-priorityEnglish-initial-auction":
        case "legitimatePropertyLease-priorityEnglish-initial-auction-manual":
        case "legitimatePropertyLease-priorityEnglish-initial-qualification":
        case "legitimatePropertyLease-priorityEnglish-initial-qualification-prod":
        case 'landRental-english':
        case 'landRental-english-fast':
        case 'landRental-english-fast-manual':
        case 'landRental-english-fast-auction-manual-qualification':
        case 'landRental-english-initial-auction':
        case 'landRental-english-initial-auction-manual':
        case 'landRental-english-initial-qualification':
        case 'landRental-english-initial-qualification-prod':

            return props.auctionsMy.minNumberOfQualifiedBids
        default:
            return 1
    }
}

///////////////////////////////////////////////////////
//КЛОНИРОВАНИЕ ИТЕМОВ
//////////////////////////////////////////////////////
async function createItemZU(props, item, auctionId){
    if(item.itemType === 'otherProperty'){
        createItemZUOtherPropertyItem(props, item, auctionId)
    }else if(item.itemType === 'jointPropertyComplex'){
        createItemZUJointPropertyComplexItem(props, item, auctionId)
    }else if(item.itemType === 'vehicle'){
        createItemZUVehicleItem(props, item, auctionId)
    }else if(item.itemType === 'realEstate'){
        createItemZURealEstateItem(props, item, auctionId)
    }
}
async function createItemZUOtherPropertyItem(props, item, auctionId){
    if(props.userProfiles !== null){
        if(props.userProfiles.organization.address.id !== null){
            let body = {}
            body.title = item.title
            body.description = item.description
            body.classificationId = Number(item.classification.id)
            body.additionalClassificationIds = item.additionalClassification
            body.addressId = Number(item.address.id)
            if(item.location !== null){
                body.location = {
                    latitude: Number(item.location.latitude),
                    longitude: Number(item.location.longitude),
                    //elevation: item.location.elevation
                }
            }
            body.itemType = item.itemType
            body.quantity = item.quantity
            body.unitCode = item.unit.code
            const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${auctionId}/item`;
            const response = await fetch(endPoint, {
                method: "POST",
                body: JSON.stringify(body),
                headers: {
                    'Authorization': props.token.access_token,
                    'Content-Type': 'application/json'
                }
            }).catch(console.error)
            if(response === undefined || response === 'undefined'){
            }else{
                const json1 = await response.json()
                if(json1.hasOwnProperty('message') === true){
                    props.changeLoader(false)
                }else{
                    if(item.registrationDetails !== null){
                        await createRegDetal(props, auctionId, json1.id, item)
                    }
                }
                /*let header = {
                    'Authorization': props.token.access_token,
                    'Content-Type': 'application/json'
                }
                const endPoint2 = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${auctionId}`;
                props.changeLoader(true)
                const response2 = await fetch(endPoint2, {
                        method: "GET",
                        headers: header
                }).catch(console.error)
                if(response2 === undefined || response2 === 'undefined'){
                }else{
                    const json2 = await response2.json()
                    if(json2.hasOwnProperty('message') === true){
                        props.changeLoader(false)
                    }else{
                        //props.getMyAuction(json2)
                        props.changeLoader(false)
                    }
                }*/
            }
        }
    }
}

async function createItemZUVehicleItem(props, item, auctionId){
    if(props.userProfiles !== null){
        if(props.userProfiles.organization.address.id !== null){
            let body = {}
            body.title = item.title
            body.description = item.description
            body.classificationId = Number(item.classification.id)
            body.additionalClassificationIds = item.additionalClassification
            body.addressId = Number(item.address.id)
            if(item.location !== null){
                body.location = {
                    latitude: Number(item.location.latitude),
                    longitude: Number(item.location.longitude),
                    //elevation: item.location.elevation
                }
            }
            body.itemType = item.itemType
            body.quantity = item.quantity
            body.unitCode = item.unit.code
            const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${auctionId}/item`;
            const response = await fetch(endPoint, {
                method: "POST",
                body: JSON.stringify(body),
                headers: {
                    'Authorization': props.token.access_token,
                    'Content-Type': 'application/json'
                }
            }).catch(console.error)
            if(response === undefined || response === 'undefined'){
            }else{
                const json = await response.json()
                if(item.registrationDetails !== null){
                    await createRegDetal(props, auctionId, json.id, item)
                }
                if(item.vehicleProps !== null){
                    await saveItemZuVehicleProps(props, item.vehicleProps, auctionId, json.id)
                }
            }
        }
    }
}
async function saveItemZuVehicleProps(props, item, auctionId, itemId){
    let body = {}
    body = {
        brand: item.brand,
        model: item.model,
        productionYear: item.productionYear,
        engineCapacity: item.engineCapacity,
        fuelType: item.fuelType,
        transmission: item.transmission,
        color: item.color,
        kilometrage: item.kilometrage,
        identificationNumber: item.identificationNumber,
        configuration: item.configuration,
        condition: item.condition,
        damagedDescription: item.damagedDescription,
        damagePresence: item.damagePresence,
    }
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${auctionId}/item/${itemId}/property_props/VehicleProps`;
    const response = await fetch(endPoint, {
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        /*let header = {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
        const endPoint2t = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${auctionId}`;
        props.changeLoader(true)
        const response2t = await fetch(endPoint2t, {
                method: "GET",
                headers: header
        }).catch(console.error)
        if(response2t === undefined || response2t === 'undefined'){
        }else{
            const json2t = await response2t.json()
            if(json2t.hasOwnProperty('message') === true){
                props.changeLoader(false)
            }else{
                props.getMyAuction(json2t)
                props.changeLoader(false)
            }
        }*/
    }
}

async function createItemZURealEstateItem(props, item, auctionId){
    if(props.userProfiles !== null){
        if(props.userProfiles.organization.address.id !== null){
            let body = {}
            body.title = item.title
            body.description = item.description
            body.classificationId = Number(item.classification.id)
            body.additionalClassificationIds = item.additionalClassification
            body.addressId = Number(item.address.id)
            if(item.location !== null){
                body.location = {
                    latitude: Number(item.location.latitude),
                    longitude: Number(item.location.longitude),
                    //elevation: item.location.elevation
                }
            }
            body.itemType = item.itemType
            body.unitCode = item.unit.code
            const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${auctionId}/item`;
            const response = await fetch(endPoint, {
                method: "POST",
                body: JSON.stringify(body),
                headers: {
                    'Authorization': props.token.access_token,
                    'Content-Type': 'application/json'
                }
            }).catch(console.error)
            if(response === undefined || response === 'undefined'){
            }else{
                const json = await response.json()
                if(item.registrationDetails !== null){
                    await createRegDetal(props, auctionId, json.id, item)
                }
                if(item.realEstateProps !== null){
                    await saveItemZuReProps(props, item.realEstateProps, auctionId, json.id)
                }
            }
        }
    }
}
async function saveItemZuReProps(props, item, auctionId, itemId){
    let body = {}
    body = {
        totalBuildingArea: item.totalBuildingArea,
        totalObjectArea: item.totalObjectArea,
        usableArea: item.usableArea,
        constructionYear: item.constructionYear,
        constructionTechnology: item.constructionTechnology,
        livingArea: item.livingArea,
        kitchenArea: item.kitchenArea,
        landArea: item.landArea,
        locationInBuilding: item.locationInBuilding,
        floors: item.floors,
        generalCondition: item.generalCondition,
        serviceElectricity: item.serviceElectricity,
        powerSupplyCapacity: item.powerSupplyCapacity,
        powerSupplyClass: item.powerSupplyClass,
        serviceWater: item.serviceWater,
        serviceSewerage: item.serviceSewerage,
        serviceGas: item.serviceGas,
        serviceCentralHeating: item.serviceCentralHeating,
        serviceAutonomousHeating: item.serviceAutonomousHeating,
        serviceHeatingCounter: item.serviceHeatingCounter,
        serviceVentilation: item.serviceVentilation,
        serviceAirConditioning: item.serviceAirConditioning,
        servicePhone: item.servicePhone,
        serviceTV: item.serviceTV,
        serviceInternet: item.serviceInternet,
        serviceElevator: item.serviceElevator,
        serviceSecurityAlarm: item.serviceSecurityAlarm,
        serviceFireAlarm: item.serviceFireAlarm,
        servicesDescription: item.servicesDescription,
        servicesAccounting: item.servicesAccounting,
        landTax: item.landTax,
    }
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${auctionId}/item/${itemId}/property_props/RealEstateProps`;
    const response = await fetch(endPoint, {
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        /*let header = {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
        const endPoint2t = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${auctionId}`;
        props.changeLoader(true)
        const response2t = await fetch(endPoint2t, {
                method: "GET",
                headers: header
        }).catch(console.error)
        if(response2t === undefined || response2t === 'undefined'){
        }else{
            const json2t = await response2t.json()
            if(json2t.hasOwnProperty('message') === true){
                props.changeLoader(false)
            }else{
                props.getMyAuction(json2t)
                props.changeLoader(false)
            }
        }*/
    }
}

async function createItemZUJointPropertyComplexItem(props, item, auctionId){
    if(props.userProfiles !== null){
        if(props.userProfiles.organization.address.id !== null){
            let body = {}
            body.title = item.title
            body.description = item.description
            body.classificationId = Number(item.classification.id)
            body.additionalClassificationIds = item.additionalClassification
            body.addressId = Number(item.address.id)
            if(item.location !== null){
                body.location = {
                    latitude: Number(item.location.latitude),
                    longitude: Number(item.location.longitude),
                    //elevation: item.location.elevation
                }
            }
            body.itemType = item.itemType
            body.quantity = item.quantity
            body.unitCode = item.unit.code
            const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${auctionId}/item`;
            const response = await fetch(endPoint, {
                method: "POST",
                body: JSON.stringify(body),
                headers: {
                    'Authorization': props.token.access_token,
                    'Content-Type': 'application/json'
                }
            }).catch(console.error)
            if(response === undefined || response === 'undefined'){
            }else{
                const json = await response.json()
                if(json.hasOwnProperty('message') === true){
                    let tmp = JSON.stringify({title: 'Помилка', message: JSON.stringify(json)})
                    props.setUniversalError(tmp)
                }
                if(item.registrationDetails !== null){
                    await createRegDetal(props, auctionId, json.id, item)
                }
                if(item.jointPropertyComplexProps !== null){
                    await saveItemZuJointPropertyComplexProps(props, item.jointPropertyComplexProps, auctionId, json.id)
                }
                //await saveItemZuJointPropertyComplexPropsLand(props, json.id, 'POST')
            }
        }
    }
}
async function saveItemZuJointPropertyComplexProps(props, item, auctionId, itemId){
    let body = {}
    body = {
        quantityAndNomenclature: item.quantityAndNomenclature,
        workplacesQuantity: item.workplacesQuantity,
        buildingsInformation: item.buildingsInformation,
        landPlotInformation: item.landPlotInformation,
        mainObligations: item.mainObligations
    }
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${auctionId}/item/${itemId}/property_props/JointPropertyComplexProps`;
    const response = await fetch(endPoint, {
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){
            let tmp = JSON.stringify({title: 'Помилка', message: JSON.stringify(json)})
	        props.setUniversalError(tmp)
        }
        if(item.landProps !== null){
            await saveItemZuJointPropertyComplexPropsLand(props, item.landProps, auctionId, itemId)
        }
    }
}
async function saveItemZuJointPropertyComplexPropsLand(props, item, auctionId, itemId){
    let body = {}
    body = {
        landArea: item.landArea,
        cadastralNumber: item.cadastralNumber,
        ownershipType: item.ownershipType,
        encumbrances: item.encumbrances,
        jointOwnership: item.jointOwnership,
        utilitiesAvailability: item.utilitiesAvailability,
        landIntendedUse: item.landIntendedUse
    }
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${auctionId}/item/${itemId}/property_props/JointPropertyComplexProps/land_props`;
    const response = await fetch(endPoint, {
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        /*let header = {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
        const endPoint2t = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${auctionId}`;
        props.changeLoader(true)
        const response2t = await fetch(endPoint2t, {
                method: "GET",
                headers: header
        }).catch(console.error)
        if(response2t === undefined || response2t === 'undefined'){
        }else{
            const json2t = await response2t.json()
            if(json2t.hasOwnProperty('message') === true){
                props.changeLoader(false)
            }else{
                props.getMyAuction(json2t)
                props.changeLoader(false)
            }
        }*/
    }
}

async function createRegDetal(props, auctionId, jsonId, item){
    if(props.userProfiles !== null){
        if(props.userProfiles.organization.address.id !== null){
            const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${auctionId}/item/${jsonId}/registration_details`;
            const response = await fetch(endPoint, {
                method: "POST",
                body: JSON.stringify({
                    registrationStatus: item.registrationDetails.registrationStatus,
                    registrationID: item.registrationDetails.registrationID,
                    registrationDate: item.registrationDetails.registrationDate,
                }),
                headers: {
                    'Authorization': props.token.access_token,
                    'Content-Type': 'application/json'
                }
            }).catch(console.error)
            if(response === undefined || response === 'undefined'){
            }else{
                //const json = await response.json()
            }
        }
    }
}

async function editAuc(props, aucId=null){
    let endPoint = null;
    if(aucId === null){
        endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/auction/${props.auctionsMy.id}`
    }else{
        endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/auction/${aucId}`
    }
    let tmpDocumentationValue = null;
    let tmpGeologivDataValue = null;
    let tmpGuarantee = null;
    let tmpMinStep = null;
    let tmpValue = null;
    switch(props.auctionsMy.sellingMethod){
        case "renewables":
        case "renewables-fast":
        case "renewables-fast-manual":
        case "renewables-initial-auction":
        case "renewables-initial-qualification":
        case "renewables-initial-qualification-fast":
            tmpGuarantee = {
                currency: 'EUR',
                amount: Number(props.auctionsMy.guarantee.amount),
            }
            tmpMinStep = {
                currency: 'EUR',
                amount: Number(props.auctionsMy.minimalStep.amount),
            }
            tmpValue = {
                currency: 'eurocent',
                amount: Number(props.auctionsMy.value.amount),
            }
            break;
        case "subsoil-english":
        case "subsoil-english-fast":
        case "subsoil-english-fast-manual":
        case "subsoil-english-initial-auction":
        case "subsoil-english-initial-qualification":
        case "subsoil-dutch":
        case "subsoil-dutch-fast":
        case "subsoil-dutch-fast-manual":
        case "subsoil-dutch-initial-auction":
        case "subsoil-dutch-initial-qualification":
        case "subsoil-dutch-initial-auction-manual":
        case "subsoil-english-fast-auction-manual-qualification":
            if(props.auctionsMy.documentationValue !== null && props.auctionsMy.documentationValue !== ''){
                tmpDocumentationValue = {currency: 'UAH', amount: props.auctionsMy.documentationValue.amount}
            }else{
                tmpDocumentationValue = null
            }
            if(props.auctionsMy.geologicDataValue !== null && props.auctionsMy.geologicDataValue !== ''){
                tmpGeologivDataValue = {currency: 'UAH', amount: Number(props.auctionsMy.geologicDataValue.amount)}
            }else{
                tmpGeologivDataValue = null
            }
            if(props.auctionsMy.guarantee !== null && props.auctionsMy.guarantee !== ''){
                tmpGuarantee = {currency: 'UAH', amount: Number(props.auctionsMy.guarantee.amount)}
            }else{
                tmpGuarantee = null
            }
            if(props.auctionsMy.minimalStep !== null && props.auctionsMy.minimalStep !== ''){
                tmpMinStep = {currency: 'UAH', amount: Number(props.auctionsMy.minimalStep.amount)}
            }else{
                tmpMinStep = null
            }
            if(props.auctionsMy.value !== null && props.auctionsMy.value !== ''){
                tmpValue = {currency: 'UAH', amount: Number(props.auctionsMy.value.amount)}
            }else{
                tmpValue = null
            }
            break;
        case "timber-english":
        case "timber-english-fast":
        case "timber-english-fast-manual":
        case "timber-english-initial-auction":
        case "timber-english-initial-qualification":
        case "timber-english-initial-qualification-fast":
        case "railwayCargo-english":
        case "railwayCargo-english-fast":
        case "railwayCargo-english-fast-manual":
        case "railwayCargo-english-initial-auction":
        case "railwayCargo-english-initial-qualification":
        case "railwayCargo-dutch":
        case "railwayCargo-dutch-fast":
        case "railwayCargo-dutch-fast-manual":
        case "railwayCargo-dutch-initial-auction":
        case "railwayCargo-dutch-initial-qualification":
        case "railwayCargo-dutch-fast-auction-manual-qualification":
        case "railwayCargo-dutch-initial-auction-manual":
        case "dgf-english":
        case "dgf-english-fast":
        case "dgf-english-fast-manual":
        case "dgf-english-fast-auction-manual-qualification":
        case "dgf-english-initial-auction":
        case "dgf-english-initial-auction-manual":
        case "dgf-english-initial-qualification":
        case "dgf-dutch":
        case "dgf-dutch-fast":
        case "dgf-dutch-fast-manual":
        case "dgf-dutch-fast-auction-manual-qualification":
        case "dgf-dutch-initial-auction":
        case "dgf-dutch-initial-qualification":
        case "dgf-dutch-initial-auction-manual":
        /*case "legitimatePropertyLease-english":
        case "legitimatePropertyLease-english-fast":
        case "legitimatePropertyLease-english-fast-manual":
        case "legitimatePropertyLease-english-fast-auction-manual-qualification":
        case "legitimatePropertyLease-english-initial-auction":
        case "legitimatePropertyLease-english-initial-auction-manual":
        case "legitimatePropertyLease-english-initial-qualification":
        case "legitimatePropertyLease-english-initial-qualification-prod":
        case "legitimatePropertyLease-dutch":
        case "legitimatePropertyLease-dutch-fast":
        case "legitimatePropertyLease-dutch-fast-manual":
        case "legitimatePropertyLease-dutch-fast-auction-manual-qualification":
        case "legitimatePropertyLease-dutch-initial-auction":
        case "legitimatePropertyLease-dutch-initial-auction-manual":
        case "legitimatePropertyLease-dutch-initial-qualification-prod":
        case "legitimatePropertyLease-priorityEnglish":
        case "legitimatePropertyLease-priorityEnglish-fast":
        case "legitimatePropertyLease-priorityEnglish-fast-manual":
        case "legitimatePropertyLease-priorityEnglish-fast-auction-manual-qualification":
        case "legitimatePropertyLease-priorityEnglish-initial-auction":
        case "legitimatePropertyLease-priorityEnglish-initial-auction-manual":
        case "legitimatePropertyLease-priorityEnglish-initial-qualification":
        case "legitimatePropertyLease-priorityEnglish-initial-qualification-prod":*/
        case 'landRental-english':
        case 'landRental-english-fast':
        case 'landRental-english-fast-manual':
        case 'landRental-english-fast-auction-manual-qualification':
        case 'landRental-english-initial-auction':
        case 'landRental-english-initial-auction-manual':
        case 'landRental-english-initial-qualification':
        case 'landRental-english-initial-qualification-prod':
        case 'landSell-english':
        case 'landSell-english-fast':
        case 'landSell-english-fast-manual':
        case 'landSell-english-fast-auction-manual-qualification':
        case 'landSell-english-initial-auction':
        case 'landSell-english-initial-auction-manual':
        case 'landSell-english-initial-qualification':
        case 'landSell-english-initial-qualification-prod':
        case 'landSell-priorityEnglish':
        case 'landSell-priorityEnglish-fast':
        case 'landSell-priorityEnglish-fast-manual':
        case 'landSell-priorityEnglish-fast-auction-manual-qualification':
        case 'landSell-priorityEnglish-initial-auction':
        case 'landSell-priorityEnglish-initial-auction-manual':
        case 'landSell-priorityEnglish-initial-qualification':
        case 'landSell-priorityEnglish-initial-qualification-prod':
            tmpGuarantee = {
                currency: 'UAH',
                amount: Number(props.auctionsMy.guarantee.amount),
                //valueAddedTaxIncluded: props.auctionsMy.guarantee.valueAddedTaxIncluded,
            }
            tmpMinStep = {
                currency: 'UAH',
                amount: Number(props.auctionsMy.minimalStep.amount),
                //valueAddedTaxIncluded: props.auctionsMy.minimalStep.valueAddedTaxIncluded,
            }
            let tmpValueAddedTaxIncluded = null
            if(props.auctionsMy.value !== null){
                if(props.auctionsMy.value.valueAddedTaxIncluded === 'false'){
                    tmpValueAddedTaxIncluded = false
                }else{
                    tmpValueAddedTaxIncluded = true
                }
                tmpValue = {
                    currency: 'UAH',
                    amount: Number(props.auctionsMy.value.amount),
                    valueAddedTaxIncluded: props.auctionsMy.value.valueAddedTaxIncluded,
                }
            }
            break
        default:
            if(
                props.auctionsMy.sellingMethod !== "legitimatePropertyLease-english" &&
                props.auctionsMy.sellingMethod !== "legitimatePropertyLease-english-fast" &&
                props.auctionsMy.sellingMethod !== "legitimatePropertyLease-english-fast-manual" &&
                props.auctionsMy.sellingMethod !== "legitimatePropertyLease-english-fast-auction-manual-qualification" &&
                props.auctionsMy.sellingMethod !== "legitimatePropertyLease-english-initial-auction" &&
                props.auctionsMy.sellingMethod !== "legitimatePropertyLease-english-initial-auction-manual" &&
                props.auctionsMy.sellingMethod !== "legitimatePropertyLease-english-initial-qualification" &&
                props.auctionsMy.sellingMethod !== "legitimatePropertyLease-english-initial-qualification-prod" &&
                props.auctionsMy.sellingMethod !== "legitimatePropertyLease-dutch" &&
                props.auctionsMy.sellingMethod !== "legitimatePropertyLease-dutch-fast" &&
                props.auctionsMy.sellingMethod !== "legitimatePropertyLease-dutch-fast-manual" &&
                props.auctionsMy.sellingMethod !== "legitimatePropertyLease-dutch-fast-auction-manual-qualification" &&
                props.auctionsMy.sellingMethod !== "legitimatePropertyLease-dutch-initial-auction" &&
                props.auctionsMy.sellingMethod !== "legitimatePropertyLease-dutch-initial-auction-manual" &&
                props.auctionsMy.sellingMethod !== "legitimatePropertyLease-dutch-initial-qualification-prod" &&
                props.auctionsMy.sellingMethod !== "legitimatePropertyLease-priorityEnglish" &&
                props.auctionsMy.sellingMethod !== "legitimatePropertyLease-priorityEnglish-fast" &&
                props.auctionsMy.sellingMethod !== "legitimatePropertyLease-priorityEnglish-fast-manual" &&
                props.auctionsMy.sellingMethod !== "legitimatePropertyLease-priorityEnglish-fast-auction-manual-qualification" &&
                props.auctionsMy.sellingMethod !== "legitimatePropertyLease-priorityEnglish-initial-auction" &&
                props.auctionsMy.sellingMethod !== "legitimatePropertyLease-priorityEnglish-initial-auction-manual" &&
                props.auctionsMy.sellingMethod !== "legitimatePropertyLease-priorityEnglish-initial-qualification" &&
                props.auctionsMy.sellingMethod !== "legitimatePropertyLease-priorityEnglish-initial-qualification-prod"
            ){
                tmpGuarantee = {
                    currency: 'UAH',
                    amount: Number(props.auctionsMy.guarantee.amount),
                }
                tmpMinStep = {
                    currency: 'UAH',
                    amount: Number(props.auctionsMy.minimalStep.amount),
                }
                tmpValue = {
                    currency: 'UAH',
                    amount: Number(props.auctionsMy.value.amount),
                }
            }
            break;
    }

    let tmpCarryType = null;
    if (
        props.auctionsMy.sellingMethod.includes('railwayCargo-english') ||
        props.auctionsMy.sellingMethod.includes('railwayCargo-dutch')) {
        if (props.auctionsMy.carryType !== null) {
            tmpCarryType = props.auctionsMy.carryType
        }
    }
    let tmpBank = null;
    if(props.bankAccountIdArr === null){
        tmpBank = []
    }else{
        tmpBank = props.bankAccountIdArr
    }
    let tmpDateDay = Date.parse(props.auctionsMy.startDate);
    let tmpMonth = null;
    if(new Date(tmpDateDay).getMonth() < 10){
        tmpMonth = '0' + (new Date(tmpDateDay).getMonth() + 1)
    }else{
        tmpMonth = new Date(tmpDateDay).getMonth() + 1
    }
    let tmpDay = null;
    if(new Date(tmpDateDay).getDate() < 10){
        tmpDay = '0' + new Date(tmpDateDay).getDate()
    }else{
        tmpDay = new Date(tmpDateDay).getDate()
    }

    let minNumberOfQualifiedBids = returnDataRailway(props)
    if(minNumberOfQualifiedBids === 'null') minNumberOfQualifiedBids = null
    let tmpDateString = new Date(tmpDateDay).getFullYear() + '-' + tmpMonth + '-' + tmpDay

    /////////////////////////////////////////////
    /////////////////////////////////////////////
    let tmpBody = null
    let tmpValueAddedTaxIncluded1 = null
    if(props.auctionsMy.value !== null){
        if(props.auctionsMy.value.valueAddedTaxIncluded === 'false' || props.auctionsMy.value.valueAddedTaxIncluded === false){
            tmpValueAddedTaxIncluded1 = false
        }else{
            tmpValueAddedTaxIncluded1 = true
        }
    }
    if(Number(props.auctionsMy.tenderAttempts) !== 1){
        tmpBody = {}
        if(
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-manual" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-auction-manual-qualification" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction-manual" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification-prod"
        ){
            if(props.auctionsMy.currentTenantGuaranteeFee.amount !== ''){
                tmpBody.currentTenantGuaranteeFee = {
                    currency: 'UAH',
                    amount: Number(props.auctionsMy.currentTenantGuaranteeFee.amount)
                }
            }
        }
        if(aucId === null){
            if(props.auctionsMy.documentationValue !== null && props.auctionsMy.documentationValue !== ''){
                tmpBody.documentationValue = props.auctionsMy.documentationValue
            }
            if(props.auctionsMy.geologicDataValue !== null && props.auctionsMy.geologicDataValue !== ''){
                tmpBody.geologicDataValue = props.auctionsMy.geologicDataValue
            }
        }
        tmpBody.leaseDuration = props.auctionsMy.leaseDuration
        //tmpBody.carryTypeId = tmpCarryType
        if(tmpCarryType !== null){
            let texttmpCarryType = typeof tmpCarryType
            if(texttmpCarryType === 'string'){
                for(let tmI = 0; tmI < props.carryType.length; tmI++){
                    if(tmpCarryType === props.carryType[tmI].name){
                        tmpBody.carryType = tmpCarryType
                    }
                }
            }else if(texttmpCarryType === 'object'){
                tmpBody.carryType = tmpCarryType.name
            }
        }
        tmpBody.previousAuctionId = props.discount.previousAuctionId
        if(props.auctionsMy.title !== null && props.auctionsMy.title !== ''){
            tmpBody.title = props.auctionsMy.title
        }
        if(props.auctionsMy.description !== null && props.auctionsMy.description !== ''){
            tmpBody.description = props.auctionsMy.description
        }
        if(
            props.auctionsMy.sellingMethod !== "subsoil-english" &&
            props.auctionsMy.sellingMethod !== "subsoil-english-fast" &&
            props.auctionsMy.sellingMethod !== "subsoil-english-fast-manual" &&
            props.auctionsMy.sellingMethod !== "subsoil-english-initial-auction" &&
            props.auctionsMy.sellingMethod !== "subsoil-english-initial-qualification" &&
            props.auctionsMy.sellingMethod !== "subsoil-dutch" &&
            props.auctionsMy.sellingMethod !== "subsoil-dutch-fast" &&
            props.auctionsMy.sellingMethod !== "subsoil-dutch-fast-manual" &&
            props.auctionsMy.sellingMethod !== "subsoil-dutch-initial-auction" &&
            props.auctionsMy.sellingMethod !== "subsoil-dutch-initial-qualification" &&
            props.auctionsMy.sellingMethod !== "subsoil-dutch-initial-auction-manual" &&
            props.auctionsMy.sellingMethod !== "subsoil-english-fast-auction-manual-qualification"
        ){
            tmpBody.bankAccountIds = createArrBanks(props, 1, props.auctionsMy.id)
        }
        tmpBody.sellingMethod = props.auctionsMy.sellingMethod
        tmpBody.tenderAttempts = props.auctionsMy.tenderAttempts
        if(props.auctionsMy.accessDetails !== null && props.auctionsMy.accessDetails !== ''){
            tmpBody.accessDetails = props.auctionsMy.accessDetails
        }
        if(
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-english" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-fast" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-fast-manual" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-fast-auction-manual-qualification" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-auction" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-auction-manual" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-qualification" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-qualification-prod" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-fast" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-fast-manual" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-fast-auction-manual-qualification" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-initial-auction" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-initial-auction-manual" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-initial-qualification-prod" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-manual" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-auction-manual-qualification" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction-manual" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification-prod"
        ){
            tmpBody.martialLawDisclaimer = props.auctionsMy.martialLawDisclaimer
            tmpBody.lotIdentifier = props.auctionsMy.lotIdentifier
            //tmpBody.xDocumentRequirements = props.auctionsMy.xDocumentRequirements
        }else{
            tmpBody.lotIdentifier = props.auctionsMy.lotIdentifier
            if(props.auctionsMy.xDocumentRequirements !== null && props.auctionsMy.xDocumentRequirements !== ''){
                tmpBody.xDocumentRequirements = props.auctionsMy.xDocumentRequirements
            }
        }
        if(props.auctionsMy.xAdditionalInformation !== null && props.auctionsMy.xAdditionalInformation !== ''){
            tmpBody.xAdditionalInformation = props.auctionsMy.xAdditionalInformation
        }
        if(props.auctionsMy.value !== null){
            if(props.auctionsMy.value.amount !== null && props.auctionsMy.value.amount !== ''){
                tmpBody.value = {
                    currency: 'UAH',//props.auctionsMy.value.currency,
                    amount: Number(props.auctionsMy.value.amount),
                    valueAddedTaxIncluded: tmpValueAddedTaxIncluded1,
                }
            }
        }
        if(props.auctionsMy.guarantee !== null){
            tmpBody.guarantee = {
                    currency: 'UAH',//props.auctionsMy.guarantee.currency,
                    amount: Number(props.auctionsMy.guarantee.amount),
                    //valueAddedTaxIncluded: props.auctionsMy.guarantee.valueAddedTaxIncluded,
            }
        }
        tmpBody.minNumberOfQualifiedBids = minNumberOfQualifiedBids
        tmpBody.minimalStep = {
            currency: 'UAH',//props.auctionsMy.minimalStep.currency,
            amount: Number(props.auctionsMy.minimalStep.amount),
            //valueAddedTaxIncluded: props.auctionsMy.minimalStep.valueAddedTaxIncluded,
        }
        tmpBody.startDate = props.auctionsMy.startDate
        tmpBody.documents = props.auctionsMy.documents

    }else{
        if(props.auctionsMy.status !== null){
            tmpBody = {}
            if(
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-manual" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-auction-manual-qualification" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction-manual" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification-prod"
            ){
                if(props.auctionsMy.hasOwnProperty('normativeMonetaryValuation') === true){
                    if(props.auctionsMy.currentTenantGuaranteeFee.amount !== ''){
                        tmpBody.currentTenantGuaranteeFee = {
                            currency: 'UAH',
                            amount: Number(props.auctionsMy.currentTenantGuaranteeFee.amount)
                        }
                    }
                }
                if(tmpBody.hasOwnProperty('currentTenantGuaranteeFee') !== true) tmpBody.currentTenantGuaranteeFee = null
            }
            if(aucId === null){
                if(props.auctionsMy.documentationValue !== null && props.auctionsMy.documentationValue !== ''){
                    tmpBody.documentationValue = props.auctionsMy.documentationValue
                }
                if(props.auctionsMy.geologicDataValue !== null && props.auctionsMy.geologicDataValue !== ''){
                    tmpBody.geologicDataValue = props.auctionsMy.geologicDataValue
                }
            }
            if(props.auctionsMy.leaseDuration !== null && props.auctionsMy.leaseDuration !== ''){
                tmpBody.leaseDuration = props.auctionsMy.leaseDuration
            }
            if(tmpCarryType !== null && tmpCarryType !== ''){
                //tmpBody.carryTypeId = tmpCarryType
                let texttmpCarryType = typeof tmpCarryType
                if(texttmpCarryType === 'string'){
                    for(let tmI = 0; tmI < props.carryType.length; tmI++){
                        if(tmpCarryType === props.carryType[tmI].name){
                            tmpBody.carryType = tmpCarryType
                        }
                    }
                }else if(texttmpCarryType === 'object'){
                    tmpBody.carryType = tmpCarryType.name
                }
            }
            tmpBody.title = props.auctionsMy.title
            if(props.auctionsMy.description !== null && props.auctionsMy.description !== ''){
                tmpBody.description = props.auctionsMy.description
            }
            if(
                props.auctionsMy.sellingMethod !== "subsoil-english" &&
                props.auctionsMy.sellingMethod !== "subsoil-english-fast" &&
                props.auctionsMy.sellingMethod !== "subsoil-english-fast-manual" &&
                props.auctionsMy.sellingMethod !== "subsoil-english-initial-auction" &&
                props.auctionsMy.sellingMethod !== "subsoil-english-initial-qualification" &&
                props.auctionsMy.sellingMethod !== "subsoil-dutch" &&
                props.auctionsMy.sellingMethod !== "subsoil-dutch-fast" &&
                props.auctionsMy.sellingMethod !== "subsoil-dutch-fast-manual" &&
                props.auctionsMy.sellingMethod !== "subsoil-dutch-initial-auction" &&
                props.auctionsMy.sellingMethod !== "subsoil-dutch-initial-qualification" &&
                props.auctionsMy.sellingMethod !== "subsoil-dutch-initial-auction-manual" &&
                props.auctionsMy.sellingMethod !== "subsoil-english-fast-auction-manual-qualification"
            ){
                tmpBody.bankAccountIds = createArrBanks(props, 1, props.auctionsMy.id)
            }
            tmpBody.sellingMethod = props.auctionsMy.sellingMethod
            tmpBody.tenderAttempts = props.auctionsMy.tenderAttempts
            if(props.auctionsMy.accessDetails !== null && props.auctionsMy.accessDetails !== ''){
                tmpBody.accessDetails = props.auctionsMy.accessDetails
            }
            if(
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-english" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-fast" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-fast-manual" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-fast-auction-manual-qualification" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-auction" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-auction-manual" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-qualification" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-qualification-prod" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-fast" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-fast-manual" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-fast-auction-manual-qualification" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-initial-auction" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-initial-auction-manual" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-initial-qualification-prod" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-manual" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-auction-manual-qualification" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction-manual" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification-prod"
            ){
                tmpBody.martialLawDisclaimer = props.auctionsMy.martialLawDisclaimer
                tmpBody.lotIdentifier = props.auctionsMy.lotIdentifier
                //tmpBody.xDocumentRequirements = props.auctionsMy.xDocumentRequirements
            }else{
                tmpBody.lotIdentifier = props.auctionsMy.lotIdentifier
                if(props.auctionsMy.xDocumentRequirements !== null && props.auctionsMy.xDocumentRequirements !== ''){
                    tmpBody.xDocumentRequirements = props.auctionsMy.xDocumentRequirements
                }
            }
            if(props.auctionsMy.xAdditionalInformation !== null && props.auctionsMy.xAdditionalInformation !== ''){
                tmpBody.xAdditionalInformation = props.auctionsMy.xAdditionalInformation
            }
            tmpBody.value = {
                currency: 'UAH',//props.auctionsMy.value.currency,
                amount: Number(props.auctionsMy.value.amount),
                valueAddedTaxIncluded: tmpValueAddedTaxIncluded1,
            }
            tmpBody.guarantee = {
                currency: 'UAH',//props.auctionsMy.guarantee.currency,
                amount: Number(props.auctionsMy.guarantee.amount),
            }
            tmpBody.minNumberOfQualifiedBids = minNumberOfQualifiedBids
            tmpBody.minimalStep = {
                currency: 'UAH',//props.auctionsMy.minimalStep.currency,
                amount: Number(props.auctionsMy.minimalStep.amount),
            }
            let tmpOldAuctionStartDate = props.auctionsMy.startDate
            if(
                tmpOldAuctionStartDate !== null &&
                tmpOldAuctionStartDate !== ''
            ){
                tmpOldAuctionStartDate = new Date(tmpOldAuctionStartDate)
                if(Date.now() < tmpOldAuctionStartDate.getTime()){
                    tmpBody.startDate = props.auctionsMy.startDate
                }
            }
            tmpBody.documents = props.auctionsMy.documents
        }else{
            tmpBody = {}
            if(aucId === null){
                if(props.auctionsMy.documentationValue !== null && props.auctionsMy.documentationValue !== ''){
                    tmpBody.documentationValue = props.auctionsMy.documentationValue
                }
                if(props.auctionsMy.geologicDataValue !== null & props.auctionsMy.geologicDataValue !== ''){
                    tmpBody.geologicDataValue = props.auctionsMy.geologicDataValue
                }
            }
            if(props.auctionsMy.leaseDuration !== null && props.auctionsMy.leaseDuration !== ''){
                tmpBody.leaseDuration = props.auctionsMy.leaseDuration
            }
            if(tmpCarryType !== null && tmpCarryType !== ''){
                //tmpBody.carryTypeId = tmpCarryType
                let texttmpCarryType = typeof tmpCarryType
                if(texttmpCarryType === 'string'){
                    for(let tmI = 0; tmI < props.carryType.length; tmI++){
                        if(tmpCarryType === props.carryType[tmI].name){
                            tmpBody.carryType = tmpCarryType
                        }
                    }
                }else if(texttmpCarryType === 'object'){
                    tmpBody.carryType = tmpCarryType.name
                }
            }
            tmpBody.previousAuctionId = null
            tmpBody.title = props.auctionsMy.title
            if(props.auctionsMy.description !== null && props.auctionsMy.description !== ''){
                tmpBody.description = props.auctionsMy.description
            }
            if(
                props.auctionsMy.sellingMethod !== "subsoil-english" &&
                props.auctionsMy.sellingMethod !== "subsoil-english-fast" &&
                props.auctionsMy.sellingMethod !== "subsoil-english-fast-manual" &&
                props.auctionsMy.sellingMethod !== "subsoil-english-initial-auction" &&
                props.auctionsMy.sellingMethod !== "subsoil-english-initial-qualification" &&
                props.auctionsMy.sellingMethod !== "subsoil-dutch" &&
                props.auctionsMy.sellingMethod !== "subsoil-dutch-fast" &&
                props.auctionsMy.sellingMethod !== "subsoil-dutch-fast-manual" &&
                props.auctionsMy.sellingMethod !== "subsoil-dutch-initial-auction" &&
                props.auctionsMy.sellingMethod !== "subsoil-dutch-initial-qualification" &&
                props.auctionsMy.sellingMethod !== "subsoil-dutch-initial-auction-manual" &&
                props.auctionsMy.sellingMethod !== "subsoil-english-fast-auction-manual-qualification"
            ){
                tmpBody.bankAccountIds = createArrBanks(props, 1, props.auctionsMy.id)
            }
            tmpBody.sellingMethod = props.auctionsMy.sellingMethod
            tmpBody.tenderAttempts = props.auctionsMy.tenderAttempts
            if(props.auctionsMy.accessDetails !== null && props.auctionsMy.accessDetails !== ''){
                tmpBody.accessDetails = props.auctionsMy.accessDetails
            }
            if(
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-english" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-fast" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-fast-manual" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-fast-auction-manual-qualification" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-auction" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-auction-manual" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-qualification" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-qualification-prod" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-fast" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-fast-manual" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-fast-auction-manual-qualification" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-initial-auction" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-initial-auction-manual" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-initial-qualification-prod" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-manual" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-auction-manual-qualification" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction-manual" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification-prod"
            ){
                tmpBody.martialLawDisclaimer = props.auctionsMy.martialLawDisclaimer
                tmpBody.lotIdentifier = props.auctionsMy.lotIdentifier
                //tmpBody.xDocumentRequirements = props.auctionsMy.xDocumentRequirements
            }else{
                tmpBody.lotIdentifier = props.auctionsMy.lotIdentifier
                if(props.auctionsMy.xDocumentRequirements !== null && props.auctionsMy.xDocumentRequirements !== ''){
                    tmpBody.xDocumentRequirements = props.auctionsMy.xDocumentRequirements
                }
            }
            if(props.auctionsMy.xAdditionalInformation !== null && props.auctionsMy.xAdditionalInformation !== ''){
                tmpBody.xAdditionalInformation = props.auctionsMy.xAdditionalInformation
            }
            if(props.auctionsMy.value !== null){
                tmpBody.value = {
                    currency: 'UAH',//props.auctionsMy.value.currency,
                    amount: Number(props.auctionsMy.value.amount),
                    valueAddedTaxIncluded: tmpValueAddedTaxIncluded1,
                }
            }
            if(props.auctionsMy.guarantee !== null){
                tmpBody.guarantee = {
                    currency: 'UAH',//props.auctionsMy.guarantee.currency,
                    amount: Number(props.auctionsMy.guarantee.amount),
                }
            }
            tmpBody.minNumberOfQualifiedBids = minNumberOfQualifiedBids
            if(props.auctionsMy.minimalStep !== null){
                tmpBody.minimalStep = {
                    currency: 'UAH',//props.auctionsMy.minimalStep.currency,
                    amount: Number(props.auctionsMy.minimalStep.amount),
                }
            }
            let tmpOldAuctionStartDate = props.auctionsMy.startDate
            if(
                tmpOldAuctionStartDate !== null &&
                tmpOldAuctionStartDate !== ''
            ){
                tmpOldAuctionStartDate = new Date(tmpOldAuctionStartDate)
                if(Date.now() < tmpOldAuctionStartDate.getTime()){
                    tmpBody.startDate = props.auctionsMy.startDate
                }
            }
            tmpBody.documents = props.auctionsMy.documents
        }
    }
    /////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////
    if(aucId === null){
        if(Number(props.auctionsMy.tenderAttempts) === 1){
            if(props.auctionsMy.discount !== null){
                await deleteDiscount(props, props.auctionsMy.id)
            }
        }
    }

    switch(true){
        case props.auctionsMy.sellingMethod.includes('railwayCargo-english'):
        case props.auctionsMy.sellingMethod.includes('railwayCargo-dutch'):
            if (props.auctionsMy.registrationFee !== null) {
                switch (true) {
                    case props.auctionsMy.registrationFee.valueAddedTaxIncluded === true:
                    case props.auctionsMy.registrationFee.valueAddedTaxIncluded === 'true':

                        tmpBody.registrationFee = {
                            currency: 'UAH',
                            amount: Number(props.auctionsMy.registrationFee.amount),
                            valueAddedTaxIncluded: true
                        }
                        break;
                    case props.auctionsMy.registrationFee.valueAddedTaxIncluded === false:
                    case props.auctionsMy.registrationFee.valueAddedTaxIncluded === 'false':
                        tmpBody.registrationFee = {
                            currency: 'UAH',
                            amount: Number(props.auctionsMy.registrationFee.amount),
                            valueAddedTaxIncluded: false
                        }
                        break;
                    default:
                        tmpBody.registrationFee = {
                            currency: 'UAH',
                            amount: Number(props.auctionsMy.registrationFee.amount),
                        }
                }
            }
            break;
        case props.auctionsMy.sellingMethod.includes('dgf-english'):
        case props.auctionsMy.sellingMethod.includes('dgf-dutch'):
            tmpBody.minNumberOfQualifiedBids = Number(props.auctionsMy.minNumberOfQualifiedBids)
            tmpBody.virtualDataRoom = props.auctionsMy.virtualDataRoom
            tmpBody.eligibilityCriteria = props.auctionsMy.eligibilityCriteria
            tmpBody.decision = {
                decisionId: props.auctionsMy.decision.decisionId,
                decisionDate: props.auctionsMy.decision.decisionDate
            }
            tmpBody.x_dgfPlatformLegalDetails = props.auctionsMy.x_dgfPlatformLegalDetails
            break;
        case props.auctionsMy.sellingMethod.includes('legitimatePropertyLease-english'):
        case props.auctionsMy.sellingMethod.includes('legitimatePropertyLease-dutch'):
        case props.auctionsMy.sellingMethod.includes('legitimatePropertyLease-priorityEnglish'):
            if(Number(props.auctionsMy.minNumberOfQualifiedBids) === 1 || Number(props.auctionsMy.minNumberOfQualifiedBids) === 2){
                tmpBody.minNumberOfQualifiedBids = Number(props.auctionsMy.minNumberOfQualifiedBids)
            }else{
                tmpBody.minNumberOfQualifiedBids = 1
            }
            tmpBody.calcOnBookValue = props.auctionsMy.calcOnBookValue
            tmpBody.valueAddedTaxCharged = props.auctionsMy.valueAddedTaxCharged
            tmpBody.registryId = props.auctionsMy.registryId
            if(props.auctionsMy.registrationFee !== null){
                if(props.auctionsMy.registrationFee.valueAddedTaxIncluded === true || props.auctionsMy.registrationFee.valueAddedTaxIncluded === 'true'){
                    tmpBody.registrationFee = {
                        currency: 'UAH',
                        amount: Number(props.auctionsMy.registrationFee.amount),
                        valueAddedTaxIncluded: true
                    }
                }else if(props.auctionsMy.registrationFee.valueAddedTaxIncluded === false || props.auctionsMy.registrationFee.valueAddedTaxIncluded === 'false'){
                    tmpBody.registrationFee = {
                        currency: 'UAH',
                        amount: Number(props.auctionsMy.registrationFee.amount),
                        valueAddedTaxIncluded: false
                    }
                }else{
                    tmpBody.registrationFee = {
                        currency: 'UAH',
                        amount: Number(props.auctionsMy.registrationFee.amount),
                    }
                }
            }
            tmpBody.valuePeriod = props.auctionsMy.valuePeriod
            break;
        case props.auctionsMy.sellingMethod.includes('landRental-english'):
        case props.auctionsMy.sellingMethod.includes('landSell-english'):
        case props.auctionsMy.sellingMethod.includes('landSell-priorityEnglish'):
            tmpBody.martialLawDisclaimer = props.auctionsMy.martialLawDisclaimer
            if(props.auctionsMy.xDgfPlatformLegalDetails !== null && props.auctionsMy.xDgfPlatformLegalDetails !== '') tmpBody.xDgfPlatformLegalDetails = props.auctionsMy.xDgfPlatformLegalDetails
            if(props.auctionsMy.registrationFee !== null){
                if(props.auctionsMy.registrationFee.valueAddedTaxIncluded === true || props.auctionsMy.registrationFee.valueAddedTaxIncluded === 'true'){
                    if(props.auctionsMy.registrationFee.amount !== null && props.auctionsMy.registrationFee.amount !== ''){
                        tmpBody.registrationFee = {
                            currency: 'UAH',
                            amount: Number(props.auctionsMy.registrationFee.amount),
                            valueAddedTaxIncluded: true
                        }
                    }
                }else if(props.auctionsMy.registrationFee.valueAddedTaxIncluded === false || props.auctionsMy.registrationFee.valueAddedTaxIncluded === 'false'){
                    if(props.auctionsMy.registrationFee.amount !== null && props.auctionsMy.registrationFee.amount !== ''){
                        tmpBody.registrationFee = {
                            currency: 'UAH',
                            amount: Number(props.auctionsMy.registrationFee.amount),
                            valueAddedTaxIncluded: false
                        }
                    }
                }else{
                    if(props.auctionsMy.registrationFee.amount !== null && props.auctionsMy.registrationFee.amount !== ''){
                        tmpBody.registrationFee = {
                            currency: 'UAH',
                            amount: Number(props.auctionsMy.registrationFee.amount),
                        }
                    }
                }
            }
            if(props.auctionsMy.leaseType !== 'null' && props.auctionsMy.leaseType !== null){
                if(props.auctionsMy.leaseType === 'saleOrLeaseType'){
                    tmpBody.leaseType = 'landRental'
                }else{
                    if (props.auctionsMy.sellingMethod.includes('landRental-english')) {
                        tmpBody.leaseType = 'landRental'
                    }
                }
            }
            if(props.auctionsMy.saleType !== 'null' && props.auctionsMy.saleType !== null){
                tmpBody.saleType = props.auctionsMy.saleType
            }

            if(props.auctionsMy.hasOwnProperty('normativeMonetaryValuation') === true){
                if(props.auctionsMy.normativeMonetaryValuation !== null){
                    if(props.auctionsMy.normativeMonetaryValuation.amount !== null && props.auctionsMy.normativeMonetaryValuation.amount !== ''){
                        tmpBody.normativeMonetaryValuation = {
                            current: 'UAH',
                            amount: Number(props.auctionsMy.normativeMonetaryValuation.amount)
                        }
                    }
                }
            }
            if(tmpBody.hasOwnProperty('normativeMonetaryValuation') !== true) tmpBody.normativeMonetaryValuation = null
            if(props.auctionsMy.hasOwnProperty('expertMonetaryValuation') === true){
                if(props.auctionsMy.expertMonetaryValuation !== null){
                    if(props.auctionsMy.expertMonetaryValuation.amount !== null && props.auctionsMy.expertMonetaryValuation.amount !== ''){
                        tmpBody.expertMonetaryValuation = {
                            current: 'UAH',
                            amount: Number(props.auctionsMy.expertMonetaryValuation.amount)
                        }
                    }
                }
            }
            if(tmpBody.hasOwnProperty('expertMonetaryValuation') !== true) tmpBody.expertMonetaryValuation = null
            if(props.auctionsMy.hasOwnProperty('budgetSpent') === true){
                if(props.auctionsMy.budgetSpent !== null){
                    if(props.auctionsMy.budgetSpent.amount !== null && props.auctionsMy.budgetSpent.amount !== ''){
                        tmpBody.budgetSpent = {
                            current: 'UAH',
                            amount: Number(props.auctionsMy.budgetSpent.amount)
                        }
                    }
                }
            }
            if(tmpBody.hasOwnProperty('budgetSpent') !== true) tmpBody.budgetSpent = null
            if(props.auctionsMy.conditions !== 'null' && props.auctionsMy.conditions !== null && props.auctionsMy.conditions !== ''){
                tmpBody.conditions = props.auctionsMy.conditions
            }
            if(props.auctionsMy.restrictions !== 'null' && props.auctionsMy.restrictions !== null && props.auctionsMy.restrictions !== ''){
                tmpBody.restrictions = props.auctionsMy.restrictions
            }
            break;
        default:
            break;
    }

    if (props.auctionsMy.sellingMethod.includes('legitimatePropertyLease-priorityEnglish')) {
        if(props.auctionsMy.currentTenantGuaranteeFee !== null){
            if(props.auctionsMy.currentTenantGuaranteeFee.amount !== '' && props.auctionsMy.currentTenantGuaranteeFee.amount !== null){
                tmpBody.currentTenantGuaranteeFee = {
                    currency: 'UAH',
                    amount: Number(props.auctionsMy.currentTenantGuaranteeFee.amount)
                }
            }
        }
    }

    if (props.auctionsMy.sellingMethod.includes('landSell-priorityEnglish')){
        if(props.auctionsMy.numberOfCurrentTenants !== 'null' && props.auctionsMy.numberOfCurrentTenants !== null){
            tmpBody.numberOfCurrentTenants = props.auctionsMy.numberOfCurrentTenants
            tmpBody.currentTenants = props.auctionsMy.currentTenant
        }else{
            tmpBody.numberOfCurrentTenants = 1
            tmpBody.currentTenants = props.auctionsMy.currentTenant
        }
    }

    //TODO: Подключаю новую сборку боди
    let tmpBody2 = {}
    if (
        props.auctionsMy.sellingMethod.includes('landSell-priorityEnglish') ||
        props.auctionsMy.sellingMethod.includes('timber-english') ||
        props.auctionsMy.sellingMethod.includes('subsoil-english') ||
        props.auctionsMy.sellingMethod.includes('subsoil-dutch') ||
        props.auctionsMy.sellingMethod.includes('railwayCargo-english') ||
        props.auctionsMy.sellingMethod.includes('railwayCargo-dutch') ||
        props.auctionsMy.sellingMethod.includes('landRental-english') ||
        props.auctionsMy.sellingMethod.includes('landSell-english') ||
        props.auctionsMy.sellingMethod.includes('landSell-priorityEnglish') ||

        isBSE(props.auctionsMy.sellingMethod) || isBSD(props.auctionsMy.sellingMethod) ||
        isALE(props.auctionsMy.sellingMethod) ||
        isCSE(props.auctionsMy.sellingMethod) || isCSD(props.auctionsMy.sellingMethod) ||
        isBRE(props.auctionsMy.sellingMethod) || isBRD(props.auctionsMy.sellingMethod) ||
        isNLE(props.auctionsMy.sellingMethod) || isNLD(props.auctionsMy.sellingMethod)
    ){
        let bodyObj = {
            base: props.auctionsMy,
            bank: props.bankAccountWork,
            bankList: props.bankAccount,
            discount: props.discount,
        }
        if(props.auctionsMy.sellingMethod.includes('landSell-priorityEnglish')){
            if(props.auctionsMy.numberOfCurrentTenants !== 'null' && props.auctionsMy.numberOfCurrentTenants !== null){
                bodyObj.base.numberOfCurrentTenants = props.auctionsMy.numberOfCurrentTenants
                bodyObj.base.currentTenants = props.auctionsMy.currentTenants
            }else{
                bodyObj.base.numberOfCurrentTenants = 1
                bodyObj.base.currentTenants = props.auctionsMy.currentTenants
            }
        }
        tmpBody2 = createBodyOld(bodyObj)
        if (
            props.auctionsMy.sellingMethod.includes('railwayCargo-english') ||
            props.auctionsMy.sellingMethod.includes('railwayCargo-dutch')
        ) {
            tmpBody2.registrationFee = tmpBody.registrationFee;
        }
    }else{
        if(tmpBody.martialLawDisclaimer !== false) tmpBody.martialLawDisclaimer = true
        tmpBody2 = tmpBody
    }
    const response = await fetch(endPoint, {
        method: "PUT",
        body: JSON.stringify(tmpBody2),
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    })
    .catch(console.error)



    if(response === undefined || response === 'undefined'){
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){
            let tmp = null
            if(json.message.hasOwnProperty('documents') === true){
                if(json.message.documents === "Procedure cannot be updated without clarifications document. To update procedure data upload clarifications document first"){
                    tmp = JSON.stringify({title: 'Помилка при редагуванні аукціону', message: 'Перед тим, як внести зміни в аукціон, завантажте "Погодження змін до опису лота. Опис причин редагування" в документи до аукціону.'})
                }
            }
            if(tmp === null){
                tmp = JSON.stringify({title: 'Помилка при редагуванні аукціону', message: JSON.stringify(json)})
            }
            props.setUniversalError(tmp)
        }else{
            if(
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-english" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-fast" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-fast-manual" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-fast-auction-manual-qualification" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-auction" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-auction-manual" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-qualification" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-qualification-prod" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-fast" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-fast-manual" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-fast-auction-manual-qualification" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-initial-auction" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-initial-auction-manual" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-initial-qualification-prod" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-manual" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-auction-manual-qualification" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction-manual" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification" ||
                props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification-prod" ||
                props.auctionsMy.sellingMethod === 'landRental-english' ||
                props.auctionsMy.sellingMethod === 'landRental-english-fast' ||
                props.auctionsMy.sellingMethod === 'landRental-english-fast-manual' ||
                props.auctionsMy.sellingMethod === 'landRental-english-fast-auction-manual-qualification' ||
                props.auctionsMy.sellingMethod === 'landRental-english-initial-auction' ||
                props.auctionsMy.sellingMethod === 'landRental-english-initial-auction-manual' ||
                props.auctionsMy.sellingMethod === 'landRental-english-initial-qualification' ||
                props.auctionsMy.sellingMethod === 'landRental-english-initial-qualification-prod' ||
                props.auctionsMy.sellingMethod === 'landSell-english' ||
                props.auctionsMy.sellingMethod === 'landSell-english-fast' ||
                props.auctionsMy.sellingMethod === 'landSell-english-fast-manual' ||
                props.auctionsMy.sellingMethod === 'landSell-english-fast-auction-manual-qualification' ||
                props.auctionsMy.sellingMethod === 'landSell-english-initial-auction' ||
                props.auctionsMy.sellingMethod === 'landSell-english-initial-auction-manual' ||
                props.auctionsMy.sellingMethod === 'landSell-english-initial-qualification' ||
                props.auctionsMy.sellingMethod === 'landSell-english-initial-qualification-prod' ||
                props.auctionsMy.sellingMethod === 'landSell-priorityEnglish' ||
                props.auctionsMy.sellingMethod === 'landSell-priorityEnglish-fast' ||
                props.auctionsMy.sellingMethod === 'landSell-priorityEnglish-fast-manual' ||
                props.auctionsMy.sellingMethod === 'landSell-priorityEnglish-fast-auction-manual-qualification' ||
                props.auctionsMy.sellingMethod === 'landSell-priorityEnglish-initial-auction' ||
                props.auctionsMy.sellingMethod === 'landSell-priorityEnglish-initial-auction-manual' ||
                props.auctionsMy.sellingMethod === 'landSell-priorityEnglish-initial-qualification' ||
                props.auctionsMy.sellingMethod === 'landSell-priorityEnglish-initial-qualification-prod' ||

                isBSE(props.auctionsMy.sellingMethod) || isBSD(props.auctionsMy.sellingMethod) ||
                isALE(props.auctionsMy.sellingMethod) ||
                isCSE(props.auctionsMy.sellingMethod) || isCSD(props.auctionsMy.sellingMethod) ||
                isBRE(props.auctionsMy.sellingMethod) || isBRD(props.auctionsMy.sellingMethod) ||
                isNLE(props.auctionsMy.sellingMethod) || isNLD(props.auctionsMy.sellingMethod)
            ){
                if(aucId === null){
                    createArrBanks(props, 2, props.auctionsMy.id)
                    //createAuctionLeaseRules(props, json.id)
                }else{
                    createArrBanks(props, 2, aucId)
                    //createAuctionLeaseRules(props, json.id)
                }
                if(
                    props.auctionsMy.sellingMethod === 'landSell-priorityEnglish' ||
                    props.auctionsMy.sellingMethod === 'landSell-priorityEnglish-fast' ||
                    props.auctionsMy.sellingMethod === 'landSell-priorityEnglish-fast-manual' ||
                    props.auctionsMy.sellingMethod === 'landSell-priorityEnglish-fast-auction-manual-qualification' ||
                    props.auctionsMy.sellingMethod === 'landSell-priorityEnglish-initial-auction' ||
                    props.auctionsMy.sellingMethod === 'landSell-priorityEnglish-initial-auction-manual' ||
                    props.auctionsMy.sellingMethod === 'landSell-priorityEnglish-initial-qualification' ||
                    props.auctionsMy.sellingMethod === 'landSell-priorityEnglish-initial-qualification-prod'
                ){
                    //if(blokCurrentTenant(props))
                    //createCurrentTenant(props)
                }
            }
            if(aucId === null){
                //if(props.discount.discountOnOff === true){
                    if(props.discount.id === null){
                        createDiscountForAuction(props, props.auctionsMy.id)
                    }else{
                        editDiscountForAuction(props, props.auctionsMy.id)
                    }
            }else{
                if(props.discount.discountOnOff === true){
                    if(props.discount.id === null){
                        createDiscountForAuction(props, aucId)
                    }else{
                        editDiscountForAuction(props, aucId)
                    }
                }else{
                    if(props.discount.id === null){
                        createDiscountForAuction(props, aucId)
                    }else{
                        editDiscountForAuction(props, aucId)
                    }
                }
            }
            if(
                dutchData(props) !== false &&
                props.auctionsMy.sellingMethod !== "legitimatePropertyLease-english" &&
                props.auctionsMy.sellingMethod !== "legitimatePropertyLease-english-fast" &&
                props.auctionsMy.sellingMethod !== "legitimatePropertyLease-english-fast-manual" &&
                props.auctionsMy.sellingMethod !== "legitimatePropertyLease-english-fast-auction-manual-qualification" &&
                props.auctionsMy.sellingMethod !== "legitimatePropertyLease-english-initial-auction" &&
                props.auctionsMy.sellingMethod !== "legitimatePropertyLease-english-initial-auction-manual" &&
                props.auctionsMy.sellingMethod !== "legitimatePropertyLease-english-initial-qualification" &&
                props.auctionsMy.sellingMethod !== "legitimatePropertyLease-english-initial-qualification-prod" &&
                /*props.auctionsMy.sellingMethod !== "legitimatePropertyLease-dutch" &&
                props.auctionsMy.sellingMethod !== "legitimatePropertyLease-dutch-fast" &&
                props.auctionsMy.sellingMethod !== "legitimatePropertyLease-dutch-fast-manual" &&
                props.auctionsMy.sellingMethod !== "legitimatePropertyLease-dutch-fast-auction-manual-qualification" &&
                props.auctionsMy.sellingMethod !== "legitimatePropertyLease-dutch-initial-auction" &&
                props.auctionsMy.sellingMethod !== "legitimatePropertyLease-dutch-initial-auction-manual" &&
                props.auctionsMy.sellingMethod !== "legitimatePropertyLease-dutch-initial-qualification-prod" &&*/
                props.auctionsMy.sellingMethod !== "legitimatePropertyLease-priorityEnglish" &&
                props.auctionsMy.sellingMethod !== "legitimatePropertyLease-priorityEnglish-fast" &&
                props.auctionsMy.sellingMethod !== "legitimatePropertyLease-priorityEnglish-fast-manual" &&
                props.auctionsMy.sellingMethod !== "legitimatePropertyLease-priorityEnglish-fast-auction-manual-qualification" &&
                props.auctionsMy.sellingMethod !== "legitimatePropertyLease-priorityEnglish-initial-auction" &&
                props.auctionsMy.sellingMethod !== "legitimatePropertyLease-priorityEnglish-initial-auction-manual" &&
                props.auctionsMy.sellingMethod !== "legitimatePropertyLease-priorityEnglish-initial-qualification" &&
                props.auctionsMy.sellingMethod !== "legitimatePropertyLease-priorityEnglish-initial-qualification-prod"
            ){
                let dutchStep = {}
                dutchStep.dutchStepValue = {}
                if(props.auctionsMy.dutchStep !== null){
                    if(props.auctionsMy.dutchStep.dutchStepQuantity === null || props.auctionsMy.dutchStep.dutchStepQuantity === 'null'){
                        dutchStep.dutchStepQuantity = 1
                    }else{
                        dutchStep.dutchStepQuantity = props.auctionsMy.dutchStep.dutchStepQuantity
                    }
                }
                dutchStep.dutchStepValue.currency = 'UAH'
                if(
                    props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch" ||
                    props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-fast" ||
                    props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-fast-manual" ||
                    props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-fast-auction-manual-qualification" ||
                    props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-initial-auction" ||
                    props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-initial-auction-manual" ||
                    props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-initial-qualification-prod"
                ){
                    dutchStep.dutchStepPercent = 1
                    if(props.auctionsMy.dutchStep !== null && props.auctionsMy.dutchStep !== ''){
                        if(props.auctionsMy.dutchStep.dutchStepValue !== null){
                            if(props.auctionsMy.value.amount !== null && props.auctionsMy.value.amount !== ''){
                                dutchStep.dutchStepValue.amount = props.auctionsMy.value.amount/100
                            }
                        }
                    }
                }else{
                    if(props.auctionsMy.dutchStep !== null && props.auctionsMy.dutchStep !== ''){
                        dutchStep.dutchStepPercent = props.auctionsMy.dutchStep.dutchStepPercent
                        if(props.auctionsMy.dutchStep.dutchStepValue !== null){
                            if(props.auctionsMy.value.amount !== null && props.auctionsMy.value.amount !== ''){
                                dutchStep.dutchStepValue.amount = props.auctionsMy.dutchStep.dutchStepValue.amount
                            }
                        }
                    }
                }
                const endPoint3 = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${json.id}/dutch_step`
                const response3 = await fetch(endPoint3, {
                    method: "PUT",
                    body: JSON.stringify(dutchStep),
                    headers: {
                        'Authorization': props.token.access_token,
                        'Content-Type': 'application/json'
                    }
                }).catch(console.error)
                if(response3 === undefined || response3 === 'undefined'){
                }else{
                    const json3 = await response3.json()
                    if(json3.hasOwnProperty('message') === true){
                        let tmp = JSON.stringify({title: 'Помилка при редагуванні аукціону', message: JSON.stringify(json3)})
                        props.setUniversalError(tmp)
                        props.setPopUpAuctionCreate(1)
                    }else{

                        let tmpAucData = json
                        tmpAucData.dutchStep = json3
                        //props.setPopUpAuctionCreate(tmpAucData)
                    }
                }
            }else{
                //props.setPopUpAuctionCreate(json)
            }
        }
    }
    let newData = null
    if(aucId === null){
        newData = await getAucionsNewData(props, props.auctionsMy.id)
    }else{
        newData = await getAucionsNewData(props, aucId)
    }
    props.setPopUpAuctionCreate(newData)
    props.setBankAccountAdd([])
    props.setBankAccountDelete([])
    props.changeLoader(false)
}

async function deleteDiscount(props, auctionId){
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${auctionId}/discount`
    const response = await fetch(endPoint, {
        method: "DELETE",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){
            let tmp = JSON.stringify({title: 'Помилка при видаленні знижки', message: JSON.stringify(json)})
            props.setUniversalError(tmp)
        }else{
        }
    }
}

function createArrBanks(props, type, aucId){
    if(
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-fast" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-fast-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-fast-auction-manual-qualification" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-auction" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-auction-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-qualification" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-qualification-prod" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-fast" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-fast-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-fast-auction-manual-qualification" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-initial-auction" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-initial-auction-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-initial-qualification-prod" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-auction-manual-qualification" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction-manual" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification" ||
        props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification-prod" ||
        props.auctionsMy.sellingMethod === 'landRental-english' ||
        props.auctionsMy.sellingMethod === 'landRental-english-fast' ||
        props.auctionsMy.sellingMethod === 'landRental-english-fast-manual' ||
        props.auctionsMy.sellingMethod === 'landRental-english-fast-auction-manual-qualification' ||
        props.auctionsMy.sellingMethod === 'landRental-english-initial-auction' ||
        props.auctionsMy.sellingMethod === 'landRental-english-initial-auction-manual' ||
        props.auctionsMy.sellingMethod === 'landRental-english-initial-qualification' ||
        props.auctionsMy.sellingMethod === 'landRental-english-initial-qualification-prod' ||
        props.auctionsMy.sellingMethod === 'landSell-english' ||
        props.auctionsMy.sellingMethod === 'landSell-english-fast' ||
        props.auctionsMy.sellingMethod === 'landSell-english-fast-manual' ||
        props.auctionsMy.sellingMethod === 'landSell-english-fast-auction-manual-qualification' ||
        props.auctionsMy.sellingMethod === 'landSell-english-initial-auction' ||
        props.auctionsMy.sellingMethod === 'landSell-english-initial-auction-manual' ||
        props.auctionsMy.sellingMethod === 'landSell-english-initial-qualification' ||
        props.auctionsMy.sellingMethod === 'landSell-english-initial-qualification-prod' ||
        props.auctionsMy.sellingMethod === 'landSell-priorityEnglish' ||
        props.auctionsMy.sellingMethod === 'landSell-priorityEnglish-fast' ||
        props.auctionsMy.sellingMethod === 'landSell-priorityEnglish-fast-manual' ||
        props.auctionsMy.sellingMethod === 'landSell-priorityEnglish-fast-auction-manual-qualification' ||
        props.auctionsMy.sellingMethod === 'landSell-priorityEnglish-initial-auction' ||
        props.auctionsMy.sellingMethod === 'landSell-priorityEnglish-initial-auction-manual' ||
        props.auctionsMy.sellingMethod === 'landSell-priorityEnglish-initial-qualification' ||
        props.auctionsMy.sellingMethod === 'landSell-priorityEnglish-initial-qualification-prod' ||
        isBSE(props.auctionsMy.sellingMethod) || isBSD(props.auctionsMy.sellingMethod) ||
        isALE(props.auctionsMy.sellingMethod) ||
        isCSE(props.auctionsMy.sellingMethod) || isCSD(props.auctionsMy.sellingMethod) ||
        isBRE(props.auctionsMy.sellingMethod) || isBRD(props.auctionsMy.sellingMethod) ||
        isNLE(props.auctionsMy.sellingMethod) || isNLD(props.auctionsMy.sellingMethod)
    ){
        if(type === 2){
            createZUBanks(props, aucId)
        }
    }else{
        if(
            props.auctionsMy.sellingMethod !== "subsoil-english" &&
            props.auctionsMy.sellingMethod !== "subsoil-english-fast" &&
            props.auctionsMy.sellingMethod !== "subsoil-english-fast-manual" &&
            props.auctionsMy.sellingMethod !== "subsoil-english-initial-auction" &&
            props.auctionsMy.sellingMethod !== "subsoil-english-initial-qualification" &&
            props.auctionsMy.sellingMethod !== "subsoil-dutch" &&
            props.auctionsMy.sellingMethod !== "subsoil-dutch-fast" &&
            props.auctionsMy.sellingMethod !== "subsoil-dutch-fast-manual" &&
            props.auctionsMy.sellingMethod !== "subsoil-dutch-initial-auction" &&
            props.auctionsMy.sellingMethod !== "subsoil-dutch-initial-qualification" &&
            props.auctionsMy.sellingMethod !== "subsoil-dutch-initial-auction-manual" &&
            props.auctionsMy.sellingMethod !== "subsoil-english-fast-auction-manual-qualification"
        ){
            let tmp = []
            for(let i = 0; i < props.auctionsMy.bankAccounts.length; i++){
                if(props.bankAccountWork.deleteBank.includes(String(props.auctionsMy.bankAccounts[i].id)) === false) tmp.push(props.auctionsMy.bankAccounts[i].id)
            }
            for(let i = 0; i < props.bankAccountWork.addNewBank.length; i++){
                tmp.push(Number(props.bankAccountWork.addNewBank[i]))
            }
            return tmp
        }
    }
}

function tryData(value, ref ){
    if (value === null || value === 'null' || value === ref) return null
    return value
}
//
async function createAuctionLeaseRules(props, aucId){



    let body = {}
    body.intendedUseRestrictionMethod = tryData(props.auctionsMy.leaseRules.intendedUseRestrictionMethod, [])
    body.intendedUseRestrictionDescription = tryData(props.auctionsMy.leaseRules.intendedUseRestrictionDescription, '')
    body.additionalLeaseConditions = tryData(props.auctionsMy.leaseRules.additionalLeaseConditions, '')
    body.subleaseAllowed = props.auctionsMy.leaseRules.subleaseAllowed
    if(props.auctionsMy.leaseRules !== null){
        if(props.auctionsMy.leaseRules.hasOwnProperty('intendedUseRestrictionMethod') === true){
            if(props.auctionsMy.leaseRules.intendedUseRestrictionMethod === 'exceptDescribed'){
                body.intendedUse = props.auctionsMy.leaseRules.intendedUse
            }
        }
    }
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${aucId}/lease_rules`
    const response = await fetch(endPoint, {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){
            let tmp = JSON.stringify({title: "Помилка при створенні Тип переліку, до якого віднесено об'єкт", message: JSON.stringify(json)})
            props.setUniversalError(tmp)
        }else{
            if(props.auctionsMy.leaseRules.schedule !== null){
                await createAuctionLeaseRulesShedule(props, aucId)
            }
            //if(props.schedule.daysBy.value === '' || props.schedule.daysBy.value === null){
                //if(props.schedule.hoursBy.value === '' || props.schedule.hoursBy.value === null){
                    if(props.shedullePeriod.newFullPeriod.length > 0){
                        for(let it=0; it < props.shedullePeriod.newFullPeriod.length; it++){
                            await createAuctionLeaseRulesShedulePeriod(props, aucId, props.shedullePeriod.newFullPeriod[it], false)
                        }
                    }
                    if(props.auctionsMy.leaseRules.schedule !== null){
                        if(props.auctionsMy.leaseRules.schedule.periods !== null){
                            if(props.auctionsMy.leaseRules.schedule.periods.length > 0){
                                for(let it=0; it < props.auctionsMy.leaseRules.schedule.periods.length; it++){
                                    await createAuctionLeaseRulesShedulePeriod(props, aucId, props.auctionsMy.leaseRules.schedule.periods[it], true)
                                }
                            }
                        }
                    }
                //}
            //}
            await createAuctionRelatedOrganizations(props, aucId)
        }
    }
}

async function createAuctionLeaseRulesShedule(props, aucId){
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${aucId}/lease_rules/schedule`
    let body = {}
    if(
        props.schedule.hoursBy.by !== 'null' &&
        props.schedule.hoursBy.by !== null &&
        props.schedule.hoursBy.value !== '' &&
        props.schedule.hoursBy.value !== null
    ){
        body.hoursBy = {
            value: props.schedule.hoursBy.value,
            by: props.schedule.hoursBy.by
        }
    }else{
        body.hoursBy = {}
    }
    if(
        props.schedule.daysBy.by !== 'null' &&
        props.schedule.daysBy.by !== null &&
        props.schedule.daysBy.value !== '' &&
        props.schedule.daysBy.value !== null
    ){
        body.daysBy = {
            value: props.schedule.daysBy.value,
            by: props.schedule.daysBy.by
        }
    }else{
        body.daysBy = {}
    }
    if(props.schedule.other !== '' || props.schedule.other !== null){
        body.other = props.schedule.other
    }
        /*}
    }else if(
        props.schedule.daysBy.length === 0 && props.schedule.hoursBy.length === 0
    ){
        body.other = props.schedule.other
    }*/
    const response = await fetch(endPoint, {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){
            let tmp = JSON.stringify({title: "Помилка при створенні розкладу", message: JSON.stringify(json)})
            props.setUniversalError(tmp)
        }
    }
}

//Периоды
async function createAuctionLeaseRulesShedulePeriod(props, aucId, data, type){
    let endPoint = null
    if(type === false){
        endPoint =`${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${aucId}/lease_rules/schedule/period`
    }else{
        endPoint =`${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${aucId}/lease_rules/schedule/period/${data.id}`
    }
    let body = {}
    if(type === true){
        if(data.startDate !== ''){
            body.startDate = data.startDate
        }
        if(data.endDate !== ''){
            body.endDate = data.endDate
        }
    }else{
        if(data.timeStart !== ''){
            let tmp = null
            tmp = data.timeStart.split('/');
            body.startDate = tmp[2] + '-' + tmp[1] + '-' + tmp[0] + 'T00:00:00+00:00'
        }
        if(data.timeEnd !== ''){
            let tmp = null
            tmp = data.timeEnd.split('/');
            body.endDate = tmp[2] + '-' + tmp[1] + '-' + tmp[0] + 'T00:00:00+00:00'
        }
    }

    if(data.weekdays.length > 0){
        body.weekdays = data.weekdays
    }
    let method = null
    if(type === true){
        method = 'PUT'
    }else{
        method = 'POST'
    }
    const response = await fetch(endPoint, {
        method: method,
        body: JSON.stringify(body),
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){
            let tmp = JSON.stringify({title: "Помилка при створенні періодов", message: JSON.stringify(json)})
            props.setUniversalError(tmp)
        }
    }
    await props.setDataAddress([], 'SET_NEW_FULL_PERIOD_ARR')
}

async function createAuctionRelatedOrganizations(props, aucId){
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/auction/${aucId}/related_organizations`
    let body = {}
    if(props.auctionsMy.relatedOrganizations.propertyOwner !== null){
        if(props.auctionsMy.relatedOrganizations.propertyOwner.id !== 'null') body.propertyOwnerId = Number(props.auctionsMy.relatedOrganizations.propertyOwner.id)
    }
    if(props.auctionsMy.relatedOrganizations.sellingEntity !== null){
        if(props.auctionsMy.relatedOrganizations.sellingEntity.id !== 'null') body.sellingEntityId = Number(props.auctionsMy.relatedOrganizations.sellingEntity.id)
    }
    if(props.auctionsMy.relatedOrganizations.currentTenant !== null){
        if(props.auctionsMy.relatedOrganizations.currentTenant.id !== 'null') body.currentTenantId = Number(props.auctionsMy.relatedOrganizations.currentTenant.id)
    }
    if(props.auctionsMy.relatedOrganizations.ownershipType !== '') body.ownershipType = props.auctionsMy.relatedOrganizations.ownershipType
    const response = await fetch(endPoint, {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){
            let tmp = JSON.stringify({title: "Помилка при створенні relatedOrganizations", message: JSON.stringify(json)})
            props.setUniversalError(tmp)
        }
    }
}

async function createZUBanks(props, aucId){
    let tmpRregistrationFee = [],
        tmpGuarantee = [],
        tmpSecurityDeposit = [],
        tmpImprovementCompensation = [],
        tmpOther = [],
        tmpAdvancePayment = [],
        tmpLease = [],
        tmpPayment = [],
        tmpPreparationPayment = []

    for(let i=0; i < props.bankAccountZU.length; i++){
        if(props.bankAccountZU[i].groupe === 'registrationFee'){
            tmpRregistrationFee.push(props.bankAccountZU[i])
        }else if(props.bankAccountZU[i].groupe === 'guarantee'){
            tmpGuarantee.push(props.bankAccountZU[i])
        }else if(props.bankAccountZU[i].groupe === 'securityDeposit'){
            tmpSecurityDeposit.push(props.bankAccountZU[i])
        }else if(props.bankAccountZU[i].groupe === 'improvementCompensation'){
            tmpImprovementCompensation.push(props.bankAccountZU[i])
        }else if(props.bankAccountZU[i].groupe === 'other'){
            tmpOther.push(props.bankAccountZU[i])
        }else if(props.bankAccountZU[i].groupe === 'advancePayment'){
            tmpAdvancePayment.push(props.bankAccountZU[i])
        }else if(props.bankAccountZU[i].groupe === 'lease'){
            tmpLease.push(props.bankAccountZU[i])
        }else if(props.bankAccountZU[i].groupe === 'payment'){
            tmpPayment.push(props.bankAccountZU[i])
        }else if(props.bankAccountZU[i].groupe === 'preparationPayment'){
            tmpPreparationPayment.push(props.bankAccountZU[i])
        }
    }
    if(tmpPayment.length > 0){
        let data = {}
        let tmp = []
        for(let i=0; i < tmpPayment.length; i++){
            //tmp[i] = Number(tmpPayment[i].id)
            data.id = Number(tmpPayment[i].id)
            data.groupe = "payment"
            await createZUBanksPOST(props, data, aucId)
        }
        //data.id = tmp
        //data.groupe = "payment"
        //createZUBanksPOST(props, data, aucId)
    }
    if(tmpPreparationPayment.length > 0){
        let data = {}
        let tmp = []
        for(let i=0; i < tmpPreparationPayment.length; i++){
            //tmp[i] = Number(tmpPreparationPayment[i].id)
            data.id = Number(tmpPreparationPayment[i].id)
            data.groupe = "preparationPayment"
            await createZUBanksPOST(props, data, aucId)
        }
        //data.id = tmp
        //data.groupe = "preparationPayment"
        //createZUBanksPOST(props, data, aucId)
    }

    if(tmpRregistrationFee.length > 0){
        let data = {}
        let tmp = []
        for(let i=0; i < tmpRregistrationFee.length; i++){
            //tmp[i] = Number(tmpRregistrationFee[i].id)
            data.id = Number(tmpRregistrationFee[i].id)
            data.groupe = "registrationFee"
            await createZUBanksPOST(props, data, aucId)
        }
        //data.id = tmp
        //data.groupe = "registrationFee"
        //createZUBanksPOST(props, data, aucId)
    }
    if(tmpGuarantee.length > 0){
        let data = {}
        let tmp = []
        for(let i=0; i < tmpGuarantee.length; i++){
            //tmp[i] = Number(tmpGuarantee[i].id)
            data.id = Number(tmpGuarantee[i].id)
            data.groupe = "guarantee"
            await createZUBanksPOST(props, data, aucId)
        }
        //data.id = tmp
        //data.groupe = "guarantee"
        //createZUBanksPOST(props, data, aucId)
    }
    if(tmpSecurityDeposit.length > 0){
        let data = {}
        let tmp = []
        for(let i=0; i < tmpSecurityDeposit.length; i++){
            //tmp[i] = Number(tmpSecurityDeposit[i].id)
            data.id = Number(tmpSecurityDeposit[i].id)
            data.groupe = "securityDeposit"
            createZUBanksPOST(props, data, aucId)
        }
        //data.id = tmp
        //data.groupe = "securityDeposit"
        //createZUBanksPOST(props, data, aucId)
    }
    if(tmpImprovementCompensation.length > 0){
        let data = {}
        let tmp = []
        for(let i=0; i < tmpImprovementCompensation.length; i++){
            //tmp[i] = Number(tmpImprovementCompensation[i].id)
            data.id = Number(tmpImprovementCompensation[i].id)
            data.groupe = "improvementCompensation"
            await createZUBanksPOST(props, data, aucId)
        }
        //data.id = tmp
        //data.groupe = "improvementCompensation"
        //createZUBanksPOST(props, data, aucId)
    }
    if(tmpOther.length > 0){
        let data = {}
        let tmp = []
        for(let i=0; i < tmpOther.length; i++){
            //tmp[i] = Number(tmpOther[i].id)
            data.id = Number(tmpOther[i].id)
            data.groupe = "other"
            await createZUBanksPOST(props, data, aucId)
        }
        //data.id = tmp
        //data.groupe = "other"
        //createZUBanksPOST(props, data, aucId)
    }
    if(tmpAdvancePayment.length > 0){
        let data = {}
        let tmp = []
        for(let i=0; i < tmpAdvancePayment.length; i++){
            //tmp[i] = Number(tmpAdvancePayment[i].id)
            data.id = Number(tmpAdvancePayment[i].id)
            data.groupe = "advancePayment"
            if(tmpAdvancePayment[i].percent !== ''){
                data.percent = tmpAdvancePayment[i].percent
            }
            if(tmpAdvancePayment[i].holderType !== 'null'){
                data.holderType = tmpAdvancePayment[i].holderType
            }
            await createZUBanksPOST(props, data, aucId)
        }
    }
    if(tmpLease.length > 0){
        let data = {}
        let tmp = []
        let tmpId = null
        for(let i=0; i < tmpLease.length; i++){
            //tmp[i] = Number(tmpLease[i].id)
            data.id = Number(tmpLease[i].id)
            data.groupe = "lease"
            if(tmpLease[i].percent !== ''){
                data.percent = tmpLease[i].percent
            }
            if(tmpLease[i].holderType !== 'null'){
                data.holderType = tmpLease[i].holderType
            }
            //tmpId = await createZUBanksPOST(props, data, aucId, i, tmpId)
            await createZUBanksPOST(props, data, aucId, i, tmpId)
        }
    }
    await clearBanks(props)
}

async function createZUBanksPOST(props, data, aucId){
    let body = {}
    for(let i = 0; i < props.bankAccount.length; i++){
        if(props.bankAccount[i].id === Number(data.id)){
            body.bankAccounts = [
                {
                    id: props.bankAccount[i].id,
                    bankName: props.bankAccount[i].bankName,
                    currency: props.bankAccount[i].currency,
                    accountIdentifications: [
                        {
                            id: props.bankAccount[i].accountIdentifications[0].id,
                            //description: props.bankAccount[i].accountIdentifications[0].description,
                            identifier: props.bankAccount[i].accountIdentifications[0].identifier,
                            scheme: props.bankAccount[i].accountIdentifications[0].scheme
                        }
                    ],
                    accountHolder: props.bankAccount[i].accountHolder
                }
            ]
        }
    }

    if(
        data.groupe === 'advancePayment' ||
        data.groupe === 'lease'
    ){
        if(data.holderType !== 'null') body.holderType = data.holderType
        if(data.percent !== '') body.percent = data.percent
    }
    let endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/auction/${aucId}/bank_account_groups/${data.groupe}`
    const response = await fetch(endPoint, {
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){
            let tmp = JSON.stringify({title: "Помилка при створенні Тип переліку, до якого віднесено об'єкт", message: JSON.stringify(json)})
            props.setUniversalError(tmp)
        }else{
            //await addArrayBankAccountsClear(props)
            //await addBankAccountZUtimestamp(props)
        }
    }
    return null
}

async function clearBanks(props){
    await addArrayBankAccountsClear(props)
    await addBankAccountZUtimestamp(props)
}


async function createZUBanksPOSTOld(props, data, aucId){
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/auction/${aucId}/bank_account_groups/${data.group}/${data.gropType}/${data.type}`
    const response = await fetch(endPoint, {
        method: "POST",
        body: JSON.stringify({
            bankAccountIds: data.id
        }),
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){
            let tmp = JSON.stringify({title: "Помилка при створенні Тип переліку, до якого віднесено об'єкт", message: JSON.stringify(json)})
            props.setUniversalError(tmp)
        }else{
            //await addArrayBankAccountsClear(props)
            //await addBankAccountZUtimestamp(props)
        }
    }
}

async function addArrayBankAccountsClear(props){
    props.setDataAddress([], 'SET_POP_UP_BANK_ACCOUNT_ZU')
}
async function addBankAccountZUtimestamp(props){
    let tmp = new Date()
    props.setDataAddress(String(tmp), 'SET_POP_UP_BANK_ACCOUNT_ZU_TIMESTAMP')
}

async function createObject(props, objId){
    props.changeLoader(true)
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/auction/bridge/${objId}`
    const response = await fetch(endPoint, {
        method: "POST",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){
            //let tmp = JSON.stringify({title: "Помилка при створенні Тип переліку, до якого віднесено об'єкт", message: JSON.stringify(json)})
            //props.setUniversalError(tmp)
        }else{
            //Тут подключаю файл редактирования аука
            //getAucionFromId(props, json.id)
            await editAuc(props, json.id)
        }
    }
}

async function createCurrentTenant(props, flag=false){
    props.changeLoader(true)
    let body = {}
    // console.log(props.auctionsMy)
    if(props.auctionsMy.currentTenants !== null){
        if(props.auctionsMy.hasOwnProperty("currentTenant") === true && props.auctionsMy.currentTenant !== null && props.auctionsMy.currentTenant.hasOwnProperty("currentTenants") === true && props.auctionsMy.currentTenant.currentTenants !== null){
            body.currentTenants = props.auctionsMy.currentTenant.currentTenants
        }else{
            body.currentTenants = props.auctionsMy.currentTenants
        }
        let method = 'POST'
        ///if(props.auctionsMy.currentTenant.id !== null) method = 'PATCH'
        const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/auction/${props.auctionsMy.id}/currentTenant`
        const response = await fetch(endPoint, {
            method: method,
            body: JSON.stringify(body),
            headers: {
                'Authorization': props.token.access_token,
                'Content-Type': 'application/json'
            }
        }).catch(console.error)
        if(response === undefined || response === 'undefined'){
            props.changeLoader(false)
        }else{
            const json = await response.json()
            if(json.hasOwnProperty('message') === true){
                let tmp = JSON.stringify({title: "Помилка при редагуванні учасника з переважним правом", message: JSON.stringify(json)})
                props.setUniversalError(tmp)
                props.changeLoader(false)
            }else{
                ////Тут подключаю файл редактирования аука
                if(flag === true){
                    await props.setPopUpAuctionCreate(props.auctionsMy)
                    //await getAucionFromIdTT(props, props.auctionsMy.id)
                }
            }
        }
    }
    props.changeLoader(false)
}
////////////////////////////////
async function getAucionFromIdTT(props, aucId){
    props.changeLoader(true)
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/auction/${aucId}`;
    const response = await fetch(endPoint, {
        method: "GET",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
		props.changeLoader(false)
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){
            let tmp = JSON.stringify({title: 'Помилка при завантаженні аукціону', message: JSON.stringify(json)})
	        props.setUniversalError(tmp)
			props.changeLoader(false)
        }else{
			await props.setDataAddress(json, 'EDIT_AUCTION_FROM_ID')
			props.changeLoader(false)
        }
    }
	props.changeLoader(false)
}
////////////////////////////////


function getAddressRegionName(props){
    if(props.auctionsMy !== null){
        if(props.auctionsMy.hasOwnProperty('currentTenant') === true){
            if(props.auctionsMy.currentTenant !== null){
                if(props.auctionsMy.currentTenant.hasOwnProperty('address') === true){
                    if(props.auctionsMy.currentTenant.address !== null){
                        if(props.auctionsMy.currentTenant.address.region !== '' && props.auctionsMy.currentTenant.address.region !== null){
                            return createRegionCode(props.auctionsMy.currentTenant.address.region)
                        }
                    }
                }
            }
        }
    }
    return ''
}

function getAddressRegionCode(props){
    if(props.auctionsMy !== null){
        if(props.auctionsMy.hasOwnProperty('currentTenant') === true){
            if(props.auctionsMy.currentTenant !== null){
                if(props.auctionsMy.currentTenant.hasOwnProperty('address') === true){
                    if(props.auctionsMy.currentTenant.address !== null){
                        if(props.auctionsMy.currentTenant.address.region !== '' && props.auctionsMy.currentTenant.address.region !== null){
                            return getRegion(props.auctionsMy.currentTenant.address.region)
                        }
                    }
                }
            }
        }
    }
    return ''
}

function createRegionCode(data){
    if(data === 'Автономна Республіка Крим' || data === "АВТОНОМНА РЕСПУБЛІКА КРИМ/М.СІМФЕРОПОЛЬ" || data === "0100000000+:+АВТОНОМНА РЕСПУБЛІКА КРИМ/М.СІМФЕРОПОЛЬ"){
        return '0100000000'
    }else if(data === 'Вінницька область' || data === "ВІННИЦЬКА ОБЛАСТЬ/М.ВІННИЦЯ" || data === "0500000000+:+ВІННИЦЬКА ОБЛАСТЬ/М.ВІННИЦЯ"){
        return '0500000000'
    }else if(data === 'Волинська область' || data === "ВОЛИНСЬКА ОБЛАСТЬ/М.ЛУЦЬК" || data === "0700000000+:+ВОЛИНСЬКА ОБЛАСТЬ/М.ЛУЦЬК"){
        return '0700000000'
    }else if(data === 'Дніпропетровська область' || data === "ДНІПРОПЕТРОВСЬКА ОБЛАСТЬ/М.ДНІПРО" || data === "1200000000+:+ДНІПРОПЕТРОВСЬКА ОБЛАСТЬ/М.ДНІПРО"){
        return '1200000000'
    }else if(data === 'Донецька область' || data === "ДОНЕЦЬКА ОБЛАСТЬ/М.ДОНЕЦЬК" || data === "1400000000+:+ДОНЕЦЬКА ОБЛАСТЬ/М.ДОНЕЦЬК"){
        return '1400000000'
    }else if(data === 'Житомирська область' || data === "ЖИТОМИРСЬКА ОБЛАСТЬ/М.ЖИТОМИР" || data === "1800000000+:+ЖИТОМИРСЬКА ОБЛАСТЬ/М.ЖИТОМИР"){
        return '1800000000'
    }else if(data === 'Закарпатська область' || data === "ЗАКАРПАТСЬКА ОБЛАСТЬ/М.УЖГОРОД" || data === "2100000000+:+ЗАКАРПАТСЬКА ОБЛАСТЬ/М.УЖГОРОД"){
        return '2100000000'
    }else if(data === 'Запорізька область' || data === "ЗАПОРІЗЬКА ОБЛАСТЬ/М.ЗАПОРІЖЖЯ" || data === "2300000000+:+ЗАПОРІЗЬКА ОБЛАСТЬ/М.ЗАПОРІЖЖЯ"){
        return '2300000000'
    }else if(data === 'Івано-Франківська область' || data === "ІВАНО-ФРАНКІВСЬКА ОБЛАСТЬ/М.ІВАНО-ФРАНКІВСЬК" || data === "2600000000+:+ІВАНО-ФРАНКІВСЬКА ОБЛАСТЬ/М.ІВАНО-ФРАНКІВСЬК"){
        return '2600000000'
    }else if(data === 'Київська область' || data === "КИЇВСЬКА ОБЛАСТЬ/М.КИЇВ" || data === "3200000000+:+КИЇВСЬКА ОБЛАСТЬ/М.КИЇВ"){
        return '3200000000'
    }else if(data === 'Кіровоградська область' || data === "КІРОВОГРАДСЬКА ОБЛАСТЬ/М.КРОПИВНИЦЬКИЙ" || data === "3500000000+:+КІРОВОГРАДСЬКА ОБЛАСТЬ/М.КРОПИВНИЦЬКИЙ"){
        return '3500000000'
    }else if(data === 'Луганська область' || data === "ЛУГАНСЬКА ОБЛАСТЬ/М.ЛУГАНСЬК" || data === "4400000000+:+ЛУГАНСЬКА ОБЛАСТЬ/М.ЛУГАНСЬК"){
        return '4400000000'
    }else if(data === 'Львівська область' || data === "ЛЬВІВСЬКА ОБЛАСТЬ/М.ЛЬВІВ" || data === "4600000000+:+ЛЬВІВСЬКА ОБЛАСТЬ/М.ЛЬВІВ"){
        return '4600000000'
    }else if(data === 'Миколаївська область' || data === "МИКОЛАЇВСЬКА ОБЛАСТЬ/М.МИКОЛАЇВ" || data === "4800000000+:+МИКОЛАЇВСЬКА ОБЛАСТЬ/М.МИКОЛАЇВ"){
        return '4800000000'
    }else if(data === 'Одеська область' || data === "ОДЕСЬКА ОБЛАСТЬ/М.ОДЕСА" || data === "5100000000+:+ОДЕСЬКА ОБЛАСТЬ/М.ОДЕСА"){
        return '5100000000'
    }else if(data === 'Полтавська область' || data === "ПОЛТАВСЬКА ОБЛАСТЬ/М.ПОЛТАВА" || data === "5300000000+:+ПОЛТАВСЬКА ОБЛАСТЬ/М.ПОЛТАВА"){
        return '5300000000'
    }else if(data === 'Рівненська область' || data === "РІВНЕНСЬКА ОБЛАСТЬ/М.РІВНЕ" || data === "5600000000+:+РІВНЕНСЬКА ОБЛАСТЬ/М.РІВНЕ"){
        return '5600000000'
    }else if(data === 'Сумська область' || data === "СУМСЬКА ОБЛАСТЬ/М.СУМИ" || data === "5900000000+:+СУМСЬКА ОБЛАСТЬ/М.СУМИ"){
        return '5900000000'
    }else if(data === 'Тернопільська область' || data === "ТЕРНОПІЛЬСЬКА ОБЛАСТЬ/М.ТЕРНОПІЛЬ" || data === "6100000000+:+ТЕРНОПІЛЬСЬКА ОБЛАСТЬ/М.ТЕРНОПІЛЬ"){
        return '6100000000'
    }else if(data === 'Харківська область' || data === "ХАРКІВСЬКА ОБЛАСТЬ/М.ХАРКІВ" || data === "6300000000+:+ХАРКІВСЬКА ОБЛАСТЬ/М.ХАРКІВ"){
        return '6300000000'
    }else if(data === 'Херсонська область' || data === "ХЕРСОНСЬКА ОБЛАСТЬ/М.ХЕРСОН" || data === "6500000000+:+ХЕРСОНСЬКА ОБЛАСТЬ/М.ХЕРСОН"){
        return '6500000000'
    }else if(data === 'Хмельницька область' || data === "ХМЕЛЬНИЦЬКА ОБЛАСТЬ/М.ХМЕЛЬНИЦЬКИЙ" || data === "6800000000+:+ХМЕЛЬНИЦЬКА ОБЛАСТЬ/М.ХМЕЛЬНИЦЬКИЙ"){
        return '6800000000'
    }else if(data === 'Черкаська область' || data === "ЧЕРКАСЬКА ОБЛАСТЬ/М.ЧЕРКАСИ" || data === "7100000000+:+ЧЕРКАСЬКА ОБЛАСТЬ/М.ЧЕРКАСИ"){
        return '7100000000'
    }else if(data === 'Чернівецька область' || data === "ЧЕРНІВЕЦЬКА ОБЛАСТЬ/М.ЧЕРНІВЦІ" || data === "7300000000+:+ЧЕРНІВЕЦЬКА ОБЛАСТЬ/М.ЧЕРНІВЦІ"){
        return '7300000000'
    }else if(data === 'Чернігівська область' || data === "ЧЕРНІГІВСЬКА ОБЛАСТЬ/М.ЧЕРНІГІВ" || data === "7400000000+:+ЧЕРНІГІВСЬКА ОБЛАСТЬ/М.ЧЕРНІГІВ"){
        return '7400000000'
    }else if(data === 'Київ' || data === "М.КИЇВ" || data === "8000000000+:+М.КИЇВ"){
        return '8000000000'
    }else if(data === 'Севастополь' || data === "М.СЕВАСТОПОЛЬ" || data === "8500000000+:+М.СЕВАСТОПОЛЬ"){
        return '8500000000'
    }else{
        return ''
    }
}


//////////////////////////////////////////////
function blokCurrentTenant(props){
    let tmpCurrent = Date.now()
    if(props.auctionsMy.auctionPeriods !== null){
        if(props.auctionsMy.auctionPeriods.length === 0){
            return true
        }
        //let tmpStart = dateTimeStart(props)
        let tmpEnd = dateTimeEnd(props)
        //if( tmpCurrent < tmpStart.getTime()){
        //    return false
        //}else if(tmpCurrent > tmpEnd.getTime()){
        if(tmpCurrent > tmpEnd.getTime()){
            return false
        }else{
            return true
        }
    }
}

function dateTimeStart(props){
    if(props.auctionsMy.auctionPeriods !== null){
        if(props.auctionsMy.auctionPeriods.length > 0){
            for(let tm = 0; tm < props.auctionsMy.auctionPeriods.length; tm++){
                if(props.auctionsMy.auctionPeriods[tm].status === "transferPriorityPeriod") {
                    return new Date(props.auctionsMy.auctionPeriods[tm].startDate)
                }
            }
        }
    }
}

function dateTimeEnd(props){
    if(props.auctionsMy.auctionPeriods !== null){
        if(props.auctionsMy.auctionPeriods.length > 0){
            for(let tm = 0; tm < props.auctionsMy.auctionPeriods.length; tm++){
                if(props.auctionsMy.auctionPeriods[tm].status === "transferPriorityPeriod") {
                    return new Date(props.auctionsMy.auctionPeriods[tm].endDate)
                }
            }
        }
    }
}

async function getAucions(props, id){
    props.changeLoader(true)
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/auction/${id}`;
    const response = await fetch(endPoint, {
        method: "GET",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){
            let tmp = JSON.stringify({title: 'Помилка при завантаженні аукціонів', message: JSON.stringify(json)})
	        props.setUniversalError(tmp)
        }else{
            props.setDataAddress(json, 'EDIT_AUCTION_FROM_ID')
        }
    }
    props.changeLoader(false)
}

const mapStateToProps = state => {
    return {
        token: state.start.token,
        auctions: state.start.auctionsGet,
        langFlag: state.start.langFlag,
        createAuctionTab: state.start.createAuctionTab,
        createAuction: state.start.createAuction,
        auctionsSwitchWindow: state.start.auctionsSwitchWindow,
        endPoint: state.start.endPoint,
        classificationsGets: state.start.classificationsGet,
        auctionsMy: state.start.auctionsMy,
        bankAccount: state.start.bankAccount,
        bankAccountId: state.start.bankAccountId,
        bankAccountIdArr: state.start.bankAccountIdArr,
        discount: state.start.discount,
        userProfiles: state.start.userProfiles,
        carryType: state.start.carryType,
        bankAccountWork: state.start.bankAccountWork,
        addressNew: state.start.addressNew,
        bankAccountZU: state.start.bankAccountZU,
        createBankAccountZU: state.start.createBankAccountZU,
        schedule: state.start.schedule,
        auctionsZUAttemp: state.start.auctionsZUAttemp,
        shedullePeriod: state.start.shedullePeriod,
    }
}

const mapDispatchToProps = {
    changeCreateAuctionTitle,
    changeCreateAuctionDescription,

    changeCreateAuctionType,
    changeCreateAuctionTenderAttempts,
    changeCreateAuctionAccessDetails,
    changeCreateAuctionXDocumentRequirements,
    changeCreateAuctionXAdditionalInformation,
    changeCreateAuctionValueCurrency,
    changeCreateAuctionValueAmount,
    changeCreateAuctionValueTax,
    changeCreateAuctionGuaranteeCurrency,
    changeCreateAuctionGuaranteeAmount,
    changeCreateAuctionGuaranteeTax,
    changeCreateAuctionMinimalStepCurrency,
    changeCreateAuctionMinimalStepAmount,
    changeCreateAuctionMinimalStepTax,
    changeCreateAuctionDateStart,
    changeCreateAuctionDateEnd,

    changeAuctionsSwitchWindow,

    auctionsGetMy,
    getMyAuction,
    changeCreateAuctionLotIdentifier,

    changePopUpBankAccount,
    setPopBankAccountId,
    setPopBankAccountIdArr,
    changeCreateAuctionTab,

    setPopUpAuctionCreate,

    changeCreateItemGeologivDataValue,
    changeCreateItemDocumentationValue,
    changeCreateItemDateUse,
    setCarryType,

    setPopUpAuctionMyNull,

    changeDiscountPercent,
    changeDiscountPreviousAuctionCurrency,
    changeDiscountPreviousAuctionAmount,
    changeDiscountPreviousAuctionId,
    discountAuc,
    bankAccountGet,

    minNumberOfQualifiedBids,
    setUniversalError,

    setBankAccountSave,
    setBankAccountAdd,
    setBankAccountDelete,

    changeCreateAuctionMinNumberOfQualifiedBids,
    changeCreateAuctionDgfVirtualDataRoom,
    changeCreateAuctionDgfEligibilityCriteria,
    changeCreateAuctionDgfX_dgfPlatformLegalDetails,
    changeCreateAuctionDgfDecisionId,
    changeCreateAuctionDgfDecisionDate,
    setDataAddress,

    changeLoader,
    openBankWindows,
}

export default connect(mapStateToProps, mapDispatchToProps)(WindowAuction)