import React from 'react';
import style from './period.module.css';

import {connect} from 'react-redux';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { TimePicker, DateTimePicker, KeyboardDateTimePicker } from "@material-ui/pickers";

import {TranslationsUk} from '../../../redux/translations.uk.js';

import {
    setDataAddress,
} from '../../../redux/createAddress';

import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';

import {modifiDateString} from '../../../redux/modifiDate'

import {
    setEditAuctionFromId,

    getMyAuction,
    changeLoader,
} from '../../../redux/actions.js';

import {setUniversalError} from '../../../redux/actions.js';

import {
    changeDiscountPercent,
    changeDiscountPreviousAuctionCurrency,
    changeDiscountPreviousAuctionAmount,
    changeDiscountPreviousAuctionId
} from '../../../redux/actions/discount/discount.js';


const ListAddress = ( props ) => {
    return (
        <div className={style.popup_wrap}>
            <div className={style.popup_message}>
                <div
                    className={style.popup_closeX}
                    onClick={() => {
                            props.setDataAddress([], 'SET_NEW_PERIOD_ARR')
                            props.setDataAddress('', 'SET_SCHEDULE_PERIOD_START_DATE')
                            props.setDataAddress('', 'SET_SCHEDULE_PERIOD_END_DATE')
                            props.setDataAddress('null', 'SET_SCHEDULE_WEEKDAY')
                            props.setDataAddress('', 'SET_SCHEDULE_TIME_START')
                            props.setDataAddress('', 'SET_SCHEDULE_TIME_END')
                            props.setDataAddress(false, 'SET_SHEDULE_PERIOD')
                            props.setDataAddress(null, 'SET_SHEDULE_PERIOD_ID')
                        }
                    }
                    
                >
                    X
                </div>
                <div className={style.popup_main_doc}>
                    <h3>Створення періода</h3>
                </div>
                <div>{TranslationsUk['legitimatePropertyLease-english'].legitimatePropertyLeaseEnglishProcedure.leaseRules.schedule.period.legalName}</div>
                <div className={style.groupElem}>
                    <KeyboardDateTimePicker
                        className={style.dateSearch}
                        label="Дата та час початку"
                        value={textData(props.schedule.period.startDate)}
                        onChange={
                            (e) => {
                                let tmpData = new Date(e)
                                let tmpMonth = tmpData.getMonth()+1
                                if(tmpMonth < 10) tmpMonth = `0${tmpMonth}`
                                let tmpDay = tmpData.getDate()
                                if(tmpDay < 10) tmpDay = `0${tmpDay}`
                                let tmpString = `${tmpData.getFullYear()}/${tmpMonth}/${tmpDay}`
                                props.setDataAddress(tmpString, 'SET_SCHEDULE_PERIOD_START_DATE')
                            }
                        }
                        onError={console.log}
                        minDate={new Date("1900-01-01T00:00")}
                        ampm={false}
                        format="yyyy/MM/dd HH:mm"
                        helperText="Формат дати: гггг/мм/дд чч:мм"
                    />
                    <KeyboardDateTimePicker
                        className={style.dateSearch}
                        label="Дата та час завершення"
                        value={textData(props.schedule.period.endDate)}
                        onChange={
                            (e) => {
                                let tmpData = new Date(e)
                                let tmpMonth = tmpData.getMonth()+1
                                if(tmpMonth < 10) tmpMonth = `0${tmpMonth}`
                                let tmpDay = tmpData.getDate()
                                if(tmpDay < 10) tmpDay = `0${tmpDay}`
                                let tmpString = `${tmpData.getFullYear()}/${tmpMonth}/${tmpDay}`
                                props.setDataAddress(tmpString, 'SET_SCHEDULE_PERIOD_END_DATE')
                            }
                        }
                        onError={console.log}
                        minDate={new Date("1900-01-01T00:00")}
                        ampm={false}
                        format="yyyy/MM/dd HH:mm"
                        helperText="Формат дати: гггг/мм/дд чч:мм"
                    />
                </div>
                {buttonCreatePeriod(props)}
                <div>
                    {viewNewPeriodWeekdaysArr(props)}
                </div>
                <div className={style.groupElem}>
                    <TextField
                        className={style.inputText}
                        id="outlined-select-currency-native"
                        select
                        label="День тижня"
                        defaultValue={props.schedule.period.weekday}
                        onChange={(e)=>{
                            props.setDataAddress(e.target.value, 'SET_SCHEDULE_WEEKDAY')
                        }}
                        variant="outlined"
                    >
                        <option key={0} className={style.hoverOptions} selected value="null">Обрати</option>
                        <option key={1} className={style.hoverOptions} value="Понеділок">Понеділок</option>
                        <option key={2} className={style.hoverOptions} value="Вівторок">Вівторок</option>
                        <option key={3} className={style.hoverOptions} value="Середа">Середа</option>
                        <option key={4} className={style.hoverOptions} value="Четвер">Четвер</option>
                        <option key={5} className={style.hoverOptions} value="П'ятниця">П'ятниця</option>
                        <option key={6} className={style.hoverOptions} value="Субота">Субота</option>
                        <option key={7} className={style.hoverOptions} value="Неділя">Неділя</option>
                    </TextField>
                    <TimePicker
                        clearable
                        ampm={false}
                        label="Початок"
                        value={props.schedule.period.startTime}
                        onChange={
                            (e) => {
                                /*let tmpData = new Date(e)
                                let tmpMonth = tmpData.getMonth()+1
                                if(tmpMonth < 10) tmpMonth = `0${tmpMonth}`
                                let tmpDay = tmpData.getDate()
                                if(tmpDay < 10) tmpDay = `0${tmpDay}`
                                let tmpHours = tmpData.getHours()
                                if(tmpHours < 10) tmpHours = `0${tmpHours}`
                                let tmpMinutes = tmpData.getMinutes()
                                if(tmpMinutes < 10) tmpMinutes = `0${tmpMinutes}`
                                let tmpString = `${tmpData.getFullYear()}-${tmpMonth}-${tmpDay}T${tmpHours}:${tmpMinutes}:00+00:00`*/
                                props.setDataAddress(e, 'SET_SCHEDULE_TIME_START')
                            }
                        }
                    />
                    <TimePicker
                        clearable
                        ampm={false}
                        label="Кінець"
                        value={props.schedule.period.endTime}
                        onChange={
                            (e) => {
                                props.setDataAddress(e, 'SET_SCHEDULE_TIME_END')
                            }
                        }
                    />
                </div>
                {buttonCreateWeekendPeriod(props)}
            </div>
        </div>
    )
}

function buttonCreateWeekendPeriod(props){
    if(
        props.schedule.period.startDate !== '' &&
        props.schedule.period.endDate !== '' &&
        props.schedule.period.weekday !== 'null' &&
        props.schedule.period.startTime !== '' &&
        props.schedule.period.endTime !== ''
    ){
        return (
            <div className={style.bottonBlock}>
                <Button
                    className={style.buttonExitDocument}
                    variant="contained"
                    color="primary"
                    onClick={
                        () => {
                            let tmp = []
                            if(props.shedullePeriod.newPeriodArr.length > 0){
                                tmp = props.shedullePeriod.newPeriodArr
                            }
                            tmp.push(
                                {
                                    weekday: props.schedule.period.weekday,
                                    startTime: strDateToTime(props.schedule.period.startTime),
                                    endTime: strDateToTime(props.schedule.period.endTime)
                                }
                            )
                            props.setDataAddress(tmp, 'SET_NEW_PERIOD_ARR')
                            props.setDataAddress('null', 'SET_SCHEDULE_WEEKDAY')
                            props.setDataAddress('', 'SET_SCHEDULE_TIME_START')
                            props.setDataAddress('', 'SET_SCHEDULE_TIME_END')
                        }
                    }
                >
                    Додати період
                </Button>
            </div>
        )
    }
    return (
        <div className={style.bottonBlock}>
            <Button
                disabled
                className={style.buttonExitDocument}
                variant="contained"
                color="disabled"
            >
                Додати період
            </Button>
        </div>
    )
}

function strDateToTime(data){
    let tmpHours = data.getHours()
    if(Number(tmpHours) < 10) tmpHours = `0${tmpHours}`
    let tmpMinutes = data.getMinutes()
    if(Number(tmpMinutes) < 10) tmpMinutes = `0${tmpMinutes}`
    return tmpHours + ':' + tmpMinutes
}

function buttonCreatePeriod(props){
    if(
        props.schedule.period.startDate !== '' &&
        props.schedule.period.endDate !== '' &&
        props.shedullePeriod.newPeriodArr.length > 0
    ){
        return (
            <div className={style.bottonBlock}>
                <Button
                    className={style.buttonExitDocument}
                    variant="contained"
                    color="primary"
                    onClick={
                        () => {
                            if(props.shedullePeriod.id !== null){

                                editWeek(props, props.auctionsMy.id, props.shedullePeriod.id)
                                props.setDataAddress([], 'SET_NEW_PERIOD_ARR')
                                props.setDataAddress('', 'SET_SCHEDULE_PERIOD_START_DATE')
                                props.setDataAddress('', 'SET_SCHEDULE_PERIOD_END_DATE')
                                props.setDataAddress('null', 'SET_SCHEDULE_WEEKDAY')
                                props.setDataAddress('', 'SET_SCHEDULE_TIME_START')
                                props.setDataAddress('', 'SET_SCHEDULE_TIME_END')
                                props.setDataAddress(false, 'SET_SHEDULE_PERIOD')
                                //Єто для редактирования
                                props.setDataAddress(null, 'SET_SHEDULE_PERIOD_ID')
                            }else{
                                let tmp = []
                                if(props.shedullePeriod.newFullPeriod.length > 0){
                                    tmp = props.shedullePeriod.newFullPeriod
                                }
                                tmp.push(
                                    {                                        
                                        timeStart: props.schedule.period.startDate,
                                        timeEnd: props.schedule.period.endDate,
                                        weekdays: props.shedullePeriod.newPeriodArr
                                    }
                                )
                                props.setDataAddress(tmp, 'SET_NEW_FULL_PERIOD_ARR')
                                props.setDataAddress([], 'SET_NEW_PERIOD_ARR')
                                props.setDataAddress('', 'SET_SCHEDULE_PERIOD_START_DATE')
                                props.setDataAddress('', 'SET_SCHEDULE_PERIOD_END_DATE')
                                props.setDataAddress('null', 'SET_SCHEDULE_WEEKDAY')
                                props.setDataAddress('', 'SET_SCHEDULE_TIME_START')
                                props.setDataAddress('', 'SET_SCHEDULE_TIME_END')
                                props.setDataAddress(false, 'SET_SHEDULE_PERIOD')
                                //Єто для редактирования
                                props.setDataAddress(null, 'SET_SHEDULE_PERIOD_ID')
                            }
                        }
                    }
                >
                    {typeTextCreateOrSave(props)}
                </Button>
            </div>
        )
    }
    return (
        <div className={style.bottonBlock}>
            <Button
                disabled
                className={style.buttonExitDocument}
                variant="contained"
                color="disabled"
            >
                {typeTextCreateOrSave(props)}
            </Button>
        </div>
    )
}

function typeTextCreateOrSave(props){
    if(props.shedullePeriod.id !== null) return 'Зберегти період та закрити вікно'
    return 'Створити період та закрити вікно'
}

function textData(value){
    /*let tmp = ''
    if(value !== ''){
        tmp = value.split('T');
        return tmp[0]
    }*/
    //return tmp
    return value
}


function viewNewPeriodWeekdaysArr(props){
    if(props.shedullePeriod.newPeriodArr.length > 0){
        return props.shedullePeriod.newPeriodArr
        .filter(
            (e) => {
                return e !== null
            }
        )
        .map(
            (e, count) => {
                return (
                    <div className={style.weekend_elem}>
                        <div className={style.weekend_data}>День тижня: {e.weekday}</div>
                        <div className={style.weekend_data}>Початок: {e.startTime}</div>
                        <div className={style.weekend_data}>Кінець: {e.endTime}</div>
                        <IconButton 
                            aria-label="edit"
                            onClick={
                                () => {
                                    let tmp = []
                                    let tmpCount = 0
                                    for(let i = 0; i < props.shedullePeriod.newPeriodArr.length; i++){
                                        if(i !== count){
                                            tmp[tmpCount] = props.shedullePeriod.newPeriodArr[i]
                                            tmpCount++
                                        }
                                    }
                                    props.setDataAddress(tmp, 'SET_NEW_PERIOD_ARR')
                                }
                            }
                        >
                            <DeleteIcon/>
                        </IconButton>
                    </div>
                )
            }
        )
    }
}

function viewNewPeriodArr(props){
    if(props.shedullePeriod.newFullPeriod.length > 0){
        return props.shedullePeriod.newFullPeriod.map(
            (e, count) => {
                let tmpWeekday = null
                if(e.weekdays.length > 0) tmpWeekday = viewNewPeriodWeekdaysArr(e.weekdays)
                let tmpStart = null
                if(e.startTime !== '') tmpStart = modifiDateString(e.startTime, 1, true)
                let tmpEnd = null
                if(e.endTime !== '') tmpEnd = modifiDateString(e.endTime, 1, true)
                return (
                    <div className={style.weekend_wrap}>
                        <div className={style.weekend}>
                            <div className={style.weekend_data}>Дата початку: {tmpStart}</div>
                            <div className={style.weekend_data}>Дата завершення: {tmpEnd}</div>
                            <IconButton 
                                aria-label="edit"
                                onClick={
                                    () => {
                                        let tmp = []
                                        for(let i = 0; i < props.shedullePeriod.newFullPeriod.length; i++){
                                            if(i !== count){
                                                tmp[i] = props.shedullePeriod.newFullPeriod[i]
                                            }
                                        }
                                        props.setDataAddress(tmp, 'SET_NEW_FULL_PERIOD_ARR')
                                    }
                                }
                            >
                                <DeleteIcon/>
                            </IconButton>
                        </div>
                        <div>{tmpWeekday}</div>
                    </div>
                )
            }
        )
    }
}

function viewArr(props){
    if(props.shedullePeriod.newPeriodArr.length > 0){
        return props.shedullePeriod.newPeriodArr.map(
            (e, count) => {
                return (
                    <div>
                        <div>{e.weekday}</div>
                        <div
                            onClick={
                                () => {
                                    let tmp = []
                                    if(props.shedullePeriod.newPeriodArr.length > 0){
                                        for(let i = 0; i < props.shedullePeriod.newPeriodArr.length; i++){
                                            if(i !== count){
                                                tmp[i] = props.shedullePeriod.newPeriodArr[i]
                                            }
                                        }
                                        props.setDataAddress(tmp, 'SET_NEW_PERIOD_ARR')
                                    }
                                }
                            }
                        >
                            Видалити
                        </div>
                    </div>
                )
            }
        )
    }
}


async function editWeek(props, aucId, periodId){
    props.changeLoader(true)
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${aucId}/lease_rules/schedule/period/${periodId}`
    let body = {}
    if(props.schedule.period.startDate !== ''){
        body.startDate = props.schedule.period.startDate
    }
    if(props.schedule.period.endDate !== ''){
        body.endDate = props.schedule.period.endDate
    }
    if(props.shedullePeriod.newPeriodArr.length > 0){
        body.weekdays = props.shedullePeriod.newPeriodArr
    }
    const response = await fetch(endPoint, {
        method: "PUT",
        body: JSON.stringify(body),
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json2 = await response.json()
        if(json2.hasOwnProperty('message') === true){

        }else{
            await props.getMyAuction(null)
            await getAucionFromId(props, aucId)
            await props.changeLoader(false)
        }
    }
}

async function getAucionFromId(props, aucId){
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${aucId}`;
    const response = await fetch(endPoint, {
        method: "GET",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){
            let tmp = JSON.stringify({title: 'Помилка при завантаженні аукціону', message: JSON.stringify(json)})
	        props.setUniversalError(tmp)
        }else{
            props.setEditAuctionFromId(json)
            if(json.previousAuctionId !== null){
                props.changeDiscountPreviousAuctionId(json.previousAuctionId)
                if(json.discount !== null){
                    props.changeDiscountPreviousAuctionAmount(json.discount.previousAuctionValue.amount)
                    props.changeDiscountPercent(json.discount.discountPercent)
                }
            }
        }
    }
}

const mapStateToProps = state => {
    return {
        auctionsMy: state.start.auctionsMy,
        token: state.start.token,
        auctions: state.start.auctionsGet,
        langFlag: state.start.langFlag,
        schedule: state.start.schedule,
        shedullePeriod: state.start.shedullePeriod,
    }
}

const mapDispatchToProps = {
    setDataAddress,
    changeLoader,
    getMyAuction,
    setUniversalError,
    setEditAuctionFromId,

    changeDiscountPreviousAuctionId,
    changeDiscountPreviousAuctionAmount,
    changeDiscountPercent,
}

export default connect(mapStateToProps, mapDispatchToProps)(ListAddress)