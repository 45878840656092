import React from 'react';
import style from './popupContractAppruve.module.css';

import {connect} from 'react-redux';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { TimePicker, DateTimePicker, KeyboardDateTimePicker } from "@material-ui/pickers";

import {
    auctionsGetAwards,
    auctionsGetAwardsId,
    changeAwardRejectPopUpStatus,
    changeAwardErrorPopUpStatus,
    changeAwardRejectTitle,
    changeAwardRejectDescription,
    changeAwardRejectDocumentType,
} from '../../../../redux/actions/awards/awards.js';

import {
    changeContractApprowePopUpStatus,
    changeContractErrorPopUpStatus,

    setContractTitle,
    setContractDescription,
    setContractContractNumber,
    setContractDateFrom,
    setContractDateTill,
    setContractStartDate,
    setContractEndDate,
    setContractCurrency,
    setContractAmount,
    setContractDateSigned,

    setContractDocTitle,
    setContractDocType,
    setContractDocDescription,
    setContractDatePaid,
} from '../../../../redux/actions/contracts/contracts.js';

import {setUniversalError, changeLoader} from '../../../../redux/actions.js';

import {contractsAuctionIdGet} from '../../../../redux/actions/contracts/contracts.js';

import {
    togetherApiPopUpOnOff,
    togetherApiDocTitle,
    togetherApiDocDescription,
    togetherApiDocType,
    togetherApiDocLang,
    togetherApiDocFileName,
    togetherApiECP,
} from '../../../../redux/togetherApi.js';
import {ecpApiPass, ecpApiList, changeCA} from '../../../../redux/ecpApi.js';

import {
    changeDocumentId,
    changeDocumentTitle,
    changeDocumentDescription,
    changeDocumentLang,
    changeDocumentFileName,
    changeDocumentType
} from '../../../../redux/actions/documents/documents.js';
import { styles } from '@material-ui/pickers/views/Calendar/Calendar';

import InstructionBlock from '../../../elements/viewData/instructionBlock/instructionBlock';
import InputText from '../../../elements/inputs/input';
import TextArea from '../../../elements/inputs/textArea';
import InputDate from '../../../elements/inputs/inputDate';
import InputAmount from '../../../elements/inputs/inputAmount';




import {setDataAddress} from '../../../../redux/createAddress';
import {getUpdateDataAuction} from '../../../product/updateData'
import { isTIE, isSUE, isSUD, isRCE, isRCD, isGFE, isGFD, isLLE, isLLD, isLLP,
    isLRE, isLSE, isLSP, isBSE, isBSD, isALE, isCSE, isCSD, isAAE, isBRE, isBRD, isBRW,
    isRLE, isRLD, isCLE, isCLD
} from '../../../../redux/procedure';
import nonRemovable from "../../../product/lotInformation/auctionElements/nonRemovable";

//Блокировка кнопки если нет документа contractSigned (Підписаний договір)
//Очиска полей при закрытии попапа


const ContractOther = ( props ) => {
    return (
        <>
            <div className={style.wrapMain}>
                <div className={style.wrap}>
                    <div
                        className={style.close}
                        onClick={() => {
                            props.setContractTitle('')
                            props.setContractDescription('')
                            props.setContractContractNumber('')
                            props.setContractDateFrom('')
                            props.setContractDateTill('')
                            props.setContractStartDate('')
                            props.setContractEndDate('')
                            props.setContractCurrency('')
                            props.setContractAmount('')
                            props.setContractDateSigned('')
                            props.setContractDocTitle('')
                            props.setContractDocType('null')
                            props.setContractDocDescription('')
                            props.changeContractApprowePopUpStatus('')
                        }}
                    >✕</div>

                    <div className={style.popup_main_doc}>
                        <div className={style.dataContracts}>
                            <div className={style.dataContractsTitle}>Дані договору</div>
                            {viewDataContract(props)}
                        </div>
                    </div>
                    <div className={style.bottomPart}>
                    </div>
                </div>
            </div>
        </>
    )
}

/////////////////////////////////////////
function viewDataContract(props){
    let tmpButton = null
    // console.log(props.timberContractPut,11)

    switch (true) {
        case(
            isLLE(props.popUpContractApprowe.sellingMethod) ||
            isLLD(props.popUpContractApprowe.sellingMethod) ||
            isLLP(props.popUpContractApprowe.sellingMethod)
        ):
            if (
                props.timberContractPut.title !== '' &&
                props.timberContractPut.description !== '' &&
                props.timberContractPut.contractNumber !== '' &&
                props.timberContractPut.contractTime.dateFrom !== '' &&
                props.timberContractPut.contractTime.dateTill !== '' &&
                props.timberContractPut.xValueUAH.amount !== null &&
                props.timberContractPut.dateSigned !== ''
            ) {
                if (props.auctions.privateData.isOwner !== false) {
                    tmpButton = <div
                        className={style.btnDone}
                        onClick={
                            () => {
                                updateContractData(props)
                            }
                        }
                    >
                        Зберегти
                    </div>
                }
            }
            break
        case(
            isBSE(props.popUpContractApprowe.sellingMethod) || isBSD(props.popUpContractApprowe.sellingMethod) ||
            isALE(props.popUpContractApprowe.sellingMethod) ||
            isCSE(props.popUpContractApprowe.sellingMethod) || isCSD(props.popUpContractApprowe.sellingMethod) ||
            isBRE(props.popUpContractApprowe.sellingMethod) || isBRD(props.popUpContractApprowe.sellingMethod) || isBRW(props.popUpContractApprowe.sellingMethod) ||
            isRLE(props.popUpContractApprowe.sellingMethod) || isRLD(props.popUpContractApprowe.sellingMethod) ||
            isCLE(props.popUpContractApprowe.sellingMethod) || isCLD(props.popUpContractApprowe.sellingMethod)
        ):
            if (
                props.timberContractPut.title !== '' &&
                props.timberContractPut.description !== '' &&
                props.timberContractPut.contractNumber !== '' &&
                //props.timberContractPut.contractTime.dateFrom !== '' &&
                //props.timberContractPut.contractTime.dateTill !== '' &&
                (props.timberContractPut.xValueUAH.amount !== null || props.timberContractPut.contractTotalValue.amount !== null) &&
                /*props.timberContractPut.contractTime.dateFrom !== '' &&*/
                props.timberContractPut.dateSigned !== ''
            ) {
                if (props.auctions.privateData.isOwner !== false) {
                    tmpButton = <div
                        className={style.btnDone}
                        onClick={
                            () => {
                                updateContractData(props)
                            }
                        }
                    >
                        Зберегти
                    </div>
                }
            }
            break
        case(!isLSE(props.popUpContractApprowe.sellingMethod) && !isLSP(props.popUpContractApprowe.sellingMethod)):
            // console.log(props.timberContractPut)
            if (
                props.timberContractPut.title !== '' &&
                props.timberContractPut.description !== '' &&
                props.timberContractPut.contractNumber !== '' &&
                props.timberContractPut.contractTime.dateFrom !== '' &&
                props.timberContractPut.contractTime.dateTill !== '' &&
                // (props.timberContractPut.xValueUAH.amount !== null || props.timberContractPut.contractTotalValue.amount !== null) &&
                /*props.timberContractPut.contractTime.dateFrom !== '' &&*/
                props.timberContractPut.dateSigned !== ''
            ) {
                if (props.auctions.privateData.isOwner !== false) {
                    tmpButton = <div
                        className={style.btnDone}
                        onClick={
                            () => {
                                updateContractData(props)
                            }
                        }
                    >
                        Зберегти
                    </div>
                }
            }
            break
        case (
            props.timberContractPut.title !== '' &&
            props.timberContractPut.description !== '' &&
            props.timberContractPut.contractNumber !== '' &&
            // props.timberContractPut.xValueUAH.amount !== null &&
            /*props.timberContractPut.contractTime.dateFrom !== '' &&*/
            props.timberContractPut.dateSigned !== ''
        ):
            if (props.auctions.privateData.isOwner !== false) {
                tmpButton = <div
                    className={style.btnDone}
                    onClick={
                        () => {
                            updateContractData(props)
                        }
                    }
                >
                    Зберегти
                </div>
            }
            break
        default:
            break
    }


    let tmpContractTitle = null
    // let dataName = {
    //     targetType: 'SET_CONTRACT_TITLE',
    //     target: props.timberContractPut.title,
    //     typePopUp: 'contracts',
    //     label: `Назва договору`,
    //     necessarily: true
    // }
    if(props.timberContractPut.title === '' || props.timberContractPut.title === null){
        tmpContractTitle = <InputText 
                                value={props.timberContractPut.title.hasOwnProperty("uk_UA")
                                    ? props.timberContractPut.title.uk_UA
                                    : props.timberContractPut.title
                                }
                                label='Назва договору'
                                onChange={(e)=> props.setDataAddress(e, 'SET_CONTRACT_TITLE')}
                                reqiured={true}
                            />
    }else{
        tmpContractTitle = <InputText 
                                value={props.timberContractPut.title.hasOwnProperty("uk_UA")
                                    ? props.timberContractPut.title.uk_UA
                                    : props.timberContractPut.title
                                }
                                label='Назва договору'
                                onChange={(e)=> props.setDataAddress(e, 'SET_CONTRACT_TITLE')}
                                reqiured={true}
                            />
    }
    let tmpContractDescription = null
    // let dataDescription = {
    //     targetType: 'SET_CONTRACT_DESCRIPTION',
    //     target: props.timberContractPut.description,
    //     typePopUp: 'contracts',
    //     label: `Опис договору`,
    //     necessarily: true
    // }
    if(props.timberContractPut.description === '' || props.timberContractPut.description === null){
        tmpContractDescription = <TextArea 
                                    value={props.timberContractPut.description.hasOwnProperty("uk_UA") 
                                        ? props.timberContractPut.description.uk_UA
                                        : props.timberContractPut.description
                                    }
                                    label='Опис договору'
                                    onChange={(e)=> props.setDataAddress(e, 'SET_CONTRACT_DESCRIPTION')}
                                    reqiured={true}
                                />
    }else{
        tmpContractDescription = <TextArea 
                                    value={props.timberContractPut.description.hasOwnProperty("uk_UA") 
                                        ? props.timberContractPut.description.uk_UA
                                        : props.timberContractPut.description
                                    }
                                    label='Опис договору'
                                    onChange={(e)=> props.setDataAddress(e, 'SET_CONTRACT_DESCRIPTION')}
                                    reqiured={true}
                                 />
    }
    let tmpContractNumber = null
    // let dataNumber = {
    //     targetType: 'SET_CONTRACT_CONTRACT_NUMBER',
    //     target: props.timberContractPut.contractNumber,
    //     typePopUp: 'contracts',
    //     label: `Номер договору`,
    //     necessarily: true
    // }
    if(props.timberContractPut.contractNumber === '' || props.timberContractPut.contractNumber === null){
        tmpContractNumber = <InputText 
                                value={props.timberContractPut.contractNumber}
                                label='Номер договору'
                                onChange={(e)=> props.setDataAddress(e, 'SET_CONTRACT_CONTRACT_NUMBER')}
                                reqiured={true}
                            />
    }else{
        tmpContractNumber = <InputText 
                                value={props.timberContractPut.contractNumber}
                                label='Номер договору'
                                onChange={(e)=> props.setDataAddress(e, 'SET_CONTRACT_CONTRACT_NUMBER')}
                                reqiured={true}
                             />
    }

    
    let tmpContractTimeStartDate = null
    let dataContractTimeStartDate = {}
    dataContractTimeStartDate.targetType = 'SET_CONTRACT_START_DATE'
    dataContractTimeStartDate.target = props.timberContractPut.contractTime.dateFrom
    dataContractTimeStartDate.typePopUp = 'contracts'
    dataContractTimeStartDate.functions = props.setContractStartDate
    dataContractTimeStartDate.label = `Строк дії договору Період з`
    if(
        isBSE(props.popUpContractApprowe.sellingMethod) || isBSD(props.popUpContractApprowe.sellingMethod) ||
        isALE(props.popUpContractApprowe.sellingMethod) ||
        isCSE(props.popUpContractApprowe.sellingMethod) || isCSD(props.popUpContractApprowe.sellingMethod) ||
        isBRE(props.popUpContractApprowe.sellingMethod) || isBRD(props.popUpContractApprowe.sellingMethod) || isBRW(props.popUpContractApprowe.sellingMethod) ||
        isRLE(props.popUpContractApprowe.sellingMethod) || isRLD(props.popUpContractApprowe.sellingMethod) ||
        isCLE(props.popUpContractApprowe.sellingMethod) || isCLD(props.popUpContractApprowe.sellingMethod)
    ){
        dataContractTimeStartDate.necessarily = false
    }else{
        dataContractTimeStartDate.necessarily = true
    }
    dataContractTimeStartDate.time = true
    if(
        !isLSE(props.popUpContractApprowe.sellingMethod) &&
        !isLSP(props.popUpContractApprowe.sellingMethod)
    ){
        if(props.timberContractPut.contractTime.dateFrom === '' || props.timberContractPut.contractTime.dateFrom === null){
            tmpContractTimeStartDate = <div className={style.groupElem}>
                <InputDate 
                    data={{
                        label: dataContractTimeStartDate.label,
                        target: dataContractTimeStartDate.target,
                        reqiured: dataContractTimeStartDate.necessarily,
                        time: dataContractTimeStartDate.time
                    }}
                    onChange={
                        (e) => {
                            props.setContractStartDate(e)
                        }
                    }
                />
            </div>
        }else{
            let tmpTimerStart = new Date(props.timberContractPut.contractTime.dateFrom).getTime()
            let tmpTimerSigned = new Date(props.timberContractPut.dateSigned).getTime()
            if(tmpTimerStart < tmpTimerSigned){
                tmpContractTimeStartDate = <div className={style.groupElem}>
                    <InputDate 
                        data={{
                            label: dataContractTimeStartDate.label,
                            target: dataContractTimeStartDate.target,
                            reqiured: dataContractTimeStartDate.necessarily,
                            time: dataContractTimeStartDate.time
                        }}
                        onChange={(e) => { props.setContractStartDate(e) }
                        }
                     />
                </div>
            }else{
                tmpContractTimeStartDate = <div className={style.groupElem}>
                    <InputDate 
                        data={{
                            label: dataContractTimeStartDate.label,
                            target: dataContractTimeStartDate.target,
                            reqiured: dataContractTimeStartDate.necessarily,
                            time: dataContractTimeStartDate.time
                        }}
                        onChange={(e) => { props.setContractStartDate(e) }
                        }
                    />
                </div>
            }
        }
    }
    let tmpContractTimeEndDate = null
    let dataContractTimeEndDate = {}
    dataContractTimeEndDate.targetType = 'SET_CONTRACT_END_DATE'
    dataContractTimeEndDate.target = props.timberContractPut.contractTime.dateTill
    dataContractTimeEndDate.typePopUp = 'contracts'
    dataContractTimeEndDate.functions = props.setContractEndDate
    dataContractTimeEndDate.label = `Строк дії договору Період по`
    if(
        isBSE(props.popUpContractApprowe.sellingMethod) || isBSD(props.popUpContractApprowe.sellingMethod) ||
        isALE(props.popUpContractApprowe.sellingMethod) ||
        isCSE(props.popUpContractApprowe.sellingMethod) || isCSD(props.popUpContractApprowe.sellingMethod) ||
        isBRE(props.popUpContractApprowe.sellingMethod) || isBRD(props.popUpContractApprowe.sellingMethod) || isBRW(props.popUpContractApprowe.sellingMethod) ||
        isRLE(props.popUpContractApprowe.sellingMethod) || isRLD(props.popUpContractApprowe.sellingMethod) ||
        isCLE(props.popUpContractApprowe.sellingMethod) || isCLD(props.popUpContractApprowe.sellingMethod)
    ){
        dataContractTimeEndDate.necessarily = false
    }else{
        dataContractTimeEndDate.necessarily = true
    }
    dataContractTimeEndDate.time = true
    if(
        !isLSE(props.popUpContractApprowe.sellingMethod) && !isLSP(props.popUpContractApprowe.sellingMethod)
    ){
        if(props.timberContractPut.contractTime.dateTill === '' || props.timberContractPut.contractTime.dateTill === null){
            tmpContractTimeEndDate = <div className={style.groupElem}>
                <InputDate 
                    data={{
                        target: props.timberContractPut.contractTime.dateTill,
                        label: dataContractTimeEndDate.label,
                        reqiured: dataContractTimeEndDate.necessarily,
                        time: dataContractTimeEndDate.time
                    }}
                    onChange={(e) => { props.setContractEndDate(e) }
                    }
                 />
            </div>
        }else{
            let tmpTimerStart = new Date(props.timberContractPut.contractTime.dateFrom).getTime()
            let tmpTimerEnd = new Date(props.timberContractPut.contractTime.dateTill).getTime()
            if(tmpTimerStart > tmpTimerEnd){
                tmpContractTimeEndDate = <div className={style.groupElem}>
                    <InputDate
                        data={{
                            target: props.timberContractPut.contractTime.dateTill,
                            label: dataContractTimeEndDate.label,
                            reqiured: dataContractTimeEndDate.necessarily,
                            time: dataContractTimeEndDate.time
                        }}
                        onChange={(e) => { props.setContractEndDate(e) }
                        }
                    />
                </div>
            }else{
                tmpContractTimeEndDate = <div className={style.groupElem}>
                    <InputDate
                        data={{
                            target: props.timberContractPut.contractTime.dateTill,
                            label: dataContractTimeEndDate.label,
                            reqiured: dataContractTimeEndDate.necessarily,
                            time: dataContractTimeEndDate.time
                        }}
                        onChange={(e) => { props.setContractEndDate(e) }
                        } 
                    />
                </div>
            }
        }
    }
    let tmpContractTimeDateFrom = null
    let dataContractTimeFromDate = {
        // targetType: 'SET_CONTRACT_DATE_FROM',
        target: props.timberContractPut.contractTime.dateFrom,
        // typePopUp: 'contracts',
        // functions: props.setContractDateFrom,
        label: `Дата передплати`,
        reqiured: true,
        time: true
    }
    if(
        !isLLE(props.popUpContractApprowe.sellingMethod) &&
        !isLLD(props.popUpContractApprowe.sellingMethod) &&
        !isLLP(props.popUpContractApprowe.sellingMethod) 
    ){
        if(props.timberContractPut.contractTime.dateFrom === '' || props.timberContractPut.contractTime.dateFrom === null){
            tmpContractTimeDateFrom = <div className={style.groupElem}>
                <InputDate data={dataContractTimeFromDate} onChange={(e) => { props.setContractDateFrom(e) }} />
            </div>
        }else{
            let tmpTimerFrom = new Date(props.timberContractPut.contractTime.dateFrom).getTime()
            let tmpTimerSigned = new Date(props.timberContractPut.dateSigned).getTime()
            let tmpTimerEnd = new Date(props.timberContractPut.contractTime.dateTill).getTime()
            if(tmpTimerFrom < tmpTimerSigned || tmpTimerEnd < tmpTimerFrom){
                tmpContractTimeDateFrom = <div className={style.dateTill}>
                    <InputDate data={dataContractTimeFromDate} onChange={(e) => { props.setContractDateFrom(e) }} />
                </div>
            }else{
                tmpContractTimeDateFrom = <div className={style.groupElem}>
                    <InputDate data={dataContractTimeFromDate} onChange={(e) => { props.setContractDateFrom(e) }} />
                </div>
            }
        }
    }
    let tmpContractTimeDateSigned = null
    let dataContractTimeSignedDate = {
        // targetType: 'SET_CONTRACT_DATE_SIGNED',
        target: props.timberContractPut.dateSigned,
        // typePopUp: 'contracts',
        // functions: props.setContractDateSigned,
        label: `Дата підписання договору`,
        reqiured: true,
        time: true
    }
    if(props.timberContractPut.dateSigned === '' || props.timberContractPut.dateSigned === null){
        tmpContractTimeDateSigned = <div className={style.groupElem}>
            <InputDate data={dataContractTimeSignedDate} onChange={(e) => { props.setContractDateSigned(e) }} />
        </div>
    }else{
        tmpContractTimeDateSigned = <div className={style.groupElem}>
            <InputDate data={dataContractTimeSignedDate} onChange={(e) => { props.setContractDateSigned(e) }} />
        </div>
    }
    let tmpData = null
    if(props.auctions !== null){
        if(props.auctions.value !== null){
            if(props.auctions.value.valueAddedTaxIncluded){
                tmpData = 'Загальна вартість договору в грн., з ПДВ'
            }else{
                tmpData = 'Загальна вартість договору в грн., без ПДВ'
            }
        }
        if(
            isRLE(props.auctions.sellingMethod) || isRLD(props.auctions.sellingMethod) ||
            isCLE(props.auctions.sellingMethod) || isCLD(props.auctions.sellingMethod) ||
            isLSP(props.auctions.sellingMethod) || isLSE(props.auctions.sellingMethod) || isLRE(props.auctions.sellingMethod)
        ){
            tmpData = 'Заключна вартість договору з урахуванням податків'
        }
        if (isBSE(props.auctions.sellingMethod)) {
            tmpData = 'Заключна вартість договору'
        }
    }

    let dataContractSumm = {
        target: props.timberContractPut.xValueUAH.amount,
        label: tmpData,
        reqiured: true,
    }
    if(
        isBSE(props.auctions.sellingMethod) ||
        isRLE(props.auctions.sellingMethod) || isRLD(props.auctions.sellingMethod) ||
        isCLE(props.auctions.sellingMethod) || isCLD(props.auctions.sellingMethod) ||
        isLSP(props.auctions.sellingMethod) || isLSE(props.auctions.sellingMethod) || isLRE(props.auctions.sellingMethod)
    ){
        console.log(props.timberContractPut.contractTotalValue)
        dataContractSumm = {
            target: props.timberContractPut.contractTotalValue.amount,
            label: tmpData,
            reqiured: true,
        }
    }

    let tmpDogovorSumm = <InputText
                                value={dataContractSumm.target}
                                label={dataContractSumm.label}
                                onChange={(e)=> props.setDataAddress(e, 'SET_CONTRACT_TOTAL_AMOUNT')}
                                reqiured={dataContractSumm.reqiured}
                             />
    
    let tmpDataValue = null
    if(props.auctions !== null){
        if(props.auctions.value !== null){
            if(props.auctions.value.valueAddedTaxIncluded){
                tmpDataValue = 'Вартість договору в грн., з ПДВ'
            }else{
                tmpDataValue = 'Вартість договору в грн., без ПДВ'
            }
        }
        if(
            isRLE(props.auctions.sellingMethod) || isRLD(props.auctions.sellingMethod) ||
            isCLE(props.auctions.sellingMethod) || isCLD(props.auctions.sellingMethod) ||
            isLRE(props.auctions.sellingMethod) || isLSP(props.auctions.sellingMethod) || isLSE(props.auctions.sellingMethod)
        ){
            tmpDataValue = 'Вартість договору'
        }
    }
    let dataContractValue = {
        targetType: 'SET_CONTRACT_VALUE_AMOUNT',
        target: props.timberContractPut.value.amount,
        functions: props.setContractAmount,
        label: tmpDataValue,
        reqiured: false,
        disabledElem: true
    }
    let tmpDogovorValue = <InputText 
                                value={dataContractValue.target}
                                label={dataContractValue.label}
                                onChange={(e)=> props.setDataAddress(e, dataContractValue.targetType)}
                                reqiured={dataContractValue.reqiured}
                                disabled={dataContractValue.disabledElem}
                            />
    //let tmpDogovorValue = null

    let tmpForm = null
    switch (true) {
        case(
            !isLSE(props.popUpContractApprowe.sellingMethod) &&
            !isLSP(props.popUpContractApprowe.sellingMethod)
        ):
            tmpForm = <div className={style.formLine}>
                <form
                    className={style.formData}
                    onSubmit={(e) => {
                        handleSubmitDataContract(e, props)
                    }}
                    id="addFileForm"
                >
                    {tmpContractTitle}
                    {tmpContractDescription}
                    <div className={style.baseWrap}>
                        <div className={style.baseWrapHalf}>
                            {tmpContractNumber}
                            {tmpDogovorValue}
                            {tmpDogovorSumm}
                        </div>
                        <div className={style.baseWrapHalf}>
                            {tmpContractTimeDateSigned}
                            {tmpContractTimeStartDate}
                            {tmpContractTimeEndDate}
                            {/*tmpContractTimeDateFrom*/}
                        </div>
                    </div>
                    {tmpButton}
                </form>
            </div>

            break
        case isLSE(props.popUpContractApprowe.sellingMethod) :
            tmpForm = <div className={style.formLine}>
                <form
                    className={style.formData}
                    onSubmit={(e) => {
                        handleSubmitDataContract(e, props)
                    }}
                    id="addFileForm"
                >
                    {tmpContractTitle}
                    {tmpContractDescription}
                    <div className={style.baseWrap}>
                        <div className={style.baseWrapHalf}>
                            {tmpDogovorValue}
                            {tmpContractNumber}
                            {tmpContractTimeDateSigned}
                        </div>
                        <div className={style.baseWrapHalf}>
                            {tmpDogovorSumm}
                            {tmpContractTimeStartDate}
                            {tmpContractTimeEndDate}
                            {/*tmpContractTimeDateFrom*/}
                        </div>
                    </div>
                    {tmpButton}
                </form>
            </div>
            break

        case isLSP(props.popUpContractApprowe.sellingMethod) :
            tmpForm = <div className={style.formLine}>
                <form
                    className={style.formData}
                    onSubmit={(e) => {
                        handleSubmitDataContract(e, props)
                    }}
                    id="addFileForm"
                >
                    {tmpContractTitle}
                    {tmpContractDescription}
                    <div className={style.baseWrap}>
                        <div className={style.baseWrapHalf}>
                            {tmpDogovorValue}
                            {tmpContractNumber}
                        </div>
                        <div className={style.baseWrapHalf}>
                            {tmpDogovorSumm}
                            {tmpContractTimeDateSigned}
                        </div>
                    </div>
                    {tmpButton}
                </form>
            </div>
            break

        default:
            tmpForm = <div className={style.formLine}>
                <form
                    className={style.formData}
                    onSubmit={(e) => {
                        handleSubmitDataContract(e, props)
                    }}
                    id="addFileForm"
                >
                    {tmpContractTitle}
                    {tmpContractDescription}
                    <div className={style.baseWrap}>
                        <div className={style.baseWrapHalf}>
                            {tmpDogovorValue}
                            {tmpContractTimeDateFrom}
                            {tmpContractNumber}
                        </div>
                        <div className={style.baseWrapHalf}>
                            {tmpDogovorSumm}
                            {tmpContractTimeDateSigned}
                        </div>
                    </div>
                    {tmpButton}
                </form>
            </div>
    }
    return tmpForm
}

function approweContract(props){
    if(props.popUpContractApprowe !== null){
        switch(props.popUpContractApprowe.sellingMethod){
            case "subsoil-english":
            case "subsoil-english-fast":
            case "subsoil-english-fast-manual":
            case "subsoil-english-initial-auction":
            case "subsoil-english-initial-qualification":
                return (
                    <div 
                        className={style.btnDone}
                        onClick={
                            () => {
                                signedContract(props)
                            }
                        }
                    >
                        Підтвердити договір
                    </div>
                )
        }
    }
}

function payDataBlock(props){
    if(props.popUpContractApprowe !== null){
        switch(props.popUpContractApprowe.sellingMethod){
            case "subsoil-english":
            case "subsoil-english-fast":
            case "subsoil-english-fast-manual":
            case "subsoil-english-initial-auction":
            case "subsoil-english-initial-qualification":
                return (
                    <>
                        <div className={style.groupElem}>
                            <TextField
                                className={style.inputFormContractData}
                                id="datetime-local"
                                label="Дата сплати до державного бюджету України"
                                type="datetime-local"
                                defaultValue={props.timberContractPut.datePaid}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                variant="outlined"
                                onChange={(event)=>{
                                    props.setContractDatePaid(event.target.value)
                                }}
                            />
                        </div>
                        <div
                            className={style.btnDone}
                            onClick={
                                () => {
                                    if(props.timberContractPut.datePaid !== ''){
                                        updateContractDatePaid(props)
                                    }
                                }
                            }
                        >
                            Кошти до бюджету сплачено
                        </div>
                    </>
                )
        }
    }
}

function endingContract(props){
    /*if(props.popUpContractApprowe !== null){
        switch(props.popUpContractApprowe.sellingMethod){
            case "subsoil-english":
            case "subsoil-english-fast":
            case "subsoil-english-fast-manual":
            case "subsoil-english-initial-auction":
            case "subsoil-english-initial-qualification":
                return (
                    <button
                        className={style.popup_message_button}
                        onClick={
                            () => {
                                postEndingContract(props)
                            }
                        }
                    >
                        Аукціон завершено. Очікується виконання умов договору
                    </button>
                )
        }
    }*/
    return null
}

async function updateContractDatePaid(props){
    await props.changeLoader(true)
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/auction/${props.popUpContractApprowe.aucId}/contract/${props.popUpContractApprowe.contractId}`
    const response = await fetch(endPoint, {
        method: "PUT",
        body: JSON.stringify({
            'datePaid': props.timberContractPut.datePaid
        }),
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }

    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){
            let tmp = JSON.stringify({title: 'Помилка оновлення данних контракту', message: JSON.stringify(json)})
            props.setUniversalError(tmp)
            await props.changeLoader(false)
        }else{
            //Добавить очистку полей
            props.setContractTitle('')
            props.setContractDescription('')
            props.setContractContractNumber('')
            props.setContractDateFrom('')
            props.setContractDateTill('')
            props.setContractStartDate('')
            props.setContractEndDate('')
            props.setContractCurrency(null)
            props.setContractAmount(null)
            props.setContractDateSigned('')
            props.changeContractApprowePopUpStatus(null)
            await postEndingContract(props)
            let data1 = {
                domen: process.env.REACT_APP_END_POINT_BD,
                version: 'v1',
                local: props.langFlag
            }
            let headers1 = {
                'Authorization': props.token.access_token,
                'Content-Type': 'application/json'
            }
            //await props.contractsAuctionIdGet(data1, headers1, props.popUpContractApprowe.aucId)
            await getUpdateDataAuction(props, props.auctions.id)
            await props.changeLoader(false)
        }
    }
}

async function signedContract(props){
    await props.changeLoader(true)
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${props.popUpContractApprowe.aucId}/contract/${props.popUpContractApprowe.contractId}/approve/set_status/signed`
    const response = await fetch(endPoint, {
        method: "POST",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }

    }).catch(console.error)
    if(response === undefined || response === 'undefined'){

    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){
            let tmp = JSON.stringify({title: 'Помилка підпису контракту', message: JSON.stringify(json)})
            props.setUniversalError(tmp)
            await props.changeLoader(false)
        }else{
            let data1 = {
                domen: process.env.REACT_APP_END_POINT_BD,
                version: 'v1',
                local: props.langFlag
            }
            let headers1 = {
                'Authorization': props.token.access_token,
                'Content-Type': 'application/json'
            }
            //await props.contractsAuctionIdGet(data1, headers1, props.popUpContractApprowe.aucId)
            await getUpdateDataAuction(props, props.auctions.id)
            await props.changeLoader(false)
        }
    }    
}

async function postEndingContract(props){
    await props.changeLoader(true)
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/auction/${props.popUpContractApprowe.aucId}/contract/${props.popUpContractApprowe.contractId}/approve/set_status/paid`
    const response = await fetch(endPoint, {
        method: "POST",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }

    }).catch(console.error)
    if(response === undefined || response === 'undefined'){

    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){
            let tmp = JSON.stringify({title: 'Помилка підпису контракту', message: JSON.stringify(json)})
            props.setUniversalError(tmp)
            await props.changeLoader(false)
        }else{
            let data1 = {
                domen: process.env.REACT_APP_END_POINT_BD,
                version: 'v1',
                local: props.langFlag
            }
            let headers1 = {
                'Authorization': props.token.access_token,
                'Content-Type': 'application/json'
            }
            //await props.contractsAuctionIdGet(data1, headers1, props.popUpContractApprowe.aucId)
            await getUpdateDataAuction(props, props.auctions.id)
            await props.changeLoader(false)
        }
    }    
}

function handleSubmitDataContract(e, props){
    e.preventDefault();
    updateContractData(props)
}

async function updateContractData(props){
    await props.changeLoader(true)
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/private/auction/${props.popUpContractApprowe.aucId}/contract/${props.popUpContractApprowe.contractId}`
    let body = {}
    body.title = props.timberContractPut.title
    body.description = props.timberContractPut.description
    body.contractNumber = props.timberContractPut.contractNumber
    if(
        !isTIE(props.popUpContractApprowe.sellingMethod) &&
        !isLRE(props.popUpContractApprowe.sellingMethod) &&
        !isLSE(props.popUpContractApprowe.sellingMethod) && !isLSP(props.popUpContractApprowe.sellingMethod) &&
        !isLLE(props.popUpContractApprowe.sellingMethod) && !isLLD(props.popUpContractApprowe.sellingMethod) && !isLLP(props.popUpContractApprowe.sellingMethod) && 
        !isBSE(props.popUpContractApprowe.sellingMethod) && !isBSD(props.popUpContractApprowe.sellingMethod) &&
        !isALE(props.popUpContractApprowe.sellingMethod) &&
        !isCSE(props.popUpContractApprowe.sellingMethod) && !isCSD(props.popUpContractApprowe.sellingMethod) &&
        !isBRE(props.popUpContractApprowe.sellingMethod) && !isBRD(props.popUpContractApprowe.sellingMethod) && !isBRW(props.popUpContractApprowe.sellingMethod) &&
        !isRLE(props.popUpContractApprowe.sellingMethod) && !isRLD(props.popUpContractApprowe.sellingMethod) &&
        !isCLE(props.popUpContractApprowe.sellingMethod) && !isCLD(props.popUpContractApprowe.sellingMethod)
    ){
        body.x_prepaymentDetailsDate = props.timberContractPut.contractTime.dateFrom
        let stramount = ''
        if(props.timberContractPut.xValueUAH.amount.indexOf(',') !== -1){
            stramount = props.timberContractPut.xValueUAH.amount.replace(',', '.')
        }else{
            stramount = props.timberContractPut.xValueUAH.amount
        }
        body.x_prepaymentDetailsValue = {
            'currency': 'UAH',
            'amount': Number(props.timberContractPut.xValueUAH.stramount)
        }
    }else{
        /*body.value = {
            'currency': 'UAH',
            'amount': props.timberContractPut.xValueUAH.amount
        }*/
        if(
            isTIE(props.popUpContractApprowe.sellingMethod) || //Правка для запуска леса если ок убрать 03022022
            isBSD(props.popUpContractApprowe.sellingMethod) ||
            isALE(props.popUpContractApprowe.sellingMethod) ||
            isCSE(props.popUpContractApprowe.sellingMethod) || isCSD(props.popUpContractApprowe.sellingMethod) ||
            isBRE(props.popUpContractApprowe.sellingMethod) || isBRD(props.popUpContractApprowe.sellingMethod) || isBRW(props.popUpContractApprowe.sellingMethod) ||
            isRLE(props.popUpContractApprowe.sellingMethod) || isRLD(props.popUpContractApprowe.sellingMethod) ||
            isCLE(props.popUpContractApprowe.sellingMethod) || isCLD(props.popUpContractApprowe.sellingMethod)
        ){
            /*body.value = {
                'currency': 'UAH',
                'amount': Number(props.timberContractPut.value.amount),
                //'valueAddedTaxIncluded': true
            }*/
            let stramount = ''
            if(
                props.timberContractPut.xValueUAH !== null &&
                props.timberContractPut.xValueUAH.hasOwnProperty('amount') &&
                props.timberContractPut.xValueUAH.amount !== null
            ){
                if(String(props.timberContractPut.xValueUAH.amount).indexOf(',') !== -1){
                    stramount = String(props.timberContractPut.xValueUAH.amount).replace(',', '.')
                }else{
                    stramount = props.timberContractPut.xValueUAH.amount
                }
            }else{
                stramount = props.timberContractPut.xValueUAH.amount
            }
            body.contractTotalValue = {
                'currency': 'UAH',
                'amount': Number(stramount),
                /*'valueAddedTaxIncluded': true*/
            }
        }else{
            let stramount = ''
            if(String(props.timberContractPut.contractTotalValue.amount).indexOf(',') !== -1){
                stramount = String(props.timberContractPut.contractTotalValue.amount).replace(',', '.')
            }else{
                stramount = props.timberContractPut.contractTotalValue.amount
            }
            body.contractTotalValue = {
                'currency': 'UAH',
                'amount': Number(stramount)
            }
        }
    }
    if(
        props.popUpContractApprowe.sellingMethod !== 'landSell-english' &&
        props.popUpContractApprowe.sellingMethod !== 'landSell-english-fast' &&
        props.popUpContractApprowe.sellingMethod !== 'landSell-english-fast-manual' &&
        props.popUpContractApprowe.sellingMethod !== 'landSell-english-fast-auction-manual-qualification' &&
        props.popUpContractApprowe.sellingMethod !== 'landSell-english-initial-auction' &&
        props.popUpContractApprowe.sellingMethod !== 'landSell-english-initial-auction-manual' &&
        props.popUpContractApprowe.sellingMethod !== 'landSell-english-initial-qualification' &&
        props.popUpContractApprowe.sellingMethod !== 'landSell-english-initial-qualification-prod' &&
        props.popUpContractApprowe.sellingMethod !== 'landSell-priorityEnglish' &&
        props.popUpContractApprowe.sellingMethod !== 'landSell-priorityEnglish-fast' &&
        props.popUpContractApprowe.sellingMethod !== 'landSell-priorityEnglish-fast-manual' &&
        props.popUpContractApprowe.sellingMethod !== 'landSell-priorityEnglish-fast-auction-manual-qualification' &&
        props.popUpContractApprowe.sellingMethod !== 'landSell-priorityEnglish-initial-auction' &&
        props.popUpContractApprowe.sellingMethod !== 'landSell-priorityEnglish-initial-auction-manual' &&
        props.popUpContractApprowe.sellingMethod !== 'landSell-priorityEnglish-initial-qualification' &&
        props.popUpContractApprowe.sellingMethod !== 'landSell-priorityEnglish-initial-qualification-prod'
    ){
        if(
            props.timberContractPut.contractTime.dateFrom !== '' ||
            props.timberContractPut.contractTime.dateFrom !== null ||
            props.timberContractPut.contractTime.dateTill !== '' ||
            props.timberContractPut.contractTime.dateTill !== null
        ){
            if(props.timberContractPut.contractTime.dateFrom !== '' && props.timberContractPut.contractTime.dateFrom !== null){
                body.contractTime = {}
                body.contractTime.dateFrom = props.timberContractPut.contractTime.dateFrom
            }
            if(props.timberContractPut.contractTime.dateTill !== '' && props.timberContractPut.contractTime.dateTill !== null){
                if(body.hasOwnProperty('contractTime' !== true)) body.contractTime = {}
                body.contractTime.dateTill = props.timberContractPut.contractTime.dateTill
            }
        }
    }
    body.dateSigned =  props.timberContractPut.dateSigned
    const response = await fetch(endPoint, {
        method: "PATCH",
        body: JSON.stringify(body),
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }

    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json = await response.json()
        if(json.hasOwnProperty('message') === true){
            let tmp = JSON.stringify({title: 'Помилка оновлення данних контракту', message: JSON.stringify(json)})
            if(json.message.hasOwnProperty('x_prepaymentDetails') === true){
                if(json.message.x_prepaymentDetails.hasOwnProperty('date') === true){
                    if(json.message.x_prepaymentDetails.date === 'Value should be greater than auctionPeriod.endDate and less than now'){
                        tmp = JSON.stringify({title: 'Помилка оновлення данних контракту', message: 'Дата контракту не правильно виставлена'})
                    }
                }
            }
            props.setUniversalError(tmp)
            await props.changeLoader(false)
        }else{
            //Добавить очистку полей//
            props.setContractTitle('')
            props.setContractDescription('')
            props.setContractContractNumber('')
            props.setContractDateFrom('')
            props.setContractDateTill('')
            props.setContractStartDate('')
            props.setContractEndDate('')
            props.setContractCurrency(null)
            props.setContractAmount(null)
            props.setContractDateSigned('')
            props.changeContractApprowePopUpStatus(null)

            await getUpdateDataAuction(props, props.auctions.id)
            //await props.contractsAuctionIdGet(data1, headers1, props.popUpContractApprowe.aucId)
            await props.changeLoader(false)
        }
    }
}

const mapStateToProps = state => {
    return {
        token: state.start.token,
        //auctions: state.start.auctionsGet,
        auctions: state.start.auctionFull,
        langFlag: state.start.langFlag,
        createAuctionTab: state.start.createAuctionTab,
        createAuction: state.start.createAuction,
        createItems: state.start.createItems,
        auctionsMy: state.start.auctionsMy,
        auctionsItemsSwitchWindow: state.start.auctionsItemsSwitchWindow,
        classificationsGets: state.start.classificationsGet,
        unitsGet: state.start.unitsGet,
        userProfiles: state.start.userProfiles,
        classificationsTimber: state.start.classificationsTimber,
        classificationsEnergy: state.start.classificationsEnergy,
        auctionsList: state.start.auctionsGetMy,
        auctionAwards: state.start.auctionAwards,
        popUpAwardReject: state.start.popUpAwardReject,
        awardReject: state.start.awardReject,
        auction: state.start.auctionsGetId,
        contractAuctionIdGet: state.start.contractAuctionIdGet,
        popUpContractApprowe: state.start.popUpContractApprowe,
        popUpContractUniversal: state.start.popUpContractUniversal,
        timberContractPut: state.start.timberContractPut,

        togetherApiPage: state.start.togetherApiPage,
        ecpData: state.start.ecpData,

        createDocuments: state.start.createDocuments,
    }
}

const mapDispatchToProps = {
    auctionsGetAwards,
    auctionsGetAwardsId,
    changeAwardRejectPopUpStatus,
    changeAwardErrorPopUpStatus,
    changeAwardRejectTitle,
    changeAwardRejectDescription,
    changeAwardRejectDocumentType,

    setUniversalError,
    changeContractApprowePopUpStatus,

    setContractDocTitle,
    setContractDocType,
    setContractDocDescription,

    changeContractErrorPopUpStatus,

    setContractTitle,
    setContractDescription,
    setContractContractNumber,
    setContractDateFrom,
    setContractDateTill,
    setContractStartDate,
    setContractEndDate,
    setContractCurrency,
    setContractAmount,
    setContractDateSigned,
    
    contractsAuctionIdGet,

    togetherApiPopUpOnOff,
    togetherApiDocTitle,
    togetherApiDocDescription,
    togetherApiDocType,
    togetherApiDocLang,
    togetherApiDocFileName,
    togetherApiECP,

    ecpApiPass,
    ecpApiList,
    changeCA,

    changeDocumentId,
    changeDocumentTitle,
    changeDocumentDescription,
    changeDocumentLang,
    changeDocumentFileName,
    changeDocumentType,

    changeLoader,

    setContractDatePaid,
    setDataAddress,
}

export default connect(mapStateToProps, mapDispatchToProps)(ContractOther)
