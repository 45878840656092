import React from 'react';
import styles from './timber.module.css';

import {connect} from 'react-redux';
import {
    changeCreateItemAuctionId,
    changeCreateItemClassificationId,
    changeCreateItemUnitValueCurrency,
    changeCreateItemUnitValueAmount,
    changeCreateItemUnitValueTax,
    changeCreateItemDescription,
    changeAdditionalClassificationIds,
    changeCreateItemQuantity,
    changeCreateItemIds,
    changeCreateAuctionTab,
    changeCreateItemAddress,
    changeLoader,
    getMyAuction,
} from '../../../../redux/actions.js';

import {auctionsGetMy,
    changeAuctionsItemsSwitchWindow,
    changeAuctionsSwitchWindow,
    auctionsGetClassificationsByType,
    auctionsGetClassificationsByTimberSortment,
    auctionsGetClassificationsByTimberSpecies,
    auctionsGetClassificationsByTimberClass,
    auctionsGetClassificationsByTimberDiameter,
    auctionsGetClassificationsByTimberLength,
    auctionsGetClassificationsByTimberStorage,
    auctionsGetClassificationsByTimberProductionYear,
    auctionsGetClassificationsByTimberProductionQuarter,
    changeTimberSortment,
    changeTimberSpecies,
    changeTimberClass,
    changeTimberDiameter,
    changeTimberLength,
    changeTimberStorage,
    changeTimberProductionYear,
    changeTimberProductionQuarter,
    changeCreateItemUnitCode,
    setPopUpAuctionMyNull,
} from '../../../../redux/actions/auctions/auctions.js';

import {setRecExpTimberDiametr} from '../../../../redux/actions/auctionItems/auctionItems.js';

import {
    streetAddress,
    localityAddress,
    regionAddress,
    postalCodeAddress,
    countryNameAddress,
    popUpAddress,
    popUpAddressId,
    setPopAddressError,
    setPopAddressComplite,
    setKoatuuRegion,
    setKoatuuGorod,
} from '../../../../redux/actions/address/address.js';

import 'date-fns';
/*import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';*/

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';

import ReturnButton from '../../returnButton'

import ZuMap from '../../../map/zuMaps'
import {setDataAddress} from '../../../../redux/createAddress';
import {disabledElement} from '../../disabled'

//x-legalNameUa: Код класифікатора
//x-legalNameEn: Classification ID

const WindowItems = ( props ) => {
    let tmpText = null;
    if(props.createItems.id === null){
        tmpText = null;
    }else{
        tmpText = null;
    }
    let tmpItems = null;
    if(props.auctionsMy !== null){
            if(props.auctionsMy.items.length > 0){
                tmpItems = props.auctionsMy.items.map(
                    (i, count) => {
                        return (
                            <div key={i.id} className={styles.auctionsLine}>
                                <div className={styles.auctionsLineElem}>
                                    <div className={styles.itemsId}>ID лота: {i.id}</div>
                                    <div>Опис лота: {i.description}</div>
                                </div>
                                <div className={styles.auctionsLineElem}>
                                    {/*<div
                                        onClick={
                                            () => {
                                                props.changeAuctionsItemsSwitchWindow(2);
                                                
                                                props.changeCreateItemAuctionId(props.auctionsMy.id)
                                                props.changeCreateItemIds(i.id)
                                                props.changeCreateItemClassificationId(i.classification.id)
                                                let tmpTimberLength = null;
                                                if(i.additionalClassifications !== null){
                                                    for(let tt = 0; tt < i.additionalClassifications.length; tt++){
                                                        if(i.additionalClassifications[tt].scheme === 'timberLength'){
                                                            tmpTimberLength = i.additionalClassifications[tt].classificationId
                                                        }
                                                    }
                                                }
                                                tmpTimberLength = tmpTimberLength.split(' - ')
                                                props.changeTimberLength(tmpTimberLength[0], 1)
                                                props.changeTimberLength(tmpTimberLength[1], 2)



                                                props.changeCreateItemDescription(i.description)
                                                props.changeCreateItemAuctionId(i.unitCode)
                                                props.changeCreateItemQuantity(i.quantity)

                                                props.changeCreateItemUnitCode(i.unit.code)
                                                props.changeCreateItemAddress(i.address.id)

                                                let data = {
                                                    domen: process.env.REACT_APP_END_POINT_BD,
                                                    version: 'v1',
                                                    local: props.langFlag,
                                                    params: '?limit=20'
                                                }
                                                let header = {
                                                    'Authorization': props.token.access_token,
                                                    'Content-Type': 'application/json'
                                                }
                                                classificationTimberSortmentStartChange(props, count)
                                                classificationTimberSpeciesChange(props, count)
                                                classificationTimberClassChange(props, count)
                                                classificationTimberDiameterChange(props, count)
                                                classificationTimberLengthChange(props, count)
                                                classificationTimberStorageChange(props, count)
                                                classificationTimberProductionYearChange(props, count)
                                                classificationTimberProductionQuarterChange(props, count)
                                            }
                                        }
                                    >
                                        <IconButton aria-label="edit">
                                            <EditIcon />
                                        </IconButton>
                                    </div>*/}
                                    {buttonEditItem(props, i, count)}
                                    {buttonDeleteItem(props, i)}
                                    {/*<div
                                        disabled={elementDisabledCreate(props)}
                                        onClick={
                                            () => {
                                                props.changeLoader(true)
                                                deleteItem(props, props.auctionsMy, i.id)
                                                //props.changeAuctionsSwitchWindow(0)
                                            }
                                        }
                                    >
                                        <IconButton aria-label="delete">
                                            <DeleteIcon />
                                        </IconButton>
                                    </div>*/}
                                </div>
                            </div>
                        )
                    }
                )
            }
    }

    let tmpZuMap = null
    tmpZuMap = <div className={styles.groupElem + ' ' + styles.groupElemCategory + ' ' + styles.groupElemCategoryTwo}>
        <div>
            <ZuMap
                latitude = {props.createItems.landRental.location.latitude}
                longitude = {props.createItems.landRental.location.longitude}
            />
        </div>
    </div>
    let formItem = null;
    let tmpLengthError = null
    let regExpTimberDiamError = null;
    if(props.regExpTimberDiametr !== null){
        regExpTimberDiamError = <span>OK</span>
    }else{
        regExpTimberDiamError = <span>Помилка заповненя</span>
    }
    if(props.auctionsItemsSwitchWindow > 0){
        formItem = (
            <>
                <div className={styles.group}>
                    <div className={styles.groupElem + ' ' + styles.groupElemCategory}>
                        <div>
                            <TextField
                                disabled={elementDisabledCreate(props)}
                                id="outlined-select-currency-native"
                                select
                                label="Код класифікатора"
                                value={props.createItems.classificationId}
                                onChange={(event)=>{
                                    props.changeCreateItemClassificationId(event.target.value)
                                }}
                                SelectProps={{
                                    native: true,
                                }}
                                helperText="Оберіть код класифікатора"
                                variant="outlined"
                            >
                                <option key='null' selected disabled value="null">Обрати</option>
                                {classificationIdOption(props)}
                            </TextField>
                        </div>
                    </div>
                    <div className={styles.groupElem + ' ' + styles.groupElemCategory}>
                        <div>
                            <TextField
                                disabled={elementDisabledCreate(props)}
                                id="outlined-select-currency-native"
                                select
                                label="Порода"
                                value={props.createItems.additionalClassificationIds.timberSpecies}
                                onChange={(event)=>{
                                    props.changeTimberSpecies(event.target.value)
                                }}
                                SelectProps={{
                                    native: true,
                                }}
                                helperText="Оберіть породу"
                                variant="outlined"
                            >
                                <option key='null' selected disabled value="null">Обрати</option>
                                {classificationTimberSpeciesOption(props)}
                            </TextField>
                        </div>
                    </div>
                    <div className={styles.groupElem + ' ' + styles.groupElemCategory}>
                        <div>
                            <TextField
                                disabled={elementDisabledCreate(props)}
                                id="outlined-select-currency-native"
                                select
                                label="Сортимент"
                                value={props.createItems.additionalClassificationIds.timberSortment}
                                onChange={(event)=>{
                                    props.changeTimberClass('null')
                                    props.changeTimberSortment(event.target.value)
                                }}
                                SelectProps={{
                                    native: true,
                                }}
                                helperText="Оберіть сортимент"
                                variant="outlined"
                            >
                                <option key='null' selected disabled value="null">Обрати</option>
                                {classificationTimberSortmentOption(props)}
                            </TextField>
                        </div>
                    </div>
                    {viewTimberClass(props)}
                    <div className={styles.groupElem + ' ' + styles.groupElemCategory}>
                        <div>
                            {timberDiametr(props)}
                        </div>
                    </div>
                    <div className={styles.groupElem + ' ' + styles.groupElemCategory}>
                        <div>
                            <TextField
                                disabled={elementDisabledCreate(props)}
                                label="Довжина від. Приклад (1,2)"
                                value={props.createItems.additionalClassificationIds.timberLength1}
                                onChange={(event) => {
                                    props.changeTimberLength(event.target.value, 1)
                                }}
                                id="formatted-numberformat-input"
                                variant="outlined"
                                helperText=''
                            />
                        </div>
                        <div>
                            <TextField
                                disabled={elementDisabledCreate(props)}
                                label="Довжина до. Приклад (2,3)"
                                value={props.createItems.additionalClassificationIds.timberLength2}
                                onChange={(event) => {
                                    props.changeTimberLength(event.target.value, 2)
                                }}
                                id="formatted-numberformat-input"
                                variant="outlined"
                                helperText=''
                            />
                        </div>
                    </div>
                    <div className={styles.groupElem + ' ' + styles.groupElemCategory}>
                        {lengthMinMax(props)}
                    </div>
                    <div className={styles.groupElem + ' ' + styles.groupElemCategory}>
                        <div>
                            <TextField
                                disabled={elementDisabledCreate(props)}
                                id="outlined-select-currency-native"
                                select
                                label="Склад"
                                value={props.createItems.additionalClassificationIds.timberStorage}
                                onChange={(event)=>{
                                    props.changeTimberStorage(event.target.value)
                                }}
                                SelectProps={{
                                    native: true,
                                }}
                                helperText="Оберіть склад"
                                variant="outlined"
                            >
                                <option key='null' selected disabled value="null">Обрати</option>
                                {classificationTimberStorageOption(props)}
                            </TextField>
                        </div>
                    </div>
                    <div className={styles.groupElem + ' ' + styles.groupElemCategory}>
                        <div>
                            <TextField
                                disabled={elementDisabledCreate(props)}
                                id="outlined-select-currency-native"
                                select
                                label="Рік заготівлі"
                                value={props.createItems.additionalClassificationIds.timberProductionYear}
                                onChange={(event)=>{
                                    props.changeTimberProductionYear(event.target.value)
                                }}
                                SelectProps={{
                                    native: true,
                                }}
                                helperText="Оберіть рік заготівлі"
                                variant="outlined"
                            >
                                <option key='null' selected disabled value="null">Обрати</option>
                                {classificationTimberProductionYearOption(props)}
                            </TextField>
                        </div>
                    </div>
                    <div className={styles.groupElem + ' ' + styles.groupElemCategory}>
                        <div>
                            <TextField
                                disabled={elementDisabledCreate(props)}
                                id="outlined-select-currency-native"
                                select
                                label="Квартал заготівлі"
                                value={props.createItems.additionalClassificationIds.timberProductionQuarter}
                                onChange={(event)=>{
                                    props.changeTimberProductionQuarter(event.target.value)
                                }}
                                SelectProps={{
                                    native: true,
                                }}
                                helperText="Оберіть квартал заготівлі"
                                variant="outlined"
                            >
                                <option key='null' selected disabled value="null">Обрати</option>
                                {classificationTimberProductionQuarterOption(props)}
                            </TextField>
                        </div>
                    </div>
                    
                    {/*<div className={styles.groupElem + ' ' + styles.groupElemCategory}>
                        <div>
                            <TextField
                                disabled={elementDisabledCreate(props)}
                                id="outlined-select-currency-native"
                                select
                                label="Місцезнаходження"
                                value={props.createItems.addressesGetMy}
                                onChange={(event)=>{
                                    props.changeCreateItemAddress(event.target.value)
                                }}
                                SelectProps={{
                                    native: true,
                                }}
                                helperText="Оберіть адресу"
                                variant="outlined"
                            >
                                <option key='null' selected disabled value="null">Обрати</option>
                                {addressArr(props)}
                            </TextField>
                        </div>
                    </div>
                    <div className={styles.groupElem + ' ' + styles.groupElemCategory}>
                        <div>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={
                                    () => {
                                        props.popUpAddress(true)
                                    }
                                }
                            >
                                Створити адресу
                            </Button>
                        </div>
                        {editAdress(props)}
                    </div>*/}
                    {tmpZuMap}
                    <div className={styles.lineButtons}>
                        <div className={styles.editButton}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={
                                    ()=>{
                                        setNewAddress(props)
                                    }
                                }
                            >
                                Додати обрані координати
                            </Button>
                        </div>
                    </div>
                    <div>Адреса</div>
                    <div className={styles.groupElem}>
                        <div>
                            <TextField
                                disabled
                                error = {redBlock(props, props.createItems.landRental.addressItem.countryName)}
                                required
                                className={styles.textAreaWidth}
                                id="outlined-multiline-static"
                                label="Країна"
                                variant="outlined"
                                value={props.createItems.landRental.addressItem.countryName}
                                onChange={(event)=>{
                                    props.setDataAddress(event.target.value, 'CREATE_ITEMS_LAND_RENTAL_ADDRESS_COUNTRY_NAME')
                                }}
                            />
                        </div>
                    </div>
                    <div className={styles.groupElem}>
                        <div>
                            <TextField
                                disabled
                                error = {redBlock(props.createItems.landRental.addressItem.region)}
                                required
                                className={styles.textAreaWidth}
                                id="outlined-multiline-static"
                                label="Область"
                                variant="outlined"
                                value={props.createItems.landRental.addressItem.region}
                                onChange={(event)=>{
                                    props.setDataAddress(event.target.value, 'CREATE_ITEMS_LAND_RENTAL_ADDRESS_REGION')
                                }}
                            />
                        </div>
                    </div>
                    <div className={styles.groupElem}>
                        <div>
                            <TextField
                                error = {redBlock(props, props.createItems.landRental.addressItem.locality)}
                                required
                                disabled={elementDisabledCreate(props)}
                                className={styles.textAreaWidth}
                                id="outlined-multiline-static"
                                label="Населенний пункт"
                                variant="outlined"
                                value={props.createItems.landRental.addressItem.locality}
                                onChange={(event)=>{
                                    props.setDataAddress(event.target.value, 'CREATE_ITEMS_LAND_RENTAL_ADDRESS_LOCALITY')
                                }}
                            />
                        </div>
                    </div>
                    <div className={styles.groupElem}>
                        <div>
                            <TextField
                                error = {redBlock(props, props.createItems.landRental.addressItem.streetAddress)}
                                required
                                disabled={elementDisabledCreate(props)}
                                className={styles.textAreaWidth}
                                id="outlined-multiline-static"
                                label="Вулиця, буд. №"
                                variant="outlined"
                                value={props.createItems.landRental.addressItem.streetAddress}
                                onChange={(event)=>{
                                    props.setDataAddress(event.target.value, 'CREATE_ITEMS_LAND_RENTAL_ADDRESS_STREET_ADDRESS')
                                }}
                            />
                        </div>
                    </div>
                    <div className={styles.groupElem}>
                        <div>
                            <TextField
                                error = {redBlock(props, props.createItems.landRental.addressItem.postalCode)}
                                required
                                disabled={elementDisabledCreate(props)}
                                className={styles.textAreaWidth}
                                id="outlined-multiline-static"
                                label="Поштовий індекс"
                                variant="outlined"
                                value={props.createItems.landRental.addressItem.postalCode}
                                onChange={(event)=>{
                                    props.setDataAddress(event.target.value, 'CREATE_ITEMS_LAND_RENTAL_ADDRESS_POSTAL_CODE')
                                }}
                            />
                        </div>
                    </div>


                    {/*<div>Координати</div>
                    <div className={styles.groupElem + ' ' + styles.groupElemCategory}>
                        <div>
                            <TextField
                                error = {redBlock(props, props.createItems.landRental.location.latitude)}
                                disabled
                                id="outlined-select-currency-native"
                                label="Широта"
                                value={props.createItems.landRental.location.latitude}
                                onChange={(event) => {
                                    props.setDataAddress(event.target.value, 'CREATE_ITEMS_LAND_RENTAL_LOCATION_LATITUDE')
                                }}
                                name="numberformat"
                                id="formatted-numberformat-input"
                                variant="outlined"
                                helperText=" "
                            />
                        </div>
                        <div>
                            <TextField
                                error = {redBlock(props, props.createItems.landRental.location.longitude)}
                                disabled
                                id="outlined-select-currency-native"
                                label="Довгота"
                                value={props.createItems.landRental.location.longitude}
                                onChange={(event) => {
                                    props.setDataAddress(event.target.value, 'CREATE_ITEMS_LAND_RENTAL_LOCATION_LONGITUDE')
                                }}
                                name="numberformat"
                                id="formatted-numberformat-input"
                                variant="outlined"
                                helperText=" "
                            />
                        </div>
                    </div>*/}




                    <div className={styles.groupElem}>
                        <div>
                            <TextField
                                disabled={elementDisabledCreate(props)}
                                className={styles.textAreaWidth}
                                id="outlined-multiline-static"
                                label="Опис об'єкта"
                                multiline
                                rows={10}
                                value={props.createItems.description}
                                variant="outlined"
                                onChange={(event)=>{
                                    props.changeCreateItemDescription(event.target.value)
                                }}
                            />
                        </div>
                    </div>
                    <div className={styles.groupElem + ' ' + styles.groupElemCategory}>
                        <div>
                            <TextField
                                disabled={elementDisabledCreate(props)}
                                id="outlined-select-currency-native"
                                select
                                label="Одиниці виміру"
                                value={props.createItems.unitCode}
                                onChange={(event)=>{
                                    props.changeCreateItemUnitCode(event.target.value)
                                }}
                                SelectProps={{
                                    native: true,
                                }}
                                helperText="Оберіть ед. виміру"
                                variant="outlined"
                            >
                                <option key='null' selected disabled value="null">Обрати</option>
                                {classificationTimberUnitCode(props)}
                            </TextField>
                        </div>
                    </div>
                    <div className={styles.groupElem}>
                        <div>
                            <TextField
                                disabled={elementDisabledCreate(props)}
                                label="Кількість"
                                value={props.createItems.quantity}
                                onChange={(event) => {
                                    props.changeCreateItemQuantity(event.target.value)
                                }}
                                name="numberformat"
                                id="formatted-numberformat-input"
                                variant="outlined"
                                helperText="Розмір партії деревини"
                            />
                        </div>
                    </div>
                </div>
            </>
        )
    }
    let formButton = null;
    if(props.auctionsItemsSwitchWindow === 1){
        formButton = (
            <div className={styles.lineButtons}>
                <div className={styles.editButton}>
                    <Button
                        disabled={elementDisabled(props)}
                        variant="contained"
                        color="primary"
                        onClick={
                            () => {
                                props.changeLoader(true)
                                createItem(props)
                            }
                        }
                    >
                        Зберегти зміни
                    </Button>
                </div>
                <div>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={
                            () => {
                                props.changeAuctionsItemsSwitchWindow(0);
                                props.changeCreateItemClassificationId('null')
                                props.changeTimberSortment('null')
                                props.changeTimberSpecies('null')
                                props.changeTimberClass('null')
                                props.changeTimberDiameter('null')
                                props.changeTimberLength('', 1)
                                props.changeTimberLength('', 2)
                                props.setRecExpTimberDiametr('null')
                                props.changeTimberStorage('null')
                                props.changeTimberProductionYear('null')
                                props.changeTimberProductionQuarter('null')
                                props.changeCreateItemUnitCode('null')
                                props.changeCreateItemDescription('')
                                //props.changeCreateItemUnitValueCurrency('null')
                                //props.changeCreateItemUnitValueAmount('')
                                props.changeCreateItemQuantity('')
                            }
                        }
                    >
                        Закрити
                    </Button>
                </div>
            </div>
        )
    }else if(props.auctionsItemsSwitchWindow === 2){
        formButton = (
            <div className={styles.lineButtons}>
                <div className={styles.editButton}>
                    <Button
                        disabled={elementDisabled(props)}
                        variant="contained"
                        color="primary"
                        onClick={
                            () => {
                                props.changeLoader(true)
                                saveItem(props)
                            }
                        }
                    >
                        Зберегти зміни
                    </Button>
                </div>
                <div>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={
                            () => {
                                props.changeAuctionsItemsSwitchWindow(0);
                                props.changeCreateItemClassificationId('null')
                                props.changeTimberSortment('null')
                                props.changeTimberSpecies('null')
                                props.changeTimberClass('null')
                                props.changeTimberDiameter('null')
                                props.changeTimberLength('', 1)
                                props.changeTimberLength('', 2)
                                props.setRecExpTimberDiametr('null')
                                props.changeTimberStorage('null')
                                props.changeTimberProductionYear('null')
                                props.changeTimberProductionQuarter('null')
                                props.changeCreateItemUnitCode('null')
                                props.changeCreateItemDescription('')
                                //props.changeCreateItemUnitValueCurrency('null')
                                //props.changeCreateItemUnitValueAmount('')
                                props.changeCreateItemQuantity('')
                            }
                        }
                    >
                        Закрити
                    </Button>
                </div>
            </div>
        )
    }

    let tmpButtons = null;
    if(props.auctionsMy !== null){
        if(props.auctionsMy.items.length === 0){
            tmpButtons = <div className={styles.headers}>
                <Button
                    disabled={elementDisabledCreate(props)}
                    variant="contained"
                    color="primary"
                    onClick={
                        () => {
                            props.changeAuctionsItemsSwitchWindow(1);
                            props.changeCreateItemClassificationId('null')
                            props.changeTimberSortment('null')
                            props.changeTimberSpecies('null')
                            props.changeTimberClass('null')
                            props.changeTimberDiameter('null')
                            props.changeTimberLength('', 1)
                            props.changeTimberLength('', 2)
                            props.setRecExpTimberDiametr('null')
                            props.changeTimberStorage('null')
                            props.changeTimberProductionYear('null')
                            props.changeTimberProductionQuarter('null')
                            props.changeCreateItemUnitCode('null')
                            props.changeCreateItemDescription('')
                            //props.changeCreateItemUnitValueCurrency('null')
                            //props.changeCreateItemUnitValueAmount('')
                            props.changeCreateItemQuantity('')
                        }
                    }
                >
                    Створити новий лот
                </Button>
            </div>
        }
    }

    /*if(
        props.classificationsTimber.classificationsByTypeMain !== null &&
        props.classificationsTimber.timberSortment !== null &&
        props.classificationsTimber.timberSpecies !== null &&
        props.classificationsTimber.timberClass !== null &&
        props.classificationsTimber.timberDiameter !== null &&
        props.classificationsTimber.timberStorage !== null &&
        props.classificationsTimber.timberProductionYear !== null &&
        props.classificationsTimber.timberProductionQuarter !== null &&
        props.classificationsTimber.unitCode !== null
    ){
        if(
            props.classificationsTimber.classificationsByTypeMain.length > 0 &&
            props.classificationsTimber.timberSortment.length > 0 &&
            props.classificationsTimber.timberSpecies.length > 0 &&
            props.classificationsTimber.timberClass.length > 0 &&
            props.classificationsTimber.timberDiameter.length > 0 &&
            props.classificationsTimber.timberStorage.length > 0 &&
            props.classificationsTimber.timberProductionYear.length > 0 &&
            props.classificationsTimber.timberProductionQuarter.length > 0 &&
            props.classificationsTimber.unitCode.length > 0
        ){
            if(props.loader !== false){
                props.changeLoader(false)
            }
        }else{
            if(props.loader !== true){
                props.changeLoader(true)
            }
        }
    }else{
        if(props.loader !== true){
            props.changeLoader(true)
        }
    }*/
    return (
        <div>
            <div className={styles.flexBlock}>
                <div className={styles.flexBlockElem}>
                    {tmpButtons}
                    {tmpItems}
                </div>
                <div className={styles.flexBlockElem}>
                    {formItem}
                    {formButton}
                </div>
            </div>
            <div className={styles.bottonBlock}>
                <ReturnButton />
            </div>
        </div>
    )
}


function lengthMinMax(props){
    let tmp1 = props.createItems.additionalClassificationIds.timberLength1.replace(',', '.')
    let tmp2 = props.createItems.additionalClassificationIds.timberLength2.replace(',', '.')
    let tmp = null
    if(Number(tmp1) > Number(tmp2)){
        tmp = "Мінімальне значення не повинно бути більше максимального"
    }else{
        tmp = null
    }
    return tmp
}

function editAdress(props){
    let tmp = null
    if(props.createItems.addressesGetMy !== 'null'){
        tmp = <div>
            <Button
                variant="contained"
                color="primary"
                onClick={
                    () => {
                        let tmp=null;
                        if(props.addressGetId.length > 0){
                            tmp = props.addressGetId.filter(
                                (e) => {
                                    return e.id === Number(props.createItems.addressesGetMy)
                                }
                            )
                            .map(
                                (e) => {
                                    let t = null;
                                    switch(e.region){
                                        //case "Автономна Республіка Крим":
                                        case "АВТОНОМНА РЕСПУБЛІКА КРИМ/М.СІМФЕРОПОЛЬ":
                                            props.regionAddress("0100000000+:+АВТОНОМНА РЕСПУБЛІКА КРИМ/М.СІМФЕРОПОЛЬ")
                                            t = "0100000000+:+АВТОНОМНА РЕСПУБЛІКА КРИМ/М.СІМФЕРОПОЛЬ".substr(0, 2)
                                            props.setKoatuuGorod(null)
                                            props.localityAddress('null')
                                            getKoatuuGorod(props, t)
                                            break;
                                        //case "Вінницька область":
                                        case "ВІННИЦЬКА ОБЛАСТЬ/М.ВІННИЦЯ":
                                            props.regionAddress("0500000000+:+ВІННИЦЬКА ОБЛАСТЬ/М.ВІННИЦЯ")
                                            t = "0500000000+:+ВІННИЦЬКА ОБЛАСТЬ/М.ВІННИЦЯ".substr(0, 2)
                                            props.setKoatuuGorod(null)
                                            props.localityAddress('null')
                                            getKoatuuGorod(props, t)
                                            break;
                                        //case "Волинська область":
                                        case "ВОЛИНСЬКА ОБЛАСТЬ/М.ЛУЦЬК":
                                            props.regionAddress("0700000000+:+ВОЛИНСЬКА ОБЛАСТЬ/М.ЛУЦЬК")
                                            t = "0700000000+:+ВОЛИНСЬКА ОБЛАСТЬ/М.ЛУЦЬК".substr(0, 2)
                                            props.setKoatuuGorod(null)
                                            props.localityAddress('null')
                                            getKoatuuGorod(props, t)
                                            break;
                                        //case "Дніпропетровська область":
                                        case "ДНІПРОПЕТРОВСЬКА ОБЛАСТЬ/М.ДНІПРО":
                                            props.regionAddress("1200000000+:+ДНІПРОПЕТРОВСЬКА ОБЛАСТЬ/М.ДНІПРО")
                                            t = "1200000000+:+ДНІПРОПЕТРОВСЬКА ОБЛАСТЬ/М.ДНІПРО".substr(0, 2)
                                            props.setKoatuuGorod(null)
                                            props.localityAddress('null')
                                            getKoatuuGorod(props, t)
                                            break;
                                        //case "Донецька область":
                                        case "ДОНЕЦЬКА ОБЛАСТЬ/М.ДОНЕЦЬК":
                                            props.regionAddress("1400000000+:+ДОНЕЦЬКА ОБЛАСТЬ/М.ДОНЕЦЬК")
                                            t = "1400000000+:+ДОНЕЦЬКА ОБЛАСТЬ/М.ДОНЕЦЬК".substr(0, 2)
                                            props.setKoatuuGorod(null)
                                            props.localityAddress('null')
                                            getKoatuuGorod(props, t)
                                            break;
                                        //case "Житомирська область":
                                        case "ЖИТОМИРСЬКА ОБЛАСТЬ/М.ЖИТОМИР":
                                            props.regionAddress("1800000000+:+ЖИТОМИРСЬКА ОБЛАСТЬ/М.ЖИТОМИР")
                                            t = "1800000000+:+ЖИТОМИРСЬКА ОБЛАСТЬ/М.ЖИТОМИР".substr(0, 2)
                                            props.setKoatuuGorod(null)
                                            props.localityAddress('null')
                                            getKoatuuGorod(props, t)
                                            break;
                                        //case "Закарпатська область":
                                        case "ЗАКАРПАТСЬКА ОБЛАСТЬ/М.УЖГОРОД":
                                            props.regionAddress("2100000000+:+ЗАКАРПАТСЬКА ОБЛАСТЬ/М.УЖГОРОД")
                                            t = "2100000000+:+ЗАКАРПАТСЬКА ОБЛАСТЬ/М.УЖГОРОД".substr(0, 2)
                                            props.setKoatuuGorod(null)
                                            props.localityAddress('null')
                                            getKoatuuGorod(props, t)
                                            break;
                                        //case "Запорізька область":
                                        case "ЗАПОРІЗЬКА ОБЛАСТЬ/М.ЗАПОРІЖЖЯ":
                                            props.regionAddress("2300000000+:+ЗАПОРІЗЬКА ОБЛАСТЬ/М.ЗАПОРІЖЖЯ")
                                            t = "2300000000+:+ЗАПОРІЗЬКА ОБЛАСТЬ/М.ЗАПОРІЖЖЯ".substr(0, 2)
                                            props.setKoatuuGorod(null)
                                            props.localityAddress('null')
                                            getKoatuuGorod(props, t)
                                            break;
                                        //case "Івано-Франківська область":
                                        case "ІВАНО-ФРАНКІВСЬКА ОБЛАСТЬ/М.ІВАНО-ФРАНКІВСЬК":
                                            props.regionAddress("2600000000+:+ІВАНО-ФРАНКІВСЬКА ОБЛАСТЬ/М.ІВАНО-ФРАНКІВСЬК")
                                            t = "2600000000+:+ІВАНО-ФРАНКІВСЬКА ОБЛАСТЬ/М.ІВАНО-ФРАНКІВСЬК".substr(0, 2)
                                            props.setKoatuuGorod(null)
                                            props.localityAddress('null')
                                            getKoatuuGorod(props, t)
                                            break;
                                        //case "Київська область":
                                        case "КИЇВСЬКА ОБЛАСТЬ/М.КИЇВ":
                                            props.regionAddress("3200000000+:+КИЇВСЬКА ОБЛАСТЬ/М.КИЇВ")
                                            t = "3200000000+:+КИЇВСЬКА ОБЛАСТЬ/М.КИЇВ".substr(0, 2)
                                            props.setKoatuuGorod(null)
                                            props.localityAddress('null')
                                            getKoatuuGorod(props, t)
                                            break;
                                        //case "Кіровоградська область":
                                        case "КІРОВОГРАДСЬКА ОБЛАСТЬ/М.КРОПИВНИЦЬКИЙ":
                                            props.regionAddress("3500000000+:+КІРОВОГРАДСЬКА ОБЛАСТЬ/М.КРОПИВНИЦЬКИЙ")
                                            t = "3500000000+:+КІРОВОГРАДСЬКА ОБЛАСТЬ/М.КРОПИВНИЦЬКИЙ".substr(0, 2)
                                            props.setKoatuuGorod(null)
                                            props.localityAddress('null')
                                            getKoatuuGorod(props, t)
                                            break;
                                        //case "Луганська область":
                                        case "ЛУГАНСЬКА ОБЛАСТЬ/М.ЛУГАНСЬК":
                                            props.regionAddress("4400000000+:+ЛУГАНСЬКА ОБЛАСТЬ/М.ЛУГАНСЬК")
                                            t = "4400000000+:+ЛУГАНСЬКА ОБЛАСТЬ/М.ЛУГАНСЬК".substr(0, 2)
                                            props.setKoatuuGorod(null)
                                            props.localityAddress('null')
                                            getKoatuuGorod(props, t)
                                            break;
                                        //case "Львівська область":
                                        case "ЛЬВІВСЬКА ОБЛАСТЬ/М.ЛЬВІВ":
                                            props.regionAddress("4600000000+:+ЛЬВІВСЬКА ОБЛАСТЬ/М.ЛЬВІВ")
                                            t = "4600000000+:+ЛЬВІВСЬКА ОБЛАСТЬ/М.ЛЬВІВ".substr(0, 2)
                                            props.setKoatuuGorod(null)
                                            props.localityAddress('null')
                                            getKoatuuGorod(props, t)
                                            break;
                                        //case "Миколаївська область":
                                        case "МИКОЛАЇВСЬКА ОБЛАСТЬ/М.МИКОЛАЇВ":
                                            props.regionAddress("4800000000+:+МИКОЛАЇВСЬКА ОБЛАСТЬ/М.МИКОЛАЇВ")
                                            t = "4800000000+:+МИКОЛАЇВСЬКА ОБЛАСТЬ/М.МИКОЛАЇВ".substr(0, 2)
                                            props.setKoatuuGorod(null)
                                            props.localityAddress('null')
                                            getKoatuuGorod(props, t)
                                            break;
                                        //case "Одеська область":
                                        case "ОДЕСЬКА ОБЛАСТЬ/М.ОДЕСА":
                                            props.regionAddress("5100000000+:+ОДЕСЬКА ОБЛАСТЬ/М.ОДЕСА")
                                            t = "5100000000+:+ОДЕСЬКА ОБЛАСТЬ/М.ОДЕСА".substr(0, 2)
                                            props.setKoatuuGorod(null)
                                            props.localityAddress('null')
                                            getKoatuuGorod(props, t)
                                            break;
                                        //case "Полтавська область":
                                        case "ПОЛТАВСЬКА ОБЛАСТЬ/М.ПОЛТАВА":
                                            props.regionAddress("5300000000+:+ПОЛТАВСЬКА ОБЛАСТЬ/М.ПОЛТАВА")
                                            t = "5300000000+:+ПОЛТАВСЬКА ОБЛАСТЬ/М.ПОЛТАВА".substr(0, 2)
                                            props.setKoatuuGorod(null)
                                            props.localityAddress('null')
                                            getKoatuuGorod(props, t)
                                            break;
                                        //case "Рівненська область":
                                        case "РІВНЕНСЬКА ОБЛАСТЬ/М.РІВНЕ":
                                            props.regionAddress("5600000000+:+РІВНЕНСЬКА ОБЛАСТЬ/М.РІВНЕ")
                                            t = "5600000000+:+РІВНЕНСЬКА ОБЛАСТЬ/М.РІВНЕ".substr(0, 2)
                                            props.setKoatuuGorod(null)
                                            props.localityAddress('null')
                                            getKoatuuGorod(props, t)
                                            break;
                                        //case "Сумська область":
                                        case "СУМСЬКА ОБЛАСТЬ/М.СУМИ":
                                            props.regionAddress("5900000000+:+СУМСЬКА ОБЛАСТЬ/М.СУМИ")
                                            t = "5900000000+:+СУМСЬКА ОБЛАСТЬ/М.СУМИ".substr(0, 2)
                                            props.setKoatuuGorod(null)
                                            props.localityAddress('null')
                                            getKoatuuGorod(props, t)
                                            break;
                                        //case "Тернопільська область":
                                        case "ТЕРНОПІЛЬСЬКА ОБЛАСТЬ/М.ТЕРНОПІЛЬ":
                                            props.regionAddress("6100000000+:+ТЕРНОПІЛЬСЬКА ОБЛАСТЬ/М.ТЕРНОПІЛЬ")
                                            t = "6100000000+:+ТЕРНОПІЛЬСЬКА ОБЛАСТЬ/М.ТЕРНОПІЛЬ".substr(0, 2)
                                            props.setKoatuuGorod(null)
                                            props.localityAddress('null')
                                            getKoatuuGorod(props, t)
                                            break;
                                        //case "Харківська область":
                                        case "ХАРКІВСЬКА ОБЛАСТЬ/М.ХАРКІВ":
                                            props.regionAddress("6300000000+:+ХАРКІВСЬКА ОБЛАСТЬ/М.ХАРКІВ")
                                            t = "6300000000+:+ХАРКІВСЬКА ОБЛАСТЬ/М.ХАРКІВ".substr(0, 2)
                                            props.setKoatuuGorod(null)
                                            props.localityAddress('null')
                                            getKoatuuGorod(props, t)
                                            break;
                                        //case "Херсонська область":
                                        case "ХЕРСОНСЬКА ОБЛАСТЬ/М.ХЕРСОН":
                                            props.regionAddress("6500000000+:+ХЕРСОНСЬКА ОБЛАСТЬ/М.ХЕРСОН")
                                            t = "6500000000+:+ХЕРСОНСЬКА ОБЛАСТЬ/М.ХЕРСОН".substr(0, 2)
                                            props.setKoatuuGorod(null)
                                            props.localityAddress('null')
                                            getKoatuuGorod(props, t)
                                            break;
                                        //case "Хмельницька область":
                                        case "ХМЕЛЬНИЦЬКА ОБЛАСТЬ/М.ХМЕЛЬНИЦЬКИЙ":
                                            props.regionAddress("6800000000+:+ХМЕЛЬНИЦЬКА ОБЛАСТЬ/М.ХМЕЛЬНИЦЬКИЙ")
                                            t = "6800000000+:+ХМЕЛЬНИЦЬКА ОБЛАСТЬ/М.ХМЕЛЬНИЦЬКИЙ".substr(0, 2)
                                            props.setKoatuuGorod(null)
                                            props.localityAddress('null')
                                            getKoatuuGorod(props, t)
                                            break;
                                        //case "Черкаська область":
                                        case "ЧЕРКАСЬКА ОБЛАСТЬ/М.ЧЕРКАСИ":
                                            props.regionAddress("7100000000+:+ЧЕРКАСЬКА ОБЛАСТЬ/М.ЧЕРКАСИ")
                                            t = "7100000000+:+ЧЕРКАСЬКА ОБЛАСТЬ/М.ЧЕРКАСИ".substr(0, 2)
                                            props.setKoatuuGorod(null)
                                            props.localityAddress('null')
                                            getKoatuuGorod(props, t)
                                            break;
                                        //case "Чернівецька область":
                                        case "ЧЕРНІВЕЦЬКА ОБЛАСТЬ/М.ЧЕРНІВЦІ":
                                            props.regionAddress("7300000000+:+ЧЕРНІВЕЦЬКА ОБЛАСТЬ/М.ЧЕРНІВЦІ")
                                            t = "7300000000+:+ЧЕРНІВЕЦЬКА ОБЛАСТЬ/М.ЧЕРНІВЦІ".substr(0, 2)
                                            props.setKoatuuGorod(null)
                                            props.localityAddress('null')
                                            getKoatuuGorod(props, t)
                                            break;
                                        //case "Чернігівська область":
                                        case "ЧЕРНІГІВСЬКА ОБЛАСТЬ/М.ЧЕРНІГІВ":
                                            props.regionAddress("7400000000+:+ЧЕРНІГІВСЬКА ОБЛАСТЬ/М.ЧЕРНІГІВ")
                                            t = "7400000000+:+ЧЕРНІГІВСЬКА ОБЛАСТЬ/М.ЧЕРНІГІВ".substr(0, 2)
                                            props.setKoatuuGorod(null)
                                            props.localityAddress('null')
                                            getKoatuuGorod(props, t)
                                            break;
                                        //case "Київ":
                                        case "М.КИЇВ":
                                            props.regionAddress("8000000000+:+М.КИЇВ")
                                            t = "8000000000+:+М.КИЇВ".substr(0, 2)
                                            props.setKoatuuGorod(null)
                                            props.localityAddress('null')
                                            getKoatuuGorod(props, t)
                                            break;
                                        //case "Севастополь":
                                        case "М.СЕВАСТОПОЛЬ":
                                            props.regionAddress("8500000000+:+М.СЕВАСТОПОЛЬ")
                                            t = "8500000000+:+М.СЕВАСТОПОЛЬ".substr(0, 2)
                                            props.setKoatuuGorod(null)
                                            props.localityAddress('null')
                                            getKoatuuGorod(props, t)
                                            break;
                                        default:
                                            break;
                                    }
                                    props.countryNameAddress('Україна')
                                    props.localityAddress(e.locality)
                                    props.postalCodeAddress(e.postalCode)
                                    props.streetAddress(e.streetAddress)
                                    return 1
                                }
                            )
                        }
                        props.popUpAddress(true)
                        props.popUpAddressId(true)
                    }
                }
            >
                Редагувати адресу
            </Button>
        </div>
    }
    return tmp
}

async function getKoatuuGorod(props, id){
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/classifications/koatuu/byMask?mask=${id}`;
    const response = await fetch(endPoint, {
        method: "GET",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json1 = await response.json()
        if(json1.hasOwnProperty('message') === true){}
        else{
            props.setKoatuuGorod(json1)
        }
        
    }
}

function viewTimberClass(props){
    let tmp = null;
    let tmpClassificationId = null;
    if(props.createItems.additionalClassificationIds.timberSortment !== 'null') tmpClassificationId = JSON.parse(props.createItems.additionalClassificationIds.timberSortment);
    if(tmpClassificationId !== null){
        //if(tmpClassificationId.classificationId === 'sRound'){
            tmp = <div className={styles.groupElem + ' ' + styles.groupElemCategory}>
                <div>
                    <TextField
                        disabled={elementDisabledCreate(props)}
                        id="outlined-select-currency-native"
                        select
                        label="Клас якості"
                        value={props.createItems.additionalClassificationIds.timberClass}
                        onChange={(event)=>{
                            props.changeTimberClass(event.target.value)
                            props.changeTimberDiameter('null')
                        }}
                        SelectProps={{
                            native: true,
                        }}
                        helperText="Оберіть клас якості"
                        variant="outlined"
                    >
                        <option key='null' selected disabled value="null">Обрати</option>
                        {classificationTimberClassOption(props)}
                    </TextField>
                </div>
            </div>
        //}
    }
    return tmp
}

function buttonEditItem(props, i, count){
    if(props.auctionsMy.status === null || props.auctionsMy.status === 'active_rectification'){
        return (
            <div
                onClick={
                    () => {
                        props.changeAuctionsItemsSwitchWindow(2);
                        
                        props.changeCreateItemAuctionId(props.auctionsMy.id)
                        props.changeCreateItemIds(i.id)
                        props.changeCreateItemClassificationId(i.classification.id)
                        let tmpTimberLength = null;
                        if(i.additionalClassifications !== null){
                            for(let tt = 0; tt < i.additionalClassifications.length; tt++){
                                if(i.additionalClassifications[tt].scheme === 'timberLength'){
                                    tmpTimberLength = i.additionalClassifications[tt].classificationId
                                }
                            }
                        }
                        tmpTimberLength = tmpTimberLength.split(' - ')
                        props.changeTimberLength(tmpTimberLength[0], 1)
                        props.changeTimberLength(tmpTimberLength[1], 2)

                        props.changeCreateItemDescription(i.description)
                        props.changeCreateItemAuctionId(i.unitCode)
                        props.changeCreateItemQuantity(i.quantity)

                        props.changeCreateItemUnitCode(i.unit.code)
                        //props.changeCreateItemAddress(i.address.id)
                        if(i.address !== null){
                            props.setDataAddress(i.address.id, 'CREATE_ITEMS_LAND_RENTAL_ADDRESS')
                            
                            props.setDataAddress(i.address.id, 'CREATE_ITEMS_LAND_RENTAL_ADDRESS_ID')
                            props.setDataAddress(i.address.countryName, 'CREATE_ITEMS_LAND_RENTAL_ADDRESS_COUNTRY_NAME')
                            props.setDataAddress(i.address.region, 'CREATE_ITEMS_LAND_RENTAL_ADDRESS_REGION')
                            props.setDataAddress(i.address.locality, 'CREATE_ITEMS_LAND_RENTAL_ADDRESS_LOCALITY')
                            props.setDataAddress(i.address.streetAddress, 'CREATE_ITEMS_LAND_RENTAL_ADDRESS_STREET_ADDRESS')
                            props.setDataAddress(i.address.postalCode, 'CREATE_ITEMS_LAND_RENTAL_ADDRESS_POSTAL_CODE')
                        }
                        if(i.location !== null){
                            props.setDataAddress(i.location.latitude, 'CREATE_ITEMS_LAND_RENTAL_LOCATION_LATITUDE')
                            props.setDataAddress(i.location.longitude, 'CREATE_ITEMS_LAND_RENTAL_LOCATION_LONGITUDE')
                        }



                        /*let data = {
                            domen: process.env.REACT_APP_END_POINT_BD,
                            version: 'v1',
                            local: props.langFlag,
                            params: '?limit=20'
                        }
                        let header = {
                            'Authorization': props.token.access_token,
                            'Content-Type': 'application/json'
                        }*/

                        //combineData(props)


                        /*if(props.classificationsTimber.timberSortment === null){
                            if(props.classificationsTimber.timberSortment.length === 0){*/
                                classificationTimberSortmentStartChange(props, count)
                            /*}
                        }
                        if(props.classificationsTimber.timberSpecies === null){
                            if(props.classificationsTimber.timberSpecies.length === 0){*/
                                classificationTimberSpeciesChange(props, count)
                            /*}
                        }
                        if(props.classificationsTimber.timberClass === null){
                            if(props.classificationsTimber.timberClass.length === 0){*/
                                classificationTimberClassChange(props, count)
                            /*}
                        }
                        if(props.classificationsTimber.timberDiameter === null){
                            if(props.classificationsTimber.timberDiameter.length === 0){*/
                                classificationTimberDiameterChange(props, count)
                            /*}
                        }
                        if(props.classificationsTimber.timberLength === null){
                            if(props.classificationsTimber.timberLength.length === 0){*/
                                classificationTimberLengthChange(props, count)
                            /*}
                        }
                        if(props.classificationsTimber.timberStorage === null){
                            if(props.classificationsTimber.timberStorage.length === 0){*/
                                classificationTimberStorageChange(props, count)
                            /*}
                        }
                        if(props.classificationsTimber.timberProductionYear === null){
                            if(props.classificationsTimber.timberProductionYear.length === 0){*/
                                classificationTimberProductionYearChange(props, count)
                            /*}
                        }
                        if(props.classificationsTimber.timberProductionQuarter === null){
                            if(props.classificationsTimber.timberProductionQuarter.length === 0){*/
                                classificationTimberProductionQuarterChange(props, count)
                            /*}
                        }*/
                    }
                }
            >
                <IconButton aria-label="edit">
                    <EditIcon />
                </IconButton>
            </div>
        )
    }else{
        return (
            <div>
                <IconButton 
                    disabled
                    aria-label="edit"
                >
                    <EditIcon />
                </IconButton>
            </div>
        )
    }
}

async function combineData(props){
    await loadData(props)
}

async function loadData(props){
    let data5 = {
        domen: process.env.REACT_APP_END_POINT_BD,
        version: 'v1',
        local: props.langFlag,
        params: '?limit=100'
    }
    let header5 = {
        'Authorization': props.token.access_token,
        'Content-Type': 'application/json'
    }
    // props.auctionsGetClassificationsByTimberSortment(data5, header5)
    // props.auctionsGetClassificationsByTimberSpecies(data5, header5)
    // props.auctionsGetClassificationsByTimberClass(data5, header5)
    // props.auctionsGetClassificationsByTimberDiameter(data5, header5)
    // props.auctionsGetClassificationsByTimberLength(data5, header5)
    // props.auctionsGetClassificationsByTimberStorage(data5, header5)
    // props.auctionsGetClassificationsByTimberProductionYear(data5, header5)
    // props.auctionsGetClassificationsByTimberProductionQuarter(data5, header5)
}

function buttonDeleteItem(props, i){
    if(props.auctionsMy.status === null){
        return (
            <div
                onClick={
                    () => {
                        props.changeLoader(true)
                        deleteItem(props, props.auctionsMy, i.id)
                        //props.changeAuctionsSwitchWindow(0)
                    }
                }
            >
                <IconButton aria-label="delete">
                    <DeleteIcon />
                </IconButton>
            </div>
        )
    }
    return null
}

function addressArr(props){
    if(props.addressGetId !== null){
        return props.addressGetId.map(
            (i, count) => {
                return <option key={count} value={i.id}>{i.countryName + ' ' + i.postalCode + ' ' + i.region + ' ' + i.locality + ' ' + i.streetAddress}</option>
            }
        )
    }
}

function timberDiametr(props){
    let tmp = null;
    if(props.createItems.additionalClassificationIds.timberSortment === null || props.createItems.additionalClassificationIds.timberSortment === '' || props.createItems.additionalClassificationIds.timberSortment === 'null'){
        tmp = <TextField
            disabled='disabled'
            id="outlined-select-currency-native"
            select
            label="Діаметр"
            value={props.createItems.additionalClassificationIds.timberDiameter}
            onChange={(event)=>{
                props.changeTimberDiameter(event.target.value)
            }}
            SelectProps={{
                native: true,
            }}
            helperText="Оберіть діаметр"
            variant="outlined"
        >
            <option key='null' selected disabled value="null">Обрати</option>
            {classificationTimberDiameterOption(props)}
        </TextField>
    }else{
        tmp = <TextField
            disabled={elementDisabledCreate(props)}
            id="outlined-select-currency-native"
            select
            label="Діаметр"
            value={props.createItems.additionalClassificationIds.timberDiameter}
            onChange={(event)=>{
                props.changeTimberDiameter(event.target.value)
            }}
            SelectProps={{
                native: true,
            }}
            helperText="Оберіть діаметр"
            variant="outlined"
        >
            <option key='null' selected disabled value="null">Обрати</option>
            {classificationTimberDiameterOption(props)}
        </TextField>
    }
    return tmp;
}

function elementDisabled(props){
    let tmpStatus = null;
    if(
        props.createItems.classificationId === null ||
        props.createItems.classificationId === '' ||
        props.createItems.classificationId === 'null' ||
        props.createItems.additionalClassificationIds.timberSortment === null ||
        props.createItems.additionalClassificationIds.timberSortment === '' ||
        props.createItems.additionalClassificationIds.timberSortment === 'null' ||
        props.createItems.additionalClassificationIds.timberSpecies=== null ||
        props.createItems.additionalClassificationIds.timberSpecies === '' ||
        props.createItems.additionalClassificationIds.timberSpecies === 'null' ||
        //props.createItems.additionalClassificationIds.timberClass === null ||
        //props.createItems.additionalClassificationIds.timberClass === '' ||
        //props.createItems.additionalClassificationIds.timberClass === 'null' ||
        //props.createItems.additionalClassificationIds.timberDiameter === null ||
        //props.createItems.additionalClassificationIds.timberDiameter === '' ||
        //props.createItems.additionalClassificationIds.timberDiameter === 'null' ||
        props.createItems.additionalClassificationIds.timberLength1 === null ||
        props.createItems.additionalClassificationIds.timberLength1 === '' ||
        props.createItems.additionalClassificationIds.timberLength1 === 'null' ||
        props.createItems.additionalClassificationIds.timberLength2 === null ||
        props.createItems.additionalClassificationIds.timberLength2 === '' ||
        props.createItems.additionalClassificationIds.timberLength2 === 'null' ||
        props.createItems.additionalClassificationIds.timberStorage === null ||
        props.createItems.additionalClassificationIds.timberStorage === '' ||
        props.createItems.additionalClassificationIds.timberStorage === 'null' ||
        props.createItems.additionalClassificationIds.timberProductionYear === null ||
        props.createItems.additionalClassificationIds.timberProductionYear === '' ||
        props.createItems.additionalClassificationIds.timberProductionYear === 'null' ||
        props.createItems.additionalClassificationIds.timberProductionQuarter === null ||
        props.createItems.additionalClassificationIds.timberProductionQuarter === '' ||
        props.createItems.additionalClassificationIds.timberProductionQuarter === 'null' ||
        props.createItems.unitCode === null ||
        props.createItems.unitCode === '' ||
        props.createItems.unitCode === 'null' ||
        props.createItems.description === null ||
        props.createItems.description === '' ||
        props.createItems.description === 'null' ||
        /*props.createItems.unitValue.currency === null ||
        props.createItems.unitValue.currency === '' ||
        props.createItems.unitValue.currency === 'null' ||
        props.createItems.unitValue.amount === null ||
        props.createItems.unitValue.amount === '' ||
        props.createItems.unitValue.amount === 'null' ||*/
        props.createItems.quantity === null ||
        props.createItems.quantity === '' ||
        props.createItems.quantity === 'null'
    ){
        tmpStatus = 'disabled'
    }
    if(props.auctionsMy.status !== null){
        if(props.auctionsMy.status !== 'active_rectification'){
            tmpStatus = 'disabled'
        }
    }
    return tmpStatus
}

function elementDisabledCreate(props){
    let tmpStatus = null;
    if(props.auctionsMy.status !== null){
        if(props.auctionsMy.status !== 'active_rectification'){
            tmpStatus = 'disabled'
        }
    }
    return tmpStatus
}

function classificationIdOption(props){
    if(props.classificationsTimber.classificationsByTypeMain !== null){
        if(props.classificationsTimber.classificationsByTypeMain.length > 0){
            return props.classificationsTimber.classificationsByTypeMain.map(
                (i, count) => {
                    return <option key={count} value={i.id}>{i.scheme + ' ' + i.classificationId + ' ' + i.description}</option>
                }
            )
        }
    }
}

function classificationTimberSortmentOption(props){
    if(props.classificationsTimber.timberSortment !== null){
        if(props.classificationsTimber.timberSortment.length > 0){
            return props.classificationsTimber.timberSortment.map(
                (i, count) => {
                    //return <option key={count} value={i.id}>{i.id + ' ' + i.scheme + ' ' + i.classificationId + ' ' + i.description}</option>
                    return <option key={count} value={JSON.stringify({
                        scheme: "timberSortment",
                        classificationId : i.name
                    })}>{i.description.uk_UA}</option>
                }
            )
        }
    }
}

function classificationTimberSortmentStartChange(props, count){
    if(props.classificationsTimber.timberSortment !== null){
        if(props.classificationsTimber.timberSortment.length > 0){
            return props.classificationsTimber.timberSortment.map(
                (i) => {
                    let tt = props.auctionsMy.items[count].additionalClassifications.filter(
                        (i) => {
                            return i.scheme === "timberSortment"
                        }
                    )
                    
                    if(tt.length > 0){
                        if(tt[0].classificationId === i.name){
                            //props.changeTimberSortment(i.id)
                            props.changeTimberSortment(
                                JSON.stringify({
                                    scheme: "timberSortment",
                                    classificationId : tt[0].classificationId
                                })
                            )
                        }
                    }
                    return null
                }
            )
        }
    }
}

function classificationTimberSpeciesOption(props){
    if(props.classificationsTimber.timberSpecies !== null){
        if(props.classificationsTimber.timberSpecies.length > 0){
            return props.classificationsTimber.timberSpecies.map(
                (i, count) => {
                    //return <option key={count} value={i.id}>{i.id + ' ' + i.scheme + ' ' + i.classificationId + ' ' + i.description}</option>
                    return <option key={count} value={JSON.stringify({
                        scheme: "timberSpecies",
                        classificationId : i.name
                    })}>{i.description.uk_UA}</option>
                }
            )
        }
    }
}

function classificationTimberSpeciesChange(props, count){
    if(props.classificationsTimber.timberSpecies !== null){
        if(props.classificationsTimber.timberSpecies.length > 0){
            return props.classificationsTimber.timberSpecies.map(
                (i) => {
                    let tt = props.auctionsMy.items[count].additionalClassifications
                    .filter(
                        (i) => {
                            return i.scheme === "timberSpecies"
                        }
                    )
                    if(tt.length > 0){
                        if(tt[0].classificationId === i.name){
                            //props.changeTimberSpecies(i.id)
                            props.changeTimberSpecies(
                                JSON.stringify({
                                    scheme: "timberSpecies",
                                    classificationId : tt[0].classificationId
                                })
                            )
                        }
                    }
                    return null
                }
            )
        }
    }
}

function classificationTimberClassOption(props){
    let tmpClassificationId = null;
    if(props.createItems.additionalClassificationIds.timberSortment !== 'null') tmpClassificationId = JSON.parse(props.createItems.additionalClassificationIds.timberSortment);
    if(tmpClassificationId !== null){
        if(tmpClassificationId.classificationId === 'sRound'){
            if(props.classificationsTimber.timberClass !== null){
                if(props.classificationsTimber.timberClass.length > 0){
                    return props.classificationsTimber.timberClass
                    .filter(
                        (e) => {
                            return e.name !== "noQualityClass"
                        }
                    )
                    .map(
                        (i, count) => {
                            return <option key={count} value={JSON.stringify({
                                "scheme": "timberClass",
                                "classificationId": i.name
                            })}>{i.description.uk_UA}</option>
                        }
                    )
                }
            }
        }else{
            if(props.classificationsTimber.timberClass !== null){
                if(props.classificationsTimber.timberClass.length > 0){
                    return props.classificationsTimber.timberClass
                    .filter(
                        (e) => {
                            return e.name === "noQualityClass"
                        }
                    )
                    .map(
                        (i, count) => {
                            return <option key={count} value={JSON.stringify({
                                "scheme": "timberClass",
                                "classificationId": i.name
                            })}>{i.description.uk_UA}</option>
                        }
                    )
                }
            }
        }
    }
}

function classificationTimberClassChange(props, count){
    if(props.classificationsTimber.timberClass !== null){
        if(props.classificationsTimber.timberClass.length > 0){
            return props.classificationsTimber.timberClass.map(
                (i) => {
                    let tt = props.auctionsMy.items[count].additionalClassifications.filter(
                        (i) => {
                            return i.scheme === "timberClass"
                        }
                    )
                    
                    if(tt.length > 0){
                        if(tt[0].classificationId === i.name){
                            //props.changeTimberClass(i.id)
                            props.changeTimberClass(
                                JSON.stringify({
                                    "scheme": "timberClass",
                                    "classificationId": tt[0].classificationId
                                })
                            )
                        }
                    }
                    return null
                }
            )
        }
    }
}

function classificationTimberDiameterOption(props){
    if(
        props.createItems.additionalClassificationIds.timberSortment !== '' &&
        props.createItems.additionalClassificationIds.timberSortment !== null &&
        props.createItems.additionalClassificationIds.timberSortment !== 'null' &&
        props.classificationsTimber.timberDiameter !== null
    ){
        if(props.classificationsTimber.timberDiameter.length > 0){
            return props.classificationsTimber.timberDiameter
            .filter(
                (i) => {
                    let tmp = JSON.parse(props.createItems.additionalClassificationIds.timberSortment)
                    let tmpFlag = false
                    if(i.description.relatedTo.length > 0){
                        for(let e = 0; e < i.description.relatedTo.length; e++){
                            if(i.description.relatedTo[e].indexOf(tmp.classificationId) !== -1){
                                tmpFlag = true
                                break
                            }
                        }
                    }
                    return tmpFlag
                }
            )
            .map(
                (i, count) => {
                    return <option key={count} value={JSON.stringify({
                        scheme: "timberDiameter",
                        classificationId : i.name
                    })}>{i.description.uk_UA}</option>
                }
            )
        }
    }
}

function classificationTimberDiameterChange(props, count){
    if(props.classificationsTimber.timberDiameter !== null){
        if(props.classificationsTimber.timberDiameter.length > 0){
            return props.classificationsTimber.timberDiameter.map(
                (i) => {
                    let tt = props.auctionsMy.items[count].additionalClassifications.filter(
                        (i) => {
                            return i.scheme === "timberDiameter"
                        }
                    )
                    if(tt.length > 0){
                        if(tt[0].classificationId === i.name){
                            //props.changeTimberDiameter(i.id)
                            props.changeTimberDiameter(
                                JSON.stringify({
                                    scheme: "timberDiameter",
                                    classificationId : tt[0].classificationId
                                })
                            )
                        }
                    }
                    return null
                }
            )
        }
    }
}

function classificationTimberStorageOption(props){
    if(props.classificationsTimber.timberStorage !== null){
        if(props.classificationsTimber.timberStorage.length > 0){
            return props.classificationsTimber.timberStorage.map(
                (i, count) => {
                    return <option key={count} value={JSON.stringify({
                        scheme: "timberStorage",
                        classificationId : i.name
                    })}>{i.description.uk_UA}</option>
                }
            )
        }
    }
}

function classificationTimberStorageChange(props, count){
    if(props.classificationsTimber.timberStorage !== null){
        if(props.classificationsTimber.timberStorage.length > 0){
            return props.classificationsTimber.timberStorage.map(
                (i) => {
                    let tt = props.auctionsMy.items[count].additionalClassifications.filter(
                        (i) => {
                            return i.scheme === "timberStorage"
                        }
                    )
                    
                    if(tt.length > 0){
                        if(tt[0].classificationId === i.name){
                            //props.changeTimberStorage(i.id)
                            props.changeTimberStorage(
                                JSON.stringify({
                                    scheme: "timberStorage",
                                    classificationId : tt[0].classificationId
                                })
                            )
                        }
                    }
                    return null
                }
            )
        }
    }
}

function classificationTimberProductionYearOption(props){
    const tmpTimberProductionYear = [
        {
            scheme: "timberProductionYear",
            classificationId : '2000'
        },
        {
            scheme: "timberProductionYear",
            classificationId : '2001'
        },
        {
            scheme: "timberProductionYear",
            classificationId : '2002'
        },
        {
            scheme: "timberProductionYear",
            classificationId : '2003'
        },
        {
            scheme: "timberProductionYear",
            classificationId : '2004'
        },
        {
            scheme: "timberProductionYear",
            classificationId : '2005'
        },
        {
            scheme: "timberProductionYear",
            classificationId : '2006'
        },
        {
            scheme: "timberProductionYear",
            classificationId : '2007'
        },
        {
            scheme: "timberProductionYear",
            classificationId : '2008'
        },
        {
            scheme: "timberProductionYear",
            classificationId : '2009'
        },
        {
            scheme: "timberProductionYear",
            classificationId : '2010'
        },
        {
            scheme: "timberProductionYear",
            classificationId : '2011'
        },
        {
            scheme: "timberProductionYear",
            classificationId : '2012'
        },
        {
            scheme: "timberProductionYear",
            classificationId : '2013'
        },
        {
            scheme: "timberProductionYear",
            classificationId : '2014'
        },
        {
            scheme: "timberProductionYear",
            classificationId : '2015'
        },
        {
            scheme: "timberProductionYear",
            classificationId : '2016'
        },
        {
            scheme: "timberProductionYear",
            classificationId : '2017'
        },
        {
            scheme: "timberProductionYear",
            classificationId : '2018'
        },
        {
            scheme: "timberProductionYear",
            classificationId : '2019'
        },
        {
            scheme: "timberProductionYear",
            classificationId : '2020'
        },
        {
            scheme: "timberProductionYear",
            classificationId : '2021'
        },
        {
            scheme: "timberProductionYear",
            classificationId : '2022'
        },
    ]
    //if(props.classificationsTimber.timberProductionYear !== null){
        //if(props.classificationsTimber.timberProductionYear.length > 0){
            //return props.classificationsTimber.timberProductionYear.map(
    if(tmpTimberProductionYear !== null){
        if(tmpTimberProductionYear.length > 0){
            return tmpTimberProductionYear.map(
                (i, count) => {
                    return <option key={count} value={JSON.stringify({
                        scheme: "timberProductionYear",
                        classificationId : i.classificationId
                    })}>{i.classificationId}</option>
                }
            )
        }
    }
}

function classificationTimberProductionYearChange(props, count){
    const tmpTimberProductionYear = [
        {
            scheme: "timberProductionYear",
            classificationId : '2000'
        },
        {
            scheme: "timberProductionYear",
            classificationId : '2001'
        },
        {
            scheme: "timberProductionYear",
            classificationId : '2002'
        },
        {
            scheme: "timberProductionYear",
            classificationId : '2003'
        },
        {
            scheme: "timberProductionYear",
            classificationId : '2004'
        },
        {
            scheme: "timberProductionYear",
            classificationId : '2005'
        },
        {
            scheme: "timberProductionYear",
            classificationId : '2006'
        },
        {
            scheme: "timberProductionYear",
            classificationId : '2007'
        },
        {
            scheme: "timberProductionYear",
            classificationId : '2008'
        },
        {
            scheme: "timberProductionYear",
            classificationId : '2009'
        },
        {
            scheme: "timberProductionYear",
            classificationId : '2010'
        },
        {
            scheme: "timberProductionYear",
            classificationId : '2011'
        },
        {
            scheme: "timberProductionYear",
            classificationId : '2012'
        },
        {
            scheme: "timberProductionYear",
            classificationId : '2013'
        },
        {
            scheme: "timberProductionYear",
            classificationId : '2014'
        },
        {
            scheme: "timberProductionYear",
            classificationId : '2015'
        },
        {
            scheme: "timberProductionYear",
            classificationId : '2016'
        },
        {
            scheme: "timberProductionYear",
            classificationId : '2017'
        },
        {
            scheme: "timberProductionYear",
            classificationId : '2018'
        },
        {
            scheme: "timberProductionYear",
            classificationId : '2019'
        },
        {
            scheme: "timberProductionYear",
            classificationId : '2020'
        },
        {
            scheme: "timberProductionYear",
            classificationId : '2021'
        },
        {
            scheme: "timberProductionYear",
            classificationId : '2022'
        },
    ]
    //if(props.classificationsTimber.timberProductionYear !== null){
        //if(props.classificationsTimber.timberProductionYear.length > 0){
            //return props.classificationsTimber.timberProductionYear.map(
    if(tmpTimberProductionYear !== null){
        if(tmpTimberProductionYear.length > 0){
            return tmpTimberProductionYear.map(
                (i) => {
                    let tt = props.auctionsMy.items[count].additionalClassifications.filter(
                        (i) => {
                            return i.scheme === "timberProductionYear"
                        }
                    )
                    
                    if(tt.length > 0){
                        //if(tt[0].id === i.id){
                        if(tt[0].classificationId === i.classificationId){
                            props.changeTimberProductionYear(
                                JSON.stringify({
                                    scheme: "timberProductionYear",
                                    classificationId : tt[0].classificationId
                                })
                            )
                        }
                    }
                    return null
                }
            )
        }
    }
}

function classificationTimberProductionQuarterOption(props){
    if(props.classificationsTimber.timberProductionQuarter !== null){
        if(props.classificationsTimber.timberProductionQuarter.length > 0){
            return props.classificationsTimber.timberProductionQuarter.map(
                (i, count) => {
                    return <option key={count} value={JSON.stringify({
                        scheme: "timberProductionQuarter",
                        classificationId : i.classificationId
                    })}>{i.classificationId}</option>
                }
            )
        }
    }
}

function classificationTimberProductionQuarterChange(props, count){
    if(props.classificationsTimber.timberProductionQuarter !== null){
        if(props.classificationsTimber.timberProductionQuarter.length > 0){
            return props.classificationsTimber.timberProductionQuarter.map(
                (i) => {
                    let tt = props.auctionsMy.items[count].additionalClassifications.filter(
                        (i) => {
                            return i.scheme === "timberProductionQuarter"
                        }
                    )
                    
                    if(tt.length > 0){
                        if(tt[0].id === i.id){
                            //props.changeTimberProductionQuarter(i.id)
                            props.changeTimberProductionQuarter(
                                JSON.stringify({
                                    scheme: "timberProductionQuarter",
                                    classificationId : tt[0].classificationId
                                })
                            )
                        }
                    }
                    return null
                }
            )
        }
    }
}

function classificationTimberLengthOption(props){
    if(props.classificationsTimber.timberLength !== null){
        if(props.classificationsTimber.timberLength.length > 0){
            return props.classificationsTimber.timberLength.map(
                (i, count) => {
                    return <option key={count} value={i.id}>{i.id + ' ' + i.scheme + ' ' + i.classificationId + ' ' + i.description}</option>
                }
            )
        }
    }
}

function classificationTimberLengthChange(props, count){
    if(props.classificationsTimber.timberLength !== null){
        if(props.classificationsTimber.timberLength.length > 0){
            return props.classificationsTimber.timberLength.map(
                (i) => {
                    let tt = props.auctionsMy.items[count].additionalClassifications.filter(
                        (i) => {
                            return i.scheme === "timberLength"
                        }
                    )
                    
                    if(tt.length > 0){
                        if(tt[0].id === i.id){
                            //тут делю на 2 части по -
                            let lengthTimber = i.classificationId.split('-')
                            //props.changeTimberLength(i.classificationId, 1)
                            props.changeTimberLength(lengthTimber[0].trim(), 1)
                            props.changeTimberLength(lengthTimber[1].trim(), 2)
                        }
                    }
                    return null
                }
            )
        }
    }
}

function classificationTimberUnitCode(props){
    if(props.classificationsTimber.unitCode !== null){
        if(props.classificationsTimber.unitCode.length > 0){
            return props.classificationsTimber.unitCode.map(
                (i, count) => {
                    return <option key={count} value={i.code}>{i.name}</option>
                }
            )
        }
    }
}

async function createItem(props){
    //let tmpAdress = await createAddress(props)
    if(props.userProfiles !== null){
        if(props.userProfiles.organization.address.id !== null){
            /*const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/item`;*/
            const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/auction/${props.auctionsMy.id}/item`;
            const arrAddClass = [
                {
                    scheme: "CPVS",
                    classificationId :"QB49-3"
                },
            ];
            if(props.createItems.additionalClassificationIds.timberSortment !== null){
                arrAddClass.push(JSON.parse(props.createItems.additionalClassificationIds.timberSortment))
            }
            if(props.createItems.additionalClassificationIds.timberSpecies !== null){
                arrAddClass.push(JSON.parse(props.createItems.additionalClassificationIds.timberSpecies))
            }

            let tmpClassificationId = null;
            if(props.createItems.additionalClassificationIds.timberSortment !== 'null') tmpClassificationId = JSON.parse(props.createItems.additionalClassificationIds.timberSortment);
            if(tmpClassificationId !== null){
                //if(tmpClassificationId.classificationId === 'sRound'){
                    if(props.createItems.additionalClassificationIds.timberClass !== null){
                        arrAddClass.push(JSON.parse(props.createItems.additionalClassificationIds.timberClass))
                    }
                //}
            }

            if(props.createItems.additionalClassificationIds.timberDiameter !== null && props.createItems.additionalClassificationIds.timberDiameter !== 'null'){
                arrAddClass.push(JSON.parse(props.createItems.additionalClassificationIds.timberDiameter))
            }
            if(props.createItems.additionalClassificationIds.timberLength1 !== null && props.createItems.additionalClassificationIds.timberLength2 !== null){
                let timberLength1 = props.createItems.additionalClassificationIds.timberLength1.replace('.', ',')
                let timberLength2 = props.createItems.additionalClassificationIds.timberLength2.replace('.', ',')
                if(timberLength1.indexOf(',') === -1 ){
                    timberLength1 = timberLength1 + ',0'
                }
                if(timberLength2.indexOf(',') === -1 ){
                    timberLength2 = timberLength2 + ',0'
                }
                timberLength1 = timberLength1.replace(',,', ',')
                timberLength2 = timberLength2.replace(',,', ',')
                arrAddClass.push(
                    {
                        scheme: 'timberLength',
                        classificationId: timberLength1.trim() + ' - ' + timberLength2.trim()
                    }
                )
            }
            if(props.createItems.additionalClassificationIds.timberStorage !== null){
                arrAddClass.push(JSON.parse(props.createItems.additionalClassificationIds.timberStorage))
            }
            if(props.createItems.additionalClassificationIds.timberProductionYear !== null){
                arrAddClass.push(JSON.parse(props.createItems.additionalClassificationIds.timberProductionYear))
            }
            if(props.createItems.additionalClassificationIds.timberProductionQuarter !== null){
                arrAddClass.push(JSON.parse(props.createItems.additionalClassificationIds.timberProductionQuarter))
            }

            const response = await fetch(endPoint, {
                method: "POST",
                body: JSON.stringify({
                    //auctionId: props.auctionsMy[0].id,                      //+
                    classificationId: Number(props.createItems.classificationId), //Тип аукциона                     //+
                    additionalClassifications: arrAddClass,//props.createItems.additionalClassificationIds,  //+     //Доп классификатор, порода дерева
                    //locationId: 0,                                                                    //New необязательное поле
                    //addressId: Number(props.createItems.addressesGetMy),  //+
                    //addressId: tmpAdress,
                    address: {
                        addressIdentifier: {
                            scheme: 'koatuu',
                            identifier: setKoatuu(props.createItems.landRental.addressItem.region),
                            name: props.createItems.landRental.addressItem.region
                        },
                        streetAddress: props.createItems.landRental.addressItem.streetAddress,
                        locality: props.createItems.landRental.addressItem.locality,
                        region: props.createItems.landRental.addressItem.region,
                        postalCode: props.createItems.landRental.addressItem.postalCode,
                        countryName: props.createItems.landRental.addressItem.countryName
                    },
                    description: props.createItems.description,             //+
                    //unitId: 14, //Новое поле
                    unitCode: props.createItems.unitCode,         //+               //Мера измерения
                    unitValue: {
                        currency: 'UAH',                                 //+            //Валюта
                        amount: props.auctionsMy.value.amount,      //+                               //Цена
                    },
                    quantity: props.createItems.quantity, //+                         //Колличество
                }),
                headers: {
                    'Authorization': props.token.access_token,
                    'Content-Type': 'application/json'
                }
            }).catch(console.error)
            if(response === undefined || response === 'undefined'){
            }else{
                /*let data = {
                    domen: process.env.REACT_APP_END_POINT_BD,
                    version: 'v1',
                    local: props.langFlag,
                    params: '?limit=100'
                }*/
                let header = {
                    'Authorization': props.token.access_token,
                    'Content-Type': 'application/json'
                }
                //props.auctionsGetMy(data, header)

                const endPoint2 = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/auction/${props.auctionsMy.id}`;
                props.changeLoader(true)
                const response2 = await fetch(endPoint2, {
                        method: "GET",
                        headers: header
                }).catch(console.error)
                if(response2 === undefined || response2 === 'undefined'){
                }else{
                    props.changeAuctionsItemsSwitchWindow(0);
                    props.changeCreateItemClassificationId('null')
                    props.changeTimberSortment('null')
                    props.changeTimberSpecies('null')
                    props.changeTimberClass('null')
                    props.changeTimberDiameter('null')
                    props.changeTimberLength('', 1)
                    props.changeTimberLength('', 2)
                    props.setRecExpTimberDiametr('null')
                    props.changeTimberStorage('null')
                    props.changeTimberProductionYear('null')
                    props.changeTimberProductionQuarter('null')
                    props.changeCreateItemUnitCode('null')
                    props.changeCreateItemDescription('')
                    //props.changeCreateItemUnitValueCurrency('null')
                    //props.changeCreateItemUnitValueAmount('')
                    props.changeCreateItemQuantity('')
                    const json2 = await response2.json()
                    if(json2.hasOwnProperty('message') === true){
                        props.changeLoader(false)
                    }else{
                        props.getMyAuction(json2)
                        props.changeLoader(false)
                    }
                }
            }
        }
    }
} 

async function saveItem(props){
    if(props.userProfiles !== null){
        if(props.userProfiles.organization.address.id !== null){
            /*const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/item/${props.createItems.id}`;*/
            const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/auction/${props.auctionsMy.id}/item/${props.createItems.id}`;
            const arrAddClass = [
                {
                    scheme: "CPVS",
                    classificationId :"QB49-3"
                },
            ];
            if(props.createItems.additionalClassificationIds.timberSortment !== null){
                arrAddClass.push(JSON.parse(props.createItems.additionalClassificationIds.timberSortment))
            }
            if(props.createItems.additionalClassificationIds.timberSpecies !== null){
                arrAddClass.push(JSON.parse(props.createItems.additionalClassificationIds.timberSpecies))
            }
            if(props.createItems.additionalClassificationIds.timberClass !== null){
                arrAddClass.push(JSON.parse(props.createItems.additionalClassificationIds.timberClass))
            }
            if(props.createItems.additionalClassificationIds.timberDiameter !== null){
                arrAddClass.push(JSON.parse(props.createItems.additionalClassificationIds.timberDiameter))
            }
            if(props.createItems.additionalClassificationIds.timberLength1 !== null && props.createItems.additionalClassificationIds.timberLength2 !== null){
                let timberLength1 = props.createItems.additionalClassificationIds.timberLength1.replace('.', ',')
                let timberLength2 = props.createItems.additionalClassificationIds.timberLength2.replace('.', ',')
                if(timberLength1.indexOf(',') === -1 ){
                    timberLength1 = timberLength1 + ',0'
                }
                if(timberLength2.indexOf(',') === -1 ){
                    timberLength2 = timberLength2 + ',0'
                }
                timberLength1 = timberLength1.replace(',,', ',')
                timberLength2 = timberLength2.replace(',,', ',')
                arrAddClass.push(
                    {
                        scheme: 'timberLength',
                        classificationId: timberLength1.trim() + ' - ' + timberLength2.trim()
                    }
                )
            }
            if(props.createItems.additionalClassificationIds.timberStorage !== null){
                arrAddClass.push(JSON.parse(props.createItems.additionalClassificationIds.timberStorage))
            }
            if(props.createItems.additionalClassificationIds.timberProductionYear !== null){
                arrAddClass.push(JSON.parse(props.createItems.additionalClassificationIds.timberProductionYear))
            }
            if(props.createItems.additionalClassificationIds.timberProductionQuarter !== null){
                arrAddClass.push(JSON.parse(props.createItems.additionalClassificationIds.timberProductionQuarter))
            }

            //let tmpAdress = await createAddress(props)
            const response = await fetch(endPoint, {
                method: "PUT",
                body: JSON.stringify({
                    auctionId: props.createAuction.id,                      //+
                    classificationId: Number(props.createItems.classificationId), //Тип аукциона                     //+
                    additionalClassifications: arrAddClass,//props.createItems.additionalClassificationIds,  //+     //Доп классификатор, порода дерева
                    //locationId: 0,                                                                    //New необязательное поле
                    //addressId: Number(props.createItems.addressesGetMy),  //+
                    //addressId: tmpAdress,
                    address: {
                        addressIdentifier: {
                            scheme: 'koatuu',
                            identifier: setKoatuu(props.createItems.landRental.addressItem.region),
                            name: props.createItems.landRental.addressItem.region
                        },
                        streetAddress: props.createItems.landRental.addressItem.streetAddress,
                        locality: props.createItems.landRental.addressItem.locality,
                        region: props.createItems.landRental.addressItem.region,
                        postalCode: props.createItems.landRental.addressItem.postalCode,
                        countryName: props.createItems.landRental.addressItem.countryName
                    },
                    //unitId: props.createItems.unitCode, //Новое поле
                    description: props.createItems.description,             //+
                    unitCode: props.createItems.unitCode,         //+               //Мера измерения
                    unitValue: {
                        currency: 'UAH',                                 //+            //Валюта
                        amount: props.auctionsMy.value.amount,      //+                               //Цена
                    },
                    quantity: props.createItems.quantity, //+                         //Колличество
                }),
                headers: {
                    'Authorization': props.token.access_token,
                    'Content-Type': 'application/json'
                }
            }).catch(console.error)
            if(response === undefined || response === 'undefined'){
            }else{
                let header = {
                    'Authorization': props.token.access_token,
                    'Content-Type': 'application/json'
                }
                const endPoint2 = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/auction/${props.auctionsMy.id}`;
                props.changeLoader(true)
                const response2 = await fetch(endPoint2, {
                        method: "GET",
                        headers: header
                }).catch(console.error)
                if(response2 === undefined || response2 === 'undefined'){
                }else{
                    props.changeAuctionsItemsSwitchWindow(0);
                    props.changeCreateItemClassificationId('null')
                    props.changeTimberSortment('null')
                    props.changeTimberSpecies('null')
                    props.changeTimberClass('null')
                    props.changeTimberDiameter('null')
                    props.changeTimberLength('', 1)
                    props.changeTimberLength('', 2)
                    props.setRecExpTimberDiametr('null')
                    props.changeTimberStorage('null')
                    props.changeTimberProductionYear('null')
                    props.changeTimberProductionQuarter('null')
                    props.changeCreateItemUnitCode('null')
                    props.changeCreateItemDescription('')
                    //props.changeCreateItemUnitValueCurrency('null')
                    //props.changeCreateItemUnitValueAmount('')
                    props.changeCreateItemQuantity('')
                    const json2 = await response2.json()
                    if(json2.hasOwnProperty('message') === true){
                        props.changeLoader(false)
                    }else{
                        props.getMyAuction(json2)
                        props.changeLoader(false)
                    }
                }
            }
        }
    }
}

async function  deleteItem(props, aucId, itemId){
    const endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/auction/${aucId}/item/${itemId}`;
    const response1 = await fetch(endPoint, {
        method: "DELETE",
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response1 === undefined || response1 === 'undefined'){
    }else{
        /*let data = {
            domen: process.env.REACT_APP_END_POINT_BD,
            version: 'v1',
            local: props.langFlag,
            params: '?limit=100'
        }*/
        let header = {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
        //props.auctionsGetMy(data, header)

        const endPoint2 = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/private/auction/${props.auctionsMy.id}`;
        props.changeLoader(true)
        const response2 = await fetch(endPoint2, {
                method: "GET",
                headers: header
        }).catch(console.error)
        if(response2 === undefined || response2 === 'undefined'){
        }else{
            props.changeAuctionsItemsSwitchWindow(0);
            props.changeCreateItemClassificationId('null')
            props.changeTimberSortment('null')
            props.changeTimberSpecies('null')
            props.changeTimberClass('null')
            props.changeTimberDiameter('null')
            props.changeTimberLength('', 1)
            props.changeTimberLength('', 2)
            props.setRecExpTimberDiametr('null')
            props.changeTimberStorage('null')
            props.changeTimberProductionYear('null')
            props.changeTimberProductionQuarter('null')
            props.changeCreateItemUnitCode('null')
            props.changeCreateItemDescription('')
            //props.changeCreateItemUnitValueCurrency('null')
            //props.changeCreateItemUnitValueAmount('')
            props.changeCreateItemQuantity('')
            const json2 = await response2.json()
            if(json2.hasOwnProperty('message') === true){
                props.changeLoader(false)
            }else{
                props.getMyAuction(json2)
                props.changeLoader(false)
            }
        }
    }
}



function setNewAddress(props) {
    let map = window.parent.document.getElementById("target").contentWindow.document.getElementById('map');
    let tmpLat = map.dataset.lat.split('.')
    let tmpLng = map.dataset.lng.split('.')
    let newAddress = {
        //address: `${map.dataset.city !== '' ? map.dataset.city : ''}${map.dataset.street !== '' ? ', ' + map.dataset.street : ''}${map.dataset.house !== '' ? ', будинок ' + map.dataset.house + ',' : ''}`,
        address: `${map.dataset.street !== '' ? map.dataset.street : ''}${map.dataset.house !== '' ? ', будинок ' + map.dataset.house + ',' : ''}`,
        postcode: map.dataset.postcode,
        region: map.dataset.region,
        //lat: map.dataset.lat,
        lat: `${tmpLat[0]}.${tmpLat[1].slice(0, 5)}`,
        //lng: map.dataset.lng,
        lng: `${tmpLng[0]}.${tmpLng[1].slice(0, 5)}`,
        locality: map.dataset.city,
        street: map.dataset.street
    }
    if (map.dataset.country === 'Україна' || map.dataset.country === 'Донецька область'){
        if(map.dataset.country === 'Донецька область'){
            newAddress.country = 'Україна'
        }else{
            newAddress.country = map.dataset.country
        }
    }
    props.setDataAddress(newAddress.country, 'CREATE_ITEMS_LAND_RENTAL_ADDRESS_COUNTRY_NAME')
    props.setDataAddress(newAddress.region, 'CREATE_ITEMS_LAND_RENTAL_ADDRESS_REGION')
    props.setDataAddress(newAddress.locality, 'CREATE_ITEMS_LAND_RENTAL_ADDRESS_LOCALITY')
    props.setDataAddress(newAddress.address, 'CREATE_ITEMS_LAND_RENTAL_ADDRESS_STREET_ADDRESS')
    props.setDataAddress(newAddress.postcode, 'CREATE_ITEMS_LAND_RENTAL_ADDRESS_POSTAL_CODE')

    props.setDataAddress(newAddress.lat, 'CREATE_ITEMS_LAND_RENTAL_LOCATION_LATITUDE')
    props.setDataAddress(newAddress.lng, 'CREATE_ITEMS_LAND_RENTAL_LOCATION_LONGITUDE')
}

function blokedInput(props, type){
    if(props.createItems.landRental.id !== null){
        return disabledElement(props.auctionsMy, type)
    }else if(
        props.auctionsMy.items === null ||
        props.auctionsMy.items.length === 0
    ){
        return disabledElement(props.auctionsMy, type)
    }else{
        return 'disabled'
    }
}

async function createAddress(props){
    let endPoint = null
    let tmpMetod = null
    if(props.createItems.landRental.addressItem.id === null){
        endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/address`;
        tmpMetod = 'POST'
    }else{
        endPoint = `${process.env.REACT_APP_END_POINT_BD}/api/v1/uk/address/${props.createItems.landRental.addressItem.id}`;
        tmpMetod = 'PUT'
    }
    const response = await fetch(endPoint, {
        method: tmpMetod,
        body: JSON.stringify({
            addressIdentifier: {
                scheme: 'koatuu',
                identifier: setKoatuu(props.createItems.landRental.addressItem.region),
                name: props.createItems.landRental.addressItem.region
            },
            streetAddress: props.createItems.landRental.addressItem.streetAddress,
            locality: props.createItems.landRental.addressItem.locality,
            region: props.createItems.landRental.addressItem.region,
            postalCode: props.createItems.landRental.addressItem.postalCode,
            countryName: props.createItems.landRental.addressItem.countryName
        }),
        headers: {
            'Authorization': props.token.access_token,
            'Content-Type': 'application/json'
        }
    }).catch(console.error)
    if(response === undefined || response === 'undefined'){
    }else{
        const json1 = await response.json()
        if(json1.hasOwnProperty('message') === true){
            //await props.setPopAddressError(json1)
        }else{
            return await json1.id
        }
    }
}

function setKoatuu(data){
	switch(data){
        case 'Севастополь': return "8500000000"
        case 'Київ': return "8000000000"
        case 'Чернігівська область': return "7400000000"
        case 'Чернівецька область': return "7300000000"
        case 'Черкаська область': return "7100000000"
        case 'Хмельницька область': return "6800000000"
        case 'Херсонська область': return "6500000000"
        case 'Харківська область': return "6300000000"
        case 'Тернопільська область': return "6100000000"
        case 'Сумська область': return "5900000000"
        case 'Рівненська область': return "5600000000"
        case 'Полтавська область': return "5300000000"
        case 'Одеська область': return "5100000000"
        case 'Миколаївська область': return "4800000000"
        case 'Львівська область': return "4600000000"
        case 'Луганська область': return "4400000000"
        case 'Кіровоградська область': return "3500000000"
        case 'Київська область': return "3200000000"
        case 'Івано-Франківська область': return "2600000000"
        case 'Запорізька область': return "2300000000"
        case 'Закарпатська область': return "2100000000"
        case 'Житомирська область': return "1800000000"
        case 'Донецька область': return "1400000000"
        case 'Дніпропетровська область': return "1200000000"
        case 'Волинська область': return "0700000000"
        case 'Вінницька область': return "0500000000"
        case 'Автономна Республіка Крим': return "0100000000"
	}
}

function redBlock(props, amount){
    if(props.auctionsMy === undefined || props.auctionsMy === 'undefined'){
        return null
    }else{
        if(
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-english" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-fast" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-fast-manual" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-fast-auction-manual-qualification" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-auction" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-auction-manual" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-qualification" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-english-initial-qualification-prod" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-fast" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-fast-manual" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-fast-auction-manual-qualification" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-initial-auction" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-initial-auction-manual" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-dutch-initial-qualification-prod" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-manual" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-fast-auction-manual-qualification" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-auction-manual" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification" ||
            props.auctionsMy.sellingMethod === "legitimatePropertyLease-priorityEnglish-initial-qualification-prod"
        ){
            if(amount === null || amount === '' || amount === 'null'){
                return 'error'
            }
            return null
        }
    }
    return null
}
const mapStateToProps = state => {
    return {
        token: state.start.token,
        auctions: state.start.auctionsGet,
        langFlag: state.start.langFlag,
        createAuctionTab: state.start.createAuctionTab,
        createAuction: state.start.createAuction,
        createItems: state.start.createItems,
        auctionsMy: state.start.auctionsMy,
        auctionsItemsSwitchWindow: state.start.auctionsItemsSwitchWindow,
        classificationsGets: state.start.classificationsGet,
        unitsGet: state.start.unitsGet,
        userProfiles: state.start.userProfiles,
        classificationsTimber: state.start.classificationsTimber,
        regExpTimberDiametr: state.start.regExpTimberDiametr,
        addressGetId: state.start.addressGetId,
        address: state.start.address,
        loader: state.start.loader,
    }
}

const mapDispatchToProps = {
    changeCreateItemAuctionId,
    changeCreateItemClassificationId,
    changeCreateItemUnitValueCurrency,
    changeCreateItemUnitValueAmount,
    changeCreateItemUnitValueTax,
    changeCreateItemDescription,
    changeAuctionsItemsSwitchWindow,
    auctionsGetMy,
    changeAuctionsSwitchWindow,
    changeAdditionalClassificationIds,
    changeCreateItemQuantity,
    changeCreateItemIds,
    auctionsGetClassificationsByType,
    auctionsGetClassificationsByTimberSortment,
    auctionsGetClassificationsByTimberSpecies,
    auctionsGetClassificationsByTimberClass,
    auctionsGetClassificationsByTimberDiameter,
    auctionsGetClassificationsByTimberLength,
    auctionsGetClassificationsByTimberStorage,
    auctionsGetClassificationsByTimberProductionYear,
    auctionsGetClassificationsByTimberProductionQuarter,
    changeTimberSortment,
    changeTimberSpecies,
    changeTimberClass,
    changeTimberDiameter,
    changeTimberLength,
    changeTimberStorage,
    changeTimberProductionYear,
    changeTimberProductionQuarter,
    changeCreateItemUnitCode,
    changeCreateAuctionTab,
    setRecExpTimberDiametr,
    changeCreateItemAddress,
    changeLoader,
    getMyAuction,
    setPopUpAuctionMyNull,

    streetAddress,
    localityAddress,
    regionAddress,
    postalCodeAddress,
    countryNameAddress,
    popUpAddress,
    popUpAddressId,
    setPopAddressError,
    setPopAddressComplite,
    setKoatuuRegion,
    setKoatuuGorod,
    setDataAddress,
}

export default connect(mapStateToProps, mapDispatchToProps)(WindowItems)
