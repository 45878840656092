import React from 'react';
import styles from './activeAuctionLinkNew.module.css';

import {connect} from 'react-redux';

const ActiveAuctionLink = (props) => {
    let tmp = null;
    let linkProzoro = null
    if(props.auction !== null){
        if(props.auction.auctionUrl !== null){
            linkProzoro = <a className={styles.colorLink} href={props.auction.auctionUrl} target="_blank" rel="noopener noreferrer">
                <div className={styles.buttonLink}>Вхід в аукціон в якості спостерігача</div>
            </a>
        }/*else{
            linkProzoro = <a className={styles.colorLink} href={props.auction.auctionUrl} target="_blank">
                <div className={styles.buttonLink}>Вхід в аукціон в якості спостерігача</div>
            </a>
        }*/
        let linkProzoroOwner = null
        if(props.auctionsAllBids.arr !== null){
            // if(props.curentBidLot.length > 0){
            //TODO: Проверить линки на ауки, которые Серега не возвращает
                if(props.auctionsAllBids.arr.status === "active"){
                    let tmpLink = props.auctionsAllBids.arr.link
                    //let tmpLink = props.curentBidLot[0].participationUrl
                    linkProzoroOwner = <a className={styles.colorLink} href={tmpLink} target="_blank" rel="noopener noreferrer">
                        <div className={styles.buttonLink +  ' ' + styles.buttonLinkBottom}>Вхід в аукціон в якості учасника</div> 
                    </a>
                // }
            }
        }
        tmp = (
            <div className={styles.infoBlock}>
                <h3>Посилання на аукціон</h3>
                <div className={styles.documentElem}>
                    {linkProzoro}
                    {linkProzoroOwner}
                </div>
            </div>
        )
    }
    return tmp
}

const mapStateToProps = state => {
    return {
        token: state.start.token,
        auction: state.start.auctionFull,
        langFlag: state.start.langFlag,
        history: state.start.history,
        curentBidLot: state.start.curentBidLot,
        auctionsAllBids: state.start.auctionsAllBids,
    }
}

export default connect(mapStateToProps, null)(ActiveAuctionLink)
