import conditions from "../../../../product/lotInformation/auctionElements/conditions"

export function addProperty(data, body, property){
    let tmp = body
    if(data.base[property] !== null && data.base[property] !== '' && data.base[property] !== 'null'){
        if(data.base[property] === 'true'){
            tmp[property] = true
        }else if(data.base[property] === 'false'){
            tmp[property] = false
        }else if(property === 'tenderAttempts'){
            tmp[property] = Number(data.base[property])
        }else{
            tmp[property] = data.base[property]
        }
        return tmp
    }
    return body
}

export function addPropertyMonga(data, body, property){
    let tmp = body
    if(data.base[property] === undefined || data.base[property] === 'undefined') return body
    if(data.base[property] !== null && data.base[property] !== '' && data.base[property] !== 'null'){
        if(property === 'xDocumentRequirements'){
            if(data.base[property] !== null && data.base[property] !== '' && data.base[property] !== 'null'){
                tmp['x_documentRequirements'] = {}
                if(data.base[property].hasOwnProperty('uk_UA')){
                    tmp['x_documentRequirements'] = data.base[property]
                }else{
                    tmp['x_documentRequirements'].uk_UA = data.base[property]
                }
            }
        }else if(property === 'xAdditionalInformation'){
            if(data.base[property] !== null && data.base[property] !== '' && data.base[property] !== 'null'){
                tmp['x_additionalInformation'] = {}
                if(data.base[property].hasOwnProperty('uk_UA')){
                    tmp['x_additionalInformation'] = data.base[property]
                }else{
                    tmp['x_additionalInformation'].uk_UA = data.base[property]
                }
            }
        }else if(property === 'lotIdentifier'){
            if(data.base[property] !== null && data.base[property] !== '' && data.base[property] !== 'null'){
                tmp['lotId'] = {}
                tmp['lotId'] = data.base[property]
            }
        }else{
            tmp[property] = {}
            if(data.base[property].hasOwnProperty('uk_UA')){
                tmp[property] = data.base[property]
            }else{
                tmp[property].uk_UA = data.base[property]
            }
        }
        return tmp
    }
    return body
}

export function addPropertyValue(data, body, property){
    let tmp = body
    if(data.base[property] !== null && data.base[property] !== '' && data.base[property] !== 'null'){
        if(data.base[property].amount !== null && data.base[property].amount !== ''){
            if(property === 'value'){
                if(data.base[property].valueAddedTaxIncluded === true || data.base[property].valueAddedTaxIncluded === 'true'){
                    tmp[property] = {
                        currency: data.base[property]?.currency ? data.base[property]?.currency : 'UAH',
                        amount: Number(data.base[property].amount),
                        valueAddedTaxIncluded: true
                    }
                }else{
                    tmp[property] = {
                        currency: data.base[property]?.currency ? data.base[property]?.currency : 'UAH',
                        amount: Number(data.base[property].amount),
                        valueAddedTaxIncluded: false
                    }
                }
            }else if(property === 'budgetSpent'){
                if(Number(data.base[property].amount) > 0){
                    tmp[property] = {
                        currency: data.base[property]?.currency ? data.base[property]?.currency : 'UAH',
                        amount: Number(data.base[property].amount),
                    }
                }else{
                    tmp[property] = null
                }
            }else{
                tmp[property] = {
                    currency: data.base[property]?.currency ? data.base[property]?.currency : 'UAH',
                    amount: Number(data.base[property].amount),
                }
            }
            return tmp
        }else{
            if(property === 'value'){
                if(data.base[property].valueAddedTaxIncluded === true || data.base[property].valueAddedTaxIncluded === 'true'){
                    tmp[property] = {
                        currency: 'UAH',
                        amount: 0.01,
                        valueAddedTaxIncluded: true
                    }
                }else{
                    tmp[property] = {
                        currency: 'UAH',
                        amount: 0.01,
                        valueAddedTaxIncluded: false
                    }
                }
            }else if(property === 'budgetSpent'){
                tmp[property] = null
            }else{
                if(
                    property === 'guarantee' ||
                    property === 'registrationFee'
                ){
                    tmp[property] = {
                        currency: 'UAH',
                        amount: 0.01,
                    }
                }else if(
                    property === 'minimalStep'
                ){
                    tmp[property] = null
                }
            }
            return tmp
        }
    }
    return body
}

export function addPropertyBanks(data, body, property, propertyMy){
    let tmp = body
    //if(data.base[propertyMy] !== null && data.base[propertyMy] !== '' && data.base[propertyMy] !== 'null'){
        //if(data.base[propertyMy].length > 0){
    if(data.bank.addNewBank.length > 0 || data.bank.deleteBank.length > 0){
        tmp[property] = createBankAccountsIds(data, propertyMy)
        return tmp
    }
        //return tmp
    //}
    return body
}

function createBankAccountsIds(data, propertyMy){
    let tmp = []
    if(data.bank.deleteBank.length > 0){
        let count = false
        for(let i = 0; i < data.base[propertyMy].length; i++){
            for(let j = 0; j < data.bank.deleteBank.length; j++){
                if(data.base[propertyMy][i].id === Number(data.bank.deleteBank[j])) count = true
            }
            if(!count){
            //if(data.bank.deleteBank.includes(String(data.base[propertyMy][i].id)) === false){
                tmp.push(
                    {
                        accountIdentifications: [
                            {
                                description: data.base[propertyMy][i].accountIdentifications[data.base[propertyMy][i].accountIdentifications.length-1].description,
                                identifier: data.base[propertyMy][i].accountIdentifications[data.base[propertyMy][i].accountIdentifications.length-1].identifier,
                                scheme: data.base[propertyMy][i].accountIdentifications[data.base[propertyMy][i].accountIdentifications.length-1].scheme,//"UA-IBAN",
                                id: data.base[propertyMy][i].accountIdentifications[data.base[propertyMy][i].accountIdentifications.length-1].id
                            }
                        ],
                        apiIdentifier: data.base[propertyMy][i].apiIdentifier,
                        bankName: data.base[propertyMy][i].bankName,
                        currency: data.base[propertyMy][i].currency,
                        description: data.base[propertyMy][i].description,
                        id: data.base[propertyMy][i].id
                    }
                )
            }
        }
    }
    if(data.bank.addNewBank.length > 0){
        if(data.base[propertyMy].length > 0) tmp = data.base[propertyMy]
        for(let i = 0; i < data.bank.addNewBank.length; i++){
            for(let j = 0; j < data.bankList.length; j++){
                if(Number(data.bank.addNewBank[i]) === data.bankList[j].id){
                    tmp.push(
                        {
                            accountIdentifications: [
                                {
                                    description: data.bankList[j].accountIdentifications[data.bankList[j].accountIdentifications.length-1].description,
                                    identifier: data.bankList[j].accountIdentifications[data.bankList[j].accountIdentifications.length-1].identifier,
                                    scheme: data.bankList[j].accountIdentifications[data.bankList[j].accountIdentifications.length-1].scheme
                                }
                            ],
                            apiIdentifier: data.bankList[j].apiIdentifier,
                            bankName: data.bankList[j].bankName,
                            currency: data.bankList[j].currency,
                            description: data.bankList[j].description
                        }
                    )
                }
            }
        }
    }
    return tmp
}

export function previousAuctionId(data, body, property){
    let tmp = body
    if(data.base.tenderAttempts === 1){
        tmp[property] = null
        return tmp
    }
    if(data.discount[property] !== null && data.discount[property] !== '' && data.discount[property] !== 'null'){
        tmp[property] = data.discount[property]
        return tmp
    }
    return body
}

export function addPropertyDiscount(data, body, property){
    let tmp = body
    if(data.discount.discountOnOff !== true ) {
        tmp.discount = null
        return tmp
    }
    if(data.discount.previousAuctionId !== ''){
        if(data.discount.discountPercent !== '' && data.discount.discountPercent !== null && data.discount.discountPercent !== 0 && data.discount.discountPercent !== '0'){
            tmp.discount = {}
            tmp.discount.discount = true
            if(data.discount.discountPercent !== '' && data.discount.discountPercent !== null){
                tmp.discount.discountPercent = Number(data.discount.discountPercent)
            }else{
                tmp.discountPercent = '0'
            }
            if(data.discount.previousAuctionValue.amount !== '' && data.discount.previousAuctionValue.amount !== null){
                tmp.discount.previousAuctionValue = {}
                tmp.discount.previousAuctionValue.currency = 'UAH'
                tmp.discount.previousAuctionValue.amount = Number(data.discount.previousAuctionValue.amount)
                if(data.discount.previousAuctionValue.valueAddedTaxIncluded !== '' && data.discount.previousAuctionValue.valueAddedTaxIncluded !== null){
                    if(data.discount.previousAuctionValue.valueAddedTaxIncluded === 'null' || data.discount.previousAuctionValue.valueAddedTaxIncluded === 'true' || data.discount.previousAuctionValue.valueAddedTaxIncluded === true){
                        tmp.discount.previousAuctionValue.valueAddedTaxIncluded = true
                    }else{
                        tmp.discount.previousAuctionValue.valueAddedTaxIncluded = false
                    }
                }else{
                    tmp.discount.previousAuctionValue.valueAddedTaxIncluded = true
                }
            }
            return tmp
        }else{
            tmp.discount = {}
            tmp.discount = null
            return tmp
        }
    }
    return body
}

export function addPropertyBanksGroop(data, body, property){
    let tmp = body
    let tmpRregistrationFee = [], 
        tmpGuarantee = [],
        tmpSecurityDeposit = [],
        tmpImprovementCompensation = [],
        tmpOther = [],
        tmpAdvancePayment = [],
        tmpLease = [],
        tmpPayment = [],
        tmpPreparationPayment = []
    if(data.bankAccountZU !== null){
        for(let i=0; i < data.bankAccountZU.length; i++){
            if(data.bankAccountZU[i].group === 'registrationFee' || data.bankAccountZU[i].groupe === 'registrationFee'){
                tmpRregistrationFee.push(data.bankAccountZU[i])
            }else if(data.bankAccountZU[i].group === 'guarantee' || data.bankAccountZU[i].groupe === 'guarantee'){
                tmpGuarantee.push(data.bankAccountZU[i])
            }else if(data.bankAccountZU[i].group === 'securityDeposit' || data.bankAccountZU[i].groupe === 'securityDeposit'){
                tmpSecurityDeposit.push(data.bankAccountZU[i])
            }else if(data.bankAccountZU[i].group === 'improvementCompensation' || data.bankAccountZU[i].groupe === 'improvementCompensation'){
                tmpImprovementCompensation.push(data.bankAccountZU[i])
            }else if(data.bankAccountZU[i].group === 'other' || data.bankAccountZU[i].groupe === 'other'){
                tmpOther.push(data.bankAccountZU[i])
            }else if(data.bankAccountZU[i].group === 'advancePayment' || data.bankAccountZU[i].groupe === 'advancePayment'){
                tmpAdvancePayment.push(data.bankAccountZU[i])
            }else if(data.bankAccountZU[i].group === 'lease' || data.bankAccountZU[i].groupe === 'lease'){
                tmpLease.push(data.bankAccountZU[i])
            }else if(data.bankAccountZU[i].group === 'payment' || data.bankAccountZU[i].groupe === 'payment'){
                tmpPayment.push(data.bankAccountZU[i])
            }else if(data.bankAccountZU[i].group === 'preparationPayment' || data.bankAccountZU[i].groupe === 'preparationPayment'){
                tmpPreparationPayment.push(data.bankAccountZU[i])
            }
        }
    }
    if( tmpRregistrationFee.length > 0 || tmpGuarantee.length > 0 || tmpOther.length > 0 || (data.base.bankAccounts !== null && data.base.bankAccounts.length > 0)){
        tmp.bankAccounts = []
        if(tmpRregistrationFee.length > 0){
            let datas = {}
            datas.accountType = "registrationFee"
            datas.accounts = []
            for(let i=0; i < tmpRregistrationFee.length; i++){
                for(let j=0; j < data.bankList.length; j++){
                    if(Number(data.bankList[j].id) === Number(tmpRregistrationFee[i].id)){
                        datas.accounts[i] = {}
                        datas.accounts[i].accountIdentifications = []
                        for(let t=0; t < data.bankList[j].accountIdentifications.length; t++){
                            datas.accounts[i].accountIdentifications[t] = {}
                            datas.accounts[i].accountIdentifications[t].description = {}
                            datas.accounts[i].accountIdentifications[t].description.uk_UA = data.bankList[j].accountIdentifications[t].description
                            datas.accounts[i].accountIdentifications[t].id = data.bankList[j].accountIdentifications[t].identifier
                            datas.accounts[i].accountIdentifications[t].scheme = data.bankList[j].accountIdentifications[t].scheme
                        }
                        datas.accounts[i].bankName = {}
                        datas.accounts[i].bankName.uk_UA = data.bankList[j].bankName
                        datas.accounts[i].currency = data.bankList[j].currency //'UAH'
                        datas.accounts[i].description = {}
                        datas.accounts[i].description.uk_UA = data.bankList[j].description
                    }
                    
                }
            }
            let tmpDel = false
            for(let jt = 0; jt < data.deleteBankGroops.length; jt++){
                if(data.deleteBankGroops[jt].type === "registrationFee") tmpDel = data.deleteBankGroops[jt].status
            }
            if(!tmpDel){
                if(data.base.bankAccounts !== null && data.base.bankAccounts.length > 0){
                    for(let i=0; i < data.base.bankAccounts.length; i++){
                        if(data.base.bankAccounts[i].accountType === 'registrationFee'){
                            let bankTmp = []
                            for(let rt = 0; rt < data.base.bankAccounts[i].accounts.length; rt++){
                                let tmpFlag = false
                                for(let f = 0; f < data.deleteCurentBank.length; f++){
                                    if(data.deleteCurentBank[f] === data.base.bankAccounts[i].accounts[rt].id) tmpFlag = true
                                }
                                if(!tmpFlag) bankTmp.push(data.base.bankAccounts[i].accounts[rt])
                            }
                            let tmpBank = [...datas.accounts, ...bankTmp]
                            datas.accounts = tmpBank
                        }
                    }
                }
            }
            tmp.bankAccounts.push(datas)
        }else{
            let tmpDel = false
            for(let jt = 0; jt < data.deleteBankGroops.length; jt++){
                if(data.deleteBankGroops[jt].type === "registrationFee") tmpDel = data.deleteBankGroops[jt].status
            }
            if(!tmpDel){
                if(data.base.bankAccounts !== null && data.base.bankAccounts.length > 0){
                    let datas = {}
                    let flag = false
                    datas.accountType = "registrationFee"
                    for(let i=0; i < data.base.bankAccounts.length; i++){
                        if(data.base.bankAccounts[i].accountType === 'registrationFee'){
                            let bankTmp = []
                            for(let rt = 0; rt < data.base.bankAccounts[i].accounts.length; rt++){
                                let tmpFlag = false
                                for(let f = 0; f < data.deleteCurentBank.length; f++){
                                    if(data.deleteCurentBank[f] === data.base.bankAccounts[i].accounts[rt].id) tmpFlag = true
                                }
                                if(!tmpFlag) bankTmp.push(data.base.bankAccounts[i].accounts[rt])
                            }
                            datas.accounts = bankTmp
                            flag = true
                        }
                    }
                    if(flag === true) tmp.bankAccounts.push(datas)
                }
            }
        }
        if(tmpGuarantee.length > 0){
            let datas = {}
            datas.accountType = "guarantee"
            datas.accounts = []
            for(let i=0; i < tmpGuarantee.length; i++){
                for(let j=0; j < data.bankList.length; j++){
                    if(Number(data.bankList[j].id) === Number(tmpGuarantee[i].id)){
                        datas.accounts[i] = {}
                        datas.accounts[i].accountIdentifications = []
                        for(let t=0; t < data.bankList[j].accountIdentifications.length; t++){
                            datas.accounts[i].accountIdentifications[t] = {}
                            datas.accounts[i].accountIdentifications[t].description = {}
                            datas.accounts[i].accountIdentifications[t].description.uk_UA = data.bankList[j].accountIdentifications[t].description
                            datas.accounts[i].accountIdentifications[t].id = data.bankList[j].accountIdentifications[t].identifier
                            datas.accounts[i].accountIdentifications[t].scheme = data.bankList[j].accountIdentifications[t].scheme
                        }
                        datas.accounts[i].bankName = {}
                        datas.accounts[i].bankName.uk_UA = data.bankList[j].bankName
                        datas.accounts[i].currency = data.bankList[j].currency//'UAH'
                        datas.accounts[i].description = {}
                        datas.accounts[i].description.uk_UA = data.bankList[j].description
                    }
                }
            }
            let tmpDel = false
            for(let jt = 0; jt < data.deleteBankGroops.length; jt++){
                if(data.deleteBankGroops[jt].type === "guarantee") tmpDel = data.deleteBankGroops[jt].status
            }
            if(!tmpDel){
                if(data.base.bankAccounts !== null && data.base.bankAccounts.length > 0){
                    for(let i=0; i < data.base.bankAccounts.length; i++){
                        if(data.base.bankAccounts[i].accountType === 'guarantee'){
                            let bankTmp = []
                            for(let rt = 0; rt < data.base.bankAccounts[i].accounts.length; rt++){
                                let tmpFlag = false
                                for(let f = 0; f < data.deleteCurentBank.length; f++){
                                    if(data.deleteCurentBank[f] === data.base.bankAccounts[i].accounts[rt].id) tmpFlag = true
                                }
                                if(!tmpFlag) bankTmp.push(data.base.bankAccounts[i].accounts[rt])
                            }
                            let tmpBank = [...datas.accounts, ...bankTmp]
                            datas.accounts = tmpBank
                        }
                    }
                }
            }
            tmp.bankAccounts.push(datas)
        }else{
            let tmpDel = false
            for(let jt = 0; jt < data.deleteBankGroops.length; jt++){
                if(data.deleteBankGroops[jt].type === "guarantee") tmpDel = data.deleteBankGroops[jt].status
            }
            if(!tmpDel){
                if(data.base.bankAccounts !== null && data.base.bankAccounts.length > 0){
                    let datas = {}
                    let flag = false
                    datas.accountType = "guarantee"
                    for(let i=0; i < data.base.bankAccounts.length; i++){
                        if(data.base.bankAccounts[i].accountType === 'guarantee'){
                            let bankTmp = []
                            for(let rt = 0; rt < data.base.bankAccounts[i].accounts.length; rt++){
                                let tmpFlag = false
                                for(let f = 0; f < data.deleteCurentBank.length; f++){
                                    if(data.deleteCurentBank[f] === data.base.bankAccounts[i].accounts[rt].id) tmpFlag = true
                                }
                                if(!tmpFlag) bankTmp.push(data.base.bankAccounts[i].accounts[rt])
                            }
                            datas.accounts = bankTmp
                            flag = true
                        }
                    }
                    if(flag === true) tmp.bankAccounts.push(datas)
                }
            }
        }
        if(tmpOther.length > 0){
            let datas = {}
            datas.accountType = "other"
            datas.accounts = []
            for(let i=0; i < tmpOther.length; i++){
                for(let j=0; j < data.bankList.length; j++){
                    if(Number(data.bankList[j].id) === Number(tmpOther[i].id)){
                        datas.accounts[i] = {}
                        datas.accounts[i].accountIdentifications = []
                        for(let t=0; t < data.bankList[j].accountIdentifications.length; t++){
                            datas.accounts[i].accountIdentifications[t] = {}
                            datas.accounts[i].accountIdentifications[t].description = {}
                            datas.accounts[i].accountIdentifications[t].description.uk_UA = data.bankList[j].accountIdentifications[t].description
                            datas.accounts[i].accountIdentifications[t].id = data.bankList[j].accountIdentifications[t].identifier
                            datas.accounts[i].accountIdentifications[t].scheme = data.bankList[j].accountIdentifications[t].scheme
                        }
                        datas.accounts[i].bankName = {}
                        datas.accounts[i].bankName.uk_UA = data.bankList[j].bankName
                        datas.accounts[i].currency = data.bankList[j].currency //'UAH'
                        datas.accounts[i].description = {}
                        datas.accounts[i].description.uk_UA = data.bankList[j].description
                    }
                }
            }
            let tmpDel = false
            for(let jt = 0; jt < data.deleteBankGroops.length; jt++){
                if(data.deleteBankGroops[jt].type === "other") tmpDel = data.deleteBankGroops[jt].status
            }
            if(!tmpDel){
                if(data.base.bankAccounts !== null && data.base.bankAccounts.length > 0){
                    for(let i=0; i < data.base.bankAccounts.length; i++){
                        if(data.base.bankAccounts[i].accountType === 'other'){
                            let bankTmp = []
                            for(let rt = 0; rt < data.base.bankAccounts[i].accounts.length; rt++){
                                let tmpFlag = false
                                for(let f = 0; f < data.deleteCurentBank.length; f++){
                                    if(data.deleteCurentBank[f] === data.base.bankAccounts[i].accounts[rt].id) tmpFlag = true
                                }
                                if(!tmpFlag) bankTmp.push(data.base.bankAccounts[i].accounts[rt])
                            }
                            let tmpBank = [...datas.accounts, ...bankTmp]
                            datas.accounts = tmpBank
                        }
                    }
                }
            }
            tmp.bankAccounts.push(datas)
        }else{
            let tmpDel = false
            for(let jt = 0; jt < data.deleteBankGroops.length; jt++){
                if(data.deleteBankGroops[jt].type === "other") tmpDel = data.deleteBankGroops[jt].status
            }
            if(!tmpDel){
                if(data.base.bankAccounts !== null && data.base.bankAccounts.length > 0){
                    let datas = {}
                    let flag = false
                    datas.accountType = "other"
                    for(let i=0; i < data.base.bankAccounts.length; i++){
                        if(data.base.bankAccounts[i].accountType === 'other'){
                            let bankTmp = []
                            for(let rt = 0; rt < data.base.bankAccounts[i].accounts.length; rt++){
                                let tmpFlag = false
                                for(let f = 0; f < data.deleteCurentBank.length; f++){
                                    if(data.deleteCurentBank[f] === data.base.bankAccounts[i].accounts[rt].id) tmpFlag = true
                                }
                                if(!tmpFlag) bankTmp.push(data.base.bankAccounts[i].accounts[rt])
                            }
                            datas.accounts = bankTmp
                            flag = true
                        }
                    }
                    if(flag === true) tmp.bankAccounts.push(datas)
                }
            }
        }
        return tmp
    }
    return body
}

export function addAuctionPeriodProperty(data, body, property){
    let tmp = body
    if(data.base[property] !== null && data.base[property] !== '' && data.base[property] !== 'null'){
        let auctionPeriod = {}
        auctionPeriod.startDate = data.base[property]
        tmp.auctionPeriod = auctionPeriod
        return tmp
    }
    return body
}

export function addPropertyMongaDS(data, body, property){
    let tmp = body
    if(data.base[property] !== null && data.base[property] !== '' && data.base[property] !== 'null'){
        let dutchStep = {}
        dutchStep.dutchStepQuantity = Number(data.base[property].dutchStepQuantity)
        /*dutchStep.dutchStepPercent = Number(data.base[property].dutchStepPercent)
        if(data.base[property].hasOwnProperty('dutchStepValue')){
            if(data.base[property].dutchStepValue !== null){
                if(data.base[property].dutchStepValue.hasOwnProperty('amount')){
                    if(data.base[property].dutchStepValue.amount !== null){
                        dutchStep.dutchStepValue = {}
                        dutchStep.dutchStepValue.currency = 'UAH'
                        dutchStep.dutchStepValue.amount = Number(data.base[property].dutchStepValue.amount)
                    }
                }
            }
        }*/
        tmp.dutchStep = dutchStep
        return tmp
    }   
    return body
}