import React,{useState} from 'react';
import styles from './newContract.module.css';

import {connect} from 'react-redux';

import {
    isRCE, isRCD,
    isLRE, isLSE, isLSP,
    isBRW, isSPE, isSPD,
    isNLE, isNLD, isLAE, isLAW, isLAP, isLPE, isAPE, isAPD
} from '../../../redux/procedure';
    
import {changeAwardRejectPopUpStatus, } from '../../../redux/actions/awards/awards.js';

import { setAwardAllUserPopUp, setAwardAllUserPopUpUser } from '../../../redux/actions.js';
import { setDataAddress } from '../../../redux/createAddress';

import {
    changeContractApprowePopUpStatus,
    setContractTitle,
    setContractDescription,
    setContractContractNumber,
    setContractDateFrom,
    setContractDateTill,
    setContractStartDate,
    setContractEndDate,
    setContractCurrency,
    setContractAmount,
    setContractDateSigned,
    setContractDatePaid,
    setContractDocId,
    setContractDocTitle,
    setContractDocType,
    setContractDocDescription,
} from '../../../redux/actions/contracts/contracts.js';

import {
    historyAuctions,
    historyAwards,
    historyContrasts,
    historyBids,
} from '../../../redux/history.js';
import {setUniversalError, changeLoader} from '../../../redux/actions.js';
import {contractsAuctionIdGet} from '../../../redux/actions/contracts/contracts.js';

import ConractOther from './contractForm/other'
import ContractLreLseLsp from './contractForm/LreLspLse'
import ContractBRW from './contractForm/Brw'
import ContractSPE from './contractForm/Spe'
import ContractNLE from './contractForm/nle'
import ContractLAE from './contractForm/lae'
import ContractLAP from './contractForm/lap'
import ContractLPE from './contractForm/lpe'
import ContractAPE from './contractForm/ape'

const Contract = (props) => {
    if(props.auctionId !== null){
        if(isRCE(props.auctionId.sellingMethod) || isRCD(props.auctionId.sellingMethod)) return null
        if(isBRW(props.auctionId.sellingMethod)){
            return <ContractBRW />
        }else if(isSPE(props.auctionId.sellingMethod) || isSPD(props.auctionId.sellingMethod)){
            return <ContractSPE />
        }else if(
            isLRE(props.auctionId.sellingMethod)
            || isLSE(props.auctionId.sellingMethod)
            || isLSP(props.auctionId.sellingMethod)
        ){
            return <ContractLreLseLsp />
        }else if(isNLE(props.auctionId.sellingMethod) || isNLD(props.auctionId.sellingMethod)){
            return <ContractNLE />
        }else if(isLAE(props.auctionId.sellingMethod) || isLAW(props.auctionId.sellingMethod)){
            return <ContractLAE />
        }else if(isLAP(props.auctionId.sellingMethod)){
            return <ContractLAP />
        }else if(isLPE(props.auctionId.sellingMethod)){
            return <ContractLPE />
        }else if(isAPE(props.auctionId.sellingMethod) || isAPD(props.auctionId.sellingMethod)){
            return <ContractAPE />
        }else{
            return <ConractOther />
        }
    }
    return null
}

const mapStateToProps = state => {
    return {
        auctionId: state.start.auctionFull,
    }
}

const mapDispatchToProps = {
    changeAwardRejectPopUpStatus,
    setAwardAllUserPopUp,
    setAwardAllUserPopUpUser,

    changeContractApprowePopUpStatus,

    setContractTitle,
    setContractDescription,
    setContractContractNumber,
    setContractDateFrom,
    setContractDateTill,
    setContractStartDate,
    setContractEndDate,
    setContractCurrency,
    setContractAmount,
    setContractDateSigned,

    historyAuctions,
    historyAwards,
    historyContrasts,
    historyBids,

    setUniversalError,
    changeLoader,
    contractsAuctionIdGet,
    setContractDatePaid,

    setContractDocId,
    setContractDocTitle,
    setContractDocType,
    setContractDocDescription,
    setDataAddress,
}

export default connect(mapStateToProps, mapDispatchToProps)(Contract)
