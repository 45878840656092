import {addProperty, addPropertyMonga, addPropertyValue, addPropertyDiscount,
    addAuctionPeriodProperty, addBankProperty, addArrest, addDebtorInformation,
    addCurrentTenants, addCurrentTenants2
} from './utilsCreateBody'

export function createBodyLAP(data){
    if(data !== null){
        let body = {}
        body = addPropertyMonga(data, body, 'title')
        body = addPropertyMonga(data, body, 'description')
        body = addPropertyMonga(data, body, 'accessDetails')
        body = addProperty(data, body, 'tenderAttempts')
        body = addProperty(data, body, 'numberOfCurrentTenants')
        body = addProperty(data, body, 'lotId')
        body = addProperty(data, body, 'sellingMethod')
        body = addPropertyMonga(data, body, 'x_documentRequirements')
        body = addPropertyMonga(data, body, 'x_additionalInformation')
        //body = addProperty(data, body, 'minNumberOfQualifiedBids')
        body = addProperty(data, body, 'saleType')
        body = addAuctionPeriodProperty(data, body, 'auctionPeriod')
        ///body = addProperty(data, body, 'valueAddedTaxCharged')
        body = addPropertyValue(data, body, 'value')
        if(data.base.minimalStep.amount !== null
            && data.base.minimalStep.amount !== ''
            && data.base.minimalStep.amount !== 0
            && data.base.minimalStep.amount !== '0'
        ){

            body = addPropertyValue(data, body, 'minimalStep')
        }else{
            body.minimalStep = null
        }
        body = addPropertyValue(data, body, 'guarantee')
        body = addPropertyValue(data, body, 'registrationFee')
        body = addPropertyValue(data, body, 'expertMonetaryValuation')
        body = addPropertyValue(data, body, 'normativeMonetaryValuation')
        body = addPropertyValue(data, body, 'budgetSpent')
        body = addProperty(data, body, 'previousAuctionId')
        // body = addPropertyDiscount(data, body, 'discount')
        body = addBankProperty(data, body, 'banks')
        body = addPropertyMonga(data, body, 'restrictions')
        body = addPropertyMonga(data, body, 'conditions')
        body = addArrest(data, body, 'arrests')

        //body = addDebtorInformation(data, body, 'sellingEntity')
        body.currentTenants = []
        body.currentTenants.push(addCurrentTenants(data, body, 'currentTenants'))
        if(body.numberOfCurrentTenants === 2) body.currentTenants.push(addCurrentTenants2(data, body, 'currentTenants2'))
        //if(body.numberOfCurrentTenants === 2) body.currentTenants.push(data.base.currentTenants2)
        return body
    }
    return null
}
export function createBodyLAPCT(data){
    if(data !== null){
        let body = {}
        ///body = addCurrentTenants(data, body, 'currentTenants')
        //body = addCurrentTenants(data, body, 'currentTenants')
        /////body = addCurrentTenants(data, body, 'currentTenants')
        //body = addCurrentTenants(data, body, 'currentTenants')
        body.currentTenants = []
        body.currentTenants.push(addCurrentTenants(data, body, 'currentTenants'))
        if(body.numberOfCurrentTenants === 2) body.currentTenants.push(addCurrentTenants2(data, body, 'currentTenants2'))
        return body
    }
    return null
}