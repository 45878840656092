import React from 'react';

import {connect} from 'react-redux';

import TextFull from '../../../elements/viewData/textFull'

import {isGFE, isGFD, isLRE, isLSE, isLSP} from '../../../../redux/procedure.js';

import {viewStringValue} from '../../../../api/valueToString.js'

const ValueString = (props) => {
    if(props.auctionId === null) return null
    if(props.auctionId.value === null) return null
    let tmp = viewStringValue(props.auctionId.value)
    return (
        <TextFull
            title={typeTitleText(props)}
            data={tmp}
        />
    )
}

function typeTitleText(props){
    if(isLRE(props.auctionId.sellingMethod) || isLSE(props.auctionId.sellingMethod) || isLSP(props.auctionId.sellingMethod)){
        return 'Стартова ціна'
        //return 'Стартова орендна плата за період'
    }
    if(isGFE(props.auctionId.sellingMethod) || isGFD(props.auctionId.sellingMethod)) return 'Початкова ціна продажу лоту'
    return 'Стартова ціна лота'
}

const mapStateToProps = state => {
    return {
        auctionId: state.start.auctionFull,
    }
}

export default connect(mapStateToProps, null)(ValueString)